import React, { useState, useEffect } from "react";
import { Modal, Button, Container, Row, Col, Table } from "react-bootstrap";
import { API_URL, API_ROUTES } from "../../../api";
import axios from "axios";
import { InputNumber } from "primereact/inputnumber";
import { useHeaders } from "../../../const/headers";
import { Dropdown } from "primereact/dropdown";
import { useCity } from "../listlocation/ListLocation";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";

function EditOrder({
  modalEdit,
  setModalEdit,
  itemEdit,
  LickCheck,
  setRefresh,
  refresh,
}) {
  const accses = localStorage.getItem("access");
  const headers = useHeaders();
  const editApi = API_URL + API_ROUTES.EDIT_ORDER;
  const getDicrict = API_URL + API_ROUTES.GET_DISTRICT;
  const getWard = API_URL + API_ROUTES.GET_WARD;
  const [selecItem, setSelecItem] = useState({});
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedDirict, setSelectedDirict] = useState(null);
  const [selectedWard, setSelectedWard] = useState(null);
  const [dataDic, setDataDic] = useState([]);
  const [dataWard, setDataWard] = useState([]);
  const [cities, setCities] = useState([]);

  const getListCity = API_URL + API_ROUTES.LIST_CITIS;

  const handleInputChange = (event, field) => {
    const value = field === "price" ? event.value : event.target.value;
    setSelecItem((prevItem) => ({
      ...prevItem,
      [field]: value,
    }));
  };
  const getDataWard = async (data) => {
    if (data?.id) {
      const body = { id_district: data?.id };
      try {
        const res = await axios.post(getWard, body, headers);
        setDataWard(res.data);
        setSelectedWard(res.data.find((w) => w.ward === itemEdit.ward));
      } catch (err) {}
    }
  };

  const getDataDicrict = async (data) => {
    if (data?.id) {
      const body = { id_city: data?.id };
      try {
        const res = await axios.post(getDicrict, body, headers);
        setDataDic(res.data);
        setDataWard([]);
        setSelectedWard([]);
        setSelectedDirict(
          res.data?.find((item) => item?.district === itemEdit?.district)
        );
        await getDataWard(
          res.data.find((w) => w.district === itemEdit.district)
        );
      } catch (err) {}
    }
  };

  useEffect(() => {
    setSelecItem(itemEdit);
    const fetchCities = async () => {
      try {
        const response = await axios.get(getListCity, headers);
        setCities(response.data);
        const country = response.data.find((c) => c.city === itemEdit.city);
        setSelectedCountry(country);
        getDataDicrict(country);
        setDataWard([]);
        setSelectedWard([]);
      } catch (err) {}
    };

    fetchCities();
  }, [modalEdit, itemEdit]);

  const postEditOrder = async () => {
    const body = {
      id_order: selecItem.id,
      name_order: selecItem.name_order,
      phone_number: selecItem.phone_number,
      quantity: selecItem.quantity,
      address: selecItem.address,
      price: selecItem.price,
      note: selecItem.note,
      product: selecItem.product,
      city: selectedCountry.city,
      district: selectedDirict.district,
      ward: selectedWard.ward,
    };
    try {
      const response = await axios.post(editApi, body, headers);
      setModalEdit(false);
      setRefresh(!refresh);
    } catch (err) {}
  };
  return (
    <Dialog
      visible={modalEdit}
      header="Chỉnh sửa đơn hàng"
      size="lg"
      backdrop="static"
      onHide={() => setModalEdit(false)}
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Container>
        <Row>
          <Col className="w-100" xs={6} md={4}>
            <div className="input-group flex-nowrap mb-2">
              <div className="" style={{ width: "9rem" }}>
                <span
                  className="input-group-text"
                  id="addon-wrapping"
                  style={{
                    borderBottomRightRadius: "0",
                    borderTopRightRadius: "0",
                  }}
                >
                  Tên khách hàng
                </span>
              </div>
              <input
                type="text"
                className="form-control"
                placeholder=""
                aria-label="Username"
                style={{ height: "auto" }}
                aria-describedby="addon-wrapping"
                value={selecItem.name_order || ""}
                onChange={(event) => handleInputChange(event, "name_order")}
              />
            </div>
            <div className="input-group flex-nowrap mb-2">
              <div className="" style={{ width: "9rem" }}>
                <span
                  className="input-group-text"
                  id="addon-wrapping"
                  style={{
                    borderBottomRightRadius: "0",
                    borderTopRightRadius: "0",
                  }}
                >
                  Số điện thoại
                </span>
              </div>
              <input
                type="text"
                className="form-control"
                placeholder=""
                aria-label="Username"
                style={{ height: "auto" }}
                aria-describedby="addon-wrapping"
                value={selecItem.phone_number || ""}
                onChange={(event) => handleInputChange(event, "phone_number")}
              />
            </div>
            <div className="d-flex w-100 mb-2">
              <Dropdown
                style={{
                  padding: "0 !important",
                  borderRadius: "0",
                  alignItems: "center",
                  height: "38px",
                  width: "100%",
                }}
                value={selectedCountry}
                onChange={(e) => {
                  setSelectedCountry(e.value);
                  getDataDicrict(e.value);
                }}
                options={cities}
                optionLabel="city"
                placeholder="Tỉnh / Thành Phố"
              />
              <Dropdown
                style={{
                  height: "38px",
                  width: "100%",
                  padding: "0",
                  borderRadius: "0",
                  alignItems: "center",
                }}
                value={selectedDirict}
                onChange={(e) => {
                  setSelectedDirict(e.value);
                  getDataWard(e.value);
                }}
                options={dataDic}
                optionLabel="district"
                placeholder="Quận / Huyện"
              />
              <Dropdown
                style={{
                  height: "24px",
                  padding: "0",
                  borderRadius: "0",
                  alignItems: "center",
                  height: "38px",
                  width: "100%",
                }}
                value={selectedWard}
                onChange={(e) => setSelectedWard(e.value)}
                options={dataWard}
                optionLabel="ward"
                placeholder="Xã / Phường"
              />
            </div>
            <div className="input-group flex-nowrap mb-2">
              <div className="" style={{ width: "9rem" }}>
                <span
                  className="input-group-text"
                  id="addon-wrapping"
                  style={{
                    borderBottomRightRadius: "0",
                    borderTopRightRadius: "0",
                  }}
                >
                  Địa chỉ cụ thể
                </span>
              </div>
              <input
                type="text"
                className="form-control"
                placeholder=""
                aria-label="Username"
                style={{ height: "auto" }}
                aria-describedby="addon-wrapping"
                value={selecItem.address || ""}
                onChange={(event) => handleInputChange(event, "address")}
              />
            </div>
            <div className="input-group flex-nowrap mb-2">
              <span
                style={{ width: "11rem", textAlign: "start" }}
                className="p-inputgroup-addon input-group-text justify-content-start"
                id="addon-wrapping"
              >
                Sản phẩm
              </span>
              <InputTextarea
                className="w-100"
                value={selecItem.product || ""}
                onChange={(event) => handleInputChange(event, "product")}
              />
            </div>
            <div className="input-group flex-nowrap mb-2">
              <div className="" style={{ width: "9rem" }}>
                <span
                  className="input-group-text"
                  id="addon-wrapping"
                  style={{
                    borderBottomRightRadius: "0",
                    borderTopRightRadius: "0",
                  }}
                >
                  Số lượng
                </span>
              </div>
              <input
                type="number"
                min={0}
                className="form-control"
                placeholder=""
                aria-label="Username"
                style={{ height: "auto" }}
                aria-describedby="addon-wrapping"
                value={selecItem.quantity || ""}
                onChange={(event) => handleInputChange(event, "quantity")}
              />
            </div>
            <div className="input-group flex-nowrap mb-2">
              <div className="" style={{ width: "11.5rem" }}>
                <span
                  className="input-group-text"
                  id="addon-wrapping"
                  style={{
                    borderBottomRightRadius: "0",
                    borderTopRightRadius: "0",
                  }}
                >
                  Thành tiền
                </span>
              </div>
              <nav className="d-flex gap-2" style={{ width: "100%" }}>
                <InputNumber
                  style={{
                    width: "70%",
                    height: "auto",
                  }}
                  inputId="integeronly"
                  min={0}
                  value={selecItem.price || ""}
                  onChange={(event) => handleInputChange(event, "price")}
                />
                <input
                  style={{ width: "30%" }}
                  disabled={true}
                  type="text"
                  className="form-control"
                  placeholder="VND"
                  aria-label="Username"
                  aria-describedby="addon-wrapping"
                />
              </nav>
            </div>
            <div className="input-group flex-nowrap mb-2">
              <span
                style={{ width: "11rem", textAlign: "start" }}
                className="p-inputgroup-addon input-group-text justify-content-start"
                id="addon-wrapping"
              >
                Ghi chú
              </span>
              <InputTextarea
                className="w-100"
                value={selecItem.note || ""}
                onChange={(event) => handleInputChange(event, "note")}
              />
            </div>
          </Col>
        </Row>
      </Container>
      <nav className="w-100 d-flex gap-2 justify-content-end">
        <Button className=" button-blue radius" onClick={postEditOrder}>
          Lưu
        </Button>
        <Button
          className=" button-red radius"
          onClick={() => setModalEdit(false)}
        >
          Hủy
        </Button>
      </nav>
    </Dialog>
  );
}

export default EditOrder;
