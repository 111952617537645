import React from "react";

const Yourlistings = ({ dataStatis }) => {
  return (
    <div className="card">
      <div
        style={{ width: "100%", backgroundColor: "#fff" }}
        className="w-moblie d-flex flex-column card"
      >
        <div className="title">
          <h4
            className="w-100  p-2"
            //   style={{
            //     color: "#1976D2",
            //   }}
          >
            Bài niêm yết của bạn
          </h4>
        </div>
        <div className="middle-dash h-100 d-flex text-center justify-content-around w-100 ">
          <div
            style={{
              paddingRight: "30px",
              paddingLeft: "30px",
              paddingBottom: "30px",
            }}
            className="d-flex w-100 gap-2 flex-wrap"
          >
            <div
              // className="d-flex flex-column justify-content-start align-items-center"
              style={{
                border: "0.5px solid #ccc",
                width: "24%",
                borderRadius: "8px",
                padding: "12px",
              }}
            >
              <h4 className="d-flex w-100 fw-bold">
                {dataStatis?.data?.needs_attention}
              </h4>
              <p style={{ fontWeight: "300" }} className="d-flex w-100 fs-6">
                Cần chú ý
              </p>
            </div>
            <div
              // className="d-flex flex-column justify-content-start align-items-center"
              style={{
                border: "0.5px solid #ccc",
                width: "24%",
                borderRadius: "8px",
                padding: "12px",
              }}
            >
              <h4 className="d-flex w-100 fw-bold">
                {dataStatis?.data?.active_and_pending}
              </h4>
              <p style={{ fontWeight: "300" }} className="d-flex w-100 fs-6">
                Đang hoạt động & đang chờ xử lý
              </p>
            </div>
            <div
              // className="d-flex flex-column justify-content-start align-items-center"
              style={{
                border: "0.5px solid #ccc",
                width: "24%",
                borderRadius: "8px",
                padding: "12px",
              }}
            >
              <h4 className="d-flex w-100 fw-bold">
                {dataStatis?.data?.sold_and_out_of_stock}
              </h4>
              <p style={{ fontWeight: "300" }} className="d-flex w-100 fs-6">
                Đã bán & hết hàng
              </p>
            </div>
            <div
              style={{
                border: "0.5px solid #ccc",
                width: "24%",
                borderRadius: "8px",
                padding: "12px",
              }}
            >
              <h4 className="d-flex w-100 fw-bold">
                {dataStatis?.data?.drafts}
              </h4>
              <p style={{ fontWeight: "300" }} className="d-flex w-100 fs-6">
                Bản nháp
              </p>
            </div>
            <div
              // className="d-flex flex-column justify-content-start align-items-center"
              style={{
                border: "0.5px solid #ccc",
                width: "24%",
                borderRadius: "8px",
                padding: "12px",
              }}
            >
              <h4 className="d-flex w-100 fw-bold">
                {dataStatis?.data?.renew_listings_count}
              </h4>
              <p style={{ fontWeight: "300" }} className="d-flex w-100 fs-6">
                Có thể gia hạn
              </p>
            </div>
            <div
              // className="d-flex flex-column justify-content-start align-items-center"
              style={{
                border: "0.5px solid #ccc",
                width: "24%",
                borderRadius: "8px",
                padding: "12px",
              }}
            >
              <h4 className="d-flex w-100 fw-bold">
                {dataStatis?.data?.delete_and_relist_listings_count}
              </h4>
              <p style={{ fontWeight: "300" }} className="d-flex w-100 fs-6">
                Có thể xóa & niêm yết lại
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Yourlistings;
