import React from "react";
import { Dialog } from "primereact/dialog";
export const ModalDetalisReup = ({
  setModalDetails,
  modalDetails,
  itemResults,
}) => {
  return (
    <Dialog
      header="Trạng thái chi tiết"
      visible={modalDetails}
      style={{ width: "50vw" }}
      onHide={() => setModalDetails(false)}
    >
      <div className="f-flex flex-column gap-2">
        <nav
          style={{
            paddingBottom: "0",
            paddingTop: "0.5rem",
          }}
          className="d-flex gap-2"
        >
          <h5>UID: {itemResults?.account?.UID}</h5>
          <h5>{itemResults?.account?.name}</h5>
        </nav>

        <nav
          style={{
            borderBottom: "1px solid",
            paddingBottom: "0.5rem",
            paddingTop: "0.5rem",
          }}
          className="d-flex gap-2"
        >
          <p>
            {itemResults?.status === 0
              ? "Sao chép bài viết không thành công"
              : itemResults?.status === 1
              ? "Sao chép bài viết thành công"
              : "Bị giới hạn tính năng sao chép bài viết."}
          </p>
        </nav>

        <nav
          style={{
            borderBottom: "1px solid",
            paddingBottom: "0.5rem",
            paddingTop: "0",
          }}
          className="d-flex gap-2"
        >
          <h6
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              marginTop: "10px",
            }}
          >
            <>
              <p>Link bài viết:</p>
              <p>
                <a
                  href={`https://www.facebook.com/${itemResults?.post_id}`}
                  target="_blank"
                  rel="nofollow noreferrer"
                >
                  {itemResults?.post_id}
                </a>
              </p>
            </>
          </h6>
        </nav>
        <nav
          style={{
            paddingBottom: "0.5rem",
            paddingTop: "0",
            marginTop: "10px",
          }}
          className="d-flex gap-2"
        >
          <>
            <p>Sao chép từ nguồn:</p>
            <p>
              <a
                href={`https://www.facebook.com/${itemResults?.from_id}`}
                target="_blank"
                rel="nofollow noreferrer"
              >
                {itemResults?.from_id}
              </a>
            </p>
          </>
        </nav>
      </div>
    </Dialog>
  );
};
