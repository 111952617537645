import classNames from "classnames/bind";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { Dialog } from "primereact/dialog";
import { useState, useEffect } from "react";
import axios from "axios";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { IoIosLink } from "react-icons/io";
import { MdContentCopy } from "react-icons/md";
import { useHeaders } from "../../../../../../const/headers";
import { API_ROUTES, API_URL } from "../../../../../../api";
import styles from "./Slideshow.module.scss";
import { useUser } from "../../../../../../contexts/UserContext";
const cx = classNames.bind(styles);

function Slideshow() {
  const { userInfo } = useUser();
  const [visible, setVisible] = useState(false);
  const [file, setFile] = useState(null);
  const [image, setImage] = useState("");
  const [imageFirst, setImageFirst] = useState("");
  const [content, setContent] = useState("");
  const [link, setLink] = useState("");
  const [rf, setRf] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const itemEdit = { id: "" }; // Update itemEdit with the appropriate value
  const headers = useHeaders(); // Update with your headers configuration
  const uploadEndpoint = API_URL + API_ROUTES.UPLOAD_FILE_SERVER;
  const createUpdateEndpoint = API_URL + API_ROUTES.EDIT_LANDING;
  const apiGetLanding = API_URL + API_ROUTES.GET_ALL_LANDING;
  const [landing, setLanding] = useState([]);
  const fetchData = async () => {
    try {
      const res = await axios.get(apiGetLanding);
      setLanding(res.data);
    } catch (err) {
    }
  };
  useEffect(() => {
    fetchData();
  }, [rf]);
  const dataImage = landing?.filter((item) => item?.id === 3);
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFile(file);
    const reader = new FileReader();
    reader.onload = () => {
      const imageDataURL = reader.result;
      const imgElement = document.getElementById("previewImage");
      if (imgElement) {
        imgElement.src = imageDataURL;
      }
    };
    reader.readAsDataURL(file);
  };

  const uploadToServer = async () => {
    if (file !== null) {
      const formData = new FormData();
      formData.append("image", file);
      try {
        const response = await axios.post(uploadEndpoint, formData, headers);
        setImage(response.data.image);
      } catch (error) {
      }
    }
  };

  useEffect(() => {
    uploadToServer();
  }, [file]);
  useEffect(() => {
    setImage(dataImage[0]?.image);
  }, [visible]);
  const createResource = async () => {
    const data = {
      id: dataImage[0].id,
      title: content,
      content: link,
      image: image === "" ? dataImage[0]?.image : image,
    };
    try {
      const response = await axios.post(createUpdateEndpoint, data, headers);
      setRf(!rf);
      setLink("");
      setContent("");
      setModalEdit(false);
    } catch (error) {
    }
  };

  const slideImages = [`${API_URL}/${dataImage[0]?.image}`];

  const slideProperties = {
    duration: 2000,
    transitionDuration: 500,
    autoplay: false,
    indicators: false,
    arrows: false,
  };

  return (
    <div>
      <div id="home" className={cx("slideshow-container")}>
        {/* <Slide {...slideProperties}> */}
        {/* {slideImages.map((image, index) => ( */}
        <div className={cx("each-slide")}>
          <div className={cx("image-container")}>
            <img className={cx("image")} src={slideImages[0]} alt={`slide}`} />
          </div>
        </div>
        {/* ))} */}
        {/* </Slide> */}
        {userInfo?.is_admin === true ? (
          <button
            style={{
              width: "100%",
              border: "none",
              backgroundColor: "#0866ff",
              color: "#fff",
              fontSize: "1rem",
              padding: "0.5rem 1rem",
              cursor: "pointer",
              outline: "none",
              transition: "all 0.2s ease-in-out",
            }}
            onClick={() => setVisible(true)}
          >
            Sửa
          </button>
        ) : (
          <></>
        )}
        <Dialog
          header="Quản lý"
          visible={visible}
          style={{ width: "50vw" }}
          onHide={() => {
            if (!visible) return;
            setVisible(false);
          }}
        >
          <div className="d-flex flex-column gap-2 mr-2 mb-2">
            <h3>Chọn Ảnh</h3>
            <label htmlFor="fileInput">
              <button
                className="d-flex flex-column p-2 align-items-center gap-2 border border-secondary justify-content-between rounded"
                onClick={() => document.getElementById("fileInput").click()}
              >
                <p className="mb-0">Chọn ảnh</p>
              </button>
            </label>
            <input
              id="fileInput"
              type="file"
              style={{ display: "none" }}
              onChange={handleImageChange}
            />
            {image === "" && imageFirst === "" ? (
              <div></div>
            ) : (
              <img
                style={{
                  width: "100px",
                  display: "block",
                }}
                alt="Selected Image"
                src={`${API_URL}/${image === "" ? imageFirst : image}`}
              />
            )}
          </div>
          <div className="d-flex justify-content-end gap-2 mt-2">
            <Button
              className="button-blue radius"
              label="Sửa"
              onClick={() => createResource()}
            />
            <Button
              className="button-red radius"
              label="Hủy"
              onClick={() => setModalEdit(false)}
              severity="danger"
            />
          </div>
        </Dialog>
      </div>
    </div>
  );
}

export default Slideshow;
