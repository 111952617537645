import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import axios from "axios";
import { useHeaders } from "../../../../const/headers";
import { API_URL, API_ROUTES_NTD } from "../../../../api";
import { ContentsCreateWatch } from "./dataCreateScript/ContentsCreateWatch";
import DatePicker from "react-datepicker";
import { forwardRef } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { vi } from "date-fns/locale";
import { formatDateArticle } from "../../../../const/convertDate";
import { Checkbox } from "primereact/checkbox";
import { RiExpandDiagonal2Line } from "react-icons/ri";
export const CreateScriptWatch = ({
  props,
  openAddScript,
  setOpenAddScript,
  setRefresh,
  refresh,
}) => {
  const headers = useHeaders();
  const createCateArticlePost =
    API_URL + API_ROUTES_NTD.ARTICLE_WATCH.CREATE_OR_EDIT;
  const [name, setName] = useState("");
  const [comments, setComments] = useState("");
  const [linkVd, setLinkVd] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());
  const [checkedOn, setCheckedOn] = useState(false);
  const addCateMkt = async () => {
    if (!name) {
      alert("Vui lòng nhập đầy đủ thông tin");
    } else if (!linkVd.startsWith("https://drive.google.com/file/d/")) {
      alert("Link video không đúng định dạng. Vui lòng kiểm tra lại!");
    } else if (linkVd === "" || comments?.length === 0) {
      alert("Phải thêm nội dung hoặc hình ảnh");
    } else {
      const body = {
        id_category: null,
        name: name,
        content: comments,
        video: linkVd,
        schedule: checkedOn
          ? `${formatDateArticle(startTime)?.split(" ")[0]} ${
              formatDateArticle(startDate)?.split(" ")[1]
            }` || null
          : null,
      };
      try {
        const res = await axios.post(createCateArticlePost, body, headers);
        setComments([]);
        setLinkVd("");
        setName("");
        setOpenAddScript(!openAddScript);
        setRefresh(!refresh);
      } catch (err) {}
    }
  };
  const handleStartDateChange = (date) => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    if (date >= currentDate) {
      setStartDate(date);
      if (date.setHours(0, 0, 0, 0) === currentDate.getTime()) {
        const now = new Date();
        setStartTime(
          new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate(),
            now.getHours(),
            now.getMinutes()
          )
        );
      }
    } else {
      alert("Không được chọn ngày nhỏ hơn ngày hiện tại.");
    }
  };
  const handleStartTimeChange = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(startDate);
    selectedDate.setHours(0, 0, 0, 0);
    currentDate.setHours(0, 0, 0, 0);
    if (selectedDate.getTime() === currentDate.getTime()) {
      if (time >= new Date()) {
        setStartTime(time);
      } else {
        alert("Không được chọn thời gian nhỏ hơn thời gian hiện tại.");
      }
    } else {
      setStartTime(time);
    }
  };
  const ExampleCustomTimeInput = forwardRef(({ value, onClick }, ref) => (
    <button className="example-custom-input radius" onClick={onClick} ref={ref}>
      {value}
    </button>
  ));
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button
      className="example-custom-input radius"
      style={{ width: "120px" }}
      onClick={onClick}
      ref={ref}
    >
      {value}
    </button>
  ));
  return (
    <Dialog
      header="Kịch bản đăng video"
      visible={openAddScript}
      style={{ position: "relative", width: "70vw" }}
      onHide={() => setOpenAddScript(false)}
      className="overFlowJoinGr"
      footer={
        <div className="d-flex justify-content-end gap-2 mt-2">
          <Button className="button-blue radius" onClick={addCateMkt}>
            Lưu kịch bản
          </Button>
          <Button
            className="button-red radius"
            onClick={() => setOpenAddScript(false)}
          >
            Thoát không lưu
          </Button>
          <RiExpandDiagonal2Line
            size={20}
            style={{
              position: "absolute",
              right: "0",
              bottom: "0",
            }}
          />
        </div>
      }
    >
      <div className="d-flex flex-column h-100" style={{ overflow: "auto" }}>
        <div className="p-inputgroup flex-1 mb-2">
          <span className="p-inputgroup-addon " style={{ width: "9rem" }}>
            Tên kịch bản
          </span>
          <InputText
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Nhập tên kịch bản"
          />
        </div>
        <div className="d-flex flex-column gap-2">
          <div className="d-flex gap-2">
            <Checkbox
              onChange={(e) => setCheckedOn(e.checked)}
              checked={checkedOn}
            ></Checkbox>
            <nav className="d-flex gap-2 align-items-center">
              <p style={{ whiteSpace: "nowrap" }}>Đặt lịch: </p>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                {" "}
                <p style={{ whiteSpace: "nowrap" }}>Chọn giờ </p>
                <DatePicker
                  selected={startTime}
                  onChange={handleStartTimeChange}
                  customInput={<ExampleCustomTimeInput />}
                  locale={vi}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Giờ"
                  dateFormat="HH:mm"
                  placeholderText="Chọn giờ"
                />
                <p style={{ whiteSpace: "nowrap" }}>Chọn ngày </p>
                <DatePicker
                  selected={startDate}
                  onChange={handleStartDateChange}
                  customInput={<ExampleCustomInput />}
                  locale={vi}
                  dateFormat="dd-MM-yyyy"
                  placeholderText="Chọn ngày"
                  minDate={new Date()}
                />
              </div>
            </nav>
          </div>
        </div>
        <ContentsCreateWatch
          linkVd={linkVd}
          setLinkVd={setLinkVd}
          comments={comments}
          setComments={setComments}
        />
      </div>
    </Dialog>
  );
};
