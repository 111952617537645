import React, { useState, useEffect, useRef } from "react";
import { API_URL, API_ROUTES } from "../../../api";
import axios from "axios";
import { useHeaders } from "../../../const/headers";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";
import { useCity } from "../listlocation/ListLocation";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";

function CreateOrder({
  modalCreate,
  setModalCreate,
  itemEdit,
  setRefresh,
  refresh,
}) {
  const headers = useHeaders();
  const { cities } = useCity();
  const isInitialRender = useRef(true);
  const [selecItem, setSeclecItem] = useState({});
  const createOrder = API_URL + API_ROUTES.CREATE_ORDER;
  const apiGetProduct = API_URL + API_ROUTES.GET_PAGE_PRODUCT;
  const getDicrict = API_URL + API_ROUTES.GET_DISTRICT;
  const getWard = API_URL + API_ROUTES.GET_WARD;
  const [product, setProduct] = useState([]);
  const [numPrice, setNumPrice] = useState("");
  const [getItem, setGetItem] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedDirict, setSelectedDirict] = useState(null);
  const [selectedWard, setSelectedWard] = useState(null);
  const [dataDic, setDataDic] = useState([]);
  const [dataWard, setDataWard] = useState([]);
  const countries = cities;
  const getDataDicrict = async () => {
    if (selectedCountry === null) {
      return;
    }
    const body = { id_city: selectedCountry?.id };
    try {
      const res = await axios.post(getDicrict, body, headers);
      setDataDic(res.data);
    } catch (err) {}
  };
  const getDataWard = async () => {
    if (selectedDirict === null) {
      return;
    }
    const body = { id_district: selectedDirict?.id };
    try {
      const res = await axios.post(getWard, body, headers);
      setDataWard(res.data);
    } catch (err) {}
  };
  useEffect(() => {
    getDataDicrict();
  }, [selectedCountry]);
  useEffect(() => {
    getDataWard();
  }, [selectedDirict]);
  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <div>{option?.city}</div>
        </div>
      );
    }
    return <span>{props.placeholder}</span>;
  };

  const selectedDirctTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <div>{option?.district}</div>
        </div>
      );
    }
    return <span>{props.placeholder}</span>;
  };
  const selectedWardTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <div>{option?.ward}</div>
        </div>
      );
    }
    return <span>{props.placeholder}</span>;
  };
  const fetchData = async () => {
    try {
      const response = await axios.get(apiGetProduct, headers);
      setProduct([]);
    } catch (err) {}
  };
  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    if (modalCreate) {
      setSeclecItem(itemEdit);
    }
  }, [modalCreate, itemEdit]);
  const handleInputChange = (event, field) => {
    let value;

    if (field === "final_price" || field === "quantity") {
      value = event.value;
    } else {
      value = event.target.value;
    }
    // if (field === "quantity") {
    //   value = value.replace(/[^0-9]/g, "");
    //   if (value.length > 1 && value.startsWith("0")) {
    //     value = value.replace(/^0+/, "");
    //   }
    // }

    setSeclecItem((prevItem) => ({
      ...prevItem,
      [field]: value,
    }));
  };

  useEffect(() => {
    function formatNumber(number) {
      return number?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    setNumPrice(formatNumber(selecItem?.final_price));
  }, [selecItem]);
  const body = {
    name_order: selecItem?.name_order,
    phone_number: selecItem?.phone_number,
    quantity: selecItem?.quantity,
    address: selecItem?.address,
    price: Number(selecItem?.final_price),
    note: selecItem?.note,
    product: selecItem?.order_code,
    city: selectedCountry?.city,
    district: selectedDirict?.district,
    ward: selectedWard?.ward,
  };
  const postCreateOrder = async () => {
    if (
      selecItem?.name_order === undefined ||
      selecItem?.phone_number === undefined ||
      selecItem?.address === undefined ||
      selectedCountry?.city === null ||
      selectedDirict?.district === null ||
      selectedWard?.ward === null
    ) {
      alert("Vui lòng nhập đầy đủ thông tin.");
      return;
    }

    const body = {
      name_order: selecItem?.name_order,
      phone_number: selecItem?.phone_number,
      quantity: selecItem?.quantity,
      address: selecItem?.address,
      price: Number(selecItem?.final_price),
      note: selecItem?.note,
      product: selecItem?.order_code,
      city: selectedCountry?.city,
      district: selectedDirict?.district,
      ward: selectedWard?.ward,
    };
    try {
      const response = await axios.post(createOrder, body, headers);
      setModalCreate(false);
      setRefresh(!refresh);
      setSeclecItem({});
      setGetItem([]);
    } catch (err) {}
  };
  const element = document.getElementById("widthdiv");
  const element1 = document.getElementById("witdhsecondiv");
  const heightAdd = element?.offsetWidth - element1?.offsetWidth;
  const productsByOrderCode = product?.reduce((acc, product) => {
    const orderCode = product?.id;
    if (!acc[orderCode]) {
      acc[orderCode] = [];
    }
    acc[orderCode].push(product);
    return acc;
  }, {});
  const uniqueProductArrays = Object.values(productsByOrderCode);
  const uniqueProducts = uniqueProductArrays.map(
    (productArray) => productArray[0]
  );
  return (
    <Dialog
      visible={modalCreate}
      header="Thêm đơn hàng"
      size="lg"
      backdrop="static"
      onHide={() => setModalCreate(false)}
      aria-labelledby="contained-modal-title-vcenter"
    >
      <div className="input-group flex-nowrap mb-2 ">
        <span className="p-inputgroup-addon" style={{ width: "200px" }}>
          Tên khách hàng
        </span>
        <InputText
          className="w-100"
          onChange={(event) => handleInputChange(event, "name_order")}
        />
      </div>
      <div className="input-group flex-nowrap mb-2">
        <span className="p-inputgroup-addon" style={{ width: "200px" }}>
          Số điện thoại
        </span>
        <InputText
          className="w-100"
          onChange={(event) => handleInputChange(event, "phone_number")}
          keyfilter={/[0-9]/}
        />
      </div>

      <div className="d-flex w-100 mb-2">
        <Dropdown
          style={{
            padding: "0 !important",
            borderRadius: "0",
            alignItems: "center",
            height: "38px",
            width: "100%",
          }}
          value={selectedCountry}
          onChange={(e) => setSelectedCountry(e.value)}
          options={countries}
          optionLabel="city"
          placeholder="Tỉnh / Thành Phố"
          filter
          valueTemplate={selectedCountryTemplate}
        />
        <Dropdown
          style={{
            height: "38px",
            width: "100%",
            padding: "0",
            borderRadius: "0",
            alignItems: "center",
          }}
          value={selectedDirict}
          onChange={(e) => setSelectedDirict(e.value)}
          options={dataDic}
          optionLabel="district"
          placeholder="Quận / Huyện"
          filter
          valueTemplate={selectedDirctTemplate}
        />
        <Dropdown
          style={{
            height: "24px",
            padding: "0",
            borderRadius: "0",
            alignItems: "center",
            height: "38px",
            width: "100%",
          }}
          value={selectedWard}
          onChange={(e) => setSelectedWard(e.value)}
          options={dataWard}
          optionLabel="ward"
          placeholder="Xã / Phường"
          filter
          valueTemplate={selectedWardTemplate}
        />
      </div>
      <div className="input-group flex-nowrap mb-2 ">
        <span className="p-inputgroup-addon" style={{ width: "200px" }}>
          Địa chỉ cụ thể
        </span>
        <InputText
          className="w-100"
          onChange={(event) => handleInputChange(event, "address")}
        />
      </div>
      <div className="input-group flex-nowrap mb-2 ">
        <span className="p-inputgroup-addon" style={{ width: "200px" }}>
          Sản phẩm
        </span>
        <InputTextarea
          className="w-100"
          onChange={(event) => handleInputChange(event, "order_code")}
        />
      </div>
      <div className="input-group flex-nowrap mb-2 ">
        <span className="p-inputgroup-addon" style={{ width: "200px" }}>
          Số lượng
        </span>
        <InputNumber
          className="w-100"
          onChange={(event) => handleInputChange(event, "quantity")}
          // keyfilter={/[0-9]/}
        />
      </div>
      <div className="input-group flex-nowrap mb-2 ">
        <span className="p-inputgroup-addon" style={{ width: "200px" }}>
          Thành tiền
        </span>
        <nav className="d-flex gap-2" style={{ width: "100%" }}>
          <InputNumber
            style={{
              padding: "0 !important",
              width: "70%",
            }}
            onChange={(event) => handleInputChange(event, "final_price")}
            inputId="integeronly"
          />
          <input
            style={{ width: "30%" }}
            disabled={true}
            type="text"
            className="form-control"
            placeholder="VND"
            aria-label="Username"
            aria-describedby="addon-wrapping"
          />
        </nav>
      </div>
      <div className="input-group flex-nowrap mb-2 ">
        <span className="p-inputgroup-addon" style={{ width: "200px" }}>
          Ghi chú
        </span>
        <InputTextarea
          className="w-100"
          onChange={(event) => handleInputChange(event, "note")}
        />
      </div>
      <nav className="w-100 d-flex gap-2 justify-content-end">
        <Button
          className="button-blue radius"
          label="Tạo"
          onClick={() => postCreateOrder()}
        />
        <Button
          className="button-red radius"
          label="Hủy"
          onClick={() => setModalCreate(false)}
          severity="danger"
        />
      </nav>
    </Dialog>
  );
}

export default CreateOrder;
