import React, { useEffect, useMemo, useState } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../api";
import { Button } from "primereact/button";
import { API_ROUTES } from "../../api";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../../AuthContext";
import { Password } from "primereact/password";
import { useTranslation } from "react-i18next";
import { InputText } from "primereact/inputtext";
import { updateTitle } from "../../const/updateTitle";
import { MdOutlineHome } from "react-icons/md";
import { FloatLabel } from "primereact/floatlabel";
import { useUser } from "../../contexts/UserContext";
import { Dialog } from "primereact/dialog";
const Login = () => {
  updateTitle("Chốt Sale - Đăng Nhập");
  const { statusLogin, setStatusLogin, newLogo } = useUser();
  const { t } = useTranslation();
  const { userLoggedIn, setUserLoggedIn, setRefeshs } = useAuth();
  const loginApi = API_URL + API_ROUTES.LOGIN_API;
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [passWord, setPassWord] = useState("");
  const [init, setInit] = useState(false);
  const [visible, setVisible] = useState(false);
  const handleExtend = () => {
    setVisible(false);
    navigate("/redue");
  };
  const footerContent = (
    <div className="d-flex gap-2 justify-content-end">
      <Button
        label="Hủy"
        onClick={() => setVisible(false)}
        className="button-red radius"
      />
      <Button
        className="button-blue radius"
        label="Gia hạn"
        onClick={handleExtend}
        autoFocus
      />
    </div>
  );
  const changeUsername = (event) => {
    setUserName(event.target.value);
  };
  const changePassWord = (event) => {
    setPassWord(event.target.value);
  };
  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const handleLogin = () => {
    axios
      .post(loginApi, {
        username: userName,
        password: passWord,
      })
      .then((response) => {
        if (response.status === 200 && response.data.status !== 400) {
          localStorage.setItem("access", response.data.access);
          localStorage.setItem("refresh", response.data.refresh);
          localStorage.removeItem("expired");
          setRefeshs(response.data.refresh);
          setUserLoggedIn(true);
          navigate("/account");
        } else if (response.data.status === 400) {
          setStatusLogin(!statusLogin);
          localStorage.setItem("expired", true);
        }
      })
      .catch((error) => {
        if (error.response.data.error === "Tài khoản của bạn đã hết hạn") {
          setVisible(true);
        }
        toast.error(
          error.response.data.error ||
            "Có lỗi xảy ra vui lòng liên hệ admin để hỗ trợ"
        );
      });
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };

  return (
    <div
      style={{ height: "100vh", color: "#912091e0" }}
      className="p-4  w-100 d-flex justify-content-center align-items-center background-register"
    >
      <ToastContainer />
      <div className="d-flex h-100 login-register-responsive">
        <Dialog
          header={
            <span className="header-mobile" style={{ fontSize: "22px" }}>
              TÀI KHOẢN CỦA BẠN ĐÃ HẾT HẠN
            </span>
          }
          visible={visible}
          style={{ width: "50vw" }}
          onHide={() => {
            if (!visible) return;
            setVisible(false);
          }}
          footer={footerContent}
        >
          <p style={{ lineHeight: "25px" }} className="mt-1">
            TÀI KHOẢN CỦA BẠN ĐÃ HẾT HẠN SỬ DỤNG. XIN VUI LÒNG NÂNG CẤP ĐỂ KHÔNG
            BỎ TRÔI KHÁCH HÀNG CỦA BẠN.
          </p>
        </Dialog>
        <div
          className="login-register-border-radius d-flex justify-content-center align-items-center flex-column w-100"
          style={{
            padding: "58px 42px",
            backgroundColor: "transparent",
            color: "purple",
            borderTopLeftRadius: "20px",
            borderBottomLeftRadius: "20px",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
            position: "relative",
          }}
        >
          <MdOutlineHome
            onClick={() => navigate("/")}
            size={40}
            className="text-light"
            style={{
              position: "absolute",
              top: "10px",
              left: "10px",
              cursor: "pointer",
            }}
          />
          <nav className="d-flex flex-column gap-2 align-items-center mb-4">
            <img
              style={{ width: "273px", borderRadius: "10%" }}
              src={`${API_URL}/${newLogo}`}
            />
          </nav>
          <nav className="d-flex flex-column gap-2  w-100 align-items-center">
            <h4
              style={{ fontWeight: "700" }}
              className="text-light text-center"
            >
              HỆ THỐNG MARKETING
            </h4>
            <h4
              style={{ fontWeight: "700" }}
              className="text-light text-center"
            >
              BÁN HÀNG TỰ ĐỘNG
            </h4>
          </nav>
        </div>
        <div
          className="login-register-border-radius d-flex justify-content-center align-items-center flex-column w-100"
          style={{
            padding: "58px 42px",
            backgroundColor: "white",
            color: "purple",
            border: "2px solid white",
            borderTopRightRadius: "20px",
            borderBottomRightRadius: "20px",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
          }}
        >
          <nav className="d-flex align-items-end w-100 gap-4">
            <h3 className="text-dark">ĐĂNG NHẬP</h3>
          </nav>
          <div className="d-flex justify-content-start mb-4 w-100">
            <nav
              style={{
                width: "60px",
                borderRadius: "21px",
                padding: "4px",
                background: "purple",
              }}
            ></nav>
          </div>
          <div className="w-100 d-flex flex-column gap-4 mt-4">
            <FloatLabel>
              <InputText
                id="username"
                className="w-100"
                value={userName}
                onChange={changeUsername}
                onKeyDown={handleKeyDown}
                style={{ fontSize: "1rem" }}
              />
              <label htmlFor="username" style={{ fontSize: "1rem" }}>
                Tài khoản đăng nhập
              </label>
            </FloatLabel>
            <div className="d-flex gap-2 w-100 justify-content-between">
              <div className="w-100">
                <FloatLabel>
                  <Password
                    toggleMask
                    feedback={t("")}
                    promptLabel={t("")}
                    id="username"
                    className="w-100 password-input-login"
                    value={passWord}
                    onChange={changePassWord}
                    onKeyDown={handleKeyDown}
                    style={{ fontSize: "1rem " }}
                  />
                  <label htmlFor="username" style={{ fontSize: "1rem" }}>
                    Mật khẩu
                  </label>
                </FloatLabel>
              </div>
            </div>
          </div>
          <div className="w-100 mt-4 d-flex flex-column gap-2 align-items-end">
            <Button
              onClick={handleLogin}
              className="w-100  radius"
              style={{
                background: "#054ddb",
                border: "1px solid #054ddb",
                padding: "0.5rem 0.75rem",
                color: "#fff",
              }}
              label="Đăng Nhập"
            />
            <div className="d-flex justify-content-between w-100">
              <a
                style={{ cursor: "pointer", color: "blue" }}
                onClick={() => navigate("/register")}
              >
                Đăng ký
              </a>
              <a
                style={{ cursor: "pointer", color: "blue" }}
                onClick={() => navigate("/quenmatkhau")}
              >
                Quên mật khẩu
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
