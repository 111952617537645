export const API_URL = "https://ntd.chotnhanh.vn";
export const WS_URL = "ntd.chotnhanh.vn";
export const API_ROUTES = {
  GET_TOKEN: "/api/token/",
  LOGIN_API: "/api/users/login",
  LOGIN_OUT: "/api/users/logout",
  GET_ACC_FB: "/api/account/",
  ADD_ACC_FB: "/api/account/create",
  DELETE_ACC_FB: "/api/account/delete",
  CHECK_ACC_FB: "/api/account/check-account",
  CHECK_PROXY_FB: "/api/account/check-proxy",
  CHECK_ACC_EDIT: "/api/account/edit",
  GET_PAGE_SHOW: "/api/page/show",
  GET_PAGE_CHECK: "/api/page/get",
  SET_VER_CHECK: "/api/page/ver",
  SET_VER_UNCHECK: "/api/page/unver",
  GET_PAGE_PRODUCT: "/api/product/",
  GET_CREATE_PRODUCT: "/api/product/create",
  GET_EDIT_PRODUCT: "/api/product/edit",
  GET_DELETE_PRODUCT: "/api/product/delete",
  GET_URL_THUMBNAIL: "/api/upload/thumbnail",
  UPLOAD_LINK_VIDEO: "/api/upload/file",
  POST_VIDEO_REELS: "/api/page/up-reel",
  RESULTS_REELS: "/api/page/up-reel/result",
  POST_VIDEO_FB: "/api/page/livestream/start",
  STOP_POST_VIDEO_FB: "/api/page/livestream/stop",
  GET_PROMOLINK: "/api/promo-link/",
  CREATE_PROMOLINK: "/api/promo-link/create",
  UPDATE_PROMOLINK: "/api/promo-link/update",
  SHOW_ALL_PAGE: "/api/page/show-all",
  CHANGE_PASS: "/api/users/change-password",
  ACCESS_ORDER: "/api/order/accept",
  DELETE_PROMOLINK: "/api/promo-link/delete",
  GET_FIRST_MESS: "/api/order/first-message/get",
  EDIT_FIRST_MESS: "/api/order/first-message/edit",
  GET_LAST_MESS: "/api/order/accept-order-message/get",
  EDIT_LAST_MESS: "/api/order/accept-order-message/edit",
  GET_ALL_ORDER: "/api/order/",
  DELETE_ORDER: "/api/order/delete",
  EDIT_ORDER: "/api/order/edit",
  SCAN_ORDER: "/api/order/scan-order",
  CREATE_ORDER: "/api/order/add",
  CREATE_ORDER_MESSAGER: "/api/order/add-from-message",
  STOP_SCAN_ORDER: "/api/order/stop-scan-order",
  CHAT_BOT_API: "/api/chatbot/message",
  CREATE_CHATBOT: "/api/chatbot/message/create",
  EDIT_CHATBOT: "/api/chatbot/message/edit",
  DELETE_CHATBOT: "/api/chatbot/message/delete",
  ACTIVE_CHATBOT: "/api/chatbot/message/active",
  ACTIVE_CHATBOT_PAGE: "/api/chatbot/message/page/active",
  ACTIVE_CHATBOT_ACCOUNT: "/api/chatbot/message/account/active",
  ACTIVE_CHATBOT_USER: "/api/chatbot/message/user/active",
  DELETE_VIDEO: "/api/upload/video/delete",
  GET_MESS_FB: "/api/message/get-conversations-fb",
  GET_MESS_SERVER: "/api/message/get-conversations",
  GET_DB_MESS: "/api/message/get-message",
  GET_FB_MESS: "/api/message/get-message",
  SEND_MESS: "/api/message/send-message",
  RESET_UNREAD: "/api/message/reset-unread-count",
  PING_API: "/api/message/ping",
  API_PING_LINK: "/api/promo-link/ping",
  SEND_ATTACHMENT: "/api/message/send-attachment",
  GET_LINK_FILE: "/api/upload/file",
  DELETE_FILE: "/api/upload/file/delete",
  GET_INFO_UERS: "/api/users/me",
  GET_FAST_REPLY: "/api/message/fast-reply/get",
  CREATE_FAST_REPLY: "/api/message/fast-reply/create",
  EDIT_FAST_REPLY: "/api/message/fast-reply/edit",
  CREATE_EMPLOYEE: "/api/users/create-employee",
  GET_LIST_EMPLOYEE: "/api/users/get-employees",
  START_AUTO_INBOX: "/api/autoinbox/start",
  RESULT_AUTO_INBOX: "/api/autoinbox/result",
  SAVE_CONTENT: "/api/autoinbox/save-script",
  GET_SAVE_CONTENT: "/api/autoinbox/get-script",
  STATUS_INBOX_CONTENT: "/api/autoinbox/status",
  GET_ALL_ACCOUNT: "/api/users/get-all-account",
  CREATE_ACCOUNT_MANAGER: "/api/users/create-manager",
  DELETE_ACCOUNT_MANAGER: "/api/users/delete-manager",
  EDIT_ACCOUNT_MANAGER: "/api/users/edit-manager",
  EDIT_ACCCOUNT_EMPLOYEE: "/api/users/edit-employee",
  DELETE_EMPLOYEE: "/api/users/delete-employee",
  STATUS_ORDER: "/api/order/status",
  STOP_AUTO_INBOX: "/api/autoinbox/stop",
  GAN_PAGE_EMPLOYEE: "/api/users/assign-page",
  CREATE_EDIT_RESOURCE: "/api/popup/resource/create-or-edit",
  GET_RESOURCE: "/api/popup/resource/get",
  DELETE_RESOURCE: "/api/popup/resource/delete",
  GET_PRODUCT_RESOURCE: "/api/popup/product-app/get",
  CREATE_PRODUCT_RESOURCE: "/api/popup/product-app/create-or-edit",
  DELETE_PRODUCT_RESOURCE: "/api/popup/product-app/delete",
  UPLOAD_FILE_SERVER: "/api/upload/server",
  LOGO_IMAGE: "/api/popup/logo/get",
  CREATE_LOGO: "/api/popup/logo/create-or-edit",
  CREATE_EDIT_HOME: "/api/popup/dashboard/create-or-edit",
  GET_ALL_COMMENTS: "/api/chatbot/comment",
  CREATE_COMMENTS_BOT: "/api/chatbot/comment/create-or-edit",
  DELETE_COMMENTS_BOT: "/api/chatbot/comment/delete",
  CHATBOT_ACTIVE: "/api/chatbot/comment/active",
  GET_EXPRE: "/api/popup/expiration/get",
  CREATE_EXPIRED: "/api/popup/expiration/create-or-edit",
  GET_ALERT: "/api/popup/alert/get",
  CREATE_ALERT: "/api/popup/alert/create-or-edit",
  GET_ALL_TRAIN_BOT: "/api/chatbot/trainbot",
  DELETE_TRAIN_BOT: "/api/chatbot/trainbot/delete",
  CREATE_REGISTER: "/api/register/create",
  GET_WAITING_ACC: "/api/register/get",
  GET_DASHBOARD: "/api/popup/dashboard/get",
  GET_ALL_TUTORIAL: "/api/popup/tutorial/get",
  CREATE_EDIT_TUTORIAL: "/api/popup/tutorial/create-or-edit",
  DELETE_TUTORIAL: "/api/popup/tutorial/delete",
  GET_ALL_LANDING: "/api/popup/landing-page/get",
  EDIT_LANDING: "/api/popup/landing-page/create-or-edit",
  CREATE_EDIT_QUESTION: "/api/popup/regular-question/create-or-edit",
  GET_QUESTION: "/api/popup/regular-question/get",
  DELETE_QUESTION: "/api/popup/regular-question/delete",
  GET_DATA_POPUP_TERM: "/api/popup/term/get",
  GET_ACCOUNT_MESS: "/api/message/show-all-account-mess",
  LIST_CITIS: "/api/order/city",
  GET_DISTRICT: "/api/order/district",
  GET_WARD: "/api/order/ward",
  GET_POPUP_REGSTER: "/api/popup/register/get",
  CREATE_UPDATE_REGISTER: "/api/popup/register/create-or-edit",
  CREATE_POPUP_TERM: "/api/popup/term/create-or-edit",
  GET_ACCEPT_TERM: "/api/users/accept-terms",
  DELETE_FAST_MESS: "/api/message/fast-reply/delete",
  GET_ALL_LABLES: "/api/message/category/get",
  CREATE_LABELS: "/api/message/category/create-or-edit",
  DELETE_LABEL: "/api/message/category/delete",
  ADD_LABEL_CONVERSION: "/api/message/category/add",
  REMOVE_LABEL_CONVERSION: "/api/message/category/remove",
  REQUEST_RESET_PASS: "/api/users/reset-password/create",
  GET_INFOR_RESET_PASS: "/api/users/reset-password/get",
  ADMIN_RESET_PASS: "/api/users/reset-password/reset",
  DELETE_RESET_PASS: "/api/users/reset-password/delete",
  GET_AUTO_INBOX_MESS: "/api/message/message-to-category/get-script",
  SAVE_AUTO_INBOX_MESS: "/api/message/message-to-category/save-script",
  START_AUTO_INBOX_MESS: "/api/message/message-to-category/start",
  STOP_AUTO_INBOX_MESS: "/api/message/message-to-category/stop",
  STATUS_AUTO_INBOX_MESS: "/api/message/message-to-category/status",
  DELETE_ACCOUNT_REGISTER: "/api/register/delete",
  HIDEN_COMMENTS: "/api/chatbot/comment/hide",
  HIDEN_COMMENTS_LIVESTREAM: "/api/order/comment-order/hide",
  GET_TOKEN_ACCOUNT: "/api/users/token/get",
  GET_NEW_TOKEN_ACCOUNT: "/api/users/token/generate",
  GET_ALL_SYSTEM_DEBUG: "/api/debug/system/show",
  DELETE_SYSTEM_DEBUG: "/api/debug/system/delete",
  DELETE_ALL_SYSTEM_DEBUG: "/api/debug/system/delete-all",
  CONFIRM_SYSTEM_DEBUG: "/api/debug/system/confirm",
  EDIT_SYSTEM_DEBUG: "/api/debug/system/edit",
  SHOW_MANUAL_DEBUG: "/api/debug/manual/show",
  CREATE_MANUAL_DEBUG: "/api/debug/manual/create",
  TEST_CONFIRM_MANUAL_DEBUG: "/api/debug/manual/tester-confirm",
  DEV_CONFIRM_MANUAL_DEBUG: "/api/debug/manual/developer-confirm",
  GET_ALL_CATEGORY_BY_MESS: "/api/message/category/get-by-account",
  CHECK_ACCOUT_RESULTS: "/api/account/check-account/result",
  CHECK_PROXY_RESULTS: "/api/account/check-proxy/result",
  STATUS_MARKETING: "/api/marketing/category/result/stats",
  STATUS_FRIEND: "/api/friend/category/result/stats",
  STATUS_POST: "/api/posts/stats",
  GET_STATS_GROUP: "/api/group/category/result/stats",
  STATS_JOIN_GROUP: "/api/group/join/stats",
  DELETE_BY_LINK: "/api/upload/file/delete/url",
  RESULTS_SEND_MESS: "/api/message/send-message/result",
  RELOAD_CONVERSATIONS: "/api/message/reload-conversations",
  RESULTS_RELOAD_CONVERSATIONS: "/api/message/reload-conversations/result",
  GET_MAIL_REGISTER_SYSTEM: "/api/system/get",
  CHANGE_MAIL_REGISTER_SYSTEM: "/api/system/change-email-register",
  EDIT_MAIL_ORDER: "/api/users/mail-order/edit",
};
export const API_ROUTES_NTD = {
  GET_ALL_FRIEND: "/api/friend/",
  GET_LIST_FRIEND: "/api/friend/get",
  GET_RESULTS_DREIND: "/api/friend/get/result",
  GET_RESULTS_GROUP: "/api/group/get/result",
  ADD_FRIEND: "/api/friend/add",
  DELETE_FRIEND: "/api/friend/delete",
  CREATE_EDIT_CATEGORY_MARKETING: "/api/marketing/category/create-or-edit",
  GET_ALL_CATEGORY_MARKETING: "/api/marketing/category/get",
  DELETE_CATEGORY_MARKETING: "/api/marketing/category/delete",
  ADD_CATEGORY_IN_ACCOUNT: "/api/marketing/category/add",
  REMOVE_CATEGORY_IN_ACCOUNT: "/api/marketing/category/remove",
  START_CATEGORY_ACCOUNT_MKT: "/api/marketing/category/start",
  STOP_CATEGORY_ACCOUNT_MKT: "/api/marketing/category/stop",
  DELETE_RESULTS_MARKETING: "/api/marketing/result/delete",
  GET_ALL_RESULTS: "/api/marketing/result/get",
  CREATE_EDIT_CATEGORY_CLIENT: "/api/friend/category/create-or-edit",
  GET_ALL_CATEGORY_CLIENT: "/api/friend/category/get",
  DELETE_CATEGORY_CLIENT: "/api/friend/category/delete",
  ADD_CATEGORY_IN_CLIENT: "/api/friend/category/add",
  REMOVE_CATEGORY_IN_CLIENT: "/api/friend/category/remove",
  START_CATEGORY_ACCOUNT_CLIENT: "/api/friend/category/start",
  STOP_CATEGORY_ACCOUNT_CLIENT: "/api/friend/category/stop",
  GET_ALL_CLIENT: "/api/friend/category/result",
  DELETE_RESULTS_CLIENT: "/api/friend/category/result/delete",
  DELETE_FR_CLIENT: "/api/friend/delete",
  GET_ALL_ADD_FRIEND: "/api/friend/scan-result",
  GET_SCAN_ADD_FRIEND: "/api/friend/scan-friending",
  ACCEPT_ADD_FRIEND: "/api/friend/accept-invitation",
  ACCEPT_ADD_FRIEND_RESULT: "/api/friend/accept-invitation/result",
  REJECT_ADD_FRIEND_RESULT: "/api/friend/delete-invitation/result",
  DELETE_ADD_FRIEND: "/api/friend/delete-invitation",
  GET_ALL_GROUP: "/api/group/",
  GET_ALL_LIST_GROUP: "/api/group/get",
  DELETE_GROUP: "/api/group/leave",
  GET_ALL_CATEGORY_GROUP: "/api/group/category/get",
  CREATE_EDIT_CATEGORY_GROUP: "/api/group/category/create-or-edit",
  DELETE_CATEGORY_GROUP: "/api/group/category/delete",
  ADD_GROUP_TO_CATEGORY: "/api/group/category/add",
  REMOVE_CATEGORY_OF_GROUP: "/api/group/category/remove",
  GET_STATUS_CATEGORY_GROUP: "/api/group/category/status",
  START_GROUP_CATEGORY: "/api/group/category/start",
  STOP_GROUP_CATEGORY: "/api/group/category/stop",
  GET_RESULTS_GROUP_CATEGORY: "/api/group/category/result",
  DELETE_RESULTS_GROUP_CATEGORY: "/api/group/category/result/delete",
  JOIN_GROUP: "/api/group/join",
  GET_RESULTS_JOIN_GROUP: "/api/group/join/result",
  DELETE_RESULTS_JOIN_GROUP: "/api/group/join/delete",
  INVITE_JOIN_GROUP: "/api/group/invite",
  RESULTS_INVITE_JOIN_GROUP: "/api/group/invite/result",
  DELETE_RESULTS_INVITE: "/api/group/invite/delete",
  TOOlBOX_USER_SCAN: "/api/toolbox/user/scan",
  TOOLBOX_USER_RESULT: "/api/toolbox/user/result",
  TOOLBOX_REMOVE_USER: "/api/toolbox/user/remove",
  TOOlBOX_PAGE_SCAN: "/api/toolbox/page/scan",
  TOOLBOX_PAGE_RESULT: "/api/toolbox/page/result",
  TOOLBOX_REMOVE_PAGE: "/api/toolbox/page/remove",
  TOOlBOX_GROUP_SCAN: "/api/toolbox/group/scan",
  TOOLBOX_GROUP_RESULT: "/api/toolbox/group/result",
  TOOLBOX_REMOVE_GROUP: "/api/toolbox/group/remove",
  REMOVE_ALL_USER_TOOL_BOX: "/api/toolbox/user/remove/all",
  REMOVE_ALL_PAGE_TOOL_BOX: "/api/toolbox/page/remove/all",
  REMOVE_ALL_GROUP_TOOL_BOX: "/api/toolbox/group/remove/all",
  COPY_ALL_RESULTS_USER: "/api/toolbox/user/export",
  COPY_ALL_RESULTS_PAGE: "/api/toolbox/page/export",
  COPY_ALL_RESULTS_GROUP: "/api/toolbox/group/export",
  TOOL_BOX_SCAN_MEMBER: "/api/toolbox/member/scan",
  TOOL_BOX_RESULT_MEMBER: "/api/toolbox/member/result",
  TOOL_BOX_REMOVE_MEMBER: "/api/toolbox/member/remove",
  TOOL_BOX_REMOVEALL_MEMBER: "/api/toolbox/member/remove/all",
  TOOL_BOX_COPY_MEMBER: "/api/toolbox/member/export",
  TOOL_BOX_SCAN_FRIEND: "/api/toolbox/friend/scan",
  TOOL_BOX_RESULTS_FRIEND: "/api/toolbox/friend/result",
  TOOL_BOX_REMOVE_FRIEND: "/api/toolbox/friend/remove",
  TOOL_BOX_REMOVEALL_FRIEND: "/api/toolbox/friend/remove/all",
  COPY_ALL_RESULTS_FRIEND: "/api/toolbox/friend/export",
  ARTICLE_POST: {
    GET_ALL_CATEGORY: "/api/posts/post/category/get",
    CREATE_OR_EDIT: "/api/posts/post/category/create-or-edit",
    DELETE: "/api/posts/post/category/delete",
    ADD_ACCOUT_TO_CATE: "/api/posts/post/category/add",
    REMOVE_ACCOUT_TO_CATE: "/api/posts/post/category/remove",
    START: "/api/posts/post/category/start",
    STOP: "/api/posts/post/category/stop",
    RESULTS: "/api/posts/post/result/get",
    DELETE_RESULTS: "/api/posts/post/result/delete",
    COPY_CATE_POST: "/api/posts/post/category/copy",
  },
  ARTICLE_REELS: {
    GET_ALL_CATEGORY: "/api/posts/reel/category/get",
    CREATE_OR_EDIT: "/api/posts/reel/category/create-or-edit",
    DELETE: "/api/posts/reel/category/delete",
    ADD_ACCOUT_TO_CATE: "/api/posts/reel/category/add",
    REMOVE_ACCOUT_TO_CATE: "/api/posts/reel/category/remove",
    START: "/api/posts/reel/category/start",
    STOP: "/api/posts/reel/category/stop",
    RESULTS: "/api/posts/reel/result/get",
    DELETE_RESULTS: "/api/posts/reel/result/delete",
    COPY_CATE_REELS: "/api/posts/reel/category/copy",
  },
  ARTICLE_WATCH: {
    GET_ALL_CATEGORY: "/api/posts/watch/category/get",
    CREATE_OR_EDIT: "/api/posts/watch/category/create-or-edit",
    DELETE: "/api/posts/watch/category/delete",
    ADD_ACCOUT_TO_CATE: "/api/posts/watch/category/add",
    REMOVE_ACCOUT_TO_CATE: "/api/posts/watch/category/remove",
    START: "/api/posts/watch/category/start",
    STOP: "/api/watch/post/category/stop",
    RESULTS: "/api/posts/watch/result/get",
    DELETE_RESULTS: "/api/posts/watch/result/delete",
    COPY_CATE_WATCH: "/api/posts/watch/category/copy",
  },
  ARTICLE_REUP: {
    GET_ALL_CATEGORY: "/api/posts/reup/category/get",
    CREATE_OR_EDIT: "/api/posts/reup/category/create-or-edit",
    DELETE: "/api/posts/reup/category/delete",
    ADD_ACCOUT_TO_CATE: "/api/posts/reup/category/add",
    REMOVE_ACCOUT_TO_CATE: "/api/posts/reup/category/remove",
    START: "/api/posts/reup/category/start",
    STOP: "/api/posts/reup/category/stop",
    RESULTS: "/api/posts/reup/result/get",
    DELETE_RESULTS: "/api/posts/reup/result/delete",
    COPY_CATE_REUP: "/api/posts/reup/category/copy",
  },
  ARTICLE_SENDING: {
    GET_ALL_CATEGORY: "/api/posts/seeding/category/get",
    CREATE_OR_EDIT: "/api/posts/seeding/category/create-or-edit",
    DELETE: "/api/posts/seeding/category/delete",
    ADD_ACCOUT_TO_CATE: "/api/posts/seeding/category/add",
    REMOVE_ACCOUT_TO_CATE: "/api/posts/seeding/category/remove",
    START: "/api/posts/seeding/category/start",
    STOP: "/api/posts/seeding/category/stop",
    RESULTS: "/api/posts/seeding/result/get",
    DELETE_RESULTS: "/api/posts/seeding/result/delete",
    COPY_CATE_SENDING: "/api/posts/seeding/category/copy",
  },
  SHOW_ALL_COMMENT_MESS: "/api/comment/show",
  RESULTS_OF_COMMENT: "/api/comment/result",
  REPLY_COMMENTS_ONMESS: "/api/comment/reply",
  ACTIVE_COMMENTS_IN_MESS: "/api/comment/active",
  GET_AVATAR_ERROR: "/api/message/get-avatar",
  SEND_MESS_MANUAL: "/api/message/send-message-anonymous",
  GET_TASKID_MANUAL: "/api/message/send-message-anonymous/result",
  RESULTS_DELETE_GROUP: "/api/group/get/result",
  RESULTS_DELETE_CLIENT: "/api/friend/delete/result",
  SHOW_SET_COUNT: "/api/comment/show-set-count",
  COPY_BOT: "/api/chatbot/message/copy",
  COPY_CATEGORY_FRIEND: "/api/friend/category/copy",
  COPY_CATEGORY_GROUP: "/api/group/category/copy",
  COPY_CATEGORY_MARKETING: "/api/marketing/category/copy",
  GET_CATE_MARKETPLACE: "/api/marketplace/get-category",
  POST_ID_GET_CATE_MARKETPLACE: "/api/marketplace/get-category-detail",
  GET_LOCATION_MARKETPLACE: "/api/marketplace/get-location",
  POST_MARKETPLACE: "/api/marketplace/category/create-or-edit",
  GET_INFOR_COMMENT: "/api/comment/get-info-comment",
  GET_CATEGORY_MARKETPLACE: "/api/marketplace/category/get",
  START_MARKETPLACE: "/api/marketplace/category/start",
  STOP_MARKETPLACE: "/api/marketplace/category/stop",
  REMOVE_CATEGORY_MARKETPLACE: "/api/marketplace/category/remove",
  DELETE_CATEGORY_MARKETPLACE: "/api/marketplace/category/delete",
  ADD_CATE_ACC_MARKETPLACE: "/api/marketplace/category/add",
  DELETE_RESULTS_MARKETPLACE: "/api/marketplace/result/delete",
  GET_RESULTS_MARKETPLACE: "/api/marketplace/result/get",
  COPY_CATEGORY_MARKETPLACE: "/api/marketplace/category/copy",
  SHOW_LIST_POST_MARKET: "/api/marketplace/show-post",
  DELETE_POST_MARKET: "/api/marketplace/delete-post",
  RESULTS_DELETE_POST_MARKET: "/api/marketplace/delete-post/result",
  GET_POST_MARKET: "/api/marketplace/get-post",
  RESULTS_GET_POST_MARKET: "/api/marketplace/result-get-post",
  GET_CHATBOT_MARKET: "/api/chatbot/marketplace",
  CREATE_OR_EDIT_CHATBOT_MARKET: "/api/chatbot/marketplace/create",
  DELETE_CHATBOT_MARKET: "/api/chatbot/marketplace/delete",
  ACTIVE_CHATBOT_MARKET: "/api/chatbot/marketplace/active",
  COPY_CHATBOT_MARKET: "/api/chatbot/marketplace/copy",
  GET_LINK_MARKET: "/api/marketplace/get-post-detail",
  RESULTS_GET_LINK_MARKET: "/api/marketplace/get-post-detail/result",
  GET_LIST_FILTER_MARKET: "/api/marketplace/get-list-post",
  RESULTS_LIST_FILTER_MARKET: "/api/marketplace/get-list-post/result",
  RESULTS_START_MARKETPLACE: "/api/marketplace/category/start/result",
  RESULTS_ADD_FREIND: "/api/friend/add/result",
  GET_STATIC_MARKET: "/api/marketplace/get-statistic",
  GET_RESULTS_STATIC_MARKET: "/api/marketplace/get-statistic/result",
  POST_DATA_EXTEND: "/api/register/extend",
  GET_QR: "/api/register/get-qr"
};
