import { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { locale } from "primereact/api";
import {
  toggleSelectAll,
  headerCheckbox,
  bodyCheckbox,
  rowClassName,
  onRowClick,
} from "../../const/checkboxPrimeReactjs";
import { API_URL, API_ROUTES_NTD } from "../../api";
import axios from "axios";
import { useHeaders } from "../../const/headers";
import { Paginator } from "primereact/paginator";
import { getAxiosResults } from "../../const/getAxios";
export const ResultsGroup = ({
  refresh,
  setRefresh,
  toast,
  selecGroup,
  setSelecGroup,
  setResultsGroup,
  resultsGroup,
  setTotalPage,
  totalPage,
  setGetTotal,
  getTotal,
  setRows,
  rows,
}) => {
  locale("vi");
  const headers = useHeaders();
  const resultGroup = API_URL + API_ROUTES_NTD.TOOLBOX_GROUP_RESULT;
  const remoteResultsGroup = API_URL + API_ROUTES_NTD.TOOLBOX_REMOVE_GROUP;
  const [first, setFirst] = useState(0);
  const rowsPerPageOptions = [100, 300, 500, 1000, 2000];
  const onPageChange = (event) => {
    setTotalPage(event.page);
    setFirst(event.first);
    setRows(event.rows);
  };
  useEffect(() => {
    getAxiosResults(
      resultGroup,
      setResultsGroup,
      setGetTotal,
      rows,
      totalPage + 1
    );
  }, [totalPage, rows, refresh]);
  const deleteResults = async (rowData) => {
    const confirm = window.confirm("Bạn chắc chắn muốn xoá không");
    if (confirm) {
      const bodyUser = { id_scan_groups: [rowData?.id] };
      try {
        const res = await axios.post(remoteResultsGroup, bodyUser, headers);
        setRefresh(!refresh);
        toast.success("Xoá thành công");
      } catch (err) {
        toast.error("Xoá thất bại");
      }
    }
  };
  const getDataAllFr = () => {};
  const nameAndAvt = (rowData) => {
    return (
      <div className="d-flex gap-2 align-items-center">
        <p>{rowData?.name}</p>
      </div>
    );
  };
  const linkGroup = (rowData) => {
    const link = `https://www.facebook.com/${rowData.UID}`;
    return (
      <a href={link} target="_blank" rel="noopener noreferrer">
        {link}
      </a>
    );
  };
  const deleteButton = (rowData) => {
    return (
      <>
        <Button
          className="button-red radius"
          style={{ padding: "6px 8px" }}
          onClick={() => deleteResults(rowData)}
          severity="danger"
        >
          Xoá
        </Button>
      </>
    );
  };
  const indexTemplate = (rowData, rowIndex) => {
    return <>{rowIndex.rowIndex + 1 + rows * totalPage}</>;
  };
  return (
    <div className="d-flex flex-column h-100 w-100">
      <DataTable
        size="small"
        value={resultsGroup}
        className="small-font-table dataTableWithFixedTool dataTableWithHoverEffect datatableoverflow"
        tableStyle={{ minWidth: "100%" }}
        rowClassName={(rowData) => rowClassName(rowData, selecGroup)}
        onRowClick={(e) => onRowClick(e.data, setSelecGroup, selecGroup)}
      >
        <Column
          header={headerCheckbox(
            selecGroup,
            resultsGroup,
            toggleSelectAll,
            setSelecGroup
          )}
          body={(rowData) =>
            bodyCheckbox(rowData, selecGroup, setSelecGroup, getDataAllFr)
          }
        />
        <Column body={indexTemplate} field="" header="STT"></Column>
        <Column field="UID" header="UID nhóm" sortable></Column>
        <Column
          body={(rowData) => nameAndAvt(rowData)}
          field="name"
          header="Tên nhóm"
          sortable
        ></Column>
        <Column field="member" header="Số lượng thành viên" sortable></Column>
        <Column field="status" header="Trạng thái" sortable></Column>
        <Column
          field="posts_per_day"
          header="Số bài đăng trên ngày"
          sortable
        ></Column>
        <Column body={linkGroup} header="Link nhóm"></Column>

        <Column field="keyword" header="Từ khóa" sortable></Column>
        <Column
          body={(rowData) => deleteButton(rowData)}
          header="Tuỳ chọn"
        ></Column>
      </DataTable>
      <Paginator
        className="pagination-markerting"
        first={first}
        rows={rows}
        totalRecords={getTotal?.count}
        onPageChange={onPageChange}
        rowsPerPageOptions={rowsPerPageOptions}
      />
    </div>
  );
};
