import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { API_URL, API_ROUTES_NTD } from "../../../api";
import axios from "axios";
import { useHeaders } from "../../../const/headers";
import { Button } from "primereact/button";
import { getAxiosMarket } from "../../../const/getAxios";
import { Paginator } from "primereact/paginator";
import "../marketing.css";
import { TiTick } from "react-icons/ti";
import { Checkbox } from "primereact/checkbox";
import { RiExpandDiagonal2Line } from "react-icons/ri";
export const ModalManageScriptClient = ({
  setManagerScript,
  managerScript,
  scriptClient,
  setRefresh,
  refresh,
  scanListFr,
  dataFb,
  statusRun,
  rows,
  setRows,
  setTotalPage,
  totalPage,
  setAllFriend,
  allFriend,
  setGetTotal,
  getTotal,
  toast,
  itemCate,
}) => {
  const headers = useHeaders();
  const deleteCate = API_URL + API_ROUTES_NTD.REMOVE_CATEGORY_IN_CLIENT;
  const getAllFr = API_URL + API_ROUTES_NTD.GET_ALL_FRIEND;
  const addCateClient = API_URL + API_ROUTES_NTD.ADD_CATEGORY_IN_CLIENT;
  const deleteFrClient = API_URL + API_ROUTES_NTD.DELETE_FR_CLIENT;
  const resultDele = API_URL + API_ROUTES_NTD.RESULTS_DELETE_CLIENT;
  const [itemAccout, setItemAccount] = useState([]);
  const [first, setFirst] = useState(0);
  const [listFr, setListFr] = useState([]);
  const rowsPerPageOptions = [100, 300, 500, 1000, 2000];
  const [imageErr, setImageErr] = useState([]);
  const [checked, setChecked] = useState(false);
  const onPageChange = (event) => {
    setTotalPage(event.page);
    setFirst(event.first);
    setRows(event.rows);
  };
  const filterAccountCheckpoit = dataFb?.filter(
    (item) => item?.checkpoint === false && item.status_proxy === true
  );
  const [deleGroup, setDeleGroup] = useState("");
  const [getTask, setGetTask] = useState([]);
  const getRsSendManual = async (taskid) => {
    const body = {
      id_task: taskid.id_task,
    };
    try {
      const res = await axios.post(resultDele, body, headers);
      if (res.data.status === "SUCCESS") {
        setRefresh(!refresh);
        toast.success("Xoá bạn thành công");
      }
      setDeleGroup(res.data.status);
    } catch (err) {
      setDeleGroup(err.response.data.status);
      toast.error("Xoá bạn thất bại");
    }
  };
  const removeFrClient = async (item) => {
    const confirm = window.confirm(
      `Bạn chắc chắn muốn xoá tài khoản ${item?.name}`
    );
    if (confirm) {
      const body = {
        id_account: itemAccout.id,
        id_friend: item?.id,
      };
      try {
        const res = await axios.post(deleteFrClient, body, headers);
        setGetTask(res.data);
        getRsSendManual(res.data);
        toast.success("Đang thực hiện xoá bạn");
      } catch (err) {}
    }
  };
  useEffect(() => {
    if (getTask.id_task) {
      const interval = setInterval(() => {
        getRsSendManual(getTask);
      }, 3000);
      if (deleGroup === "SUCCESS" || deleGroup === "FAILURE") {
        clearInterval(interval);
      }
      return () => clearInterval(interval);
    }
  }, [deleGroup, getTask]);
  const addCateInClient = async () => {
    if (listFr?.length > 0) {
      const body = {
        id_category: itemCate.id,
        id_friends: listFr?.map((item) => item.id),
      };
      try {
        await axios.post(addCateClient, body, headers);
        toast.success("Thêm kịch bản thành công");
        setRefresh(!refresh);
        setListFr([]);
      } catch (err) {}
    }
  };
  useEffect(() => {
    if (itemAccout.id) {
      getAxiosMarket(
        getAllFr,
        setAllFriend,
        setGetTotal,
        rows,
        totalPage + 1,
        itemAccout.id
      );
    }
    setChecked(false);
    setImageErr([]);
  }, [refresh, itemAccout, totalPage, rows]);
  const handleRemoveChip = async () => {
    const body = {
      id_friends: listFr?.map((item) => item.id),
      id_category: itemCate?.id,
    };
    try {
      const res = await axios.post(deleteCate, body, headers);
      setRefresh(!refresh);
      setListFr([]);
    } catch (err) {}
  };
  const handleItemClick = (item) => {
    if (listFr.includes(item)) {
      setListFr(listFr.filter((i) => i !== item));
    } else {
      setListFr([...listFr, item]);
    }
  };
  useEffect(() => {
    checked ? setListFr(allFriend) : setListFr([]);
  }, [checked]);
  const handleImageError = async (item) => {
    setImageErr((pre) => [...pre, item.id]);
  };
  return (
    <Dialog
      header="Quản lý kịch bản"
      visible={managerScript}
      style={{ position: "relative", width: "40vw" }}
      onHide={() => setManagerScript(false)}
      className="overFlowJoinGr"
      footer={
        <div className="d-flex justify-content-end gap-2 mt-2">
          <Button
            style={{
              width: "max-content",
              fontSize: "12px",
            }}
            disabled={statusRun}
            onClick={() => addCateInClient()}
            className="mr-0 button-blue d-flex justify-content-center"
          >
            Gán kịch bản
          </Button>
          <Button
            style={{
              width: "max-content",
              fontSize: "12px",
            }}
            disabled={statusRun}
            onClick={() => handleRemoveChip()}
            className="mr-0 button-red d-flex justify-content-center"
          >
            Bỏ gán
          </Button>
          <RiExpandDiagonal2Line
            size={20}
            style={{
              position: "absolute",
              right: "0",
              bottom: "0",
            }}
          />
        </div>
      }
    >
      <div className="d-flex flex-column" style={{ height: "75vh" }}>
        <div>
          <Button
            style={{
              width: "max-content",
              fontSize: "12px",
            }}
            disabled={statusRun}
            onClick={() => scanListFr(itemAccout)}
            className="button-blue d-flex justify-content-center"
          >
            Quét danh sách bạn bè
          </Button>
        </div>
        <div
          className="d-flex w-100 overflow-auto mt-2 mb-2"
          style={{ border: "1px solid", height: "142px" }}
        >
          {filterAccountCheckpoit?.map((item, index) => (
            <div
              className={`flex flex-column p-2 align-items-center gap-2 w-100`}
              style={{ cursor: "pointer" }}
              onClick={() => setItemAccount(item)}
            >
              <img
                style={{
                  width: "36px",
                  borderRadius: "50%",
                  background: itemAccout.id === item.id ? "#0084ff" : "#cdc4c5",
                  padding: itemAccout.id === item.id ? "8px" : "",
                }}
                src={item.avatar}
                alt={item.name}
              />
              <p style={{ width: "max-content", fontSize: "12px" }}>
                {item.name}
              </p>
            </div>
          ))}
        </div>
        <div
          className="d-flex flex-column h-100 overflow-auto"
          style={{ border: "1px solid" }}
        >
          <div className="d-flex gap-2 align-items-center">
            <p style={{ fontWeight: "700", padding: "0.5rem" }}>
              Danh sách bạn bè
            </p>
            <Checkbox
              onChange={(e) => setChecked(e.checked)}
              checked={checked}
            ></Checkbox>
            <p style={{ fontSize: "12px" }}>Chọn tất cả</p>
          </div>
          <div className="d-flex flex-column h-100 overflow-auto custom-scrollbar">
            {allFriend?.map((item, index) => (
              <div
                className={`flex p-2 justify-content-between align-items-center gap-2 w-100 ${
                  listFr.includes(item) ? "selected-item" : ""
                }`}
                style={{ cursor: "pointer" }}
                onClick={() => handleItemClick(item)}
              >
                <nav className="d-flex gap-2 align-items-center">
                  <p
                    style={{
                      width: "max-content",
                      fontSize: "12px",
                    }}
                  >
                    {index + 1}
                    {"/ "}
                  </p>
                  <img
                    style={{
                      width: "36px",
                      borderRadius: "50%",
                      background:
                        itemAccout.id === item.id ? "#0084ff" : "#cdc4c5",
                      padding: itemAccout.id === item.id ? "8px" : "",
                    }}
                    src={
                      imageErr.includes(item.id)
                        ? `${API_URL}/media/z5694199119434_7e0d5e3def934cc09dc755769ec27b0b.jpg`
                        : item.avatar
                    }
                    onError={(event) => handleImageError(event, item)}
                  />
                  <p
                    style={{
                      width: "max-content",
                      fontSize: "12px",
                    }}
                  >
                    {item.UID}
                  </p>
                  <p
                    style={{
                      width: "max-content",
                      fontSize: "12px",
                    }}
                  >
                    {item.name}
                  </p>
                </nav>
                <nav className="d-flex gap-2 align-items-center">
                  {item?.category_friends.length !== 0 &&
                    item?.category_friends?.some(
                      (item) => item.id === itemCate.id
                    ) && (
                      <p style={{ color: "blue", fontSize: "12px" }}>
                        Đã được gán
                        <TiTick />
                      </p>
                    )}
                  <Button
                    style={{
                      width: "max-content",
                      fontSize: "12px",
                      borderRadius: "5px",
                    }}
                    onClick={() => removeFrClient(item)}
                    className="mr-0 button-red d-flex justify-content-center"
                  >
                    Xoá khách hàng
                  </Button>
                </nav>
              </div>
            ))}
          </div>
          <Paginator
            className="pagination-markerting"
            first={first}
            rows={rows}
            totalRecords={getTotal?.count}
            onPageChange={onPageChange}
            rowsPerPageOptions={rowsPerPageOptions}
          />
        </div>
      </div>
    </Dialog>
  );
};
