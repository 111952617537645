import { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import {
  toggleSelectAll,
  headerCheckbox,
  bodyCheckbox,
  rowClassName,
  onRowClick,
} from "../../../../const/checkboxPrimeReactjs";
import { EditScriptWatch } from "./EditScriptWatch";
import { API_URL, API_ROUTES_NTD } from "../../../../api";
import { useHeaders } from "../../../../const/headers";
import axios from "axios";
import { statusRunTools } from "../../../../const/updateTitle";
export const ListScriptArticleWatch = ({
  allCate,
  setRefresh,
  refresh,
  setListMktCate,
  listMktCate,
  setOpenManager,
  setItemCate,
  handleCopyCate,
}) => {
  const deleteScript = API_URL + API_ROUTES_NTD.ARTICLE_WATCH.DELETE;
  const headers = useHeaders();
  const [editCate, setEditCate] = useState([]);
  const [modalEdit, setModalEdit] = useState(false);
  const getDataAllFr = () => {};
  const handleDeleteScript = async (item) => {
    const confirm = window.confirm("Bạn chắc chắn muốn xoá !");
    if (confirm) {
      const body = { id_category: item.id };
      try {
        const res = await axios.post(deleteScript, body, headers);
        setRefresh(!refresh);
      } catch (err) {}
    }
  };
  const listAccount = (rowData) => {
    return (
      <div className="w-100 d-flex flex-wrap gap-2 align-items-center">
        <p>
          {rowData?.account?.length === 0
            ? "Chưa có tài khoản nào được gán"
            : `Có ${rowData?.account?.length} tài khoản được gán`}
        </p>
      </div>
    );
  };
  const optionAcc = (rowData) => {
    return (
      <div className="d-flex gap-2">
        <Button
          className="button-blue radius"
          onClick={() => {
            setOpenManager(true);
            setItemCate(rowData);
          }}
          style={{ padding: "6px 8px" }}
        >
          Quản lý kịch bản
        </Button>
        <Button
          className="button-blue radius"
          onClick={() => {
            handleCopyCate(rowData);
          }}
          style={{ padding: "6px 8px" }}
        >
          Sao chép kịch bản
        </Button>
        <Button
          className="button-blue radius"
          onClick={() => {
            setEditCate(rowData);
            setModalEdit(true);
          }}
          style={{ padding: "6px 8px" }}
        >
          Sửa
        </Button>
        <Button
          className="button-red radius"
          severity="danger"
          onClick={() => handleDeleteScript(rowData)}
          style={{ padding: "6px 8px" }}
        >
          Xoá
        </Button>
      </div>
    );
  };
  const indexTemplate = (rowData, rowIndex) => {
    return <>{rowIndex.rowIndex + 1}</>;
  };
  return (
    <div className=" h-100 " style={{ border: "1px solid", width: "100%" }}>
      <DataTable
        size="small"
        className="small-font-table dataTableWithFixedHeader dataTableWithHoverEffect datatableoverflow"
        tableStyle={{ width: "100%" }}
        value={allCate}
        rowClassName={(rowData) => rowClassName(rowData, listMktCate)}
        onRowClick={(e) => onRowClick(e.data, setListMktCate, listMktCate)}
      >
        <Column
          header={headerCheckbox(
            listMktCate,
            allCate,
            toggleSelectAll,
            setListMktCate
          )}
          body={(rowData) =>
            bodyCheckbox(rowData, listMktCate, setListMktCate, getDataAllFr)
          }
        />
        <Column body={indexTemplate} field="UID" header="STT"></Column>
        <Column field="name" sortable header="Tên Kịch bản"></Column>
        <Column
          field="name"
          body={(rowData) => listAccount(rowData)}
          header="Tài khoản được gán"
        ></Column>
        <Column
          field=""
          body={(rowData) => statusRunTools(rowData)}
          sortable
          header="Trạng thái"
        ></Column>
        <Column
          body={(rowData) => optionAcc(rowData)}
          header="Tuỳ chọn"
          className="options-category"
        ></Column>
      </DataTable>
      <EditScriptWatch
        setRefresh={setRefresh}
        refresh={refresh}
        editCate={editCate}
        modalEdit={modalEdit}
        setModalEdit={setModalEdit}
      />
    </div>
  );
};
