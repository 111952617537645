import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useAuth } from "../../../AuthContext";
import { useUser } from "../../../contexts/UserContext";
import { API_URL } from "../../../api";
export default function ModalRegister() {
  const { modalRe, setModalRe, register } = useUser();
  return (
    <div style={{ border: "0" }} className=" flex justify-content-center">
      <Dialog
        header="Thông báo"
        visible={modalRe}
        style={{ width: "50vw" }}
        onHide={() => setModalRe(false)}
      >
        <div className="d-flex w-100 align-items-center gap-2">
          <img
            style={{ width: "30%" }}
            src={`
          ${API_URL}/${register[0]?.image}`}
          />
          <h4
            style={{ wordWrap: "break-word", width: "70%", marginLeft: "20px" }}
            dangerouslySetInnerHTML={{ __html: register[0]?.content }}
          />
        </div>
      </Dialog>
    </div>
  );
}
