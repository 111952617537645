import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { useHeaders } from "../../../const/headers";
import { API_URL, API_ROUTES } from "../../../api"; // Replace 'path/to/headers' with the actual path to the module containing the 'useHeaders' function.
import axios from "axios";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";

const FilterSep = ({
  setVisible2,
  visible2,
  setRefresh,
  refresh,
  sortSep,
  setSortSep,
}) => {
  const headers = useHeaders();
  const getAllAcc = API_URL + API_ROUTES.GET_ALL_ACCOUNT;
  const [selectPermission, setSelectPermission] = useState(null);
  const permission = [
    { name: "Quản trị viên", value: "is_admin" },
    { name: "Khách hàng", value: "is_manager" },
    { name: "Lập trình viên", value: "is_developer" },
    { name: "Chăm sóc khách hàng", value: "is_saler" },
    { name: "Tất cả", value: "all" },
  ];
  const [selectStatus, setSelectStatus] = useState(null);
  const status = [
    { name: "Online", value: "true" },
    { name: "Offline", value: "false" },
    { name: "Tất cả", value: "all" },
  ];
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [enableDate, setEnableDate] = useState(false);

  const handleSubmit = async () => {
    let url = `${getAllAcc}`;
    const queryParams = [];
    if (selectPermission !== "all") {
      queryParams.push(`permission=${selectPermission}`);
    }

    if (selectStatus !== null && selectStatus !== "all") {
      queryParams.push(`is_online=${selectStatus}`);
    }
    if (enableDate && startDate && endDate) {
      const adjustedStartDate = new Date(
        startDate.getTime() - startDate.getTimezoneOffset() * 60000
      )
        .toISOString()
        .split("T")[0];
      const adjustedEndDate = new Date(
        endDate.getTime() - endDate.getTimezoneOffset() * 60000
      )
        .toISOString()
        .split("T")[0];
      queryParams.push(
        `start_date=${adjustedStartDate}&?end_date=${adjustedEndDate}`
      );
    }
    if (queryParams.length > 0) {
      url += `?${queryParams.join("&")}`;
    }
    try {
      const res = await axios.get(url, headers);
      setSortSep(res.data);
      setVisible2(false);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div>
      <Dialog
        header="Lọc"
        visible={visible2}
        style={{ width: "15vw%" }}
        onHide={() => {
          if (!visible2) return;
          setVisible2(false);
        }}
      >
        <div className="d-flex flex-column gap-2 w-100">
          <div className="d-flex flex-column justify-content-evenly gap-2">
            <div className="d-flex flex-column gap-1">
              <label htmlFor="permission-dropdown">Quyền</label>
              <Dropdown
                value={selectPermission}
                onChange={(e) => setSelectPermission(e.value)}
                options={permission}
                optionLabel="name"
                placeholder=""
                className="w-full md:w-14rem"
              />
            </div>
            <div className="d-flex flex-column gap-1">
              <label htmlFor="status-dropdown">Trạng thái</label>
              <Dropdown
                value={selectStatus}
                onChange={(e) => setSelectStatus(e.value)}
                options={status}
                optionLabel="name"
                placeholder=""
                className="w-full md:w-14rem"
              />
            </div>
          </div>
          <div className="d-flex flex-column justify-content-evenly gap-2">
            <div className="d-flex flex-column gap-1">
              <div className="d-flex align-items-center gap-1 mb-2 mt-2">
                <Checkbox
                  inputId="cbStartDate"
                  checked={enableDate}
                  onChange={(e) => setEnableDate(e.checked)}
                />
                <label htmlFor="cbStartDate" className="mb-0">
                  Lọc theo ngày tạo
                </label>
              </div>
              <label htmlFor="start-date-calendar">Ngày bắt đầu</label>
              <Calendar
                locale="vi"
                value={startDate}
                onChange={(e) => setStartDate(e.value)}
                placeholder=""
                className="w-full md:w-14rem"
              />
            </div>
            <div className="d-flex flex-column gap-1">
              <label htmlFor="end-date-calendar">Ngày kết thúc</label>
              <Calendar
                locale="vi"
                value={endDate}
                onChange={(e) => setEndDate(e.value)}
                placeholder=""
                className="w-full md:w-14rem"
              />
            </div>
          </div>
          <div className=" d-flex justify-content-end">
            <Button
              style={{ borderRadius: "4px" }}
              onClick={handleSubmit}
              label="Xác nhận"
              className="button-blue radius"
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default FilterSep;
