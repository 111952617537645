import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { MdAccountCircle } from "react-icons/md";
import { MdOutlinePassword } from "react-icons/md";
import { Calendar } from "primereact/calendar";
import { FaCalendarAlt } from "react-icons/fa";
import { Button } from "primereact/button";
import moment from "moment-timezone";
import axios from "axios";
import { useHeaders } from "../../../const/headers";
import { API_URL, API_ROUTES } from "../../../api";
import { useUser } from "../../../contexts/UserContext";
import { Password } from "primereact/password";
import { useTranslation } from "react-i18next";
import { FaPhoneAlt } from "react-icons/fa";
import { MdDriveFileRenameOutline } from "react-icons/md";
import { MdOutlineTimeline } from "react-icons/md";
import { Dropdown } from "primereact/dropdown";
import { FaUserGroup } from "react-icons/fa6";

export const ManageSep = ({
  setVisible,
  visible,
  setRefresh,
  refresh,
  listEmpl,
}) => {
  const currentDate = moment().tz("Asia/Ho_Chi_Minh").toDate();
  const { userInfo, setUserInfo } = useUser();
  const headers = useHeaders();
  const [date, setDate] = useState(null);
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [fullName, setFullName] = useState("");
  const [limitLive, setLimitLive] = useState("");
  const [limitEmp, setLimitEmp] = useState("");
  const [linkFb, setLinkFb] = useState("");
  const [mail, setMail] = useState("");
  const [permission, setPermission] = useState("");
  const [phone, setPhone] = useState("");
  const [numPage, setNumPage] = useState("");
  const createAcc = API_URL + API_ROUTES.CREATE_ACCOUNT_MANAGER;
  const { t } = useTranslation();
  const onChangePass = (e) => {
    setPassword(e.target.value);
  };
  const onChangePage = (e) => {
    setNumPage(e.target.value);
  };
  const onChangeMail = (e) => {
    setMail(e.target.value);
  };

  const onChangeFb = (e) => {
    setLinkFb(e.target.value);
  };
  const onChangeUser = (e) => {
    setUsername(e.target.value);
  };
  const onChangeFullName = (e) => {
    setFullName(e.target.value);
  };
  const onChangePhone = (e) => {
    setPhone(e.target.value);
  };
  const onChangeLimit = (e) => {
    setLimitLive(e.target.value);
  };
  const onChangeLimitEmp = (e) => {
    setLimitEmp(e.target.value);
  };
  const styles = {
    mb0ml2: {
      width: "205px",
      justifyContent: "flex-start",
    },
  };
  var startTime = new Date();
  var endTime = new Date("2025-12-31T23:59:59");
  var timeDifference = endTime.getTime() - startTime.getTime();
  var newDate = new Date(startTime.getTime() + timeDifference);
  var formattedDate =
    newDate.toISOString().split("T")[0] +
    " " +
    newDate.toTimeString().split(" ")[0];
  const permissionsList = [
    { name: "Quản trị viên", value: "is_admin" },
    { name: "Khách hàng", value: "is_manager" },
    { name: "Lập trình viên", value: "is_developer" },
    { name: "Chăm sóc khách hàng", value: "is_saler" },
  ];
  const addEmployee = async () => {
    const body = {
      facebook_link: linkFb,
      username: username,
      fullname: fullName,
      password: password,
      phone_number: phone,
      employee_limit: limitEmp,
      expiration_date: formattedDate,
      mail: mail,
      account_limt: numPage,
      permission: permission,
    };
    try {
      const response = await axios.post(createAcc, body, headers);
      setRefresh(!refresh);
      setVisible(false);
    } catch (err) {}
    // }
  };
  const selectedPermissionTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <div>{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };
  return (
    <div className="card flex justify-content-center">
      <Dialog
        header="Thông tin tài khoản"
        visible={visible}
        style={{ width: "50vw" }}
        onHide={() => setVisible(false)}
      >
        <div
          className="card flex flex-column flex-wrap md:flex-row gap-3"
          style={{ border: "0" }}
        >
          <div className="p-inputgroup ">
            <span className="p-inputgroup-addon" style={styles.mb0ml2}>
              <MdAccountCircle />
              <p className="mb-0 ml-2">Tên đăng nhập</p>
            </span>
            <InputText
              onChange={(e) => onChangeUser(e)}
              placeholder="Tên đăng nhập"
            />
          </div>
          <div className="p-inputgroup ">
            <span className="p-inputgroup-addon" style={styles.mb0ml2}>
              <MdOutlinePassword />
              <p className="mb-0 ml-2">Mật khẩu</p>
            </span>
            <Password
              toggleMask
              feedback={t("")}
              style={{ fontSize: "1rem" }}
              promptLabel={t("")}
              onChange={(e) => onChangePass(e)}
              placeholder="Mật khẩu đăng nhập"
            />
          </div>
          <div className="p-inputgroup ">
            <span className="p-inputgroup-addon" style={styles.mb0ml2}>
              <MdDriveFileRenameOutline />
              <p className="mb-0 ml-2">Họ tên</p>
            </span>
            <InputText
              onChange={(e) => onChangeFullName(e)}
              placeholder="Họ Tên"
            />
          </div>
          <div className="p-inputgroup ">
            <span className="p-inputgroup-addon " style={styles.mb0ml2}>
              <FaPhoneAlt />
              <p className="mb-0 ml-2">Số điện thoại</p>
            </span>
            <InputText
              onChange={(e) => onChangePhone(e)}
              placeholder="Số điện thoại"
              keyfilter={/[0-9]/}
            />
          </div>
          <div className="p-inputgroup ">
            <span className="p-inputgroup-addon" style={styles.mb0ml2}>
              <MdDriveFileRenameOutline />
              <p className="mb-0 ml-2">Link Facebook</p>
            </span>
            <InputText
              onChange={(e) => onChangeFb(e)}
              placeholder="Link Facebook"
            />
          </div>
          <div className="p-inputgroup ">
            <span className="p-inputgroup-addon" style={styles.mb0ml2}>
              <MdDriveFileRenameOutline />
              <p className="mb-0 ml-2">Mail</p>
            </span>
            <InputText onChange={(e) => onChangeMail(e)} placeholder="Mail" />
          </div>
          <div className="p-inputgroup ">
            <span className="p-inputgroup-addon" style={styles.mb0ml2}>
              <FaUserGroup />
              <p className="mb-0 ml-2">Phân quyền</p>
            </span>
            <Dropdown
              options={permissionsList}
              optionLabel="name"
              optionValue="value"
              value={permission}
              onChange={(e) => setPermission(e.value)}
              placeholder="Phân quyền"
              valueTemplate={selectedPermissionTemplate}
            />
          </div>

          <div className="p-inputgroup ">
            <span className="p-inputgroup-addon" style={styles.mb0ml2}>
              <MdOutlineTimeline />
              <p className="mb-0 ml-2">Số lượng nhân viên</p>
            </span>
            <InputText
              onChange={(e) => onChangeLimitEmp(e)}
              placeholder="Số lượng nhân viên"
              type="number"
            />
          </div>
          <div className="p-inputgroup ">
            <span className="p-inputgroup-addon" style={styles.mb0ml2}>
              <MdOutlineTimeline />
              <p className="mb-0 ml-2">Số lượng tài khoản</p>
            </span>
            <InputText
              onChange={(e) => onChangePage(e)}
              placeholder="Số lượng tài khoản"
              type="number"
            />
          </div>
          <div className="p-inputgroup ">
            <span className="p-inputgroup-addon" style={styles.mb0ml2}>
              <FaCalendarAlt />
              <p className="mb-0 ml-2">Hạn sử dụng</p>
            </span>
            <Calendar
              placeholder="Hạn sử dụng"
              locale="vi"
              id="buttondisplay"
              value={date}
              onChange={(e) => setDate(e.value)}
              minDate={currentDate}
              readOnlyInput
              disabledDates={[]}
            />
          </div>
        </div>
        <div className="d-flex justify-content-end gap-2 mt-2">
          <Button
            className=" button-blue radius"
            label="Tạo"
            onClick={() => addEmployee()}
          />
          <Button
            className=" button-red radius"
            label="Hủy"
            onClick={() => setVisible(false)}
            severity="danger"
          />
        </div>
      </Dialog>
    </div>
  );
};
