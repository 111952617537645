import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { API_ROUTES, API_URL } from "../../../api";
import { InputText } from "primereact/inputtext";
import { MdContentCopy } from "react-icons/md";
import { useHeaders } from "../../../const/headers";
import { ColorPicker } from "primereact/colorpicker";
import { useUser } from "../../../contexts/UserContext";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import "react-toastify/dist/ReactToastify.css";
import { MdLabel } from "react-icons/md";
import { TiTick } from "react-icons/ti";
import { RiDeleteBack2Fill } from "react-icons/ri";

export const AddLabelMess = ({
  setOpenAddLabel,
  openAddLabel,
  editQuestion,
  setRf,
  rf,
  itemLabel,
}) => {
  const headers = useHeaders();
  const { label, setLabel, rfLable, setRfLabel } = useUser();
  const addLabel = API_URL + API_ROUTES.ADD_LABEL_CONVERSION;
  const deleteLabel = API_URL + API_ROUTES.REMOVE_LABEL_CONVERSION;
  const checkNumber = itemLabel?.id;
  const status = label
    ?.filter((item) => item.conversation?.includes(checkNumber))
    ?.map((item) => item.id);

  const addNhan = async (item) => {
    const data = {
      id_category:item?.id,
      id_conversations: [itemLabel?.id],
    };
    try {
      const res = await axios.post(addLabel, data, headers);
      setRfLabel(!rfLable);
    } catch (err) {}
  };
  const deleLabels = async (item) => {
    const body = { id_category:item?.id, id_conversations: [itemLabel?.id] };
    try {
      await axios.post(deleteLabel, body, headers);
      setRfLabel(!rfLable);
    } catch (err) {}
  };
  return (
    <div className="flex justify-content-center">
      <Dialog
        header="Gán nhãn"
        visible={openAddLabel}
        onHide={() => setOpenAddLabel(false)}
        style={{ width: "30vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        <div
          style={{ border: "1px solid" }}
          className="d-flex flex-column gap-2 mt-2 p-2"
        >
          <h6>Xoá nhãn</h6>
          <div className="d-flex flex-column gap-2">
            {label?.map((item, index) => (
              <div
                key={index}
                className="d-flex justify-content-between align-items-center"
              >
                <nav
                  onClick={() => addNhan(item)}
                  className="d-flex align-items-center"
                >
                  <MdLabel style={{ color: `${item?.color}` }} />
                  <p
                    className="mb-0 p-2 rounded"
                    style={{
                      color: "#000",
                      cursor: "pointer",
                      width: "max-content",
                    }}
                  >
                    {item?.name}
                  </p>
                  {status?.filter((items) => items === item?.id).length ===
                  0 ? (
                    <></>
                  ) : (
                    <TiTick color="blue" />
                  )}
                </nav>
                {status?.filter((items) => items === item?.id).length === 0 ? (
                  <></>
                ) : (
                  <RiDeleteBack2Fill
                    style={{ cursor: "pointer" }}
                    onClick={() => deleLabels(item)}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      </Dialog>
    </div>
  );
};
