import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { API_ROUTES, API_ROUTES_NTD, API_URL } from "../../../api";
import { InputText } from "primereact/inputtext";
import { useHeaders } from "../../../const/headers";
import { FloatLabel } from "primereact/floatlabel";
import { InputTextarea } from "primereact/inputtextarea";
import { CreateFastMess } from "./CreateFastMess";
import { Card } from "primereact/card";
import { Image } from "primereact/image";
import { FaEdit } from "react-icons/fa";
import { EditFastMess } from "./EditFastMess";
import { MdDelete } from "react-icons/md";

export const ModalFastMess = ({
  setOpenFastMess,
  openFastMess,
  editQuestion,
  setRf,
  toast,
  rf,
  idPage,
  setNewMessage,
  setSelectedFileName,
  setImageSrc,
  setFileFast,
}) => {
  const getAllMess = API_URL + API_ROUTES.GET_FAST_REPLY;
  const deleteFastMess = API_URL + API_ROUTES.DELETE_FAST_MESS;

  const headers = useHeaders();
  const [dataMess, setDataMes] = useState([]);
  const [openCreate, setOpenCreate] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [dataEdit, setDataEdit] = useState([]);
  const handleGetMess = async () => {
    const body = {
      id_account: idPage,
    };
    try {
      const response = await axios.post(getAllMess, body, headers);
      setDataMes(response.data);
    } catch (err) {}
  };
  useEffect(() => {
    handleGetMess();
  }, [openFastMess]);
  const deleteMess = async (item) => {
    const confirm = window.confirm("Bạn chắc chắn muốn xoá không");
    const body = { id_fast_reply: item.id };
    try {
      const res = await axios.post(deleteFastMess, body, headers);
      toast.success("Xoá thành công");
      handleGetMess();
    } catch (err) {
      toast.error("Xoá thất bại");
    }
  };
  const sendMessAt = async (item) => {
    setNewMessage(item.content);
    setImageSrc(`${API_URL}/${item?.image}`);
    setSelectedFileName("FastImage");
    setFileFast(item?.image);
  };
  return (
    <div className="flex justify-content-center">
      <Dialog
        header="Danh sách tin nhắn nhanh"
        visible={openFastMess}
        onHide={() => setOpenFastMess(false)}
        style={{ width: "30vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        <nav className="d-flex justify-content-end">
          <Button
            className="button-blue
             radius"
            style={{ marginTop: "1rem" }}
            onClick={() => setOpenCreate(true)}
            label="Thêm"
            text
          />
        </nav>
        <div className="d-flex flex-column gap-4 mt-4 p-2 w-100">
          {dataMess?.map((item, index) => (
            <div style={{ position: "relative" }}>
              <Card key={index} title={item.title}>
                <div
                  className="d-flex justify-content-between"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    sendMessAt(item);
                    setOpenFastMess(false);
                  }}
                >
                  <p className="m-0">{item.content}</p>
                  {item.image && (
                    <Image
                      src={`${API_URL}/${item.image}`}
                      zoomSrc={`${API_URL}/${item.image}`}
                      alt="Image"
                      width="80"
                      height="60"
                      preview
                    />
                  )}
                </div>
                <FaEdit
                  onClick={() => {
                    setOpenEdit(true);
                    setDataEdit(item);
                  }}
                  size={20}
                  style={{
                    cursor: "pointer",
                    position: "absolute",
                    top: "0",
                    right: "25px",
                  }}
                />
                <MdDelete
                  onClick={() => {
                    deleteMess(item);
                  }}
                  size={20}
                  style={{
                    cursor: "pointer",
                    position: "absolute",
                    top: "1px",
                    right: "0",
                    color: "red",
                  }}
                />
              </Card>
            </div>
          ))}
        </div>
        <CreateFastMess
          handleGetMess={handleGetMess}
          toast={toast}
          idPage={idPage}
          setOpenCreate={setOpenCreate}
          openCreate={openCreate}
        />
        <EditFastMess
          handleGetMess={handleGetMess}
          toast={toast}
          dataEdit={dataEdit}
          openEdit={openEdit}
          setOpenEdit={setOpenEdit}
        />
      </Dialog>
    </div>
  );
};
