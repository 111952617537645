import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { API_URL, API_ROUTES } from "../api";
import { CityProvider } from "../components/container/listlocation/ListLocation";
const UserContext = createContext();
export const UserProvider = ({ children }) => {
  const getLogo = API_URL + API_ROUTES.LOGO_IMAGE;
  const getExpried = API_URL + API_ROUTES.GET_EXPRE;
  const getAlert = API_URL + API_ROUTES.GET_ALERT;
  const getRegister = API_URL + API_ROUTES.GET_POPUP_REGSTER;
  const getAllLable = API_URL + API_ROUTES.GET_ALL_LABLES;
  const getDataTerm = API_URL + API_ROUTES.GET_DATA_POPUP_TERM;
  const [rfDt, setRfDt] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [alerts, setAlerts] = useState([]);
  const [rfAlert, setRfAlert] = useState(false);
  const [newLogo, setNewLogo] = useState("");
  const [rfLogo, setRfLogo] = useState(false);
  const [register, setRegister] = useState([]);
  const [rfRegister, setRfRegister] = useState(false);
  const [visibleTb, setVisibleTb] = useState(false);
  const [visibleEx, setVisibleEx] = useState(false);
  const [modalRe, setModalRe] = useState(false);
  const [dataEx, setDataEx] = useState([]);
  const [rfEx, setRfEx] = useState(false);
  const [label, setLabel] = useState([]);
  const [rfLable, setRfLabel] = useState(false);
  const [rfFooter, setRfFooter] = useState(false);
  const [closeList, setCloseList] = useState(true);
  const [statusLogin, setStatusLogin] = useState(false);
  const [modalAcceptTerms, setModalAcceptTerms] = useState(false);
  const [rfTerm, setRfTerm] = useState(false);
  const [termData, setTermData] = useState([]);
  const expried = localStorage.getItem("expired");
  const rfLocal = localStorage.getItem("access");
  const [bellMess, setBellMess] = useState(false);
  const [rfMe, setRfMe] = useState(false);
  useEffect(() => {
    if (expried) {
      setVisibleEx(true);
    }
  }, [statusLogin]);
  const fetchDataLogo = async () => {
    try {
      const res = await axios.get(getLogo);
      setNewLogo(res?.data?.link);
    } catch (err) {}
  };
  const fetchDatas = async () => {
    try {
      const res = await axios.get(getExpried);
      setDataEx(res.data);
    } catch (err) {}
  };
  const fetchAlert = async () => {
    try {
      const res = await axios.get(getAlert, {
        headers: {
          Authorization: `Bearer ${rfLocal}`,
        },
      });
      setAlerts(res.data);
    } catch (err) {}
  };
  const fetchRegister = async () => {
    try {
      const res = await axios.get(getRegister);
      setRegister(res.data);
    } catch (err) {}
  };

  const fetchLabel = async () => {
    try {
      const res = await axios.get(getAllLable, {
        headers: {
          Authorization: `Bearer ${rfLocal}`,
        },
      });
      setLabel(res.data);
    } catch (err) {}
  };
  const fetchDataTerm = async () => {
    try {
      const res = await axios.get(getDataTerm);
      setTermData(res.data);
    } catch (err) {}
  };
  useEffect(() => {
    fetchDataTerm();
  }, [rfTerm]);
  useEffect(() => {
    fetchDataLogo();
  }, [rfLogo, rfLocal]);
  useEffect(() => {
    fetchDatas();
  }, [rfEx]);
  useEffect(() => {
    fetchRegister();
  }, [rfRegister]);
  useEffect(() => {
    fetchAlert();
  }, [rfAlert]);
  useEffect(() => {
    fetchLabel();
  }, [rfLable]);
  return (
    <UserContext.Provider
      value={{
        rfMe,
        setRfMe,
        setModalRe,
        modalRe,
        userInfo,
        setUserInfo,
        setNewLogo,
        newLogo,
        rfLogo,
        setDataEx,
        dataEx,
        rfEx,
        setRfEx,
        alerts,
        rfAlert,
        setRfAlert,
        setRfLogo,
        visibleTb,
        setVisibleTb,
        visibleEx,
        setVisibleEx,
        fetchDataLogo,
        fetchAlert,
        register,
        rfRegister,
        setRfRegister,
        label,
        setLabel,
        rfLable,
        setRfLabel,
        rfDt,
        setRfDt,
        rfFooter,
        setRfFooter,
        closeList,
        setCloseList,
        statusLogin,
        setStatusLogin,
        modalAcceptTerms,
        setModalAcceptTerms,
        rfTerm,
        setRfTerm,
        termData,
        setTermData,
      }}
    >
      <CityProvider>{children}</CityProvider>
    </UserContext.Provider>
  );
};

export const useUser = () => {
  return useContext(UserContext);
};
