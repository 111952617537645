import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// Thêm các ngôn ngữ bạn muốn hỗ trợ ở đây
// Ví dụ: tiếng Việt
const resources = {
  vi: {
    translation: {
      placeholder: "Nhập mật khẩu",
      "Quản lý tin nhắn": "Quản lý tin nhắn",
      Xoá: "Xoá",
    },
  },
  en: {
    translation: {
      placeholder: "Enter password",
      "Quản Lý Tin Nhắn": "Manage messages",
      "Quản Lý Tài Khoản": "Manage accounts",
      "Quản Lý Đơn Hàng": "Manage orders",
      "Quản Lý Khách Hàng": "Manage customers",
      "Quản Lý Nhóm": "Manage groups",
      "Marketing Bán Hàng": "Marketing and sales",
      "Quản Lý Bài Viết": "Manage articles",
      "Công Cụ Facebook": "Facebook tools",
      "Quản Lý Nhân Viên": "Manage employees",
      "Thống Kê": "Statistics",
      "Quản Lý Người Dùng": "Manage users",
      "Tài Khoản Đang Chờ": "Accounts are waiting",
      "Tài Nguyên": "Resources",
      "Hướng Dẫn": "Guide",
      "Cài Đặt": "Settings",
      "Đăng Xuất": "Log out",
      "Debug Thủ Công": "Manual debugging",
      Xoá: "Delete",
      Sửa: "Edit",
      "Hoạt động": "Activity",
      "Không hoạt động": "Inactive",
      "Thêm tài khoản Facebook": "Add Facebook account",
      "Lấy mã đồng bộ": "Get sync code",
      "Kiểm tra tài khoản": "Check Account",
      "Kiểm tra Proxy": "Check Proxy",
      "Tìm kiếm nhanh": "Quick search",
      "Tên tài khoản": "Account name",
      "Ghi chú": "Note",
      "Trạng thái tài khoản": "Account status",
      "Trạng thái Proxy": "Proxy status",
      "Tuỳ chọn": "Optional",
      "Nhập dữ liệu": "Insert information",
      Lưu: "Save",
      Thoát: "Exit",
      "*Lưu ý: Không chia sẻ mã đồng bộ này với người khác":
        "Note: Do not share this sync code with others",
      "Làm mới": "Refresh",
      "Mã đồng bộ": "Sync Code",
      "Bạn phải chọn ít nhất 1 tài khoản để kiểm tra":
        "You must select at least 1 account to check",
      "Xóa không thành công": "Delete failed",
      "Bạn phải chọn ít nhất 1 tài khoản để xóa":
        "You must select at least 1 account to delete",
      "Lưu ý: Khi xoá tài khoản ra khỏi hệ thống. Tất cả các chiến dịch, kịch bản, nhãn khách hàng..., Dữ liệu liên quan đến tài khoản sẽ bị mất":
        "Note: When deleting an account from the system, all campaigns, scripts, customer labels..., data related to the account will be lost",
      "sửa không thành công": "Edit failed",
      "Chỉnh sửa thông tin": "Edit information",
      "Tên đăng nhập": "User name",
      "Tài khoản bị giới hạn tin nhắn, vui lòng kiểm tra tài khoản":
        "Your account is limited to messages, please check your account",
      "Vui lòng chọn tài khoản trước khi thực hiện chức năng này":
        "Please select an account before performing this function",
      "Đã gửi lời mời kết bạn": "Friend request sent",
      "Quản lý nhãn": "Label management",
      "Tất cả": "All",
      " : Nam": " : Male",
      " : Nữ": " : Female",
      "Kết bạn": "Add friend",
      "Tính năng kết bạn thủ công:": "Manual friend making feature:",
      "- Không nên dùng tính năng kết bạn thủ công liên tục trên một tài khoản, sẽ dễ bị facebook hạn chế tính năng do phát hiện hành vi bất thường":
        "- Do not use the manual friend making feature If you continue on one account, you will be susceptible to Facebook restrictions feature suppression due to abnormal behavior detection",
      "Gợi ý: Mỗi ngày chỉ nên kết bạn từ 1 đến 5 người bạn và chỉ nên kết bạn với những người đã phản hồi tin nhắn của bạn":
        "Suggestion: You should only make friends from 1 to 5 people per day friends and should only make friends with people who have betrayed you reply to your message",
      "Đã gửi lời mời kết bạn": "Friend request sent",
      "Xem thêm...": "See more...",
      "Người dùng này hiện không có mặt , bạn không thể nhắn tin với người này":
        "This user is currently unavailable, you cannot message Trust this person",
      Tạo: "Create",
      "Địa chỉ": "Address",
      "TẠO ĐƠN HÀNG": "CREATE ORDER",
      "Tên khách hàng": "Customer name",
      "Số điện thoại": "Phone number",
      "Sản phẩm": "Product",
      "Số lượng": "Quantity",
      "Thành tiền": "Price",
      "Địa chỉ cụ thể": "Specific address",
      Tỉnh: "Province",
      "Thành phố": "City",
      "Tỉnh / Thành Phố": "Province / City",
      "Quận / Huyện": "District",
      "Xã / Phường": "Ward",
      "Bình luận": "Comment",
      "Đang trả lời bình luận": "Replying to comments",
      "Trả lời": "Reply",
      "Nhập nội dung...": "Answer...",
      "Bình luận liên quan": "Comments",
      "Quản lý bình luận:": "Manager comment",
      "Gửi tin nhắn tới người lạ": "Send message to strangers",
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "vi",
  fallbackLng: "vi",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
