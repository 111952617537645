import React, { useState, useEffect, useRef, useMemo } from "react";
import { Dialog } from "primereact/dialog";
import { Card } from "primereact/card";
import { Avatar } from "primereact/avatar";
import { InputText } from "primereact/inputtext";
import { IoMdSend } from "react-icons/io";
import { IoCameraOutline } from "react-icons/io5";
import { API_URL, API_ROUTES_NTD, API_ROUTES } from "../../../api";
import axios from "axios";
import { useHeaders } from "../../../const/headers";
import { GrPrevious } from "react-icons/gr";
import { GrNext } from "react-icons/gr";
import { getAxios } from "../../../const/getAxios";
import { Image } from "primereact/image";
import { MdDeleteForever } from "react-icons/md";
const FacebookPost = React.memo(({ href }) => {
  useEffect(() => {
    if (window.FB) {
      window.FB.XFBML.parse();
    }
  }, [href]);
  return (
    <div
      className="fb-post text-center"
      data-href={href}
      data-width="500"
      data-height="400"
      data-show-text="true"
      data-show-comments="true"
      loading="lazy"
      data-numposts="5"
    ></div>
  );
});
export default function ModalDetailsComments({
  visible,
  setVisible,
  allComments,
  setAllComments,
  listAccout,
  toast,
  t,
}) {
  const headers = useHeaders();
  const [value, setValue] = useState("");
  const [isReply, setIsReply] = useState(true);
  const [fileImg, setFileImg] = useState(null);
  const apiReply = API_URL + API_ROUTES_NTD.REPLY_COMMENTS_ONMESS;
  const showOnComment = API_URL + API_ROUTES_NTD.SHOW_ALL_COMMENT_MESS;
  const resultsComment = API_URL + API_ROUTES_NTD.RESULTS_OF_COMMENT;
  const apiGetLink = API_URL + API_ROUTES.GET_LINK_FILE;
  const getInforCmt = API_URL + API_ROUTES_NTD.GET_INFOR_COMMENT;
  const [numberNext, setNumberNext] = useState(0);
  const [replyComment, setReplyComment] = useState("");
  const [getTask, setGetTask] = useState([]);
  const [imageSrc, setImageSrc] = useState(null);
  const [numberPr, setNumberPr] = useState(0);
  const [isPressed, setIsPressed] = useState(false);
  const [linkImg, setLinkImg] = useState("");
  const deleteImgSrc = () => {
    setImageSrc(null);
  };
  const [isSending, setIsSending] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const scanAvatar = async () => {
    const body = { id_comment: allComments?.results[0].id };
    try {
      const res = await axios.post(getInforCmt, body, headers);
    } catch (err) {}
  };
  const handleImageError = async (event) => {
    const url = event.target.src;
    await scanAvatar();
  };
  const getLinkServer = async (fileImg) => {
    const formData = new FormData();
    formData.append("file", fileImg);
    try {
      const res = await axios.post(apiGetLink, formData, headers);
      setLinkImg(res.data);
    } catch (error) {}
  };
  const replyComments = async () => {
    if (
      allComments &&
      allComments.results &&
      allComments.results[0] &&
      allComments.results[0].post
    ) {
      setIsSending(true);
      const body = {
        id_post: allComments.results[0].post.id,
        id_comment: allComments.results[0].id,
        reply_comment_text: value,
        reply_comment_media: linkImg.file,
      };

      try {
        const res = await axios.post(apiReply, body, headers);
        setGetTask(res.data);
        getResultsComment(res.data);
        setImageSrc(null);
        setFileImg(null);
        setValue("");
        getAxios(
          `${`${showOnComment}?page=${numberNext - 1}`}`,
          setAllComments
        );
        toast.info(t("Đang trả lời bình luận"));
      } catch (err) {
        setImageSrc(null);
        setFileImg(null);
        setValue("");
        getAxios(
          `${`${showOnComment}?page=${numberNext - 1}`}`,
          setAllComments
        );
        toast.error("Trả lời bình luận thất bại");
      } finally {
        setIsSending(false);
      }
    }
  };
  const getResultsComment = async (taskid) => {
    const body = { id_task: taskid.id_task };
    try {
      const res = await axios.post(resultsComment, body, headers);
      setReplyComment(res.data.status);
      if (res.data.data.status === true) {
        toast.success("Trả lời bình luận Thành công");
      } else if (res.data.data === "Rate limit exceeded") {
        toast.error(
          "Tài khoản bị giới hạn bình luận, vui lòng kiểm tra tài khoản"
        );
      } else if (res.data.data === "Chưa đăng nhập") {
        toast.error(
          "Tài khoản đã bị đăng xuất, vui lòng đăng nhập lại để tiếp tục bình luận"
        );
      }
    } catch (err) {
      // setReplyComment(err.res?.data);
      // toast.error("Đã xảy ra lỗi khi bình luận");
    }
  };
  useEffect(() => {
    if (getTask.id_task) {
      const interval = setInterval(() => {
        getResultsComment(getTask);
      }, 3000);
      if (replyComment === "SUCCESS" || replyComment === "FAILURE") {
        getAxios(
          `${`${showOnComment}?page=${numberNext - 1}`}`,
          setAllComments
        );
        clearInterval(interval);
      }
      return () => clearInterval(interval);
    }
  }, [replyComment, getTask]);

  function timeSince(date) {
    const now = new Date();
    const created = new Date(date);
    const seconds = Math.floor((now - created) / 1000);

    let interval = Math.floor(seconds / 31536000);

    if (interval >= 1) {
      return interval + (interval === 1 ? " năm" : " năm");
    }
    interval = Math.floor(seconds / 2592000);
    if (interval >= 1) {
      return interval + (interval === 1 ? " tháng" : " tháng");
    }
    interval = Math.floor(seconds / 86400);
    if (interval >= 1) {
      return interval + (interval === 1 ? " ngày" : " ngày");
    }
    interval = Math.floor(seconds / 3600);
    if (interval >= 1) {
      return interval + (interval === 1 ? " giờ" : " giờ");
    }
    interval = Math.floor(seconds / 60);
    if (interval >= 1) {
      return interval + (interval === 1 ? " phút" : " phút");
    }
    return (
      Math.floor(seconds) + (Math.floor(seconds) === 1 ? " giây" : " giây")
    );
  }

  const fileInputRef = useRef(null);

  const handleIconClick = () => {
    setIsPressed(true);
    fileInputRef.current.click();
    setTimeout(() => setIsPressed(false), 1000);
  };
  const handleInputChange = (event) => {
    const value = event.target.value;
    setValue(value);
    setIsTyping(value.length > 0);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    getLinkServer(event.target.files[0]);
    setFileImg(file);
  };

  useEffect(() => {
    if (allComments && allComments.next) {
      setNumberNext(Number(allComments.next.split("=")[1]));
    }
    if (allComments && allComments.previous) {
      setNumberPr(Number(allComments.previous.split("=")[1]));
    }
  }, [allComments]);

  const href = useMemo(() => {
    return (
      allComments &&
      allComments.results &&
      allComments.results.length > 0 &&
      allComments.results[0].url
    );
  }, [allComments]);

  useEffect(() => {
    if (fileImg) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageSrc(reader.result);
      };
      reader.readAsDataURL(fileImg);
    }
  }, [fileImg]);

  const MAX_LENGTH = 200;

  const getCommentText = () => {
    if (allComments && allComments.results && allComments.results.length > 0) {
      return allComments.results[0].comment_text;
    }
    return "";
  };

  const commentText = getCommentText();
  const isLongText = commentText.length > MAX_LENGTH;
  const displayText = isLongText
    ? commentText.substring(0, MAX_LENGTH) + "..."
    : commentText;

  const filterAccount = useMemo(() => {
    if (
      Array.isArray(listAccout) &&
      allComments &&
      allComments.results &&
      allComments.results.length > 0 &&
      allComments.results[0].post
    ) {
      return listAccout.filter(
        (item) => item && item.id === allComments.results[0].post.account
      );
    }
    return [];
  }, [listAccout, allComments]);
  return (
    <div className="flex justify-content-center">
      <Dialog
        header={t("Quản lý bình luận:")}
        className="dialogheight"
        visible={visible}
        style={{ width: "70vw" }}
        onHide={() => {
          if (!visible) return;
          setVisible(false);
        }}
      >
        <GrPrevious
          style={{
            cursor: numberPr > 0 ? "pointer" : "default",
            display: allComments?.results?.length !== 1 ? "none" : "",
            // opacity: numberPr > 0 && numberNext !== 3 ? 1 : 0.5,
            // pointerEvents: numberPr > 0 && numberNext !== 3 ? "auto" : "none",
            color: numberPr > 0 || numberNext === 3 ? "blue" : "grey",
          }}
          onClick={() =>
            getAxios(
              `${
                numberNext === 3
                  ? `${showOnComment}?page=1`
                  : `${showOnComment}?page=${numberPr}`
              }`,
              setAllComments
            )
          }
          size={50}
        />
        {allComments?.results?.length > 0 ? (
          <div
            className="d-flex h-100"
            style={{ width: "90%", maxHeight: "600px" }}
          >
            <FacebookPost
              style={{ width: "50%", height: "auto", objectFit: "contain" }}
              href={href}
            />
            <Card
              title={t("Bình luận liên quan")}
              className="h-100"
              style={{ width: "50%" }}
            >
              <div className="d-flex ">
                <Avatar
                  onError={handleImageError}
                  style={{ width: "32px" }}
                  image={
                    allComments &&
                    allComments.results &&
                    allComments.results.length > 0 &&
                    allComments.results[0].comment_author_avatar
                  }
                  className="mr-2 widthimage"
                  shape="circle"
                />
                <nav>
                  <nav
                    style={{
                      background: "#F0F2F5",
                      padding: "10px 20px",
                      width: "max-content",
                      borderRadius: "20px",
                    }}
                  >
                    <p style={{ fontWeight: "bold" }}>
                      {allComments &&
                        allComments.results &&
                        allComments.results.length > 0 &&
                        allComments.results[0].comment_author_name}{" "}
                      -{" "}
                      {allComments &&
                        allComments.results &&
                        allComments.results.length > 0 &&
                        allComments.results[0].comment_author_id}{" "}
                      -{" "}
                      {allComments &&
                        allComments.results &&
                        allComments.results.length > 0 &&
                        (allComments.results[0].comment_author_gender === "male"
                          ? "Nam"
                          : allComments.results[0].comment_author_gender ===
                            "female"
                          ? "Nữ"
                          : "")}
                    </p>

                    <p style={{ width: "200px" }}> {displayText}</p>
                  </nav>
                  <nav>
                    {" "}
                    {allComments &&
                    allComments.results &&
                    allComments.results.length > 0 &&
                    allComments.results[0].comment_media &&
                    allComments.results[0].comment_media !== "[]" ? (
                      <Image
                        src={allComments.results[0].comment_media}
                        zoomSrc={allComments.results[0].comment_media}
                        alt="Image"
                        width="80"
                        height="auto"
                        preview
                        style={{ marginTop: "10px", marginLeft: "20px" }}
                      />
                    ) : null}
                  </nav>

                  <nav className="d-flex gap-4" style={{ padding: "0 20px" }}>
                    <p style={{ fontSize: "12px" }}>
                      {timeSince(
                        allComments &&
                          allComments.results &&
                          allComments.results.length > 0 &&
                          allComments.results[0].created_time_comment
                      )}
                    </p>
                    <p
                      onClick={() => setIsReply(!isReply)}
                      style={{
                        fontSize: "12px",
                        cursor: "pointer",
                        fontWeight: "600",
                      }}
                    >
                      {t("Trả lời")}
                    </p>
                  </nav>
                  <nav
                    className="d-flex mt-2"
                    style={{
                      padding: "0 20px",
                    }}
                  >
                    <Avatar
                      style={{
                        display:
                          allComments &&
                          allComments.results &&
                          allComments.results.length > 0 &&
                          allComments.results[0].reply_comment_text === null &&
                          allComments &&
                          allComments.results &&
                          allComments.results.length > 0 &&
                          allComments.results[0].reply_comment_media?.length ===
                            0
                            ? "none"
                            : "",
                      }}
                      image={
                        filterAccount.length > 0 ? filterAccount[0].avatar : ""
                      }
                      className="mr-2 mt-3"
                      shape="circle"
                    />
                    <nav
                      style={{
                        padding: "10px 20px",

                        display:
                          allComments &&
                          allComments.results &&
                          allComments.results.length > 0 &&
                          allComments.results[0].reply_comment_text === null &&
                          allComments &&
                          allComments.results &&
                          allComments.results.length > 0 &&
                          allComments.results[0].reply_comment_media?.length ===
                            0
                            ? "none"
                            : "",
                      }}
                    >
                      <nav
                        className="rounded-pill"
                        style={{
                          background: "#F0F2F5",
                          padding: "10px 20px",
                          width: "max-content",
                        }}
                      >
                        <p style={{ fontWeight: "bold" }}>
                          {filterAccount.length > 0
                            ? filterAccount[0].name
                            : ""}
                        </p>
                        <p>
                          {allComments &&
                            allComments.results &&
                            allComments.results.length > 0 &&
                            allComments.results[0].reply_comment_text}
                        </p>
                      </nav>
                      <Image
                        src={
                          allComments &&
                          allComments.results &&
                          allComments.results.length > 0 &&
                          allComments.results[0].reply_comment_media
                        }
                        zoomSrc={
                          allComments &&
                          allComments.results &&
                          allComments.results.length > 0 &&
                          allComments.results[0].reply_comment_media
                        }
                        alt="Image"
                        width="80"
                        height="auto"
                        preview
                        style={{ marginTop: "10px", marginLeft: "20px" }}
                      />
                      <nav
                        style={{
                          marginTop: "10px",
                        }}
                      >
                        <a
                          href={`https://www.facebook.com/${
                            allComments &&
                            allComments.results &&
                            allComments.results.length > 0 &&
                            allComments.results[0].reply_comment_id
                          }`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span style={{ color: "#4b5563" }}>Link:</span>{" "}
                          {allComments &&
                            allComments.results &&
                            allComments.results.length > 0 &&
                            allComments.results[0].reply_comment_id}
                        </a>
                      </nav>
                    </nav>
                  </nav>
                  {isReply && (
                    <nav className="d-flex flex-column align-items-center">
                      <nav className="d-flex mt-2 align-items-center">
                        <Avatar
                          image={
                            filterAccount.length > 0
                              ? filterAccount[0].avatar
                              : ""
                          }
                          className="mr-2"
                          shape="circle"
                        />
                        <nav className="d-flex align-items-center border rounded-pill pr-2">
                          <InputText
                            className="input-reply"
                            value={value}
                            placeholder={t("Nhập nội dung...")}
                            onChange={handleInputChange}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                replyComments();
                                setIsTyping(false);
                              }
                            }}
                          />
                          <IoCameraOutline
                            size={20}
                            onClick={handleIconClick}
                            style={{
                              cursor: "pointer",
                              color: isPressed ? "blue" : "inherit",
                            }}
                          />
                          <input
                            type="file"
                            ref={fileInputRef}
                            style={{ display: "none" }}
                            onChange={handleFileChange}
                          />
                        </nav>
                        <IoMdSend
                          size={30}
                          onClick={() => {
                            replyComments();
                            setIsTyping(false);
                          }}
                          style={{
                            cursor: "pointer",
                            // color: isTyping ? "blue" : "grey",
                            color: isSending
                              ? "grey"
                              : isTyping
                              ? "blue"
                              : "grey",
                            // color: isSending ? "grey" : "grey", // Thay đổi màu khi đang gửi
                            transform: isSending ? "scale(1)" : "scale(0.7)", // Phóng to nhẹ khi đang gửi
                            transition: "color 0.3s ease, transform 0.3s ease", // Hiệu ứng chuyển đổi mượt mà
                          }}
                        />
                      </nav>
                      {imageSrc && (
                        <nav style={{ position: "relative" }}>
                          <Image
                            src={imageSrc}
                            zoomSrc={imageSrc}
                            alt="Image"
                            width="80"
                            height="60"
                            preview
                          />
                          <MdDeleteForever
                            onClick={deleteImgSrc}
                            size={30}
                            style={{
                              position: "absolute",
                              top: "-5px",
                              right: "-16px",
                              cursor: "pointer",
                            }}
                          />
                        </nav>
                      )}
                    </nav>
                  )}
                </nav>
              </div>
            </Card>
          </div>
        ) : (
          <></>
        )}
        <GrNext
          style={{
            cursor: "pointer",
            display:
              numberNext === undefined || allComments?.results?.length === 0
                ? "none"
                : "",
            opacity: numberNext > allComments?.count ? 0.5 : 1,
            pointerEvents: numberNext >= allComments?.count ? "none" : "auto",
            color: numberNext >= allComments?.count ? "grey" : "blue",
          }}
          onClick={() => {
            if (numberNext < allComments?.count) {
              getAxios(`${showOnComment}?page=${numberNext}`, setAllComments);
            }
          }}
          size={50}
        />
      </Dialog>
    </div>
  );
}
