import { useState, useEffect } from "react";
import { Button } from "primereact/button";
import axios from "axios";
import { useHeaders } from "../../../../const/headers";
import { toast } from "react-toastify";
import { API_URL, API_ROUTES_NTD } from "../../../../api";
import { getAxios, getAxiosResults } from "../../../../const/getAxios";
import { ListAccArticle } from "../ListAccArticle";
import { ResultsSending } from "./ResultsSending";
import { CreateScriptSending } from "./CreateScriptSending";
import { ListScriptSending } from "./ListScriptSending";
import { ModalDetailsSending } from "./ModalDetailsSending";
import { Sidebar } from "primereact/sidebar";
import { ModalCategorisPost } from "../post/ModalCategorisPost";

export const ListTableSending = ({
  dataFb,
  refresh,
  setRefresh,
  isInitialRender,
  renderKey,
  allCate,
  setAllCate,
}) => {
  const headers = useHeaders();
  const getAllCateArticlePost =
    API_URL + API_ROUTES_NTD.ARTICLE_SENDING.GET_ALL_CATEGORY;
  const startMarketing = API_URL + API_ROUTES_NTD.ARTICLE_SENDING.START;
  const stopMarketing = API_URL + API_ROUTES_NTD.ARTICLE_SENDING.STOP;
  const deleteResultArticle =
    API_URL + API_ROUTES_NTD.ARTICLE_SENDING.DELETE_RESULTS;
  const allResults = API_URL + API_ROUTES_NTD.ARTICLE_SENDING.RESULTS;
  const copyCate = API_URL + API_ROUTES_NTD.ARTICLE_SENDING.COPY_CATE_SENDING;
  const addCatePost =
    API_URL + API_ROUTES_NTD.ARTICLE_SENDING.ADD_ACCOUT_TO_CATE;
  const deleteCate =
    API_URL + API_ROUTES_NTD.ARTICLE_SENDING.REMOVE_ACCOUT_TO_CATE;
  const [listMktCate, setListMktCate] = useState([]);
  const [wsResults, setWsResults] = useState([]);
  const [openDetails, setOpenDetails] = useState(false);
  const [itemResults, setItemResults] = useState([]);
  const [listResults, setListResults] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [getTotal, setGetTotal] = useState([]);
  const [rows, setRows] = useState(100);
  const [openAddScript, setOpenAddScript] = useState(false);
  const [modalDetails, setModalDetails] = useState(false);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [openManager, setOpenManager] = useState(false);
  const [itemCate, setItemCate] = useState([]);
  const deleteResults = async () => {
    const body = { id_results: listResults?.map((item) => item?.id) };
    try {
      const res = await axios.post(deleteResultArticle, body, headers);
      getAxiosResults(
        allResults,
        setWsResults,
        setGetTotal,
        rows,
        totalPage + 1
      );
      toast.success("Xoá thành công");
      setListResults([]);
    } catch (err) {}
  };
  useEffect(() => {
    getAxios(getAllCateArticlePost, setAllCate);
  }, [refresh]);
  const startMarketingCate = async (type) => {
    if (listMktCate?.length === 0) {
      alert("Chọn ít nhất 1 kịch bản để chạy ");
    } else {
      const body = {
        id_categories: listMktCate?.map((item) => item.id),
        type: type,
      };
      try {
        await axios.post(startMarketing, body, headers);
        toast.success("Chạy chiến dịch thành công");
      } catch (err) {
        toast.error(err.response.data.error);
      }
    }
  };
  useEffect(() => {
    if (allCate.length > 0 && itemCate) {
      const updatedItemCate = allCate.find((item) => item.id === itemCate.id);
      if (updatedItemCate) {
        setItemCate(updatedItemCate);
      }
    }
  }, [allCate]);
  const stopMarketingCate = async () => {
    if (listMktCate?.length === 0) {
      alert("Chọn ít nhất 1 kịch bản để dừng ");
    } else {
      const body = {
        id_categories: listMktCate?.map((item) => item.id),
      };
      try {
        await axios.post(stopMarketing, body, headers);
        setRefresh(!refresh);
        toast.success("Dừng chiến dịch thành công");
      } catch (err) {
        toast.error("Dừng chiến dịch thất bại");
      }
    }
  };
  const handleCopyCate = async (item) => {
    const userName = prompt("Nhập tên kịch bản mới");
    if (!userName) return alert("Vui lòng nhập tên kịch bản mới");
    const body = { id_category: item.id, name: userName };
    try {
      await axios.post(copyCate, body, headers);
      setRefresh(!refresh);
      setListMktCate([]);
    } catch (err) {}
  };
  return (
    <div className="d-flex  h-100 w-100">
      <div
        className="overflow-auto h-100 d-flex flex-column"
        style={{
          width: "100%",
        }}
      >
        <div
          className="d-flex gap-2 w-100 justify-content-end pt-2 pl-2 pr-2"
          id="lisstSeclect"
        >
          <Button
            onClick={() => setOpenAddScript(true)}
            style={{
              width: "200px",
              fontSize: "12px",
            }}
            className="d-flex justify-content-center button-blue"
          >
            Thêm kịch bản
          </Button>
          <Button
            onClick={() => startMarketingCate("new")}
            style={{
              width: "200px",
              fontSize: "12px",
            }}
            className="d-flex justify-content-center button-blue"
          >
            Chạy
          </Button>
          <Button
            onClick={() => startMarketingCate("")}
            style={{
              width: "200px",
              fontSize: "12px",
            }}
            className="d-flex justify-content-center button-blue"
          >
            Chạy tiếp
          </Button>
          <Button
            onClick={() => setOpenSidebar(true)}
            style={{
              width: "200px",
              fontSize: "12px",
            }}
            className="d-flex justify-content-center button-blue"
          >
            Kết quả
          </Button>
          <Button
            severity="danger"
            style={{ width: "200px", fontSize: "12px" }}
            className="d-flex justify-content-center button-red"
            onClick={stopMarketingCate}
          >
            Dừng
          </Button>
        </div>
        <div
          className="d-flex flex-column  gap-2 h-100 m-2"
          style={{ overflow: "hidden" }}
        >
          <div className="d-flex w gap-2" style={{ height: "100%" }}>
            <ListScriptSending
              setItemCate={setItemCate}
              handleCopyCate={handleCopyCate}
              openManager={openManager}
              setOpenManager={setOpenManager}
              listMktCate={listMktCate}
              setListMktCate={setListMktCate}
              allCate={allCate}
              refresh={refresh}
              setRefresh={setRefresh}
            />
          </div>
          <Sidebar
            visible={openSidebar}
            onHide={() => setOpenSidebar(null)}
            position="bottom"
            className="sidebar-market"
          >
            <div
              className="d-flex flex-column w-100"
              style={{ height: "100%" }}
            >
              <Button
                severity="danger"
                style={{
                  width: "200px",
                  display: listResults.length === 0 ? "none" : "block",
                  fontSize: "12px",
                }}
                className="justify-content-center button-red"
                onClick={deleteResults}
              >
                Xoá kết quả
              </Button>
              <div className="d-flex  " style={{ height: "100%" }}>
                <ResultsSending
                  renderKey={renderKey}
                  isInitialRender={isInitialRender}
                  setModalDetails={setModalDetails}
                  rows={rows}
                  setRows={setRows}
                  toast={toast}
                  openDetails={openDetails}
                  setOpenDetails={setOpenDetails}
                  itemResults={itemResults}
                  setItemResults={setItemResults}
                  allCate={allCate}
                  setAllCate={setAllCate}
                  wsResults={wsResults}
                  setWsResults={setWsResults}
                  listResults={listResults}
                  setListResults={setListResults}
                  totalPage={totalPage}
                  setTotalPage={setTotalPage}
                  getTotal={getTotal}
                  setGetTotal={setGetTotal}
                />
              </div>
            </div>
          </Sidebar>
        </div>
      </div>
      <CreateScriptSending
        refresh={refresh}
        setRefresh={setRefresh}
        openAddScript={openAddScript}
        setOpenAddScript={setOpenAddScript}
      />
      <ModalDetailsSending
        itemResults={itemResults}
        modalDetails={modalDetails}
        setModalDetails={setModalDetails}
      />
      <ModalCategorisPost
        deleteCate={deleteCate}
        addCatePost={addCatePost}
        itemCate={itemCate}
        dataFb={dataFb}
        openManager={openManager}
        setOpenManager={setOpenManager}
        refresh={refresh}
        setRefresh={setRefresh}
        toast={toast}
      />
    </div>
  );
};
