import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { API_ROUTES, API_URL } from "../../../api";
import { InputText } from "primereact/inputtext";
import { IoIosLink } from "react-icons/io";
import { MdContentCopy } from "react-icons/md";
import { MdOutlineSubtitles } from "react-icons/md";
import { useHeaders } from "../../../const/headers";
import { PiCaretCircleDoubleRightDuotone } from "react-icons/pi";
export const ManagePro = ({ setModalPro, modalPro, itemPro, setRf, rf }) => {
  const headers = useHeaders();
  const createUpdate = API_URL + API_ROUTES.CREATE_PRODUCT_RESOURCE;
  const upload = API_URL + API_ROUTES.UPLOAD_FILE_SERVER;
  const [content, setContent] = useState(itemPro.content || "");
  const [link, setLink] = useState(itemPro.link || "");
  const [title, setTitle] = useState(itemPro.title || "");
  const [file, setFile] = useState(null);
  const [imageLink, setImageLink] = useState("");
  useEffect(() => {
    setContent(itemPro.content || "");
    setLink(itemPro.link || "");
    setTitle(itemPro.title || "");
    setFile(itemPro.image || null);
  }, [modalPro]);
  const upLoadSever = async () => {
    if (file !== null) {
      const formData = new FormData();
      formData.append("image", file);
      try {
        const res = await axios.post(upload, formData, headers);
        setImageLink(res.data.image);
      } catch (err) {
      }
    }
  };

  useEffect(() => {
    upLoadSever();
  }, [file]);
  const createProduct = async () => {
    const data = {
      id: itemPro?.id,
      content: content,
      link: link,
      image: imageLink.length === 0 ? itemPro.image : imageLink,
      title: title,
    };
    try {
      const res = await axios.post(createUpdate, data, headers);
      setRf(!rf);
      setContent("");
      setTitle("");
      setImageLink("");
      setFile(null);
      setModalPro(false);
    } catch (err) {
    }
  };
  const onChangeContent = (e) => {
    setContent(e.target.value);
  };
  const onChangeLink = (e) => {
    setLink(e.target.value);
  };
  const onChangeTitle = (e) => {
    setTitle(e.target.value);
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFile(file);
    const reader = new FileReader();
    reader.onload = () => {
      const imageDataURL = reader.result;
      const imgElement = document.getElementById("previewImage");
      if (imgElement) {
        imgElement.src = imageDataURL;
      }
    };
    reader.readAsDataURL(file);
  };
  return (
    <div className="card flex justify-content-center">
      <Dialog
        header="Quản lý"
        visible={modalPro}
        onHide={() => setModalPro(false)}
        style={{ width: "50vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        <div className="card flex flex-column  gap-3" style={{ border: "0" }}>
          <div className="p-inputgroup flex-1">
            <span className="p-inputgroup-addon">
              <MdOutlineSubtitles />
            </span>
            <InputText
              onChange={(e) => onChangeTitle(e)}
              value={title}
              placeholder="Tiêu đề"
            />
          </div>
          <div className="p-inputgroup flex-1">
            <span className="p-inputgroup-addon">
              <MdContentCopy />
            </span>
            <InputText
              onChange={(e) => onChangeContent(e)}
              placeholder="Nội dung"
              value={content}
              maxLength={250}
            />
            <div style={{ padding: "10px" }}>{250 - content.length}/250</div>
          </div>
          <div className="p-inputgroup flex-1">
            <span className="p-inputgroup-addon">
              <IoIosLink />
            </span>
            <InputText
              onChange={(e) => onChangeLink(e)}
              value={link}
              placeholder="Link"
            />
          </div>

          <div className="d-flex gap-2 mr-2 mb-2">
            <label htmlFor="fileInput">
              <button
                className="d-flex flex-column align-items-center gap-2 border border-secondary justify-content-between rounded"
                onClick={() => document.getElementById("fileInput").click()}
              >
                <p className="mb-0">Chọn ảnh</p>
              </button>
            </label>
            <input
              id="fileInput"
              type="file"
              style={{ display: "none" }}
              onChange={handleImageChange}
            />
            {imageLink === "" ? (
              <img
                // id="previewImage"
                style={{
                  height: "100px",
                  display: "block",
                }}
                alt="Selected Image"
                src={`${API_URL}/${itemPro?.image}`}
              />
            ) : (
              <img
                // id="previewImage"
                style={{
                  height: "100px",
                  display: "block",
                }}
                alt="Selected Image"
                src={`${API_URL}/${imageLink}`}
              />
            )}
          </div>
        </div>
        <div className="d-flex justify-content-end gap-2 mt-2">
          <Button
            className=" button-blue radius"
            label="Sửa"
            onClick={() => createProduct()}
          />
          <Button
            className=" button-red radius"
            label="Huỷ"
            onClick={() => setModalPro(false)}
            severity="danger"
          />
        </div>
      </Dialog>
    </div>
  );
};
