import React, { useEffect, useState, memo, useRef } from "react";
import { Modal, Button, Container, Form } from "react-bootstrap";
import { API_URL, API_ROUTES } from "../../../api";
import axios from "axios";
import { useHeaders } from "../../../const/headers";
const ShowLastMess = ({
  showLast,
  setShowLast,
  clickAccess,
  getItem,
  setRefresh,
  refresh,
}) => {
  const headers = useHeaders();
  const [textEdit, setTextEdit] = useState("");
  const editMessFirst = API_URL + API_ROUTES.GET_LAST_MESS;
  const [resetModal, setResetModal] = useState(false);
  const isInitialRender = useRef(true);
  const getMess = async () => {
    try {
      const response = await axios.get(editMessFirst, headers);
      setTextEdit(response.data.accept_order_message);
    } catch (error) {
    }
  };

  useEffect(() => {

    getMess();
  }, [showLast]);
  const editLastMess = API_URL + API_ROUTES.EDIT_LAST_MESS;
  const handleTextareaChange = (event) => {
    setTextEdit(event.target.value);
  };

  const editLinkPro = async () => {
    const data = {
      text: textEdit,
    };
    const accses = localStorage.getItem("access");
    try {
      const response = await axios.post(editLastMess, data, {
        headers: {
          Authorization: "Bearer " + accses,
        },
      });
      setResetModal(!resetModal);
      setRefresh(!refresh);
      setShowLast(false);
    } catch (error) {
    }
  };

  return (
    <Modal
      show={showLast}
      onHide={() => setShowLast(false)}
      backdrop="static"
      animation={false}
      style={{ background: "#ffffff4a" }}
    >
      <Modal.Header
        closeButton
        className="d-flex flex-column justify-content-start align-items-start"
      >
        <Modal.Title id="contained-modal-title-vcenter">
          Tin nhắn xác nhận đơn hàng
        </Modal.Title>
        <nav>
          <p className="mb-0">* Gửi tin nhắn xác nhận đơn hàng và cảm ơn.</p>
        </nav>
      </Modal.Header>
      <Modal.Body className="gird">
        <Container>
          <Form>
            <Form.Group
              style={{ height: "300px" }}
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
              value={textEdit}
              onChange={handleTextareaChange}
            >
              <Form.Control
                value={textEdit}
                as="textarea"
                style={{ height: "100%" }}
                rows={3}
              />
            </Form.Group>
          </Form>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className=" button-blue radius"
          // disabled={loading || returns}
          onClick={() => {
            editLinkPro();
            clickAccess(getItem);
          }}
        >
          Xác nhận đơn hàng
        </Button>
        <Button
          className=" button-blue radius"
          onClick={() => setShowLast(false)}
        >
          Hủy
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default memo(ShowLastMess);
