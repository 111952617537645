import React, { useEffect } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";

const UpdateMapCenter = ({ position }) => {
  const map = useMap();
  useEffect(() => {
    if (position.latitude && position.longitude) {
      map.setView([position.latitude, position.longitude]);
    }
  }, [position, map]);
  return null;
};

export const RenderMap = ({ position }) => {
  const newPosition = position;
  if (!newPosition || !newPosition.latitude || !newPosition.longitude) {
    return null;
  }

  return (
    <MapContainer
      center={[newPosition.latitude, newPosition.longitude]}
      zoom={11}
      style={{ height: "300px", width: "100%", marginBottom: "50px" }}
    >
      <UpdateMapCenter position={newPosition} />
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        id="mapbox/light-v10"
        // attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
      />
      <Marker position={[newPosition.latitude, newPosition.longitude]}>
        <Popup>{newPosition?.name}</Popup>
      </Marker>
    </MapContainer>
  );
};
