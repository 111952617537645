import React from "react";
import { Dialog } from "primereact/dialog";
import { API_URL } from "../../../api";
export const ModalDetailsGroup = ({
  setOpenDetails,
  openDetails,
  itemResults,
}) => {
  return (
    <Dialog
      header="Trạng thái chi tiết"
      visible={openDetails}
      style={{ width: "50vw" }}
      onHide={() => setOpenDetails(false)}
    >
      <div className="f-flex flex-column gap-2">
        <nav
          style={{
            paddingBottom: "0",
            paddingTop: "0.5rem",
          }}
          className="d-flex gap-2"
        >
          <h5>UID: {itemResults?.account?.UID}</h5>
          <h5>{itemResults?.account?.name}</h5>
        </nav>
        <nav
          style={{
            borderBottom: "1px solid",
            paddingBottom: "0.5rem",
            paddingTop: "0",
            display: "flex",
            flexDirection: "column",
            gap: "2px",
          }}
          className="d-flex gap-2"
        >
          <h6 style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <>
              Link bài viết:&nbsp;
              {itemResults?.post_id === null ? (
                "chưa có link"
              ) : (
                <a
                  href={`https://www.facebook.com/${itemResults?.post_id}`}
                  target="_blank"
                  rel="nofollow noreferrer"
                >
                  {itemResults?.post_id}
                </a>
              )}
            </>
          </h6>
          <h6 style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            {" "}
            <>
              (Lưu ý: Có thể bài viết đang duyệt nên bị ẩn. Phải xem bài viết từ
              chính tài khoản đăng bài)
            </>
          </h6>
        </nav>

        <nav
          style={{
            paddingBottom: "0.5rem",
            paddingTop: "0.5rem",
            borderBottom: "1px solid",
          }}
          className="d-flex gap-2"
        >
          <p>Nhóm:&nbsp; {itemResults?.name_group}.</p>
          <p>
            UID nhóm:&nbsp;{" "}
            <a
              href={`https://www.facebook.com/groups/${itemResults?.UID_group}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {itemResults?.UID_group}
            </a>
          </p>
        </nav>

        <nav
          style={{
            borderBottom: "1px solid",
            paddingBottom: "0.5rem",
            paddingTop: "0.5rem",
          }}
          className=""
        >
          <nav className="d-flex gap-2 w-100">
            <p style={{ width: "max-content" }}>Nội dung đăng bài:</p>
            <p style={{ width: "fit-content" }}>{itemResults?.content}</p>
            {itemResults?.images?.length > 0 &&
              itemResults?.images?.map((image, index) => (
                <img
                  key={index}
                  style={{ width: "200px", height: "100%" }}
                  src={`${API_URL}/${image}`}
                />
              ))}
          </nav>
        </nav>
      </div>
    </Dialog>
  );
};
