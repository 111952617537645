import React, { useState } from "react";
import { CiBookmark } from "react-icons/ci";
import { PiEyesDuotone } from "react-icons/pi";
import { PiShareFatLight } from "react-icons/pi";
import { RiUserFollowLine } from "react-icons/ri";

const MarketplaceInsights = ({
  dataStatis,
  setDataStatis,
  dataFb,
  setDataFB,
}) => {
  return (
    <div className="card">
      <div
        style={{ width: "100%", backgroundColor: "#fff" }}
        className="w-moblie d-flex flex-column card gap-2"
      >
        <div className="title">
          <h4
            className="w-100  p-2"
            //   style={{
            //     color: "#1976D2",
            //   }}
          >
            Thông tin chi tiết trên Marketplace
          </h4>
        </div>
        <div
          style={{ boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2)" }}
          className="middle-dash h-100 d-flex text-center justify-content-around w-100 "
        >
          <div
            style={{
              paddingRight: "30px",
              paddingLeft: "30px",
              paddingBottom: "30px",
              flexWrap: "wrap",
            }}
            className="d-flex w-100 gap-2"
          >
            <div
              style={{
                border: "0.5px solid #ccc",
                width: "24%",
                borderRadius: "8px",
                padding: "12px",
              }}
            >
              <div className="d-flex gap-1">
                <div>
                  <PiEyesDuotone fontSize={30} />
                </div>
                <div>
                  <h4 className="d-flex w-100 fw-bold">
                    {dataStatis?.data?.views}
                  </h4>
                  <p
                    style={{ fontWeight: "300" }}
                    className="d-flex w-100 fs-6"
                  >
                    Lượt click vào bài niêm yết
                  </p>
                </div>
              </div>
            </div>
            <div
              style={{
                border: "0.5px solid #ccc",
                width: "24%",
                borderRadius: "8px",
                padding: "12px",
              }}
            >
              <div className="d-flex gap-1">
                <div>
                  <CiBookmark fontSize={30} />
                </div>
                <div>
                  <h4 className="d-flex w-100 fw-bold">
                    {dataStatis?.data?.saves}
                  </h4>
                  <p
                    style={{ fontWeight: "300" }}
                    className="d-flex w-100 fs-6"
                  >
                    Lượt lưu bài niêm yết
                  </p>
                </div>
              </div>
            </div>
            <div
              style={{
                border: "0.5px solid #ccc",
                width: "24%",
                borderRadius: "8px",
                padding: "12px",
              }}
            >
              <div className="d-flex gap-1">
                <div>
                  <PiShareFatLight fontSize={30} />
                </div>
                <div>
                  <h4 className="d-flex w-100 fw-bold">
                    {dataStatis?.data?.shares}
                  </h4>
                  <p
                    style={{ fontWeight: "300" }}
                    className="d-flex w-100 fs-6"
                  >
                    Lượt chia sẻ bài niêm yết
                  </p>
                </div>
              </div>
            </div>
            <div
              style={{
                border: "0.5px solid #ccc",
                width: "24%",
                borderRadius: "8px",
                padding: "12px",
              }}
            >
              <div className="d-flex gap-1">
                <div>
                  <RiUserFollowLine fontSize={30} />
                </div>
                <div>
                  <h4 className="d-flex w-100 fw-bold">
                    {dataStatis?.data?.follows}
                  </h4>
                  <p
                    style={{ fontWeight: "300" }}
                    className="d-flex w-100 fs-6"
                  >
                    Người theo dõi trên Marketplace
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarketplaceInsights;
