import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import axios from "axios";
import { useHeaders } from "../../../const/headers";
import { API_URL, API_ROUTES_NTD } from "../../../api";
import { InputNumber } from "primereact/inputnumber";
import { ListContentGroup } from "./script/ListContentGroup";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { IoInformationCircle } from "react-icons/io5";
import { RiExpandDiagonal2Line } from "react-icons/ri";
export const ModalCreateScriptGroup = ({
  props,
  openCategory,
  setOpenCategory,
  setRefresh,
  refresh,
}) => {
  const headers = useHeaders();
  const createCateMkt = API_URL + API_ROUTES_NTD.CREATE_EDIT_CATEGORY_GROUP;
  const [listImage, setListImage] = useState([]);
  const [name, setName] = useState("");
  const [ingredients, setIngredients] = useState({
    like: false,
    comment: false,
    share: false,
    addfr: false,
    mess: false,
  });
  const [valueCmt, setValueCmt] = useState("");
  const [comments, setComments] = useState([]);
  const [delayTime, setDelayTime] = useState(30);
  const [numberCount, setNumberCount] = useState(0);
  const [waitTime, setWaitTime] = useState(0);
  const addCateMkt = async () => {
    if (!name || comments?.length === 0) {
      alert("Vui lòng nhập đầy đủ thông tin");
    } else if (comments?.length < 5) {
      alert("kịch bản đăng bài vào nhóm cần tối thiếu 5 nội dung khác nhau");
    } else {
      const body = {
        id_category: null,
        name: name,
        message: ingredients.mess,
        delay_time: delayTime,
        contents: comments,
        images: listImage,
        video: null,
        number_count: Number(numberCount),
        wait_time: Number(waitTime),
      };
      try {
        const res = await axios.post(createCateMkt, body, headers);
        setComments([]);
        setName("");
        setListImage([]);
        setOpenCategory(!openCategory);
        setRefresh(!refresh);
      } catch (err) {}
    }
  };
  return (
    <Dialog
      header="Kịch bản: Đăng bài vào nhóm"
      visible={openCategory}
      style={{ position: "relative", width: "70vw" }}
      onHide={() => setOpenCategory(false)}
      className="overFlowJoinGr"
      footer={
        <div className="d-flex justify-content-end gap-2 mt-2">
          <Button className=" button-blue radius" onClick={addCateMkt}>
            Lưu kịch bản
          </Button>
          <Button
            onClick={() => setOpenCategory(false)}
            className=" button-red radius"
          >
            Thoát không lưu
          </Button>
          <RiExpandDiagonal2Line
            size={20}
            style={{
              position: "absolute",
              right: "0",
              bottom: "0",
            }}
          />
        </div>
      }
    >
      <div className="d-flex flex-column h-100" style={{ overflow: "auto" }}>
        <div className="p-inputgroup flex-1 mb-2">
          <span className="p-inputgroup-addon" style={{ width: "9rem" }}>
            Tên kịch bản
          </span>
          <InputText
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder=""
          />
        </div>
        <div className="p-inputgroup flex-1 w-100">
          <div className="d-flex flex-column w-100 gap-2">
            <nav className="d-flex gap-2">
              <span>Thời gian chờ (Giây)</span>
              <OverlayTrigger
                key="tooltip"
                placement="top"
                overlay={
                  <Tooltip id="tooltip-right" className="custom-tooltip">
                    <div className="text-start">
                      Thời gian chờ giữa mỗi lần đăng bài vào nhóm
                      <br />- Ví dụ: Bạn đăng bài vào 3 nhóm thì sau khi đăng
                      bài vào nhóm thứ nhất thì sẽ chờ một khoảng thời gian do
                      bạn cài đặt rồi mới đăng bài tiếp theo vào nhóm thứ 2. Bạn
                      nên để thời gian chờ tối thiểu là 60s và khuyến khích là
                      1200s để an toàn cho tài khoản. Hành động quá nhanh sẽ dễ
                      bị hạn chế tính năng trên tài khoản do cơ chế chống spam
                      của Facebook
                    </div>
                  </Tooltip>
                }
              >
                <span
                  style={{
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                >
                  <IoInformationCircle size={15} />
                </span>
              </OverlayTrigger>
            </nav>
            <InputNumber
              style={{ width: "100%" }}
              value={delayTime}
              placeholder="Thời gian tính bằng giây (s)"
              onValueChange={(e) => setDelayTime(e.value)}
              useGrouping={false}
              min={60}
            />
          </div>
          <div className="d-flex flex-column w-100 gap-2">
            <nav className="d-flex gap-2">
              <span>Số lượt</span>
              <OverlayTrigger
                key="tooltip"
                placement="top"
                overlay={
                  <Tooltip id="tooltip-right" className="custom-tooltip">
                    <div className="text-start">
                      Đăng bài 1 bài vào 1 nhóm được tính là 1 lượt. Nên để số
                      lượt là 1. Hành động đăng bài quá nhanh sẽ dễ bị hạn chế
                      tính năng trên tài khoản do cơ chế chống spam của
                      Facebook. Và cài đặt thêm thời gian chờ lặp lại ở ô bên
                      cạnh
                    </div>
                  </Tooltip>
                }
              >
                <span
                  style={{
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                >
                  <IoInformationCircle size={15} />
                </span>
              </OverlayTrigger>
            </nav>
            <InputNumber
              style={{ width: "100%" }}
              value={numberCount}
              placeholder=""
              onValueChange={(e) => setNumberCount(e.value)}
              useGrouping={false}
              min={1}
            />
          </div>
          <div className="d-flex flex-column w-100 gap-2">
            <nav className="d-flex gap-2">
              <span style={{ width: "" }}>Thời gian chờ lặp lại (Giờ)</span>
              <OverlayTrigger
                key="tooltip"
                placement="top"
                overlay={
                  <Tooltip id="tooltip-right" className="custom-tooltip">
                    <div className="text-start">
                      Chức năng này có tác dụng khi bạn cài đặt số lượt (số lượt
                      được cài đặt ở ô bên cạnh) Chức năng này giúp chạy tiếp
                      chiến dịch hoàn toàn tự động khi bạn đặt số lượt ở ô bên
                      cạnh. Nên để thời gian là 3 giờ. Hành động đăng bài quá
                      nhanh sẽ dễ bị hạn chế tính năng trên tài khoản do cơ chế
                      chống spam của Facebook.
                    </div>
                  </Tooltip>
                }
              >
                <span
                  style={{
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                >
                  <IoInformationCircle size={15} />
                </span>
              </OverlayTrigger>
            </nav>
            <InputNumber
              style={{ width: "100%" }}
              value={waitTime}
              placeholder="Thời gian tính bằng giờ (h)"
              onValueChange={(e) => setWaitTime(e.value)}
              useGrouping={false}
              min={1}
            />
          </div>
        </div>
        <ListContentGroup
          comments={comments}
          listImage={listImage}
          setListImage={setListImage}
          valueCmt={valueCmt}
          setComments={setComments}
          setValueCmt={setValueCmt}
        />
      </div>
    </Dialog>
  );
};
