import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import axios from "axios";
import { useHeaders } from "../../../const/headers";
import { API_URL, API_ROUTES_NTD } from "../../../api";
import { TableAccountClientAddFr } from "./TableAccountClientAddFr";
import { TableAddFrOfAccount } from "./TableAddFrOfAccount";
import "primeicons/primeicons.css";
import { RiExpandDiagonal2Line } from "react-icons/ri";
export const ModalScanAddFr = ({
  openCategoryAddFr,
  setOpenCategoryAddFr,
  setRefresh,
  refresh,
  dataFb,
  checkboxes,
  setCheckboxes,
  setAllFriend,
  scriptClient,
  setScriptClient,
  allFriend,
  listFr,
  setListFr,
  toast,
  setManagerScript,
}) => {
  const headers = useHeaders();
  const scanAddFriend = API_URL + API_ROUTES_NTD.GET_SCAN_ADD_FRIEND;
  const [allAddFriend, setAllAddFriend] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const getScanAddFriend = async () => {
    if (selectedItems?.length === 0) {
      alert("Vui lòng chọn ít nhất một tài khoản trước khi bấm quét");
      return;
    }
    const body = { id_accounts: selectedItems?.map((item) => item.id) };
    try {
      await axios.post(scanAddFriend, body, headers);
      toast.success("Đang quét lời mời kết bạn");
      setSelectedItems([]);
      setRefresh(!refresh);
    } catch (err) {}
  };
  return (
    <Dialog
      header={
        <div className="d-flex align-items-center gap-2">
          <span>Lời mời chờ xác nhận</span>
          <Button
            onClick={getScanAddFriend}
            className="button-blue radius"
            style={{ height: "30px" }}
          >
            <div style={{ fontSize: "12px" }}>Quét</div>
          </Button>
        </div>
      }
      visible={openCategoryAddFr}
      style={{ position: "relative", width: "71vw" }}
      onHide={() => setOpenCategoryAddFr(false)}
      className="overFlowJoinGr"
      footer={
        <div className="d-flex justify-content-end gap-2 mt-2">
          <Button
            onClick={() => setOpenCategoryAddFr(false)}
            className="d-flex justify-content-center button-red"
          >
            Thoát
          </Button>
          <RiExpandDiagonal2Line
            size={20}
            style={{
              position: "absolute",
              right: "0",
              bottom: "0",
            }}
          />
        </div>
      }
    >
      <div className="d-flex flex-column h-100" style={{ overflow: "auto" }}>
        <div className="d-flex gap-1 w-100 h-100">
          <div style={{ width: "40%" }} className="h-100">
            <TableAccountClientAddFr
              setAllAddFriend={setAllAddFriend}
              allAddFriend={allAddFriend}
              setRefresh={setRefresh}
              refresh={refresh}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              dataFb={dataFb}
              setAllFriend={setAllFriend}
              checkboxes={checkboxes}
              setCheckboxes={setCheckboxes}
            />
          </div>
          <div style={{ width: "60%" }} className=" h-100">
            <TableAddFrOfAccount
              setAllAddFriend={setAllAddFriend}
              allAddFriend={allAddFriend}
              refresh={refresh}
              setRefresh={setRefresh}
              selectedItems={selectedItems}
              scriptClient={scriptClient}
              setScriptClient={setScriptClient}
              allFriend={allFriend}
              listFr={listFr}
              setListFr={setListFr}
              toast={toast}
              setManagerScript={setManagerScript}
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
};
