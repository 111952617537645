import React, { useEffect, useState } from "react";
import Navigate from "../container/Navigate";
import { Footer } from "./Footer";
import { TabView, TabPanel } from "primereact/tabview";
import { API_ROUTES, API_URL } from "../../api";
import axios from "axios";
import { useHeaders } from "../../const/headers";
import { Image } from "primereact/image";
import { useUser } from "../../contexts/UserContext";
import BasicDemoExpried from "../container/home/Expired";
import { updateTitle } from "../../const/updateTitle";
import BasicDemoAlert from "../container/home/Alerts";
import AlertRegister from "../container/home/AlertRegister";
import Term from "../container/home/Term";
export const SettingPage = () => {
  updateTitle("Chốt Sale - Cài Đặt");
  const { newLogo, setNewLogo, rfLogo, setRfLogo } = useUser();
  const headers = useHeaders();
  const upload = API_URL + API_ROUTES.UPLOAD_FILE_SERVER;
  const createLogo = API_URL + API_ROUTES.CREATE_LOGO;
  const [imageLink, setImageLink] = useState("");
  const [file, setFile] = useState(null);
  const updateLogo = async () => {
    const body = {
      link: imageLink,
    };
    try {
      const res = await axios.post(createLogo, body, headers);
      setRfLogo(!rfLogo);
    } catch (err) {
    }
  };
  const upLoadSever = async () => {
    if (file !== null) {
      const formData = new FormData();
      formData.append("image", file);
      try {
        const res = await axios.post(upload, formData, headers);
        setImageLink(res.data.image);
      } catch (err) {
      }
    }
  };
  useEffect(() => {
    upLoadSever();
  }, [file]);
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFile(file);
    const reader = new FileReader();
    reader.onload = () => {
      const imageDataURL = reader.result;
      const imgElement = document.getElementById("previewImage");
      if (imgElement) {
        imgElement.src = imageDataURL;
      }
    };
    reader.readAsDataURL(file);
  };
  return (
    <div className="d-flex flex-column " style={{ height: "100vh" }}>
      <div className="notifica-container">
        <div className="navigate-container">
          <Navigate />
        </div>
        <div className="card w-100">
          <TabView>
            <TabPanel header="Thông báo">
              <BasicDemoAlert />
            </TabPanel>
            <TabPanel header="Hết hạn">
              <BasicDemoExpried />
            </TabPanel>
            <TabPanel header="Logo">
              <div className="mb-4">
                <h3>Logo hiện tại</h3>
                <Image
                  src={`${API_URL}/${newLogo}`}
                  alt="Image"
                  width="250"
                  preview
                />
              </div>
              <div className="d-flex flex-column gap-2 mr-2 mb-2">
                <h3>Chọn Logo mới</h3>
                <label htmlFor="fileInput" style={{ width: "max-content" }}>
                  <button
                    className="d-flex flex-column p-2 align-items-center gap-2 border border-secondary justify-content-between rounded"
                    onClick={() =>
                      document.getElementById("fileInput").click()
                    }>
                    <p className="mb-0">Chọn ảnh</p>
                  </button>
                </label>
                <input
                  id="fileInput"
                  type="file"
                  style={{ display: "none" }}
                  onChange={handleImageChange}
                />
                {imageLink === "" ? (
                  <div></div>
                ) : (
                  <img
                    style={{
                      width: "100px",
                      display: "block",
                    }}
                    alt="Selected Image"
                    src={`${API_URL}/${imageLink}`}
                  />
                )}
                <label htmlFor="fileInput" style={{ width: "max-content" }}>
                  <button
                    onClick={updateLogo}
                    className="d-flex flex-column p-2 align-items-center gap-2 border border-secondary justify-content-between rounded">
                    <p className="mb-0">Lưu</p>
                  </button>
                </label>
              </div>
            </TabPanel>
            <TabPanel header="Trang chủ">
              <a href="/">Đến Trang chủ</a>
            </TabPanel>
            <TabPanel header="Thông báo đăng ký">
              <AlertRegister />
            </TabPanel>
            <TabPanel header="Thông báo đăng nhập lần đầu tiên">
              <Term />
            </TabPanel>
          </TabView>
        </div>
      </div>
      <Footer />
    </div>
  );
};
