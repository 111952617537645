import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { MdAccountCircle } from "react-icons/md";
import { Button } from "primereact/button";
import moment from "moment-timezone";
import axios from "axios";
import { API_URL, API_ROUTES } from "../../../api";
import { useUser } from "../../../contexts/UserContext";
import { useTranslation } from "react-i18next";
import { useHeaders } from "../../../const/headers";
export const EditSystemDebug = ({
  openEditDebug,
  setOpenEditDebug,
  setRefresh,
  refresh,
  getItemEdit,
}) => {
  const headers = useHeaders();
  const [username, setUsername] = useState(getItemEdit?.username || "");
  const [note, setNote] = useState(getItemEdit?.note || "");
  const editDb = API_URL + API_ROUTES.EDIT_DEBUG;
  const { t } = useTranslation();
  const onChangeFullName = (e) => {
    setUsername(e.target.value);
  };
  const onChangeNote = (e) => {
    setNote(e.target.value);
  };

  const editConfirm = async () => {
    const body = {
      id_debug: getItemEdit.id_debug,
      note: note,
      fixer: username,
    };
    try {
      const res = await axios.post(editDb, body, headers);
      setOpenEditDebug(false);
      setRefresh(!refresh);
    } catch (err) {}
  };
  return (
    <div className=" flex justify-content-center">
      <Dialog
        header="Ghi chú"
        visible={openEditDebug}
        style={{ width: "50vw" }}
        onHide={() => setOpenEditDebug(false)}
      >
        <div
          className="card flex flex-column flex-wrap md:flex-row gap-3"
          style={{ border: "0" }}
        >
          <div className="p-inputgroup ">
            <span className="p-inputgroup-addon">
              <MdAccountCircle />
            </span>
            <InputText
              onChange={(e) => onChangeFullName(e)}
              placeholder="Tên kỹ thuật viên"
              value={username}
            />
          </div>
          <div className="p-inputgroup ">
            <span className="p-inputgroup-addon">
              <MdAccountCircle />
            </span>
            <InputText
              onChange={(e) => onChangeNote(e)}
              placeholder="Ghi chú"
              value={note}
            />
          </div>
        </div>
        <div className="d-flex justify-content-end gap-2 mt-2">
          <Button
            className=" button-blue radius"
            label="Xác nhận"
            onClick={() => editConfirm()}
          />
          <Button
            className=" button-red radius"
            label="Hủy"
            onClick={() => setOpenEditDebug(false)}
            severity="danger"
          />
        </div>
      </Dialog>
    </div>
  );
};
