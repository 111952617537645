import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";
import { FaCalendarAlt } from "react-icons/fa";
import { CSVLink } from "react-csv";
export const ModalDataCsv = ({ setOpenCsv, openCsv, allOrder, listOrder }) => {
  const [date, setDate] = useState(null);
  const [end, setEnd] = useState(null);
  const headerss = [
    { label: "Mã Đơn Hàng", key: "id" },
    { label: "Họ Tên", key: "name_order" },
    { label: "Số điện thoại", key: "phone_number" },
    { label: "Tỉnh", key: "city" },
    { label: "Huyện", key: "district" },
    { label: "Xã", key: "ward" },
    { label: "Địa chỉ cụ thể", key: "address" },
    { label: "Sản phẩm", key: "product" },
    { label: "Số lượng", key: "quantity" },
    { label: "Khối lượng", key: "" },
    { label: "Thành tiền", key: "price" },
    { label: "Ghi chú", key: "note" },
  ];
  const filteredItems = allOrder?.filter((item) => {
    const createdAt = new Date(item.created_at);
    return createdAt >= date && createdAt <= end;
  });
  const csvData = filteredItems;
  const csvDatas = listOrder;
  const formattedDate = new Date(date)?.toLocaleString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
  const formattedEnd = new Date(end)?.toLocaleString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
  const handleExportClick = (e) => {
    if (!date || !end) {
      e.preventDefault();
      alert("Vui lòng chọn ngày bắt đầu và ngày kết thúc trước khi xuất file.");
    }
  };
  const handleExportClicks = (e) => {
    if (listOrder?.length === 0) {
      e.preventDefault();
      alert("Vui lòng chọn ít nhất 1 đơn hàng.");
    }
  };
  return (
    <div className="flex justify-content-center">
      <Dialog
        header="Xuất file Exel"
        visible={openCsv}
        onHide={() => setOpenCsv(false)}
        style={{ width: "40vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        <div className="p-inputgroup ">
          <span className="p-inputgroup-addon">
            <FaCalendarAlt />
          </span>
          <Calendar
            placeholder="Ngày bắt đầu"
            locale="vi"
            id="buttondisplay"
            value={date}
            onChange={(e) => setDate(e.value)}
            readOnlyInput
            disabledDates={[]}
          />
        </div>
        <div className="p-inputgroup ">
          <span className="p-inputgroup-addon">
            <FaCalendarAlt />
          </span>
          <Calendar
            placeholder="Ngày kết thúc"
            locale="vi"
            id="buttondisplay"
            value={end}
            onChange={(e) => setEnd(e.value)}
            readOnlyInput
            disabledDates={[]}
          />
        </div>
        <div className="d-flex justify-content-end">
          <Button className=" button-blue radius mt-3">
            <CSVLink
              className="text-light"
              data={csvData}
              headers={headerss}
              onClick={handleExportClick}
              filename={`data-${formattedDate}-${formattedEnd}.csv`}
            >
              Xuất file theo thời gian
            </CSVLink>
          </Button>
          <Button className="button-blue radius mt-3">
            <CSVLink
              className="text-light"
              data={csvDatas}
              headers={headerss}
              onClick={handleExportClicks}
              filename={`data.csv`}
            >
              Xuất file theo đơn hàng được chọn
            </CSVLink>
          </Button>
        </div>
      </Dialog>
    </div>
  );
};
