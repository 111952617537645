import { useState, useEffect } from "react";
import { Button } from "primereact/button";
import axios from "axios";
import { toast } from "react-toastify";
import { ResultsGroup } from "./ResultsGroup";
import { ModalScanGroup } from "./ModalScanGroup";
import { API_URL, API_ROUTES_NTD } from "../../api";
import { useHeaders } from "../../const/headers";

export const TableUidGroup = ({
  selecGroup,
  setSelecGroup,
  refresh,
  setRefresh,
  toast,
  resultsGroup,
  setResultsGroup,
  setTotalPage,
  totalPage,
  setGetTotal,
  getTotal,
  setRows,
  rows,
  setListResults,
  dataFb,
}) => {
  const headers = useHeaders();
  const [groupModal, setGroupModal] = useState(false);
  const exportAllGroup = API_URL + API_ROUTES_NTD.COPY_ALL_RESULTS_GROUP;
  const remoteResultsGroup = API_URL + API_ROUTES_NTD.TOOLBOX_REMOVE_GROUP;
  const remoteAllGroup = API_URL + API_ROUTES_NTD.REMOVE_ALL_GROUP_TOOL_BOX;

  const copyResults = async () => {
    let dataToCopy = "";
    if (selecGroup?.length > 0) {
      dataToCopy += `${selecGroup.map((item) => item.UID).join("\n")}`;
    }
    if (dataToCopy) {
      try {
        await navigator.clipboard.writeText(dataToCopy);
        toast.success("Sao chép thành công");
      } catch (err) {
        toast.error("Sao chép thất bại");
      }
    } else {
      toast.info("Không có dữ liệu để sao chép");
    }
  };
  const copyAllResults = async () => {
    try {
      const res = await axios.get(exportAllGroup, headers);
      const results = res.data;
      const formattedResults = results.map((item) => item).join("\n");
      await navigator.clipboard.writeText(formattedResults);
      toast.success("Sao chép thành công");
    } catch (err) {
      toast.error("Sao chép thất bại");
    }
  };
  const deleteResults = async () => {
    const confirm = window.confirm("Bạn chắc chắn muốn xoá không");
    if (confirm) {
      const bodyPage = { id_scan_groups: selecGroup?.map((item) => item?.id) };
      try {
        const res = await axios.post(remoteResultsGroup, bodyPage, headers);
        setRefresh(!refresh);
        setSelecGroup([]);
        toast.success("Xoá thành công");
      } catch (err) {
        toast.error("Xoá thất bại");
      }
    }
  };
  const deleteAllResults = async () => {
    const confirm = window.confirm("Bạn chắc chắn muốn xoá không");
    if (confirm) {
      try {
        const res = await axios.get(remoteAllGroup, headers);
        setRefresh(!refresh);
        toast.success("Xoá thành công");
      } catch (err) {
        toast.error("Xoá thất bại");
      }
    }
  };
  return (
    <div className="d-flex  h-100 w-100">
      <div
        className="overflow-auto h-100 d-flex flex-column"
        style={{
          width: "100%",
        }}
      >
        <div
          className="d-flex gap-2 w-100 justify-content-end pl-2 pr-2 pt-2 "
          id="lisstSeclect"
        >
          {" "}
          <Button
            style={{ fontSize: "12px", width: "100%" }}
            onClick={() => setGroupModal(true)}
            className="d-flex justify-content-center button-blue"
          >
            Quét UID Group
          </Button>
          <Button
            style={{ fontSize: "12px", width: "100%" }}
            className="d-flex justify-content-center button-blue"
            onClick={copyResults}
          >
            Sao chép
          </Button>
          <Button
            style={{ fontSize: "12px", width: "100%" }}
            className="d-flex justify-content-center button-blue"
            onClick={copyAllResults}
          >
            Sao chép tất cả
          </Button>
          <Button
            style={{ fontSize: "12px", width: "100%" }}
            onClick={deleteResults}
            className="d-flex justify-content-center button-red"
          >
            Xoá kết quả
          </Button>
          <Button
            style={{ fontSize: "12px", width: "100%" }}
            onClick={deleteAllResults}
            className="d-flex justify-content-center button-red"
          >
            Xoá tất cả kết quả
          </Button>
        </div>
        <div
          className="d-flex flex-column  gap-2 h-100 m-2"
          style={{ overflow: "hidden" }}
        >
          <div className="d-flex w gap-2 h-100" style={{ border: "1px solid" }}>
            <ResultsGroup
              resultsGroup={resultsGroup}
              setResultsGroup={setResultsGroup}
              toast={toast}
              setRefresh={setRefresh}
              refresh={refresh}
              selecGroup={selecGroup}
              setSelecGroup={setSelecGroup}
              rows={rows}
              setRows={setRows}
              setListResults={setListResults}
              totalPage={totalPage}
              setTotalPage={setTotalPage}
              getTotal={getTotal}
              setGetTotal={setGetTotal}
            />
          </div>
          <ModalScanGroup
            dataFb={dataFb}
            toast={toast}
            groupModal={groupModal}
            setGroupModal={setGroupModal}
          />
        </div>
      </div>
    </div>
  );
};
