import React from "react";
import { Dialog } from "primereact/dialog";
export const ModalDetailsReels = ({
  setModalDetails,
  modalDetails,
  itemResults,
}) => {
  return (
    <Dialog
      header="Trạng thái chi tiết"
      visible={modalDetails}
      style={{ width: "50vw" }}
      onHide={() => setModalDetails(false)}
    >
      <div className="f-flex flex-column gap-2">
        <nav
          style={{
            paddingBottom: "0",
            paddingTop: "0.5rem",
          }}
          className="d-flex gap-2"
        >
          <h5>UID: {itemResults?.account?.UID}</h5>
          <h5>{itemResults?.account?.name}</h5>
        </nav>

        <nav
          style={{
            borderBottom: "1px solid",
            paddingBottom: "0.5rem",
            paddingTop: "0",
          }}
          className="d-flex gap-2"
        >
          <h6 style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <>
              <p>Trạng thái đăng video:</p>
              <p>
                {itemResults?.status === 1
                  ? "Thành công"
                  : itemResults?.status === 0
                  ? "Thất bại"
                  : "Tính năng bị chặn"}
              </p>
            </>
          </h6>
        </nav>
        <nav
          style={{
            paddingBottom: "0.5rem",
            paddingTop: "0.5rem",
          }}
          className=""
        >
          <nav
            style={{
              paddingBottom: "0.5rem",
              paddingTop: "0.5rem",
            }}
            className="d-flex gap-2 w-100"
          >
            <p style={{ width: "max-content" }}>Nội dung đăng video:</p>
            <p style={{ width: "fit-content", textAlign: "justify" }}>
              {itemResults?.content}
            </p>
          </nav>
        </nav>

        <nav
          style={{
            paddingBottom: "0.5rem",
            paddingTop: "0.5rem",
          }}
          className="d-flex gap-2"
        >
          <p style={{ width: "fit-content", textAlign: "justify" }}>
            Link video:
          </p>

          <a
            href={itemResults?.video}
            target="_blank"
            rel="nofollow noreferrer"
          >
            {`${itemResults?.video?.slice(0, 50)}...`}
          </a>
        </nav>
      </div>
    </Dialog>
  );
};
