import axios from "axios";
import { API_URL, API_ROUTES } from "../api";
export const deleteFileOnServer = async (url) => {
  const rfLocal = localStorage.getItem("access");
  const deleteByServe = API_URL + API_ROUTES.DELETE_BY_LINK;
  if (url.includes("https")) {
    return;
  }
  const data = {
    url: url,
  };
  try {
    const res = await axios.post(deleteByServe, data, {
      headers: {
        Authorization: `Bearer ${rfLocal}`,
      },
    });
  } catch (err) {}
};
