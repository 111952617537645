import React, { useEffect, useState, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import axios from "axios";
import { useHeaders } from "../../../const/headers";
import { API_URL, API_ROUTES, API_ROUTES_NTD } from "../../../api";
import { Checkbox } from "primereact/checkbox";
import { CommentScript } from "./script/CommentScript";
import { InboxScript } from "./script/InboxScript";
import { InputNumber } from "primereact/inputnumber";
import { Tooltip, OverlayTrigger, Popover } from "react-bootstrap";
import { IoInformationCircle } from "react-icons/io5";
import { RiExpandDiagonal2Line } from "react-icons/ri";

export const ScriptMarketing = ({
  props,
  modalScript,
  setModalScript,
  setRefresh,
  refresh,
}) => {
  const headers = useHeaders();
  const createCateMkt = API_URL + API_ROUTES_NTD.CREATE_EDIT_CATEGORY_MARKETING;
  const [listImage, setListImage] = useState([]);
  const [listImageInbox, setListImageInbox] = useState([]);
  const [name, setName] = useState("");
  const [ingredients, setIngredients] = useState({
    like: false,
    comment: false,
    share: false,
    addfr: false,
    mess: false,
  });
  const [valueCmt, setValueCmt] = useState("");
  const [valueInbox, setValueInbox] = useState("");
  const [comments, setComments] = useState([]);
  const [inbox, setInbox] = useState([]);
  const [inboxSpilt, setInboxSplit] = useState([]);
  const [listUid, setListUid] = useState("");
  const [uidTag, setUidTag] = useState("");
  const [textSearch, setTextSearch] = useState("");
  const [delayTime, setDelayTime] = useState(30);
  const [numberCount, setNumberCount] = useState(0);
  const [waitTime, setWaitTime] = useState(0);
  const onIngredientsChange = (e) => {
    const { value, checked } = e.target;
    setIngredients((prevIngredients) => ({
      ...prevIngredients,
      [value]: checked,
    }));
  };
  const addCateMkt = async () => {
    if (!name || !listUid) {
      alert("Vui lòng nhập đầy đủ thông tin");
    } else if (
      ingredients.comment === false &&
      ingredients.like === false &&
      ingredients.mess === false &&
      ingredients.share === false &&
      ingredients.addfr === false
    ) {
      alert("Vui lòng chọn chức năng");
    } else if (ingredients.comment === true && comments?.length === 0) {
      alert("Vui lòng nhập nội dung bình luận");
    } else if (ingredients.mess === true && inbox?.length === 0) {
      alert("Vui lòng nhập nội dung tin nhắn");
    } else if (ingredients.comment === true && comments?.length < 5) {
      alert("kịch bản bình luận cần tối thiếu 5 nội dung khác nhau");
    } else if (ingredients.mess === true && inbox?.length < 5) {
      alert("kịch bản tin nhắn cần tối thiếu 5 nội dung khác nhau");
    } else {
      const body = {
        id_category: null,
        name: name,
        add_friend: ingredients.addfr,
        like: ingredients.like,
        comment: ingredients.comment,
        share: ingredients.share,
        message: ingredients.mess,
        delay_time: delayTime,
        comment_contents: comments,
        comment_images: listImage,
        message_contents: inbox
          ?.map((item) => item)
          ?.map((subArray) => {
            if (subArray?.length > 1) {
              return subArray?.split("[ngatcau]");
            }
            return subArray[0];
          }),
        message_images: listImageInbox,
        tag: uidTag,
        list_UID: listUid.split("\n")?.filter((i) => i !== ""),
        keywords: textSearch ? textSearch.split("||") : [],
        number_count: numberCount,
        wait_time: waitTime,
      };
      try {
        const res = await axios.post(createCateMkt, body, headers);
        setTextSearch("");
        setListUid("");
        setUidTag("");
        setInboxSplit([]);
        setInbox([]);
        setComments([]);
        setName("");
        setListImage([]);
        setListImageInbox([]);
        setModalScript(!modalScript);
        setRefresh(!refresh);
      } catch (err) {}
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  return (
    <Dialog
      header="Kịch bản"
      visible={modalScript}
      style={{ position: "relative", width: "70vw" }}
      onHide={() => setModalScript(false)}
      className="overFlowJoinGr"
      footer={
        <div className="d-flex justify-content-end gap-2 mt-2">
          <Button className="button-blue radius" onClick={addCateMkt}>
            Lưu kịch bản
          </Button>
          <Button
            className="button-red radius"
            onClick={() => setModalScript(false)}
          >
            Thoát không lưu
          </Button>
          <RiExpandDiagonal2Line
            size={20}
            style={{
              position: "absolute",
              right: "0",
              bottom: "0",
            }}
          />
        </div>
      }
    >
      <div className="d-flex flex-column h-100" style={{ overflow: "auto" }}>
        <div className="p-inputgroup flex-1 mb-2">
          <span className="p-inputgroup-addon " style={{ width: "9rem" }}>
            Tên kịch bản
          </span>
          <InputText
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Nhập tên kịch bản"
          />
        </div>
        <div className="p-inputgroup flex-1 w-100">
          <div className="d-flex flex-column w-100 gap-2">
            <nav className="d-flex gap-2">
              <span>Thời gian chờ (Giây)</span>
              <OverlayTrigger
                key="tooltip"
                placement="bottom"
                overlay={
                  <Tooltip id="tooltip-right" className="custom-tooltip ">
                    <div className="text-start custom-tooltip-content">
                      Thời gian chờ giữa mỗi hành động trong một chuỗi nhiều
                      hành động
                      <br />- Ví dụ: Bạn tích chọn 3 chức năng "Gửi tin nhắn +
                      Bình luận + Like bài viết". Thì đây là một chuỗi 3 hành
                      động liên tiếp. Bạn nên để thời gian chờ tối thiểu là 60s
                      và khuyến khích là 180s để an toàn cho tài khoản. Hành
                      động quá nhanh sẽ dễ bị hạn chế tính năng trên tài khoản
                      do cơ chế chống spam của Facebook
                    </div>
                  </Tooltip>
                }
              >
                <span
                  style={{
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                >
                  <IoInformationCircle size={15} />
                </span>
              </OverlayTrigger>
            </nav>
            <InputNumber
              style={{ width: "100%" }}
              value={delayTime}
              placeholder="Thời gian tính bằng giây (s)"
              onValueChange={(e) => setDelayTime(e.value)}
              useGrouping={false}
              min={60}
            />
          </div>
          <div className="d-flex flex-column w-100 gap-2">
            <nav className="d-flex gap-2">
              <span>Số lượt</span>
              <OverlayTrigger
                key="tooltip"
                placement="bottom"
                overlay={
                  <Tooltip id="tooltip-right" className="custom-tooltip">
                    <div className="text-start">
                      Tài khoản tương tác với mỗi UID trong danh sách UID được
                      tính là một lượt. Chức năng nhập số lượt này giúp giới hạn
                      số lần spam trên mỗi tài khoản trong một thời điểm, nhằm
                      giúp bảo vệ tài khoản, tránh bị tình trạng hạn chế tính
                      năng. Nên nhập số lượt là 1 lượt. Và cài đặt thêm thời
                      gian chờ lặp lại ở ô bên cạnh
                    </div>
                  </Tooltip>
                }
              >
                <span
                  style={{
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                >
                  <IoInformationCircle size={15} />
                </span>
              </OverlayTrigger>
            </nav>
            <InputNumber
              style={{ width: "100%" }}
              value={numberCount}
              placeholder=""
              onValueChange={(e) => setNumberCount(e.value)}
              useGrouping={false}
              min={1}
            />
          </div>
          <div className="d-flex flex-column w-100 gap-2">
            <nav className="d-flex gap-2">
              <span style={{ width: "" }}>Thời gian chờ lặp lại (Giờ)</span>
              <OverlayTrigger
                key="tooltip"
                placement="bottom"
                overlay={
                  <Tooltip id="tooltip-right" className="custom-tooltip">
                    <div className="d-flex text-start">
                      <p style={{ whiteSpace: "pre-wrap" }}>
                        Chức năng này có tác dụng khi bạn cài đặt số lượt (số
                        lượt được cài đặt ở ô bên cạnh). Chức năng này giúp chạy
                        tiếp chiến dịch hoàn toàn tự động khi bạn đặt số lượt ở
                        ô bên cạnh. Nên để là 1 giờ. Hành động quá nhanh sẽ dễ
                        bị hạn chế tính năng trên tài khoản do cơ chế chống spam
                        của Facebook
                      </p>
                    </div>
                  </Tooltip>
                }
              >
                <span
                  style={{
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                >
                  <IoInformationCircle size={15} />
                </span>
              </OverlayTrigger>
            </nav>
            <InputNumber
              style={{ width: "100%" }}
              value={waitTime}
              placeholder="Thời gian tính bằng giờ (h)"
              onValueChange={(e) => setWaitTime(e.value)}
              useGrouping={false}
              min={1}
            />
          </div>
        </div>
        <div className="flex flex-wrap justify-content-between gap-3 mt-2">
          <div className="flex align-items-center">
            <Checkbox
              inputId="ingredient5"
              name="pizza"
              value="mess"
              onChange={onIngredientsChange}
              checked={ingredients.mess}
            />
            <label htmlFor="ingredient5" className="ml-2">
              Gửi tin nhắn
            </label>
          </div>
          <div className="flex align-items-center">
            <Checkbox
              inputId="ingredient2"
              name="pizza"
              value="comment"
              onChange={onIngredientsChange}
              checked={ingredients.comment}
            />
            <label htmlFor="ingredient2" className="ml-2">
              Bình luận bài viết
            </label>
          </div>
          <div className="flex align-items-center">
            <Checkbox
              inputId="ingredient4"
              name="pizza"
              value="addfr"
              onChange={onIngredientsChange}
              checked={ingredients.addfr}
            />
            <label htmlFor="ingredient4" className="ml-2">
              Kết bạn
            </label>
          </div>

          <div className="flex align-items-center">
            <Checkbox
              inputId="ingredient1"
              name="pizza"
              value="like"
              onChange={onIngredientsChange}
              checked={ingredients.like}
            />
            <label htmlFor="ingredient1" className="ml-2">
              Like bài viết
            </label>
          </div>
          <div className="flex align-items-center">
            <Checkbox
              inputId="ingredient3"
              name="pizza"
              value="share"
              onChange={onIngredientsChange}
              checked={ingredients.share}
            />
            <label htmlFor="ingredient3" className="ml-2">
              Chia sẻ bài viết
            </label>
          </div>
        </div>
        <div className="p-inputgroup flex-1 mt-2">
          <InputText
            value={textSearch}
            onChange={(e) => setTextSearch(e.target.value)}
            placeholder="Nhập từ khoá tìm kiếm (Vui lòng nhập định dạng áo quần||xe đạp||.... , nếu nhập nhiều từ khoá khác nhau)"
          />
        </div>
        <div className="p-inputgroup flex-1 mt-2 mb-2 gap-2">
          <nav style={{ width: "50%" }}>
            <nav className="d-flex gap-2">
              <span style={{ width: "" }}>Danh sách UID</span>
              <OverlayTrigger
                key="tooltip"
                placement="bottom"
                overlay={
                  <Tooltip id="tooltip-right" className="custom-tooltip">
                    <div className="text-start">
                      - Có thể nhập nhiều UID. Nên nhập 1 dạng UID đồng nhất. Sẽ
                      dễ kiểm soát và quản lý. Nếu là danh sách 100 UID người
                      dùng Facebook, tài khoản của bạn sẽ tương tác các chức
                      năng được tích chọn với bài viết đầu tiên hoặc bài viết có
                      chứa từ khóa trên tường cá nhân của UID người dùng
                      Facebook có trong danh sách.
                      <br />
                      - Lưu ý:
                      <br />
                      - Khi chọn từ 2 tài khoản trở lên để cùng chạy 1 chiến
                      dịch, sẽ chỉ có 1 tài khoản thực hiện hành động đến 1 UID
                      <br />
                      - Nếu bạn muốn nhiều tài khoản của bạn đều hành động đến
                      tất cả các UID trong danh sách thì có thể sao chép ra
                      thành nhiều chiến dịch, mỗi chiến dịch chạy bằng 1 tài
                      khoản
                      <br />
                      - Nhập danh sách UID cần tiếp cận vào đây
                      <br />
                      - Vui lòng nhập định dạng
                      <br />
                      0123654478964
                      <br />
                      154502134564566
                      <br />
                      ....
                    </div>
                  </Tooltip>
                }
              >
                <span
                  style={{
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                >
                  <IoInformationCircle size={15} />
                </span>
              </OverlayTrigger>
            </nav>
            <InputTextarea
              style={{ width: "100%", height: "80%", marginTop: "9px" }}
              value={listUid}
              onChange={(e) => setListUid(e.target.value)}
              placeholder={``}
              rows={5}
              cols={30}
            />
          </nav>
          <nav style={{ width: "50%" }}>
            <div className="d-flex">
              <p className="mb-2 mr-2">UID được tag</p>
              <OverlayTrigger
                key="tooltip"
                placement="bottom"
                overlay={
                  <Tooltip id="tooltip-right" className="custom-tooltip">
                    <div className="text-start">
                      - Chỉ nhập duy nhất 1 UID
                      <br />- Ví dụ: UID Facebook của Fanpage, Group
                    </div>
                  </Tooltip>
                }
              >
                <span
                  style={{
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                >
                  <IoInformationCircle size={15} />
                </span>
              </OverlayTrigger>
            </div>
            <InputTextarea
              style={{ width: "100%", height: "80%" }}
              value={uidTag}
              onChange={(e) => setUidTag(e.target.value)}
              onKeyDown={handleKeyDown}
              placeholder=""
              rows={1}
              cols={30}
            />
          </nav>
        </div>
        <CommentScript
          comments={comments}
          listImage={listImage}
          setListImage={setListImage}
          valueCmt={valueCmt}
          setComments={setComments}
          setValueCmt={setValueCmt}
        />
        <InboxScript
          listImageInbox={listImageInbox}
          setListImageInbox={setListImageInbox}
          valueInbox={valueInbox}
          setInbox={setInbox}
          inbox={inbox}
          setValueInbox={setValueInbox}
          inboxSpilt={inboxSpilt}
          setInboxSplit={setInboxSplit}
        />
      </div>
    </Dialog>
  );
};
