import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";
import { API_URL, API_ROUTES_NTD } from "../../api";
import axios from "axios";
import { useHeaders } from "../../const/headers";
import { RiExpandDiagonal2Line } from "react-icons/ri";
export const ModalScanGroup = ({
  dataFb,
  groupModal,
  setGroupModal,
  toast,
  setHiddenPage,
  setHiddenUser,
  setHiddenGroup,
}) => {
  const startScanGroup = API_URL + API_ROUTES_NTD.TOOlBOX_GROUP_SCAN;
  const headers = useHeaders();
  const [keyword, setKeyword] = useState("");
  const [quantity, setQuantity] = useState("");
  const [selectedPublish, setSelectedPublish] = useState(null);
  const [selecAccount, setSelectAccount] = useState(null);
  const countries = dataFb?.filter((item) => item.checkpoint === false);
  const cities = [
    { name: "Nhóm công khai", code: true },
    { name: "Tất cả nhóm", code: false },
  ];
  const selecAccountTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <img
            alt={option.name}
            src={option.avatar}
            className="mr-2  rounded-circle"
            style={{ width: "20px" }}
          />
          <div>{option.name}</div>
        </div>
      );
    }
    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <img
          alt={option.name}
          src={option.avatar}
          className="mr-2  rounded-circle"
          style={{ width: "20px" }}
        />
        <div>{option.name}</div>
      </div>
    );
  };
  const scanToolUser = async () => {
    if (
      selecAccount?.id === undefined ||
      !keyword ||
      !quantity ||
      selectedPublish?.code === undefined
    ) {
      alert("Vui lòng nhập đầy đủ thông tin");
      return;
    }
    const body = {
      id_account: selecAccount?.id,
      keyword: keyword,
      quantity: quantity,
      publish: selectedPublish?.code,
    };
    try {
      const res = await axios.post(startScanGroup, body, headers);
      setGroupModal(false);
      toast.success(res?.data?.message);
      setSelectedPublish(null);
      setKeyword("");
      setQuantity("");
      setHiddenUser(false);
      setHiddenPage(false);
      setHiddenGroup(true);
      setSelectAccount(null);
    } catch (err) {}
  };
  return (
    <Dialog
      header="Thêm thông tin"
      visible={groupModal}
      style={{ position: "relative", width: "50vw" }}
      onHide={() => setGroupModal(false)}
      className="overFlowJoinGr"
      footer={
        <div className="d-flex justify-content-end gap-2 mt-2">
          <Button className="button-blue radius" onClick={scanToolUser}>
            Bắt đầu quét
          </Button>
          <Button
            onClick={() => setGroupModal(false)}
            className="button-red radius"
          >
            Thoát không lưu
          </Button>
          <RiExpandDiagonal2Line
            size={20}
            style={{
              position: "absolute",
              right: "0",
              bottom: "0",
            }}
          />
        </div>
      }
    >
      <div className="d-flex flex-column justify-content-end gap-2 mt-2">
        <div className="p-inputgroup flex-1 ">
          <span className="p-inputgroup-addon " style={{ width: "9rem" }}>
            Tài khoản
          </span>
          <Dropdown
            value={selecAccount}
            onChange={(e) => setSelectAccount(e.value)}
            options={countries}
            optionLabel="name"
            placeholder="Danh sách tài khoản"
            filter
            valueTemplate={selecAccountTemplate}
            itemTemplate={countryOptionTemplate}
            className="w-full md:w-14rem pl-2"
          />
        </div>
        <div>
          <div className="p-inputgroup flex-1 mb-2">
            <span className="p-inputgroup-addon " style={{ width: "9rem" }}>
              Từ khoá
            </span>
            <InputText
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              placeholder="Từ khoá ( Chỉ nhập 1 từ khóa tìm kiếm ví dụ: Gia dụng)"
            />
          </div>
          <div className="p-inputgroup flex-1 mb-2">
            <span className="p-inputgroup-addon" style={{ width: "9rem" }}>
              Số lượng
            </span>
            <InputNumber
              value={quantity}
              placeholder="Số lượng UID cần quét"
              onValueChange={(e) => setQuantity(e.value)}
              useGrouping={false}
            />
          </div>
        </div>
        <div className="p-inputgroup flex-1 mb-2">
          <span className="p-inputgroup-addon " style={{ width: "9rem" }}>
            Quét
          </span>
          <Dropdown
            value={selectedPublish}
            onChange={(e) => setSelectedPublish(e.value)}
            options={cities}
            optionLabel="name"
            placeholder="Chọn giá trị"
            className="w-full md:w-14rem"
          />
        </div>
      </div>
    </Dialog>
  );
};
