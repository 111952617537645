import { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import {
  toggleSelectAll,
  headerCheckbox,
  bodyCheckbox,
} from "../../../const/checkboxPrimeReactjs";
import { Chip } from "primereact/chip";
import axios from "axios";
import { useHeaders } from "../../../const/headers";
import { API_URL, API_ROUTES_NTD } from "../../../api";
import { rowClassName } from "../../../const/checkboxPrimeReactjs";
import { onRowClick } from "../../../const/checkboxPrimeReactjs";
import { Paginator } from "primereact/paginator";
import { getAxiosMarket } from "../../../const/getAxios";
export const TableGroupOfInviteFriend = ({
  allFriend,
  allGroup,
  toast,
  setListFr,
  listFr,
  listGr,
  setListGr,
  setManagerScript,
  setScriptClient,
  refresh,
  setRefresh,
  setGetTotal,
  setAllGroup,
  rows,
  totalPage,
  setTotalPage,
  setRows,
  getTotal,
  selectedItems,
}) => {
  const headers = useHeaders();
  const deleteGroup = API_URL + API_ROUTES_NTD.DELETE_GROUP;
  const resultsDelete = API_URL + API_ROUTES_NTD.RESULTS_DELETE_GROUP;
  const getAllGroup = API_URL + API_ROUTES_NTD.GET_ALL_GROUP;
  const rowsPerPageOptions = [100, 300, 500, 1000, 2000];
  const [first, setFirst] = useState(0);
  const onPageChange = (event) => {
    setTotalPage(event.page);
    setFirst(event.first);
    setRows(event.rows);
  };
  const getDataAllGr = () => {};
  useEffect(() => {
    if (selectedItems?.length === 0) {
      setAllGroup([]);
      return;
    }
    getAxiosMarket(
      getAllGroup,
      setAllGroup,
      setGetTotal,
      rows,
      totalPage + 1,
      selectedItems[0]?.id
    );
  }, [refresh, selectedItems, totalPage, rows]);
  const indexTemplate = (rowData, rowIndex) => {
    return <>{rowIndex.rowIndex + 1 + rows * totalPage}</>;
  };

  return (
    <div className="h-100 ">
      <DataTable
        size="small"
        value={allGroup}
        className="paginator small-font-table dataTableWithFixedHeader dataTableWithHoverEffect custom-scrollbar"
        tableStyle={{ minWidth: "100%" }}
        rowClassName={(rowData) => rowClassName(rowData, listGr)}
        onRowClick={(e) => onRowClick(e.data, setListGr, listGr)}
      >
        <Column
          header={headerCheckbox(listGr, allGroup, toggleSelectAll, setListGr)}
          body={(rowData) =>
            bodyCheckbox(rowData, listGr, setListGr, getDataAllGr)
          }
        />
        <Column body={indexTemplate} header="STT"></Column>
        <Column field="group_uid" header="UID nhóm" sortable></Column>
        <Column field="name" header="Nhóm" sortable></Column>
      </DataTable>
      <Paginator
        className="pagination-markerting"
        first={first}
        rows={rows}
        totalRecords={getTotal?.count}
        onPageChange={onPageChange}
        rowsPerPageOptions={rowsPerPageOptions}
      />
    </div>
  );
};
