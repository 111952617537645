import { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import {
  toggleSelectAll,
  headerCheckbox,
  bodyCheckbox,
} from "../../../const/checkboxPrimeReactjs";
import axios from "axios";
import { useHeaders } from "../../../const/headers";
import { API_URL, API_ROUTES_NTD } from "../../../api";
import { rowClassName } from "../../../const/checkboxPrimeReactjs";
import { onRowClick } from "../../../const/checkboxPrimeReactjs";
export const TableAddFrOfAccount = ({
  toast,
  setListFr,
  listFr,
  setRefresh,
  refresh,
  allAddFriend,
}) => {
  const headers = useHeaders();
  const acceptAddFriend = API_URL + API_ROUTES_NTD.ACCEPT_ADD_FRIEND;
  const resultAcceptAddFriend =
    API_URL + API_ROUTES_NTD.ACCEPT_ADD_FRIEND_RESULT;
  const deleteAddFriend = API_URL + API_ROUTES_NTD.DELETE_ADD_FRIEND;
  const resultDeleteAddFriend =
    API_URL + API_ROUTES_NTD.REJECT_ADD_FRIEND_RESULT;
  const getDataAllFr = () => {};
  const [acceptFriend, setAcceptFriend] = useState("");
  const [rejectFriend, setRejectFriend] = useState("");
  const [getTask, setGetTask] = useState([]);
  const [getTaskDele, setGetTaskDele] = useState([]);
  const getAcceptAddFriend = async (rowData) => {
    const body = { id_account: rowData?.account, id_friending: rowData?.id };
    try {
      const res = await axios.post(acceptAddFriend, body, headers);
      setGetTask(res.data);
      getRsAcceptAddFriend(res.data);
      toast.info("Đang chấp nhận lời mời kết bạn");
      setRefresh(!refresh);
    } catch (err) {}
  };
  const getRsAcceptAddFriend = async (taskid) => {
    const body = { id_task: taskid.id_task };
    try {
      const res = await axios.post(resultAcceptAddFriend, body, headers);
      if (res.data.status === "SUCCESS") {
        toast.success("Kết bạn thành công");
        setRefresh(!refresh);
      } else if (res.data.status === "FAILURE") {
        toast.error("Kết bạn thất bại");
      }
      setRefresh(!refresh);
      setAcceptFriend(res.data.status);
    } catch (err) {
      setAcceptFriend(err.res.data.status);
      toast.error("Đã xảy ra lỗi khi kết bạn");
    }
  };
  useEffect(() => {
    if (getTask.id_task) {
      const interval = setInterval(() => {
        getRsAcceptAddFriend(getTask);
      }, 3000);
      if (acceptFriend === "SUCCESS" || acceptFriend === "FAILURE") {
        clearInterval(interval);
      }
      return () => clearInterval(interval);
    }
  }, [acceptFriend, getTask]);
  const getDeleteAddFriend = async (rowData) => {
    const body = { id_account: rowData?.account, id_friending: rowData?.id };
    try {
      const res = await axios.post(deleteAddFriend, body, headers);
      setGetTaskDele(res.data);
      getRejectAddFriend(res.data);
      toast.info("Đang xoá lời mời kết bạn");
      setRefresh(!refresh);
    } catch (err) {}
  };
  const getRejectAddFriend = async (taskidRj) => {
    const body = { id_task: taskidRj.id_task };
    try {
      const res = await axios.post(resultDeleteAddFriend, body, headers);
      if (res.data.status === "SUCCESS") {
        toast.success("Xóa lời mời kết bạn thành công");
        setRefresh(!refresh);
      } else if (res.data.status === "FAILURE") {
        toast.error("Xóa lời mời kết bạn thất bại");
      }
      setRefresh(!refresh);
      setRejectFriend(res.data.status);
    } catch (err) {
      setRejectFriend(err.res.data.status);
      toast.error("Đã xảy ra lỗi khi xoá lời mời kết bạn");
    }
  };
  useEffect(() => {
    if (getTaskDele.id_task) {
      const interval = setInterval(() => {
        getRejectAddFriend(getTaskDele);
      }, 3000);
      if (rejectFriend === "SUCCESS" || rejectFriend === "FAILURE") {
        clearInterval(interval);
      }
      return () => clearInterval(interval);
    }
  }, [rejectFriend, getTaskDele]);
  const buttonOption = (rowData) => {
    return (
      <div style={{ width: "150px" }} className="d-flex gap-2 flex-column">
        <Button
          className=" button-blue radius"
          style={{
            padding: "4px 6px",
            width: "50%",
            fontSize: "12px",
            display: "flex",
            justifyContent: "center",
          }}
          onClick={() => {
            getAcceptAddFriend(rowData);
          }}
        >
          Đồng ý
        </Button>
        <Button
          className=" button-red radius"
          severity="danger"
          style={{
            padding: "4px 6px",
            width: "50%",
            fontSize: "12px",
            display: "flex",
            justifyContent: "center",
          }}
          onClick={() => getDeleteAddFriend(rowData)}
        >
          Huỷ
        </Button>
      </div>
    );
  };
  const indexTemplate = (rowData, rowIndex) => {
    return <>{rowIndex.rowIndex + 1}</>;
  };
  return (
    <div style={{ border: "1px solid", width: "100%", height: "400px" }}>
      <DataTable
        size="small"
        value={allAddFriend}
        className="small-font-table dataTableWithFixedHeader dataTableWithHoverEffect custom-scrollbar"
        tableStyle={{ minWidth: "100%" }}
        rowClassName={(rowData) => rowClassName(rowData, listFr)}
        onRowClick={(e) => onRowClick(e.data, setListFr, listFr)}
      >
        <Column
          header={headerCheckbox(
            listFr,
            allAddFriend,
            toggleSelectAll,
            setListFr
          )}
          body={(rowData) =>
            bodyCheckbox(rowData, listFr, setListFr, getDataAllFr)
          }
        />
        <Column body={indexTemplate} header="STT"></Column>
        <Column field="uid" header="UID khách hàng" sortable></Column>
        <Column field="name" header="Tên khách hàng" sortable></Column>
        <Column field="created_time" header="Thời gian" sortable></Column>
        <Column field="mutual_friends" header="Bạn chung" sortable></Column>

        <Column body={(rowData) => buttonOption(rowData)} header="Tuỳ chọn" />
      </DataTable>
    </div>
  );
};
