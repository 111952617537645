import React from "react";
export const truncateLabel = (text) => {
  return text?.length > 8 ? text.substring(0, 8) + "..." : text;
};
export const truncateMess = (text) => {
  return text?.length > 25 ? text.substring(0, 25) + "..." : text;
};
export const truncateText = (text) => {
  return text?.length > 20 ? `${text.slice(0, 20)}...` : text;
};
export const truncateMarket = (text , num) => {
  return text?.length > num ? `${text.slice(0, num)}...` : text;
};

export const formatText = (text) => {
  return text?.split("[ngatcau]")?.map((part, index) => (
    <React.Fragment key={index}>
      {part}
      {index < text.split("[ngatcau]").length - 1 && <br />}
    </React.Fragment>
  ));
};

export const removeItem = (index, setItem) => {
  setItem((prevImages) => prevImages.filter((_, i) => i !== index));
};
