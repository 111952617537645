import { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import {
  toggleSelectAll,
  headerCheckbox,
  bodyCheckbox,
  rowClassName,
  onRowClick,
} from "../../../const/checkboxPrimeReactjs";
import { Chip } from "primereact/chip";
import { ModalCateMarket } from "./ModalCateMarket";
import { MultiSelect } from "primereact/multiselect";
import { API_URL, API_ROUTES_NTD } from "../../../api";
import { useHeaders } from "../../../const/headers";
import { useNavigate } from "react-router-dom";
import { Card } from "primereact/card";
import { FiExternalLink } from "react-icons/fi";
import axios from "axios";
import { MdDelete } from "react-icons/md";
import { Paginator } from "primereact/paginator";
import { FaLocationDot } from "react-icons/fa6";
import { LuFolderEdit } from "react-icons/lu";
import { truncateMarket } from "../../../const/splittext";
import { filterByName } from "../../../const/filter";
import { Dialog } from "primereact/dialog";
import { FaCalendarCheck } from "react-icons/fa";
export const ListCateMarketplace = ({
  setRefresh,
  refresh,
  allCate,
  setSelecCate,
  selecCate,
  keywords,
  setAllCate,
}) => {
  const addCateInMkt = API_URL + API_ROUTES_NTD.ADD_CATEGORY_IN_ACCOUNT;
  const apiDelete = API_URL + API_ROUTES_NTD.DELETE_CATEGORY_MARKETPLACE;
  const [dataFilter, setDataFilter] = useState([]);
  const navigate = useNavigate();
  const headers = useHeaders();
  const [modalManage, setModalManage] = useState(false);
  const [itemManage, setItemManage] = useState([]);
  const [openDirection, setOpenDirection] = useState(false);
  const formatPrice = (price) => {
    if (typeof price !== "number") {
      return "";
    }
    return new Intl.NumberFormat("vi-VN").format(price) + " đ";
  };
  const handleDetete = async (item) => {
    const confirm = window.confirm("Bạn chắc chắn muốn xoá không");
    if (confirm) {
      const body = {
        id_category: item.id,
      };
      try {
        await axios.post(apiDelete, body, headers);
        setRefresh(!refresh);
        setSelecCate([]);
      } catch (err) {}
    }
  };
  useEffect(() => {
    keywords.length === 0
      ? setDataFilter(allCate)
      : setDataFilter(filterByName(allCate, keywords));
    setSelecCate([]);
  }, [keywords, allCate]);
  return (
    <div className=" " style={{ border: "1px solid", width: "70%" }}>
      <div className="w-100 h-100 p-4 d-flex flex-column align-items-between justify-content-between">
        <div className="d-flex flex-column " style={{ height: "100%" }}>
          <nav className="d-flex justify-content-between">
            <nav className="d-flex gap-4">
              <h2 style={{ marginBottom: "-1px" }}>Sản phẩm của bạn</h2>
              <Button
                className=" button-blue"
                onClick={() => setOpenDirection(true)}
              >
                Hướng dẫn
              </Button>
              <Dialog
                header="Thủ thuật tăng đơn hàng"
                visible={openDirection}
                style={{ width: "50vw" }}
                onHide={() => {
                  if (!openDirection) return;
                  setOpenDirection(false);
                }}
              >
                <p className="m-0 lh-base">
                  Tích chọn vào bài viết cần đăng
                  <br />
                  Tích chọn tài khoản và bấm nút "Đăng bài"
                  <br />
                  - Mỗi bài chỉ nên đăng 1 tài khoản để tránh Facebook bóp hiển
                  thị ( không ai thấy )
                  <br />
                  - Có thể đăng 1 bài trên nhiều tài khoản ( nên đăng giãn cách
                  nhau từ 3h - 6h )
                  <br />- Nên chọn 1 bài viết gốc sau đó bấm "Sao chép sản phẩm"
                  rồi chỉnh sửa nội dung và thay đổi ảnh đầu tiên sau đó chọn
                  tài khoản và đăng, nên tránh 1 bài đăng nhiều tài khoản và
                  đăng lặp đi lặp lại 1 bài trên 1 tài khoản Bài đăng rất dễ bị
                  bóp hiển thị ( không ai thấy ) và vi phạm chính sách bán hàng
                  trên Facebook
                </p>
              </Dialog>
            </nav>
          </nav>
          <div
            className=" d-flex flex-column gap-2 w-100 styles-card-market  h-100 "
            style={{ overflowY: "auto", overflowX: "hidden" }}
          >
            {Array.isArray(dataFilter) &&
              dataFilter.map((item, index) => (
                <Card
                  className={item.id === selecCate.id ? "card-market" : ""}
                  onClick={() => setSelecCate(item)}
                  key={index}
                >
                  <div className="d-flex gap-2 w-100 cursor-pointer align-items-center">
                    <div>
                      <img
                        style={{ width: "134px", height: "134px" }}
                        src={
                          item?.images[0].includes("https")
                            ? item?.images[0]
                            : `${API_URL}/${item?.images[0]}`
                        }
                      />
                    </div>
                    <div className="d-flex flex-column gap-2 w-100">
                      <h4 className="mb-0">{truncateMarket(item.title, 50)}</h4>
                      <h6 className="mb-0">
                        {truncateMarket(item.description, 150)}
                      </h6>
                      <p>{formatPrice(item.price)}</p>
                      <nav className="w-100 d-flex gap-2 align-items-center justify-content-between">
                        <nav className="d-flex gap-2 align-items-center w-100">
                          <FaLocationDot size={25} />
                          <p>{item.location}</p>
                        </nav>
                        <nav className="d-flex gap-2">
                          <LuFolderEdit
                            cursor="pointer"
                            onClick={() => {
                              // setEditCate(item);
                              // setModalEdit(true);
                              // navigate(`/market/${item.id}`);
                              if (item.classify === 1) {
                                navigate(`/editvehicle/${item.id}`);
                              } else if (item.classify === 2) {
                                navigate(`/edithouse/${item.id}`);
                              } else {
                                navigate(`/editmarket/${item.id}`);
                              }
                            }}
                            style={{ marginTop: "2px" }}
                            size={25}
                          />
                          <MdDelete
                            cursor="pointer"
                            onClick={() => handleDetete(item)}
                            style={{ marginTop: "2px" }}
                            size={25}
                          />
                        </nav>
                      </nav>
                      <p>{`Tin nhắn trả lời tự động: ${
                        item.messages_bot?.length === 0 ? "Không" : "Có"
                      }`}</p>
                      <nav className="d-flex gap-2">
                        <FaCalendarCheck />
                        <p>{new Date(item?.created_at).toLocaleString()}</p>
                      </nav>
                    </div>
                  </div>
                </Card>
              ))}
          </div>
        </div>
      </div>
      <ModalCateMarket
        refresh={refresh}
        setRefresh={setRefresh}
        modalManage={modalManage}
        setModalManage={setModalManage}
        itemManage={itemManage}
      />
    </div>
  );
};
