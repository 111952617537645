// CityContext.js
import React, { createContext, useState, useEffect, useContext } from "react";
import axios from "axios";
import { API_URL , API_ROUTES } from "../../../api";
import { useHeaders } from "../../../const/headers";
const CityContext = createContext();

export const CityProvider = ({ children }) => {
  const headers = useHeaders();
  const [cities, setCities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const getListCity = API_URL + API_ROUTES.LIST_CITIS;
  useEffect(() => {
    const fetchCities = async () => {
      try {
        const response = await axios.get(getListCity, headers);
        setCities(response.data);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchCities();
  }, []);
  return (
    <CityContext.Provider value={{ cities, loading, error }}>
      {children}
    </CityContext.Provider>
  );
};

export const useCity = () => {
  return useContext(CityContext);
};
