import React from "react";
import { Dialog } from "primereact/dialog";
import { API_URL } from "../../../api";
export const ModalDetailsInvite = ({
  setOpenDetails,
  openDetails,
  itemResults,
  setRefresh,
  refresh,
  dataFb,
}) => {
  const showName = dataFb
    ? dataFb.filter((item) => item.id === itemResults.account)
    : [];
  return (
    <Dialog
      header="Trạng thái chi tiết"
      visible={openDetails}
      style={{ width: "50vw" }}
      onHide={() => setOpenDetails(false)}
    >
      <div className="f-flex flex-column gap-2">
        <nav
          style={{
            paddingBottom: "0",
            paddingTop: "0.5rem",
          }}
          className="d-flex gap-2"
        >
          <h5>Tài khoản: {showName[0]?.name}</h5>
          {/* <h5>UID: {itemResults?.}</h5> */}
        </nav>
        <nav
          style={{
            paddingBottom: "0.5rem",
            paddingTop: "0.5rem",
            borderBottom: "1px solid",
          }}
          className="d-flex gap-2"
        >
          <h5>Nhóm:&nbsp; {itemResults?.group?.name}.</h5>
          <h5>
            UID nhóm:&nbsp;{" "}
            <a
              href={`https://www.facebook.com/groups/${itemResults?.group?.group_uid}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {itemResults?.group?.group_uid}
            </a>
          </h5>
        </nav>
        <nav
          style={{
            paddingBottom: "0",
            paddingTop: "0.5rem",
          }}
          className="d-flex gap-2"
        >
          <div className="d-flex flex-column">
            <h5>Danh sách bạn bè được mời tham gia nhóm</h5>
            <ul className="d-flex flex-column gap-2">
              {itemResults?.friends?.map((item, index) => (
                <li key={index}>
                  {item?.name} - UID: {item?.UID}
                </li>
              ))}
            </ul>
          </div>
        </nav>
      </div>
    </Dialog>
  );
};
