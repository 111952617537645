import React, { useEffect, useRef, memo } from "react";
import Navigate from "../container/Navigate";
import { useState } from "react";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_URL, API_ROUTES, WS_URL } from "../../api";
import { Footer } from "./Footer";
import { useHeaders } from "../../const/headers";
import { updateTitle } from "../../const/updateTitle";
import { useAuth } from "../../AuthContext";
import { locale } from "primereact/api";
import { Stepper } from "primereact/stepper";
import { StepperPanel } from "primereact/stepperpanel";
import { ListTablePost } from "../container/article/post/ListTablePost";
import { ListTableReels } from "../container/article/reels/ListTableReels";
import { ListTableWatch } from "../container/article/watch/ListTableWatch";
import { ListTableReup } from "../container/article/reup/ListTableReup";
import { ListTableSending } from "../container/article/sending/ListTableSending";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { useUser } from "../../contexts/UserContext";
const ManageArticle = () => {
  locale("vi");
  updateTitle("Chốt Sale - Quản Lý Bài Viết");
  const headers = useHeaders();
  const getDataFb = API_URL + API_ROUTES.GET_ACC_FB;
  const { setUserLoggedIn } = useAuth();
  const [dataFb, setDataFb] = useState();
  const [refresh, setRefresh] = useState(true);
  const stepperRef = useRef(null);
  const isInitialRender = useRef(true);
  const socketRef = useRef(null);
  const { userInfo } = useUser();
  const [renderKey, setRenderKey] = useState(Date.now());
  const access = localStorage.getItem("access");
  const [allCate, setAllCate] = useState([]);

  useEffect(() => {
    axios
      .get(getDataFb, headers)
      .then(function (response) {
        setDataFb(response.data);
      })
      .catch(function (error) {
        if (error?.response?.status === 401) {
          setUserLoggedIn(false);
        }
      });
  }, [refresh]);
  useEffect(() => {
    const client = new W3CWebSocket(
      `wss://${WS_URL}/ws/post/${userInfo?.id}/?token=${access}`
    );
    socketRef.current = client;
    client.onerror = function () {};
    client.onopen = function () {};
    client.onmessage = function (message) {
      const parsedData = JSON.parse(message.data);
      if (parsedData.category !== undefined) {
        const newCategory = parsedData.category;
        setAllCate((prevCate) => {
          const index = prevCate.findIndex((cat) => cat.id === newCategory.id);
          if (index !== -1) {
            return prevCate.map((cat) =>
              cat.id === newCategory.id ? newCategory : cat
            );
          } else {
            return [...prevCate, newCategory];
          }
        });
      }
      if (parsedData.result !== undefined) {
        setRenderKey(Date.now());
      }
    };
    return () => {
      if (client) {
        client.close();
      }
    };
  }, []);
  return (
    <div className="d-flex flex-column " style={{ height: "100vh" }}>
      <div className="notifica-container">
        <ToastContainer />
        <div className="navigate-container">
          <Navigate />
        </div>
        <Stepper
          ref={stepperRef}
          className="w-100 h-100 flex flex-column steppPannel"
        >
          <StepperPanel className="h-100 d-flex" header="Đăng bài">
            <ListTablePost
              allCate={allCate}
              setAllCate={setAllCate}
              refresh={refresh}
              renderKey={renderKey}
              isInitialRender={isInitialRender}
              setRefresh={setRefresh}
              dataFb={dataFb}
              setDataFb={setDataFb}
            />
          </StepperPanel>
          <StepperPanel header="Đăng video">
            <ListTableWatch
              allCate={allCate}
              setAllCate={setAllCate}
              refresh={refresh}
              renderKey={renderKey}
              isInitialRender={isInitialRender}
              setRefresh={setRefresh}
              dataFb={dataFb}
              setDataFb={setDataFb}
            />
          </StepperPanel>
          <StepperPanel header="Đăng reels">
            <ListTableReels
              allCate={allCate}
              setAllCate={setAllCate}
              refresh={refresh}
              renderKey={renderKey}
              isInitialRender={isInitialRender}
              setRefresh={setRefresh}
              dataFb={dataFb}
              setDataFb={setDataFb}
            />
          </StepperPanel>
          <StepperPanel header="Sao chép bài viết">
            <ListTableReup
              allCate={allCate}
              setAllCate={setAllCate}
              refresh={refresh}
              renderKey={renderKey}
              isInitialRender={isInitialRender}
              setRefresh={setRefresh}
              dataFb={dataFb}
              setDataFb={setDataFb}
            />
          </StepperPanel>
          <StepperPanel header="Đẩy top bài viết">
            <ListTableSending
              allCate={allCate}
              setAllCate={setAllCate}
              refresh={refresh}
              renderKey={renderKey}
              isInitialRender={isInitialRender}
              setRefresh={setRefresh}
              dataFb={dataFb}
              setDataFb={setDataFb}
            />
          </StepperPanel>
        </Stepper>
      </div>
      <Footer />
    </div>
  );
};

export default memo(ManageArticle);
