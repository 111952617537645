import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useAuth } from "../../../AuthContext";
import { useUser } from "../../../contexts/UserContext";
export default function RegisterFail({ setModalReFail, modalReFail }) {
  return (
    <div style={{ border: "0" }} className=" flex justify-content-center">
      <Dialog
        header="Thông báo"
        visible={modalReFail}
        style={{ width: "50vw" }}
        onHide={() => setModalReFail(false)}
      >
        <div className="d-flex align-items-center">
          <img src="https://i.pinimg.com/236x/a9/71/d8/a971d8b69fdc16c9ca3222a38e895226.jpg" />
          <h4>Chúc mừng quý khách đã đăng ký thành công</h4>
        </div>
      </Dialog>
    </div>
  );
}
