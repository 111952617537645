import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { MdAccountCircle } from "react-icons/md";
import { MdOutlinePassword } from "react-icons/md";
import { Calendar } from "primereact/calendar";
import { FaCalendarAlt } from "react-icons/fa";
import { Button } from "primereact/button";
import moment from "moment-timezone";
import axios from "axios";
import { Headers } from "../../../const/headers";
import { API_URL, API_ROUTES } from "../../../api";
import { useUser } from "../../../contexts/UserContext";
import { Password } from "primereact/password";
import { useTranslation } from "react-i18next";
import { FaPhoneAlt } from "react-icons/fa";
import { MdDriveFileRenameOutline } from "react-icons/md";
import { useHeaders } from "../../../const/headers";
import { MultiSelect } from "primereact/multiselect";
import { FaPager } from "react-icons/fa";
export const PushPage = ({
  setOpenPush,
  openPush,
  setRefresh,
  refresh,
  itemEdit,
  listEmpl,
}) => {
  const { dataFb } = useUser();
  const headers = useHeaders();
  const currentDate = moment().tz("Asia/Ho_Chi_Minh").toDate();
  const { userInfo, setUserInfo } = useUser();
  const [date, setDate] = useState(null);
  const ganPageApi = API_URL + API_ROUTES.GAN_PAGE_EMPLOYEE;
  const [phone, setPhone] = useState("");
  const { t } = useTranslation();
  const [selectedCities, setSelectedCities] = useState([]);
  const flattenedArray = listEmpl
    ?.map((item) => item.pages)
    .flatMap((item) => item);
  const filteredData = dataFb?.filter(
    (item) => !flattenedArray?.map((item) => item.id).includes(item.id)
  );
  useEffect(() => {
    setSelectedCities(itemEdit?.pages);
  }, [itemEdit]);
  const cities = filteredData;
  const ganPage = async () => {
    const body = {
      id_employee: itemEdit?.id,
      id_page: selectedCities?.map((item) => item.id),
    };
    setRefresh(!refresh);
    setSelectedCities(null);
    setOpenPush(false);
    try {
      const response = await axios.post(ganPageApi, body, headers);
    } catch (err) {
    }
  };
  return (
    <div className="card flex  justify-content-center">
      <Dialog
        header="Chọn Page"
        visible={openPush}
        style={{ width: "50vw" }}
        onHide={() => setOpenPush(false)}
      >
        <div className="d-flex justify-content-start gap-2 mb-2 mt-2">
          <Button
            className="button-blue radius"
            label="Gán"
            onClick={() => ganPage()}
          />
          <Button
            className="button-red radius"
            label="Hủy"
            onClick={() => setOpenPush(false)}
            severity="danger"
          />
        </div>
        <div className="card flex flex-column  gap-3" style={{ border: "0" }}>
          <div className="p-inputgroup flex-1">
            <span className="p-inputgroup-addon">
              <FaPager />
            </span>
            <MultiSelect
              value={selectedCities}
              onChange={(e) => setSelectedCities(e.value)}
              options={cities}
              optionLabel="name"
              display="chip"
              placeholder="Chọn Page"
              maxSelectedLabels={3}
              className="w-full md:w-20rem"
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};
