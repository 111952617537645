export function filterLatestUpdates(data) {
  const latestItems = {};
  data?.forEach((item) => {
    const itemId = item.id;
    latestItems[itemId] = item;
  });
  return Object.values(latestItems);
}
export function filterByName(filterDataFb, searchKey) {
  return filterDataFb.filter(
    (item) =>
      item.title.toLowerCase().includes(searchKey.toLowerCase()) ||
      item.description.toLowerCase().includes(searchKey.toLowerCase())
  );
}
