import React, { useEffect, useRef } from "react";
import Navigate from "../container/Navigate";
import { useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_URL, API_ROUTES, API_ROUTES_NTD, WS_URL } from "../../api";
import { Footer } from "./Footer";
import { Button } from "primereact/button";
import { useHeaders } from "../../const/headers";
import { updateTitle } from "../../const/updateTitle";
import { useAuth } from "../../AuthContext";
import { ScriptAccount } from "../container/client/ScriptAccount";
import { ResultClient } from "../container/client/ResultClient";
import { ModalCreateCateClient } from "../container/client/ModalCreateCateClient";
import { ModalManageScriptClient } from "../container/client/ModalManageScriptClient";
import { getAxios } from "../../const/getAxios";
import { ModalDetailsClient } from "../container/client/ModalDetailsClient";
import { getAxiosResults } from "../../const/getAxios";
import { ModalScanAddFr } from "../container/client/ModalScanAddFr";
import { Sidebar } from "primereact/sidebar";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { useUser } from "../../contexts/UserContext";
export const ManagerClientVipPro = () => {
  const headers = useHeaders();
  updateTitle("Chốt Sale - Quản Lý Khách Hàng");
  const { setUserLoggedIn } = useAuth();
  const socketRef = useRef(null);
  const [renderKey, setRenderKey] = useState(Date.now());
  const { userInfo } = useUser();
  const access = localStorage.getItem("access");
  const [dataFb, setDataFb] = useState();
  const getDataFb = API_URL + API_ROUTES.GET_ACC_FB;
  const checkListFr = API_URL + API_ROUTES_NTD.GET_LIST_FRIEND;
  const getCateClient = API_URL + API_ROUTES_NTD.GET_ALL_CATEGORY_CLIENT;
  const addCateClient = API_URL + API_ROUTES_NTD.ADD_CATEGORY_IN_CLIENT;
  const startMarketing = API_URL + API_ROUTES_NTD.START_CATEGORY_ACCOUNT_CLIENT;
  const stopMarketing = API_URL + API_ROUTES_NTD.STOP_CATEGORY_ACCOUNT_CLIENT;
  const deleteResultClient = API_URL + API_ROUTES_NTD.DELETE_RESULTS_CLIENT;
  const allResults = API_URL + API_ROUTES_NTD.GET_ALL_CLIENT;
  const resultScanClient = API_URL + API_ROUTES_NTD.GET_RESULTS_DREIND;
  const [refresh, setRefresh] = useState(true);
  const [allFriend, setAllFriend] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [openCategory, setOpenCategory] = useState(false);
  const [openCategoryAddFr, setOpenCategoryAddFr] = useState(false);
  const [allCateClient, setAllCateClient] = useState([]);
  const [listFr, setListFr] = useState([]);
  const [listScript, setListScript] = useState([]);
  const [managerScript, setManagerScript] = useState(false);
  const [scriptClient, setScriptClient] = useState([]);
  const [allCate, setAllCate] = useState([]);
  const [openDetails, setOpenDetails] = useState(false);
  const [itemResults, setItemResults] = useState([]);
  const [listResults, setListResults] = useState([]);
  const [wsResults, setWsResults] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [getTotal, setGetTotal] = useState([]);
  const [itemCate, setItemCate] = useState([]);
  const isInitialRender = useRef(true);
  const [rows, setRows] = useState(100);
  const [taskId, setTaskId] = useState([]);
  const [dataScanRs, setDataScanRs] = useState("");
  const [statusRun, setStatusRun] = useState(false);
  const [openSidebar, setOpenSidebar] = useState(false);
  const intervalRef = useRef();
  const scanListFr = async (item) => {
    if (!item.id) {
      alert("Bạn vui lòng chọn ít nhất một tài khoản");
    } else if (item?.reload_friend === true) {
      alert("Bạn đã quét trước đó rồi");
      return;
    } else {
      const body = { id_accounts: [item.id] };
      try {
        const res = await axios.post(checkListFr, body, headers);
        setTaskId(res.data.id_task);
        setStatusRun(true);
        axios
          .get(getDataFb, headers)
          .then(function (response) {
            setDataFb(response.data);
          })
          .catch(function (error) {
            if (error?.response?.status === 401) {
              setUserLoggedIn(false);
            }
          });
      } catch (err) {}
    }
  };
  const checkResultsClient = async () => {
    if (taskId?.length === 0) {
      return;
    }
    const body = { id_task: taskId };
    try {
      const res = await axios.post(resultScanClient, body, headers);
      setDataScanRs(res.data);
    } catch (err) {}
  };
  useEffect(() => {
    intervalRef.current = setInterval(() => {
      checkResultsClient();
    }, 3000);
    return () => clearInterval(intervalRef.current);
  }, [taskId]);
  useEffect(() => {
    if (dataScanRs?.status === "SUCCESS" || dataScanRs?.status === "FAILURE") {
      setStatusRun(false);
      clearInterval(intervalRef.current);
      setRefresh(!refresh);
    }
  }, [dataScanRs]);
  useEffect(() => {
    axios
      .get(getDataFb, headers)
      .then(function (response) {
        setDataFb(response.data);
      })
      .catch(function (error) {
        if (error?.response?.status === 401) {
          setUserLoggedIn(false);
        }
      });
  }, []);
  useEffect(() => {
    getAxios(getCateClient, setAllCateClient);
  }, [refresh]);
  const addCateInClient = async () => {
    if (listScript?.length === 1 && listFr?.length > 0) {
      const body = {
        id_category: listScript[0].id,
        id_friends: listFr?.map((item) => item.id),
      };
      try {
        const res = await axios.post(addCateClient, body, headers);
        toast.success("Thêm kịch bản thành công");
        setListScript([]);
        setListFr([]);
        setRefresh(!refresh);
      } catch (err) {}
    }
  };
  const deleteResults = async () => {
    const body = { id_results: listResults?.map((item) => item?.id) };
    try {
      const res = await axios.post(deleteResultClient, body, headers);
      getAxiosResults(
        allResults,
        setWsResults,
        setGetTotal,
        rows,
        totalPage + 1
      );
      toast.success("Xoá thành công");
      setListResults([]);
    } catch (err) {}
  };
  const startMarketingClient = async (type) => {
    if (listScript?.length === 0) {
      alert("Chọn ít nhất 1 kịch bản để chạy ");
    } else {
      const body = {
        id_categories: listScript?.map((item) => item.id),
        type: type,
      };
      try {
        await axios.post(startMarketing, body, headers);
        toast.success("Chạy chiến dịch thành công");
      } catch (error) {
        toast.error(error.response?.data?.error);
      }
    }
  };
  const stopMarketingClient = async () => {
    if (listScript?.length === 0) {
      alert("Chọn ít nhất 1 kịch bản để dừng ");
    } else {
      const body = {
        id_categories: listScript?.map((item) => item.id),
      };
      try {
        await axios.post(stopMarketing, body, headers);
        setRefresh(!refresh);
        toast.success("Dừng chiến dịch thành công");
      } catch (err) {
        toast.error("Dừng chiến dịch thất bại");
      }
    }
  };

  useEffect(() => {
    addCateInClient();
  }, [listScript, refresh]);
  const widthNavi = document.getElementById("width-navigate")?.offsetWidth;
  useEffect(() => {
    const client = new W3CWebSocket(
      `wss://${WS_URL}/ws/friend/${userInfo?.id}/?token=${access}`
    );
    socketRef.current = client;
    client.onerror = function () {};
    client.onopen = function () {};
    client.onmessage = function (message) {
      const parsedData = JSON.parse(message.data);
      if (parsedData.category !== undefined) {
        const newCategory = parsedData.category;
        setAllCateClient((prevCate) => {
          const index = prevCate.findIndex((cat) => cat.id === newCategory.id);
          if (index !== -1) {
            return prevCate.map((cat) =>
              cat.id === newCategory.id ? newCategory : cat
            );
          } else {
            return [...prevCate, newCategory];
          }
        });
      }
      if (parsedData.result !== undefined) {
        setRenderKey(Date.now());
      }
    };
    return () => {
      if (client) {
        client.close();
      }
    };
  }, []);
  return (
    <div className="d-flex flex-column " style={{ height: "100vh" }}>
      <div className="notifica-container">
        <ToastContainer />
        <div className="navigate-container" id="width-navigate">
          <Navigate />
        </div>
        <div
          className="d-flex h-100 "
          style={{ width: `calc(100vw - ${widthNavi}px)` }}
        >
          <div
            className="overflow-auto h-100 d-flex flex-column"
            style={{
              width: "100%",
              borderRight: "0.5px solid",
              borderLeft: "0.5px solid",
            }}
          >
            <div
              className="d-flex gap-2 w-100 justify-content-end pt-2 pl-2 pr-2"
              id="lisstSeclect"
            >
              <Button
                onClick={() => setOpenCategory(true)}
                className="button-blue d-flex justify-content-center "
                style={{ width: "150px" }}
              >
                Thêm kịch bản
              </Button>
              <Button
                style={{
                  width: "150px",
                }}
                onClick={() => setOpenCategoryAddFr(true)}
                className="button-blue d-flex justify-content-center"
              >
                Lời mời kết bạn
              </Button>
              <Button
                onClick={() => startMarketingClient("new")}
                style={{
                  width: "100px",
                }}
                className=" button-blue d-flex justify-content-center"
              >
                Chạy
              </Button>
              <Button
                onClick={() => startMarketingClient("")}
                style={{
                  width: "100px",
                }}
                className="button-blue d-flex justify-content-center"
              >
                Chạy Tiếp
              </Button>
              <Button
                onClick={() => setOpenSidebar(true)}
                style={{
                  width: "100px",
                }}
                className="button-blue d-flex justify-content-center"
              >
                Kết quả
              </Button>
              <Button
                severity="danger"
                style={{ width: "100px" }}
                className="button-red d-flex justify-content-center"
                onClick={stopMarketingClient}
              >
                Dừng
              </Button>
            </div>
            <div
              className="d-flex flex-column  gap-2 h-100 m-2"
              style={{ overflow: "hidden" }}
            >
              <div className="d-flex w gap-2" style={{ height: "100%" }}>
                <ScriptAccount
                  managerScript={managerScript}
                  setManagerScript={setManagerScript}
                  listScript={listScript}
                  setListScript={setListScript}
                  allCateClient={allCateClient}
                  setRefresh={setRefresh}
                  refresh={refresh}
                  itemCate={itemCate}
                  setItemCate={setItemCate}
                />
              </div>
              <Sidebar
                visible={openSidebar}
                onHide={() => setOpenSidebar(null)}
                position="bottom"
                className="sidebar-market"
              >
                <div className="d-flex flex-column" style={{ height: "100%" }}>
                  <Button
                    severity="danger"
                    style={{
                      width: "150px",
                      display: listResults.length === 0 ? "none" : "block",
                    }}
                    className="button-red justify-content-center"
                    onClick={deleteResults}
                  >
                    Xoá kết quả
                  </Button>
                  <div className="d-flex " style={{ height: "100%" }}>
                    <ResultClient
                      renderKey={renderKey}
                      toast={toast}
                      isInitialRender={isInitialRender}
                      rows={rows}
                      setRows={setRows}
                      totalPage={totalPage}
                      setTotalPage={setTotalPage}
                      getTotal={getTotal}
                      setGetTotal={setGetTotal}
                      allCateClient={allCateClient}
                      setAllCateClient={setAllCateClient}
                      listResults={listResults}
                      setListResults={setListResults}
                      setAllCate={setAllCate}
                      setOpenDetails={setOpenDetails}
                      openDetails={openDetails}
                      setItemResults={setItemResults}
                      wsResults={wsResults}
                      setWsResults={setWsResults}
                    />
                  </div>
                </div>
              </Sidebar>
            </div>
          </div>
        </div>
        <ModalCreateCateClient
          openCategory={openCategory}
          setOpenCategory={setOpenCategory}
          setRefresh={setRefresh}
          refresh={refresh}
        />
        <ModalScanAddFr
          setSelectedItems={setSelectedItems}
          scriptClient={scriptClient}
          setScriptClient={setScriptClient}
          setAllFriend={setAllFriend}
          selectedItems={selectedItems}
          openCategoryAddFr={openCategoryAddFr}
          setOpenCategoryAddFr={setOpenCategoryAddFr}
          setRefresh={setRefresh}
          refresh={refresh}
          dataFb={dataFb}
          allFriend={allFriend}
          listFr={listFr}
          setListFr={setListFr}
          toast={toast}
          setManagerScript={setManagerScript}
        />
        <ModalManageScriptClient
          itemCate={itemCate}
          toast={toast}
          getTotal={getTotal}
          setGetTotal={setGetTotal}
          allFriend={allFriend}
          setAllFriend={setAllFriend}
          totalPage={totalPage}
          setTotalPage={setTotalPage}
          setRows={setRows}
          rows={rows}
          statusRun={statusRun}
          scanListFr={scanListFr}
          dataFb={dataFb}
          setRefresh={setRefresh}
          refresh={refresh}
          scriptClient={scriptClient}
          managerScript={managerScript}
          setManagerScript={setManagerScript}
        />
        <ModalDetailsClient
          openDetails={openDetails}
          setOpenDetails={setOpenDetails}
          itemResults={itemResults}
        />
      </div>
      <Footer />
    </div>
  );
};
