import React, { useEffect, useMemo, useState } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { API_ROUTES, API_URL } from "../api";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../AuthContext";
import { Password } from "primereact/password";
import { useTranslation } from "react-i18next";
import { InputText } from "primereact/inputtext";
import { updateTitle } from "../const/updateTitle";
import { MdOutlineHome } from "react-icons/md";
import { FloatLabel } from "primereact/floatlabel";
import ModalResetPass from "./container/resetPass/ModalResetPass";
import { useUser } from "../contexts/UserContext";
const QuenMatKhau = () => {
  updateTitle("Chốt Sale - Quên Mật Khẩu");
  const { t } = useTranslation();
  const { userLoggedIn, setUserLoggedIn, setRefeshs } = useAuth();
  const { newLogo } = useUser();
  const loginApi = API_URL + API_ROUTES.REQUEST_RESET_PASS;
  const getToken = API_URL + API_ROUTES.GET_TOKEN;
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [fullName, setFullName] = useState("");
  const [phone, setPhone] = useState("");
  const [init, setInit] = useState(false);
  const [openResetPass, setOpenResetPass] = useState(false);
  const [getStatus, setGetStatus] = useState([]);
  const changeUsername = (event) => {
    setUserName(event.target.value);
  };
  const changeFullName = (event) => {
    setFullName(event.target.value);
  };
  const changePhone = (event) => {
    setPhone(event.target.value);
  };

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const handleLogin = () => {
    axios
      .post(loginApi, {
        username: userName,
        fullname: fullName,
        phone_number: phone,
      })
      .then((response) => {
        setGetStatus(response.data.status);
      })
      .catch((error) => {
        setGetStatus(error);
      });
    setOpenResetPass(true);
  };

  return (
    <div
      style={{ height: "100vh", color: "#912091e0" }}
      className="p-4  w-100 d-flex justify-content-center align-items-center background-register">
      <ToastContainer />
      <ModalResetPass
        setOpenResetPass={setOpenResetPass}
        openResetPass={openResetPass}
        getStatus={getStatus}
      />
      <div className="d-flex h-100 login-register-responsive" style={{}}>
        <div
          className="login-register-border-radius d-flex justify-content-center align-items-center flex-column w-100"
          style={{
            padding: "58px 42px",
            backgroundColor: "transparent",
            color: "purple",
            borderTopLeftRadius: "20px",
            borderBottomLeftRadius: "20px",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
            position: "relative",
          }}>
          <MdOutlineHome
            onClick={() => navigate("/")}
            size={40}
            className="text-light"
            style={{
              position: "absolute",
              top: "10px",
              left: "10px",
              cursor: "pointer",
            }}
          />
          <nav className="d-flex flex-column gap-2 align-items-center mb-4">
            <img
              style={{ width: "273px", borderRadius: "10%" }}
              src={`${API_URL}/${newLogo}`}
            />
          </nav>
          <nav className="d-flex flex-column gap-2  w-100 align-items-center">
            <h4
              style={{ fontWeight: "700" }}
              className="text-light text-center">
              HỆ THỐNG MARKETING
            </h4>
            <h4
              style={{ fontWeight: "700" }}
              className="text-light text-center">
              BÁN HÀNG TỰ ĐỘNG
            </h4>
          </nav>
        </div>
        <div
          className="login-register-border-radius d-flex justify-content-center align-items-center flex-column w-100"
          style={{
            padding: "58px 42px",
            backgroundColor: "white",
            color: "purple",
            border: "2px solid white",
            borderTopRightRadius: "20px",
            borderBottomRightRadius: "20px",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
          }}>
          <nav className="d-flex align-items-end w-100 gap-4">
            <h3 className="text-dark">Quên mật khẩu</h3>
          </nav>
          <div className="d-flex justify-content-start mb-4 w-100">
            <nav
              style={{
                width: "60px",
                borderRadius: "21px",
                padding: "4px",
                background: "purple",
              }}></nav>
          </div>
          <p className="text-dark mb-2" style={{ fontSize: "1rem" }}>
            Vui lòng nhập đầy đủ thông tin để tìm kiếm tài khoản của bạn.
          </p>
          <div className="w-100 d-flex flex-column gap-4 mt-4 mb-2">
            <FloatLabel>
              <InputText
                id="username"
                className="w-100"
                value={userName}
                onChange={changeUsername}
                style={{ fontSize: "1rem" }}
              />
              <label htmlFor="username" style={{ fontSize: "1rem" }}>
                Tên tài khoản đăng nhập trước đây của bạn
              </label>
            </FloatLabel>
          </div>
          <div className="w-100 d-flex flex-column gap-4 mt-4 mb-2">
            <FloatLabel>
              <InputText
                id="username"
                className="w-100"
                value={fullName}
                onChange={changeFullName}
                style={{ fontSize: "1rem" }}
              />
              <label htmlFor="username" style={{ fontSize: "1rem" }}>
                Họ tên đầy đủ
              </label>
            </FloatLabel>
          </div>
          <div className="w-100 d-flex flex-column gap-4 mt-4 mb-2">
            <FloatLabel>
              <InputText
                id="username"
                className="w-100"
                value={phone}
                onChange={changePhone}
                style={{ fontSize: "1rem" }}
              />
              <label htmlFor="username" style={{ fontSize: "1rem" }}>
                Số điện thoại
              </label>
            </FloatLabel>
          </div>
          <div className="w-100 mt-4 d-flex flex-column gap-2 align-items-end">
            <Button
              onClick={handleLogin}
              className="w-100 button-blue radius"
              label="Gửi thông tin"
            />
            <a
              style={{ cursor: "pointer", color: "blue" }}
              onClick={() => navigate("/login")}>
              Đăng nhập
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuenMatKhau;
