import { useState, useRef } from "react";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { Sidebar } from "primereact/sidebar";
import { useHeaders } from "../../../../const/headers";
import { API_URL, API_ROUTES } from "../../../../api";
import { removeItem } from "../../../../const/splittext";
import { truncateLabel } from "../../../../const/splittext";
import { Image } from "primereact/image";
import { TiDelete } from "react-icons/ti";
import { toast } from "react-toastify";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { deleteFileOnServer } from "../../../../const/deleteFileOnServer";
import { IoInformationCircle } from "react-icons/io5";
import axios from "axios";
export const ListCommentsClient = ({
  valueCmt,
  setComments,
  setValueCmt,
  comments,
  setListImage,
  listImage,
}) => {
  const headers = useHeaders();
  const [activeIndex, setActiveIndex] = useState(null);
  const apiGetLink = API_URL + API_ROUTES.GET_LINK_FILE;
  const inputRef = useRef(null);
  const [imageURLs, setImageURLs] = useState([]);
  const [listFile, setListFile] = useState([]);
  const showFileInput = () => {
    document.getElementById(`fileInput`).click();
  };
  const handleGetImg = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    try {
      const res = await axios.post(apiGetLink, formData, headers);
      setListImage((prevURLs) => [...prevURLs, res.data.file]);
    } catch (err) {}
  };
  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    for (let i = 0; i < files.length; i++) {
      handleGetImg(files[i]);
    }
    setListFile(files);
    const newImageURLs = files.map((file) => URL.createObjectURL(file));
    setImageURLs((prevURLs) => [...prevURLs, ...newImageURLs]);
  };
  const handleRemoveImage = (index) => {
    const updatedURLs = [...imageURLs];
    const updatedFile = [...listFile];
    const updatedImage = [...listImage];
    deleteFileOnServer(listImage[index]);
    updatedURLs.splice(index, 1);
    updatedFile.splice(index, 1);
    updatedImage.splice(index, 1);
    setImageURLs(updatedURLs);
    setListFile(updatedFile);
    setListImage(updatedImage);
  };
  const handleKeyCmt = (e) => {
    if (e.type === "keydown") {
      return;
    }
    e.preventDefault();
    if (valueCmt.trim() !== "") {
      setComments((prevComments) => [...prevComments, valueCmt.trim()]);
      setValueCmt("");
    }
  };
  const copyContent = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success("Sao chép thành công");
      })
      .catch(() => {
        toast.error("Sao chép thất bại");
      });
  };
  const convertNewlinesToBreaks = (text) => {
    return text.replace(/\n/g, "<br />");
  };
  const handleAddGenderName = () => {
    setValueCmt(
      (currentValue) =>
        `${currentValue}Em chào [gender] [uid_tag], bên shop [tag] có sản phẩm phù hợp với [gender] lắm`
    );
    inputRef.current.focus();
  };
  return (
    <div className="mt-2 d-flex gap-2 justify-content-between w-100">
      <div className="d-flex flex-column" style={{ width: "50%" }}>
        <nav className="d-flex align-items-center gap-2">
          <nav className="d-flex gap-2">
            <p className="mb-0">Nội dung bình luận</p>
            <OverlayTrigger
              key="tooltip"
              placement="bottom"
              overlay={
                <Tooltip id="tooltip-right" className="custom-tooltip">
                  <div className="text-start">
                    - Nên thêm nhiều nội dung bình luận, tối thiểu là 5 nội
                    dung. Hệ thống sẽ tự động lấy ngẫu nhiên mỗi lần 1 nội dung
                    để đi bình luận . Giúp tài khoản của bạn giảm hạn chế tính
                    năng do cơ chế chống spam của Facebook
                    <br />- Thêm bình luận bằng cách nhấn nút "Thêm nội dung"
                    <br />
                    - Cú pháp để sử dụng chức năng tag:
                    <br />
                    [uid_tag] : UID được tag từ danh sách UID spam
                    <br />
                    [tag] : UID được tag duy nhất của kịch bản
                    <br />
                    [gender] : Giới tính của UID được tag (anh/chị)
                    <br />
                    - Ví dụ: Em chào [gender] [uid_tag], bên shop [tag] có sản
                    phẩm phù hợp với [gender] lắm.
                    <br />- Lưu ý: Không thay đổi vị trí của [uid_tag] và [tag],
                    [uid_tag] bắt buộc phải đứng trước [tag]
                  </div>
                </Tooltip>
              }
            >
              <span
                style={{
                  cursor: "pointer",
                  fontWeight: "bold",
                }}
              >
                <IoInformationCircle size={15} />
              </span>
            </OverlayTrigger>
          </nav>

          <Button
            className="button-blue radius"
            style={{ padding: "6px 8px" }}
            onClick={handleKeyCmt}
          >
            Thêm nội dung
          </Button>
          <Button
            className="button-blue radius"
            style={{ padding: "6px 8px" }}
            onClick={handleAddGenderName}
          >
            Mẫu tag
          </Button>
        </nav>
        <div className="p-inputgroup flex-1 mt-2">
          <InputTextarea
            value={valueCmt}
            onChange={(e) => setValueCmt(e.target.value)}
            onKeyDown={handleKeyCmt}
            placeholder={``}
            rows={5}
            cols={30}
            ref={inputRef}
          />
        </div>
        <div className="d-flex gap-2 flex-wrap w-100 mt-2">
          {comments?.map((item, index) => (
            <div
              key={index}
              style={{
                position: "relative",
                display: "inline-block",
              }}
            >
              <Button
                className="button-blue radius"
                style={{ padding: "6px 8px", fontSize: "12px" }}
                label={truncateLabel(item)}
                onClick={() => setActiveIndex(index)}
              />
              <Sidebar
                visible={activeIndex === index}
                onHide={() => setActiveIndex(null)}
              >
                <Button
                  className="button-blue radius"
                  label="Copy"
                  onClick={() => copyContent(item)}
                  style={{ marginBottom: "10px" }}
                />
                <p
                  dangerouslySetInnerHTML={{
                    __html: convertNewlinesToBreaks(item),
                  }}
                />
              </Sidebar>
              <TiDelete
                className="p-button-rounded p-button-danger p-button-sm "
                style={{
                  position: "absolute",
                  top: "-8px",
                  right: "-8px",
                  width: "25px",
                  height: "25px",
                  color: "red",
                  cursor: "pointer",
                }}
                onClick={() => removeItem(index, setComments)}
              />
            </div>
          ))}
        </div>
      </div>
      <div style={{ width: "50%" }}>
        <nav className="d-flex align-items-center gap-2">
          <p className="mb-0">Danh sách ảnh bình luận</p>

          <Button
            className="button-blue radius"
            style={{ padding: "6px 8px" }}
            onClick={showFileInput}
          >
            Thêm ảnh
          </Button>
          <OverlayTrigger
            key="tooltip"
            placement="top"
            overlay={
              <Tooltip id="tooltip-right" className="custom-tooltip">
                <div className="text-start">
                  Hạn chế gửi kèm ảnh. Tính năng gửi kèm ảnh dễ bị hạn chế tính
                  năng trên tài khoản do cơ chế chống spam của Facebook
                </div>
              </Tooltip>
            }
          >
            <span
              style={{
                cursor: "pointer",
                fontWeight: "bold",
              }}
            >
              <IoInformationCircle size={15} />
            </span>
          </OverlayTrigger>
        </nav>
        <div className="flex flex-wrap">
          {listImage?.map((url, index) => (
            <div key={index} className="position-relative m-2">
              <Image
                width="50"
                height="50"
                src={`${API_URL}/${url}`}
                alt={`Selected ${index}`}
                preview
              />
              <button
                className="btn-remove"
                style={{ position: "absolute", top: "-6px", right: "-8px" }}
                onClick={() => handleRemoveImage(index)}
              >
                <TiDelete size={15} />
              </button>
            </div>
          ))}
        </div>
        <input
          id={`fileInput`}
          type="file"
          multiple
          style={{ display: "none" }}
          onChange={(e) => handleImageChange(e)}
        />
      </div>
    </div>
  );
};
