import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useAuth } from "../../../AuthContext";
import { useUser } from "../../../contexts/UserContext";
import { API_URL } from "../../../api";

export default function ModalResetPass({
  setOpenResetPass,
  openResetPass,
  getStatus,
}) {
  return (
    <div
      style={{ border: "0" }}
      className="card b-0 flex justify-content-center"
    >
      <Dialog
        header=""
        visible={openResetPass}
        style={{ width: "50vw" }}
        onHide={() => setOpenResetPass(false)}
      >
        <div>
          {getStatus === 1 ? (
            <h5>
              Hệ thống tạm dừng chức năng Khôi phục mật khẩu. Vui lòng thử lại
              vào 9h sáng mai hoặc liên hệ với chúng tôi{" "}
              <a target="blank" href="https://www.facebook.com/phanmemchotsale">
                tại đây
              </a>{" "}
              để được hỗ trợ nhanh nhất.
            </h5>
          ) : getStatus === 2 ? (
            <h5>
              Yêu cầu đã được gửi. Chúng tôi sẽ gửi mật khẩu mới qua email của
              bạn. Vui lòng chuyển email của chúng tôi ra khỏi hộp thư spam để
              có thể nhận được những thông tin mới nhất.
            </h5>
          ) : getStatus === 3 ? (
            <h5>
              Yêu cầu đã được gửi. Chúng tôi sẽ gửi mật khẩu mới qua email của
              bạn. Vui lòng chuyển email của chúng tôi ra khỏi hộp thư spam để
              có thể nhận được những thông tin mới nhất.
            </h5>
          ) : (
            <div></div>
          )}
        </div>
      </Dialog>
    </div>
  );
}
