import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { FloatLabel } from "primereact/floatlabel";
import { InputText } from "primereact/inputtext";
import { API_URL, API_ROUTES } from "../../../api";
import axios from "axios";
import { useHeaders } from "../../../const/headers";
import { InputTextarea } from "primereact/inputtextarea";
import { TiDelete } from "react-icons/ti";
import { deleteFileOnServer } from "../../../const/deleteFileOnServer";

export const EditFastMess = ({
  setOpenEdit,
  openEdit,
  toast,
  dataEdit,
  handleGetMess,
}) => {
  const headers = useHeaders();
  const editFastMess = API_URL + API_ROUTES.EDIT_FAST_REPLY;
  const getLinkImage = API_URL + API_ROUTES.GET_LINK_FILE;
  const formData = new FormData();
  const [title, setTitle] = useState("");
  const [mess, setMess] = useState("");
  const [linkImage, setLinkImage] = useState("");
  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      extracLinkImage(e.target.files[0]);
    }
  };
  const extracLinkImage = async (selectFileFast) => {
    formData.append("file", selectFileFast);
    try {
      const response = await axios.post(getLinkImage, formData, headers);
      setLinkImage(response.data.file);
    } catch (err) {}
  };
  const removeImage = () => {
    setLinkImage("");
    deleteFileOnServer(linkImage);
  };
  useEffect(() => {
    setTitle(dataEdit.title);
    setMess(dataEdit.content);
    setLinkImage(dataEdit.image);
  }, [openEdit]);
  const editDataMess = async () => {
    const body = {
      id_fast_reply: dataEdit.id,
      command: "",
      title: title,
      content: mess,
      image: linkImage || "",
    };
    try {
      const responsive = await axios.post(editFastMess, body, headers);
      toast.success("Thành công");
      setOpenEdit(false);
      handleGetMess();
    } catch (err) {
      toast.error("Thất bại");
    }
  };
  return (
    <div className="flex justify-content-center">
      <Dialog
        header="Sửa tin nhắn nhanh"
        visible={openEdit}
        onHide={() => setOpenEdit(false)}
        style={{ width: "30vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        <div className="d-flex flex-column gap-4 mt-4 p-2 w-100">
          <FloatLabel>
            <InputText
              id="title"
              className="w-100"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <label htmlFor="title">Tiêu đề</label>
          </FloatLabel>
          <FloatLabel>
            <InputTextarea
              id="mess"
              className="w-100"
              value={mess}
              onChange={(e) => setMess(e.target.value)}
            />
            <label htmlFor="mess">Tin nhắn</label>
          </FloatLabel>
          <FloatLabel className="d-flex">
            <input
              id="file"
              type="file"
              className="w-100"
              onChange={handleFileChange}
              lang="vi"
            />
            {linkImage && (
              <div style={{ position: "relative" }}>
                <img
                  style={{ width: "80px", height: "80px" }}
                  src={`${API_URL}/${linkImage}`}
                />
                <TiDelete
                  onClick={removeImage}
                  size={20}
                  style={{
                    position: "absolute",
                    top: "0",
                    right: "0",
                    color: "red",
                  }}
                />
              </div>
            )}
            {/* <label htmlFor="file">Tệp tin</label> */}
          </FloatLabel>
        </div>
        <nav className="d-flex justify-content-end">
          <Button
            className="button-blue radius"
            style={{ marginTop: "1rem" }}
            label="Sửa"
            onClick={() => editDataMess()}
            text
          />
        </nav>
      </Dialog>
    </div>
  );
};
