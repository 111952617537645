import { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import {
  toggleSelectAll,
  headerCheckbox,
  bodyCheckbox,
} from "../../../const/checkboxPrimeReactjs";
import { Chip } from "primereact/chip";
import axios from "axios";
import { useHeaders } from "../../../const/headers";
import { API_URL, API_ROUTES_NTD } from "../../../api";
import { rowClassName } from "../../../const/checkboxPrimeReactjs";
import { onRowClick } from "../../../const/checkboxPrimeReactjs";
import { getAxiosMarket } from "../../../const/getAxios";
import { Paginator } from "primereact/paginator";
export const TableFriendOfAccount = ({
  allFriend,
  toast,
  setListFr,
  listFr,
  setManagerScript,
  setScriptClient,
  selectedItems,
  setRefresh,
  refresh,
  setAllFriend,
  setGetTotal,
  rows,
  totalPage,
  setTotalPage,
  setRows,
  getTotal,
}) => {
  const getAllFr = API_URL + API_ROUTES_NTD.GET_ALL_FRIEND;
  const rowsPerPageOptions = [100, 300, 500, 1000, 2000];
  const [first, setFirst] = useState(0);
  const headers = useHeaders();
  const onPageChange = (event) => {
    setTotalPage(event.page);
    setFirst(event.first);
    setRows(event.rows);
  };
  const getDataAllFr = () => {};
  useEffect(() => {
    if (selectedItems?.length === 0) {
      setAllFriend([]);
      return;
    }
    getAxiosMarket(
      getAllFr,
      setAllFriend,
      setGetTotal,
      rows,
      totalPage + 1,
      selectedItems[0]?.id
    );
  }, [refresh, selectedItems, totalPage, rows]);

  const buttonOption = (rowData) => {
    return (
      <div style={{ width: "90px" }} className="d-flex gap-2">
        <Button
          className=" button-blue radius"
          style={{
            padding: "4px 6px",
            width: "50%",
            fontSize: "12px",
            display: "flex",
            justifyContent: "center",
          }}
          onClick={() => {
            setScriptClient(rowData);
            setManagerScript(true);
          }}
        >
          Mời
        </Button>
      </div>
    );
  };
  const indexTemplate = (rowData, rowIndex) => {
    return <>{rowIndex.rowIndex + 1 + rows * totalPage}</>;
  };

  return (
    <div className="h-100 ">
      <DataTable
        size="small"
        value={allFriend}
        className="paginator small-font-table dataTableWithFixedHeader dataTableWithHoverEffect custom-scrollbar"
        tableStyle={{ minWidth: "100%" }}
        rowClassName={(rowData) => rowClassName(rowData, listFr)}
        onRowClick={(e) => onRowClick(e.data, setListFr, listFr)}
      >
        <Column
          header={headerCheckbox(listFr, allFriend, toggleSelectAll, setListFr)}
          body={(rowData) =>
            bodyCheckbox(rowData, listFr, setListFr, getDataAllFr)
          }
        />
        <Column body={indexTemplate} header="STT"></Column>
        <Column field="UID" header="UID khách hàng" sortable></Column>
        <Column field="name" header="Tên khách hàng" sortable></Column>
      </DataTable>
      <Paginator
        className="pagination-markerting"
        first={first}
        rows={rows}
        totalRecords={getTotal?.count}
        onPageChange={onPageChange}
        rowsPerPageOptions={rowsPerPageOptions}
      />
    </div>
  );
};
