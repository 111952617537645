import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { MdAccountCircle } from "react-icons/md";
import { MdOutlinePassword } from "react-icons/md";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import axios from "axios";
import { API_URL, API_ROUTES } from "../../../api";
import { useUser } from "../../../contexts/UserContext";
import { Password } from "primereact/password";
import { useTranslation } from "react-i18next";
import { FaPhoneAlt } from "react-icons/fa";
import { MdOutlineTimeline } from "react-icons/md";
import { MdDriveFileRenameOutline } from "react-icons/md";
import { CiViewTimeline } from "react-icons/ci";
import { useHeaders } from "../../../const/headers";
import { CiMail } from "react-icons/ci";
import { Dropdown } from "primereact/dropdown";
import { FaUserGroup } from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa6";

export const ModalAcceptAcc = ({
  setModalEdit,
  modalEdit,
  setRefresh,
  refresh,
  getItemEdit,
}) => {
  const headers = useHeaders();
  const currentDate = new Date();
  const defaultDate = new Date(currentDate);
  defaultDate.setDate(defaultDate.getDate() + 3);
  const { userInfo, setUserInfo } = useUser();
  const [date, setDate] = useState(defaultDate);
  const [password, setPassword] = useState(getItemEdit?.password || "");
  const [username, setUsername] = useState(getItemEdit?.username || "");
  const [fullName, setFullName] = useState(getItemEdit?.fullname || "");
  const [linkFb, setLinkFb] = useState(getItemEdit?.facebook_link || "");
  const [phone, setPhone] = useState(getItemEdit?.phone_number || "");
  const [mail, setMail] = useState(getItemEdit?.phone_number || "");
  const [pageLim, setPageLim] = useState(10);
  const [limitLive, setLimitLive] = useState(
    getItemEdit?.live_stream_limit || ""
  );
  const [permission, setPermission] = useState("is_manager");
  const [limitEml, setLimitEpl] = useState(0);
  const editAcc = API_URL + API_ROUTES.CREATE_ACCOUNT_MANAGER;
  const { t } = useTranslation();
  const onChangePass = (e) => {
    setPassword(e.target.value);
  };
  const onChangeFullName = (e) => {
    setFullName(e.target.value);
  };
  const onChangeLinkfb = (e) => {
    setLinkFb(e.target.value);
  };
  const onChangePhone = (e) => {
    setPhone(e.target.value);
  };
  const onChangeMail = (e) => {
    setMail(e.target.value);
  };
  const onChangeUser = (e) => {
    setUsername(e.target.value);
  };
  const onchangeEpl = (e) => {
    setLimitEpl(e.target.value);
  };
  const onchangePageLim = (e) => {
    setPageLim(e.target.value);
  };
  const selectedPermissionTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <div>{option?.name}</div>
        </div>
      );
    }
    return <span>{props.placeholder}</span>;
  };
  const permissionsList = [
    { name: "Quản trị viên", value: "is_admin" },
    { name: "Khách hàng", value: "is_manager" },
    { name: "Lập trình viên", value: "is_developer" },
    { name: "Chăm sóc khách hàng", value: "is_saler" },
  ];
  useEffect(() => {
    setUsername(getItemEdit?.username);
    setLinkFb(getItemEdit?.facebook_link);
    setPassword(getItemEdit?.password);
    setFullName(getItemEdit?.fullname);
    setPhone(getItemEdit?.phone_number);
    setMail(getItemEdit?.mail);
    setLimitLive(getItemEdit?.live_stream_limit);
  }, [getItemEdit]);
  var startTime = new Date(date);
  var formattedDate = `${startTime.getFullYear()}-${
    startTime.getMonth() + 1
  }-${startTime.getDate()} 23:59:59`;
  const editManager = async () => {
    const body = {
      username: username,
      password: password,
      fullname: fullName,
      facebook_link: linkFb,
      phone_number: phone,
      mail: mail,
      expiration_date: formattedDate,
      employee_limit: Number(limitEml),
      account_limit: Number(pageLim),
      permission: permission,
    };
    setRefresh(!refresh);
    setModalEdit(false);
    try {
      await axios.post(editAcc, body, headers);
    } catch (err) {}
  };
  const styles = {
    mb0ml2: {
      width: "260px",
      justifyContent: "flex-start",
    },
  };
  return (
    <div className=" flex justify-content-center">
      <Dialog
        header="Xác nhận tài khoản"
        visible={modalEdit}
        style={{ width: "50vw" }}
        onHide={() => setModalEdit(false)}
      >
        <div
          className="card flex flex-column flex-wrap md:flex-row gap-3"
          style={{ border: "0" }}
        >
          <div className="p-inputgroup ">
            <span className="p-inputgroup-addon" style={styles.mb0ml2}>
              <MdAccountCircle />
              <p className="mb-0 ml-2">Tên đăng nhập</p>
            </span>
            <InputText
              onChange={(e) => onChangeUser(e)}
              placeholder="Tên đăng nhập"
              value={username}
            />
          </div>

          <div className="p-inputgroup ">
            <span className="p-inputgroup-addon" style={styles.mb0ml2}>
              <FaFacebookF />
              <p className="mb-0 ml-2">Link Facebook</p>
            </span>
            <InputText
              onChange={(e) => onChangeLinkfb(e)}
              placeholder="Link Facebook"
              value={linkFb}
            />
          </div>

          <div className="p-inputgroup ">
            <span className="p-inputgroup-addon" style={styles.mb0ml2}>
              <MdOutlinePassword />
              <p className="mb-0 ml-2">Mật khẩu</p>
            </span>
            <Password
              toggleMask
              feedback={t("")}
              style={{ fontSize: "1rem" }}
              promptLabel={t("")}
              onChange={(e) => onChangePass(e)}
              placeholder="Mật khẩu đăng nhập"
              value={password}
            />
          </div>
          <div className="p-inputgroup ">
            <span className="p-inputgroup-addon" style={styles.mb0ml2}>
              <MdDriveFileRenameOutline />
              <p className="mb-0 ml-2">Họ Tên</p>
            </span>
            <InputText
              onChange={(e) => onChangeFullName(e)}
              placeholder="Họ Tên"
              value={fullName}
            />
          </div>
          <div className="p-inputgroup ">
            <span className="p-inputgroup-addon" style={styles.mb0ml2}>
              <FaPhoneAlt />
              <p className="mb-0 ml-2">Số điện thoại</p>
            </span>
            <InputText
              onChange={(e) => onChangePhone(e)}
              placeholder="Số điện thoại"
              keyfilter={/[0-9]/}
              value={phone}
            />
          </div>
          <div className="p-inputgroup ">
            <span className="p-inputgroup-addon" style={styles.mb0ml2}>
              <CiMail />
              <p className="mb-0 ml-2">Mail</p>
            </span>
            <InputText
              onChange={(e) => onChangeMail(e)}
              placeholder="Mail"
              keyfilter={/[0-9]/}
              value={mail}
            />
          </div>
          <div className="p-inputgroup ">
            <span className="p-inputgroup-addon" style={styles.mb0ml2}>
              <FaUserGroup />
              <p className="mb-0 ml-2">Phân quyền</p>
            </span>
            <Dropdown
              options={permissionsList}
              optionLabel="name"
              optionValue="value"
              value={permission}
              onChange={(e) => setPermission(e.value)}
              placeholder="Phân quyền"
              valueTemplate={selectedPermissionTemplate}
            />
          </div>
          <div className="p-inputgroup ">
            <span className="p-inputgroup-addon" style={styles.mb0ml2}>
              <MdOutlineTimeline />
              <p className="mb-0 ml-2">Giới hạn số lượng nhân viên</p>
            </span>
            <InputText
              value={limitEml}
              onChange={(e) => onchangeEpl(e)}
              placeholder="Giới hạn số lượng nhân viên"
              type="number"
              min={0}
            />
          </div>
          <div className="p-inputgroup ">
            <span className="p-inputgroup-addon" style={styles.mb0ml2}>
              <MdOutlineTimeline />
              <p className="mb-0 ml-2">Giới hạn số lượng tài khoản</p>
            </span>
            <InputText
              value={pageLim}
              onChange={(e) => onchangePageLim(e)}
              placeholder="Giới hạn số lượng tài khoản"
              type="number"
              min={0}
            />
          </div>
          <div className="p-inputgroup ">
            <span className="p-inputgroup-addon" style={styles.mb0ml2}>
              <CiViewTimeline />
              <p className="mb-0 ml-2">Hạn sử dụng</p>
            </span>
            <Calendar
              placeholder="Hạn sử dụng"
              locale="vi"
              id="buttondisplay"
              value={date}
              onChange={(e) => setDate(e.value)}
              minDate={currentDate}
              readOnlyInput
              disabledDates={[]}
            />
          </div>
        </div>
        <div className="d-flex justify-content-end gap-2 mt-2">
          <Button
            className="button-blue radius"
            label="Xác nhận"
            onClick={() => editManager()}
          />
          <Button
            className="button-red radius"
            label="Hủy"
            onClick={() => setModalEdit(false)}
            severity="danger"
          />
        </div>
      </Dialog>
    </div>
  );
};
