import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { API_ROUTES, API_URL, API_ROUTES_NTD } from "../../../api";
import { InputText } from "primereact/inputtext";
import { MdContentCopy } from "react-icons/md";
import { useHeaders } from "../../../const/headers";
import { ColorPicker } from "primereact/colorpicker";
import { useUser } from "../../../contexts/UserContext";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import "react-toastify/dist/ReactToastify.css";
import { MdLabel } from "react-icons/md";
import { TiTick } from "react-icons/ti";
import { RiDeleteBack2Fill } from "react-icons/ri";
import { PickList } from "primereact/picklist";
import { useLocation } from "react-router-dom";
export const ModalCopyBot = ({
  setOpenMdCopy,
  openMdCopy,
  editQuestion,
  setRf,
  rf,
  itemLabel,
  dataFb,
  itemPage,
  toast,
}) => {
  const location = useLocation();
  const apiCopy = API_URL + API_ROUTES_NTD.COPY_BOT;
  const apiCopyMarket = API_URL + API_ROUTES_NTD.COPY_CHATBOT_MARKET;
  const headers = useHeaders();
  const dataAcc = dataFb
    ?.filter((item) => item.checkpoint === false)
    ?.filter((item) => item.status_proxy === true);
  const [source, setSource] = useState([]);
  const [target, setTarget] = useState([]);

  const handleCopy = async () => {
    const confirm = window.confirm(
      "Lưu ý khi sao chép các tài khoản này sẽ mất kịch bản cũ, nhấn ok để tiếp tục sao chép"
    );
    if (confirm) {
      if (target?.length === 0) {
        alert("Vui lòng chọn ít nhất 1 tài khoản ");
        return;
      }
      const body = {
        id_account_main: itemPage.id,
        id_accounts: target?.map((item) => item.id),
      };
      try {
        const res = await axios.post(
          location.pathname === "/chatbotmarket" ? apiCopyMarket : apiCopy,
          body,
          headers
        );
        toast.success("Sao chép thành công");
        setSource(dataAcc.filter((item) => item.id !== itemPage.id));
        setTarget([]);
      } catch (err) {}
    }
  };

  useEffect(() => {
    setSource(dataAcc.filter((item) => item.id !== itemPage.id));
    setTarget([]);
  }, [openMdCopy]);

  const onChange = (event) => {
    setSource(event.source);
    setTarget(event.target);
  };

  const handleItemClick = (item) => {
    const sourceCopy = [...source];
    const targetCopy = [...target];
    const sourceIndex = sourceCopy.indexOf(item);
    if (sourceIndex !== -1) {
      sourceCopy.splice(sourceIndex, 1);
      targetCopy.push(item);
    } else {
      const targetIndex = targetCopy.indexOf(item);
      targetCopy.splice(targetIndex, 1);
      sourceCopy.push(item);
    }
    setSource(sourceCopy);
    setTarget(targetCopy);
  };

  const itemTemplate = (item) => {
    return (
      <div
        className="flex flex-wrap p-2 align-items-center gap-3"
        onClick={() => handleItemClick(item)}
        style={{ cursor: "pointer" }}
      >
        <img
          style={{
            width: "36px",
            borderRadius: "50%",
          }}
          src={item.avatar}
          alt={item.name}
        />
        <div className="flex-1 flex flex-column gap-2">
          <span className="font-bold">{item.name}</span>
        </div>
      </div>
    );
  };

  return (
    <div className="flex justify-content-center">
      <Dialog
        header="Sao chép Bot"
        visible={openMdCopy}
        onHide={() => setOpenMdCopy(false)}
        style={{ width: "50vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        <div className="card-picklist-style">
          <PickList
            dataKey="id"
            source={source}
            target={target}
            onChange={onChange}
            itemTemplate={itemTemplate}
            filter
            filterBy="name"
            breakpoint="1280px"
            sourceHeader="Chọn tài khoản"
            targetHeader="Tài khoản được chọn "
            sourceStyle={{ height: "24rem" }}
            targetStyle={{ height: "24rem" }}
            sourceFilterPlaceholder=""
            targetFilterPlaceholder=""
          />
        </div>
        <div className="w-100 text-right">
          <Button
            onClick={handleCopy}
            className="button-blue border "
            style={{ borderRadius: "0" }}
          >
            Sao chép Bot
          </Button>
        </div>
      </Dialog>
    </div>
  );
};
