import { AiOutlineCheckCircle } from "react-icons/ai";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { FaExclamationTriangle } from "react-icons/fa";

export function updateTitle(newTitle) {
  document.getElementById("dynamicTitle").innerText = newTitle;
}
export const statusLineCheck = (rowData, status) => {
  return (
    <div className="modal-status-line-check">
      {rowData?.[status] === 2 ? (
        <></>
      ) : rowData?.[status] === 1 ? (
        <AiOutlineCheckCircle
          size={20}
          style={{ color: "green" }}
          title="Thành công"
        />
      ) : rowData?.[status] === 3 ? (
        <FaExclamationTriangle
          size={20}
          style={{ color: "#ff7809" }}
          title="Tài khoản của bạn đang bị FB hạn chế chức năng này.
          Hệ thống đã tự động bật tính năng bảo vệ tài khoản bằng cách dừng chạy chức năng này"
        />
      ) : (
        <AiOutlineCloseCircle
          size={20}
          style={{ color: "red" }}
          title="Thất bại"
        />
      )}
    </div>
  );
};

export const statusMarket = (rowData) => {
  return (
    <div className="modal-status-line-check">
      {rowData.status === 0 ? (
        <AiOutlineCloseCircle
          size={20}
          style={{ color: "red" }}
          title="Thất bại"
        />
      ) : rowData.status === 1 ? (
        <AiOutlineCheckCircle
          size={20}
          style={{ color: "green" }}
          title="Thành công"
        />
      ) : rowData.status === 2 ? (
        <FaExclamationTriangle
          size={20}
          style={{ color: "#ff7809" }}
          title={rowData.status_indicator}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export const statusRunTools = (rowData) => {
  return (
    <p
      style={{
        color:
          rowData?.status === 0
            ? "red"
            : rowData?.status === 1
            ? "#573613"
            : rowData?.status === 2
            ? "blue"
            : "",
      }}
    >
      {rowData?.status === null
        ? ""
        : rowData?.status === 0
        ? "Tạm dừng"
        : rowData?.status === 1
        ? "Đang chạy"
        : rowData?.status === 2
        ? "Hoàn thành"
        : ""}
    </p>
  );
};
