import classNames from "classnames/bind";
import styles from "./Main.module.scss";
import axios from "axios";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { API_ROUTES, API_URL } from "../../../../../../api";
import { BsDot } from "react-icons/bs";
import { useEffect, useState } from "react";
import { useUser } from "../../../../../../contexts/UserContext";
import { useHeaders } from "../../../../../../const/headers";
import { MdContentCopy } from "react-icons/md";
import { InputText } from "primereact/inputtext";
import { IoIosLink } from "react-icons/io";
const cx = classNames.bind(styles);
function Main() {
  const navigate = useNavigate();
  const handleNavi = (link) => {
    navigate(link);
  };
  const { userInfo } = useUser();
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [visible3, setVisible3] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [file, setFile] = useState(null);
  const [image, setImage] = useState("");
  const [image2, setImage2] = useState("");
  const [image3, setImage3] = useState("");
  const [imageFirst, setImageFirst] = useState("");
  const [content, setContent] = useState("");
  const [content2, setContent2] = useState("");
  const [content3, setContent3] = useState("");
  const [description, setDescription] = useState("");
  const [description2, setDescription2] = useState("");
  const [description3, setDescription3] = useState("");
  const [rf, setRf] = useState(false);
  const headers = useHeaders(); // Update with your headers configuration
  const itemEdit = { id: "" }; // Update itemEdit with the appropriate value
  const uploadEndpoint = API_URL + API_ROUTES.UPLOAD_FILE_SERVER;
  const createUpdateEndpoint = API_URL + API_ROUTES.EDIT_LANDING;
  const apiGetLanding = API_URL + API_ROUTES.GET_ALL_LANDING;
  const [landing, setLanding] = useState([]);
  const fetchData = async () => {
    try {
      const res = await axios.get(apiGetLanding);
      setLanding(res.data);
    } catch (err) {}
  };
  useEffect(() => {
    fetchData();
  }, [rf]);
  const contentFistMain = landing?.filter((item) => item?.id === 4);
  const contentSecondMain = landing?.filter((item) => item?.id === 5);
  const contentThirdMain = landing?.filter((item) => item?.id === 6);
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFile(file);
    const reader = new FileReader();
    reader.onload = () => {
      const imageDataURL = reader.result;
      const imgElement = document.getElementById("previewImage");
      if (imgElement) {
        imgElement.src = imageDataURL;
      }
    };
    reader.readAsDataURL(file);
  };

  const uploadToServer = async () => {
    if (file !== null) {
      const formData = new FormData();
      formData.append("image", file);
      try {
        const response = await axios.post(uploadEndpoint, formData, headers);
        setImage(response.data.image);
        setImage2(response.data.image);
        setImage3(response.data.image);
      } catch (error) {}
    }
  };
  useEffect(() => {
    setImage(contentFistMain[0]?.image);
    setDescription(contentFistMain[0]?.content);
    setContent(contentFistMain[0]?.title);
    setImage2(contentSecondMain[0]?.image);
    setDescription2(contentSecondMain[0]?.content);
    setContent2(contentSecondMain[0]?.title);
    setImage3(contentThirdMain[0]?.image);
    setDescription3(contentThirdMain[0]?.content);
    setContent3(contentThirdMain[0]?.title);
  }, [visible, visible2, visible3]);
  useEffect(() => {
    uploadToServer();
  }, [file]);
  const onChangeContent = (e) => {
    setContent(e.target.value);
  };
  const onChangeContent2 = (e) => {
    setContent2(e.target.value);
  };
  const onChangeContent3 = (e) => {
    setContent3(e.target.value);
  };
  const onChangeLink = (e) => {
    setDescription(e.target.value);
  };
  const onChangeLink2 = (e) => {
    setDescription2(e.target.value);
  };
  const onChangeLink3 = (e) => {
    setDescription3(e.target.value);
  };
  const createResource = async () => {
    const data = {
      id: contentFistMain[0]?.id,
      title: content,
      content: description,
      image: image === "" ? contentFistMain[0]?.image : image,
    };
    try {
      const response = await axios.post(createUpdateEndpoint, data, headers);
      setRf(!rf);
      setDescription("");
      setContent("");
      setVisible(false);
    } catch (error) {}
  };
  const createResource2 = async () => {
    const data = {
      id: contentSecondMain[0]?.id,
      title: content2,
      content: description2,
      image: image2 === "" ? contentSecondMain[0]?.image : image2,
    };
    try {
      const response = await axios.post(createUpdateEndpoint, data, headers);
      setRf(!rf);
      setDescription2("");
      setContent2("");
      setVisible2(false);
    } catch (error) {}
  };
  const createResource3 = async () => {
    const data = {
      id: contentThirdMain[0]?.id,
      title: content3,
      content: description3,
      image: image3 === "" ? contentThirdMain[0]?.image : image3,
    };
    try {
      const response = await axios.post(createUpdateEndpoint, data, headers);
      setRf(!rf);
      setDescription3("");
      setContent3("");
      setVisible3(false);
    } catch (error) {}
  };
  return (
    <>
      <nav className={cx("nav")}>
        <h4 className={cx("title-container")}>{contentFistMain[0]?.title}</h4>
        <p className={cx("line")}></p>
        <img
          className={cx("img-container")}
          src={`${API_URL}/${contentFistMain[0]?.image}`}
          alt="hệ sinh thái"
        />
        <p style={{ fontSize: "1rem" }} className={cx("title")}>
          {contentFistMain[0]?.content}
        </p>
      </nav>
      {userInfo?.is_admin === true ? (
        <button
          style={{
            width: "100%",
            border: "none",
            backgroundColor: "#0866ff",
            color: "#fff",
            fontSize: "1rem",
            padding: "0.5rem 1rem",
            cursor: "pointer",
            outline: "none",
            transition: "all 0.2s ease-in-out",
          }}
          onClick={() => setVisible(true)}
        >
          Sửa
        </button>
      ) : (
        <></>
      )}
      <Dialog
        header="Quản lý"
        visible={visible}
        style={{ width: "50vw" }}
        onHide={() => {
          if (!visible) return;
          setVisible(false);
        }}
      >
        <div className="d-flex flex-column gap-2 mr-2 mb-2">
          <div className="p-inputgroup flex-1">
            <span className="p-inputgroup-addon">
              <MdContentCopy />
            </span>
            <InputText
              onChange={(e) => onChangeContent(e)}
              placeholder="Tiêu đề"
              value={content}
            />
          </div>
          <div className="p-inputgroup flex-1">
            <span className="p-inputgroup-addon">
              <IoIosLink />
            </span>
            <InputText
              onChange={(e) => onChangeLink(e)}
              value={description}
              placeholder="Mô tả"
            />
          </div>
          <h3>Chọn Ảnh</h3>
          <label htmlFor="fileInput">
            <button
              className="d-flex flex-column p-2 align-items-center gap-2 border border-secondary justify-content-between rounded"
              onClick={() => document.getElementById("fileInput").click()}
            >
              <p className="mb-0">Chọn ảnh</p>
            </button>
          </label>
          <input
            id="fileInput"
            type="file"
            style={{ display: "none" }}
            onChange={handleImageChange}
          />
          {image === "" && imageFirst === "" ? (
            <div></div>
          ) : (
            <img
              style={{
                width: "100px",
                display: "block",
              }}
              alt="Selected Image"
              src={`${API_URL}/${image === "" ? imageFirst : image}`}
            />
          )}
        </div>
        <div className="d-flex justify-content-end gap-2 mt-2">
          <Button
            className="button-blue radius"
            label="Sửa"
            onClick={() => createResource()}
          />
          <Button
            className="button-red radius"
            label="Hủy"
            onClick={() => setVisible(false)}
            severity="danger"
          />
        </div>
      </Dialog>
      <div className={cx("nav__pc")}>
        <div className={cx("wrapper")}>
          <nav className={cx("image1")}>
            <nav>
              <h4 style={{ fontSize: "1rem", fontWeight: "500" }}>
                {contentSecondMain[0]?.title}
              </h4>
              <nav
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  gap: "1rem",
                  marginBottom: "8px",
                }}
              >
                <span
                  style={{
                    height: "6px",
                    backgroundColor: "#333",
                    width: "40px",
                    borderRadius: "4px",
                  }}
                ></span>
                <span
                  style={{
                    height: "6px",
                    backgroundColor: "#333",
                    width: "40px",
                    borderRadius: "4px",
                  }}
                ></span>
                <span
                  style={{
                    height: "6px",
                    backgroundColor: "#333",
                    width: "40px",
                    borderRadius: "4px",
                  }}
                ></span>
              </nav>
              {/* <p
                dangerouslySetInnerHTML={{
                  __html: contentSecondMain[0]?.content,
                }}
              /> */}
              <ul>
                <li className={cx("content-li")}>
                  <BsDot />
                  Tự động Tìm kiếm khách hàng tiềm năng theo đúng ngành nghề,
                  lĩnh vực
                </li>
                <li className={cx("content-li")}>
                  <BsDot />
                  Hỗ trợ xây dựng song song nhiều tài khoản Facebook bán hàng
                  hiệu quả
                </li>
                <li className={cx("content-li")}>
                  <BsDot />
                  Tự động quét khách hàng, tương tác, đăng bài, kết bạn theo
                  lịch trình có sẵn
                </li>
                <li className={cx("content-li")}>
                  <BsDot />
                  Tham gia hội nhóm đăng bài kết bạn kết nối đến không giới hạn
                  khách hàng
                </li>
                <li className={cx("content-li")}>
                  <BsDot />
                  Chăm sóc lại TỆP khách hàng cũ bằng vài thao tác đơn giản
                </li>
              </ul>
            </nav>
            <img
              className={cx("image-container-1")}
              src={`${API_URL}/${contentSecondMain[0]?.image}`}
              alt="hệ sinh thái"
            />
            <button
              onClick={() => handleNavi("/register")}
              className={cx("main-btn")}
            >
              DÙNG NGAY MIỄN PHÍ
            </button>
            {userInfo?.is_admin === true ? (
              <button
                className="mt-2"
                style={{
                  width: "100%",
                  border: "none",
                  backgroundColor: "#0866ff",
                  color: "#fff",
                  fontSize: "1rem",
                  padding: "0.5rem 1rem",
                  cursor: "pointer",
                  outline: "none",
                  transition: "all 0.2s ease-in-out",
                }}
                onClick={() => setVisible2(true)}
              >
                Sửa
              </button>
            ) : (
              <></>
            )}
            <Dialog
              header="Quản lý"
              visible={visible2}
              style={{ width: "50vw" }}
              onHide={() => {
                if (!visible2) return;
                setVisible2(false);
              }}
            >
              <div className="d-flex flex-column gap-2 mr-2 mb-2">
                <div className="p-inputgroup flex-1">
                  <span className="p-inputgroup-addon">
                    <MdContentCopy />
                  </span>
                  <InputText
                    onChange={(e) => onChangeContent2(e)}
                    placeholder="Tiêu đề"
                    value={content2}
                  />
                </div>
                <div className="p-inputgroup flex-1">
                  <span className="p-inputgroup-addon">
                    <IoIosLink />
                  </span>
                  <InputText
                    onChange={(e) => onChangeLink2(e)}
                    value={description2}
                    placeholder="Mô tả"
                  />
                </div>
                <h3>Chọn Ảnh</h3>
                <label htmlFor="fileInput">
                  <button
                    className="d-flex flex-column p-2 align-items-center gap-2 border border-secondary justify-content-between rounded"
                    onClick={() => document.getElementById("fileInput").click()}
                  >
                    <p className="mb-0">Chọn ảnh</p>
                  </button>
                </label>
                <input
                  id="fileInput"
                  type="file"
                  style={{ display: "none" }}
                  onChange={handleImageChange}
                />
                {image2 === "" && imageFirst === "" ? (
                  <div></div>
                ) : (
                  <img
                    style={{
                      width: "100px",
                      display: "block",
                    }}
                    alt="Selected Image"
                    src={`${API_URL}/${image2 === "" ? imageFirst : image2}`}
                  />
                )}
              </div>
              <div className="d-flex justify-content-end gap-2 mt-2">
                <Button
                  className="button-blue radius"
                  label="Sửa"
                  onClick={() => createResource2()}
                />
                <Button
                  className="button-red radius"
                  label="Hủy"
                  onClick={() => setVisible2(false)}
                  severity="danger"
                />
              </div>
            </Dialog>
          </nav>

          <nav className={cx("container")}>
            <div className={cx("section-container")}>
              <nav className={cx("section-title")}>
                <h4 className={cx("text-light")}></h4>
                <h4 className={cx("text-title")}>
                  CHỐT SALE ỨNG DỤNG HÀNG ĐẦU
                </h4>
              </nav>
              <ul className={cx("section-content")}>
                <p>
                  {/* <BsDot /> */}
                  Với CHỐT SALE bạn có thể tự động BÁN HÀNG VÀ CHỐT ĐƠN cùng 1
                  lúc trên nhiều tài khoản Facebook chăm sóc khách hàng tự động:
                </p>
                <li>
                  <BsDot />
                  Sử dụng mọi nơi trên mọi thiết bị
                </li>
                <li>
                  <BsDot />
                  không cần máy tính cấu hình cao
                </li>
                <li>
                  <BsDot />
                  không cần treo máy
                </li>
                <li>
                  <BsDot />
                  Phân quyền và quản lý nhân viên
                </li>
                <li>
                  <BsDot />
                  Quản lý tin nhắn nhiều tài khoản 1 giao diện
                </li>
                <li>
                  <BsDot />
                  Gửi tin nhắn thủ công và tự động 2 in 1
                </li>
                <li>
                  <BsDot />
                  Quản lý thông báo nhiều tài khoản
                </li>
                <li>
                  <BsDot />
                  Tùy biến kịch bản marketing chuyên nghiệp và đơn giản
                </li>
                <li>
                  <BsDot />
                  Tìm kiếm khách hàng mọi nơi từ trang cá nhân sang Fanpage đến
                  group hội nhóm
                </li>
                <li>
                  <BsDot />
                  Tìm kiếm khách hàng mục tiêu theo từ khóa
                </li>
                <li>
                  <BsDot />
                  Marketing bán hàng theo mục tiêu có từ khóa cao cấp
                </li>
              </ul>
            </div>
          </nav>
        </div>
        <div className={cx("wrapper")}>
          <nav className={cx("container")}>
            <div className={cx("section-container")}>
              <nav className={cx("section-title")}>
                <h4 className={cx("text-light")}></h4>
                <h4 className={cx("text-title")}>
                  CHỨC NĂNG ĐA DẠNG VÀ ỔN ĐỊNH
                </h4>
              </nav>
              <ul className={cx("section-content")}>
                <li>
                  <BsDot />
                  Tạo gắn nhãn phân loại tệp khách hàng
                </li>
                <li>
                  <BsDot />
                  Remarketing hiệu quả từng chiến dịch
                </li>
                <li>
                  <BsDot />
                  Phân chia chiến dịch và lưu trữ thông tin chiến dịch
                </li>
                <li>
                  <BsDot />
                  Tùy chọn chiến dịch mới và cũ
                </li>
                <li>
                  <BsDot />
                  Bám đuổi tệp khách hàng ngày đêm
                </li>
                <li>
                  <BsDot />
                  Đăng bài lên hội nhóm
                </li>
                <li>
                  <BsDot />
                  Quản lý phân loại danh sách nhóm
                </li>
                <li>
                  <BsDot />
                  Tùy chọn ramdom nhiều nội dung
                </li>
                <li>
                  <BsDot />
                  Đăng nội dung kèm 1 hoặc nhiều ảnh
                </li>
                <li>
                  <BsDot />
                  Đăng nội dung kèm video
                </li>
                <li>
                  <BsDot />
                  Quét UID like bài viết
                </li>
                <li>
                  <BsDot />
                  Quét UID comment bài viết
                </li>
                <li>
                  <BsDot />
                  Quét UID bài viết cá nhân
                </li>
                <li>
                  <BsDot />
                  Quét UID bài viết nhóm
                </li>
                <li>
                  <BsDot />
                  Quét UID bài viết fanpage
                </li>
                <li>
                  <BsDot />
                  Quét UID nhóm công khai
                </li>
                <li>
                  <BsDot />
                  Quét UID nhóm theo từ khóa
                </li>
                <li>
                  <BsDot />
                  Quét UID Fanpage theo từ khóa
                </li>
              </ul>
            </div>
          </nav>
          <nav className={cx("image2")}>
            <nav>
              <h4 style={{ fontSize: "1rem", fontWeight: "500" }}>
                {contentThirdMain[0]?.title}
              </h4>
              <nav
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  gap: "1rem",
                  marginBottom: "8px",
                }}
              >
                <span
                  style={{
                    height: "6px",
                    backgroundColor: "#333",
                    width: "40px",
                    borderRadius: "4px",
                  }}
                ></span>
                <span
                  style={{
                    height: "6px",
                    backgroundColor: "#333",
                    width: "40px",
                    borderRadius: "4px",
                  }}
                ></span>
                <span
                  style={{
                    height: "6px",
                    backgroundColor: "#333",
                    width: "40px",
                    borderRadius: "4px",
                  }}
                ></span>
              </nav>
              <ul>
                <li className={cx("content-li")}>
                  <BsDot />
                  Thuật toán AN TOÀN hạn chế ảnh hưởng tài khoản
                </li>
                <li className={cx("content-li")}>
                  <BsDot />
                  Quản lý danh sách khách hàng
                </li>
                <li className={cx("content-li")}>
                  <BsDot />
                  Tương tác với khách hàng trên news feed
                </li>
                <li className={cx("content-li")}>
                  <BsDot />
                  Tùy biến kịch bản marketing chuyên nghiệp và đơn giản
                </li>
                <li className={cx("content-li")}>
                  <BsDot />
                  Like, kết bạn, comment, nhắn tin nội dung hình ảnh
                </li>
              </ul>
            </nav>
            <nav className={cx("image2-container")}>
              <img
                className={cx("image-container-2")}
                src={`${API_URL}/${contentThirdMain[0]?.image}`}
                alt="hệ sinh thái"
              />
              <button
                style={{ marginTop: "16px" }}
                onClick={() => handleNavi("/register")}
                className={cx("main-btn")}
              >
                DÙNG NGAY MIỄN PHÍ
              </button>
              {userInfo?.is_admin === true ? (
                <button
                  className="mt-2"
                  style={{
                    width: "100%",
                    border: "none",
                    backgroundColor: "#0866ff",
                    color: "#fff",
                    fontSize: "1rem",
                    padding: "0.5rem 1rem",
                    cursor: "pointer",
                    outline: "none",
                    transition: "all 0.2s ease-in-out",
                  }}
                  onClick={() => setVisible3(true)}
                >
                  Sửa
                </button>
              ) : (
                <></>
              )}
              <Dialog
                header="Quản lý"
                visible={visible3}
                style={{ width: "50vw" }}
                onHide={() => {
                  if (!visible3) return;
                  setVisible3(false);
                }}
              >
                <div className="d-flex flex-column gap-2 mr-2 mb-2">
                  <div className="p-inputgroup flex-1">
                    <span className="p-inputgroup-addon">
                      <MdContentCopy />
                    </span>
                    <InputText
                      onChange={(e) => onChangeContent3(e)}
                      placeholder="Tiêu đề"
                      value={content3}
                    />
                  </div>
                  <div className="p-inputgroup flex-1">
                    <span className="p-inputgroup-addon">
                      <IoIosLink />
                    </span>
                    <InputText
                      onChange={(e) => onChangeLink3(e)}
                      value={description3}
                      placeholder="Mô tả"
                    />
                  </div>
                  <h3>Chọn Ảnh</h3>
                  <label htmlFor="fileInput">
                    <button
                      className="d-flex flex-column p-2 align-items-center gap-2 border border-secondary justify-content-between rounded"
                      onClick={() =>
                        document.getElementById("fileInput").click()
                      }
                    >
                      <p className="mb-0">Chọn ảnh</p>
                    </button>
                  </label>
                  <input
                    id="fileInput"
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleImageChange}
                  />
                  {image3 === "" && imageFirst === "" ? (
                    <div></div>
                  ) : (
                    <img
                      style={{
                        width: "100px",
                        display: "block",
                      }}
                      alt="Selected Image"
                      src={`${API_URL}/${image3 === "" ? imageFirst : image3}`}
                    />
                  )}
                </div>
                <div className="d-flex justify-content-end gap-2 mt-2">
                  <Button
                    className="button-blue radius"
                    label="Sửa"
                    onClick={() => createResource3()}
                  />
                  <Button
                    className="button-red radius"
                    label="Hủy"
                    onClick={() => setVisible3(false)}
                    severity="danger"
                  />
                </div>
              </Dialog>
            </nav>
          </nav>
        </div>
      </div>
      <div className={cx("nav__mobile")}>
        <div className={cx("wrapper")}>
          <nav className={cx("container")}>
            <div className={cx("section-container")}>
              <nav className={cx("section-title")}>
                <h4 className={cx("text-light")}></h4>
                <h4 className={cx("text-title")}>
                  CHỐT SALE ỨNG DỤNG HÀNG ĐẦU
                </h4>
              </nav>
              <ul className={cx("section-content")}>
                <p>
                  {/* <BsDot /> */}
                  Với CHỐT SALE bạn có thể tự động BÁN HÀNG VÀ CHỐT ĐƠN cùng 1
                  lúc trên nhiều tài khoản Facebook chăm sóc khách hàng tự động:
                </p>
                <li>
                  <BsDot />
                  Sử dụng mọi nơi trên mọi thiết bị
                </li>
                <li>
                  <BsDot />
                  không cần máy tính cấu hình cao
                </li>
                <li>
                  <BsDot />
                  không cần treo máy
                </li>
                <li>
                  <BsDot />
                  Phân quyền và quản lý nhân viên
                </li>
                <li>
                  <BsDot />
                  Quản lý tin nhắn nhiều tài khoản 1 giao diện
                </li>
                <li>
                  <BsDot />
                  Gửi tin nhắn thủ công và tự động 2 in 1
                </li>
                <li>
                  <BsDot />
                  Quản lý thông báo nhiều tài khoản
                </li>
                <li>
                  <BsDot />
                  Tùy biến kịch bản marketing chuyên nghiệp và đơn giản
                </li>
                <li>
                  <BsDot />
                  Tìm kiếm khách hàng mọi nơi từ trang cá nhân sang page đến
                  group
                </li>
                <li>
                  <BsDot />
                  Tìm kiếm khách hàng mục tiêu theo từ khóa
                </li>
                <li>
                  <BsDot />
                  Marketing bán hàng theo mục tiêu có từ khóa cao cấp
                </li>
              </ul>
            </div>
          </nav>
          <nav className={cx("image1")}>
            <img
              className={cx("image-container-1")}
              src={`${API_URL}/${contentSecondMain[0]?.image}`}
              alt="hệ sinh thái"
            />
          </nav>
          <nav>
            <h4 style={{ fontSize: "1.4rem", fontWeight: "700" }}>
              {contentSecondMain[0]?.title}
            </h4>
            <nav
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                gap: "1rem",
                marginBottom: "8px",
              }}
            >
              <span
                style={{
                  height: "6px",
                  backgroundColor: "#333",
                  width: "40px",
                  borderRadius: "4px",
                }}
              ></span>
              <span
                style={{
                  height: "6px",
                  backgroundColor: "#333",
                  width: "40px",
                  borderRadius: "4px",
                }}
              ></span>
              <span
                style={{
                  height: "6px",
                  backgroundColor: "#333",
                  width: "40px",
                  borderRadius: "4px",
                }}
              ></span>
            </nav>
            <ul>
              <li className={cx("content-li")}>
                <BsDot />
                Tự động tìm kiếm khách hàng tiềm năng theo đúng ngành nghề, lĩnh
                vực
              </li>
              <li className={cx("content-li")}>
                <BsDot />
                Hỗ trợ xây dựng song song nhiều tài khoản Facebook bán hàng hiệu
                quả
              </li>
              <li className={cx("content-li")}>
                <BsDot />
                Tự động quét khách hàng, tương tác, đăng bài, kết bạn theo lịch
                trình có sẵn
              </li>
              <li className={cx("content-li")}>
                <BsDot />
                Tham gia hội nhóm đăng bài kết bạn kết nối đến không giới hạn
                khách hàng
              </li>
              <li className={cx("content-li")}>
                <BsDot />
                Chăm sóc lại TỆP khách hàng cũ bằng vài thao tác đơn giản
              </li>
            </ul>
          </nav>
          <button
            onClick={() => handleNavi("/register")}
            className={cx("main-btn")}
          >
            DÙNG NGAY MIỄN PHÍ
          </button>
          {userInfo?.is_admin === true ? (
            <button
              className="mt-2"
              style={{
                width: "100%",
                border: "none",
                backgroundColor: "#0866ff",
                color: "#fff",
                fontSize: "1rem",
                padding: "0.5rem 1rem",
                cursor: "pointer",
                outline: "none",
                transition: "all 0.2s ease-in-out",
              }}
              onClick={() => setVisible2(true)}
            >
              Sửa
            </button>
          ) : (
            <></>
          )}
          <Dialog
            header="Quản lý"
            visible={visible2}
            style={{ width: "50vw" }}
            onHide={() => {
              if (!visible2) return;
              setVisible2(false);
            }}
          >
            <div className="d-flex flex-column gap-2 mr-2 mb-2">
              <div className="p-inputgroup flex-1">
                <span className="p-inputgroup-addon">
                  <MdContentCopy />
                </span>
                <InputText
                  onChange={(e) => onChangeContent2(e)}
                  placeholder="Tiêu đề"
                  value={content2}
                />
              </div>
              <div className="p-inputgroup flex-1">
                <span className="p-inputgroup-addon">
                  <IoIosLink />
                </span>
                <InputText
                  onChange={(e) => onChangeLink2(e)}
                  value={description2}
                  placeholder="Mô tả"
                />
              </div>
              <h3>Chọn Ảnh</h3>
              <label htmlFor="fileInput">
                <button
                  className="d-flex flex-column p-2 align-items-center gap-2 border border-secondary justify-content-between rounded"
                  onClick={() => document.getElementById("fileInput").click()}
                >
                  <p className="mb-0">Chọn ảnh</p>
                </button>
              </label>
              <input
                id="fileInput"
                type="file"
                style={{ display: "none" }}
                onChange={handleImageChange}
              />
              {image2 === "" && imageFirst === "" ? (
                <div></div>
              ) : (
                <img
                  style={{
                    width: "100px",
                    display: "block",
                  }}
                  alt="Selected Image"
                  src={`${API_URL}/${image2 === "" ? imageFirst : image2}`}
                />
              )}
            </div>
            <div className="d-flex justify-content-end gap-2 mt-2">
              <Button
                className="button-blue radius"
                label="Sửa"
                onClick={() => createResource2()}
              />
              <Button
                className="button-red radius"
                label="Hủy"
                onClick={() => setVisible2(false)}
                severity="danger"
              />
            </div>
          </Dialog>
        </div>
        <div className={cx("wrapper")}>
          <nav className={cx("container")}>
            <div className={cx("section-container")}>
              <nav className={cx("section-title")}>
                <h4 className={cx("text-light")}></h4>
                <h4 className={cx("text-title")}>
                  CHỨC NĂNG ĐA DẠNG VÀ ỔN ĐỊNH
                </h4>
              </nav>
              <ul className={cx("section-content")}>
                <li>
                  <BsDot />
                  Tạo gắn nhãn phân loại tệp khách hàng
                </li>
                <li>
                  <BsDot />
                  Remarketing hiệu quả từng chiến dịch
                </li>
                <li>
                  <BsDot />
                  Phân chia chiến dịch và lưu trữ thông tin chiến dịch
                </li>
                <li>
                  <BsDot />
                  Tùy chọn chiến dịch mới và cũ
                </li>
                <li>
                  <BsDot />
                  Bám đuổi tệp khách hàng ngày đêm
                </li>
                <li>
                  <BsDot />
                  Đăng bài lên hội nhóm
                </li>
                <li>
                  <BsDot />
                  Quản lý phân loại danh sách nhóm
                </li>
                <li>
                  <BsDot />
                  Tùy chọn ramdom nhiều nội dung
                </li>
                <li>
                  <BsDot />
                  Đăng nội dung kèm 1 hoặc nhiều ảnh
                </li>
                <li>
                  <BsDot />
                  Đăng nội dung kèm video
                </li>
                <li>
                  <BsDot />
                  Quét UID like bài viết
                </li>
                <li>
                  <BsDot />
                  Quét UID comment bài viết
                </li>
                <li>
                  <BsDot />
                  Quét UID bài viết cá nhân
                </li>
                <li>
                  <BsDot />
                  Quét UID bài viết nhóm
                </li>
                <li>
                  <BsDot />
                  Quét UID bài viết Fanpage
                </li>
                <li>
                  <BsDot />
                  Quét UID nhóm công khai
                </li>
                <li>
                  <BsDot />
                  Quét UID nhóm theo từ khóa
                </li>
                <li>
                  <BsDot />
                  Quét UID Fanpage theo từ khóa
                </li>
              </ul>
            </div>
          </nav>
          <nav className={cx("image2")}>
            <nav className={cx("image2-container")}>
              <img
                className={cx("image-container-2")}
                src={`${API_URL}/${contentThirdMain[0]?.image}`}
                alt="hệ sinh thái"
              />
            </nav>
          </nav>
          <button
            onClick={() => handleNavi("/register")}
            className={cx("main-btn")}
          >
            DÙNG NGAY MIỄN PHÍ
          </button>
          {userInfo?.is_admin === true ? (
            <button
              className="mt-2"
              style={{
                width: "100%",
                border: "none",
                backgroundColor: "#0866ff",
                color: "#fff",
                fontSize: "1rem",
                padding: "0.5rem 1rem",
                cursor: "pointer",
                outline: "none",
                transition: "all 0.2s ease-in-out",
              }}
              onClick={() => setVisible3(true)}
            >
              Sửa
            </button>
          ) : (
            <></>
          )}
          <Dialog
            header="Quản lý"
            visible={visible3}
            style={{ width: "50vw" }}
            onHide={() => {
              if (!visible3) return;
              setVisible3(false);
            }}
          >
            <div className="d-flex flex-column gap-2 mr-2 mb-2">
              <div className="p-inputgroup flex-1">
                <span className="p-inputgroup-addon">
                  <MdContentCopy />
                </span>
                <InputText
                  onChange={(e) => onChangeContent3(e)}
                  placeholder="Tiêu đề"
                  value={content3}
                />
              </div>
              <div className="p-inputgroup flex-1">
                <span className="p-inputgroup-addon">
                  <IoIosLink />
                </span>
                <InputText
                  onChange={(e) => onChangeLink3(e)}
                  value={description3}
                  placeholder="Mô tả"
                />
              </div>
              <h3>Chọn Ảnh</h3>
              <label htmlFor="fileInput">
                <button
                  className="d-flex flex-column p-2 align-items-center gap-2 border border-secondary justify-content-between rounded"
                  onClick={() => document.getElementById("fileInput").click()}
                >
                  <p className="mb-0">Chọn ảnh</p>
                </button>
              </label>
              <input
                id="fileInput"
                type="file"
                style={{ display: "none" }}
                onChange={handleImageChange}
              />
              {image3 === "" && imageFirst === "" ? (
                <div></div>
              ) : (
                <img
                  style={{
                    width: "100px",
                    display: "block",
                  }}
                  alt="Selected Image"
                  src={`${API_URL}/${image3 === "" ? imageFirst : image3}`}
                />
              )}
            </div>
            <div className="d-flex justify-content-end gap-2 mt-2">
              <Button
                className="button-blue radius"
                label="Sửa"
                onClick={() => createResource3()}
              />
              <Button
                className="button-red radius"
                label="Hủy"
                onClick={() => setVisible3(false)}
                severity="danger"
              />
            </div>
          </Dialog>
          <nav>
            <h4 style={{ fontSize: "1rem", fontWeight: "500" }}>
              {contentThirdMain[0]?.title}
            </h4>
            <nav
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                gap: "1rem",
                marginBottom: "8px",
              }}
            >
              <span
                style={{
                  height: "6px",
                  backgroundColor: "#333",
                  width: "40px",
                  borderRadius: "4px",
                }}
              ></span>
              <span
                style={{
                  height: "6px",
                  backgroundColor: "#333",
                  width: "40px",
                  borderRadius: "4px",
                }}
              ></span>
              <span
                style={{
                  height: "6px",
                  backgroundColor: "#333",
                  width: "40px",
                  borderRadius: "4px",
                }}
              ></span>
            </nav>

            <ul>
              <li className={cx("content-li")}>
                <BsDot />
                Thuật toán AN TOÀN hạn chế ảnh hưởng tài khoản
              </li>
              <li className={cx("content-li")}>
                <BsDot />
                Quản lý danh sách khách hàng
              </li>
              <li className={cx("content-li")}>
                <BsDot />
                Tương tác với khách hàng trên newsfeed
              </li>
              <li className={cx("content-li")}>
                <BsDot />
                Tùy biến kịch bản marketing chuyên nghiệp và đơn giản
              </li>
              <li className={cx("content-li")}>
                <BsDot />
                Like, kết bạn, comment, nhắn tin nội dung hình ảnh
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
}

export default Main;
