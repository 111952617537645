import React, { useState, useEffect, useRef } from "react";
import Navigate from "../container/Navigate";
import faimg from "./img/faaa.png";
import { Footer } from "./Footer";
import { API_URL, API_ROUTES, API_ROUTES_NTD, WS_URL } from "../../api";
import axios from "axios";
import { ClipLoader } from "react-spinners";
import { RiAttachment2 } from "react-icons/ri";
import { Button } from "react-bootstrap";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { IoSendSharp } from "react-icons/io5";
import { useHeaders } from "../../const/headers";
import { updateTitle } from "../../const/updateTitle";
import bellSound from "../audio/messting.mp3";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CreateLabels } from "../container/labelModal/CreateLable";
import { useUser } from "../../contexts/UserContext";
import { AddLabelMess } from "../container/labelModal/AddLabelMess";
import { MdLabel } from "react-icons/md";
import { ModalAutoInbox } from "../container/labelModal/ModalAutoInbox";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import EmojiPicker from "emoji-picker-react";
import { BiHappyBeaming } from "react-icons/bi";
import { ModalCreateOrderMess } from "../container/messenger/ModalCreateOrderMess";
import { FilterTypeAtackment } from "../container/messenger/FilterTypeAtackment";
import { ListPageChat } from "../container/messenger/ListPageChat";
import { getAxios } from "../../const/getAxios";
import { Image } from "primereact/image";
import { RiUserFill } from "react-icons/ri";
import { FaFacebook } from "react-icons/fa";
import { InputTextarea } from "primereact/inputtextarea";
import { ModalMessManual } from "../container/messenger/ModalMessManual";
import { IoInformationCircle } from "react-icons/io5";
import { MdReply } from "react-icons/md";
import { truncateMess, truncateLabel } from "../../const/splittext";
import { MdCancel } from "react-icons/md";
import { ModalFastMess } from "../container/fastmess/ModalFastMess";
import { TbMessage } from "react-icons/tb";
import { useTranslation } from "react-i18next";
import { truncateMarket } from "../../const/splittext";
import { FaExclamation } from "react-icons/fa";
import { GrStatusGoodSmall } from "react-icons/gr";
export const MessZalo = () => {
  updateTitle("Chốt Sale - Quản Lý Tin Nhắn");
  const getApiServer = API_URL + API_ROUTES.GET_MESS_SERVER;
  const getDbMess = API_URL + API_ROUTES.GET_DB_MESS;
  const apiSendMess = API_URL + API_ROUTES.SEND_MESS;
  const apiReset = API_URL + API_ROUTES.RESET_UNREAD;
  const getLinkFile = API_URL + API_ROUTES.GET_LINK_FILE;
  const getDataFb = API_URL + API_ROUTES.GET_ACCOUNT_MESS;
  const showOnComment = API_URL + API_ROUTES_NTD.SHOW_ALL_COMMENT_MESS;
  const resultsSend = API_URL + API_ROUTES.RESULTS_SEND_MESS;
  const getInfor = API_URL + API_ROUTES.GET_INFO_UERS;
  const addfriend = API_URL + API_ROUTES_NTD.ADD_FRIEND;
  const reusltAddFr = API_URL + API_ROUTES_NTD.RESULTS_ADD_FREIND;
  const addLabel = API_URL + API_ROUTES.ADD_LABEL_CONVERSION;
  const removeLabel = API_URL + API_ROUTES.REMOVE_LABEL_CONVERSION;
  const { label, rfLable, setRfLabel } = useUser();
  const headers = useHeaders();
  const [newMessage, setNewMessage] = useState("");
  const [sendMessage, setSendMessage] = useState("");
  const chatMessagesRef = useRef(null);
  const { t } = useTranslation();
  const [messManual, setMessManual] = useState(false);
  const [fileFast, setFileFast] = useState(null);
  const [iconNavi, setIconNavi] = useState(false);
  const [onRef, setOnRef] = useState(false);
  const [pageConnet, setPageConnet] = useState();
  const [idPage, setIdPage] = useState();
  const [idAcc, setIdAcc] = useState();
  const [getSender, setGetSender] = useState();
  const [sendStatus, setSendStatus] = useState(false);
  const [pageData, setPageData] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [linkVideo, setLinkVideo] = useState("");
  const [selectedFileName, setSelectedFileName] = useState("");
  const formData = new FormData();
  const [product, setProduct] = useState([]);
  const apiGetProduct = API_URL + API_ROUTES.GET_PAGE_PRODUCT;
  const [openProduct, setOpenProduct] = useState(false);
  const [openPage, setOpenPage] = useState(false);
  const [openLabel, setOpenLabel] = useState(false);
  const [openAddLabel, setOpenAddLabel] = useState(false);
  const [ftLabel, setFtLabel] = useState([]);
  const [itemLabel, setItemLabel] = useState([]);
  const [openModalInbox, setOpenModalInbox] = useState(false);
  const [getPageAtib, setGetPageAtib] = useState([]);
  const [sortServer, setSortServer] = useState([]);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const access = localStorage.getItem("access");
  const [messages, setMessages] = useState([]);
  const [allPageMess, setAllPageMess] = useState([]);
  const elementx = document?.getElementById("heightcreateoder")?.offsetHeight;
  const elementy = document?.getElementById("heighttitleorder")?.offsetHeight;
  const elementW = document?.getElementById("flexnowrap")?.offsetWidth;
  const [selecItem, setSeclecItem] = useState({});
  const [listAccout, setListAccount] = useState([]);
  const [allComments, setAllComments] = useState([]);
  const [rfCmt, setRfCmt] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [numberMess, setNumberMess] = useState([]);
  const [userInfo, setUserInfo] = useState([]);
  const [idTask, setIdTask] = useState([]);
  const [sendResults, setSendResults] = useState([]);
  const [messReply, setMessReply] = useState([]);
  const [openFastMess, setOpenFastMess] = useState(false);
  const [checkLabel, setCheckLabel] = useState([]);
  const [labelIn, setLabelIn] = useState([]);
  const [labelOut, setLabelOut] = useState([]);
  const [hasLabel, setHasLabel] = useState([]);
  const [idTaskFr, setIdTaskFr] = useState([]);
  const [status, setStatus] = useState([]);
  const handleAddLabel = async (item) => {
    setHasLabel(item);
    if (checkLabel.length === 0) {
      setFtLabel(item);
    } else {
      for (let i = 0; i < checkLabel.length; i++) {
        const label = checkLabel[i];
        if (label !== undefined) {
          if (item.conversation?.some((convItem) => convItem === label)) {
            setLabelIn((prev) => [...prev, label]);
          } else {
            setLabelOut((prev) => [...prev, label]);
          }
        }
      }
    }
  };
  const effectLabel = async () => {
    if (labelOut.length > 0) {
      const body = {
        id_category: hasLabel.id,
        id_conversations: [
          ...new Set(labelOut.filter((item) => item !== undefined)),
        ],
      };
      try {
        const res = await axios.post(addLabel, body, headers);
        setRfLabel(!rfLable);
        setCheckLabel([]);
      } catch (err) {}
      setLabelOut([]);
    }
    if (labelIn.length > 0) {
      const body = {
        id_category: hasLabel.id,
        id_conversations: [
          ...new Set(labelIn.filter((item) => item !== undefined)),
        ],
      };
      try {
        await axios.post(removeLabel, body, headers);
        setRfLabel(!rfLable);
        setCheckLabel([]);
      } catch (err) {}
      setLabelIn([]);
    }
    if (labelOut.length > 0 || labelIn.length > 0) {
      toast.success("Thành công");
    }
  };
  useEffect(() => {
    effectLabel();
  }, [hasLabel]);
  const getResultsComment = async (taskid) => {
    const body = { id_task: taskid.id_task };
    try {
      const res = await axios.post(resultsSend, body, headers);
      if (res.data.data !== true) {
        toast.info(res.data.data);
        setIdTask([]);
      } else if (res.data.data === "Rate limit exceeded") {
        toast.error(
          t("Tài khoản bị giới hạn tin nhắn, vui lòng kiểm tra tài khoản")
        );
        setIdTask([]);
        setSendResults([]);
      } else {
        setIdTask([]);
      }
      setSendResults(res.data.status);
    } catch (err) {
      // setReplyComment(err.res?.data);
    }
  };
  useEffect(() => {
    if (idTask.id_task) {
      const interval = setInterval(() => {
        getResultsComment(idTask);
      }, 2000);
      if (sendResults === "SUCCESS" || sendResults === "FAILURE") {
        getMessSend(idAcc);
        setSendResults([]);
        clearInterval(interval);
      }
      return () => clearInterval(interval);
    }
  }, [sendResults, idTask]);
  const fetchDataIf = async () => {
    try {
      const response = await axios.get(getInfor, headers);
      setUserInfo(response.data);
    } catch (err) {}
  };
  useEffect(() => {
    fetchDataIf();
  }, []);
  useEffect(() => {
    getAxios(`${showOnComment}?page=1`, setAllComments);
  }, [rfCmt]);
  const onEmojiClick = (event, emojiObject) => {
    setNewMessage((prevMessage) => prevMessage + event.emoji);
  };
  const validateInbox = () => {
    if (getPageAtib.length === 0) {
      alert("Vui lòng chọn tài khoản trước khi thực hiện chức năng này");
    } else {
      setOpenModalInbox(true);
    }
  };
  const inputRef = useRef(null);
  const fileInputRef = useRef(null);
  const handlePaste = (event) => {
    const items = (event.clipboardData || event.originalEvent.clipboardData)
      .items;
    for (const item of items) {
      if (item.type.startsWith("image/")) {
        const file = item.getAsFile();
        fileInputRef.current.files = createFileList(file);
        setSelectedFile(file);
        setSelectedFileName(file.name);
        break;
      }
    }
  };
  const createFileList = (file) => {
    const dataTransfer = new DataTransfer();
    dataTransfer.items.add(file);
    return dataTransfer.files;
  };
  useEffect(() => {
    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageSrc(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    }
  }, [selectedFile]);
  //Websoket
  useEffect(() => {
    setMessages([]);
    getMessSend(idAcc);
  }, [idAcc]);
  useEffect(() => {
    if (Array.isArray(pageData[idAcc?.id])) {
      setMessages((prevMessages) => [...prevMessages, ...pageData[idAcc?.id]]);
    } else {
      setMessages([]);
    }
  }, [pageData, idAcc]);
  const socketRef = useRef(null);
  function removeDuplicates(arr) {
    const uniqueItems = new Map();
    arr.forEach((item) => {
      uniqueItems.set(item.id, item);
    });
    return Array.from(uniqueItems.values());
  }
  const uniqueData = removeDuplicates(messages);
  useEffect(() => {
    if (userInfo?.id) {
      const client = new W3CWebSocket(
        `wss://${WS_URL}/ws/chat/${userInfo?.id}/?token=${access}`
      );
      socketRef.current = client;
      client.onerror = function () {};
      client.onopen = function () {};
      client.onmessage = function (message) {
        const parsedData = JSON.parse(message.data);
        if (parsedData.message_details) {
          setMessages((prevDetails) => [
            ...prevDetails,
            parsedData.message_details,
          ]);
        }
        if (parsedData?.accounts) {
          setPageConnet(parsedData?.accounts);
        }
        if (parsedData.message) {
          setAllPageMess(parsedData.message);
          getMessSend(idAcc);
        }
      };
      return () => {
        if (client) {
          client.close();
        }
      };
    }
  }, [userInfo]);
  //Chuông
  useEffect(() => {
    const hasNewMsg = listAccout?.some((item) => item.new_message === true);
    if (hasNewMsg) {
      const audio = new Audio(bellSound);
      audio
        .play()
        .then(() => {})
        .catch((error) => {});
    }
  }, [listAccout]);

  const replaceItem = (items, newItem) => {
    if (!Array.isArray(items)) return items;
    const index = items.findIndex((item) => item.id === newItem.id);
    if (index !== -1) {
      const newItems = [...items];
      newItems[index] = newItem;
      return newItems;
    }
    return items;
  };
  useEffect(() => {
    setSortServer((prevState) => replaceItem(prevState, allPageMess));
  }, [allPageMess]);

  const fetchData = async () => {
    try {
      const response = await axios.get(apiGetProduct, headers);
      setProduct(response.data);
    } catch (err) {}
  };
  const getListAccount = async () => {
    try {
      const res = await axios.get(getDataFb, headers);
      setListAccount(res.data);
    } catch (err) {}
  };
  useEffect(() => {
    getListAccount();
  }, [pageConnet]);
  const getIconMess = async () => {
    try {
      const res = await axios.get(getDataFb, headers);
      setPageConnet(res.data);
    } catch (err) {}
  };
  const handleFileChange = (event) => {
    setSelectedFile(event?.target?.files[0]);
    setSelectedFileName(event?.target?.files[0]?.name);
  };
  useEffect(() => {
    if (selectedFile) {
      getLinkVi();
    }
  }, [selectedFile]);
  const getLinkVi = async () => {
    formData.append("file", selectedFile);
    try {
      const response = await axios.post(getLinkFile, formData, headers);
      setLinkVideo(response.data);
    } catch (error) {
    } finally {
    }
  };
  const getIdItemPage = (item) => {
    setGetPageAtib(item);
    setIdPage(item.id);
    setGetSender(item.UID);
  };
  const handleMessageChange = (event) => {
    setNewMessage(event.target.value);
    setSendMessage(event.target.value);
  };
  useEffect(() => {
    const interval = setInterval(() => {}, 5 * 60 * 1000);
    getIconMess();
    fetchData();
    return () => clearInterval(interval);
  }, []);
  const getMessSend = async (item, fv = false) => {
    // const body = { id_conversation: item?.id, fb: fv };
    if (item?.id === undefined) {
      return;
    }
    const url = `${getDbMess}?id_conversation=${item?.id}`;
    try {
      const response = await axios.get(url, headers);
      const newData = response.data.results
        .sort((a, b) => a.id - b.id)
        .sort((a, b) => a.created_time - b.created_time);
      setPageData((prevData) => ({
        ...prevData,
        [item?.id]: newData,
      }));
      setNumberMess(response?.data);
    } catch (err) {}
  };
  const getMessSendNext = async (item) => {
    const url = `${getDbMess}?id_conversation=${item?.id}&page=${
      numberMess?.next?.split("page=")[1]
    }`;
    try {
      const response = await axios.get(url, headers);
      setNumberMess(response?.data);
      const newData = response.data.results.sort((a, b) => a.id - b.id);
      setPageData((prevData) => ({
        ...prevData,
        [item?.id]: newData,
      }));
    } catch (err) {}
  };
  const resetUnread = async (item) => {
    if (item?.unread_count > 0) {
      const body = {
        id_conversation: item?.id,
      };
      try {
        const response = await axios.post(apiReset, body, headers);
      } catch (err) {}
    }
  };
  useEffect(() => {
    if (idAcc?.unread_count > 0) {
      resetUnread();
    }
  }, [idAcc]);
  const handleSendMessage = async () => {
    setShowEmojiPicker(false);
    if (newMessage || selectedFile || fileFast) {
      setSendStatus(true);
      const body = {
        id_conversation: idAcc?.id,
        message: newMessage,
        attachment: selectedFile
          ? [
              {
                type: selectedFile
                  ? selectedFile.type.split("/")[0] !== "video" &&
                    selectedFile.type.split("/")[0] !== "image"
                    ? "file"
                    : selectedFile.type.split("/")[0]
                  : "",
                file: selectedFile
                  ? selectedFile.type.split("/")[0] !== "video"
                    ? linkVideo.file
                    : linkVideo.file
                  : "",
              },
            ]
          : [],
      };
      const type = selectedFile
        ? selectedFile.type.split("/")[0] !== "video" &&
          selectedFile.type.split("/")[0] !== "image"
          ? "file"
          : selectedFile.type.split("/")[0]
        : fileFast
        ? "image"
        : "";

      const file = selectedFile
        ? selectedFile.type.split("/")[0] !== "video"
          ? linkVideo.file
          : linkVideo.file
        : fileFast || "";

      try {
        if (newMessage && (selectedFile || fileFast)) {
          const bodyMessage = {
            id_conversation: idAcc?.id,
            message: newMessage,
            attachment: [],
          };
          try {
            const res = await axios.post(apiSendMess, bodyMessage, headers);
            setIdTask(res.data);
          } catch (err) {}
          const bodyAttachment = {
            id_conversation: idAcc?.id,
            message: "",
            type: fileFast ? "fast-reply" : "",
            attachment: [
              {
                type: type,
                file: file,
              },
            ],
          };
          try {
            const res = await axios.post(apiSendMess, bodyAttachment, headers);
            setIdTask(res.data);
          } catch (err) {}
          setNewMessage("");
          setFileFast(null);
          setSelectedFile(null);
          setImageSrc(null);
          setSelectedFileName("");
        } else if (messReply.id) {
          const body = {
            id_conversation: idAcc?.id,
            message: newMessage || "",
            message_id: messReply.id_message,
            attachment:
              selectedFile || fileFast
                ? [
                    {
                      type: type,
                      file: file,
                    },
                  ]
                : [],
          };
          try {
            const res = await axios.post(apiSendMess, body, headers);
            setIdTask(res.data);
            setMessReply([]);
          } catch (err) {}
          if (newMessage) {
            setNewMessage("");
          }
          if (selectedFile || fileFast) {
            setSelectedFile(null);
            setFileFast(null);
            setImageSrc(null);
            setSelectedFileName("");
          }
        } else {
          const body = {
            id_conversation: idAcc?.id,
            message: newMessage || "",
            attachment:
              selectedFile || fileFast
                ? [
                    {
                      type: type,
                      file: file,
                    },
                  ]
                : [],
          };
          try {
            const res = await axios.post(apiSendMess, body, headers);
            setIdTask(res.data);
          } catch (err) {}
          if (newMessage) {
            setNewMessage("");
          }
          if (selectedFile || fileFast) {
            setSelectedFile(null);
            setFileFast(null);
            setImageSrc(null);
            setSelectedFileName("");
          }
        }
        setSendStatus(false);
      } catch (err) {
        setSendStatus(false);
      }
    }
  };
  const handleAddFriend = async () => {
    const body = {
      id_account: idAcc?.account,
      id_conversation: idAcc?.id,
    };
    try {
      const response = await axios.post(addfriend, body, headers);
      setRfLabel(!rfLable);
      getMessSend(idAcc);
      if (response.status === 200) {
        toast.success(t("Đã gửi lời mời kết bạn"));
      }
      setIdTaskFr(response.data.id_task);
    } catch (err) {
      toast.error(err.response.data.status);
    }
  };
  const getResultsTask = async () => {
    const body = {
      id_task: idTaskFr,
    };
    try {
      const res = await axios.post(reusltAddFr, body, headers);
      if (res.data.status === "SUCCESS") {
        setIdTaskFr([]);
        res.data.data
          ? toast.success("Kết bạn thành công")
          : toast.error("Kết bạn thất bại");
      }
      setStatus(res.data.status);
    } catch (err) {
      setStatus(err.response.data.status);
      toast.error("Kết bạn thất bại");
    }
  };

  useEffect(() => {
    if (idTaskFr.length > 0) {
      const interval = setInterval(() => {
        getResultsTask(idTaskFr);
      }, 3000);
      if (status === "SUCCESS" || status === "FAILURE") {
        setStatus([]);
        setIdTaskFr([]);
        clearInterval(interval);
      }
      return () => clearInterval(interval);
    }
  }, [idTaskFr]);
  const getApiOnServer = async (item) => {
    if (item) {
      const url = `${getApiServer}?id_account=${item}`;
      try {
        const response = await axios.get(url, headers);
        await setSortServer(response.data);
      } catch (err) {}
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSendMessage();
    }
  };
  const element = document.getElementById("footer");
  const element1 = document.getElementById("height-top");
  const element2 = document.getElementById("bannenravt");
  const element3 = document.getElementById("chatinput");
  const element4 = document.getElementById("homneId");
  const heightAdd = element?.offsetHeight + element1?.offsetHeight;
  const heightChat =
    element4?.offsetHeight - (element2?.offsetHeight + element3?.offsetHeight);
  const heightAddNext = heightAdd.toString();
  useEffect(() => {
    getApiOnServer(idPage);
  }, [rfLable, pageConnet]);
  useEffect(() => {
    if (chatMessagesRef.current) {
      chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
    }
  }, [sendStatus, onRef, pageConnet, messages]);

  const filterAccountConnect = listAccout
    ?.filter((item) => item.checkpoint === false)
    ?.filter((item) => item.status_proxy === true)
    .sort((a, b) => {
      const dateA = new Date(a.updated_time);
      const dateB = new Date(b.updated_time);
      return dateB - dateA;
    });
  const handleClearSelectedFile = () => {
    setSelectedFileName("");
    setSelectedFile(null);
    setImageSrc(null);
    setFileFast(null);
  };
  const filteredMessages = [];
  const seenImageIds = new Set();
  for (let i = uniqueData?.length - 1; i >= 0; i--) {
    const message = uniqueData[i];
    const imageIds = message?.image_attachment?.map((img) => img?.id_image);
    if (imageIds?.some((id) => seenImageIds?.has(id))) {
      continue;
    }
    imageIds?.forEach((id) => seenImageIds?.add(id));
    filteredMessages?.push(message);
  }
  filteredMessages.reverse();
  const statusAddFr =
    sortServer?.results?.filter((item) => item.id === idAcc?.id) || [];
  return (
    <div
      className="d-flex flex-column "
      style={{ height: "100vh", overflow: "hidden" }}
    >
      <div
        className="d-flex"
        style={{ flex: "1", height: `calc(100vh - ${heightAddNext}px - 46px)` }}
      >
        <Navigate
          className="h-100"
          iconNavi={iconNavi}
          openProduct={openProduct}
          setOpenProduct={setOpenProduct}
          setOpenPage={setOpenPage}
          openPage={openPage}
        />
        <ToastContainer />
        <div className="d-flex flex-column h-100 w-100">
          <div
            id="height-top"
            className="p-0 m-0 d-flex w-100 thead-fixed"
            style={{ background: "#2c343f" }}
          >
            <nav
              className="allchatmess-responsive d-flex flex-column justify-content-between"
              style={{
                background: "#2c343f",
                width: "200px",
                borderRight: "1px solid #fff",
                borderLeft: "1px solid #fff",
              }}
            >
              <div className="d-flex justify-content-between pl-2 pr-2 ">
                <Button
                  style={{
                    height: "26px",
                    padding: "1px 2px",
                    fontSize: "8px",
                    marginTop: "0.5rem",
                    boxShadow: "1px 1px 2px #fff",
                  }}
                  onClick={() => setOpenLabel(true)}
                >
                  {t("Quản lý nhãn")}
                </Button>{" "}
                <Button
                  style={{
                    height: "26px",
                    padding: "1px 2px",
                    fontSize: "9px",
                    marginTop: "0.5rem",
                    boxShadow: "1px 1px 2px #fff",
                  }}
                  onClick={() => validateInbox()}
                >
                  Auto Inbox
                </Button>{" "}
                <Button
                  style={{
                    width: "50px",
                    height: "26px",
                    padding: "1px 2px",
                    fontSize: "9px",
                    marginTop: "0.5rem",
                    boxShadow: "1px 1px 2px #fff",
                  }}
                  onClick={() => setFtLabel([])}
                >
                  {t("Tất cả")}
                </Button>{" "}
              </div>
              <nav
                className="d-flex flex-column w-100 align-items-start pl-2 mt-2"
                style={{ overflowX: "auto" }}
              >
                <div className="d-flex w-100 flex-wrap " style={{ gap: "2px" }}>
                  {label?.map((item, index) => (
                    <div className="overlaytriger-style">
                      <OverlayTrigger
                        key={item.id}
                        placement="top"
                        overlay={
                          <Tooltip
                            id="tooltip-top"
                            className="custom-tooltip overlaytrighestyle"
                          >
                            {item?.name}
                          </Tooltip>
                        }
                      >
                        <div style={{ marginRight: "10px" }}>
                          <MdLabel
                            onClick={() => handleAddLabel(item)}
                            size={20}
                            style={{
                              color: `${item?.color}`,
                              cursor: "pointer",
                            }}
                          />
                        </div>
                      </OverlayTrigger>
                    </div>
                  ))}
                </div>
              </nav>
            </nav>
            <div
              className={`me-auto justify-content-start d-flex gap-4`}
              style={{
                overflow: "auto",
                width: "100vh",
                flex: "1",
              }}
            >
              <div
                style={{
                  overflow: "auto",
                  width: "100%",
                  height: "100%",
                }}
                className="d-flex gap-2 "
              >
                {filterAccountConnect?.map((item, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      getIdItemPage(item);
                      getApiOnServer(item?.id);
                      setRfLabel(!rfLable);
                      setOpenPage(true);
                      setCheckLabel([]);
                      setIdAcc([]);
                    }}
                    href="#home"
                    className="d-flex flex-column align-items-center justify-content-center "
                    style={{
                      // flex: "1",
                      cursor: "pointer",
                      padding: "10px 10px 0 10px",
                    }}
                  >
                    <nav style={{ position: "relative" }}>
                      <img
                        className=""
                        style={{
                          width: "35px",
                          height: "35px",
                          borderRadius: "50%",
                          background:
                            idPage === item.id ? "#0084ff" : "#cdc4c5",
                          padding: idPage === item.id ? "3px" : "",
                          boxShadow: "0 0 20px rgba(0, 0, 0, 0.1)",
                        }}
                        src={item.avatar || faimg}
                        alt="facebook"
                      />
                      <span
                        className="d-flex align-items-center justify-content-center position-absolute top-0 end-0 text-white rounded-circle"
                        style={{}}
                      >
                        {item.total_unread_count === null ||
                        item.total_unread_count === 0 ? (
                          ""
                        ) : item.total_unread_count > 9 ? (
                          <GrStatusGoodSmall color="f44336" />
                        ) : (
                          <GrStatusGoodSmall color="f44336" />
                        )}
                      </span>
                    </nav>
                    <p
                      className="text-light m-0 text-center"
                      style={{
                        width: "80px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {item.name}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="d-flex h-100 ">
            <ListPageChat
              checkLabel={checkLabel}
              setCheckLabel={setCheckLabel}
              t={t}
              setOpenPage={setOpenPage}
              openPage={openPage}
              getListAccount={getListAccount}
              getPageAtib={getPageAtib}
              setMessReply={setMessReply}
              toast={toast}
              getApiOnServer={getApiOnServer}
              setMessManual={setMessManual}
              setSortServer={setSortServer}
              onRef={onRef}
              setOnRef={setOnRef}
              faimg={faimg}
              setIdAcc={setIdAcc}
              idPage={idPage}
              resetUnread={resetUnread}
              messages={messages}
              idAcc={idAcc}
              setOpenAddLabel={setOpenAddLabel}
              setItemLabel={setItemLabel}
              ftLabel={ftLabel}
              heightAddNext={heightAddNext}
              sortServer={sortServer}
              getMessSend={getMessSend}
              listAccout={listAccout}
            />
            <div
              className="d-flex flex-column w-100"
              id="homneId"
              style={{ flex: "1" }}
            >
              {idAcc?.account !== undefined &&
              idPage !== undefined &&
              idAcc?.account === idPage ? (
                <div
                  id="bannenravt"
                  className="d-flex justify-content-between p-2"
                  style={{ background: "#ffffff", borderBottom: "1px solid" }}
                >
                  <div className="d-flex align-items-center  gap-2">
                    <img
                      className=""
                      style={{
                        borderRadius: "50%",
                        height: "30px",
                        width: "30px",
                      }}
                      src={
                        idAcc?.thread_type === "MARKETPLACE"
                          ? idAcc?.avatar || faimg
                          : idAcc?.client_avatar || faimg
                      }
                    />
                    <p
                      className="m-1"
                      style={{
                        fontSize: "16",
                        fontWeight: "700",
                      }}
                    >
                      {idAcc?.thread_type === "MARKETPLACE"
                        ? truncateMarket(idAcc.name, 40)
                        : idAcc?.client_fullname}
                    </p>
                    <a
                      href={idAcc?.marketplace_thread_data?.for_sale_item?.url}
                      target="blank"
                      className="m-1 mess-avatar-reponsive"
                      style={{
                        fontSize: "16",
                        fontWeight: "700",
                        cursor: "pointer",
                      }}
                    >
                      {idAcc?.thread_type === "MARKETPLACE" &&
                      idAcc.marketplace_thread_data?.for_sale_item
                        ?.formatted_price
                        ? truncateMarket(
                            `${idAcc.marketplace_thread_data?.for_sale_item?.formatted_price?.text} - ${idAcc?.marketplace_thread_data?.for_sale_item?.group_commerce_item_title}`,
                            40
                          )
                        : ""}
                    </a>
                    <p
                      className="m-1"
                      style={{
                        fontSize: "16",
                        fontWeight: "700",
                      }}
                    >
                      {idAcc?.client_gender === "MALE" ? (
                        <nav className="d-flex justify-content-center">
                          <RiUserFill size={15} />
                          <p>{t(" : Nam")}</p>
                        </nav>
                      ) : idAcc?.client_gender === "FEMALE" ? (
                        <nav className="d-flex justify-content-center">
                          <RiUserFill size={15} />
                          <p>{t(" : Nữ ")}</p>
                        </nav>
                      ) : (
                        ""
                      )}
                    </p>
                    <p
                      className="m-1 mess-avatar-reponsive"
                      style={{
                        fontSize: "16",
                        fontWeight: "700",
                      }}
                    >
                      {" "}
                      <nav className="d-flex justify-content-center">
                        <FaFacebook size={15} />
                        <a
                          href={`https://www.facebook.com//${idAcc?.client_profile_id}`}
                          target="_blank"
                        >
                          : {idAcc?.client_profile_id}
                        </a>
                      </nav>
                    </p>
                    {statusAddFr[0]?.is_viewer_friend === false &&
                    statusAddFr[0]?.can_send_request_friend === true &&
                    statusAddFr[0]?.typename === "User" &&
                    statusAddFr[0]?.send_request_friend === false ? (
                      <>
                        <button
                          className="ml-auto button-blue radius"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "30px",
                            alignSelf: "center",
                            padding: "0 12px",
                            // backgroundColor: statusAddFr[0]?.send_request_friend
                            //   ? "gray"
                            //   : "",
                          }}
                          onClick={handleAddFriend}
                        >
                          {t("Kết bạn")}
                        </button>
                        <OverlayTrigger
                          key="tooltip"
                          placement="bottom"
                          overlay={
                            <Tooltip
                              id="tooltip-right"
                              className="custom-tooltip"
                            >
                              <div className="text-start">
                                {t("Tính năng kết bạn thủ công:")}
                                <br />
                                {t(
                                  "- Không nên dùng tính năng kết bạn thủ công liên tục trên một tài khoản, sẽ dễ bị facebook hạn chế tính năng do phát hiện hành vi bất thường"
                                )}
                                <br />
                                {t(
                                  " Gợi ý: Mỗi ngày chỉ nên kết bạn từ 1 đến 5 người bạn và chỉ nên kết bạn với những người đã phản hồi tin nhắn của bạn"
                                )}
                              </div>
                            </Tooltip>
                          }
                        >
                          <span
                            style={{
                              cursor: "pointer",
                              fontWeight: "bold",
                            }}
                          >
                            <IoInformationCircle size={15} />
                          </span>
                        </OverlayTrigger>
                      </>
                    ) : statusAddFr[0]?.typename === "user" &&
                      statusAddFr[0]?.send_request_friend === true ? (
                      <button
                        className="ml-auto radius"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "30px",
                          alignSelf: "center",
                          padding: "0 12px",
                          backgroundColor: "#gray",
                        }}
                        disabled={true}
                      >
                        {t("Đã gửi lời mời kết bạn")}
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ) : (
                <div></div>
              )}
              {idAcc?.account !== undefined &&
              idPage !== undefined &&
              idAcc?.account === idPage ? (
                <div className="h-100 w-100" style={{ position: "relative" }}>
                  <p
                    onClick={() => getMessSendNext(idAcc)}
                    style={{
                      cursor: "pointer",
                      textAlign: "center",
                      display:
                        numberMess?.next?.split("page=")[1] === undefined
                          ? "none"
                          : "",
                    }}
                  >
                    {t("Xem thêm...")}
                  </p>
                  <div
                    ref={chatMessagesRef}
                    style={{
                      position: "absolute",
                      bottom: `${element3}px`,
                      height: `${heightChat}px`,
                      overflowY: "auto",
                      overflowX: "hidden",
                    }}
                    className="w-100 chat-messages p-2 pb-4"
                  >
                    {idAcc.typename === "UnavailableMessagingActor" ? (
                      <p
                        style={{
                          color: "red",
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        {t(
                          "Người dùng này hiện không có mặt , bạn không thể nhắn tin với người này"
                        )}
                      </p>
                    ) : (
                      <div className="w-100">
                        {idAcc?.id ===
                        filteredMessages?.map(
                          (item, index) => item?.message_model
                        )[0] ? (
                          filteredMessages
                            .sort(
                              (a, b) =>
                                new Date(a.created_time) -
                                new Date(b.created_time)
                            )
                            ?.map((message, index) => (
                              <>
                                {idAcc?.id === message?.message_model ? (
                                  <div
                                    key={index}
                                    className={`message-container w-100 ${
                                      getSender === message?.from_id
                                        ? "senderOne"
                                        : "senderTwo"
                                    } ${
                                      message?.message
                                        ? getSender === message?.from_id
                                          ? "sender1"
                                          : " sender2"
                                        : ""
                                    }`}
                                  >
                                    <div
                                      className="message d-flex align-items-center gap-2 "
                                      style={{
                                        fontSize: "14px",
                                        width: "50%",
                                        lineHeight: "20px",
                                      }}
                                    >
                                      {message?.message ? (
                                        <div className="d-flex align-items-center gap-2 w-100">
                                          <img
                                            style={{
                                              display:
                                                getSender === message?.from_id
                                                  ? "none"
                                                  : "",
                                              borderRadius: "50%",
                                              width: "20px",
                                            }}
                                            src={
                                              idAcc?.account === idPage
                                                ? idAcc?.client_avatar || faimg
                                                : ""
                                            }
                                          />
                                          <div className="d-flex flex-column align-items-start mb-2 w-100">
                                            <OverlayTrigger
                                              placement={`${
                                                getSender === message?.from_id
                                                  ? "bottom"
                                                  : "bottom"
                                              }`}
                                              style={{
                                                // position: "absolute",
                                                left: 0,
                                                bottom: "-20px",
                                                // paddingLeft: "20px",
                                              }}
                                              overlay={
                                                <p
                                                  style={{
                                                    // marginBottom: "10px",
                                                    fontSize: "10px",
                                                    fontWeight: "300",
                                                  }}
                                                >
                                                  {new Date(
                                                    message.created_time
                                                  ).toLocaleString()}
                                                </p>
                                              }
                                            >
                                              <div className="w-100">
                                                {message?.message.startsWith(
                                                  "https://"
                                                ) ? (
                                                  <a
                                                    href={message?.message}
                                                    target="blank"
                                                    style={{
                                                      background:
                                                        getSender ===
                                                        message?.from_id
                                                          ? "#0866ff"
                                                          : "#f0f0f0",
                                                      borderRadius: "12px",
                                                      fontWeight: "400",
                                                      display: "inline-block",
                                                      padding: "8px",
                                                      textDecoration:
                                                        "underline",
                                                      color:
                                                        getSender ===
                                                        message?.from_id
                                                          ? "#fff"
                                                          : "#000",
                                                    }}
                                                  >
                                                    {message?.message}
                                                  </a>
                                                ) : (
                                                  <div
                                                    className="d-flex align-items-center gap-2 hiden-reply w-100"
                                                    style={{
                                                      position: "relative",
                                                    }}
                                                  >
                                                    {getSender ===
                                                    message?.from_id ? (
                                                      <MdReply
                                                        onClick={() => {
                                                          setMessReply(message);
                                                          if (
                                                            inputRef.current
                                                          ) {
                                                            inputRef.current.focus();
                                                          }
                                                        }}
                                                        className="reply-icon"
                                                        style={{
                                                          color: "#000",
                                                          position: "absolute",
                                                          left: "-30px",
                                                          cursor: "pointer",
                                                        }}
                                                        size={30}
                                                      />
                                                    ) : (
                                                      <></>
                                                    )}
                                                    <nav
                                                      className={`d-flex flex-column ${
                                                        getSender ===
                                                        message?.from_id
                                                          ? "align-items-end"
                                                          : "align-items-start"
                                                      }`}
                                                    >
                                                      {message?.replied_to_message !==
                                                      null ? (
                                                        <p
                                                          style={{
                                                            background:
                                                              "#c5c5c5",
                                                            borderRadius: "6px",
                                                            fontSize: "10px",
                                                            textAlign:
                                                              "-webkit-fill-available",
                                                            width: "100%",
                                                            padding: "4px",
                                                          }}
                                                          className="text-dark"
                                                        >
                                                          {`
                                                          ${
                                                            filteredMessages?.filter(
                                                              (item) =>
                                                                item.id_message ===
                                                                message
                                                                  ?.replied_to_message
                                                                  ?.message_id
                                                            )[0]?.from_id ===
                                                              idAcc.client_profile_id &&
                                                            filteredMessages?.filter(
                                                              (item) =>
                                                                item.id_message ===
                                                                message
                                                                  ?.replied_to_message
                                                                  ?.message_id
                                                            )[0]?.from_id ===
                                                              message.from_id
                                                              ? `${idAcc.client_fullname} đã trả lời chính họ:  `
                                                              : ""
                                                          }
                                                          ${
                                                            filteredMessages?.filter(
                                                              (item) =>
                                                                item.id_message ===
                                                                message
                                                                  ?.replied_to_message
                                                                  ?.message_id
                                                            )[0]?.from_id !==
                                                              idAcc.client_profile_id &&
                                                            filteredMessages?.filter(
                                                              (item) =>
                                                                item.id_message ===
                                                                message
                                                                  ?.replied_to_message
                                                                  ?.message_id
                                                            )[0]?.from_id ===
                                                              message.from_id
                                                              ? `Bạn đã trả lời chính mình:  `
                                                              : ""
                                                          }
                                                           ${
                                                             filteredMessages?.filter(
                                                               (item) =>
                                                                 item.id_message ===
                                                                 message
                                                                   ?.replied_to_message
                                                                   ?.message_id
                                                             )[0]?.from_id ===
                                                               idAcc.client_profile_id &&
                                                             filteredMessages?.filter(
                                                               (item) =>
                                                                 item.id_message ===
                                                                 message
                                                                   ?.replied_to_message
                                                                   ?.message_id
                                                             )[0]?.from_id !==
                                                               message.from_id
                                                               ? `Bạn đã trả lời ${idAcc.client_fullname}:`
                                                               : `  `
                                                           }
                                                            ${
                                                              filteredMessages?.filter(
                                                                (item) =>
                                                                  item.id_message ===
                                                                  message
                                                                    ?.replied_to_message
                                                                    ?.message_id
                                                              )[0]?.from_id !==
                                                                idAcc.client_profile_id &&
                                                              filteredMessages?.filter(
                                                                (item) =>
                                                                  item.id_message ===
                                                                  message
                                                                    ?.replied_to_message
                                                                    ?.message_id
                                                              )[0]?.from_id !==
                                                                message.from_id
                                                                ? `${idAcc.client_fullname} đã trả lời bạn:   `
                                                                : `   `
                                                            }
                                                            ${
                                                              filteredMessages?.filter(
                                                                (item) =>
                                                                  item.id_message ===
                                                                  message
                                                                    ?.replied_to_message
                                                                    ?.message_id
                                                              )[0]?.message
                                                            }
                                                            `}
                                                          <img
                                                            style={{
                                                              width: "20px",
                                                              height: "20px",
                                                              display:
                                                                filteredMessages?.filter(
                                                                  (item) =>
                                                                    item.id_message ===
                                                                    message
                                                                      ?.replied_to_message
                                                                      ?.message_id
                                                                )[0]
                                                                  ?.image_attachment[0]
                                                                  ? ""
                                                                  : "none",
                                                            }}
                                                            src={
                                                              filteredMessages?.filter(
                                                                (item) =>
                                                                  item.id_message ===
                                                                  message
                                                                    ?.replied_to_message
                                                                    ?.message_id
                                                              )[0]
                                                                ?.image_attachment[0]
                                                                ?.preview
                                                            }
                                                          />
                                                        </p>
                                                      ) : (
                                                        <></>
                                                      )}
                                                      <p
                                                        style={{
                                                          background:
                                                            getSender ===
                                                            message?.from_id
                                                              ? "#0866ff"
                                                              : "#f0f0f0",
                                                          borderRadius: "12px",
                                                          fontWeight: "500",
                                                          padding: "8px",
                                                          margin: "0",
                                                        }}
                                                        className={`mb-0`}
                                                        dangerouslySetInnerHTML={{
                                                          __html:
                                                            message?.message.replace(
                                                              /\n/g,
                                                              "<br>"
                                                            ),
                                                        }}
                                                      />
                                                    </nav>
                                                    {getSender ===
                                                    message?.from_id ? (
                                                      <></>
                                                    ) : (
                                                      <MdReply
                                                        size={30}
                                                        onClick={() => {
                                                          setMessReply(message);
                                                          if (
                                                            inputRef.current
                                                          ) {
                                                            inputRef.current.focus();
                                                          }
                                                        }}
                                                        className="reply-icon"
                                                        style={{
                                                          color: "#000",
                                                          cursor: "pointer",
                                                        }}
                                                      />
                                                    )}
                                                  </div>
                                                )}
                                              </div>
                                            </OverlayTrigger>
                                          </div>
                                        </div>
                                      ) : (
                                        <FilterTypeAtackment
                                          inputRef={inputRef}
                                          filteredMessages={filteredMessages}
                                          MdReply={MdReply}
                                          setMessReply={setMessReply}
                                          message={message}
                                          idAcc={idAcc}
                                          getSender={getSender}
                                          idPage={idPage}
                                          faimg={faimg}
                                        />
                                      )}
                                    </div>
                                  </div>
                                ) : (
                                  <div></div>
                                )}
                              </>
                            ))
                        ) : (
                          <div></div>
                        )}
                      </div>
                    )}

                    <div
                      style={{
                        display: `${sendStatus === true ? "" : "none"}`,
                      }}
                      className={"message-container sender1 align-items-end "}
                    >
                      <div className="message d-flex  align-items-center gap-2">
                        <p
                          className="text-light rounded-circle"
                          style={{ marginBottom: "0", fontSize: "16px" }}
                        >
                          {sendMessage.length === 0
                            ? selectedFile?.name
                            : sendMessage}
                        </p>{" "}
                        <ClipLoader size={12} />
                      </div>
                    </div>
                  </div>
                  {idAcc.typename === "UnavailableMessagingActor" ? (
                    <></>
                  ) : (
                    <div
                      className="w-100"
                      style={{
                        display: `${!messages === true ? "none" : ""}`,
                      }}
                    >
                      <div
                        id="chatinput"
                        className="chat-input w-100 d-flex align-items-center bg-light"
                        style={{
                          position: "absolute",
                          bottom: "0",
                        }}
                      >
                        {(imageSrc && imageSrc?.includes("media")) ||
                          (imageSrc?.includes("image") && (
                            <Image
                              src={imageSrc}
                              zoomSrc={imageSrc}
                              alt="Image"
                              width="120"
                              height="100"
                              preview
                              style={{
                                position: "absolute",
                                bottom: "53px",
                                right: "46%",
                              }}
                            />
                          ))}
                        {messReply?.id && (
                          <div
                            className="d-flex gap-2 align-items-center"
                            style={{
                              position: "absolute",
                              bottom: "53px",
                              left: "10px",
                            }}
                          >
                            <MdReply size={20} />
                            <p style={{ fontWeight: "700" }}>{`Trả lời ${
                              messReply?.from_id === idAcc?.client_profile_id
                                ? idAcc.client_fullname
                                : "chính mình"
                            }: ${truncateMess(messReply?.message)}`}</p>
                            {messReply?.image_attachment[0]?.id && (
                              <img
                                src={messReply?.image_attachment[0].thumbnail}
                              />
                            )}
                            <MdCancel
                              style={{ cursor: "pointer" }}
                              onClick={() => setMessReply([])}
                              size={20}
                            />
                          </div>
                        )}
                        <InputTextarea
                          ref={inputRef}
                          className="w-100 p-2 radius"
                          value={newMessage || ""}
                          onChange={handleMessageChange}
                          onKeyPress={handleKeyPress}
                          onPaste={handlePaste}
                          placeholder="Nhập tin nhắn...."
                          style={{
                            fontSize: "14px",
                            border: "0.5px solid !important",
                            resize: "none",
                          }}
                        />
                        <input
                          ref={fileInputRef}
                          type="file"
                          onChange={handleFileChange}
                          style={{ display: "none" }}
                        />
                        {(selectedFileName &&
                          imageSrc &&
                          imageSrc?.includes("media")) ||
                          (imageSrc?.includes("image") && (
                            <div>
                              {truncateLabel(selectedFileName)}
                              <button
                                style={{
                                  borderBottom: "0",
                                  paddingBottom: "0",
                                }}
                                onClick={handleClearSelectedFile}
                              >
                                {t("Xóa")}
                              </button>
                            </div>
                          ))}
                        <div style={{ position: "relative" }}>
                          <Button
                            variant="outline-secondary"
                            onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                          >
                            <BiHappyBeaming style={{ color: "yellow" }} />
                          </Button>
                          {showEmojiPicker && (
                            <div
                              style={{
                                position: "absolute",
                                top: "-450px",
                                right: 0,
                                zIndex: 9999,
                              }}
                            >
                              <EmojiPicker onEmojiClick={onEmojiClick} />
                            </div>
                          )}
                        </div>
                        <div>
                          <OverlayTrigger
                            key="tooltip"
                            placement="top"
                            className="overlay-css"
                            overlay={
                              <Tooltip id={`tooltip-top toppicoke`}>
                                Chọn file định dạng .txt, .doc, .pdf, .jpg,
                                .png, .gif
                              </Tooltip>
                            }
                          >
                            <label
                              htmlFor="fileInput"
                              className="border-0 text-dark d-flex align-items-center"
                              style={{
                                // padding: "6px 16px",
                                background: "transparent",
                                cursor: "pointer",
                              }}
                            >
                              <input
                                id="fileInput"
                                type="file"
                                style={{ display: "none" }}
                                accept=".txt,.doc,.docx,.pdf,.xls,.xlsx,.ppt,.pptx,.csv,.mp3,.jpg,.jpeg,.png,.gif,.mp4,video/mp4,video/quicktime,video/x-msvideo"
                                onChange={handleFileChange}
                              />

                              <RiAttachment2
                                style={{ color: "#0866ff" }}
                                size={25}
                              />
                              {selectedFile ? (
                                <span style={{ marginLeft: "5px" }}></span>
                              ) : (
                                <span style={{ marginLeft: "5px" }}></span>
                              )}
                            </label>
                          </OverlayTrigger>
                        </div>
                        <Button variant="secondary">
                          <TbMessage onClick={() => setOpenFastMess(true)} />
                        </Button>
                        <button
                          onkeypress={(e) => {
                            handleSendMessage();
                          }}
                          className="border-0 text-light"
                          style={{ background: "transparent" }}
                          onClick={handleSendMessage}
                        >
                          <IoSendSharp style={{ color: "#0866ff" }} size={25} />
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </div>
        </div>
        <ModalCreateOrderMess
          t={t}
          listAccout={listAccout}
          allComments={allComments}
          setAllComments={setAllComments}
          rfCmt={rfCmt}
          setRfCmt={setRfCmt}
          elementW={elementW}
          toast={toast}
          getPageAtib={getPageAtib}
          product={product}
          idAcc={idAcc}
          element1={element1}
          openProduct={openProduct}
          elementx={elementx}
          elementy={elementy}
          selecItem={selecItem}
          setSeclecItem={setSeclecItem}
        />
      </div>
      <CreateLabels
        openLabel={openLabel}
        setOpenLabel={setOpenLabel}
        toast={toast}
        setFtLabel={setFtLabel}
      />
      <AddLabelMess
        itemLabel={itemLabel}
        setOpenAddLabel={setOpenAddLabel}
        openAddLabel={openAddLabel}
      />
      <ModalAutoInbox
        toast={toast}
        getPageAtib={getPageAtib}
        setOpenModalInbox={setOpenModalInbox}
        openModalInbox={openModalInbox}
      />
      <ModalMessManual
        messManual={messManual}
        setMessManual={setMessManual}
        toast={toast}
        idPage={idPage}
      />
      <ModalFastMess
        setSelectedFileName={setSelectedFileName}
        setImageSrc={setImageSrc}
        setFileFast={setFileFast}
        setNewMessage={setNewMessage}
        toast={toast}
        idPage={idPage}
        openFastMess={openFastMess}
        setOpenFastMess={setOpenFastMess}
      />
      <div>
        <Footer style={{ flex: "1" }} />
      </div>
    </div>
  );
};
