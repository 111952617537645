import React, { useEffect, useState } from "react";
import classNames from "classnames/bind";
import styles from "./Nav.module.scss";
import { Accordion, AccordionTab } from "primereact/accordion";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../../../../../contexts/UserContext";
import { API_ROUTES, API_URL } from "../../../../../../api";
import { useHeaders } from "../../../../../../const/headers";
import { MdContentCopy } from "react-icons/md";
import { InputText } from "primereact/inputtext";
import { IoIosLink } from "react-icons/io";
import axios from "axios";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
const cx = classNames.bind(styles);

function Nav() {
  const { userInfo } = useUser();
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [visible3, setVisible3] = useState(false);
  const [visible4, setVisible4] = useState(false);
  const [visible5, setVisible5] = useState(false);
  const [visible6, setVisible6] = useState(false);
  const [visible7, setVisible7] = useState(false);
  const [visible8, setVisible8] = useState(false);
  const [visible9, setVisible9] = useState(false);
  const [file, setFile] = useState(null);
  const [image, setImage] = useState("");
  const [image2, setImage2] = useState("");
  const [imageFirst, setImageFirst] = useState("");
  const [content, setContent] = useState("");
  const [content2, setContent2] = useState("");
  const [content3, setContent3] = useState("");
  const [content4, setContent4] = useState("");
  const [content5, setContent5] = useState("");
  const [content6, setContent6] = useState("");
  const [content7, setContent7] = useState("");
  const [content8, setContent8] = useState("");
  const [content9, setContent9] = useState("");
  const [description, setDescription] = useState("");
  const [description2, setDescription2] = useState("");
  const [description3, setDescription3] = useState("");
  const [description4, setDescription4] = useState("");
  const [description5, setDescription5] = useState("");
  const [description6, setDescription6] = useState("");
  const [description7, setDescription7] = useState("");
  const [description8, setDescription8] = useState("");
  const [description9, setDescription9] = useState("");
  const headers = useHeaders();
  const [rf, setRf] = useState(false);
  const uploadEndpoint = API_URL + API_ROUTES.UPLOAD_FILE_SERVER;
  const createUpdateEndpoint = API_URL + API_ROUTES.EDIT_LANDING;
  const apiGetLanding = API_URL + API_ROUTES.GET_ALL_LANDING;
  const [landing, setLanding] = useState([]);
  const fetchData = async () => {
    try {
      const res = await axios.get(apiGetLanding);
      setLanding(res.data);
    } catch (err) {
    }
  };
  useEffect(() => {
    fetchData();
  }, [rf]);
  const contentFistNav = landing?.filter((item) => item?.id === 7);
  const contentSecondNav = landing?.filter((item) => item?.id === 8);
  const contentThirdNav = landing?.filter((item) => item?.id === 9);
  const contentFourNav = landing?.filter((item) => item?.id === 10);
  const contentFifthNav = landing?.filter((item) => item?.id === 11);
  const contentSixNav = landing?.filter((item) => item?.id === 12);
  const contentSevenNav = landing?.filter((item) => item?.id === 13);
  const contentEightNav = landing?.filter((item) => item?.id === 14);
  const contentNineNav = landing?.filter((item) => item?.id === 15);
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFile(file);
    const reader = new FileReader();
    reader.onload = () => {
      const imageDataURL = reader.result;
      const imgElement = document.getElementById("previewImage");
      if (imgElement) {
        imgElement.src = imageDataURL;
      }
    };
    reader.readAsDataURL(file);
  };

  const uploadToServer = async () => {
    if (file !== null) {
      const formData = new FormData();
      formData.append("image", file);
      try {
        const response = await axios.post(uploadEndpoint, formData, headers);
        setImage(response.data.image);
        setImage2(response.data.image);
      } catch (error) {}
    }
  };
  useEffect(() => {
    setImage(contentFistNav[0]?.image);
    setImage2(contentFourNav[0]?.image);
    setDescription(contentFistNav[0]?.content);
    setContent(contentFistNav[0]?.title);
    setDescription2(contentSecondNav[0]?.content);
    setContent2(contentSecondNav[0]?.title);
    setDescription3(contentThirdNav[0]?.content);
    setContent3(contentThirdNav[0]?.title);
    setDescription4(contentFourNav[0]?.content);
    setContent4(contentFourNav[0]?.title);
    setDescription5(contentFifthNav[0]?.content);
    setContent5(contentFifthNav[0]?.title);
    setDescription6(contentSixNav[0]?.content);
    setContent6(contentSixNav[0]?.title);
    setDescription7(contentSevenNav[0]?.content);
    setContent7(contentSevenNav[0]?.title);
    setDescription8(contentEightNav[0]?.content);
    setContent8(contentEightNav[0]?.title);
    setDescription9(contentNineNav[0]?.content);
    setContent9(contentNineNav[0]?.title);
  }, [
    visible,
    visible2,
    visible3,
    visible4,
    visible5,
    visible6,
    visible7,
    visible8,
    visible9,
  ]);
  useEffect(() => {
    uploadToServer();
  }, [file]);
  const onChangeContent = (e) => {
    setContent(e.target.value);
  };
  const onChangeContent2 = (e) => {
    setContent2(e.target.value);
  };
  const onChangeContent3 = (e) => {
    setContent3(e.target.value);
  };
  const onChangeContent4 = (e) => {
    setContent4(e.target.value);
  };
  const onChangeContent5 = (e) => {
    setContent5(e.target.value);
  };
  const onChangeContent6 = (e) => {
    setContent6(e.target.value);
  };
  const onChangeContent7 = (e) => {
    setContent7(e.target.value);
  };
  const onChangeContent8 = (e) => {
    setContent8(e.target.value);
  };
  const onChangeContent9 = (e) => {
    setContent9(e.target.value);
  };
  const onChangeLink = (e) => {
    setDescription(e.target.value);
  };
  const onChangeLink2 = (e) => {
    setDescription2(e.target.value);
  };
  const onChangeLink3 = (e) => {
    setDescription3(e.target.value);
  };
  const onChangeLink4 = (e) => {
    setDescription4(e.target.value);
  };
  const onChangeLink5 = (e) => {
    setDescription5(e.target.value);
  };
  const onChangeLink6 = (e) => {
    setDescription6(e.target.value);
  };
  const onChangeLink7 = (e) => {
    setDescription7(e.target.value);
  };
  const onChangeLink8 = (e) => {
    setDescription8(e.target.value);
  };
  const onChangeLink9 = (e) => {
    setDescription9(e.target.value);
  };
  const createResource = async () => {
    const data = {
      id: contentFistNav[0]?.id,
      title: content,
      content: description,
      image: image === "" ? contentFistNav[0]?.image : image,
    };
    try {
      const response = await axios.post(createUpdateEndpoint, data, headers);
      setRf(!rf);
      setDescription("");
      setContent("");
      setVisible(false);
    } catch (error) {}
  };
  const createResource2 = async () => {
    const data = {
      id: contentSecondNav[0]?.id,
      title: content2,
      content: description2,
      image: "",
    };
    try {
      const response = await axios.post(createUpdateEndpoint, data, headers);
      setRf(!rf);
      setDescription2("");
      setContent2("");
      setVisible2(false);
    } catch (error) {}
  };
  const createResource3 = async () => {
    const data = {
      id: contentThirdNav[0]?.id,
      title: content3,
      content: description3,
      image: "",
    };
    try {
      const response = await axios.post(createUpdateEndpoint, data, headers);
      setRf(!rf);
      setDescription3("");
      setContent3("");
      setVisible3(false);
    } catch (error) {}
  };
  const createResource4 = async () => {
    const data = {
      id: contentFourNav[0]?.id,
      title: content4,
      content: description4,
      image: image2 === "" ? contentFourNav[0]?.image : image,
    };
    try {
      const response = await axios.post(createUpdateEndpoint, data, headers);
      setRf(!rf);
      setDescription4("");
      setContent4("");
      setVisible4(false);
    } catch (error) {}
  };
  const navigate = useNavigate();
  const handleNavi = (link) => {
    navigate(link);
  };
  const createResource5 = async () => {
    const data = {
      id: contentFifthNav[0]?.id,
      title: content5,
      content: description5,
      image: "",
    };
    try {
      const response = await axios.post(createUpdateEndpoint, data, headers);
      setRf(!rf);
      setDescription5("");
      setContent5("");
      setVisible5(false);
    } catch (error) {}
  };
  const createResource6 = async () => {
    const data = {
      id: contentSixNav[0]?.id,
      title: content6,
      content: description6,
      image: "",
    };
    try {
      const response = await axios.post(createUpdateEndpoint, data, headers);
      setRf(!rf);
      setDescription6("");
      setContent6("");
      setVisible6(false);
    } catch (error) {}
  };
  const createResource7 = async () => {
    const data = {
      id: contentSevenNav[0]?.id,
      title: content7,
      content: description7,
      image: "",
    };
    try {
      const response = await axios.post(createUpdateEndpoint, data, headers);
      setRf(!rf);
      setDescription7("");
      setContent7("");
      setVisible7(false);
    } catch (error) {}
  };
  const createResource8 = async () => {
    const data = {
      id: contentEightNav[0]?.id,
      title: content8,
      content: description8,
      image: "",
    };
    try {
      const response = await axios.post(createUpdateEndpoint, data, headers);
      setRf(!rf);
      setDescription8("");
      setContent8("");
      setVisible8(false);
    } catch (error) {}
  };
  const createResource9 = async () => {
    const data = {
      id: contentNineNav[0]?.id,
      title: content9,
      content: description9,
      image: "",
    };
    try {
      const response = await axios.post(createUpdateEndpoint, data, headers);
      setRf(!rf);
      setDescription9("");
      setContent9("");
      setVisible9(false);
    } catch (error) {}
  };

  return (
    <>
      <div id="feature" className={cx("feature")}>
        <nav className={cx("nav")}>
          <h4 className={cx("title-container")}>
            những tính năng nổi bật của phần mềm CHỐT SALE
          </h4>
          <p className={cx("line")}></p>
        </nav>
        <div className={cx("feature-container")}>
          <div className={cx("feature-items")}>
            <nav className={cx("item")}>
              <h6 className={cx("content-item")}>CHATBOT TIN NHẮN</h6>
              {/* <img
                className={cx("image-item")}
                src="https://livechat.chotnhanh.vn/media/server/12ba684e-a58d-435b-9d2a-3d131027f31a.png"
                alt="Chat bot"
              /> */}
            </nav>
          </div>
          <div className={cx("feature-items")}>
            <nav className={cx("item")}>
              <h6 className={cx("content-item")}>AUTO INBOX</h6>
              {/* <img
                className={cx("image-item")}
                src="https://livechat.chotnhanh.vn/media/server/12ba684e-a58d-435b-9d2a-3d131027f31a.png"
                alt="Chat bot"
              /> */}
            </nav>
          </div>
          <div className={cx("feature-items")}>
            <nav className={cx("item")}>
              <h6 className={cx("content-item")}>TỰ ĐỘNG BÁN HÀNG</h6>
              {/* <img
                className={cx("image-item")}
                src="https://livechat.chotnhanh.vn/media/server/12ba684e-a58d-435b-9d2a-3d131027f31a.png"
                alt="Chat bot"
              /> */}
            </nav>
          </div>
        </div>
      </div>
      <button
        onClick={() => handleNavi("/register")}
        className={cx("main-btn")}
      >
        DÙNG NGAY MIỄN PHÍ
      </button>
      <div className={cx("benefit")}>
        <nav className={cx("nav")}>
          <h4 className={cx("title-container")}>
            LỢI ÍCH KHI SỬ DỤNG PHẦN MỀM CHỐT SALE
          </h4>
          <p className={cx("line")}></p>
        </nav>
        <div className={cx("benefit-container")}>
          <nav className={cx("benefit-1")}>
            <p className={cx("benefit-title")}>{contentFistNav[0]?.title}</p>
            <img
              className={cx("benefit-image")}
              src={`${API_URL}/${contentFistNav[0]?.image}`}
              alt=""
            />
            <div></div>
            <p className={cx("benefit-content")}>
              {contentFistNav[0]?.content}
            </p>
            {userInfo?.is_admin === true ? (
              <button
                className="mt-2"
                style={{
                  width: "100%",
                  border: "none",
                  backgroundColor: "#0866ff",
                  color: "#fff",
                  fontSize: "1rem",
                  padding: "0.5rem 1rem",
                  cursor: "pointer",
                  outline: "none",
                  transition: "all 0.2s ease-in-out",
                }}
                onClick={() => setVisible(true)}
              >
                Sửa
              </button>
            ) : (
              <></>
            )}
            <Dialog
              header="Quản lý"
              visible={visible}
              style={{ width: "50vw" }}
              onHide={() => {
                if (!visible) return;
                setVisible(false);
              }}
            >
              <div className="d-flex flex-column gap-2 mr-2 mb-2">
                <div className="p-inputgroup flex-1">
                  <span className="p-inputgroup-addon">
                    <MdContentCopy />
                  </span>
                  <InputText
                    onChange={(e) => onChangeContent(e)}
                    placeholder="Tiêu đề"
                    value={content}
                  />
                </div>
                <div className="p-inputgroup flex-1">
                  <span className="p-inputgroup-addon">
                    <IoIosLink />
                  </span>
                  <InputText
                    onChange={(e) => onChangeLink(e)}
                    value={description}
                    placeholder="Mô tả"
                  />
                </div>
                <h3>Chọn Ảnh</h3>
                <label htmlFor="fileInput">
                  <button
                    className="d-flex flex-column p-2 align-items-center gap-2 border border-secondary justify-content-between rounded"
                    onClick={() => document.getElementById("fileInput").click()}
                  >
                    <p className="mb-0">Chọn ảnh</p>
                  </button>
                </label>
                <input
                  id="fileInput"
                  type="file"
                  style={{ display: "none" }}
                  onChange={handleImageChange}
                />
                {image === "" && imageFirst === "" ? (
                  <div></div>
                ) : (
                  <img
                    style={{
                      width: "100px",
                      display: "block",
                    }}
                    alt="Selected Image"
                    src={`${API_URL}/${image === "" ? imageFirst : image}`}
                  />
                )}
              </div>
              <div className="d-flex justify-content-end gap-2 mt-2">
                <Button
                  className="button-blue radius"
                  label="Sửa"
                  onClick={() => createResource()}
                />
                <Button
                  className="button-red radius"
                  label="Hủy"
                  onClick={() => setVisible(false)}
                  severity="danger"
                />
              </div>
            </Dialog>
          </nav>
          <div className={cx("benefit-2")}>
            <nav>
              <p className={cx("benefit-title")}>
                {contentSecondNav[0]?.title}
              </p>
              <p className={cx("benefit-content")}>
                {contentSecondNav[0]?.content}
              </p>
              {userInfo?.is_admin === true ? (
                <button
                  className="mt-2"
                  style={{
                    width: "100%",
                    border: "none",
                    backgroundColor: "#0866ff",
                    color: "#fff",
                    fontSize: "1rem",
                    padding: "0.5rem 1rem",
                    cursor: "pointer",
                    outline: "none",
                    transition: "all 0.2s ease-in-out",
                  }}
                  onClick={() => setVisible2(true)}
                >
                  Sửa
                </button>
              ) : (
                <></>
              )}
              <Dialog
                header="Quản lý"
                visible={visible2}
                style={{ width: "50vw" }}
                onHide={() => {
                  if (!visible2) return;
                  setVisible2(false);
                }}
              >
                <div className="d-flex flex-column gap-2 mr-2 mb-2">
                  <div className="p-inputgroup flex-1">
                    <span className="p-inputgroup-addon">
                      <MdContentCopy />
                    </span>
                    <InputText
                      onChange={(e) => onChangeContent2(e)}
                      placeholder="Tiêu đề"
                      value={content2}
                    />
                  </div>
                  <div className="p-inputgroup flex-1">
                    <span className="p-inputgroup-addon">
                      <IoIosLink />
                    </span>
                    <InputText
                      onChange={(e) => onChangeLink2(e)}
                      value={description2}
                      placeholder="Mô tả"
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-end gap-2 mt-2">
                  <Button
                    className="button-blue radius"
                    label="Sửa"
                    onClick={() => createResource2()}
                  />
                  <Button
                    className="button-red radius"
                    label="Hủy"
                    onClick={() => setVisible2(false)}
                    severity="danger"
                  />
                </div>
              </Dialog>
            </nav>
            <p className={cx("line-2")}></p>
            <nav>
              <p className={cx("benefit-title")}>{contentThirdNav[0]?.title}</p>
              <p className={cx("benefit-content")}>
                {contentThirdNav[0]?.content}
              </p>
              {userInfo?.is_admin === true ? (
                <button
                  className="mt-2"
                  style={{
                    width: "100%",
                    border: "none",
                    backgroundColor: "#0866ff",
                    color: "#fff",
                    fontSize: "1rem",
                    padding: "0.5rem 1rem",
                    cursor: "pointer",
                    outline: "none",
                    transition: "all 0.2s ease-in-out",
                  }}
                  onClick={() => setVisible3(true)}
                >
                  Sửa
                </button>
              ) : (
                <></>
              )}
              <Dialog
                header="Quản lý"
                visible={visible3}
                style={{ width: "50vw" }}
                onHide={() => {
                  if (!visible3) return;
                  setVisible3(false);
                }}
              >
                <div className="d-flex flex-column gap-2 mr-2 mb-2">
                  <div className="p-inputgroup flex-1">
                    <span className="p-inputgroup-addon">
                      <MdContentCopy />
                    </span>
                    <InputText
                      onChange={(e) => onChangeContent3(e)}
                      placeholder="Tiêu đề"
                      value={content3}
                    />
                  </div>
                  <div className="p-inputgroup flex-1">
                    <span className="p-inputgroup-addon">
                      <IoIosLink />
                    </span>
                    <InputText
                      onChange={(e) => onChangeLink3(e)}
                      value={description3}
                      placeholder="Mô tả"
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-end gap-2 mt-2">
                  <Button
                    className="button-blue radius"
                    label="Sửa"
                    onClick={() => createResource3()}
                  />
                  <Button
                    className="button-red radius"
                    label="Hủy"
                    onClick={() => setVisible3(false)}
                    severity="danger"
                  />
                </div>
              </Dialog>
            </nav>
          </div>
          <nav className={cx("benefit-3")}>
            <p className={cx("benefit-title")}>{contentFourNav[0]?.title}</p>
            <img
              className={cx("benefit-image")}
              src={`${API_URL}/${contentFourNav[0]?.image}`}
              alt=""
            />
            <p className={cx("benefit-content")}>
              {contentFourNav[0]?.content}
            </p>
            {userInfo?.is_admin === true ? (
              <button
                className="mt-2"
                style={{
                  width: "100%",
                  border: "none",
                  backgroundColor: "#0866ff",
                  color: "#fff",
                  fontSize: "1rem",
                  padding: "0.5rem 1rem",
                  cursor: "pointer",
                  outline: "none",
                  transition: "all 0.2s ease-in-out",
                }}
                onClick={() => setVisible4(true)}
              >
                Sửa
              </button>
            ) : (
              <></>
            )}
            <Dialog
              header="Quản lý"
              visible={visible4}
              style={{ width: "50vw" }}
              onHide={() => {
                if (!visible4) return;
                setVisible4(false);
              }}
            >
              <div className="d-flex flex-column gap-2 mr-2 mb-2">
                <div className="p-inputgroup flex-1">
                  <span className="p-inputgroup-addon">
                    <MdContentCopy />
                  </span>
                  <InputText
                    onChange={(e) => onChangeContent4(e)}
                    placeholder="Tiêu đề"
                    value={content4}
                  />
                </div>
                <div className="p-inputgroup flex-1">
                  <span className="p-inputgroup-addon">
                    <IoIosLink />
                  </span>
                  <InputText
                    onChange={(e) => onChangeLink4(e)}
                    value={description4}
                    placeholder="Mô tả"
                  />
                </div>
                <h3>Chọn Ảnh</h3>
                <label htmlFor="fileInput">
                  <button
                    className="d-flex flex-column p-2 align-items-center gap-2 border border-secondary justify-content-between rounded"
                    onClick={() => document.getElementById("fileInput").click()}
                  >
                    <p className="mb-0">Chọn ảnh</p>
                  </button>
                </label>
                <input
                  id="fileInput"
                  type="file"
                  style={{ display: "none" }}
                  onChange={handleImageChange}
                />
                {image2 === "" && imageFirst === "" ? (
                  <div></div>
                ) : (
                  <img
                    style={{
                      width: "100px",
                      display: "block",
                    }}
                    alt="Selected Image"
                    src={`${API_URL}/${image2 === "" ? imageFirst : image2}`}
                  />
                )}
              </div>
              <div className="d-flex justify-content-end gap-2 mt-2">
                <Button
                  className="button-blue radius"
                  label="Sửa"
                  onClick={() => createResource4()}
                />
                <Button
                  className="button-red radius"
                  label="Hủy"
                  onClick={() => setVisible4(false)}
                  severity="danger"
                />
              </div>
            </Dialog>
          </nav>
        </div>
      </div>
      <div id="questions" className={cx("questions")}>
        <nav className={cx("nav")}>
          <h4 className={cx("title-container")}>
            NHỮNG CÂU HỎI THƯỜNG GẶP KHI SỬ DỤNG PHẦN MỀM
          </h4>
          <p className={cx("line")}></p>
        </nav>
        <Accordion multiple>
          <AccordionTab header={contentFifthNav[0]?.title}>
            {userInfo?.is_admin === true ? (
              <button
                className="mt-2 mb-2"
                style={{
                  width: "15%",
                  border: "none",
                  backgroundColor: "#0866ff",
                  color: "#fff",
                  fontSize: "1rem",
                  padding: "0.5rem 1rem",
                  cursor: "pointer",
                  outline: "none",
                  transition: "all 0.2s ease-in-out",
                }}
                onClick={() => setVisible5(true)}
              >
                Sửa
              </button>
            ) : (
              <></>
            )}
            <Dialog
              header="Quản lý"
              visible={visible5}
              style={{ width: "50vw" }}
              onHide={() => {
                if (!visible5) return;
                setVisible5(false);
              }}
            >
              <div className="d-flex flex-column gap-2 mr-2 mb-2">
                <div className="p-inputgroup flex-1">
                  <span className="p-inputgroup-addon">
                    <MdContentCopy />
                  </span>
                  <InputText
                    onChange={(e) => onChangeContent5(e)}
                    placeholder="Tiêu đề"
                    value={content5}
                  />
                </div>
                <div className="p-inputgroup flex-1">
                  <span className="p-inputgroup-addon">
                    <IoIosLink />
                  </span>
                  <InputText
                    onChange={(e) => onChangeLink5(e)}
                    value={description5}
                    placeholder="Mô tả"
                  />
                </div>
              </div>
              <div className="d-flex justify-content-end gap-2 mt-2">
                <Button
                  className="button-blue radius"
                  label="Sửa"
                  onClick={() => createResource5()}
                />
                <Button
                  className="button-red radius"
                  label="Hủy"
                  onClick={() => setVisible5(false)}
                  severity="danger"
                />
              </div>
            </Dialog>
            <p className={cx("m-0")}>{contentFifthNav[0]?.content}</p>
          </AccordionTab>
          <AccordionTab header={contentSixNav[0]?.title}>
            {userInfo?.is_admin === true ? (
              <button
                className="mt-2 mb-2"
                style={{
                  width: "15%",
                  border: "none",
                  backgroundColor: "#0866ff",
                  color: "#fff",
                  fontSize: "1rem",
                  padding: "0.5rem 1rem",
                  cursor: "pointer",
                  outline: "none",
                  transition: "all 0.2s ease-in-out",
                }}
                onClick={() => setVisible6(true)}
              >
                Sửa
              </button>
            ) : (
              <></>
            )}
            <Dialog
              header="Quản lý"
              visible={visible6}
              style={{ width: "50vw" }}
              onHide={() => {
                if (!visible6) return;
                setVisible6(false);
              }}
            >
              <div className="d-flex flex-column gap-2 mr-2 mb-2">
                <div className="p-inputgroup flex-1">
                  <span className="p-inputgroup-addon">
                    <MdContentCopy />
                  </span>
                  <InputText
                    onChange={(e) => onChangeContent6(e)}
                    placeholder="Tiêu đề"
                    value={content6}
                  />
                </div>
                <div className="p-inputgroup flex-1">
                  <span className="p-inputgroup-addon">
                    <IoIosLink />
                  </span>
                  <InputText
                    onChange={(e) => onChangeLink6(e)}
                    value={description6}
                    placeholder="Mô tả"
                  />
                </div>
              </div>
              <div className="d-flex justify-content-end gap-2 mt-2">
                <Button
                  className="button-blue radius"
                  label="Sửa"
                  onClick={() => createResource6()}
                />
                <Button
                  className="button-red radius"
                  label="Hủy"
                  onClick={() => setVisible6(false)}
                  severity="danger"
                />
              </div>
            </Dialog>
            <p className={cx("m-0")}>{contentSixNav[0]?.content}</p>
          </AccordionTab>
          <AccordionTab header={contentSevenNav[0]?.title}>
            {userInfo?.is_admin === true ? (
              <button
                className="mt-2 mb-2"
                style={{
                  width: "15%",
                  border: "none",
                  backgroundColor: "#0866ff",
                  color: "#fff",
                  fontSize: "1rem",
                  padding: "0.5rem 1rem",
                  cursor: "pointer",
                  outline: "none",
                  transition: "all 0.2s ease-in-out",
                }}
                onClick={() => setVisible7(true)}
              >
                Sửa
              </button>
            ) : (
              <></>
            )}
            <Dialog
              header="Quản lý"
              visible={visible7}
              style={{ width: "50vw" }}
              onHide={() => {
                if (!visible7) return;
                setVisible7(false);
              }}
            >
              <div className="d-flex flex-column gap-2 mr-2 mb-2">
                <div className="p-inputgroup flex-1">
                  <span className="p-inputgroup-addon">
                    <MdContentCopy />
                  </span>
                  <InputText
                    onChange={(e) => onChangeContent7(e)}
                    placeholder="Tiêu đề"
                    value={content7}
                  />
                </div>
                <div className="p-inputgroup flex-1">
                  <span className="p-inputgroup-addon">
                    <IoIosLink />
                  </span>
                  <InputText
                    onChange={(e) => onChangeLink7(e)}
                    value={description7}
                    placeholder="Mô tả"
                  />
                </div>
              </div>
              <div className="d-flex justify-content-end gap-2 mt-2">
                <Button
                  className="button-blue radius"
                  label="Sửa"
                  onClick={() => createResource7()}
                />
                <Button
                  className="button-red radius"
                  label="Hủy"
                  onClick={() => setVisible7(false)}
                  severity="danger"
                />
              </div>
            </Dialog>
            <p className={cx("m-0")}>{contentSevenNav[0]?.content}</p>
          </AccordionTab>
          <AccordionTab header={contentEightNav[0]?.title}>
            {userInfo?.is_admin === true ? (
              <button
                className="mt-2 mb-2"
                style={{
                  width: "15%",
                  border: "none",
                  backgroundColor: "#0866ff",
                  color: "#fff",
                  fontSize: "1rem",
                  padding: "0.5rem 1rem",
                  cursor: "pointer",
                  outline: "none",
                  transition: "all 0.2s ease-in-out",
                }}
                onClick={() => setVisible8(true)}
              >
                Sửa
              </button>
            ) : (
              <></>
            )}
            <Dialog
              header="Quản lý"
              visible={visible8}
              style={{ width: "50vw" }}
              onHide={() => {
                if (!visible8) return;
                setVisible8(false);
              }}
            >
              <div className="d-flex flex-column gap-2 mr-2 mb-2">
                <div className="p-inputgroup flex-1">
                  <span className="p-inputgroup-addon">
                    <MdContentCopy />
                  </span>
                  <InputText
                    onChange={(e) => onChangeContent8(e)}
                    placeholder="Tiêu đề"
                    value={content8}
                  />
                </div>
                <div className="p-inputgroup flex-1">
                  <span className="p-inputgroup-addon">
                    <IoIosLink />
                  </span>
                  <InputText
                    onChange={(e) => onChangeLink8(e)}
                    value={description8}
                    placeholder="Mô tả"
                  />
                </div>
              </div>
              <div className="d-flex justify-content-end gap-2 mt-2">
                <Button
                  className="button-blue radius"
                  label="Sửa"
                  onClick={() => createResource8()}
                />
                <Button
                  className="button-red radius"
                  label="Hủy"
                  onClick={() => setVisible8(false)}
                  severity="danger"
                />
              </div>
            </Dialog>
            <p className={cx("m-0")}>{contentEightNav[0]?.content}</p>
          </AccordionTab>
          <AccordionTab header={contentNineNav[0]?.title}>
            {userInfo?.is_admin === true ? (
              <button
                className="mt-2 mb-2"
                style={{
                  width: "15%",
                  border: "none",
                  backgroundColor: "#0866ff",
                  color: "#fff",
                  fontSize: "1rem",
                  padding: "0.5rem 1rem",
                  cursor: "pointer",
                  outline: "none",
                  transition: "all 0.2s ease-in-out",
                }}
                onClick={() => setVisible9(true)}
              >
                Sửa
              </button>
            ) : (
              <></>
            )}
            <Dialog
              header="Quản lý"
              visible={visible9}
              style={{ width: "50vw" }}
              onHide={() => {
                if (!visible9) return;
                setVisible9(false);
              }}
            >
              <div className="d-flex flex-column gap-2 mr-2 mb-2">
                <div className="p-inputgroup flex-1">
                  <span className="p-inputgroup-addon">
                    <MdContentCopy />
                  </span>
                  <InputText
                    onChange={(e) => onChangeContent9(e)}
                    placeholder="Tiêu đề"
                    value={content9}
                  />
                </div>
                <div className="p-inputgroup flex-1">
                  <span className="p-inputgroup-addon">
                    <IoIosLink />
                  </span>
                  <InputText
                    onChange={(e) => onChangeLink9(e)}
                    value={description9}
                    placeholder="Mô tả"
                  />
                </div>
              </div>
              <div className="d-flex justify-content-end gap-2 mt-2">
                <Button
                  className="button-blue radius"
                  label="Sửa"
                  onClick={() => createResource9()}
                />
                <Button
                  className="button-red radius"
                  label="Hủy"
                  onClick={() => setVisible9(false)}
                  severity="danger"
                />
              </div>
            </Dialog>
            <p className={cx("m-0")}>{contentNineNav[0]?.content}</p>
          </AccordionTab>
        </Accordion>
        <button
          onClick={() => handleNavi("/register")}
          className={cx("questions-btn")}
        >
          DÙNG NGAY MIỄN PHÍ
        </button>
      </div>
      <footer className={cx("footer")}>
        <a
          href="https://www.chotnhanh.vn/"
          target="_blank"
          className={cx("footer-content")}
        >
          BY CHOT NHANH
        </a>
      </footer>
    </>
  );
}

export default Nav;
