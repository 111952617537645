import { InputTextarea } from "primereact/inputtextarea";
export const EditContentReels = ({
  setComments,
  setLinkVd,
  linkVd,
  comments,
}) => {
  return (
    <div className="mt-2 d-flex gap-2 justify-content-between w-100">
      <div className="d-flex flex-column" style={{ width: "50%" }}>
        <nav className="d-flex align-items-center gap-2">
          <p className="mb-0">Nội dung </p>
        </nav>
        <div className="p-inputgroup flex-1 mt-2">
          <InputTextarea
            value={comments}
            onChange={(e) => setComments(e.target.value)}
            placeholder={`Nhập danh sách nội dung bình luận\n \nThêm bình luận bằng cách nhấn nút "Thêm nội dung"`}
            rows={5}
            cols={30}
          />
        </div>
      </div>
      <div style={{ width: "50%" }}>
        <nav className="d-flex align-items-center gap-2">
          <p className="mb-0">Video </p>
        </nav>
        <div className="p-inputgroup flex-1 mt-2">
          <InputTextarea
            value={linkVd}
            onChange={(e) => setLinkVd(e.target.value)}
            placeholder={`Nhập Link (Định dạng : https://drive.google.com/file/d/........) `}
            rows={5}
            cols={30}
          />
        </div>
      </div>
    </div>
  );
};
