import React, { useEffect, useState, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";
import { API_URL, API_ROUTES_NTD } from "../../api";
import axios from "axios";
import { useHeaders } from "../../const/headers";
import { RiExpandDiagonal2Line } from "react-icons/ri";
export const ModalScanUser = ({
  props,
  dataFb,
  userModal,
  setUserModal,
  setRefresh,
  refresh,
  toast,
  setHiddenPage,
  setHiddenUser,
  setHiddenGroup,
}) => {
  const startScanUser = API_URL + API_ROUTES_NTD.TOOlBOX_USER_SCAN;
  const headers = useHeaders();
  const [url, setUrl] = useState("");
  const [quantity, setQuantity] = useState("");
  const [selectedType, setSelectedType] = useState(null);
  const cities = [
    { name: "Like bài viết", code: "like" },
    { name: "Bình luận bài viết", code: "comment" },
  ];
  const [selecAccount, setSelectAccount] = useState(null);
  const countries = dataFb?.filter((item) => item.checkpoint === false);
  const selecAccountTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <img
            alt={option.name}
            src={option.avatar}
            className="mr-2  rounded-circle"
            style={{ width: "20px" }}
          />
          <div>{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <img
          alt={option.name}
          src={option.avatar}
          className="mr-2  rounded-circle"
          style={{ width: "20px" }}
        />
        <div>{option.name}</div>
      </div>
    );
  };
  const scanToolUser = async () => {
    if (
      selecAccount?.id === undefined ||
      !url ||
      !quantity ||
      selectedType?.code === undefined
    ) {
      alert("Vui lòng nhập đầy đủ thông tin");
      return;
    }
    const body = {
      id_account: selecAccount?.id,
      url: url,
      quantity: quantity,
      type: selectedType?.code,
    };
    try {
      const res = await axios.post(startScanUser, body, headers);
      setUserModal(false);
      toast.success(res?.data?.message);
      setQuantity("");
      setSelectedType(null);
      setSelectAccount(null);
      setUrl("");
    } catch (err) {}
  };
  return (
    <Dialog
      header="Thêm thông tin"
      visible={userModal}
      style={{ position: "relative", width: "50vw" }}
      onHide={() => setUserModal(false)}
      className="overFlowJoinGr"
      footer={
        <div className="d-flex justify-content-end gap-2 mt-2">
          <Button className="button-blue radius" onClick={scanToolUser}>
            Bắt đầu quét
          </Button>
          <Button
            onClick={() => setUserModal(false)}
            className="button-red radius"
          >
            Thoát không lưu
          </Button>
          <RiExpandDiagonal2Line
            size={20}
            style={{
              position: "absolute",
              right: "0",
              bottom: "0",
            }}
          />
        </div>
      }
    >
      <div className="d-flex flex-column h-100" style={{ overflow: "auto" }}>
        <div className="p-inputgroup flex-1 mb-2">
          <span className="p-inputgroup-addon " style={{ width: "9rem" }}>
            Tài khoản
          </span>
          <Dropdown
            value={selecAccount}
            onChange={(e) => setSelectAccount(e.value)}
            options={countries}
            optionLabel="name"
            placeholder="Danh sách tài khoản"
            filter
            valueTemplate={selecAccountTemplate}
            itemTemplate={countryOptionTemplate}
            className="w-full md:w-14rem pl-2"
          />
        </div>
        <div>
          <div className="p-inputgroup flex-1 mb-2">
            <span className="p-inputgroup-addon " style={{ width: "9rem" }}>
              URL
            </span>
            <InputText
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              placeholder="Link bài viết sẽ đi quét ( Chỉ nhập 1 link bài viết muốn quét)"
            />
          </div>
          <div className="p-inputgroup flex-1 mb-2">
            <span className="p-inputgroup-addon" style={{ width: "9rem" }}>
              Số lượng
            </span>
            <InputNumber
              value={quantity}
              placeholder="Số lượng UID cần quét"
              onValueChange={(e) => setQuantity(e.value)}
              useGrouping={false}
            />
          </div>
        </div>
        <div className="p-inputgroup flex-1 mb-2">
          <span className="p-inputgroup-addon " style={{ width: "9rem" }}>
            Quét
          </span>
          <Dropdown
            value={selectedType}
            onChange={(e) => setSelectedType(e.value)}
            options={cities}
            optionLabel="name"
            placeholder="Chọn giá trị"
            className="w-full md:w-14rem"
          />
        </div>
      </div>
    </Dialog>
  );
};
