import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
export const ModalExpProxy = ({ dataFb }) => {
  const [showDialog, setShowDialog] = useState(false);
  const checkExpiredProxies = (data) => {
    const currentDate = new Date();
    return data.filter(
      (item) =>
        item.date_expired_proxy !== null &&
        new Date(item.date_expired_proxy) < currentDate
    );
  };
  const expiredProxies = checkExpiredProxies(dataFb);
  useEffect(() => {
    const dialogShown = localStorage.getItem("dialogShown");
    if (expiredProxies.length > 0 && !dialogShown) {
      setShowDialog(true);
      localStorage.setItem("dialogShown", "true");
    }
  }, [expiredProxies]);
  const onHideDialog = () => {
    setShowDialog(false);
  };

  return (
    <Dialog
      header="Hết hạn Proxy"
      visible={showDialog}
      style={{ width: "50vw" }}
      onHide={onHideDialog}
    >
      <div className="d-flex flex-wrap gap-2">
        <p>Các tài khoản:</p>
        {expiredProxies?.map((item, index) => (
          <p key={index} style={{ fontWeight: "bold" }}>
            {item?.name}
          </p>
        ))}
        <p>đã hết hạn proxy</p>
      </div>
    </Dialog>
  );
};
