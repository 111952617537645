import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { MdAccountCircle } from "react-icons/md";
import { MdOutlinePassword } from "react-icons/md";
import { Calendar } from "primereact/calendar";
import { FaCalendarAlt } from "react-icons/fa";
import { Button } from "primereact/button";
import moment from "moment-timezone";
import axios from "axios";
import { API_URL, API_ROUTES } from "../../../api";
import { useUser } from "../../../contexts/UserContext";
import { Password } from "primereact/password";
import { useTranslation } from "react-i18next";
import { FaPhoneAlt } from "react-icons/fa";
import { MdOutlineTimeline } from "react-icons/md";
import { MdDriveFileRenameOutline } from "react-icons/md";
import { CiViewTimeline } from "react-icons/ci";
import { useHeaders } from "../../../const/headers";
export const Editemployee = ({
  setModalEdit,
  modalEdit,
  setRefresh,
  refresh,
  itemEdit,
}) => {
  const headers = useHeaders();
  const currentDate = moment().tz("Asia/Ho_Chi_Minh").toDate();
  const { userInfo, setUserInfo } = useUser();
  const [date, setDate] = useState(
    itemEdit?.expiration_date?.split(" ")[0] || null
  );
  const [password, setPassword] = useState(itemEdit?.raw_password || "");
  const [username, setUsername] = useState(itemEdit?.username);
  const [fullName, setFullName] = useState(itemEdit?.fullname || "");
  const [phone, setPhone] = useState(itemEdit?.phone_number || "");
  const [limitLive, setLimitLive] = useState(itemEdit?.live_stream_limit || "");
  const editAcc = API_URL + API_ROUTES.EDIT_ACCCOUNT_EMPLOYEE;
  const { t } = useTranslation();
  const onChangePass = (e) => {
    setPassword(e.target.value);
  };
  const onChangeFullName = (e) => {
    setFullName(e.target.value);
  };
  const onChangePhone = (e) => {
    setPhone(e.target.value);
  };
  const onChangeUser = (e) => {
    setUsername(e.target.value);
  };
  const onchangeDate = (e) => {
    setDate(e.target.value);
  };
  const onchangeLimit = (e) => {
    setLimitLive(e.target.value);
  };
  const styles = {
    mb0ml2: {
      width: "150px",
      justifyContent: "flex-start",
    },
  };
  useEffect(() => {
    setDate(itemEdit?.expiration_date?.split(" ")[0]);
    setPassword(itemEdit?.raw_password);
    setFullName(itemEdit?.fullname);
    setUsername(itemEdit?.username);
    setPhone(itemEdit?.phone_number);
    setLimitLive(itemEdit?.live_stream_limit);
  }, [itemEdit]);
  const editManager = async () => {
    const body = {
      id_employee: itemEdit?.id,
      username: itemEdit?.username,
      password: password,
      fullname: fullName,
      phone_number: phone,
      expiration_date: `${date} 23:59:59`,
      live_stream_limit: 0,
    };
    setRefresh(!refresh);
    setModalEdit(false);
    try {
      const response = await axios.post(editAcc, body, headers);
    } catch (err) {
    }
  };
  return (
    <div className="card flex justify-content-center">
      <Dialog
        header="Chỉnh sửa tài khoản nhân viên"
        visible={modalEdit}
        style={{ width: "50vw" }}
        onHide={() => setModalEdit(false)}
      >
        <div
          className="card flex flex-column flex-wrap md:flex-row gap-3"
          style={{ border: "0" }}
        >
          <div className="p-inputgroup ">
            <span className="p-inputgroup-addon" style={styles.mb0ml2}>
              <MdAccountCircle />
              <p className="mb-0 ml-2">Tài khoản</p>
            </span>
            <InputText
              //   onChange={(e) => onChangeUser(e)}
              placeholder="Tài khoản"
              value={username}
            />
          </div>
          <div className="p-inputgroup ">
            <span className="p-inputgroup-addon" style={styles.mb0ml2}>
              <MdOutlinePassword />
              <p className="mb-0 ml-2">Mật khẩu</p>
            </span>
            <Password
              toggleMask
              feedback={t("")}
              promptLabel={t("")}
              onChange={(e) => onChangePass(e)}
              placeholder="Mật khẩu"
              value={password}
            />
          </div>
          <div className="p-inputgroup ">
            <span className="p-inputgroup-addon" style={styles.mb0ml2}>
              <MdDriveFileRenameOutline />
              <p className="mb-0 ml-2">Họ tên</p>
            </span>
            <InputText
              onChange={(e) => onChangeFullName(e)}
              placeholder="Họ Tên"
              value={fullName}
            />
          </div>
          <div className="p-inputgroup ">
            <span className="p-inputgroup-addon" style={styles.mb0ml2}>
              <FaPhoneAlt />
              <p className="mb-0 ml-2">Số điện thoại</p>
            </span>
            <InputText
              onChange={(e) => onChangePhone(e)}
              placeholder="Số điện thoại"
              keyfilter={/[0-9]/}
              value={phone}
            />
          </div>
          {/* <div className="p-inputgroup ">
            <span className="p-inputgroup-addon" style={styles.mb0ml2}>
              <MdOutlineTimeline />
            </span>
            <InputText
              value={limitLive}
              onChange={(e) => onchangeLimit(e)}
              placeholder="Giới hạn LiveStream"
              type="number"
            />
          </div> */}
          {/* <div className="p-inputgroup ">
            <span className="p-inputgroup-addon" style={styles.mb0ml2}>
              <CiViewTimeline />
            </span>
            <InputText
              value={date}
              onChange={(e) => onchangeDate(e)}
              placeholder="Thời hạn"
              type="text"
            />
          </div> */}
        </div>
        <div className="d-flex justify-content-end gap-2 mt-2">
          <Button
            className="button-blue radius"
            label="Sửa"
            onClick={() => editManager()}
          />
          <Button
            className="button-red radius"
            label="Hủy"
            onClick={() => setModalEdit(false)}
            severity="danger"
          />
        </div>
      </Dialog>
    </div>
  );
};
