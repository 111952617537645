import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Fieldset } from "primereact/fieldset";
import { Avatar } from "primereact/avatar";
import { Chip } from "primereact/chip";
import { API_URL, API_ROUTES_NTD } from "../../../api";
import axios from "axios";
import { useHeaders } from "../../../const/headers";
import { TiDelete } from "react-icons/ti";

export const ModalManageCateMkt = ({
  setModalManage,
  modalManage,
  itemManage,
  setRefresh,
  refresh,
  itemCate,
  dataFb,
  allCate,
}) => {
  const headers = useHeaders();
  const deleteCate = API_URL + API_ROUTES_NTD.REMOVE_CATEGORY_IN_ACCOUNT;
  const dataAccount =
    dataFb?.filter((item) => itemCate?.account?.includes(item.id)) || [];
  // const handleRemoveChip = async (item) => {
  //   const body = {
  //     id_category: itemCate?.id,
  //     id_accounts: [item?.id],
  //   };
  //   try {
  //     await axios.post(deleteCate, body, headers);
  //     setRefresh(!refresh);
  //   } catch (err) {}
  // };
  return (
    <Dialog
      header="Tài khoản được gán"
      visible={modalManage}
      style={{ width: "50vw" }}
      onHide={() => setModalManage(false)}
    >
      <div className="d-flex gap-2 flex-wrap">
        {dataAccount?.map((item, index) => (
          <div
            key={index}
            className="d-flex gap-2 align-items-center"
            style={{
              padding: "4px",
              borderRadius: "12px",
              background: "#0083f5",
            }}
          >
            <img
              className="rounded-circle"
              style={{
                width: "20px",
                height: "20px",
              }}
              src={item?.avatar}
            />
            <p className="text-light">{item?.name}</p>
            {/* <TiDelete onClick={() => handleRemoveChip(item)} size={20} /> */}
          </div>
        ))}
      </div>
    </Dialog>
  );
};
