import React, { useState, useEffect, useRef } from "react";
import Navigate from "../container/Navigate";
import { InputGroup } from "react-bootstrap";
import { Form, Table } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { API_URL, API_ROUTES } from "../../api";
import axios from "axios";
import { Footer } from "./Footer";
import { IoSearch } from "react-icons/io5";
import { useAuth } from "../../AuthContext";
import { useHeaders } from "../../const/headers";
import { updateTitle } from "../../const/updateTitle";
import { CreateProducts } from "../container/productModal/CreateProducts";
import { EditProducts } from "../container/productModal/EditProducts";
import { EditMessConfirm } from "../container/productModal/EditMessConfirm";
export const ManageProducts = (props) => {
  updateTitle("Chốt Sale - Quản Lý Sản Phẩm");
  const headers = useHeaders();
  const [modalShow, setModalShow] = useState(false);
  const [productShow, setProductShow] = useState(false);
  const [product, setProduct] = useState();
  const [refresh, setRefresh] = useState(true);
  const deleteProduct = API_URL + API_ROUTES.GET_DELETE_PRODUCT;
  const getMessFirst = API_URL + API_ROUTES.GET_FIRST_MESS;
  const apiGetProduct = API_URL + API_ROUTES.GET_PAGE_PRODUCT;
  const getThumbnail = API_URL + API_ROUTES.GET_URL_THUMBNAIL;
  const [selectedItemEdit, setSelectedItemEdit] = useState({});
  const [keywordsEdit, setKeywordsEdit] = useState("");
  const handleClose = () => setModalMess(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [hiddenSearch, setHiddenSearch] = useState(false);
  const [messFirst, setMessFirst] = useState("");
  const [modalMess, setModalMess] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const { setUserLoggedIn } = useAuth();
  const [widthContent, setWidthContent] = useState("");
  const [checkbox, setCheckbox] = useState(false);
  const [addProducts, setAddProducts] = useState([
    {
      phanloai1: "",
      phanloai2: "",
      price: 0,
      inventory: 0,
      imageProduct: null,
    },
  ]);
  const [editProducts, setEditProducts] = useState([
    {
      id: null,
      phanloai1: "",
      phanloai2: "",
      price: 0,
      inventory: 0,
      imageProduct: null,
    },
  ]);
  const editOrderCode = product?.filter(
    (item) => item.order_code === keywordsEdit
  );
  useEffect(() => {
    setEditProducts(
      editOrderCode?.map((item) => ({
        id: item.id || null,
        phanloai1: item.phanloai1 || "",
        phanloai2: item.phanloai2 || "",
        price: item.price || 0,
        inventorys: item.inventory || 0,
        imageProduct: item.thumbnail,
        soldquantity: item.sold_quantity,
        totalquantity: item.total_quantity,
      }))
    );
  }, [productShow]);
  const handleChangeEdit = async (index, event) => {
    const { name, value, files } = event.target;
    const newProducts = [...editProducts];
    if (
      name === "category" ||
      name === "phanloai1" ||
      name === "phanloai2" ||
      name === "price" ||
      name === "inventory"
    ) {
      newProducts[index][name] = value;
    } else if (name === "image") {
      newProducts[index][name] = files[0];
      await getListLinkThumnail(index);
    }
    setEditProducts(newProducts);
  };
  const handleEditProduct = () => {
    setEditProducts((prevProducts) => {
      const newProduct = {
        id: null,
        category: "",
        price: 0,
        inventory: 0,
        imageProduct: null,
      };
      return [...prevProducts, newProduct];
    });
  };
  const handleRemoveEdit = (index) => {
    const newProducts = [...editProducts];
    const matchingIndex = editOrderCode.findIndex(
      (item) => item.id === newProducts[index].id
    );
    if (matchingIndex === -1) {
      newProducts.splice(index, 1);
      setEditProducts(newProducts);
    } else {
      alert("Không thể xóa mục này");
    }
  };
  const handleChangePro = async (index, value, name) => {
    const newProducts = [...addProducts];
    if (name === "phanloai2" || name === "phanloai1" || name === "inventory") {
      newProducts[index][name] = value.target.value;
    } else if (name === "price") {
      newProducts[index][name] = value;
    } else if (name === "image") {
      newProducts[index][name] = value.target.files[0];
      await getListLinkAdd(index);
    }
    setAddProducts(newProducts);
  };
  const handleAddProduct = () => {
    setAddProducts([
      ...addProducts,
      {
        phanloai1: "",
        phanloai2: "",
        price: 0,
        inventory: 0,
        imageProduct: null,
      },
    ]);
  };
  const handleRemoveProduct = (index) => {
    const newProducts = [...addProducts];
    newProducts.splice(index, 1);
    setAddProducts(newProducts);
  };

  const getListLinkThumnail = async (index) => {
    const formData = new FormData();
    formData.append("thumbnail", editProducts[index].image);
    try {
      const res = await axios.post(getThumbnail, formData, headers);
      const newThumbnailUrl = res.data.thumbnail;
      updateThumbnailUrl(index, newThumbnailUrl);
    } catch (err) {
      
    }
  };
  const updateThumbnailUrl = (index, newUrl) => {
    setEditProducts((prevProducts) => {
      const updatedProducts = [...prevProducts];
      updatedProducts[index].imageProduct = newUrl;
      return updatedProducts;
    });
  };
  const getListLinkAdd = async (index) => {
    const formData = new FormData();
    formData.append("thumbnail", addProducts[index].image);
    try {
      const res = await axios.post(getThumbnail, formData, headers);
      const newThumbnailUrl = res.data.thumbnail;
      createThumbnailUrl(index, newThumbnailUrl);
    } catch (err) {
      
    }
  };
  const createThumbnailUrl = (index, newUrl) => {
    setAddProducts((prevProducts) => {
      const updatedProducts = [...prevProducts];
      updatedProducts[index].imageProduct = newUrl;
      return updatedProducts;
    });
  };

  const [productInfo, setProductInfo] = useState({
    order_code: "",
    name: "",
    thumbnail: null,
    category: "",
    price: "",
    inventory: "",
    detail: "",
  });
  const handleEditMess = () => {
    setModalMess(true);
  };
  const onClickSearch = () => {
    setHiddenSearch(!hiddenSearch);
  };
  const handleCheckboxChange = (event) => {
    setCheckbox(event.target.checked);
    const { checked } = event.target;
    const updatedSelectedItems = {};
    product?.forEach((item) => {
      updatedSelectedItems[item.id] = checked;
    });
    setSelectedItems(updatedSelectedItems);
  };
  const handleCheckboxSelec = (event) => {
    const { value, checked } = event.target;
    setSelectedItems((prevState) => ({
      ...prevState,
      [value]: checked,
    }));
  };
  const handleInputChangeE = (event, field) => {
    const { value } = event.target;
    setSelectedItemEdit((prevItem) => ({
      ...prevItem,
      [field]: value,
    }));
  };
  const handleEdit = (item) => {
    setKeywordsEdit(item.order_code);
    setSelectedItemEdit(item);
    setProductShow(true);
  };
  const getSelectedUIDs = () => {
    return Object.keys(selectedItems)?.filter((key) => selectedItems[key]);
  };
  const selectedUIDs = getSelectedUIDs();
  const deleteData = async () => {
    if (selectedUIDs.length === 0) {
      alert("Bạn chưa chọn sản phẩm nào để xóa");
    } else {
      const confirm = window.confirm("Bạn chắc chắn muốn xóa không");
      if (confirm) {
        try {
          const body = { id: selectedUIDs };
          const response = await axios.post(deleteProduct, body, headers);
          setRefresh(false);
          setSelectedItems({});
        } catch (error) {
        }
      }
    }
  };

  const deleteDataOne = async (item) => {
    if (item.length === 0) {
      alert("Bạn chưa chọn sản phẩm nào để xóa");
    } else {
      const confirm = window.confirm("Bạn chắc chắn muốn xóa không");
      if (confirm) {
        try {
          const body = { id: [item.id] };
          const response = await axios.post(deleteProduct, body, headers);
          setRefresh(false);
          setSelectedItems({});
        } catch (error) {
        }
      }
    }
  };

  const handleChangeMa = (event) => {
    const { name, value } = event.target;
    let sanitizedValue = value;

    if (name === "order_code") {
      const hasSpecialCharacters = /[^\w\s]/.test(value);
      if (hasSpecialCharacters) {
        alert("Mã sản phẩm không được chứa dấu , Vui lòng nhập lại");
        return;
      }
      sanitizedValue = value.replace(/[^\w\s]/gi, "");
    }
    setProductInfo((prevState) => ({
      ...prevState,
      [name]: sanitizedValue,
    }));
  };
  const isOrderCodeExist =
    product?.filter((item) => item.order_code === productInfo.order_code)
      .length > 0;

  const showFileInput = (index) => {
    document.getElementById(`fileInput-${index}`).click();
  };

  const getFistMess = async () => {
    try {
      const res = await axios.get(getMessFirst, headers);
      setMessFirst(res?.data.first_message);
    } catch (error) {
    }
  };
  useEffect(() => {
    axios
      .get(apiGetProduct, headers)
      .then(function (response) {
        setProduct(response.data);
      })
      .catch(function (error) {
        if (error?.response?.status === 401) {
          setUserLoggedIn(false);
        }
      });
    setRefresh(true);
    getFistMess();
    setProductInfo({
      order_code: "",
      name: "",
      thumbnail: "",
      category: "",
      price: "",
      inventory: "",
      detail: "",
    });
  }, [refresh]);

  const uniqueItems = {};
  product?.forEach((item) => {
    const orderCode = item["order_code"];
    if (!uniqueItems[orderCode]) {
      uniqueItems[orderCode] = item;
    }
  });
  const uniqueData = Object.values(uniqueItems);
  const sumByOrderCode = [];
  product?.forEach((item) => {
    const orderCode = item["order_code"];
    if (!sumByOrderCode[orderCode]) {
      sumByOrderCode[orderCode] = item["total_quantity"];
    } else {
      sumByOrderCode[orderCode] += item["total_quantity"];
    }
  });

  function filterByName(filterDataFb, searchKey) {
    return filterDataFb?.filter((item) =>
      Object.values(item).some(
        (value) =>
          typeof value === "string" &&
          value.toLowerCase().includes(searchKey.toLowerCase())
      )
    );
  }
  const handleClickImage = (index) => {
    const images = document.querySelectorAll(".img-modal");
    images.forEach((img, i) => {
      if (i === index) {
        img.classList?.toggle("zoom");
      } else {
        img.classList?.remove("zoom");
      }
    });
  };
  const resultPro = filterByName(product, searchKey);
  const element = document.getElementById("width-content-product");
  const phanloai1 = document.getElementById("phanloai1");
  useEffect(() => {
    setWidthContent(element?.offsetWidth);
  }, [element]);
  const onChangeKey = (e) => {
    setSearchKey(e.target.value);
  };

  return (
    <div className="d-flex flex-column " style={{ height: "100vh" }}>
      <div className="notifica-container">
        <div className="navigate-container">
          <Navigate />
        </div>
        <div className="p-2 d-flex flex-column w-100" style={{ gap: "10px" }}>
          <div className="d-flex  gap-2 flite-account-manage">
            <div
              className="d-flex align-items-center  w-100 box-sizing-border-box"
              style={{ gap: "10px" }}
            >
              <Button
                style={{
                  borderRadius: "0",
                  height: "100%",
                  width: "100%",
                  background: "#054ddb",
                  fontSize: "14px",
                }}
                variant="primary"
                onClick={() => setModalShow(true)}
              >
                Thêm mã sản phẩm
              </Button>

              <CreateProducts
                showFileInput={showFileInput}
                setProductInfo={setProductInfo}
                setAddProducts={setAddProducts}
                handleChangePro={handleChangePro}
                handleRemoveProduct={handleRemoveProduct}
                handleAddProduct={handleAddProduct}
                isOrderCodeExist={isOrderCodeExist}
                headers={headers}
                addProducts={addProducts}
                widthContent={widthContent}
                setModalShow={setModalShow}
                modalShow={modalShow}
                productInfo={productInfo}
                handleChangeMa={handleChangeMa}
                setRefresh={setRefresh}
              />
              <EditProducts
                handleRemoveEdit={handleRemoveEdit}
                editProducts={editProducts}
                handleChangeEdit={handleChangeEdit}
                setRefresh={setRefresh}
                headers={headers}
                productShow={productShow}
                setProductShow={setProductShow}
                selectedItemEdit={selectedItemEdit}
                handleInputChangeE={handleInputChangeE}
                showFileInput={showFileInput}
                handleClickImage={handleClickImage}
                handleEditProduct={handleEditProduct}
              />
              <Button
                style={{
                  borderRadius: "0",
                  height: "100%",
                  width: "100%",
                  background: "#054ddb",
                  fontSize: "14px",
                }}
                variant="primary"
                onClick={() => handleEditMess()}
              >
                Thiết lập tin nhắn xác nhận trong Livestream
              </Button>
              <Button
                style={{
                  borderRadius: "0",
                  height: "100%",
                  width: "100%",
                  fontSize: "14px",
                }}
                variant="primary bg-danger border-danger"
                onClick={deleteData}
              >
                Xóa mã sản phẩm
              </Button>
              <EditMessConfirm
                messFirst={messFirst}
                handleClose={handleClose}
                modalMess={modalMess}
                headers={headers}
                setRefresh={setRefresh}
                refresh={refresh}
                setModalMess={setModalMess}
              />
            </div>
            <InputGroup
              className="responsive-width-acc"
              style={{
                width: "auto",
                borderRadius: "0",
              }}
            >
              <Form.Control
                placeholder="Tìm kiếm nhanh"
                aria-describedby="basic-addon2"
                value={searchKey}
                onChange={onChangeKey}
                style={{
                  borderTopLeftRadius: "0",
                  borderBottomLeftRadius: "0",
                  display: `${hiddenSearch === true ? "none" : ""}`,
                }}
              />

              <IoSearch
                onClick={onClickSearch}
                className="responsive-hidden-search"
                style={{ display: "none", fontSize: "42px" }}
              />
            </InputGroup>
          </div>
          <div
            className="overflow-auto h-100"
            style={{ border: "0.5px solid" }}
          >
            <Table striped bordered hover>
              <thead className="thead-fixed">
                <tr style={{ borderTop: "0" }}>
                  <th>
                    <input
                      type="checkbox"
                      checked={checkbox}
                      onChange={handleCheckboxChange}
                    />
                  </th>
                  <th>STT</th>
                  <th>Mã hàng</th>
                  <th>Tên sản phẩm</th>
                  <th>Ảnh sản phẩm</th>
                  <th>Mô tả</th>
                  <th>Số lượng</th>
                  <th>Tùy chọn</th>
                </tr>
              </thead>
              <tbody>
                {(uniqueData || resultPro)?.map((item, index) => (
                  <tr key={index}>
                    <td className="text-center">
                      {" "}
                      <input
                        type="checkbox"
                        checked={selectedItems[item?.id] || false}
                        onChange={handleCheckboxSelec}
                        value={item?.id}
                      />
                    </td>
                    <td style={{ textTransform: "uppercase" }}>{index + 1}</td>
                    <td style={{ textTransform: "uppercase" }}>
                      {item.order_code}
                    </td>
                    <td>{item.name}</td>
                    <td>
                      <img
                        style={{ width: "30%" }}
                        src={`${API_URL}/${item.thumbnail}`}
                        alt=""
                      />
                    </td>
                    {/* <td>{item.phanloai}</td> */}
                    <td>{item.detail}</td>
                    <td>
                      {sumByOrderCode[item?.order_code]}
                      {/* {item?.total_quantity} */}
                    </td>
                    <td>
                      <button
                        className="text-light"
                        style={{
                          background: "#054ddb",
                          color: "#054ddb",
                          padding: "4px 14px",
                          border: "#054ddb",
                          marginRight: "0.5rem",
                        }}
                        onClick={() => handleEdit(item)}
                      >
                        Sửa
                      </button>
                      <button
                        className="text-light"
                        style={{
                          background: "red",
                          color: "red",
                          padding: "4px 14px",
                          border: "red",
                        }}
                        onClick={() => deleteDataOne(item)}
                      >
                        Xóa
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
