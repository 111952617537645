import { useState, useEffect } from "react";
import { API_URL, API_ROUTES_NTD } from "../../../api";
import axios from "axios";
import { useHeaders } from "../../../const/headers";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Checkbox } from "primereact/checkbox";
import { locale } from "primereact/api";
import { rowClassName } from "../../../const/checkboxPrimeReactjs";
import { onRowClick } from "../../../const/checkboxPrimeReactjs";
import {
  toggleSelectAll,
  headerCheckbox,
  bodyCheckbox,
} from "../../../const/checkboxPrimeReactjs";
export const TableAccountInviteFriend = ({
  dataFb,
  setAllFriend,
  setAllGroup,
  checkboxes,
  setCheckboxes,
  selectedItems,
  setSelectedItems,
  refresh,
  setListFr,
  setListGr,
}) => {
  locale("vi");
  const getAllGr = API_URL + API_ROUTES_NTD.GET_ALL_GROUP;
  const getAllFr = API_URL + API_ROUTES_NTD.GET_ALL_FRIEND;
  const headers = useHeaders();
  const filterAccountCheckpoit = dataFb?.filter(
    (item) => item?.checkpoint === false && item.status_proxy === true
  );
  const getDataAllGr = async (item) => {
    if (item?.id !== undefined) {
      const body = { id_account: item?.id };
      try {
        const res = await axios.get(getAllGr, body, headers);
        setAllGroup(res.data);
      } catch (error) {}
    } else {
      setAllGroup([]);
      setListGr([]);
    }
  };
  const getDataAllFr = async (item) => {
    if (item?.id !== undefined) {
      const body = { id_account: item?.id };
      try {
        const res = await axios.get(getAllFr, body, headers);
        setAllFriend(res.data);
      } catch (err) {}
    } else {
      setAllFriend([]);
      setListFr([]);
    }
  };

  useEffect(() => {
    getDataAllGr(selectedItems[0]);
    getDataAllFr(selectedItems[0]);
  }, [refresh, selectedItems]);

  const nameAndAvt = (rowData) => {
    return (
      <div className="d-flex gap-2 align-items-center">
        <img
          className="rounded-circle"
          style={{ width: "20px", height: "20px" }}
          src={rowData?.avatar}
        />
        <p>{rowData?.name}</p>
      </div>
    );
  };
  const indexTemplate = (rowData, rowIndex) => {
    return <>{rowIndex.rowIndex + 1}</>;
  };
  return (
    <div style={{ width: "100%" }}>
      <DataTable
        size="small"
        value={filterAccountCheckpoit}
        className="small-font-table dataTableWithFixedHeader dataTableWithHoverEffect datatableoverflow"
        tableStyle={{ minWidth: "50%" }}
        rowClassName={(rowData) => rowClassName(rowData, selectedItems)}
        onRowClick={(e) => onRowClick(e.data, setSelectedItems, selectedItems)}
      >
        <Column
          header={headerCheckbox(
            selectedItems,
            filterAccountCheckpoit,
            toggleSelectAll,
            setSelectedItems
          )}
          body={(rowData) =>
            bodyCheckbox(
              rowData,
              selectedItems,
              setSelectedItems,
              getDataAllGr,
              getDataAllFr
            )
          }
        />
        <Column body={indexTemplate} field="UID" header="STT"></Column>
        <Column field="UID" header="UID" sortable></Column>
        <Column
          body={(rowData) => nameAndAvt(rowData)}
          field="name"
          header="Tên tài khoản"
          sortable
        ></Column>
      </DataTable>
    </div>
  );
};
