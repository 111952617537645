import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { MdAccountCircle } from "react-icons/md";
import axios from "axios";
import { API_URL, API_ROUTES } from "../../../api";
import { useTranslation } from "react-i18next";
import { useHeaders } from "../../../const/headers";

export const ModalAcceptDebug = ({
  debugAcc,
  setDebugAcc,
  setRefresh,
  refresh,
  getItemEdit,
}) => {
  const headers = useHeaders();
  const [username, setUsername] = useState(getItemEdit?.username || "");
  const conformAcc = API_URL + API_ROUTES.CONFIRM_SYSTEM_DEBUG;
  const { t } = useTranslation();
  const onChangeFullName = (e) => {
    setUsername(e.target.value);
  };
  const adminConfirm = async () => {
    const body = { id_debug: getItemEdit.id_debug, fixer: username };
    try {
      const res = await axios.post(conformAcc, body, headers);
      setDebugAcc(false);
      setRefresh(!refresh);
    } catch (err) {
    }
  };
  return (
    <div className=" flex justify-content-center">
      <Dialog
        header="Xác nhận "
        visible={debugAcc}
        style={{ width: "50vw" }}
        onHide={() => setDebugAcc(false)}
      >
        <div
          className="card flex flex-column flex-wrap md:flex-row gap-3"
          style={{ border: "0" }}
        >
          <div className="p-inputgroup ">
            <span className="p-inputgroup-addon">
              <MdAccountCircle />
            </span>
            <InputText
              onChange={(e) => onChangeFullName(e)}
              placeholder="Tên nhân viên"
              value={username}
            />
          </div>
        </div>
        <div className="d-flex justify-content-end gap-2 mt-2">
          <Button
            className=" button-blue radius"
            label="Xác nhận"
            onClick={() => adminConfirm()}
          />
          <Button
            className=" button-red radius"
            label="Hủy"
            onClick={() => setDebugAcc(false)}
            severity="danger"
          />
        </div>
      </Dialog>
    </div>
  );
};
