import React from "react";
import { Checkbox } from "primereact/checkbox";
export const headerCheckbox = (
  selectedItems,
  filterAccountCheckpoit,
  toggleSelectAll,
  setSelectedItems
) => (
  <Checkbox
    onChange={() =>
      toggleSelectAll(selectedItems, filterAccountCheckpoit, setSelectedItems)
    }
    checked={selectedItems?.length === filterAccountCheckpoit?.length}
  />
);
export const toggleSelectAll = (
  selectedItems,
  filterAccountCheckpoit,
  setSelectedItems
) => {
  if (selectedItems?.length === filterAccountCheckpoit?.length) {
    setSelectedItems([]);
  } else {
    setSelectedItems([...filterAccountCheckpoit]);
  }
};
export const onRowCheckboxChange = (
  e,
  item,
  selectedItems,
  setSelectedItems
) => {
  let _selectedItems = [...selectedItems];
  if (e.checked) {
    _selectedItems.push(item);
  } else {
    _selectedItems = _selectedItems.filter((i) => i.id !== item.id);
  }
  setSelectedItems(_selectedItems);
};
export const isItemSelected = (item, selectedItems) => {
  return (
    Array.isArray(selectedItems) &&
    selectedItems.some((i) => i?.id === item?.id)
  );
};
export const bodyCheckbox = (
  item,
  selectedItems,
  setSelectedItems,
  getDataAllFr
) => (
  <Checkbox
    onClick={(e) => {
      if (e.target.checked) {
        getDataAllFr(item);
      } else {
        getDataAllFr({});
      }
    }}
    onChange={(e) =>
      onRowCheckboxChange(e, item, selectedItems, setSelectedItems)
    }
    checked={isItemSelected(item, selectedItems)}
  />
);
export const onRowClick = (rowData, setSelectedItem, selectedItem) => {
  const selected = selectedItem.some((item) => item.id === rowData.id);
  const newSelectedItem = selected
    ? selectedItem.filter((item) => item.id !== rowData.id)
    : [...selectedItem, rowData];
  setSelectedItem(newSelectedItem);
};
export const rowClassName = (rowData, selectedItem) => {
  return selectedItem?.includes(rowData.id) ? "selected-row" : "";
};
