import React, { useEffect, useState, useRef } from "react";
import { Divider } from "primereact/divider";
import { Galleria } from "primereact/galleria";
import { MdLocationOn } from "react-icons/md";
import { InputTextarea } from "primereact/inputtextarea";
// import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { Button } from "primereact/button";
import { API_URL, API_ROUTES_NTD } from "../../api";
import { useHeaders } from "../../const/headers";
import { useNavigate } from "react-router-dom";
import { IoIosCloseCircle } from "react-icons/io";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { RenderMap } from "../../const/RenderMap";
import { getAxios } from "../../const/getAxios";
import axios from "axios";
import { useLocation } from "react-router-dom";
export default function PreviewMarket({
  imageURLs,
  title,
  price,
  description,
  filerKey,
  selecAcc,
  listFile,
  selectedSubOption,
  listImage,
  name,
  dataHidden,
  toast,
  messMarket,
  location,
  subOptions,
  selectCate,
  selectedYear,
  carBrand,
  carModel,
  carMileage,
  acreage,
  bathRoom,
  bedRoom,
  seletedHouse,
}) {
  const locationss = useLocation();
  const navigate = useNavigate();
  const apiPostMarket = API_URL + API_ROUTES_NTD.POST_MARKETPLACE;
  const getAllCateMkt = API_URL + API_ROUTES_NTD.GET_CATEGORY_MARKETPLACE;
  const getIdDetail = API_URL + API_ROUTES_NTD.POST_ID_GET_CATE_MARKETPLACE;
  const getIdCate = API_URL + API_ROUTES_NTD.GET_CATE_MARKETPLACE;
  const formData = new FormData();
  const headers = useHeaders();
  const [allCate, setAllCate] = useState([]);
  const [idCate, setIdCate] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [dataDetailVehicle, setDataDetailVehicle] = useState([]);
  const [dataDetailHouse, setDataDetailHouse] = useState([]);
  const idCateHouse = idCate?.find(
    (item) => item.category_name === "Cho thuê nhà"
  );
  const idCateVehicle = idCate?.find((item) => item.category_name === "Xe cộ");
  const idCategotyDetail = async () => {
    const bodyPostHouse = {
      id_category: idCateHouse?.id || null,
    };
    const bodyPostVehicle = {
      id_category: idCateVehicle?.id || null,
    };
    if (!idCateHouse?.id || !idCateVehicle?.id) {
      return;
    }
    try {
      const res = await axios.post(
        getIdDetail,
        locationss.pathname === "/vehiclemarket" ||
          locationss.pathname.includes("/editvehicle")
          ? bodyPostVehicle
          : bodyPostHouse,
        headers
      );
      if (
        locationss.pathname === "/vehiclemarket" ||
        locationss.pathname.includes("/editvehicle")
      ) {
        setDataDetailVehicle(res.data);
        console.log(res.data);
      }
      if (
        locationss.pathname === "/housemarket" ||
        locationss.pathname.includes("/edithouse")
      ) {
        setDataDetailHouse(res.data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    getAxios(getAllCateMkt, setAllCate);
  }, []);
  useEffect(() => {
    getAxios(getIdCate, setIdCate);
  }, []);
  useEffect(() => {
    idCategotyDetail();
  }, [selectCate, idCate]);
  const body = {
    id_category: dataHidden ? dataHidden[0]?.id : null,
    title: title || "",
    description: description || "",
    images: listImage || "",
    latitude:
      filerKey?.latitude ||
      (Array.isArray(location) && location[0]?.latitude) ||
      "",
    longitude:
      filerKey?.longitude ||
      (Array.isArray(location) && location[0]?.longitude) ||
      "",
    id_category_detail: selectedSubOption?.code || subOptions?.[0]?.code || "",
    location:
      filerKey?.name || (Array.isArray(location) && location[0]?.name) || "",
    price:
      price === 0
        ? "0"
        : price?.toString()?.includes(".")
        ? price?.split(".")[0]
        : price || "",
    schedule: null,
    messages_bot: messMarket || "",
  };
  const bodyRentHouse = {
    id_category: locationss.pathname.includes("/edithouse")
      ? locationss.pathname.split("/")[2]
      : null,
    title: title || "",
    description: description || "",
    images: listImage || "",
    latitude:
      filerKey?.latitude ||
      (Array.isArray(location) && location[0]?.latitude) ||
      "",
    longitude:
      filerKey?.longitude ||
      (Array.isArray(location) && location[0]?.longitude) ||
      "",
    id_category_detail: dataDetailHouse[0]?.id || "",
    location:
      filerKey?.name || (Array.isArray(location) && location[0]?.name) || "",
    price:
      price === 0
        ? "0"
        : price?.toString()?.includes(".")
        ? price?.split(".")[0]
        : price || "",
    schedule: null,
    classify: 2,
    acreage: acreage || "",
    bedroom: bedRoom || "",
    bathroom: bathRoom || "",
    propertyType: seletedHouse?.code || subOptions?.[0]?.code || "",
    messages_bot: messMarket || "",
  };
  const bodySellVehicle = {
    id_category: locationss.pathname.includes("/editvehicle")
      ? locationss.pathname.split("/")[2]
      : null,
    title: title || "",
    description: description || "",
    images: listImage || "",
    latitude:
      filerKey?.latitude ||
      (Array.isArray(location) && location[0]?.latitude) ||
      "",
    longitude:
      filerKey?.longitude ||
      (Array.isArray(location) && location[0]?.longitude) ||
      "",
    id_category_detail: dataDetailVehicle[0]?.id || "",
    location:
      filerKey?.name || (Array.isArray(location) && location[0]?.name) || "",

      price:
      price === 0
        ? "0"
        : price?.toString()?.includes(".")
        ? price?.split(".")[0]
        : price || "",
    schedule: null,
    classify: 1,
    make: carBrand || "",
    mileage: carMileage || "",
    model: carModel || "",
    vehicleCategory: selectCate?.code || subOptions?.[0]?.code || "",
    year: selectedYear || subOptions?.[0]?.name || "",
    messages_bot: messMarket || "",
  };
  const postMarket = async () => {
    if (
      locationss.pathname === "/vehiclemarket" ||
      locationss.pathname.includes("/editvehicle")
    ) {
      if (!bodySellVehicle.title) {
        toast.error("Bạn chưa nhập tiêu đề");
        return;
      }
      if (!bodySellVehicle.description) {
        toast.error("Bạn chưa nhập mô tả");
        return;
      }
      if (!bodySellVehicle.images.length) {
        toast.error("Thêm ít nhất 1 hình ảnh");
        return;
      }
      if (!bodySellVehicle.location) {
        toast.error("Bạn chưa chọn vị trí");
        return;
      }
      if (!bodySellVehicle.make) {
        toast.error("Bạn chưa nhập hãng xe");
        return;
      }
      if (!bodySellVehicle.model) {
        toast.error("Bạn chưa nhập mẫu xe");
        return;
      }
      if (!bodySellVehicle.mileage) {
        toast.error("Bạn chưa nhập số km đã đi");
        return;
      }
      if (!bodySellVehicle.year) {
        toast.error("Bạn chưa chọn năm sản xuất");
        return;
      }
      if (!bodySellVehicle.vehicleCategory) {
        toast.error("Bạn chưa chọn loại xe");
        return;
      }
      if (
        bodySellVehicle.price === null ||
        bodySellVehicle.price === undefined ||
        bodySellVehicle.price === ""
      ) {
        toast.error("Bạn chưa nhập giá");
        return;
      }
    }
    if (
      locationss.pathname.includes("/edithouse") ||
      locationss.pathname === "/housemarket"
    ) {
      if (!bodyRentHouse.title) {
        toast.error("Bạn chưa nhập tiêu đề");
        return;
      }
      if (!bodyRentHouse.description) {
        toast.error("Bạn chưa nhập mô tả");
        return;
      }
      if (!bodyRentHouse.images.length) {
        toast.error("Thêm ít nhất 1 hình ảnh");
        return;
      }
      if (!bodyRentHouse.location) {
        toast.error("Bạn chưa chọn vị trí");
        return;
      }
      if (
        bodyRentHouse.price === null ||
        bodyRentHouse.price === undefined ||
        bodyRentHouse.price === ""
      ) {
        toast.error("Bạn chưa nhập giá");
        return;
      }

      if (!bodyRentHouse.bedroom) {
        toast.error("Bạn chưa nhập số phòng ngủ");
        return;
      }
      if (!bodyRentHouse.bathroom) {
        toast.error("Bạn chưa nhập số phòng tắm");
        return;
      }
      if (!bodyRentHouse.acreage) {
        toast.error("Bạn chưa nhập diện tích");
        return;
      }
      if (!bodyRentHouse.propertyType) {
        toast.error("Bạn chưa chọn loại nhà");
        return;
      }
    }
    if (!body.title) {
      toast.error("Bạn chưa nhập tiêu đề");
      return;
    }
    if (!body.description) {
      toast.error("Bạn chưa nhập mô tả");
      return;
    }
    if (!body.images.length) {
      toast.error("Thêm ít nhất 1 hình ảnh");
      return;
    }
    if (!body.location) {
      toast.error("Bạn chưa chọn vị trí");
      return;
    }
    if (body.price === null || body.price === undefined || body.price === "") {
      toast.error("Bạn chưa nhập giá");
      return;
    }
    // if (!body.message_bot) {
    //   toast.error("Bạn chưa nhập tin nhắn tự động trả lời");
    //   return;
    // }
    if (locationss.pathname === "/addmarket") {
      if (!body.id_category_detail) {
        toast.error("Bạn chưa chọn thể loại");
        return;
      }
    }

    try {
      const res = await axios.post(
        apiPostMarket,
        locationss.pathname === "/addmarket" ||
          locationss.pathname.includes("editmarket")
          ? body
          : locationss.pathname === "/vehiclemarket" ||
            locationss.pathname.includes("/editvehicle")
          ? bodySellVehicle
          : bodyRentHouse,
        headers
      );
      toast.success(
        dataHidden ? "Chỉnh sửa thành công" : "Thêm kịch bản thành công"
      );
      navigate("/market");
    } catch (err) {
      toast.error(err.response.data.error);
    }
  };
  const [images, setImages] = useState([
    {
      itemImageSrc: "",
      thumbnailImageSrc: "",
      title: "",
    },
  ]);
  useEffect(() => {
    setImages(
      listImage?.map((item) => ({
        itemImageSrc: item.includes("https") ? item : `${API_URL}/${item}`,
        thumbnailImageSrc: item.includes("https") ? item : `${API_URL}/${item}`,
      }))
    );
  }, [listImage]);
  const itemTemplate = (item) => {
    return (
      <img src={item?.itemImageSrc} alt={item?.alt} style={{ width: "100%" }} />
    );
  };
  const responsiveOptions = [
    {
      breakpoint: "991px",
      numVisible: 4,
    },
    {
      breakpoint: "767px",
      numVisible: 3,
    },
    {
      breakpoint: "575px",
      numVisible: 1,
    },
  ];

  const thumbnailTemplate = (item) => {
    return <img src={item?.thumbnailImageSrc} alt={item?.alt} />;
  };
  // const MapComponent = ({}) => {
  //   const mapContainerRef = useRef(null);
  //   const [zoom, setZoom] = useState(13);
  //   useEffect(() => {
  //     if (filerKey?.latitude && filerKey?.longitude) {
  //       const map = L.map(mapContainerRef.current).setView(
  //         [filerKey?.latitude, filerKey?.longitude],
  //         zoom
  //       );

  //       L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
  //         attribution:
  //           '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
  //       }).addTo(map);

  //       L.marker([filerKey?.latitude, filerKey?.longitude])
  //         .addTo(map)
  //         .bindPopup(filerKey?.name)
  //         .openPopup();
  //       map.on("zoomend", () => {
  //         setZoom(map.getZoom());
  //       });

  //       return () => map.remove();
  //     }
  //   }, [filerKey, zoom]);

  //   return (
  //     <div ref={mapContainerRef} style={{ height: "100px", width: "300px" }} />
  //   );
  // };
  const Map = ({}) => {
    if (!filerKey?.latitude || !filerKey?.longitude) {
      return;
    }
    return (
      <MapContainer
        center={[filerKey?.latitude, filerKey?.longitude]}
        zoom={11}
        style={{ height: "180px", width: "180px" }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        />
        <Marker position={[filerKey?.latitude, filerKey?.longitude]}>
          <Popup>{filerKey?.name}</Popup>
        </Marker>
      </MapContainer>
    );
  };
  const onItemChange = (event) => {
    setActiveIndex(event.index);
  };
  return (
    <div className="d-flex h-100 w-100" style={{ position: "relative" }}>
      {/* <IoIosCloseCircle
        onClick={() => navigate("/createmarket")}
        size={25}
        style={{
          position: "absolute",
          top: "0",
          right: "0",
          cursor: "pointer",
        }}
      /> */}
      <div
        style={{ borderRight: "1px solid" }}
        className="p-2 d-flex flex-column w-100 h-100 justify-content-center styles-galleria"
      >
        {activeIndex === 0 && <h3 className="text-center">Ảnh đại diện</h3>}
        <Galleria
          value={images}
          numVisible={5}
          circular
          showItemNavigators
          showItemNavigatorsOnHover
          showIndicators
          showThumbnails={false}
          item={itemTemplate}
          thumbnail={thumbnailTemplate}
          onItemChange={onItemChange}
          activeIndex={activeIndex}
        />
        {listImage?.length === 0 ? (
          <div className="d-flex w-100 h-100 justify-content-center align-items-center flex-column">
            <h4>Bản xem trước bài niêm yết</h4>
            <p
              style={{
                width: "200px",
                lineHeight: "24px",
                fontSize: "14px",
                textAlign: "center",
              }}
            >
              Trong khi tạo , bạn có thể xem trước để biết bài niêm yết sẽ hiển
              thị thế nào với mọi người trên Marketplace
            </p>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div
        className="d-flex flex-column w-100 h-100 p-2 gap-4"
        style={{ overflow: "auto" }}
      >
        <div
          className="d-flex flex-column gap-4"
          style={{
            wordWrap: "break-word",
            overflowWrap: "break-word",
            whiteSpace: "pre-wrap",
          }}
        >
          <h4>{title ? title : "Tiêu đề"}</h4>
          <h5 className="input-placeholder-large">
            {price === 0
              ? "Miễn phí"
              : price
              ? Number(price).toLocaleString("vi-VN") + "đ"
              : "Giá"}
          </h5>
          <p className="input-placeholder-large">Chi tiết</p>
          <InputTextarea
            className="input-placeholder-large"
            style={{ fontSize: "14px", fontWeight: "700" }}
            disabled
            rows={5}
            cols={30}
            value={description ? description : "Mô tả"}
          />
        </div>
        <nav className="d-flex flex-column align-items-start gap-2">
          <nav className="d-flex gap-2 align-items-center">
            <MdLocationOn size={30} />
            <p className="input-placeholder-large">{filerKey.name}</p>
          </nav>
          <RenderMap position={filerKey} />
        </nav>
        {selecAcc.name ? (
          <nav className="d-flex align-items-center gap-2">
            <img
              style={{ width: "40px", height: "40px", borderRadius: "50%" }}
              src={selecAcc.avatar}
            />
            <p style={{ fontSize: "14px", fontWeight: "700" }}>
              {selecAcc.name}
            </p>
          </nav>
        ) : (
          <></>
        )}
        <nav
          style={{
            position: "absolute",
            bottom: "10px",
            right: "10px",
            display: "flex",
            gap: "0.5rem",
            zIndex: "1000",
          }}
        >
          <Button
            className="custom-button"
            onClick={postMarket}
            style={{
              width: "max-content",
            }}
          >
            {dataHidden || locationss.pathname.includes("/edit")
              ? "Chỉnh sửa kịch bản"
              : "Tạo kịch bản"}
          </Button>

          <Button
            className="custom-button"
            style={{
              width: "max-content",
            }}
            onClick={() => window.history.back()}
            text
          >
            Quay lại
          </Button>
        </nav>
      </div>
    </div>
  );
}
