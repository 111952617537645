import React, { useEffect } from "react";
import Navigate from "../container/Navigate";
import { Table } from "react-bootstrap";
import { useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_URL, API_ROUTES } from "../../api";
import { Footer } from "./Footer";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { useHeaders } from "../../const/headers";
import { updateTitle } from "../../const/updateTitle";
import { FaFilter } from "react-icons/fa6";
import { CreateManualDebug } from "../container/manualDebug/CreateManualDebug";
import { TesterConfirm } from "../container/manualDebug/TesterConfirm";
import { DevConfirm } from "../container/manualDebug/DevConfirm";
import { Image } from "primereact/image";
import { Paginator } from "primereact/paginator";
import { getAxiosDebugManual } from "../../const/getAxios";
import { Dropdown } from "primereact/dropdown";
import { useUser } from "../../contexts/UserContext";
export const DebugManual = () => {
  updateTitle("Chốt Sale - Debug Thủ Công");
  const headers = useHeaders();
  const showAllManualDebug = API_URL + API_ROUTES.SHOW_MANUAL_DEBUG;
  const { userInfo } = useUser();
  const { t } = useTranslation();
  const [listEmpl, setListEmpl] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [hiddenTable, setHiddenTable] = useState(false);
  const [itemConfirm, setItemConfirm] = useState([]);
  const [filterAll, setFilterAll] = useState([]);
  const [filterOrSTT, setFilterOrSTT] = useState(false);
  const [filterOrTime, setFilterOrTime] = useState(false);
  const [filterOrStatus, setFilterOrStatus] = useState(false);
  const [allSelected, setAllSelected] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [modalCreate, setModalCreate] = useState(false);
  const [modalTester, setModalTester] = useState(false);
  const [modalDev, setModalDev] = useState(false);
  const [filterStatus, setFilterStatus] = useState(null);
  const cities = [
    { name: "Lỗi", code: "0" },
    { name: "Hoàn thành", code: "3" },
    { name: "Đã giải quyết", code: "2" },
    { name: "Tất cả", code: null },
  ];
  const [first, setFirst] = useState(0);
  const rowsPerPageOptions = [100, 300, 500, 1000, 2000];
  const [totalPage, setTotalPage] = useState(0);
  const [getTotal, setGetTotal] = useState([]);
  const [rows, setRows] = useState(100);
  const onPageChange = (event) => {
    setTotalPage(event.page);
    setFirst(event.first);
    setRows(event.rows);
  };
  useEffect(() => {
    getAxiosDebugManual(
      showAllManualDebug,
      setListEmpl,
      setGetTotal,
      rows,
      totalPage + 1,
      filterStatus?.code
    );
  }, [totalPage, rows, filterStatus]);
  useEffect(() => {
    getDataEmpl();
  }, [refresh, modalDev]);
  const getDataEmpl = async () => {
    try {
      const response = await axios.get(
        `${showAllManualDebug}?number_per_page=10&status=${
          filterStatus?.code === undefined ? null : filterStatus?.code
        }`,
        headers
      );
      setListEmpl(response.data.results);
      setFilterAll(response.data.results);
    } catch (err) {}
  };
  function formatDateTime(dateString) {
    const date = new Date(dateString);
    const pad = (num) => String(num).padStart(2, "0");
    const timePart = `${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(
      date.getSeconds()
    )}`;
    const datePart = `${pad(date.getDate())}-${pad(
      date.getMonth() + 1
    )}-${date.getFullYear()}`;

    return `${timePart} - ${datePart}`;
  }
  useEffect(() => {
    let sortedList = [...listEmpl];
    if (filterOrSTT) {
      sortedList.sort((a, b) => a.stt - b.stt);
    } else {
      sortedList.sort((a, b) => b.stt - a.stt);
    }
    setFilterAll(sortedList);
  }, [filterOrSTT, listEmpl]);
  useEffect(() => {
    if (filterOrTime) {
      setFilterAll(
        listEmpl?.sort((a, b) => new Date(a.time) - new Date(b.time))
      );
    } else {
      setFilterAll(
        listEmpl?.sort((a, b) => new Date(b.time) - new Date(a.time))
      );
    }
  }, [filterOrTime]);
  useEffect(() => {
    if (filterOrStatus) {
      setFilterAll(listEmpl.sort((a, b) => b.status - a.status));
    } else {
      setFilterAll(listEmpl.sort((a, b) => a.status - b.status));
    }
  }, [filterOrStatus]);
  const handleSelectAll = () => {
    if (allSelected) {
      setSelectedItems([]);
    } else {
      setSelectedItems(filterAll.map((item) => item.id));
    }
    setAllSelected(!allSelected);
  };
  const handleSelectItem = (id) => {
    setSelectedItems((prevSelectedItems) =>
      prevSelectedItems.includes(id)
        ? prevSelectedItems.filter((item) => item !== id)
        : [...prevSelectedItems, id]
    );
  };
  const getStatusStyle = (confirm) => {
    switch (confirm) {
      case 0:
        return { color: "red", text: "Lỗi" };
      case 1:
        return { color: "red", text: "Mở lại" };
      case 2:
        return { color: "green", text: "Đã giải quyết" };
      case 3:
        return { color: "gray", text: "Hoàn thành" };
      default:
        return { color: "blue", text: "Đóng" };
    }
  };
  return (
    <div className="d-flex flex-column " style={{ height: "100vh" }}>
      <div className="notifica-container">
        <ToastContainer />
        <div className="navigate-container">
          <Navigate />
        </div>
        <div className="d-flex h-100 w-100">
          <div
            className="overflow-auto h-100 d-flex flex-column"
            style={{
              width: "100%",
              borderRight: "0.5px solid",
              borderLeft: "0.5px solid",
            }}
          >
            <div
              id="heigt-top"
              style={{
                display: "flex",
                alignItems: "center",
                borderBottom: "1px solid",
              }}
              className="thead-fixed justify-content-between"
            >
              <h3
                className="manage-domain-respon"
                style={{
                  padding: "20px",
                  margin: "0",
                  fontSize: "20px",
                  fontWeight: "700",
                  color: "#545454",
                }}
              >
                Lỗi
              </h3>
              <nav className="d-flex gap-2">
                <div className=" flex justify-content-center">
                  <Dropdown
                    value={filterStatus}
                    onChange={(e) => setFilterStatus(e.value)}
                    options={cities}
                    optionLabel="name"
                    placeholder="Trạng thái"
                    className="w-full md:w-14rem"
                    style={{ borderRadius: "0" }}
                  />
                </div>
                <Button
                  className=" button-blue"
                  label="Thêm"
                  onClick={() => setModalCreate(true)}
                />
              </nav>
            </div>

            <div className="overflow-auto h-100 ">
              <Table striped bordered hover>
                <thead className="thead-fixed">
                  <tr style={{ borderTop: "0" }}>
                    <th style={{ width: "30px" }}>
                      <input
                        type="checkbox"
                        checked={allSelected}
                        onChange={handleSelectAll}
                      />
                    </th>
                    <th
                      style={{ width: "70px" }}
                      onClick={() => setFilterOrSTT(!filterOrSTT)}
                    >
                      STT
                      <FaFilter
                        style={{ marginLeft: "4px", cursor: "pointer" }}
                      />
                    </th>
                    <th style={{ width: "60px" }}>ID Bug</th>
                    <th style={{ width: "120px" }}>Tiêu đề</th>
                    <th>Nội dung</th>
                    <th>Tester Ghi chú</th>
                    <th>Dev Ghi chú</th>
                    <th style={{ width: "120px" }}>Hình ảnh</th>
                    <th
                      style={{ width: "120px" }}
                      onClick={() => setFilterOrStatus(!filterOrStatus)}
                    >
                      Trạng thái
                      <FaFilter
                        style={{ marginLeft: "4px", cursor: "pointer" }}
                      />
                    </th>
                    <th
                      style={{ width: "120px" }}
                      onClick={() => setFilterOrTime(!filterOrTime)}
                    >
                      Thời gian <FaFilter style={{ cursor: "pointer" }} />
                    </th>
                    <th style={{ width: "120px" }}>Tùy chọn</th>
                  </tr>
                </thead>
                <tbody>
                  {filterAll?.map((item, index) => {
                    const { color, text } = getStatusStyle(item.status);
                    return (
                      <tr
                        key={index}
                        style={{ cursor: "pointer" }}
                        onClick={() => setHiddenTable(!hiddenTable)}
                      >
                        <th>
                          <input
                            type="checkbox"
                            checked={selectedItems.includes(item.id)}
                            onChange={() => handleSelectItem(item.id)}
                          />
                        </th>
                        <td>{index + 1}</td>
                        <td>{item.id}</td>
                        <td>{item.title}</td>
                        <td>{item.description}</td>
                        <td>{item.tester_note}</td>
                        <td>{item.developer_note}</td>
                        <td>
                          <Image
                            src={`${API_URL}${item?.image}`}
                            alt="Image"
                            preview
                            className="custom-img-style"
                            style={{
                              width: "100px",
                              height: "100px",
                              display:
                                item?.image === "/media/null"
                                  ? "none"
                                  : "block",
                            }}
                          />
                        </td>
                        <td
                          style={{
                            color: color,
                          }}
                        >
                          {text}
                        </td>
                        <td>{formatDateTime(item.time)}</td>
                        <td>
                          <Button
                            onClick={() => {
                              setModalTester(true);
                              setItemConfirm(item);
                            }}
                            label="Tester xác nhận"
                            severity="info"
                            style={{
                              padding: "6px 12px",
                              display: item.confirm === true ? "none" : "",
                            }}
                            className="mr-2 mb-2  button-blue radius"
                          />
                          <Button
                            onClick={() => {
                              setModalDev(true);
                              setItemConfirm(item);
                            }}
                            label="Dev xác nhận"
                            severity="info"
                            style={{
                              padding: "6px 12px",
                              display:
                                item.confirm === false ||
                                userInfo.is_admin ||
                                userInfo.is_developer
                                  ? ""
                                  : "none",
                            }}
                            className="mr-2 mb-2  button-blue radius"
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
            <Paginator
              className="pagination-markerting"
              first={first}
              rows={rows}
              totalRecords={getTotal?.count}
              onPageChange={onPageChange}
              rowsPerPageOptions={rowsPerPageOptions}
            />
          </div>
        </div>
        <CreateManualDebug
          modalCreate={modalCreate}
          setModalCreate={setModalCreate}
          setRefresh={setRefresh}
          refresh={refresh}
        />
        <TesterConfirm
          modalTester={modalTester}
          setModalTester={setModalTester}
          itemConfirm={itemConfirm}
          setRefresh={setRefresh}
          refresh={refresh}
        />
        <DevConfirm
          modalDev={modalDev}
          setModalDev={setModalDev}
          itemConfirm={itemConfirm}
          setRefresh={setRefresh}
          refresh={refresh}
        />
      </div>
      <Footer />
    </div>
  );
};
