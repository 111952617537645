import React, { useEffect, useRef } from "react";
import Navigate from "../container/Navigate";
import { useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_URL, API_ROUTES, API_ROUTES_NTD, WS_URL } from "../../api";
import { Footer } from "./Footer";
import { Button } from "primereact/button";
import { useHeaders } from "../../const/headers";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { updateTitle } from "../../const/updateTitle";
import { useAuth } from "../../AuthContext";
import { getAxios } from "../../const/getAxios";
import { ModalDetailsGroup } from "../container/group/ModalDetailsGroup";
import { ModalCreateScriptGroup } from "../container/group/ModalCreateScriptGroup";
import { ScriptGroup } from "../container/group/ScriptGroup";
import { ResultGroup } from "../container/group/ResultGroup";
import { ManageScriptGroup } from "../container/group/ManageScriptGroup";
import { ModalCreateScriptJoinGroup } from "../container/group/ModalCreateScriptJoinGroup";
import { getAxiosResults, getAxiosMarket } from "../../const/getAxios";
import { ModalInviteFriendJoinGroup } from "../container/group/ModalInviteFriendJoinGroup";
import { Sidebar } from "primereact/sidebar";
import { useUser } from "../../contexts/UserContext";
export const ManageGroup = () => {
  updateTitle("Chốt Sale - Quản Lý Group");
  const headers = useHeaders();
  const { setUserLoggedIn } = useAuth();
  const [dataFb, setDataFb] = useState();
  const { userInfo } = useUser();
  const socketRef = useRef(null);
  const access = localStorage.getItem("access");
  const [renderKey, setRenderKey] = useState(Date.now());
  const getDataFb = API_URL + API_ROUTES.GET_ACC_FB;
  const checkListGroup = API_URL + API_ROUTES_NTD.GET_ALL_LIST_GROUP;
  const getCateGroup = API_URL + API_ROUTES_NTD.GET_ALL_CATEGORY_GROUP;
  const startMarketing = API_URL + API_ROUTES_NTD.START_GROUP_CATEGORY;
  const stopMarketing = API_URL + API_ROUTES_NTD.STOP_GROUP_CATEGORY;
  const deleteResultGroup =
    API_URL + API_ROUTES_NTD.DELETE_RESULTS_GROUP_CATEGORY;
  const allResults = API_URL + API_ROUTES_NTD.GET_RESULTS_GROUP_CATEGORY;
  const resultScanGr = API_URL + API_ROUTES_NTD.GET_RESULTS_GROUP;
  const copyCate = API_URL + API_ROUTES_NTD.COPY_CATEGORY_GROUP;
  const [refresh, setRefresh] = useState(true);
  const [allFriend, setAllFriend] = useState([]);
  const [checkboxes, setCheckboxes] = useState({});
  const [selectedItems, setSelectedItems] = useState([]);
  const [statusScan, setStatusScan] = useState(false);
  const [openCategory, setOpenCategory] = useState(false);
  const [openCategory2, setOpenCategory2] = useState(false);
  const [openCategory3, setOpenCategory3] = useState(false);
  const [allCateClient, setAllCateClient] = useState([]);
  const [listFr, setListFr] = useState([]);
  const [listScript, setListScript] = useState([]);
  const [managerScript, setManagerScript] = useState(false);
  const [scriptClient, setScriptClient] = useState([]);
  const isInitialRender = useRef(true);
  const [openDetails, setOpenDetails] = useState(false);
  const [itemResults, setItemResults] = useState([]);
  const [listResults, setListResults] = useState([]);
  const [wsResults, setWsResults] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [getTotal, setGetTotal] = useState([]);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(100);
  const [taskId, setTaskId] = useState([]);
  const [dataScanRs, setDataScanRs] = useState("");
  const [statusRun, setStatusRun] = useState(false);
  const intervalRef = useRef();
  const [openSidebar, setOpenSidebar] = useState(false);
  const [itemCate, setItemCate] = useState([]);

  const scanListFr = async (item) => {
    if (!item.id) {
      alert("Bạn vui lòng chọn một tài khoản");
    } else {
      const data = [item.id];
      const body = { id_accounts: data };
      try {
        const res = await axios.post(checkListGroup, body, headers);
        setTaskId(res.data.id_task);
        setStatusRun(true);
        axios
          .get(getDataFb, headers)
          .then(function (response) {
            setDataFb(response.data);
          })
          .catch(function (error) {
            if (error?.response?.status === 401) {
              setUserLoggedIn(false);
            }
          });
      } catch (err) {}
    }
  };
  const checkResultsClient = async () => {
    if (taskId?.length === 0) {
      return;
    }
    const body = { id_task: taskId };
    try {
      const res = await axios.post(resultScanGr, body, headers);
      setDataScanRs(res.data);
    } catch (err) {}
    setStatusScan(false);
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      checkResultsClient();
    }, 3000);

    if (dataScanRs?.status === "SUCCESS" || dataScanRs?.status === "FAILURE") {
      setStatusRun(false);
      setTaskId([]);
      setDataScanRs([]);
      setRefresh(!refresh);
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);
  }, [taskId, dataScanRs]);

  useEffect(() => {
    axios
      .get(getDataFb, headers)
      .then(function (response) {
        setDataFb(response.data);
      })
      .catch(function (error) {
        if (error?.response?.status === 401) {
          setUserLoggedIn(false);
        }
      });
  }, []);
  useEffect(() => {
    getAxios(getCateGroup, setAllCateClient);
  }, [refresh]);

  const deleteResults = async () => {
    const body = { id_results: listResults?.map((item) => item?.id) };
    try {
      const res = await axios.post(deleteResultGroup, body, headers);
      getAxiosResults(
        allResults,
        setWsResults,
        setGetTotal,
        rows,
        totalPage + 1
      );
      toast.success("Xoá thành công");
      setListResults([]);
    } catch (err) {}
  };
  const copyResults = () => {
    const copyText = listFr?.map((item) => item?.group_uid).join("\n");
    navigator.clipboard.writeText(copyText);
    toast.success("Sao chép thành công");
  };

  const startMarketingClient = async (type) => {
    if (listScript?.length === 0) {
      alert("Chọn ít nhất 1 kịch bản để chạy ");
    } else {
      const body = {
        id_categories: listScript?.map((item) => item.id),
        type: type,
      };
      try {
        const res = await axios.post(startMarketing, body, headers);
        toast.success("Chạy chiến dịch thành công");
      } catch (err) {
        toast.error(err.response.data.error);
      }
    }
  };
  const stopMarketingClient = async () => {
    if (listScript?.length === 0) {
      alert("Chọn ít nhất 1 kịch bản để dừng ");
    } else {
      const body = {
        id_categories: listScript?.map((item) => item.id),
      };
      try {
        const res = await axios.post(stopMarketing, body, headers);
        setRefresh(!refresh);
        toast.success("Dừng chiến dịch thành công");
      } catch (err) {
        toast.error("Dừng chiến dịch thất bại");
      }
    }
  };
  const handleCopyCate = async (item) => {
    const userName = prompt("Nhập tên kịch bản mới");
    if (!userName) return alert("Vui lòng nhập tên kịch bản mới");
    const body = { id_category: item.id, name: userName };
    try {
      await axios.post(copyCate, body, headers);
      setRefresh(!refresh);
      setListScript([]);
    } catch (err) {}
  };
  useEffect(() => {
    const client = new W3CWebSocket(
      `wss://${WS_URL}/ws/group/${userInfo?.id}/?token=${access}`
    );
    socketRef.current = client;
    client.onerror = function () {
      // console.error("Connection Error");
    };
    client.onopen = function () {};
    client.onmessage = function (message) {
      const parsedData = JSON.parse(message.data);
      if (parsedData.category !== undefined) {
        const newCategory = parsedData.category;
        setAllCateClient((prevCate) => {
          const index = prevCate.findIndex((cat) => cat.id === newCategory.id);
          if (index !== -1) {
            return prevCate.map((cat) =>
              cat.id === newCategory.id ? newCategory : cat
            );
          } else {
            return [...prevCate, newCategory];
          }
        });
      }
      if (parsedData.result !== undefined) {
        setRenderKey(Date.now());
      }
    };
    return () => {
      if (client) {
        client.close();
      }
    };
  }, []);
  return (
    <div className="d-flex flex-column " style={{ height: "100vh" }}>
      <div className="notifica-container">
        <ToastContainer />
        <div className="navigate-container">
          <Navigate />
        </div>
        <div className="d-flex h-100 w-100 " style={{ overflow: "auto" }}>
          <div
            className="overflow-auto h-100 d-flex flex-column"
            style={{
              width: "100%",
              borderRight: "0.5px solid",
              borderLeft: "0.5px solid",
            }}
          >
            <div
              className="d-flex gap-2 w-100 justify-content-end pt-2 pl-2 pr-2"
              id="lisstSeclect"
            >
              <Button
                severity="danger"
                style={{
                  width: "180px",
                  display: listResults.length === 0 ? "none" : "block",
                }}
                className=""
                onClick={deleteResults}
              >
                Xoá kết quả
              </Button>
              <Button
                onClick={() => setOpenCategory2(true)}
                className="d-flex justify-content-center button-blue"
                style={{
                  width: "150px",
                }}
              >
                Tham gia nhóm
              </Button>
              <Button
                onClick={() => setOpenCategory3(true)}
                className="d-flex justify-content-center button-blue"
                style={{
                  width: "170px",
                }}
              >
                Mời bạn bè tham gia nhóm
              </Button>
              <Button
                onClick={() => setOpenCategory(true)}
                className="d-flex justify-content-center button-blue"
                style={{
                  width: "150px",
                }}
              >
                Đăng bài vào nhóm
              </Button>

              <Button
                onClick={() => startMarketingClient("new")}
                className="d-flex justify-content-center button-blue"
                style={{
                  width: "100px",
                }}
                disabled={statusScan}
              >
                Chạy
              </Button>
              <Button
                onClick={() => startMarketingClient("")}
                className="d-flex justify-content-center button-blue"
                style={{
                  width: "100px",
                }}
                disabled={statusScan}
              >
                Chạy Tiếp
              </Button>
              <Button
                onClick={() => setOpenSidebar(true)}
                className="d-flex justify-content-center button-blue"
                style={{
                  width: "100px",
                }}
              >
                Kết quả
              </Button>
              <Button
                severity="danger"
                className="d-flex justify-content-center button-red"
                style={{
                  width: "100px",
                }}
                onClick={stopMarketingClient}
              >
                Dừng
              </Button>
            </div>
            <div
              className="d-flex flex-column  gap-2 h-100 m-2"
              style={{ overflow: "hidden" }}
            >
              <div className="d-flex w gap-2" style={{ height: "100%" }}>
                <ScriptGroup
                  setItemCate={setItemCate}
                  setManagerScript={setManagerScript}
                  handleCopyCate={handleCopyCate}
                  listScript={listScript}
                  setListScript={setListScript}
                  allCateClient={allCateClient}
                  setRefresh={setRefresh}
                  refresh={refresh}
                />
              </div>
              <Sidebar
                visible={openSidebar}
                onHide={() => setOpenSidebar(null)}
                position="bottom"
                className="sidebar-market"
              >
                <div
                  className="d-flex flex-column w-100"
                  style={{ height: "100%" }}
                >
                  <Button
                    severity="danger"
                    style={{
                      width: "200px",
                      display: listResults.length === 0 ? "none" : "block",
                      fontSize: "12px", // Set font size here
                    }}
                    className="justify-content-center button-red"
                    onClick={deleteResults}
                  >
                    Xoá kết quả
                  </Button>
                  <div className="d-flex" style={{ height: "100%" }}>
                    <ResultGroup
                      renderKey={renderKey}
                      isInitialRender={isInitialRender}
                      rows={rows}
                      setRows={setRows}
                      totalPage={totalPage}
                      setTotalPage={setTotalPage}
                      getTotal={getTotal}
                      setGetTotal={setGetTotal}
                      allCateClient={allCateClient}
                      setAllCateClient={setAllCateClient}
                      listResults={listResults}
                      setListResults={setListResults}
                      setOpenDetails={setOpenDetails}
                      openDetails={openDetails}
                      setItemResults={setItemResults}
                      wsResults={wsResults}
                      setWsResults={setWsResults}
                    />
                  </div>
                </div>
              </Sidebar>
            </div>
          </div>
        </div>
        <ModalCreateScriptGroup
          openCategory={openCategory}
          setOpenCategory={setOpenCategory}
          setRefresh={setRefresh}
          refresh={refresh}
        />
        <ModalCreateScriptJoinGroup
          selectedItems={selectedItems}
          openCategory={openCategory2}
          setOpenCategory={setOpenCategory2}
          setRefresh={setRefresh}
          dataFb={dataFb}
          refresh={refresh}
          wsResults={wsResults}
        />
        <ModalInviteFriendJoinGroup
          dataFb={dataFb}
          setDataFb={setDataFb}
          selectedItems={selectedItems}
          openCategory={openCategory3}
          setOpenCategory={setOpenCategory3}
          setRefresh={setRefresh}
          refresh={refresh}
          getDataFb={getDataFb}
        />

        <ManageScriptGroup
          copyResults={copyResults}
          allResults={allResults}
          listScript={listScript}
          setListScript={setListScript}
          getTotal={getTotal}
          rows={rows}
          toast={toast}
          setListFr={setListFr}
          allFriend={allFriend}
          setAllFriend={setAllFriend}
          statusRun={statusRun}
          scanListFr={scanListFr}
          listFr={listFr}
          dataFb={dataFb}
          setRefresh={setRefresh}
          refresh={refresh}
          scriptClient={scriptClient}
          managerScript={managerScript}
          setManagerScript={setManagerScript}
          setTotalPage={setTotalPage}
          totalPage={totalPage}
          itemCate={itemCate}
          first={first}
          setFirst={setFirst}
          setRows={setRows}
          setGetTotal={setGetTotal}
        />
        <ModalDetailsGroup
          openDetails={openDetails}
          setOpenDetails={setOpenDetails}
          itemResults={itemResults}
        />
      </div>
      <Footer />
    </div>
  );
};
