import { ToastContainer } from "react-toastify";
import { Chart } from "primereact/chart";
import { API_URL, API_ROUTES } from "../../api";
import axios from "axios";
import { useHeaders } from "../../const/headers";
import Navigate from "../container/Navigate";
import { useState, useEffect } from "react";
import { Calendar } from "primereact/calendar";
import { Footer } from "./Footer";
import { updateTitle } from "../../const/updateTitle";
import { getAxiosStatis } from "../../const/getAxios";
import "./Statistics.css";
const Statistics = () => {
  updateTitle("Chốt Sale - Thống kê");
  const [chartData, setChartData] = useState({});
  const [chartDataFr, setChartDataFr] = useState({});
  const [chartDataPost, setChartDataPost] = useState({});
  const [chartDataPostGroup, setChartDataPostGroup] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const [chartOptionsFr, setChartOptionsFr] = useState({});
  const [chartOptionsPost, setChartOptionsPost] = useState({});
  const [chartOptionsPostGroup, setChartOptionsPostGroup] = useState({});
  const apiResultMkt = API_URL + API_ROUTES.STATUS_MARKETING;
  const apiResultFriend = API_URL + API_ROUTES.STATUS_FRIEND;
  const apiResultPost = API_URL + API_ROUTES.STATUS_POST;
  const apiResultPostGroup = API_URL + API_ROUTES.GET_STATS_GROUP;
  const apiResultJoinGroup = API_URL + API_ROUTES.STATS_JOIN_GROUP;
  const [dataStats, setDataStats] = useState([]);
  const [dataStatsFriend, setDataStatsFriend] = useState([]);
  const [dataStatsPost, setDataStatsPost] = useState([]);
  const [dataStatsPostGroup, setDataStatsPostGroup] = useState([]);
  const [dataStatsJoinGroup, setDataStatsJoinGroup] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  var startTime = new Date(startDate);
  var endTime = new Date(endDate);
  const startTimeConver = `${startTime.getFullYear()}-${
    startTime.getMonth() + 1
  }-${startTime.getDate()}`;
  const endTimeConver = `${endTime.getFullYear()}-${
    endTime.getMonth() + 1
  }-${endTime.getDate()}`;
  useEffect(() => {
    getAxiosStatis(
      apiResultJoinGroup,
      setDataStatsJoinGroup,
      startTimeConver,
      endTimeConver
    );
    getAxiosStatis(
      apiResultPostGroup,
      setDataStatsPostGroup,
      startTimeConver,
      endTimeConver
    );
    getAxiosStatis(
      apiResultPost,
      setDataStatsPost,
      startTimeConver,
      endTimeConver
    );
    getAxiosStatis(
      apiResultFriend,
      setDataStatsFriend,
      startTimeConver,
      endTimeConver
    );
    getAxiosStatis(apiResultMkt, setDataStats, startTimeConver, endTimeConver);
  }, [startDate, endDate]);
  const styles = {
    mb0ml2: {
      width: "50px",
      justifyContent: "flex-start",
    },
  };
  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue("--text-color");
    const textColorSecondary = documentStyle.getPropertyValue(
      "--text-color-secondary"
    );
    const surfaceBorder = documentStyle.getPropertyValue("--surface-border");
    const data = {
      labels: ["Kết bạn", "Bình luận", "Like", "Nhắn tin", "Chia sẻ"],
      datasets: [
        {
          label: "Thành công",
          backgroundColor: documentStyle.getPropertyValue("--blue-500"),
          borderColor: documentStyle.getPropertyValue("--blue-500"),
          data: [
            dataStats?.add_friend_success,
            dataStats?.comment_success,
            dataStats?.like_success,
            dataStats?.message_success,
            dataStats?.share_success,
          ],
        },
        {
          label: "Thất bại",
          backgroundColor: "#808080",
          borderColor: "#808080",
          data: [
            dataStats?.add_friend_fail,
            dataStats?.comment_fail,
            dataStats?.like_fail,
            dataStats?.message_fail,
            dataStats?.share_fail,
          ],
        },
      ],
    };
    const options = {
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      plugins: {
        legend: {
          labels: {
            color: textColor,
          },
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              const successData = data.datasets[0].data;
              const failData = data.datasets[1].data;
              const totalData =
                successData[context.dataIndex] + failData[context.dataIndex];
              const successCount = successData[context.dataIndex];
              const failCount = failData[context.dataIndex];
              const label = context.dataset.label || "";
              if (label === "Thành công") {
                return `${label}: ${successCount} / ${totalData}`;
              } else if (label === "Thất bại") {
                return `${label}: ${failCount} / ${totalData}`;
              }
              return `${label}: ${context.raw}`;
            },
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: textColorSecondary,
            font: {
              weight: 500,
            },
          },
          grid: {
            display: false,
            drawBorder: false,
          },
        },
        y: {
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            display: false,
            color: surfaceBorder,
            drawBorder: false,
          },
        },
      },
    };

    setChartData(data);
    setChartOptions(options);
  }, [startDate, endDate, dataStats]);

  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue("--text-color");
    const textColorSecondary = documentStyle.getPropertyValue(
      "--text-color-secondary"
    );
    const surfaceBorder = documentStyle.getPropertyValue("--surface-border");
    const dataFriend = {
      labels: ["Bình luận", "Like", "Nhắn tin"],
      datasets: [
        {
          label: "Thành công",
          backgroundColor: documentStyle.getPropertyValue("--blue-500"),
          borderColor: documentStyle.getPropertyValue("--blue-500"),
          data: [
            dataStatsFriend?.comment_success,
            dataStatsFriend?.like_success,
            dataStatsFriend?.message_success,
          ],
        },
        {
          label: "Thất bại",
          backgroundColor: "#808080", // Change this to your preferred gray color
          borderColor: "#808080",
          data: [
            dataStatsFriend?.comment_fail,
            dataStatsFriend?.like_fail,
            dataStatsFriend?.message_fail,
          ],
        },
      ],
    };
    const optionsFriend = {
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      plugins: {
        legend: {
          labels: {
            color: textColor,
          },
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              const successData = dataFriend.datasets[0].data;
              const failData = dataFriend.datasets[1].data;
              const totalData =
                successData[context.dataIndex] + failData[context.dataIndex];
              const successCount = successData[context.dataIndex];
              const failCount = failData[context.dataIndex];
              const label = context.dataset.label || "";
              if (label === "Thành công") {
                return `${label}: ${successCount} / ${totalData}`;
              } else if (label === "Thất bại") {
                return `${label}: ${failCount} / ${totalData}`;
              }
              return `${label}: ${context.raw}`;
            },
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: textColorSecondary,
            font: {
              weight: 500,
            },
          },
          grid: {
            display: false,
            drawBorder: false,
          },
        },
        y: {
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            display: false,
            color: surfaceBorder,
            drawBorder: false,
          },
        },
      },
    };

    setChartDataFr(dataFriend);
    setChartOptionsFr(optionsFriend);
  }, [startDate, endDate, dataStatsFriend]);
  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue("--text-color");
    const textColorSecondary = documentStyle.getPropertyValue(
      "--text-color-secondary"
    );
    const surfaceBorder = documentStyle.getPropertyValue("--surface-border");
    const dataPostGroup = {
      labels: ["Đăng bài", "Tham gia nhóm"],
      datasets: [
        {
          label: "Thành công",
          backgroundColor: documentStyle.getPropertyValue("--blue-500"),
          borderColor: documentStyle.getPropertyValue("--blue-500"),
          data: [
            dataStatsPostGroup.status_success,
            dataStatsJoinGroup.status_success,
          ],
        },
        {
          label: "Thất bại",
          backgroundColor: "#808080", // Change this to your preferred gray color
          borderColor: "#808080",
          data: [
            dataStatsPostGroup.status_fail,
            dataStatsJoinGroup.status_fail,
          ],
        },
      ],
    };
    const optionsPostGroup = {
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      plugins: {
        legend: {
          labels: {
            color: textColor,
          },
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              const successData = dataPostGroup.datasets[0].data;
              const failData = dataPostGroup.datasets[1].data;
              const totalData =
                successData[context.dataIndex] + failData[context.dataIndex];
              const successCount = successData[context.dataIndex];
              const failCount = failData[context.dataIndex];
              const label = context.dataset.label || "";
              if (label === "Thành công") {
                return `${label}: ${successCount} / ${totalData}`;
              } else if (label === "Thất bại") {
                return `${label}: ${failCount} / ${totalData}`;
              }
              return `${label}: ${context.raw}`;
            },
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: textColorSecondary,
            font: {
              weight: 500,
            },
          },
          grid: {
            display: false,
            drawBorder: false,
          },
        },
        y: {
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            display: false,
            color: surfaceBorder,
            drawBorder: false,
          },
        },
      },
    };

    setChartDataPostGroup(dataPostGroup);
    setChartOptionsPostGroup(optionsPostGroup);
  }, [startDate, endDate, dataStatsPostGroup, dataStatsJoinGroup]);

  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue("--text-color");
    const textColorSecondary = documentStyle.getPropertyValue(
      "--text-color-secondary"
    );
    const surfaceBorder = documentStyle.getPropertyValue("--surface-border");
    const dataPost = {
      labels: ["Đăng bài", "Đăng video", "Đăng reels", "Sao chép bài viết"],
      datasets: [
        {
          label: "Thành công",
          backgroundColor: documentStyle.getPropertyValue("--blue-500"),
          borderColor: documentStyle.getPropertyValue("--blue-500"),
          data: [
            dataStatsPost?.campaign_posts?.status_posts_success,
            dataStatsPost?.campaign_watches?.status_watches_success,
            dataStatsPost?.campaign_reels?.status_reels_success,
            dataStatsPost?.campaign_reups?.status_reups_success,
          ],
        },
        {
          label: "Thất bại",
          backgroundColor: "#808080", // Change this to your preferred gray color
          borderColor: "#808080",
          data: [
            dataStatsPost?.campaign_posts?.status_posts_fail,
            dataStatsPost?.campaign_watches?.status_watches_fail,
            dataStatsPost?.campaign_reels?.status_reels_fail,
            dataStatsPost?.campaign_reups?.status_reups_fail,
          ],
        },
      ],
    };
    const optionsPost = {
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      plugins: {
        legend: {
          labels: {
            color: textColor,
          },
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              const successData = dataPost.datasets[0].data;
              const failData = dataPost.datasets[1].data;
              const totalData =
                successData[context.dataIndex] + failData[context.dataIndex];
              const successCount = successData[context.dataIndex];
              const failCount = failData[context.dataIndex];
              const label = context.dataset.label || "";
              if (label === "Thành công") {
                return `${label}: ${successCount} / ${totalData}`;
              } else if (label === "Thất bại") {
                return `${label}: ${failCount} / ${totalData}`;
              }
              return `${label}: ${context.raw}`;
            },
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: textColorSecondary,
            font: {
              weight: 500,
            },
          },
          grid: {
            display: false,
            drawBorder: false,
          },
        },
        y: {
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            display: false,
            color: surfaceBorder,
            drawBorder: false,
          },
        },
      },
    };

    setChartDataPost(dataPost);
    setChartOptionsPost(optionsPost);
  }, [startDate, endDate, dataStatsPost]);

  return (
    <div className="d-flex flex-column " style={{ height: "100vh" }}>
      <div className="notifica-container">
        <ToastContainer />
        <div className="navigate-container">
          <Navigate />
        </div>
        <div
          style={{ backgroundColor: "fce0bd" }}
          className="dashboard w-100 d-flex flex-column h-100"
        >
          <div
            style={{ backgroundColor: "#1976d2" }}
            className="top-dash d-flex justify-content-between"
          >
            <div className="header-mobile p-3 d-flex justify-content-between w-100">
              <h4 style={{ color: "#fff" }} className="fs-2 mb-0">
                Báo cáo
              </h4>
              <div className="d-flex gap-2">
                <div className="p-inputgroup w-280 mr-25">
                  <span className="p-inputgroup-addon" style={styles.mb0ml2}>
                    <p className="mb-0 ml-2">Từ</p>
                  </span>
                  <Calendar
                    placeholder=""
                    locale="vi"
                    id="buttondisplay"
                    value={startDate}
                    onChange={(e) => setStartDate(e.value)}
                    readOnlyInput
                    disabledDates={[]}
                  />
                </div>
                <div className="p-inputgroup ">
                  <span className="p-inputgroup-addon" style={styles.mb0ml2}>
                    <p className="mb-0 ml-2">Đến</p>
                  </span>
                  <Calendar
                    placeholder=""
                    locale="vi"
                    id="buttondisplay"
                    value={endDate}
                    onChange={(e) => setEndDate(e.value)}
                    readOnlyInput
                    disabledDates={[]}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className="p-mobile d-flex flex-column w-100 gap-4"
            style={{
              maxHeight: "100%",
              overflowY: "scroll",
              backgroundColor: "#dee2e6",
              padding: "50px",
            }}
          >
            <div
              style={{ width: "100%", justifyContent: "space-around" }}
              className="chart-left d-flex gap-4 "
            >
              <div
                style={{ width: "40%", backgroundColor: "#fff" }}
                className="w-moblie d-flex flex-column card"
              >
                <div className="title">
                  <h4
                    className="w-100  p-2"
                    style={{
                      color: "#1976D2",
                    }}
                  >
                    Marketing bán hàng
                  </h4>
                </div>
                <div
                  style={{ boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2)" }}
                  className="middle-dash h-100 d-flex text-center justify-content-around w-100 "
                >
                  <div className="feature mr-4 fs-6 lh-lg">
                    <h5>Chức năng</h5>
                    <div className="text-start">
                      <p>Like:</p>
                      <p>Bình luận:</p>
                      <p>Nhắn tin:</p>
                      <p>Kết bạn:</p>
                      <p>Chia sẻ:</p>
                    </div>
                  </div>
                  <div className="result mr-5 fs-6 lh-lg text-start">
                    <h5>Kết quả</h5>
                    <p>{`${dataStats?.like_success}/${
                      dataStats?.like_success + dataStats?.like_fail
                    }`}</p>
                    <p>{`${dataStats?.comment_success}/${
                      dataStats?.comment_success + dataStats?.comment_fail
                    }`}</p>
                    <p>{`${dataStats?.message_success}/${
                      dataStats?.message_success + dataStats?.message_fail
                    }`}</p>
                    <p>{`${dataStats?.add_friend_success}/${
                      dataStats?.add_friend_success + dataStats?.add_friend_fail
                    }`}</p>
                    <p>{`${dataStats?.share_success}/${
                      dataStats?.share_success + dataStats?.share_fail
                    }`}</p>
                  </div>
                  <div className="rate mr-4 fs-6 lh-lg text-start">
                    <h5>Tỉ lệ</h5>
                    <p>
                      {dataStats?.like_success === 0 ||
                      dataStats?.like_success + dataStats?.like_fail === 0
                        ? 0
                        : (
                            (dataStats?.like_success /
                              (dataStats?.like_success +
                                dataStats?.like_fail)) *
                            100
                          ).toFixed(2)}
                      %
                    </p>
                    <p>
                      {(dataStats?.comment_success === 0 ||
                      dataStats?.comment_success + dataStats?.comment_fail === 0
                        ? 0
                        : (dataStats?.comment_success /
                            (dataStats?.comment_success +
                              dataStats?.comment_fail)) *
                          100
                      ).toFixed(2)}
                      %
                    </p>
                    <p>
                      {(dataStats?.message_success === 0 ||
                      dataStats?.message_success + dataStats?.message_fail === 0
                        ? 0
                        : (dataStats?.message_success /
                            (dataStats?.message_success +
                              dataStats?.message_fail)) *
                          100
                      ).toFixed(2)}
                      %
                    </p>
                    <p>
                      {(dataStats?.add_friend_success === 0 ||
                      dataStats?.add_friend_success +
                        dataStats?.add_friend_fail ===
                        0
                        ? 0
                        : (dataStats?.add_friend_success /
                            (dataStats?.add_friend_success +
                              dataStats?.add_friend_fail)) *
                          100
                      ).toFixed(2)}
                      %
                    </p>
                    <p>
                      {(dataStats?.share_success === 0 ||
                      dataStats?.share_success + dataStats?.share_fail === 0
                        ? 0
                        : (dataStats?.share_success /
                            (dataStats?.share_success +
                              dataStats?.share_fail)) *
                          100
                      ).toFixed(2)}
                      %
                    </p>
                  </div>
                </div>
              </div>

              <div style={{ width: "56%" }} className="bot-dash">
                <div className="card">
                  <Chart
                    type="bar"
                    style={{
                      width: "100%",
                      height: "270px",
                      marginBottom: "15px",
                    }}
                    data={chartData}
                    options={chartOptions}
                  />
                </div>
              </div>
            </div>
            <div
              style={{ width: "100%", justifyContent: "space-around" }}
              className="chart-middle d-flex gap-4"
            >
              <div
                style={{ width: "40%", backgroundColor: "#fff" }}
                className="w-moblie d-flex flex-column card"
              >
                <div className="title">
                  <h4
                    className="w-100  p-2"
                    style={{
                      color: "#1976D2",
                    }}
                  >
                    Quản lý bài viết
                  </h4>
                </div>
                <div
                  style={{ boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.14)" }}
                  className="middle-dash h-100 d-flex text-center justify-content-around w-100 "
                >
                  <div className="feature mr-4 fs-6 lh-lg text-start">
                    <h5>Chức năng</h5>
                    <div className="text-start">
                      <p>Đăng bài:</p>
                      <p>Đăng video:</p>
                      <p>Đăng reels:</p>
                      <p>Sao chép bài viết:</p>
                    </div>
                  </div>
                  <div className="result mr-5 fs-6 lh-lg text-start">
                    <h5>Kết quả</h5>
                    <p>{`${
                      dataStatsPost?.campaign_posts?.status_posts_success
                    }/${
                      dataStatsPost?.campaign_posts?.status_posts_success +
                      dataStatsPost?.campaign_posts?.status_posts_fail
                    }`}</p>
                    <p>{`${
                      dataStatsPost?.campaign_watches?.status_watches_success
                    }/${
                      dataStatsPost?.campaign_watches?.status_watches_success +
                      dataStatsPost?.campaign_watches?.status_watches_fail
                    }`}</p>
                    <p>{`${
                      dataStatsPost?.campaign_reels?.status_reels_success
                    }/${
                      dataStatsPost?.campaign_reels?.status_reels_success +
                      dataStatsPost?.campaign_reels?.status_reels_fail
                    }`}</p>
                    <p>{`${
                      dataStatsPost?.campaign_reups?.status_reups_success
                    }/${
                      dataStatsPost?.campaign_reups?.status_reups_success +
                      dataStatsPost?.campaign_reups?.status_reups_fail
                    }`}</p>
                  </div>
                  <div className="rate mr-4 fs-6 lh-lg text-start">
                    <h5>Tỉ lệ</h5>
                    <p>
                      {dataStatsPost?.campaign_posts?.status_posts_success ===
                        0 ||
                      dataStatsPost?.campaign_posts?.status_posts_success +
                        dataStatsPost?.campaign_posts?.status_posts_fail ===
                        0
                        ? 0
                        : (
                            (dataStatsPost?.campaign_posts
                              ?.status_posts_success /
                              (dataStatsPost?.campaign_posts
                                ?.status_posts_success +
                                dataStatsPost?.campaign_posts
                                  ?.status_posts_fail)) *
                            100
                          ).toFixed(2)}
                      %
                    </p>
                    <p>
                      {(dataStatsPost?.campaign_watches
                        ?.status_watches_success === 0 ||
                      dataStatsPost?.campaign_watches?.status_watches_success +
                        dataStatsPost?.campaign_watches?.status_watches_fail ===
                        0
                        ? 0
                        : (dataStatsPost?.campaign_watches
                            ?.status_watches_success /
                            (dataStatsPost?.campaign_watches
                              ?.status_watches_success +
                              dataStatsPost?.campaign_watches
                                ?.status_watches_fail)) *
                          100
                      ).toFixed(2)}
                      %
                    </p>
                    <p>
                      {(dataStatsPost?.campaign_reels?.status_reels_success ===
                        0 ||
                      dataStatsPost?.campaign_reels?.status_reels_success +
                        dataStatsPost?.campaign_reels?.status_reels_fail ===
                        0
                        ? 0
                        : (dataStatsPost?.campaign_reels?.status_reels_success /
                            (dataStatsPost?.campaign_reels
                              ?.status_reels_success +
                              dataStatsPost?.campaign_reels
                                ?.status_reels_fail)) *
                          100
                      ).toFixed(2)}
                      %
                    </p>
                    <p>
                      {(dataStatsPost?.campaign_reups?.status_reups_success ===
                        0 ||
                      dataStatsPost?.campaign_reups?.status_reups_success +
                        dataStatsPost?.campaign_reups?.status_reups_fail ===
                        0
                        ? 0
                        : (dataStatsPost?.campaign_reups?.status_reups_success /
                            (dataStatsPost?.campaign_reups
                              ?.status_reups_success +
                              dataStatsPost?.campaign_reups
                                ?.status_reups_fail)) *
                          100
                      ).toFixed(2)}
                      %
                    </p>
                  </div>
                </div>
              </div>

              <div style={{ width: "56%" }} className="bot-dash">
                <div className="card">
                  <Chart
                    type="bar"
                    style={{
                      width: "100%",
                      height: "270px",
                      marginBottom: "15px",
                    }}
                    data={chartDataPost}
                    options={chartOptionsPost}
                  />
                </div>
              </div>
            </div>
            <div
              style={{ width: "100%", justifyContent: "space-around" }}
              className="chart-right d-flex gap-4"
            >
              <div
                style={{ width: "40%", backgroundColor: "#fff" }}
                className="w-moblie d-flex flex-column card"
              >
                <div className="title ">
                  <h4
                    className="w-100 p-2"
                    style={{
                      color: "#1976D2",
                    }}
                  >
                    Quản lý khách hàng
                  </h4>
                </div>
                <div
                  style={{ boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2)" }}
                  className="middle-dash h-100 d-flex text-center justify-content-around w-100"
                >
                  <div className="feature mr-5 fs-6 lh-lg ">
                    <h5>Chức năng</h5>
                    <div className="text-start">
                      <p>Like:</p>
                      <p>Bình luận:</p>
                      <p>Nhắn tin:</p>
                    </div>
                  </div>
                  <div className="result mr-4 fs-6 lh-lg text-start">
                    <h5>Kết quả</h5>
                    <p>{`${dataStatsFriend?.like_success}/${
                      dataStatsFriend?.like_success + dataStatsFriend?.like_fail
                    }`}</p>
                    <p>{`${dataStatsFriend?.comment_success}/${
                      dataStatsFriend?.comment_success +
                      dataStatsFriend?.comment_fail
                    }`}</p>
                    <p>{`${dataStatsFriend?.message_success}/${
                      dataStatsFriend?.message_success +
                      dataStatsFriend?.message_fail
                    }`}</p>
                  </div>
                  <div className="rate mr-4 fs-6 lh-lg text-start">
                    <h5>Tỉ lệ</h5>
                    <p>
                      {dataStatsFriend?.like_success === 0 ||
                      dataStatsFriend?.like_success +
                        dataStatsFriend?.like_fail ===
                        0
                        ? 0
                        : (
                            (dataStatsFriend?.like_success /
                              (dataStatsFriend?.like_success +
                                dataStatsFriend?.like_fail)) *
                            100
                          ).toFixed(2)}
                      %
                    </p>
                    <p>
                      {dataStatsFriend?.comment_success === 0 ||
                      dataStatsFriend?.comment_success +
                        dataStatsFriend?.comment_fail ===
                        0
                        ? 0
                        : (
                            (dataStatsFriend?.comment_success /
                              (dataStatsFriend?.comment_success +
                                dataStatsFriend?.comment_fail)) *
                            100
                          ).toFixed(2)}
                      %
                    </p>
                    <p>
                      {dataStatsFriend?.message_success === 0 ||
                      dataStatsFriend?.message_success +
                        dataStatsFriend?.message_fail ===
                        0
                        ? 0
                        : (
                            (dataStatsFriend?.message_success /
                              (dataStatsFriend?.message_success +
                                dataStatsFriend?.message_fail)) *
                            100
                          ).toFixed(2)}
                      %
                    </p>
                  </div>
                </div>
              </div>
              <div style={{ width: "56%" }} className="bot-dash">
                <div className="card ">
                  <Chart
                    style={{
                      width: "100%",
                      height: "270px",
                      marginBottom: "15px",
                    }}
                    type="bar"
                    data={chartDataFr}
                    options={chartOptionsFr}
                  />
                </div>
              </div>
            </div>
            <div
              style={{ width: "100%", justifyContent: "space-around" }}
              className="chart-Post-Group d-flex gap-4"
            >
              <div
                style={{ width: "40%", backgroundColor: "#fff" }}
                className="w-moblie d-flex flex-column card"
              >
                <div className="title ">
                  <h4
                    className="w-100 p-2"
                    style={{
                      color: "#1976D2",
                    }}
                  >
                    Quản lý nhóm
                  </h4>
                </div>
                <div
                  style={{ boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2)" }}
                  className="middle-dash h-100 d-flex text-center justify-content-around w-100"
                >
                  <div className="feature mr-5 fs-6 lh-lg text-start ">
                    <h5>Chức năng</h5>
                    <div className="text-start">
                      <p>Đăng bài:</p>
                      <p>Tham gia nhóm:</p>
                    </div>
                  </div>
                  <div className="result mr-4 fs-6 lh-lg text-start">
                    <h5>Kết quả</h5>
                    <p>{`${dataStatsPostGroup.status_success}/${
                      dataStatsPostGroup.status_success +
                      dataStatsPostGroup.status_fail
                    }`}</p>
                    <p>{`${dataStatsJoinGroup.status_success}/${
                      dataStatsJoinGroup.status_success +
                      dataStatsJoinGroup.status_fail
                    }`}</p>
                  </div>
                  <div className="rate mr-4 fs-6 lh-lg text-start">
                    <h5>Tỉ lệ</h5>
                    <p>
                      {dataStatsPostGroup.status_success === 0 ||
                      dataStatsPostGroup.status_success +
                        dataStatsPostGroup.status_fail ===
                        0
                        ? 0
                        : (
                            (dataStatsPostGroup.status_success /
                              (dataStatsPostGroup.status_success +
                                dataStatsPostGroup.status_fail)) *
                            100
                          ).toFixed(2)}
                      %
                    </p>
                    <p>
                      {dataStatsJoinGroup.status_success === 0 ||
                      dataStatsJoinGroup.status_success +
                        dataStatsJoinGroup.status_fail ===
                        0
                        ? 0
                        : (
                            (dataStatsJoinGroup.status_success /
                              (dataStatsJoinGroup.status_success +
                                dataStatsJoinGroup.status_fail)) *
                            100
                          ).toFixed(2)}
                      %
                    </p>
                  </div>
                </div>
              </div>
              <div style={{ width: "56%" }} className="bot-dash">
                <div className="card ">
                  <Chart
                    style={{
                      width: "100%",
                      height: "270px",
                      marginBottom: "15px",
                    }}
                    type="bar"
                    data={chartDataPostGroup}
                    options={chartOptionsPostGroup}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Statistics;
