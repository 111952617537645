import { useState } from "react";
import axios from "axios";
import { API_URL, API_ROUTES } from "../../../api";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
export const CreateAccount = ({
  setModalShow,
  modalShow,
  toast,
  headers,
  setRefresh,
  props,
  t
}) => {
  const postFb = API_URL + API_ROUTES.ADD_ACC_FB;
  const [textareaValue, setTextareaValue] = useState("");
  const handleTextareaChange = (event) => {
    setTextareaValue(event.target.value);
  };
  const inputData = textareaValue;
  function convertData(inputData) {
    const dataArray = inputData?.split("\n");
    const result = [];
    dataArray?.forEach((data) => {
      const [UID, name, password, security_code, proxy, cookie] =
        data?.split("|");
      result?.push({
        UID: UID,
        name: name,
        cookie: proxy,
        password: password,
        proxy: security_code,
        user_agent: cookie,
      });
    });

    return result;
  }

  const result = convertData(inputData);
  const postData = async () => {
    try {
      const body = result;
      const response = await axios.post(postFb, body, headers);
      setRefresh(false);
      setTextareaValue("");
    } catch (error) {
      toast.error(error.response.data.error);
    }
    setModalShow(false);
  };
  return (
    <div className="flex justify-content-center">
      <Dialog
        header={t("Nhập dữ liệu")}
        visible={modalShow}
        style={{ width: "50vw" }}
        onHide={() => setModalShow(false)}
      >
        <div
          className="card flex flex-column flex-wrap md:flex-row gap-3"
          style={{ border: "0" }}
        >
          <InputTextarea
            placeholder=" UID | Name | Password | Proxy | Cookie | User Agent"
            style={{ height: "200px", width: "100%", whiteSpace: "nowrap" }}
            rows={3}
            value={textareaValue}
            onChange={handleTextareaChange}
          />
        </div>
        <div className="d-flex justify-content-end gap-2 mt-2">
          <Button
            className="button-blue radius"
            label={t("Lưu")}
            onClick={() => postData()}
          />
          <Button
            label={t("Thoát")}
            className="button-red radius"
            onClick={() => setModalShow(false)}
            severity="danger"
          />
        </div>
      </Dialog>
    </div>
  );
};
