import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button } from "react-bootstrap";
import { Dialog } from "primereact/dialog";
import { API_ROUTES, API_URL } from "../../../api";
import { useHeaders } from "../../../const/headers";
import { useUser } from "../../../contexts/UserContext";
import "react-toastify/dist/ReactToastify.css";
import { Calendar } from "primereact/calendar";
import moment from "moment-timezone";
import { FaCalendarAlt } from "react-icons/fa";
import { CSVLink } from "react-csv";
export const ExportCsv = ({
  setOpenCsv,
  openCsv,
  dataCsv,
  setRf,
  rf,
  listEmpl,
}) => {
  const headers = useHeaders();
  const { label, setLabel, rfLable, setRfLabel } = useUser();
  const [date, setDate] = useState(null);
  const [start, setStart] = useState(new Date());
  const [end, setEnd] = useState(new Date());
  const convertDate = (date) => {
    const formattedDate = new Date(date)?.toLocaleString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    return formattedDate;
  };
  const headersSep = [
    { label: "Tên đăng nhập", key: "username" },
    { label: "Mật khẩu", key: "raw_password" },
    { label: "Số điện thoại", key: "phone_number" },
    { label: "Mail", key: "mail" },
    { label: "Họ tên", key: "fullname" },
    { label: "Giới hạn Tài khoản", key: "account_limit" },
    { label: "Số lượng nhân viên", key: "employee_count" },
    { label: "Giới hạn nhân viên", key: "employee_limit" },
    { label: "Ngày tạo", key: "created_at" },
    { label: "Ngày hết hạn", key: "expiration_date" },
  ];
  const filteredData = filterByExpirationDate(listEmpl, date, end);
  function filterByExpirationDate(data, startDate, endDate) {
    return data.filter((item) => {
      const expirationDate = new Date(item.created_at);
      return expirationDate >= startDate && expirationDate <= endDate;
    });
  }
  const handleExportClick = (e) => {
    if (!date || !end) {
      e.preventDefault();
      alert("Vui lòng chọn ngày bắt đầu và ngày kết thúc trước khi xuất file.");
    }
  };
  return (
    <div className="flex justify-content-center">
      <Dialog
        header="Xuất file Exel"
        visible={openCsv}
        onHide={() => setOpenCsv(false)}
        style={{ width: "40vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        <div className="p-inputgroup ">
          <span className="p-inputgroup-addon">
            <FaCalendarAlt />
          </span>
          <Calendar
            placeholder="Ngày bắt đầu"
            locale="vi"
            id="buttondisplay"
            value={date}
            onChange={(e) => setDate(e.value)}
            readOnlyInput
            disabledDates={[]}
          />
        </div>
        <div className="p-inputgroup ">
          <span className="p-inputgroup-addon">
            <FaCalendarAlt />
          </span>
          <Calendar
            placeholder="Ngày kết thúc"
            locale="vi"
            id="buttondisplay"
            value={end}
            onChange={(e) => setEnd(e.value)}
            readOnlyInput
            disabledDates={[]}
          />
        </div>
        <div className="d-flex justify-content-end">
          <Button className=" button-blue radius mt-3">
            <CSVLink
              className="text-light"
              data={filteredData || listEmpl}
              headers={headersSep}
              onClick={handleExportClick}
              filename={`${convertDate(date)}-${convertDate(end)}`}
            >
              Xuất file theo thời gian
            </CSVLink>
          </Button>
        </div>
      </Dialog>
    </div>
  );
};
