import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { MdAccountCircle } from "react-icons/md";
import { MdOutlinePassword } from "react-icons/md";
import { Calendar } from "primereact/calendar";
import { FaCalendarAlt } from "react-icons/fa";
import { Button } from "primereact/button";
import moment from "moment-timezone";
import axios from "axios";
import { API_URL, API_ROUTES } from "../../../api";
import { useUser } from "../../../contexts/UserContext";
import { Password } from "primereact/password";
import { useTranslation } from "react-i18next";
import { FaPhoneAlt } from "react-icons/fa";
import { MdOutlineTimeline } from "react-icons/md";
import { MdDriveFileRenameOutline } from "react-icons/md";
import { CiViewTimeline } from "react-icons/ci";
import { useHeaders } from "../../../const/headers";
import { Dropdown } from "primereact/dropdown";
import { FaUserGroup } from "react-icons/fa6";

export const ModalEditSep = ({
  setModalEdit,
  modalEdit,
  setRefresh,
  refresh,
  getItemEdit,
  managerFilter,
}) => {
  const headers = useHeaders();
  const currentDate = moment().tz("Asia/Ho_Chi_Minh").toDate();
  const { userInfo, setUserInfo } = useUser();
  const [date, setDate] = useState(
    getItemEdit?.expiration_date?.split(" ")[0] || null
  );
  const [password, setPassword] = useState(getItemEdit?.raw_password || "");
  const [username, setUsername] = useState(getItemEdit?.username || "");
  const [fullName, setFullName] = useState(getItemEdit?.fullname || "");
  const [phone, setPhone] = useState(getItemEdit?.phone_number || "");
  const [limitLive, setLimitLive] = useState(
    getItemEdit?.live_stream_limit || ""
  );
  const [permission, setPermission] = useState("");

  const [eplNum, setEplNum] = useState(getItemEdit?.employee_limit || "");
  const [eplPage, setEplPage] = useState(getItemEdit?.account_limit || "");
  const editAcc = API_URL + API_ROUTES.EDIT_ACCOUNT_MANAGER;
  const { t } = useTranslation();
  const permissionsList = [
    { name: "Quản trị viên", value: "is_admin" },
    { name: "Khách hàng", value: "is_manager" },
    { name: "Lập trình viên", value: "is_developer" },
    { name: "Chăm sóc khách hàng", value: "is_saler" },
  ];
  const onChangePass = (e) => {
    setPassword(e.target.value);
  };
  const onChangeFullName = (e) => {
    setFullName(e.target.value);
  };
  const onChangePhone = (e) => {
    setPhone(e.target.value);
  };
  const onChangeUser = (e) => {
    setUsername(e.target.value);
  };
  const onchangeDate = (e) => {
    setDate(e.target.value);
  };
  const onchangeLimit = (e) => {
    setLimitLive(e.target.value);
  };
  const onchangeEpl = (e) => {
    setEplNum(e.target.value);
  };
  const onchangePage = (e) => {
    setEplPage(e.target.value);
  };
  const selectedPermissionTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <div>{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };
  useEffect(() => {
    setDate(
      `${getItemEdit?.expiration_date?.split(" ")[0]?.split("-")[2]}-${
        getItemEdit?.expiration_date?.split(" ")[0]?.split("-")[1]
      }-${getItemEdit?.expiration_date?.split(" ")[0]?.split("-")[0]}`
    );
    setUsername(getItemEdit?.username);
    setPassword(getItemEdit?.raw_password);
    setFullName(getItemEdit?.fullname);
    setPhone(getItemEdit?.phone_number);
    setLimitLive(getItemEdit?.live_stream_limit);
    setEplNum(getItemEdit?.employee_limit);
    setEplPage(getItemEdit?.account_limit);
    setPermission(
      (permissionsList?.filter(
        (item) => item.value === getItemEdit?.permission
      ))[0]?.value
    );
  }, [getItemEdit]);
  const editManager = async () => {
    const body = {
      id_manager: getItemEdit?.id,
      username: username,
      password: password,
      fullname: fullName,
      phone_number: phone,
      expiration_date: `${date?.split("-")[2]}-${date?.split("-")[1]}-${
        date?.split("-")[0]
      } 23:59:59`,
      live_stream_limit: Number(limitLive),
      account_limit: Number(eplPage),
      permission: permission,
    };
    setRefresh(!refresh);
    setModalEdit(false);
    try {
      const response = await axios.post(editAcc, body, headers);
    } catch (err) {}
  };
  return (
    <div className=" flex justify-content-center">
      <Dialog
        header="Chỉnh sửa tài khoản"
        visible={modalEdit}
        style={{ width: "50vw" }}
        onHide={() => setModalEdit(false)}
      >
        <div
          className="card flex flex-column flex-wrap md:flex-row gap-3"
          style={{ border: "0" }}
        >
          <div className="p-inputgroup ">
            <span
              className=" d-flex justify-content-start p-inputgroup-addon"
              style={{ width: "220px" }}
            >
              <MdAccountCircle />
              <p className="mb-0 ml-2">Tên đăng nhập</p>
            </span>
            <InputText
              onChange={(e) => onChangeUser(e)}
              placeholder="Tên đăng nhập"
              value={username}
            />
          </div>
          <div className="p-inputgroup ">
            <span
              className=" d-flex justify-content-start p-inputgroup-addon"
              style={{ width: "220px" }}
            >
              <MdOutlinePassword />
              <p className="mb-0 ml-2">Mật khẩu</p>
            </span>
            <Password
              toggleMask
              feedback={t("")}
              promptLabel={t("")}
              onChange={(e) => onChangePass(e)}
              placeholder="Mật khẩu đăng nhập"
              value={password}
            />
          </div>
          <div className="p-inputgroup ">
            <span
              className=" d-flex justify-content-start p-inputgroup-addon"
              style={{ width: "220px" }}
            >
              <MdDriveFileRenameOutline />
              <p className="mb-0 ml-2">Họ tên</p>
            </span>
            <InputText
              onChange={(e) => onChangeFullName(e)}
              placeholder="Họ Tên"
              value={fullName}
            />
          </div>
          <div className="p-inputgroup ">
            <span
              className=" d-flex justify-content-start p-inputgroup-addon"
              style={{ width: "220px" }}
            >
              <FaPhoneAlt />
              <p className="mb-0 ml-2">Số điện thoại</p>
            </span>
            <InputText
              onChange={(e) => onChangePhone(e)}
              placeholder="Số điện thoại"
              keyfilter={/[0-9]/}
              value={phone}
            />
          </div>
          <div className="p-inputgroup ">
            <span
              className=" d-flex justify-content-start p-inputgroup-addon"
              style={{ width: "220px" }}
            >
              <FaUserGroup />
              <p className="mb-0 ml-2">Phân quyền</p>
            </span>
            <Dropdown
              options={permissionsList}
              optionLabel="name"
              optionValue="value"
              value={permission}
              onChange={(e) => setPermission(e.value)}
              placeholder="Phân quyền"
              valueTemplate={selectedPermissionTemplate}
            />
          </div>
          <div className="p-inputgroup ">
            <span
              className=" d-flex justify-content-start p-inputgroup-addon"
              style={{ width: "220px" }}
            >
              <MdOutlineTimeline />
              <p className="mb-0 ml-2">Số lượng nhân viên</p>
            </span>
            <InputText
              value={eplNum}
              onChange={(e) => onchangeEpl(e)}
              placeholder="Số lượng nhân viên"
              type="number"
              min={0}
            />
          </div>
          <div className="p-inputgroup ">
            <span
              className=" d-flex justify-content-start p-inputgroup-addon"
              style={{ width: "220px" }}
            >
              <MdOutlineTimeline />
              <p className="mb-0 ml-2">Số lượng tài khoản</p>
            </span>
            <InputText
              value={eplPage}
              onChange={(e) => onchangePage(e)}
              placeholder="Số lượng tài khoản"
              type="number"
              min={0}
            />
          </div>
          <div className="p-inputgroup ">
            <span
              className=" d-flex justify-content-start p-inputgroup-addon"
              style={{ width: "220px" }}
            >
              <CiViewTimeline />
              <p className="mb-0 ml-2">Hạn sử dụng</p>
            </span>
            <InputText
              value={date}
              onChange={(e) => onchangeDate(e)}
              placeholder="Hạn sử dụng: Ví dụ 25-12-2023"
              type="text"
            />
          </div>
        </div>
        <div className="d-flex justify-content-end gap-2 mt-2">
          {getItemEdit?.is_manager === true ? (
            <Button
              className=" button-blue radius"
              label="Sửa"
              onClick={() => editManager()}
            />
          ) : (
            <></>
          )}
          <Button
            className=" button-red radius"
            label="Hủy"
            onClick={() => setModalEdit(false)}
            severity="danger"
          />
        </div>
      </Dialog>
    </div>
  );
};
