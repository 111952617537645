import React, { useEffect } from "react";
import Navigate from "../container/Navigate";
import { Table } from "react-bootstrap";
import { Password } from "primereact/password";
import { TbPasswordMobilePhone } from "react-icons/tb";
import { useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_URL, API_ROUTES } from "../../api";
import { Footer } from "./Footer";
import { useUser } from "../../contexts/UserContext";
import { ManageEmployee } from "../container/manageEmployee/ManageEmployee";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { useHeaders } from "../../const/headers";
import { Editemployee } from "../container/manageEmployee/EditEmployee";
import { PushPage } from "../container/manageEmployee/PushPage";
import { InputGroup } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { updateTitle } from "../../const/updateTitle";
export const ManageEmplo = () => {
  updateTitle("Chốt Sale - Quản Lý Nhân Viên");
  const headers = useHeaders();
  const changePassApi = API_URL + API_ROUTES.CHANGE_PASS;
  const getEmployee = API_URL + API_ROUTES.GET_LIST_EMPLOYEE;
  const deleteEmployee = API_URL + API_ROUTES.DELETE_EMPLOYEE;
  const [passParent, setPassParent] = useState("");
  const [newPass, setNewPass] = useState("");
  const [donePass, setDonePass] = useState("");
  const { userInfo } = useUser();
  const [heightTop, setHeightTop] = useState("");
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();
  const [listEmpl, setListEmpl] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [hiddenTable, setHiddenTable] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [itemEdit, setItemEdit] = useState([]);
  const [openPush, setOpenPush] = useState(false);
  const [search, SetSearch] = useState("");
  const searchChange = (event) => {
    const { value } = event.target;
    SetSearch(value);
  };
  function filterByName(listEmpl, search) {
    return listEmpl?.filter((item) =>
      Object.values(item).some(
        (value) =>
          typeof value === "string" &&
          value.toLowerCase().includes(search.toLowerCase())
      )
    );
  }
  const changeNewPass = (e) => {
    setNewPass(e?.target?.value);
  };
  const changeDonePass = (e) => {
    setDonePass(e?.target?.value);
  };
  const changeParentPass = (e) => {
    setPassParent(e?.target?.value);
  };
  const deleteNv = async (id) => {
    const confirm = window.confirm(
      "Bạn có chắc chắn muốn xóa tài khoản này không"
    );
    if (confirm) {
      const body = { id_employee: id };
      try {
        await axios.post(deleteEmployee, body, headers);
        setRefresh(!refresh);
      } catch (err) {}
    }
  };
  const changePass = async () => {
    if (newPass === donePass) {
      const data = {
        old_password: passParent,
        new_password: newPass,
      };
      try {
        await axios.post(changePassApi, data, headers);
        toast.success("Đổi mật khẩu Thành Công");
      } catch (error) {
        alert(
          "Đổi mật khẩu thất bại vui lòng kiểm tra lại thông tin ,Có thể mật khẩu này quá phố biến hoặc quá dễ đoán "
        );
      }
    } else {
      alert("Vui lòng nhập chính xác mật khẩu");
    }
  };
  useEffect(() => {
    getDataEmpl();
    setHeightTop(document?.getElementById("heigt-top")?.offsetHeight);
  }, [refresh]);
  const getDataEmpl = async () => {
    try {
      const response = await axios.get(getEmployee, headers);
      setListEmpl(response.data);
    } catch (err) {}
  };
  return (
    <div className="d-flex flex-column " style={{ height: "100vh" }}>
      <div className="notifica-container">
        <ToastContainer />
        <div className="navigate-container">
          <Navigate />
        </div>
        <div className="d-flex h-100 w-100">
          <div
            className="h-100 d-flex flex-column "
            style={{ width: "15%", overflow: "auto" }}
          >
            <div
              className="thead-fixed"
              style={{
                display: "flex",
                alignItems: "center",
                borderBottom: "1px solid",
                height: `${heightTop}px`,
                background: "#fff",
              }}
            >
              <h3
                className="manage-domain-respon"
                style={{
                  padding: "20px",
                  margin: "0",
                  fontSize: "20px",
                  fontWeight: "700",
                  color: "#545454",
                }}
              >
                Chỉnh sửa mật khẩu
              </h3>
              <TbPasswordMobilePhone
                className=""
                style={{ fontSize: "20px" }}
              />
            </div>
            <div
              className="container-table-button-mange add-jobs-flex"
              style={{
                padding: "30px",
                background: "#f9f9f9",
                justifyContent: "center",
              }}
            >
              <div className="contair-add-jobs-left d-flex flex-column gap-4 align-items-center w-100">
                <div className="d-flex flex-column align-items-start w-100">
                  <p
                    style={{
                      marginBottom: "5px",
                      fontSize: "12px",
                      color: "#000",
                      fontWeight: "600",
                    }}
                  >
                    Mật khẩu hiện tại
                  </p>
                  <Password
                    placeholder={t("placeholder")}
                    className="inputPass"
                    style={{ width: "100%" }}
                    value={passParent}
                    onChange={(e) => changeParentPass(e)}
                    toggleMask
                    feedback={t("")}
                    promptLabel={t("")}
                    type="password"
                  />
                </div>
                <div className="d-flex flex-column align-items-start w-100">
                  <p
                    style={{
                      marginBottom: "5px",
                      fontSize: "12px",
                      color: "#000",
                      fontWeight: "600",
                    }}
                  >
                    Mật khẩu mới
                  </p>
                  <Password
                    className="inputPass"
                    style={{ width: "100%" }}
                    value={newPass}
                    onChange={(e) => changeNewPass(e)}
                    toggleMask
                    showMask={false}
                    feedback={t("")}
                    promptLabel={t("")}
                    placeholder={t("placeholder")}
                    type="password"
                  />
                </div>
                <div className="d-flex flex-column align-items-start w-100">
                  <p
                    style={{
                      marginBottom: "5px",
                      fontSize: "12px",
                      color: "#000",
                      fontWeight: "600",
                    }}
                  >
                    Nhập lại mật khẩu mới
                  </p>
                  <Password
                    className="inputPass"
                    style={{ width: "100%" }}
                    value={donePass}
                    onChange={(e) => changeDonePass(e)}
                    toggleMask
                    feedback={t("")}
                    promptLabel={t("")}
                    placeholder={t("placeholder")}
                    type="password"
                  />
                </div>
                <Button
                  className="button-blue radius"
                  onClick={() => changePass()}
                >
                  Cập nhật
                </Button>
              </div>
            </div>
          </div>
          <div
            className="overflow-auto h-100 d-flex flex-column"
            style={{
              width: "85%",
              borderRight: "0.5px solid",
              borderLeft: "0.5px solid",
            }}
          >
            <div
              id="heigt-top"
              style={{
                display: "flex",
                alignItems: "center",
                borderBottom: "1px solid",
              }}
              className="thead-fixed justify-content-between "
            >
              <div
                className="d-flex gap-4 align-items-center"
                style={{ padding: "20px" }}
              >
                <h5
                  className="manage-domain-respon"
                  style={{
                    margin: "0",
                    fontSize: "20px",
                    fontWeight: "700",
                    color: "#545454",
                  }}
                >
                  Quản lý nhân viên
                </h5>
                <nav className="d-flex flex-column ">
                  <p
                    className="manage-domain-respon"
                    style={{
                      margin: "0",
                    }}
                  >
                    Số lượng nhân viên được phép tạo là:{" "}
                    {userInfo?.employee_limit}
                  </p>
                  <p
                    className="manage-domain-respon"
                    style={{
                      margin: "0",
                    }}
                  >
                    Số lượng page được phép tạo là: {userInfo?.page_limit}
                  </p>
                </nav>
              </div>
              <ManageEmployee
                setVisible={setVisible}
                visible={visible}
                setRefresh={setRefresh}
                refresh={refresh}
                listEmpl={listEmpl}
              />
              <div className="d-flex gap-4 ">
                <InputGroup
                  className="responsive-width-acc"
                  style={{
                    width: "auto",
                    borderRadius: "0",
                  }}
                >
                  <Form.Control
                    placeholder="Tìm kiếm nhanh"
                    aria-describedby="basic-addon2"
                    value={search}
                    style={{
                      borderRadius: "0",
                    }}
                    onChange={searchChange}
                  />
                </InputGroup>
                <Button
                  className="button-blue"
                  style={{ marginRight: "20px" }}
                  onClick={() => setVisible(true)}
                >
                  Thêm tài khoản nhân viên
                </Button>
              </div>
            </div>
            <div className="overflow-auto h-100">
              <Table striped bordered hover>
                <thead className="thead-fixed">
                  <tr style={{ borderTop: "0" }}>
                    <th>STT</th>
                    <th>Tên đăng nhập</th>
                    <th>Mật khẩu</th>
                    <th>Họ tên</th>
                    <th>Số điện thoại</th>
                    <th>Page được gán</th>
                    <th>Hạn sử dụng</th>
                    <th>Tùy chọn</th>
                  </tr>
                </thead>
                <tbody>
                  {(filterByName(listEmpl, search) || listEmpl)?.map(
                    (item, index) => (
                      <tr
                        style={{ cursor: "pointer" }}
                        onClick={() => setHiddenTable(!hiddenTable)}
                      >
                        <td>{index + 1}</td>
                        <td>{item?.username}</td>
                        <td
                          style={{
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            wordWrap: "break-word",
                          }}
                        >
                          {item?.raw_password}
                        </td>
                        <td>{item?.fullname}</td>
                        <td>{item?.phone_number}</td>
                        <td>
                          {item?.pages?.map((item, index) => (
                            <div className="d-flex align-items-center gap-2">
                              <img
                                style={{ borderRadius: "50%", width: "20px" }}
                                src={item?.avatar}
                              />
                              <p className="mb-0">{item?.name}</p>
                            </div>
                          ))}
                        </td>
                        <td>{`${item.expiration_date.split(" ")[1]} -
                            ${
                              item.expiration_date.split(" ")[0].split("-")[2]
                            }-${
                          item.expiration_date.split(" ")[0].split("-")[1]
                        }-${
                          item.expiration_date.split(" ")[0].split("-")[0]
                        }`}</td>
                        <td>
                          <Button
                            onClick={() => {
                              setItemEdit(item);
                              setModalEdit(true);
                            }}
                            label="Sửa"
                            style={{
                              padding: "6px 12px",
                            }}
                            severity="info"
                            className="mr-2 mb-2 button-blue radius"
                          />
                          <Button
                            onClick={() => {
                              setItemEdit(item);
                              setOpenPush(true);
                            }}
                            label="Gán Page"
                            style={{
                              padding: "6px 12px",
                            }}
                            severity="info"
                            className="mr-2 mb-2 button-blue radius"
                          />
                          <Button
                            onClick={() => deleteNv(item.id)}
                            label="Xóa"
                            style={{ padding: "6px 12px" }}
                            severity="danger"
                            className="mr-2 mb-2 button-red radius"
                          />
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </Table>
              <Editemployee
                setRefresh={setRefresh}
                refresh={refresh}
                itemEdit={itemEdit}
                modalEdit={modalEdit}
                setModalEdit={setModalEdit}
              />
              <PushPage
                listEmpl={listEmpl}
                itemEdit={itemEdit}
                setOpenPush={setOpenPush}
                openPush={openPush}
                setRefresh={setRefresh}
                refresh={refresh}
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
