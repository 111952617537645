import { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import {
  toggleSelectAll,
  headerCheckbox,
  bodyCheckbox,
} from "../../../const/checkboxPrimeReactjs";
import { EditScriptClient } from "./EditScriptClient";
import { API_URL, API_ROUTES_NTD } from "../../../api";
import { useHeaders } from "../../../const/headers";
import { onRowClick } from "../../../const/checkboxPrimeReactjs";
import { rowClassName } from "../../../const/checkboxPrimeReactjs";
import { statusRunTools } from "../../../const/updateTitle";
import axios from "axios";
export const ScriptAccount = ({
  allCateClient,
  setRefresh,
  refresh,
  setListScript,
  listScript,
  setManagerScript,
  setItemCate,
}) => {
  const deleteScript = API_URL + API_ROUTES_NTD.DELETE_CATEGORY_CLIENT;
  const copyCate = API_URL + API_ROUTES_NTD.COPY_CATEGORY_FRIEND;
  const headers = useHeaders();
  const [editCate, setEditCate] = useState([]);
  const [modalEdit, setModalEdit] = useState(false);
  const getDataAllFr = () => {};
  const handleDeleteScript = async (item) => {
    const confirm = window.confirm("Bạn chắc chắn muốn xoá !");
    if (confirm) {
      const body = { id_category: item.id };
      try {
        await axios.post(deleteScript, body, headers);
        setRefresh(!refresh);
      } catch (err) {}
    }
  };
  const handleCopyCate = async (item) => {
    const userName = prompt("Nhập tên kịch bản mới");
    if (!userName) return alert("Vui lòng nhập tên kịch bản mới");
    const body = { id_category: item.id, name: userName };
    try {
      await axios.post(copyCate, body, headers);
      setRefresh(!refresh);
      setListScript([]);
    } catch (err) {}
  };
  const optionAcc = (rowData) => {
    return (
      <div className="d-flex gap-1" style={{ width: "max-content" }}>
        <Button
          onClick={() => {
            setManagerScript(true);
            setItemCate(rowData);
          }}
          style={{ padding: "6px 8px" }}
          className="button-blue radius"
        >
          Quản lý kịch bản
        </Button>
        <Button
          onClick={() => {
            handleCopyCate(rowData);
          }}
          style={{ padding: "6px 8px" }}
          className="button-blue radius"
        >
          Sao chép kịch bản
        </Button>
        <Button
          onClick={() => {
            setEditCate(rowData);
            setModalEdit(true);
          }}
          style={{ padding: "6px 8px" }}
          className="button-blue radius"
        >
          Chi tiết
        </Button>
        <Button
          severity="danger"
          onClick={() => handleDeleteScript(rowData)}
          style={{ padding: "6px 8px" }}
          className="button-red radius"
        >
          Xoá
        </Button>
      </div>
    );
  };
  const indexTemplate = (rowData, rowIndex) => {
    return <>{rowIndex.rowIndex + 1}</>;
  };
  const viewFr = (rowData) => {
    return (
      <div className="w-100 d-flex flex-wrap gap-2 align-items-center">
        <p>
          {rowData?.friend?.length === 0
            ? "Chưa có khách hàng"
            : `Có ${rowData?.friend?.length} khách hàng trong kịch bản`}
        </p>
      </div>
    );
  };
  return (
    <div className="h-100" style={{ border: "1px solid", width: "100%" }}>
      <DataTable
        size="small"
        className="small-font-table dataTableWithFixedHeader dataTableWithHoverEffect datatableoverflow"
        tableStyle={{ width: "100%" }}
        value={allCateClient}
        rowClassName={(rowData) => rowClassName(rowData, listScript)}
        onRowClick={(e) => onRowClick(e.data, setListScript, listScript)}
      >
        <Column
          header={headerCheckbox(
            listScript,
            allCateClient,
            toggleSelectAll,
            setListScript
          )}
          body={(rowData) =>
            bodyCheckbox(rowData, listScript, setListScript, getDataAllFr)
          }
        />
        <Column body={indexTemplate} sortable header="STT"></Column>
        <Column field="name" sortable header="Tên Kịch bản"></Column>
        <Column
          field="name"
          body={(rowData) => viewFr(rowData)}
          sortable
          header="Khách hàng được gán"
        ></Column>
        <Column
          field=""
          body={(rowData) => statusRunTools(rowData)}
          sortable
          header="Trạng thái"
        ></Column>
        <Column
          body={(rowData) => optionAcc(rowData)}
          header="Tuỳ chọn"
          className="options-category"
        ></Column>
      </DataTable>
      <EditScriptClient
        setRefresh={setRefresh}
        refresh={refresh}
        editCate={editCate}
        modalEdit={modalEdit}
        setModalEdit={setModalEdit}
      />
    </div>
  );
};
