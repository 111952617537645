import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import axios from "axios";
import { useHeaders } from "../../../../const/headers";
import { API_URL, API_ROUTES_NTD } from "../../../../api";
import { InputNumber } from "primereact/inputnumber";
import { Checkbox } from "primereact/checkbox";
import { CommentsContentSending } from "./dataEdit/CommentsContentSending";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { IoInformationCircle } from "react-icons/io5";
import { RiExpandDiagonal2Line } from "react-icons/ri";
export const EditScriptSending = ({
  modalEdit,
  setModalEdit,
  editCate,
  refresh,
  setRefresh,
}) => {
  const headers = useHeaders();
  const editCategoryMkt =
    API_URL + API_ROUTES_NTD.ARTICLE_SENDING.CREATE_OR_EDIT;
  const [listImage, setListImage] = useState([]);
  const [name, setName] = useState("");
  const [ingredients, setIngredients] = useState({
    like: false,
    comment: false,
    share: false,
    addfr: false,
    mess: false,
  });
  const [valueCmt, setValueCmt] = useState("");
  const [comments, setComments] = useState([]);
  const [listUid, setListUid] = useState("");
  const [delayTime, setDelayTime] = useState(30);
  const [numberCount, setNumberCount] = useState(0);
  const [waitTime, setWaitTime] = useState(0);

  useEffect(() => {
    if (editCate?.length === 0) {
      return;
    } else {
      setName(editCate?.name || "");
      setIngredients({
        like: editCate?.like || false,
        comment: editCate?.comment || false,
        share: editCate?.share || false,
      });
      setListUid(editCate?.list_link?.map((item, index) => item).join("\n"));
      setComments(editCate?.comment_contents);
      setListImage(editCate?.comment_images);
      setDelayTime(editCate?.delay_time);
    }
  }, [modalEdit]);
  const onIngredientsChange = (e) => {
    const { value, checked } = e.target;
    setIngredients((prevIngredients) => ({
      ...prevIngredients,
      [value]: checked,
    }));
  };
  const editCateMkt = async () => {
    if (!name || !listUid) {
      alert("Vui lòng nhập đầy đủ thông tin");
    } else if (
      ingredients.comment === false &&
      ingredients.like === false &&
      ingredients.share === false
    ) {
      alert("Vui lòng chọn chức năng");
    } else if (ingredients.comment === true && comments?.length === 0) {
      alert("Vui lòng nhập nội dung bình luận");
    } else if (ingredients.comment === true && comments?.length < 5) {
      alert("kịch bản bình luận cần tối thiếu 5 nội dung khác nhau");
    } else {
      const body = {
        id_category: editCate?.id,
        name: name,
        like: ingredients.like,
        comment: ingredients.comment,
        share: ingredients.share,
        delay_time: delayTime,
        comment_contents: comments,
        comment_images: listImage,
        list_link: listUid.split("\n"),
        number_count: Number(numberCount),
        wait_time: Number(waitTime),
      };
      try {
        const res = await axios.post(editCategoryMkt, body, headers);
        setRefresh(!refresh);
        setModalEdit(false);
      } catch (err) {}
    }
  };
  return (
    <Dialog
      header="Chỉnh sửa kịch bản"
      visible={modalEdit}
      style={{ position: "relative", width: "70vw" }}
      onHide={() => setModalEdit(false)}
      className="overFlowJoinGr"
      footer={
        <div className="d-flex justify-content-end gap-2 mt-2">
          <Button className="button-blue radius" onClick={editCateMkt}>
            Sửa kịch bản
          </Button>
          <Button
            onClick={() => setModalEdit(false)}
            className="button-red radius"
          >
            Thoát không lưu
          </Button>
          <RiExpandDiagonal2Line
            size={20}
            style={{
              position: "absolute",
              right: "0",
              bottom: "0",
            }}
          />
        </div>
      }
    >
      <div className="d-flex flex-column h-100" style={{ overflow: "auto" }}>
        <div className="p-inputgroup flex-1 mb-2">
          <span className="p-inputgroup-addon" style={{ width: "9rem" }}>
            Tên kịch bản
          </span>
          <InputText
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder=""
          />
        </div>
        <div className="p-inputgroup flex-1 w-100">
          <div className="d-flex flex-column w-100 gap-2">
            <nav className="d-flex gap-2">
              <span>Thời gian chờ (Giây)</span>
              <OverlayTrigger
                key="tooltip"
                placement="top"
                overlay={
                  <Tooltip id="tooltip-right" className="custom-tooltip">
                    <div className="text-start">
                      Thời gian chờ giữa mỗi hành động trong một chuỗi nhiều
                      hành động
                      <br />- Ví dụ: Bạn tích chọn 3 chức năng "Bình luận bài
                      viết + Like bài viết + Chia sẻ bài viết". Thì đây là một
                      chuỗi 3 hành động liên tiếp. Bạn nên để thời gian chờ tối
                      thiểu là 60s và khuyến khích là 300s để an toàn cho tài
                      khoản. Hành động quá nhanh sẽ dễ bị hạn chế tính năng trên
                      tài khoản do cơ chế chống spam của Facebook
                    </div>
                  </Tooltip>
                }
              >
                <span
                  style={{
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                >
                  <IoInformationCircle size={15} />
                </span>
              </OverlayTrigger>
            </nav>
            <InputNumber
              style={{ width: "100%" }}
              value={delayTime}
              placeholder="Thời gian tính bằng giây (s)"
              onValueChange={(e) => setDelayTime(e.value)}
              useGrouping={false}
              min={60}
            />
          </div>
          <div className="d-flex flex-column w-100 gap-2">
            <nav className="d-flex gap-2">
              <span>Số lượt</span>
              <OverlayTrigger
                key="tooltip"
                placement="top"
                overlay={
                  <Tooltip id="tooltip-right" className="custom-tooltip">
                    <div className="text-start">
                      Mỗi link trong danh sách link được tính là một lượt. Chức
                      năng tùy nhập số lượt này giúp giới hạn số lần tương tác
                      đẩy top trên mỗi tài khoản trong một thời điểm, nhằm giúp
                      bảo vệ tài khoản, giảm bị tình trạng hạn chế tính năng.
                      Nên để là 1 lượt. Và cài đặt thêm thời gian chờ lặp lại ở
                      ô bên cạnh
                      <br />
                      - Ví dụ tính năng đẩy top này: Có 3 tính năng và bạn tích
                      chọn hết 3 tính năng bao gồm (Bình luận bài viết + Like
                      bài viết + Chia sẻ bài viết)
                      <br />
                      + Thì khi tài khoản của bạn thao tác hết 3 tính năng (Bình
                      luận bài viết + Like bài viết + Chia sẻ bài viết) được
                      tính là 1 lượt
                      <br />- Áp dụng trong cả trường hợp bạn tích chọn 1 hoặc 2
                      tính năng trong chức năng đẩy top bài viết này
                    </div>
                  </Tooltip>
                }
              >
                <span
                  style={{
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                >
                  <IoInformationCircle size={15} />
                </span>
              </OverlayTrigger>
            </nav>
            <InputNumber
              style={{ width: "100%" }}
              value={numberCount}
              placeholder=""
              onValueChange={(e) => setNumberCount(e.value)}
              useGrouping={false}
              min={1}
            />
          </div>
          <div className="d-flex flex-column w-100 gap-2">
            <nav className="d-flex gap-2">
              <span style={{ width: "" }}>Thời gian chờ lặp lại (Giờ)</span>
              <OverlayTrigger
                key="tooltip"
                placement="top"
                overlay={
                  <Tooltip id="tooltip-right" className="custom-tooltip">
                    <div className="text-start">
                      Chức năng này có tác dụng khi bạn cài đặt số lượt (số lượt
                      được cài đặt ở ô bên cạnh) . Chức năng này giúp chạy tiếp
                      chiến dịch hoàn toàn tự động khi bạn đặt số lượt ở ô bên
                      cạnh. Nên để là 2 giờ. Hành động quá nhanh sẽ dễ bị hạn
                      chế tính năng trên tài khoản do cơ chế chống spam của
                      Facebook
                    </div>
                  </Tooltip>
                }
              >
                <span
                  style={{
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                >
                  <IoInformationCircle size={15} />
                </span>
              </OverlayTrigger>
            </nav>
            <InputNumber
              style={{ width: "100%" }}
              value={waitTime}
              placeholder="Thời gian tính bằng giờ (h)"
              onValueChange={(e) => setWaitTime(e.value)}
              useGrouping={false}
              min={1}
            />
          </div>
        </div>

        <div className="flex flex-wrap justify-content-between gap-3 mt-2">
          <div className="flex align-items-center">
            <Checkbox
              inputId="ingredient2"
              name="pizza"
              value="comment"
              onChange={onIngredientsChange}
              checked={ingredients.comment}
            />
            <label htmlFor="ingredient2" className="ml-2">
              Bình luận bài viết
            </label>
          </div>
          <div className="flex align-items-center">
            <Checkbox
              inputId="ingredient1"
              name="pizza"
              value="like"
              onChange={onIngredientsChange}
              checked={ingredients.like}
            />
            <label htmlFor="ingredient1" className="ml-2">
              Like bài viết
            </label>
          </div>

          <div className="flex align-items-center">
            <Checkbox
              inputId="ingredient3"
              name="pizza"
              value="share"
              onChange={onIngredientsChange}
              checked={ingredients.share}
            />
            <label htmlFor="ingredient3" className="ml-2">
              Chia sẻ bài viết
            </label>
          </div>
        </div>
        <div className="p-inputgroup flex-1 mt-2">
          <InputTextarea
            value={listUid}
            onChange={(e) => setListUid(e.target.value)}
            placeholder="Nhập danh sách Link"
            rows={5}
            cols={30}
          />
        </div>
        <CommentsContentSending
          comments={comments}
          listImage={listImage}
          setListImage={setListImage}
          valueCmt={valueCmt}
          setComments={setComments}
          setValueCmt={setValueCmt}
        />
      </div>
    </Dialog>
  );
};
