import { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { locale } from "primereact/api";
import {
  toggleSelectAll,
  headerCheckbox,
  bodyCheckbox,
  rowClassName,
  onRowClick,
} from "../../const/checkboxPrimeReactjs";
import { API_URL, API_ROUTES_NTD } from "../../api";
import axios from "axios";
import { useHeaders } from "../../const/headers";
import { getAxiosResults } from "../../const/getAxios";
import { Paginator } from "primereact/paginator";

export const ResultsPage = ({
  dataFb,
  selecPage,
  setSelecPage,
  setRefresh,
  refresh,
  toast,
  resultPage,
  setResultPage,
  setTotalPage,
  totalPage,
  setGetTotal,
  getTotal,
  setRows,
  rows,
  renderKey,
}) => {
  locale("vi");
  const headers = useHeaders();
  const getDataAllFr = () => {};
  const resultsPage = API_URL + API_ROUTES_NTD.TOOLBOX_PAGE_RESULT;
  const remoteResultsPage = API_URL + API_ROUTES_NTD.TOOLBOX_REMOVE_PAGE;
  const [first, setFirst] = useState(0);
  const rowsPerPageOptions = [100, 300, 500, 1000, 2000];
  const onPageChange = (event) => {
    setTotalPage(event.page);
    setFirst(event.first);
    setRows(event.rows);
  };
  useEffect(() => {
    getAxiosResults(
      resultsPage,
      setResultPage,
      setGetTotal,
      rows,
      totalPage + 1
    );
  }, [totalPage, rows, renderKey, refresh]);
  const deleteResults = async (rowData) => {
    const confirm = window.confirm("Bạn chắc chắn muốn xoá không");
    if (confirm) {
      const bodyUser = { id_scan_pages: [rowData?.id] };
      try {
        const res = await axios.post(remoteResultsPage, bodyUser, headers);
        setRefresh(!refresh);
        toast.success("Xoá thành công");
      } catch (err) {
        toast.error("Xoá thất bại");
      }
    }
  };
  const deleteButton = (rowData) => {
    return (
      <>
        <Button
          className="button-red radius"
          style={{ padding: "6px 8px" }}
          onClick={() => deleteResults(rowData)}
          severity="danger"
        >
          Xoá
        </Button>
      </>
    );
  };
  const indexTemplate = (rowData, rowIndex) => {
    return <>{rowIndex.rowIndex + 1 + rows * totalPage}</>;
  };
  const linkPage = (rowData) => {
    const link = `https://www.facebook.com/${rowData.UID}`;
    return (
      <a href={link} target="_blank" rel="noopener noreferrer">
        {link}
      </a>
    );
  };
  return (
    <div className="d-flex flex-column h-100 w-100">
      <DataTable
        size="small"
        value={resultPage}
        className="small-font-table dataTableWithFixedTool dataTableWithHoverEffect datatableoverflow"
        tableStyle={{ minWidth: "100%" }}
        rowClassName={(rowData) => rowClassName(rowData, selecPage)}
        onRowClick={(e) => onRowClick(e.data, setSelecPage, selecPage)}
      >
        <Column
          header={headerCheckbox(
            selecPage,
            resultPage,
            toggleSelectAll,
            setSelecPage
          )}
          body={(rowData) =>
            bodyCheckbox(rowData, selecPage, setSelecPage, getDataAllFr)
          }
        />
        <Column body={indexTemplate} field="" header="STT"></Column>
        <Column field="UID" header="UID page" sortable></Column>
        <Column field="followers" header="Theo dõi" sortable></Column>
        <Column field="name" header="Tên page" sortable></Column>
        <Column body={linkPage} header="Link nhóm"></Column>
        <Column field="keyword" header="từ khóa" sortable></Column>
        <Column
          body={(rowData) => deleteButton(rowData)}
          header="Tuỳ chọn"
        ></Column>
      </DataTable>
      <Paginator
        className="pagination-markerting"
        first={first}
        rows={rows}
        totalRecords={getTotal?.count}
        onPageChange={onPageChange}
        rowsPerPageOptions={rowsPerPageOptions}
      />
    </div>
  );
};
