import React, { useEffect } from "react";
import Navigate from "../container/Navigate";
import { useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Footer } from "./Footer";
import { useHeaders } from "../../const/headers";
import { updateTitle } from "../../const/updateTitle";
import { API_URL, API_ROUTES_NTD } from "../../api";
import { Dropdown } from "primereact/dropdown";
import { ListContentMarket } from "../marketplace/ListContentMarket";
import PreviewMarket from "../marketplace/PreviewMarket";
import { DropdownFilterLocation } from "../marketplace/DropdownFilterLocation";
import { getAxios } from "../../const/getAxios";
import { AccountMarket } from "../marketplace/AccountMarket";
import { useLocation } from "react-router-dom";
export const EditMarketplace = () => {
  updateTitle("Chốt Sale - Marketplace");
  const headers = useHeaders();
  const locations = useLocation();
  const allCateMarket = API_URL + API_ROUTES_NTD.GET_CATE_MARKETPLACE;
  const postIdCateMart = API_URL + API_ROUTES_NTD.POST_ID_GET_CATE_MARKETPLACE;
  const getAllCateMkt = API_URL + API_ROUTES_NTD.GET_CATEGORY_MARKETPLACE;
  const [cateMarket, setCateMarket] = useState([]);
  const [selectCate, setSelectCate] = useState(null);
  const [selectedSubOption, setSelectedSubOption] = useState(null);
  const [subOptions, setSubOptions] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  const [filerKey, setFilterKey] = useState("");
  const [title, setTitle] = useState("");
  const [imageURLs, setImageURLs] = useState([]);
  const [price, setPrice] = useState("");
  const [description, setDescription] = useState("");
  const [dataFb, setDataFb] = useState([]);
  const [selecAcc, setSelecAcc] = useState([]);
  const [location, setLocation] = useState([]);
  const [listFile, setListFile] = useState([]);
  const [listImage, setListImage] = useState([]);
  const [listCateMarket, setListCateMarket] = useState([]);
  const [messMarket, setMessMarket] = useState("");
  const pathId = locations.pathname;
  const idCate = pathId.split("/")[2];
  const [name, setName] = useState("");
  const postIdMartket = async (item) => {
    if (item) {
      const body = { id_category: item };
      try {
        const res = await axios.post(postIdCateMart, body, headers);
        setSubOptions(
          res.data?.map((item) => ({
            name: item.category_detail_name,
            code: item.id,
            category_detail_id: item.category_detail_id,
          }))
        );
      } catch (err) {}
    }
  };
  useEffect(() => {
    getAxios(allCateMarket, setCateMarket);
    getAxios(getAllCateMkt, setListCateMarket);
  }, []);
  useEffect(() => {
    const agssinCate = listCateMarket.filter(
      (item) => item.id === Number(idCate)
    );
    setDescription(agssinCate[0]?.description);
    setTitle(agssinCate[0]?.title);
    setPrice(agssinCate[0]?.price);
    setListImage(agssinCate[0]?.images);
    setFilterKey({
      latitude: agssinCate[0]?.latitude,
      longitude: agssinCate[0]?.longitude,
      name: agssinCate[0]?.location,
    });
    setName(agssinCate[0]?.name);
    setMessMarket(agssinCate[0]?.message_post);
    setSelectCate({
      name: agssinCate[0]?.category_detail?.category.category_name,
      code: agssinCate[0]?.category_detail?.category.id,
    });
    setSelectedSubOption({
      name: agssinCate[0]?.category_detail?.category_detail_name,
      code: agssinCate[0]?.category_detail?.id,
      category_detail_id: agssinCate[0]?.category_detail?.category_detail_id,
    });
    setMessMarket(agssinCate[0]?.messages_bot);
    postIdMartket(agssinCate[0]?.category_detail?.category.id);
  }, [listCateMarket]);
  const valueDropdown = cateMarket?.map((item) => ({
    name: item.category_name,
    code: item.id,
  }));
  const widthNavi = document.getElementById("navigate-width")?.offsetWidth;
  const widthMarket = document.getElementById("width-show-market")?.offsetWidth;
  return (
    <div className="d-flex flex-column " style={{ height: "100vh" }}>
      <div className="notifica-container">
        <ToastContainer />
        <div className="navigate-container" id="navigate-width">
          <Navigate />
        </div>
        <div
          className="d-flex h-100 gap-2 p-2"
          style={{ width: `calc(100vw - ${widthNavi}px)` }}
        >
          <div
            className="h-100 shadow_market"
            id="width-show-market"
            style={{ width: "20%", overflowY: "auto", overflowX: "hidden" }}
          >
            <AccountMarket
              selecAcc={selecAcc}
              setSelecAcc={setSelecAcc}
              dataFb={dataFb}
              setDataFb={setDataFb}
            />
            <ListContentMarket
              name={name}
              setName={setName}
              listImage={listImage}
              setListImage={setListImage}
              setListFile={setListFile}
              listFile={listFile}
              description={description}
              setDescription={setDescription}
              price={price}
              setPrice={setPrice}
              imageURLs={imageURLs}
              setImageURLs={setImageURLs}
              title={title}
              setTitle={setTitle}
            />
            <div className=" mt-2 flex justify-content-center flex-column align-items-center w-100">
              <p className="w-100 mb-2 fs-6">Chọn danh mục</p>
              <Dropdown
                value={selectCate}
                onChange={(e) => {
                  setSelectCate(e.value);
                  setSelectedSubOption(null);
                  postIdMartket(e.value?.code);
                }}
                options={valueDropdown}
                optionLabel="name"
                placeholder="Hạng mục"
                className="w-100 custom-dropdown"
                filter
              />
              {selectCate && (
                <Dropdown
                  value={selectedSubOption}
                  onChange={(e) => setSelectedSubOption(e.value)}
                  options={subOptions}
                  optionLabel="name"
                  placeholder={`${
                    subOptions[0]?.name || selectedSubOption?.name
                  }`}
                  className="w-100 custom-dropdown"
                />
              )}
            </div>
            <DropdownFilterLocation
              messMarket={messMarket}
              location={location}
              setMessMarket={setMessMarket}
              setLocation={setLocation}
              filterValue={filterValue}
              setFilterValue={setFilterValue}
              setFilterKey={setFilterKey}
              filerKey={filerKey}
              headers={headers}
            />
          </div>
          <div
            className="h-100 shadow_market d-flex"
            style={{ width: `calc(100% - ${widthMarket}px - 0.5rem)` }}
          >
            <PreviewMarket
              toast={toast}
              dataHidden={listCateMarket.filter(
                (item) => item.id === Number(idCate)
              )}
              messMarket={messMarket}
              name={name}
              listImage={listImage}
              selectedSubOption={selectedSubOption}
              listFile={listFile}
              filerKey={filerKey}
              selecAcc={selecAcc}
              description={description}
              title={title}
              price={price}
              imageURLs={imageURLs}
              setImageURLs={setImageURLs}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
