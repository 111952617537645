import React, {
  Children,
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import { API_URL, API_ROUTES, WS_URL } from "../api";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { useHeaders } from "../const/headers";
import axios from "axios";
const StatusContext = createContext();
export const useStatus = () => {
  return useContext(StatusContext);
};

export const StatusProvider = ({ children }) => {
  const headers = useHeaders();
  const [userInfo, setUserInfo] = useState([]);
  const [bellSource, setBellSource] = useState([]);
  const [language, setLanguage] = useState("vi");

  const getInfor = API_URL + API_ROUTES.GET_INFO_UERS;
  const fetchDataIf = async () => {
    try {
      const response = await axios.get(getInfor, headers);
      setUserInfo(response.data);
    } catch (err) {}
  };
  useEffect(() => {
    fetchDataIf();
  }, []);
  const access = localStorage.getItem("access");
  const socketRef = useRef(null);
  const secketMess = useRef(null);
  const [type, setType] = useState("");
  const [listAccout, setListAccount] = useState([]);
  useEffect(() => {
    if (userInfo?.id) {
      const client = new W3CWebSocket(
        `wss://${WS_URL}/ws/user/${userInfo?.id}/?token=${access}`
      );
      // const clientMess = new W3CWebSocket(
      //   `wss://${WS_URL}/ws/chat/${userInfo?.id}/?token=${access}`
      // );
      socketRef.current = client;
      // secketMess.current = clientMess;
      client.onerror = function () {};
      client.onopen = function () {};
      client.onmessage = function (message) {
        const parsedData = JSON.parse(message.data);
        if (parsedData.type) {
          setType(parsedData.type);
          client.send(JSON.stringify({ type: "pong" }));
        }
        if (parsedData?.accounts) {
          // setBellSource(parsedData?.accounts);
        }
        if (parsedData.message) {
          // setAllPageMess(parsedData.message);
        }
      };
      // clientMess.onmessage = function (message) {
      //   const parsedData = JSON.parse(message.data);
      //   if (parsedData.type) {
      //     // setType(parsedData.type);
      //     // client.send(JSON.stringify({ type: "pong" }));
      //   }
      //   if (parsedData?.accounts) {
      //     setBellSource(parsedData?.accounts);
      //   }
      //   if (parsedData.message) {
      //     // setAllPageMess(parsedData.message);
      //   }
      // };
      return () => {
        if (client) {
          client.close();
          // clientMess.close();
        }
      };
    }
  }, [userInfo]);
  return (
    <StatusContext.Provider
      value={{
        listAccout,
        setListAccount,
        userInfo,
        fetchDataIf,
        bellSource,
        language,
        setLanguage,
      }}
    >
      {children}
    </StatusContext.Provider>
  );
};
