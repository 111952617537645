import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { API_URL, API_ROUTES_NTD } from "../../api";
import axios from "axios";
import { useHeaders } from "../../const/headers";

const Map = ({ location }) => {
  const headers = useHeaders();
  const apiLocation = API_URL + API_ROUTES_NTD.GET_LOCATION_MARKETPLACE;
  const [position, setPosition] = useState(null); // Khởi tạo state là null
  const body = {
    location_name: location,
  };

  const getLocation = async () => {
    if (location) {
      try {
        const res = await axios.post(apiLocation, body, headers);
        setPosition(res.data[0]); // Lưu trữ đối tượng đầu tiên từ dữ liệu trả về
      } catch (err) {
        console.error(err);
      }
    }
  };

  useEffect(() => {
    getLocation();
  }, [location]);

  if (!position || !position.latitude || !position.longitude) {
    // Hiển thị loading hoặc thông báo khi position chưa có dữ liệu
    return <div>{location && <p>Đang tải bản đồ</p>}</div>;
  }

  return (
    <MapContainer
      center={[position.latitude, position.longitude]} // Đảm bảo chính tả đúng
      zoom={11}
      style={{ height: "100px", width: "160px" }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
      />
      <Marker position={[position.latitude, position.longitude]}>
        <Popup>{location?.location}</Popup>
      </Marker>
    </MapContainer>
  );
};

export default Map;
