import { useState, useEffect } from "react";
import { API_URL, API_ROUTES_NTD } from "../../../api";
import axios from "axios";
import { useHeaders } from "../../../const/headers";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Checkbox } from "primereact/checkbox";
import { locale } from "primereact/api";
import { rowClassName } from "../../../const/checkboxPrimeReactjs";
import { onRowClick } from "../../../const/checkboxPrimeReactjs";
import {
  toggleSelectAll,
  headerCheckbox,
  bodyCheckbox,
} from "../../../const/checkboxPrimeReactjs";
export const TableAccountJoinGroup = ({
  dataFb,
  setAllFriend,
  checkboxes,
  setCheckboxes,
  selectedItems,
  setSelectedItems,
  refresh,
}) => {
  locale("vi");
  const getAllGr = API_URL + API_ROUTES_NTD.GET_RESULTS_GROUP;
  const headers = useHeaders();
  const filterAccountCheckpoit = dataFb?.filter(
    (item) => item?.checkpoint === false && item.status_proxy === true
  );
  const getDataAllGr = () => {};

  const nameAndAvt = (rowData) => {
    return (
      <div className="d-flex gap-2 align-items-center">
        <img
          className="rounded-circle"
          style={{ width: "20px", height: "20px" }}
          src={rowData?.avatar}
        />
        <p>{rowData?.name}</p>
      </div>
    );
  };
  const indexTemplate = (rowData, rowIndex) => {
    return <>{rowIndex.rowIndex + 1}</>;
  };
  return (
    <div style={{ width: "100%" }}>
      <DataTable
        size="small"
        value={filterAccountCheckpoit}
        className="small-font-table dataTableWithFixedHeader dataTableWithHoverEffect datatableoverflow"
        tableStyle={{ minWidth: "50%" }}
        rowClassName={(rowData) => rowClassName(rowData, selectedItems)}
        onRowClick={(e) => onRowClick(e.data, setSelectedItems, selectedItems)}
      >
        <Column
          header={headerCheckbox(
            selectedItems,
            filterAccountCheckpoit,
            toggleSelectAll,
            setSelectedItems
          )}
          body={(rowData) =>
            bodyCheckbox(rowData, selectedItems, setSelectedItems, getDataAllGr)
          }
        />
        <Column body={indexTemplate} field="UID" header="STT"></Column>
        <Column field="UID" header="UID" sortable></Column>
        <Column
          body={(rowData) => nameAndAvt(rowData)}
          field="name"
          header="Tên tài khoản"
          sortable
        ></Column>
      </DataTable>
    </div>
  );
};
