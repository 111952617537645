import React, { createContext, useContext, useEffect, useState } from "react";

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};
export const AuthProvider = ({ children }) => {
  const [refreshs,setRefeshs] = useState("")
  const [loading, setLoading] = useState(true);
  const [userLoggedIn, setUserLoggedInState] = useState(false);
  const token = localStorage.getItem("access");
  useEffect(() => {
    const loggedInStatus = localStorage.getItem("userLoggedIn");
    if (loggedInStatus !== null) {
      setUserLoggedInState(loggedInStatus === "true");
    }
    setLoading(false);
  }, []);
  const setUserLoggedIn = (loggedIn) => {
    setUserLoggedInState(loggedIn);
    localStorage.setItem("userLoggedIn", loggedIn.toString());
  };
  if (loading) {
    return null;
  }
  return (
    <AuthContext.Provider
      value={{
        userLoggedIn,
        setUserLoggedIn,
        setUserLoggedInState,
        token,
        setRefeshs,
        refreshs
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
