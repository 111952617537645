import React, { useState } from "react";
import { ColorPicker } from "primereact/colorpicker";
import { Dialog } from "primereact/dialog";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

const CustomColorPicker = ({ value, onChange }) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const colors = [
    "#FFEB3B",
    "#FFC107",
    "#FF9800",
    "#FF5722",
    "#F44336",
    "#E91E63",
    "#9C27B0",
    "#673AB7",
    "#3F51B5",
    "#2196F3",
    "#970f00",
    "#62df07",
  ];

  const handleClick = () => {
    setDisplayColorPicker(true);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleColorSelect = (color) => {
    onChange({ value: color });
    handleClose();
  };

  return (
    <div>
      <div
        onClick={handleClick}
        style={{
          width: "30px",
          height: "31px",
          backgroundColor: value,
          cursor: "pointer",
        }}
      ></div>
      <Dialog visible={displayColorPicker} onHide={handleClose}>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {colors.map((color) => (
            <div
              key={color}
              onClick={() => handleColorSelect(color)}
              style={{
                width: "30px",
                height: "30px",
                backgroundColor: color,
                cursor: "pointer",
                margin: "5px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: value === color ? "2px solid black" : "1px solid #ccc",
              }}
            >
              {value === color && (
                <i className="pi pi-check" style={{ color: "#fff" }}></i>
              )}
            </div>
          ))}
        </div>
      </Dialog>
    </div>
  );
};

export default CustomColorPicker;
