import React, { useEffect } from "react";
import Navigate from "../container/Navigate";
import { useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Footer } from "./Footer";
import { useHeaders } from "../../const/headers";
import { updateTitle } from "../../const/updateTitle";
import { API_URL, API_ROUTES_NTD } from "../../api";
import { Dropdown } from "primereact/dropdown";
import { ListContentMarket } from "../marketplace/ListContentMarket";
import PreviewMarket from "../marketplace/PreviewMarket";
import { DropdownFilterLocation } from "../marketplace/DropdownFilterLocation";
import { getAxios } from "../../const/getAxios";
import { AccountMarket } from "../marketplace/AccountMarket";
import "./MarketplaceVehicle.css";
import { useLocation } from "react-router-dom";
import { se } from "date-fns/locale";

const years = Array.from({ length: 30 }, (_, i) => {
  const year = new Date().getFullYear() - i;
  return { label: year.toString(), value: year };
});
export const MarketplaceVehicle = () => {
  const locationss = useLocation();
  updateTitle("Chốt Sale - Marketplace");
  const headers = useHeaders();
  const allCateMarket = API_URL + API_ROUTES_NTD.GET_CATE_MARKETPLACE;
  const getAllCateMkt = API_URL + API_ROUTES_NTD.GET_CATEGORY_MARKETPLACE;
  const postIdCateMart = API_URL + API_ROUTES_NTD.POST_ID_GET_CATE_MARKETPLACE;
  const [selectedYear, setSelectedYear] = useState(null);
  const [carBrand, setCarBrand] = useState("");
  const [carModel, setCarModel] = useState("");
  const [carMileage, setCarMileage] = useState("");
  const [cateMarket, setCateMarket] = useState([]);
  const [selectCate, setSelectCate] = useState(null);
  const [selectedSubOption, setSelectedSubOption] = useState(null);
  const [subOptions, setSubOptions] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  const [filerKey, setFilterKey] = useState("");
  const [title, setTitle] = useState("");
  const [imageURLs, setImageURLs] = useState([]);
  const [price, setPrice] = useState("");
  const [description, setDescription] = useState("");
  const [dataFb, setDataFb] = useState([]);
  const [selecAcc, setSelecAcc] = useState([]);
  const [location, setLocation] = useState([]);
  const [listFile, setListFile] = useState([]);
  const [listImage, setListImage] = useState([]);
  const [messMarket, setMessMarket] = useState([]);
  const [allCate, setAllCate] = useState([]);
  const [name, setName] = useState("");
  const [dataLink, setDataLink] = useState([]);
  const widthNavi = document.getElementById("navigate-width")?.offsetWidth;
  const widthMarket = document.getElementById("width-show-market")?.offsetWidth;
  const dataCopy = JSON.parse(localStorage.getItem("dataCopy"));
  const dataFill = JSON.parse(localStorage.getItem("dataFill"));
  const valueDropdown = [
    { name: "Xe máy", code: "motorcycle" },
    { name: "Xe hơi", code: "car" },
    { name: "Tàu thuỷ", code: "boat" },
    { name: "Xe phân khối lớn", code: "powersport" },
    { name: "Xe RV/Nhà lưu động", code: "rv-camper" },
    { name: "Xe moóc", code: "trailer" },
    { name: "Xe tải", code: "truck" },
  ];
  useEffect(() => {
    getAxios(getAllCateMkt, setAllCate);
  }, []);
  const itemEditVehicle = allCate?.filter(
    (item) => item.id === Number(locationss?.pathname.split("/")[2])
  );

  useEffect(() => {
    if (itemEditVehicle[0]?.id) {
      setListImage(itemEditVehicle[0]?.images);
      setTitle(itemEditVehicle[0]?.title);
      setDescription(itemEditVehicle[0]?.description);
      setPrice(itemEditVehicle[0]?.price);
      setSelectCate(
        valueDropdown.filter(
          (item) => item.code === itemEditVehicle[0]?.vehicleCategory
        )[0]
      );
      setSelectedYear(Number(itemEditVehicle[0]?.year));
      setCarBrand(itemEditVehicle[0]?.make);
      setCarModel(itemEditVehicle[0]?.model);
      setCarMileage(itemEditVehicle[0]?.mileage);
      setFilterKey({
        name: itemEditVehicle[0]?.location,
        latitude: itemEditVehicle[0]?.latitude,
        longitude: itemEditVehicle[0]?.longitude,
      });
      setMessMarket(itemEditVehicle[0]?.messages_bot);
      postIdMartket(itemEditVehicle[0]?.category_detail?.category.id);
    }
  }, [allCate]);
  const postIdMartket = async (item) => {
    const body = { id_category: item };
    try {
      const res = await axios.post(postIdCateMart, body, headers);
      setSubOptions(
        res.data?.map((item) => ({
          name: item.category_detail_name,
          code: item.id,
          category_detail_id: item.category_detail_id,
        }))
      );
    } catch (err) {}
  };
  useEffect(() => {
    getAxios(allCateMarket, setCateMarket);
  }, []);

  useEffect(() => {
    if (dataLink?.data) {
      setDescription(dataLink?.data?.description?.text);
      setPrice(dataLink?.data?.price);
      setTitle(dataLink?.data?.title);
      setListImage(dataLink?.data?.photos);
    }
  }, [dataLink]);
  console.log(selectCate);
  return (
    <div className="d-flex flex-column " style={{ height: "100vh" }}>
      <div className="notifica-container">
        <ToastContainer />
        <div className="navigate-container" id="navigate-width">
          <Navigate />
        </div>
        <div
          className="d-flex h-100 gap-2 p-2"
          style={{ width: `calc(100vw - ${widthNavi}px)` }}
        >
          <div
            className="h-100 shadow_market"
            id="width-show-market"
            style={{ width: "20%", overflowY: "auto", overflowX: "hidden" }}
          >
            <AccountMarket
              selecAcc={selecAcc}
              setSelecAcc={setSelecAcc}
              dataFb={dataFb}
              setDataFb={setDataFb}
            />
            <ListContentMarket
              dataFill={dataFill}
              dataCopy={dataCopy}
              toast={toast}
              dataLink={dataLink}
              setDataLink={setDataLink}
              name={name}
              setName={setName}
              listImage={listImage}
              setListImage={setListImage}
              setListFile={setListFile}
              listFile={listFile}
              description={description}
              setDescription={setDescription}
              price={price}
              setPrice={setPrice}
              imageURLs={imageURLs}
              setImageURLs={setImageURLs}
              title={title}
              setTitle={setTitle}
            />
            <div className=" mt-2 flex justify-content-center flex-column align-items-center w-100">
              <p className="w-100 mb-2 fs-6">Chọn loại xe</p>
              <Dropdown
                value={selectCate}
                onChange={(e) => {
                  setSelectCate(e.value);
                }}
                options={valueDropdown}
                optionLabel="name"
                placeholder="Loại xe"
                className="w-100 custom-dropdown"
              />
            </div>
            <div className="mt-2 flex justify-content-center flex-column align-items-center w-100">
              <p className="w-100 mb-2 fs-6">Chọn năm</p>
              <Dropdown
                value={selectedYear}
                onChange={(e) => setSelectedYear(e.value)}
                options={years}
                optionLabel="label"
                placeholder="Chọn năm"
                className="w-100 custom-dropdown"
              />
            </div>
            <div className="mt-2 flex justify-content-center flex-column align-items-center w-100">
              <p className="w-100 mb-2 fs-6">Nhập hãng xe</p>
              <input
                type="text"
                value={carBrand}
                onChange={(e) => setCarBrand(e.target.value)}
                placeholder="Hãng xe"
                className="w-100 p-inputtext"
              />
            </div>
            <div className="mt-2 flex justify-content-center flex-column align-items-center w-100">
              <p className="w-100 mb-2 fs-6">Nhập mẫu xe</p>
              <input
                type="text"
                value={carModel}
                onChange={(e) => setCarModel(e.target.value)}
                placeholder="Mẫu xe"
                className="w-100 p-inputtext"
              />
            </div>
            <div className="mt-2 flex justify-content-center flex-column align-items-center w-100">
              <p className="w-100 mb-2 fs-6">Nhập số dặm</p>
              <input
                type="number"
                value={carMileage}
                onChange={(e) => setCarMileage(e.target.value)}
                placeholder="Số dặm đã đi"
                className="w-100 p-inputtext"
              />
            </div>
            <DropdownFilterLocation
              messMarket={messMarket}
              setMessMarket={setMessMarket}
              location={location}
              setLocation={setLocation}
              filterValue={filterValue}
              setFilterValue={setFilterValue}
              setFilterKey={setFilterKey}
              filerKey={filerKey}
              headers={headers}
            />
          </div>
          <div
            className="h-100 shadow_market d-flex"
            style={{ width: `calc(100% - ${widthMarket}px - 0.5rem)` }}
          >
            <PreviewMarket
              carMileage={carMileage}
              carModel={carModel}
              carBrand={carBrand}
              selectedYear={selectedYear}
              selectCate={selectCate}
              subOptions={subOptions}
              messMarket={messMarket}
              toast={toast}
              name={name}
              listImage={listImage}
              selectedSubOption={selectedSubOption}
              listFile={listFile}
              filerKey={filerKey}
              selecAcc={selecAcc}
              description={description}
              title={title}
              price={price}
              imageURLs={imageURLs}
              location={location}
              setImageURLs={setImageURLs}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
