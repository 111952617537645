import { useEffect, useState, useRef } from "react";
import { Dropdown } from "primereact/dropdown";
import { API_URL, API_ROUTES_NTD, API_ROUTES } from "../../api";
import axios from "axios";
import { InputText } from "primereact/inputtext";
import { getAxios } from "../../const/getAxios";
import { RiArrowGoBackFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
export const AccountMarket = ({
  setSelecAcc,
  selecAcc,
  dataFb,
  setDataFb,
  headers,
}) => {
  const getDataFb = API_URL + API_ROUTES.GET_ACC_FB;
  const navigate = useNavigate();
  const [location, setLocation] = useState([]);
  const apiFilterLoca = API_URL + API_ROUTES_NTD.GET_LOCATION_MARKETPLACE;
  useEffect(() => {
    getAxios(getDataFb, setDataFb);
  }, []);
  return (
    <div className="mb-2 flex justify-content-center flex-column align-items-start w-100"></div>
  );
};
