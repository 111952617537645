import axios from "axios";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { IoInformationCircle } from "react-icons/io5";
import { toast } from "react-toastify";
import { API_ROUTES_NTD, API_URL } from "../../../api";
import { useHeaders } from "../../../const/headers";
import { ListContentJoinGroup } from "./script/ListContentJoinGroup";
import { RiExpandDiagonal2Line } from "react-icons/ri";
export const ModalCreateScriptJoinGroup = ({
  props,
  openCategory,
  setOpenCategory,
  setRefresh,
  refresh,
  dataFb,
  wsResults,
}) => {
  const headers = useHeaders();
  const deleteResultGroup = API_URL + API_ROUTES_NTD.DELETE_RESULTS_JOIN_GROUP;
  const joinGroup = API_URL + API_ROUTES_NTD.JOIN_GROUP;
  const [listResults, setListResults] = useState([]);
  const [listImage, setListImage] = useState([]);
  const [statusScan, setStatusScan] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [group, setGroup] = useState([]);
  const [ingredients, setIngredients] = useState({
    devide: false,
  });
  const [valueCmt, setValueCmt] = useState("");
  const [comments, setComments] = useState([]);
  const [delayTime, setDelayTime] = useState(30);
  const [numberCount, setNumberCount] = useState(0);
  const [waitTime, setWaitTime] = useState(0);
  const addJoinGroup = async () => {
    if (group?.length === 0) {
      alert("Vui lòng nhập đầy đủ thông tin");
    } else if (selectedItems?.length === 0) {
      alert("Vui lòng chọn tài khoản để chạy");
    } else {
      const body = {
        id_accounts: selectedItems?.map((item) => item.id),
        id_groups: group.split("\n").filter((i) => i !== ""),
        delay_time: delayTime,
        number_count: numberCount,
        wait_time: waitTime,
        devide: ingredients?.devide,
      };
      try {
        await axios.post(joinGroup, body, headers);
        toast.success("Đang chạy tham gia nhóm");
        setSelectedItems([]);
        setGroup([]);
        setRefresh(!refresh);
      } catch (err) {}
    }
  };
  const stopJoinGr = async () => {
    if (listResults?.length === 0) {
      alert("Chọn ít nhất 1 chiến dịch để xóa ");
      return;
    }
    const body = {
      id_campaigns: listResults?.map((item) => item.id),
    };
    try {
      await axios.post(deleteResultGroup, body, headers);
      setRefresh(!refresh);
      toast.success("Xóa chiến dịch thành công");
    } catch (err) {
      toast.error("Xóa chiến dịch thất bại");
    }
  };
  return (
    <Dialog
      header="Tham gia nhóm"
      visible={openCategory}
      style={{ position: "relative", width: "80vw" }}
      onHide={() => setOpenCategory(false)}
      className="overFlowJoinGr"
      footer={
        <div className="d-flex justify-content-end gap-2 mt-2">
          <Button
            onClick={addJoinGroup}
            className="d-flex justify-content-center button-blue"
            style={{
              width: "180px",
            }}
            disabled={statusScan}
          >
            Chạy chiến dịch
          </Button>
          <Button
            severity="danger"
            className="d-flex justify-content-center button-red"
            style={{
              width: "180px",
            }}
            onClick={stopJoinGr}
          >
            Xóa chiến dịch
          </Button>
          <RiExpandDiagonal2Line
            size={20}
            style={{
              position: "absolute",
              right: "0",
              bottom: "0",
            }}
          />
        </div>
      }
    >
      <div className="d-flex flex-column h-100" style={{ overflow: "auto" }}>
        <div className="p-inputgroup flex-1 w-100 gap-2">
          <div className="d-flex flex-column w-100 gap-2">
            <nav className="d-flex gap-2">
              <span>Thời gian chờ (Giây)</span>
              <OverlayTrigger
                key="tooltip"
                placement="bottom"
                overlay={
                  <Tooltip id="tooltip-right" className="custom-tooltip">
                    <div className="text-start">
                      Thời gian chờ giữa mỗi lần tham gia vào nhóm
                      <br />- Ví dụ: Bạn tham gia vào 3 nhóm thì sau khi tham
                      gia vào nhóm thứ nhất sẽ chờ một khoảng thời gian do bạn
                      cài đặt rồi mới tham gia tiếp theo vào nhóm thứ 2. Thời
                      gian chờ tối thiểu là 120s và khuyến khích bạn nên để là
                      600s để an toàn cho tài khoản. Hành động tham gia nhóm quá
                      nhanh sẽ dễ bị hạn chế tính năng trên tài khoản do cơ chế
                      chống spam của Facebook
                    </div>
                  </Tooltip>
                }
              >
                <span
                  style={{
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                >
                  <IoInformationCircle size={15} />
                </span>
              </OverlayTrigger>
            </nav>
            <InputNumber
              style={{ width: "100%" }}
              value={delayTime}
              placeholder="Thời gian tính bằng giây (s)"
              onValueChange={(e) => setDelayTime(e.value)}
              useGrouping={false}
              min={120}
            />
          </div>
          <div className="d-flex flex-column w-100 gap-2">
            <nav className="d-flex gap-2">
              <span>Số lượt</span>
              <OverlayTrigger
                key="tooltip"
                placement="bottom"
                overlay={
                  <Tooltip id="tooltip-right" className="custom-tooltip">
                    <div className="text-start">
                      Tài khoản đi tham gia vào 1 nhóm được tính là 1 lượt. Nên
                      để số lượt là 1. Và cài đặt thêm thời gian chờ lặp lại ở ô
                      bên cạnh
                    </div>
                  </Tooltip>
                }
              >
                <span
                  style={{
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                >
                  <IoInformationCircle size={15} />
                </span>
              </OverlayTrigger>
            </nav>
            <InputNumber
              style={{ width: "100%" }}
              value={numberCount}
              placeholder=""
              onValueChange={(e) => setNumberCount(e.value)}
              useGrouping={false}
              min={1}
            />
          </div>
          <div className="d-flex flex-column w-100 gap-2">
            <nav className="d-flex gap-2">
              <span style={{ width: "" }}>Thời gian chờ lặp lại (Giờ)</span>
              <OverlayTrigger
                key="tooltip"
                placement="bottom"
                overlay={
                  <Tooltip id="tooltip-right" className="custom-tooltip">
                    <div className="text-start">
                      Chức năng này có tác dụng khi bạn cài đặt số lượt (số lượt
                      được cài đặt ở ô bên cạnh). Chức năng này giúp chạy tiếp
                      chiến dịch hoàn toàn tự động khi bạn đặt số lượt ở ô bên
                      cạnh. Nên để là 3 giờ. Hành động tham gia nhóm quá nhanh
                      sẽ dễ bị hạn chế tính năng trên tài khoản do cơ chế chống
                      spam của Facebook
                    </div>
                  </Tooltip>
                }
              >
                <span
                  style={{
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                >
                  <IoInformationCircle size={15} />
                </span>
              </OverlayTrigger>
            </nav>
            <InputNumber
              style={{ width: "100%" }}
              value={waitTime}
              placeholder="Thời gian tính bằng giờ (h)"
              onValueChange={(e) => setWaitTime(e.value)}
              useGrouping={false}
              min={1}
            />
          </div>
        </div>
        <ListContentJoinGroup
          refresh={refresh}
          setRefresh={setRefresh}
          ingredients={ingredients}
          setIngredients={setIngredients}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          group={group}
          setGroup={setGroup}
          dataFb={dataFb}
          comments={comments}
          listImage={listImage}
          setListImage={setListImage}
          valueCmt={valueCmt}
          setComments={setComments}
          setValueCmt={setValueCmt}
          listResults={listResults}
          setListResults={setListResults}
          wsResults={wsResults}
        />
      </div>
    </Dialog>
  );
};
