import { useAuth } from "../AuthContext";
export const useHeaders = () => {
  const { token } = useAuth();
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return headers;
};
