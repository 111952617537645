import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import axios from "axios";
import { useHeaders } from "../../../../const/headers";
import { API_URL, API_ROUTES_NTD } from "../../../../api";
import DatePicker from "react-datepicker";
import { InputTextarea } from "primereact/inputtextarea";
import { forwardRef } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { vi } from "date-fns/locale";
import { format } from "date-fns";
import { Checkbox } from "primereact/checkbox";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { IoInformationCircle } from "react-icons/io5";
import { RiExpandDiagonal2Line } from "react-icons/ri";
export const CreateScriptReup = ({
  openAddScript,
  setOpenAddScript,
  setRefresh,
  refresh,
}) => {
  const headers = useHeaders();
  const createCateArticlePost =
    API_URL + API_ROUTES_NTD.ARTICLE_REUP.CREATE_OR_EDIT;
  const [name, setName] = useState("");
  const [listUid, setListUid] = useState("");
  const [checked, setChecked] = useState(false);
  const [checkedOn, setCheckedOn] = useState(false);
  const addCateMkt = async () => {
    if (!name || !listUid) {
      alert("Vui lòng nhập đầy đủ thông tin");
    } else {
      const body = {
        id_category: null,
        name: name,
        list_UID: listUid?.split("\n"),
        schedule: checkedOn ? format(startDate, "HH:mm:00") || null : null,
        loop: checked,
      };
      try {
        await axios.post(createCateArticlePost, body, headers);
        setName("");
        setListUid("");
        setOpenAddScript(!openAddScript);
        setRefresh(!refresh);
      } catch (err) {}
    }
  };
  const [startDate, setStartDate] = useState(() => {
    const date = new Date();
    date.setHours(6, 0, 0, 0);
    return date;
  });
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button className="example-custom-input radius" onClick={onClick} ref={ref}>
      {value}
    </button>
  ));
  const handleStartDateChange = (date) => {
    setStartDate(date);
  };
  return (
    <Dialog
      header="Kịch bản sao chép bài viết"
      visible={openAddScript}
      style={{ position: "relative", width: "70vw" }}
      onHide={() => setOpenAddScript(false)}
      className="overFlowJoinGr"
      footer={
        <div className="d-flex justify-content-end gap-2 mt-2">
          <Button className="button-blue radius" onClick={addCateMkt}>
            Lưu kịch bản
          </Button>
          <Button
            className="button-red radius"
            onClick={() => setOpenAddScript(false)}
          >
            Thoát không lưu
          </Button>
          <RiExpandDiagonal2Line
            size={20}
            style={{
              position: "absolute",
              right: "0",
              bottom: "0",
            }}
          />
        </div>
      }
    >
      <div className="d-flex flex-column h-100" style={{ overflow: "auto" }}>
        <div className="p-inputgroup flex-1 mb-2">
          <span className="p-inputgroup-addon " style={{ width: "9rem" }}>
            Tên kịch bản
          </span>
          <InputText
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Nhập tên kịch bản"
          />
        </div>
        <div className="d-flex flex-column gap-2">
          <div className="d-flex gap-2">
            <Checkbox
              onChange={(e) => setCheckedOn(e.checked)}
              checked={checkedOn}
            ></Checkbox>
            <nav className="d-flex gap-2 align-items-center">
              <p style={{ whiteSpace: "nowrap" }}>Đặt lịch: </p>
              <p style={{ whiteSpace: "nowrap" }}>Chọn giờ </p>
              <DatePicker
                locale={vi}
                selected={startDate}
                onChange={handleStartDateChange}
                customInput={<ExampleCustomInput />}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Giờ"
                dateFormat="HH:mm"
                placeholderText="Chọn giờ"
                showPopperArrow={false}
              />
            </nav>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <p style={{ whiteSpace: "nowrap" }}>Lặp lại mỗi ngày </p>
              <Checkbox
                onChange={(e) => setChecked(e.checked)}
                checked={checked}
              ></Checkbox>
            </div>
          </div>
        </div>
        <nav className="d-flex align-items-center gap-2 mt-2">
          <nav className="d-flex gap-2">
            <span>Danh sách UID</span>
            <OverlayTrigger
              key="tooltip"
              placement="top"
              overlay={
                <Tooltip id="tooltip-right" className="custom-tooltip">
                  <div className="text-start">
                    Được phép nhập nhiều UID. Hệ thống sẽ lấy 1 bài viết mới
                    nhất của 1 UID được chọn ngẫu nhiên trong các UID và đăng
                    vào khung giờ được chọn. Hệ thống sẽ sao chép bài viết mới
                    và đăng lại mỗi ngày nếu tích chọn chức năng lặp lại mỗi
                    ngày
                    <br />
                    - Trường hợp 1: Chỉ có 1 UID nguồn để sao chép thì hệ thống
                    sẽ tự động lọc trùng và bỏ qua bài viết đã sao chép, đến hôm
                    sau sẽ quay lại.
                    <br />
                    - Trường hợp 2: Có nhiều UID nguồn để sao chép thì hệ thống
                    sẽ lấy 1 UID ngẫu nhiên và lọc trùng bài viết đã sao chép
                    trước khi sao chép. Nếu UID này không có bài viết mới. Hệ
                    thống sẽ tự động chuyển sang UID ngẫu nhiên thứ 2
                    <br />- Trường hợp 3: Tất cả các UID nguồn đều không có bài
                    viết mới hệ thống sẽ tự động bỏ qua đến hôm sau sẽ quay lại
                  </div>
                </Tooltip>
              }
            >
              <span
                style={{
                  cursor: "pointer",
                  fontWeight: "bold",
                }}
              >
                <IoInformationCircle size={15} />
              </span>
            </OverlayTrigger>
          </nav>
        </nav>
        <div className="p-inputgroup flex-1 mt-2">
          <InputTextarea
            value={listUid}
            onChange={(e) => setListUid(e.target.value)}
            placeholder={`Nhập danh sách UID`}
            rows={5}
            cols={30}
          />
        </div>
      </div>
    </Dialog>
  );
};
