import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import axios from "axios";
import { useHeaders } from "../../../const/headers";
import { API_URL, API_ROUTES_NTD } from "../../../api";
import { Checkbox } from "primereact/checkbox";
import { ListMessClient } from "./script/ListMessClient";
import { ListCommentsClient } from "./script/ListCommentsClient";
import { InputNumber } from "primereact/inputnumber";
import { Tooltip, OverlayTrigger, Popover } from "react-bootstrap";
import { IoInformationCircle } from "react-icons/io5";
import { RiExpandDiagonal2Line } from "react-icons/ri";
import { InputTextarea } from "primereact/inputtextarea";
export const ModalCreateCateClient = ({
  openCategory,
  setOpenCategory,
  setRefresh,
  refresh,
}) => {
  const headers = useHeaders();
  const createCateMkt = API_URL + API_ROUTES_NTD.CREATE_EDIT_CATEGORY_CLIENT;
  const [listImage, setListImage] = useState([]);
  const [listImageInbox, setListImageInbox] = useState([]);
  const [name, setName] = useState("");
  const [ingredients, setIngredients] = useState({
    like: false,
    comment: false,
    share: false,
    addfr: false,
    mess: false,
  });
  const [valueCmt, setValueCmt] = useState("");
  const [valueInbox, setValueInbox] = useState("");
  const [uidTag, setUidTag] = useState("");
  const [comments, setComments] = useState([]);
  const [inbox, setInbox] = useState([]);
  const [inboxSpilt, setInboxSplit] = useState([]);
  const [delayTime, setDelayTime] = useState(60);
  const [numberCount, setNumberCount] = useState(0);
  const [waitTime, setWaitTime] = useState(0);
  const onIngredientsChange = (e) => {
    const { value, checked } = e.target;
    setIngredients((prevIngredients) => ({
      ...prevIngredients,
      [value]: checked,
    }));
  };
  const addCateMkt = async () => {
    if (!name || !delayTime) {
      alert("Vui lòng nhập đầy đủ thông tin");
    } else if (
      ingredients.comment === false &&
      ingredients.like === false &&
      ingredients.mess === false
    ) {
      alert("Vui lòng chọn chức năng");
    } else if (ingredients.comment === true && comments?.length === 0) {
      alert("Vui lòng nhập nội dung bình luận");
    } else if (ingredients.mess === true && inbox?.length === 0) {
      alert("Vui lòng nhập nội dung tin nhắn");
    } else if (ingredients.comment === true && comments?.length < 5) {
      alert("kịch bản bình luận cần tối thiếu 5 nội dung khác nhau");
    } else if (ingredients.mess === true && inbox?.length < 5) {
      alert("kịch bản tin nhắn cần tối thiếu 5 nội dung khác nhau");
    } else {
      const body = {
        id_category: null,
        name: name,
        message: ingredients.mess,
        like: ingredients.like,
        comment: ingredients.comment,
        delay_time: delayTime,
        comment_contents: comments,
        comment_images: listImage,
        message_contents: inbox
          ?.map((item) => item)
          ?.map((subArray) => {
            if (subArray?.length > 1) {
              return subArray?.split("[ngatcau]");
            }
            return subArray[0];
          }),
        message_images: listImageInbox,
        number_count: numberCount,
        wait_time: waitTime,
        tag: uidTag,
      };
      try {
        const res = await axios.post(createCateMkt, body, headers);
        setInboxSplit([]);
        setInbox([]);
        setComments([]);
        setName("");
        setListImage([]);
        setListImageInbox([]);
        setOpenCategory(!openCategory);
        setRefresh(!refresh);
      } catch (err) {}
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };
  return (
    <Dialog
      header="Kịch bản"
      visible={openCategory}
      style={{ position: "relative", width: "70vw" }}
      onHide={() => setOpenCategory(false)}
      className="overFlowJoinGr"
      resizable={true}
      footer={
        <div
          className="d-flex justify-content-end align-items-center"
          style={{ minHeight: "50px" }}
        >
          <Button className="button-blue radius" onClick={addCateMkt}>
            Lưu kịch bản
          </Button>
          <Button
            onClick={() => setOpenCategory(false)}
            className="button-red radius"
          >
            Thoát không lưu
          </Button>
          <RiExpandDiagonal2Line
            size={20}
            style={{
              position: "absolute",
              right: "0",
              bottom: "0",
            }}
          />
        </div>
      }
    >
      <div className="d-flex flex-column h-100" style={{ overflow: "auto" }}>
        <div className="p-inputgroup flex-1 mb-2">
          <span className="p-inputgroup-addon" style={{ width: "9rem" }}>
            Tên kịch bản
          </span>

          <InputText
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder=""
          />
        </div>
        <div className="p-inputgroup flex-1 w-100">
          <div className="d-flex flex-column w-100 gap-2">
            <nav className="d-flex gap-2">
              <span>Thời gian chờ (Giây)</span>
              <OverlayTrigger
                key="tooltip"
                placement="top"
                overlay={
                  <Tooltip id="tooltip-right" className="custom-tooltip">
                    <div className="text-start">
                      Thời gian chờ giữa mỗi hành động trong một chuỗi nhiều
                      hành động
                      <br />- Ví dụ: Bạn tích chọn 3 chức năng "Gửi tin nhắn +
                      Bình luận + Like bài viết". Thì đây là một chuỗi 3 hành
                      động liên tiếp. Bạn nên để thời gian chờ tối thiểu là 60s
                      và khuyến khích là 180s để an toàn cho tài khoản. Hành
                      động quá nhanh sẽ dễ bị hạn chế tính năng trên tài khoản
                      do cơ chế chống spam của Facebook
                    </div>
                  </Tooltip>
                }
              >
                <span
                  style={{
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                >
                  <IoInformationCircle size={15} />
                </span>
              </OverlayTrigger>
            </nav>

            <InputNumber
              style={{ width: "100%" }}
              value={delayTime}
              placeholder="Thời gian tính bằng giây (s)"
              onValueChange={(e) => setDelayTime(e.value)}
              useGrouping={false}
              min={60}
            />
          </div>
          <div className="d-flex flex-column w-100 gap-2">
            <nav className="d-flex gap-2">
              <span>Số lượt</span>
              <OverlayTrigger
                key="tooltip"
                placement="top"
                overlay={
                  <Tooltip id="tooltip-right" className="custom-tooltip">
                    <div className="text-start">
                      Tài khoản đi tương tác với mỗi một khách hàng được tính là
                      1 lượt. Nên để số lượt là 1. Và cài đặt thêm thời gian chờ
                      lặp lại ở ô bên cạnh
                    </div>
                  </Tooltip>
                }
              >
                <span
                  style={{
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                >
                  <IoInformationCircle size={15} />
                </span>
              </OverlayTrigger>
            </nav>
            <InputNumber
              style={{ width: "100%" }}
              value={numberCount}
              placeholder=""
              onValueChange={(e) => setNumberCount(e.value)}
              useGrouping={false}
              min={1}
            />
          </div>
          <div className="d-flex flex-column w-100 gap-2">
            <nav className="d-flex gap-2">
              <span style={{ width: "" }}>Thời gian chờ lặp lại (Giờ)</span>
              <OverlayTrigger
                key="tooltip"
                placement="top"
                overlay={
                  <Tooltip id="tooltip-right" className="custom-tooltip">
                    <div className="text-start">
                      Chức năng này có tác dụng khi bạn cài đặt số lượt (số lượt
                      được cài đặt ở ô bên cạnh) Chức năng này giúp chạy tiếp
                      chiến dịch hoàn toàn tự động khi bạn đặt số lượt ở ô bên
                      cạnh. Nên để thời gian là 1 giờ
                    </div>
                  </Tooltip>
                }
              >
                <span
                  style={{
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                >
                  <IoInformationCircle size={15} />
                </span>
              </OverlayTrigger>
            </nav>
            <InputNumber
              style={{ width: "100%" }}
              value={waitTime}
              placeholder="Thời gian tính bằng giờ (h)"
              onValueChange={(e) => setWaitTime(e.value)}
              useGrouping={false}
              min={1}
            />
          </div>
        </div>
        <div className="flex flex-wrap justify-content-between gap-3 mt-2">
          <div className="flex align-items-center">
            <Checkbox
              inputId="ingredient4"
              name="pizza"
              value="mess"
              onChange={onIngredientsChange}
              checked={ingredients.mess}
            />
            <label htmlFor="ingredient4" className="ml-2">
              Gửi tin nhắn
            </label>
          </div>
          <div className="flex align-items-center">
            <Checkbox
              inputId="ingredient2"
              name="pizza"
              value="comment"
              onChange={onIngredientsChange}
              checked={ingredients.comment}
            />
            <label htmlFor="ingredient2" className="ml-2">
              Bình luận bài viết
            </label>
          </div>

          <div className="flex align-items-center">
            <Checkbox
              inputId="ingredient1"
              name="pizza"
              value="like"
              onChange={onIngredientsChange}
              checked={ingredients.like}
            />
            <label htmlFor="ingredient1" className="ml-2">
              Like bài viết
            </label>
          </div>
        </div>
        <nav className="mt-2" style={{ width: "50%" }}>
          <div className="d-flex">
            <p className="mb-2 mr-2">UID được tag</p>
            <OverlayTrigger
              key="tooltip"
              placement="bottom"
              overlay={
                <Tooltip id="tooltip-right" className="custom-tooltip">
                  <div className="text-start">
                    - Chỉ nhập duy nhất 1 UID
                    <br />- Ví dụ: UID Facebook của Fanpage, Group
                  </div>
                </Tooltip>
              }
            >
              <span
                style={{
                  cursor: "pointer",
                  fontWeight: "bold",
                }}
              >
                <IoInformationCircle size={15} />
              </span>
            </OverlayTrigger>
          </div>
          <InputTextarea
            style={{ width: "100%", height: "80%" }}
            value={uidTag}
            onChange={(e) => setUidTag(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder=""
            rows={1}
            cols={30}
          />
        </nav>
        <ListCommentsClient
          comments={comments}
          listImage={listImage}
          setListImage={setListImage}
          valueCmt={valueCmt}
          setComments={setComments}
          setValueCmt={setValueCmt}
        />
        <ListMessClient
          listImageInbox={listImageInbox}
          setListImageInbox={setListImageInbox}
          valueInbox={valueInbox}
          setInbox={setInbox}
          inbox={inbox}
          setValueInbox={setValueInbox}
          inboxSpilt={inboxSpilt}
          setInboxSplit={setInboxSplit}
        />
      </div>
      {/* <div className="d-flex justify-content-end gap-2 mt-2">
        <Button className="button-blue radius" onClick={addCateMkt}>
          Lưu kịch bản
        </Button>
        <Button
          onClick={() => setOpenCategory(false)}
          className="button-red radius"
        >
          Thoát không lưu
        </Button>
      </div> */}
    </Dialog>
  );
};
