import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./AuthContext";
import { UserProvider } from "./contexts/UserContext";
import { PrimeReactProvider } from "primereact/api";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primeflex/primeflex.css";
import "primereact/resources/primereact.css";
import i18n from "./i18n";
import { I18nextProvider } from "react-i18next";
import Fingerprint2 from "fingerprintjs2";
// import 'bootstrap/dist/css/bootstrap.min.css';
import { addLocale, locale } from "primereact/api";
import LongContentDemo from "./components/container/modalDialog/ModalDialog";
import DOMPurify from "dompurify";
import { StatusProvider } from "./contexts/StatusContext";
import ModalFromRedue from "./components/container/redue/ModalFromRedue";
const root = ReactDOM.createRoot(document.getElementById("root"));

addLocale("vi", {
  firstDayOfWeek: 1,
  dayNames: [
    "Chủ Nhật",
    "Thứ Hai",
    "Thứ Ba",
    "Thứ Tư",
    "Thứ Năm",
    "Thứ Sáu",
    "Thứ Bảy",
  ],
  dayNamesShort: ["CN", "T2", "T3", "T4", "T5", "T6", "T7"],
  dayNamesMin: ["CN", "T2", "T3", "T4", "T5", "T6", "T7"],
  monthNames: [
    "Tháng 1",
    "Tháng 2",
    "Tháng 3",
    "Tháng 4",
    "Tháng 5",
    "Tháng 6",
    "Tháng 7",
    "Tháng 8",
    "Tháng 9",
    "Tháng 10",
    "Tháng 11",
    "Tháng 12",
  ],
  monthNamesShort: [
    "Th.1",
    "Th.2",
    "Th.3",
    "Th.4",
    "Th.5",
    "Th.6",
    "Th.7",
    "Th.8",
    "Th.9",
    "Th.10",
    "Th.11",
    "Th.12",
  ],
  today: "Hôm Nay",
  clear: "Xóa",
});
addLocale("vi", {
  startsWith: "Bắt đầu với",
  contains: "Chứa",
  notContains: "Không chứa",
  endsWith: "Kết thúc với",
  equals: "Bằng",
  notEquals: "Không bằng",
  noFilter: "Không lọc",
  lt: "Nhỏ hơn",
  lte: "Nhỏ hơn hoặc bằng",
  gt: "Lớn hơn",
  gte: "Lớn hơn hoặc bằng",
  dateIs: "Ngày là",
  dateIsNot: "Ngày không là",
  dateBefore: "Ngày trước",
  dateAfter: "Ngày sau",
  clear: "Xóa",
  apply: "Áp dụng",
  matchAll: "Khớp tất cả",
  matchAny: "Khớp bất kỳ",
  addRule: "Thêm quy tắc",
  removeRule: "Xóa quy tắc",
  accept: "Đồng ý",
  reject: "Từ chối",
  choose: "Chọn",
  upload: "Tải lên",
  cancel: "Hủy",
  dayNames: [
    "Chủ nhật",
    "Thứ hai",
    "Thứ ba",
    "Thứ tư",
    "Thứ năm",
    "Thứ sáu",
    "Thứ bảy",
  ],
  dayNamesShort: ["CN", "T2", "T3", "T4", "T5", "T6", "T7"],
  dayNamesMin: ["CN", "T2", "T3", "T4", "T5", "T6", "T7"],
  monthNames: [
    "Tháng 1",
    "Tháng 2",
    "Tháng 3",
    "Tháng 4",
    "Tháng 5",
    "Tháng 6",
    "Tháng 7",
    "Tháng 8",
    "Tháng 9",
    "Tháng 10",
    "Tháng 11",
    "Tháng 12",
  ],
  monthNamesShort: [
    "Th1",
    "Th2",
    "Th3",
    "Th4",
    "Th5",
    "Th6",
    "Th7",
    "Th8",
    "Th9",
    "Th10",
    "Th11",
    "Th12",
  ],
  today: "Hôm nay",
  weekHeader: "Tuần",
  firstDayOfWeek: 1,
  dateFormat: "dd/mm/yy",
  weak: "Yếu",
  medium: "Trung bình",
  strong: "Mạnh",
  passwordPrompt: "Nhập mật khẩu",
  emptyFilterMessage: "Không tìm thấy kết quả",
  emptyMessage: "",
});
Fingerprint2.get((components) => {
  const values = components.map((component) => component.value);
  const murmur = Fingerprint2.x64hash128(values.join(""), 31);
});
const rawHTML = ``;
const cleanHTML = DOMPurify.sanitize(rawHTML);

root.render(
  <React.StrictMode>
    <AuthProvider>
      <UserProvider>
        <StatusProvider>
          <I18nextProvider i18n={i18n}>
            <PrimeReactProvider>
              <LongContentDemo />
              <div dangerouslySetInnerHTML={{ __html: cleanHTML }} />
              <App />
              <ModalFromRedue />
            </PrimeReactProvider>
          </I18nextProvider>
        </StatusProvider>
      </UserProvider>
    </AuthProvider>
  </React.StrictMode>
);

reportWebVitals();
