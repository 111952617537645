import { useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { API_URL, API_ROUTES_NTD } from "../../api";
import axios from "axios";
import { InputText } from "primereact/inputtext";
import { useLocation } from "react-router-dom";
import { forwardRef } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { removeItem, formatText } from "../../const/splittext";
import { InputTextarea } from "primereact/inputtextarea";
import { FaSearch } from "react-icons/fa";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { IoInformationCircle } from "react-icons/io5";
import { Button } from "primereact/button";
import { truncateLabel } from "../../const/splittext";
import { Sidebar } from "primereact/sidebar";
import { TiDelete } from "react-icons/ti";
import "./MarketPlace.css";

export const DropdownFilterLocation = ({
  setFilterKey,
  filerKey,
  headers,
  location,
  setLocation,
  setMessMarket,
  messMarket,
}) => {
  const locations = useLocation();
  const [inbox, setInbox] = useState("");
  const [activeIndex, setActiveIndex] = useState(null);
  const [value, setValue] = useState("");
  const apiFilterLoca = API_URL + API_ROUTES_NTD.GET_LOCATION_MARKETPLACE;
  const FilterLoca = async () => {
    const body = { location_name: value };
    try {
      const res = await axios.post(apiFilterLoca, body, headers);
      setLocation(res.data);
    } catch (err) {}
  };
  const handleKeyCmt = (e) => {
    if (messMarket?.length > 4) {
      alert("Chỉ cho phép nhập ít hơn 5 kịch bản");
      return;
    }
    if (e.type === "keydown") {
      return;
    }
    e.preventDefault();
    if (inbox?.trim() !== "") {
      setMessMarket((prevComments) => [...prevComments, inbox.trim()]);
      setInbox("");
    }
  };
  return (
    <div className="mb-2 mt-2 flex justify-content-center flex-column align-items-center w-100">
      {locations.pathname?.split("/")[2] && (
        <>
          <p style={{ fontSize: "16px" }} className="w-100 mb-2 ">
            Vị trí hiện tại
          </p>
          <InputText
            className="w-100 input-placeholder-large"
            placeholder={filerKey?.name}
            disabled
          />
        </>
      )}
      <div className="d-flex justify-content-start gap-2 w-100">
        <p className=" mb-2 fs-6">
          {locations.pathname?.split("/")[2]
            ? "Chọn vị trí mới"
            : "Chọn vị trí"}
        </p>
        <OverlayTrigger
          key="tooltip"
          placement="right"
          overlay={
            <Tooltip id="tooltip-right" className="custom-tooltip ">
              <div className="text-start custom-tooltip-content">
                Vị trí theo địa lý google map
                <br />
                - Vị trí này quyết định hiển thị của bài viết bán hàng của bạn.
                <br />- Bạn có thể tìm kiếm và chọn vị trí phù hợp với kế hoạch
                bán hàng của bạn bằng cách nhập từ khóa vị trí ví dụ Hà Nội và
                bấm tìm kiếm hoặc phím Enter
              </div>
            </Tooltip>
          }
        >
          <span
            style={{
              cursor: "pointer",
              fontWeight: "bold",
            }}
          >
            <IoInformationCircle size={15} />
          </span>
        </OverlayTrigger>
      </div>
      <nav className="w-100 position-relative">
        <form
          onSubmit={(e) => {
            e.preventDefault(); // Prevent the default form submit action
            FilterLoca(); // Call your FilterLoca function
          }}
        >
          <InputText
            className="w-100 input-placeholder-large"
            placeholder="Nhập tỉnh / thành phố"
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
            }}
          />
          <FaSearch
            onClick={FilterLoca}
            size={20}
            className="position-absolute "
            style={{ right: "10px", top: "10px", cursor: "pointer" }}
          />
        </form>
      </nav>
      <Dropdown
        value={filerKey}
        onChange={(e) => {
          setFilterKey(e.value);
        }}
        options={location.map((loc, index) => ({
          name: loc.name,
          code: index,
          latitude: loc.latitude,
          longitude: loc.longitude,
        }))}
        optionLabel="name"
        placeholder={`${location[0]?.name || ""}`}
        className="w-100 custom-dropdown"
      />
      <nav className="w-100 mt-2">
        <div className="d-flex gap-2 mb-2">
          <p className="fs-6">Tin nhắn tự động trả lời</p>
          <OverlayTrigger
            key="tooltip"
            placement="right"
            overlay={
              <Tooltip id="tooltip-right" className="custom-tooltip ">
                <div className="text-start custom-tooltip-content">
                  Nội dung này sẽ được trả lời tự động khi có tin nhắn đầu tiên
                  người mua hàng hỏi mua sản phẩm của bạn
                  <br />
                  - Bạn có thể nhập nội dung tùy ý bao gồm "còn, hết, giá bán,
                  địa chỉ, số điện thoại..." để người mua hàng liên hệ bạn nhanh
                  nhất có thể
                  <br />- Lưu ý : Nội dung này không bắt buộc điền "bạn có thể
                  để trống". Và không thể chỉnh sửa cài đặt lại sau khi đã đăng
                  bài.
                </div>
              </Tooltip>
            }
          >
            <span
              style={{
                cursor: "pointer",
                fontWeight: "bold",
              }}
            >
              <IoInformationCircle size={15} />
            </span>
          </OverlayTrigger>
        </div>
        <Button
          className="button-blue "
          style={{ padding: "6px 8px" }}
          onClick={handleKeyCmt}
        >
          Thêm nội dung
        </Button>
        <InputTextarea
          className="w-100 input-placeholder-large"
          placeholder="Tin nhắn đầu tiên"
          value={inbox}
          onChange={(e) => setInbox(e.target.value)}
        />
        <div className="d-flex gap-2 flex-wrap w-100 mt-2">
          {Array.isArray(messMarket) &&
            messMarket?.map((item, index) => (
              <div
                key={index}
                style={{
                  position: "relative",
                  display: "inline-block",
                }}
              >
                <Button
                  className="button-blue radius"
                  style={{ padding: "6px 8px", fontSize: "12px" }}
                  label={truncateLabel(item)}
                  onClick={() => setActiveIndex(index)}
                />{" "}
                <Sidebar
                  visible={activeIndex === index}
                  onHide={() => setActiveIndex(null)}
                >
                  {item.includes("[ngatcau]") ? (
                    <ul>
                      {item.split("[ngatcau]").map((text, index) => (
                        <li
                          key={index}
                          style={{
                            backgroundColor: "blue",
                            color: "white",
                            padding: "10px",
                            margin: "4px",
                            borderRadius: "10px",
                          }}
                        >
                          {formatText(text)}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p
                      style={{
                        backgroundColor: "blue",
                        color: "white",
                        padding: "10px",
                        margin: "4px",
                        borderRadius: "10px",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: item?.replace(/\n/g, "<br>"),
                      }}
                    />
                  )}
                </Sidebar>
                <TiDelete
                  className="p-button-rounded p-button-danger p-button-sm "
                  style={{
                    position: "absolute",
                    top: "-8px",
                    right: "-8px",
                    width: "25px",
                    height: "25px",
                    color: "red",
                    cursor: "pointer",
                  }}
                  onClick={() => removeItem(index, setMessMarket)}
                />
              </div>
            ))}
        </div>
      </nav>
    </div>
  );
};
