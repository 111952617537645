import React from "react";
import { Dialog } from "primereact/dialog";
import { Fieldset } from "primereact/fieldset";
import { Avatar } from "primereact/avatar";
import { Chip } from "primereact/chip";
import { API_URL, API_ROUTES_NTD } from "../../../api";
import axios from "axios";
import { useHeaders } from "../../../const/headers";
export const ModalCateMarket = ({
  setModalManage,
  modalManage,
  itemManage,
  setRefresh,
  refresh,
}) => {
  const headers = useHeaders();
  const deleteCate = API_URL + API_ROUTES_NTD.REMOVE_CATEGORY_MARKETPLACE;
  const handleRemoveChip = async (item) => {
    const confirm = window.confirm(
      `Nhấn oke để xoá kịch bản ${item?.name} khỏi tài khoản ${itemManage?.name}`
    );
    if (confirm) {
      const body = {
        id_category: item?.id,
        id_accounts: [itemManage?.id],
      };
      try {
        const res = await axios.post(deleteCate, body, headers);
        setRefresh(!refresh);
      } catch (err) {}
    }
  };
  const legendTemplate = (
    <div className="flex align-items-center gap-2 px-2">
      <Avatar image={itemManage?.avatar} shape="circle" />
      <span style={{ fontSize: "16px" }}>{itemManage?.name}</span>
    </div>
  );

  return (
    <Dialog
      header="Quản lý kịch bản"
      visible={modalManage}
      style={{ width: "50vw" }}
      onHide={() => setModalManage(false)}
    >
      <div className="card">
        <Fieldset legend={legendTemplate}>
          {itemManage?.category_marketplace?.map((item, index) => (
            <Chip
              style={{ margin: "4px" }}
              key={index}
              label={item?.name}
              removable
              onRemove={() => handleRemoveChip(item)}
            />
          ))}
        </Fieldset>
      </div>
    </Dialog>
  );
};
