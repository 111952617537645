import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useEffect, useState, useRef } from "react";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { Button } from "primereact/button";
import { API_URL, API_ROUTES_NTD, WS_URL } from "../../../../api";
import axios from "axios";
import { useHeaders } from "../../../../const/headers";
import { filterLatestUpdates } from "../../../../const/filter";
import {
  toggleSelectAll,
  headerCheckbox,
  bodyCheckbox,
  rowClassName,
  onRowClick,
} from "../../../../const/checkboxPrimeReactjs";
import { getAxiosResultsCategory } from "../../../../const/getAxios";
import { Paginator } from "primereact/paginator";
import { statusLineCheck } from "../../../../const/updateTitle";
import { truncateText } from "../../../../const/splittext";
import { sortDataByDate } from "../../../../const/sortData";
import { Dropdown } from "primereact/dropdown";

export const ResultsPost = ({
  setAllCate,
  setWsResults,
  wsResults,
  toast,
  setItemResults,
  setListResults,
  listResults,
  setTotalPage,
  totalPage,
  setGetTotal,
  getTotal,
  setRows,
  rows,
  setModalDetails,
  renderKey,
  allCate,
}) => {
  const headers = useHeaders();
  const deleteResultMkt = API_URL + API_ROUTES_NTD.ARTICLE_REELS.DELETE_RESULTS;
  const allResults = API_URL + API_ROUTES_NTD.ARTICLE_REELS.RESULTS;
  const getDataAllFr = () => {};
  const [first, setFirst] = useState(0);
  const rowsPerPageOptions = [100, 300, 500, 1000, 2000];
  const onPageChange = (event) => {
    setTotalPage(event.page);
    setFirst(event.first);
    setRows(event.rows);
  };
  const [seclecCate, setSelecCate] = useState(null);
  useEffect(() => {
    getAxiosResultsCategory(
      allResults,
      setWsResults,
      setGetTotal,
      rows,
      totalPage + 1,
      seclecCate?.id || "null"
    );
  }, [totalPage, rows, renderKey, seclecCate]);
  const filteredData = filterLatestUpdates(wsResults);
  const deleteResult = async (rowData) => {
    const body = { id_results: [rowData?.id] };
    try {
      const res = await axios.post(deleteResultMkt, body, headers);
      getAxiosResultsCategory(
        allResults,
        setWsResults,
        setGetTotal,
        rows,
        totalPage + 1,
        seclecCate?.id || "null"
      );
      toast.success("Xoá thành công");
    } catch (err) {}
  };
  const optionResults = (rowData) => {
    return (
      <>
        <Button
          className="button-blue radius"
          onClick={() => {
            setModalDetails(true);
            setItemResults(rowData);
          }}
          style={{ padding: "6px 8px", marginRight: "0.5rem" }}
        >
          Chi tiết
        </Button>
        <Button
          className="button-red radius"
          onClick={() => deleteResult(rowData)}
          severity="danger"
          style={{ padding: "6px 8px" }}
        >
          Xóa
        </Button>
      </>
    );
  };
  const date = (rowData) => {
    return (
      <>
        <p>{`${rowData?.created_at?.split("T")[1]?.split(".")[0]} - ${
          rowData?.created_at?.split("T")[0]
        }`}</p>
      </>
    );
  };
  const indexTemplate = (rowData, rowIndex) => {
    return <>{totalPage * rows + rowIndex.rowIndex + 1}</>;
  };
  const contentColumn = (rowData) => {
    return (
      <>
        <p>{truncateText(rowData?.content)}</p>
      </>
    );
  };
  const uidACc = (rowData) => {
    return (
      <>
        <p>{rowData?.account.UID}</p>
      </>
    );
  };
  const nameAcc = (rowData) => {
    return (
      <>
        <p>{rowData?.account.name}</p>
      </>
    );
  };
  return (
    <div className="d-flex flex-column h-100 w-100">
      <Dropdown
        value={seclecCate}
        onChange={(e) => setSelecCate(e.value)}
        options={[...allCate, { name: "Tất cả", id: null }]}
        optionLabel="name"
        placeholder="Chọn kịch bản"
        className="w-full md:w-14rem"
      />{" "}
      <div
        className="d-flex gap-2 flex-column h-100"
        style={{ border: "1px solid", overflow: "auto" }}
      >
        <DataTable
          size="small"
          value={sortDataByDate(filteredData)}
          className="small-font-table dataTableWithFixedHeaderMkt dataTableWithHoverEffect datatableoverflow"
          tableStyle={{ width: "100%", height: "86%" }}
          rowClassName={(rowData) => rowClassName(rowData, listResults)}
          onRowClick={(e) => onRowClick(e.data, setListResults, listResults)}
        >
          <Column
            header={headerCheckbox(
              listResults,
              filteredData,
              toggleSelectAll,
              setListResults
            )}
            body={(rowData) =>
              bodyCheckbox(rowData, listResults, setListResults, getDataAllFr)
            }
          />
          <Column body={indexTemplate} field="UID" header="STT"></Column>

          <Column
            field="created_at"
            body={(rowData) => date(rowData)}
            header="Thời gian"
            sortable
          ></Column>

          <Column body={(rowData) => uidACc(rowData)} header="UID"></Column>
          <Column
            body={(rowData) => nameAcc(rowData)}
            header="Tên tài khoản"
          ></Column>
          <Column
            body={(rowData) => contentColumn(rowData)}
            // field="content"
            header="Nội dung"
          ></Column>
          <Column
            body={(rowData) => statusLineCheck(rowData, "status")}
            header="Trạng thái"
          ></Column>
          <Column
            field=""
            body={(rowData) => optionResults(rowData)}
            header="Tuỳ chọn"
          ></Column>
        </DataTable>
        <Paginator
          className="pagination-markerting"
          first={first}
          rows={rows}
          totalRecords={getTotal?.count}
          onPageChange={onPageChange}
          rowsPerPageOptions={rowsPerPageOptions}
        />
      </div>
    </div>
  );
};
