import React, { useState, useEffect, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { MdAccountCircle } from "react-icons/md";
import moment from "moment-timezone";
import axios from "axios";
import { API_URL, API_ROUTES } from "../../../api";
import { useUser } from "../../../contexts/UserContext";
import { useTranslation } from "react-i18next";
import { useHeaders } from "../../../const/headers";
import Form from "react-bootstrap/Form";
import { Image } from "primereact/image";
export const CreateManualDebug = ({
  modalCreate,
  setModalCreate,
  setRefresh,
  refresh,
  getItemEdit,
}) => {
  const headers = useHeaders();
  const currentDate = moment().tz("Asia/Ho_Chi_Minh").toDate();
  const { userInfo, setUserInfo } = useUser();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [note, setNote] = useState("");
  const addManualDebug = API_URL + API_ROUTES.CREATE_MANUAL_DEBUG;
  const { t } = useTranslation();
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);
  const inputRef = useRef(null);
  const [imageSrc, setImageSrc] = useState(null);

  const createFileList = (file) => {
    const dataTransfer = new DataTransfer();
    dataTransfer.items.add(file);
    return dataTransfer.files;
  };
  const handlePaste = (event) => {
    const items = (event.clipboardData || event.originalEvent.clipboardData)
      .items;
    for (const item of items) {
      if (item.type.startsWith("image/")) {
        const file = item.getAsFile();
        fileInputRef.current.files = createFileList(file);
        setSelectedFile(file);
        break;
      }
    }
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };
  useEffect(() => {
    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageSrc(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    }
  }, [selectedFile]);
  const formData = new FormData();
  const addBug = async () => {
    formData.append("title", title);
    formData.append("description", description);
    formData.append("note", note);
    formData.append("image", selectedFile);
    try {
      const res = await axios.post(addManualDebug, formData, headers);
      setModalCreate(false);
      setTitle("");
      setDescription("");
      setNote("");
      setImageSrc(null);
      setRefresh(!refresh);
    } catch (err) {}
  };

  return (
    <div className=" flex justify-content-center">
      <Dialog
        header="Thêm Bug "
        visible={modalCreate}
        style={{ width: "50vw" }}
        onHide={() => setModalCreate(false)}
      >
        <div
          className="card flex flex-column flex-wrap md:flex-row gap-3"
          style={{ border: "0" }}
        >
          <div className="p-inputgroup ">
            <span className="p-inputgroup-addon" style={{ width: "6rem" }}>
              <p className="mb-0">Tiêu đề</p>
            </span>
            <InputText
              onChange={(e) => setTitle(e.target.value)}
              value={title}
            />
          </div>
          <div className="p-inputgroup ">
            <span className="p-inputgroup-addon" style={{ width: "6rem" }}>
              <p className="mb-0">Mô tả</p>
            </span>
            <InputText
              onChange={(e) => setDescription(e.target.value)}
              value={description}
            />
          </div>
          <div className="p-inputgroup ">
            <span className="p-inputgroup-addon" style={{ width: "6rem" }}>
              <p className="mb-0">Ghi chú</p>
            </span>
            <InputText onChange={(e) => setNote(e.target.value)} value={note} />
          </div>
          {/* <Form.Group controlId="formFile" className="mb-3 w-100">
            <Form.Control type="file" onChange={handleFileChange} />
          </Form.Group> */}
          <div className="p-inputgroup ">
            <span
              className="p-inputgroup-addon"
              style={{ width: "7rem", whiteSpace: "nowrap" }}
            >
              <p className="mb-0">Dán ảnh</p>
            </span>
            <input
              ref={inputRef}
              type="text"
              className="w-100 p-2"
              onPaste={handlePaste}
              placeholder="Dán ảnh"
              style={{
                fontSize: "14px",
                border: "1px solid #ced4da",
                borderBottomRightRadius: "5px",
                borderTopRightRadius: "5px",
              }}
            />
            <input
              ref={fileInputRef}
              type="file"
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
            {imageSrc && (
              <Image
                src={imageSrc}
                zoomSrc={imageSrc}
                alt="Image"
                width="80"
                height="60"
                preview
                style={{
                  position: "absolute",
                  bottom: "-59px",
                  right: "180px",
                }}
              />
            )}
          </div>
        </div>
        <div className="d-flex justify-content-end gap-2 mt-2">
          <Button
            className=" button-blue radius"
            label="Tạo"
            onClick={() => addBug()}
          />
          <Button
            className=" button-red radius"
            label="Hủy"
            onClick={() => setModalCreate(false)}
            severity="danger"
          />
        </div>
      </Dialog>
    </div>
  );
};
