import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import moment from "moment-timezone";
import axios from "axios";
import { API_URL, API_ROUTES } from "../../../api";
import { useUser } from "../../../contexts/UserContext";
import { useTranslation } from "react-i18next";
import { useHeaders } from "../../../const/headers";
import { Dropdown } from "primereact/dropdown";

export const DevConfirm = ({
  modalDev,
  setModalDev,
  setRefresh,
  refresh,
  itemConfirm,
}) => {
  const headers = useHeaders();
  const currentDate = moment().tz("Asia/Ho_Chi_Minh").toDate();
  const { userInfo, setUserInfo } = useUser();
  const [note, setNote] = useState(itemConfirm?.tester_note || "");
  const [status, setStatus] = useState(itemConfirm?.status || "");
  const devConf = API_URL + API_ROUTES.DEV_CONFIRM_MANUAL_DEBUG;
  const { t } = useTranslation();
  const listStatus = [
    { name: "Đã giải quyết", code: 2 },
    { name: "Hoàn thành", code: 3 },
    { name: "Lỗi", code: 0 },
  ];
  useEffect(() => {
    setNote(itemConfirm?.tester_note || "");
    setStatus(itemConfirm?.status || "");
  }, [modalDev]);
  const devConfirm = async () => {
    const body = {
      id_debug: itemConfirm.id,
      status: status?.code,
      note: note,
    };
    try {
      const res = await axios.post(devConf, body, headers);
      setModalDev(false);
      setRefresh(!refresh);
    } catch (err) {
    }
  };
  return (
    <div className=" flex justify-content-center">
      <Dialog
        header="Xác nhận "
        visible={modalDev}
        style={{ width: "50vw" }}
        onHide={() => setModalDev(false)}
      >
        <div
          className="card flex flex-column flex-wrap md:flex-row gap-3"
          style={{ border: "0" }}
        >
          <div className="p-inputgroup ">
            <span className="p-inputgroup-addon" style={{ width: "8rem" }}>
              <p className="mb-0">Ghi chú</p>
            </span>
            <InputText onChange={(e) => setNote(e.target.value)} value={note} />
          </div>
        </div>
        <div className=" flex justify-content-center">
          <Dropdown
            value={status}
            onChange={(e) => setStatus(e.value)}
            options={listStatus}
            optionLabel="name"
            placeholder="Trạng thái"
            className="w-100 mt-2"
          />
        </div>
        <div className="d-flex justify-content-end gap-2 mt-2">
          <Button
            className=" button-blue radius"
            label="Xác nhận"
            onClick={() => devConfirm()}
          />
          <Button
            className=" button-red radius"
            label="Hủy"
            onClick={() => setModalDev(false)}
            severity="danger"
          />
        </div>
      </Dialog>
    </div>
  );
};
