import axios from "axios";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useEffect, useRef, useState } from "react";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { API_ROUTES_NTD, API_URL, WS_URL } from "../../../api";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { AiOutlineCloseCircle } from "react-icons/ai";
import {
  bodyCheckbox,
  headerCheckbox,
  onRowClick,
  rowClassName,
  toggleSelectAll,
} from "../../../const/checkboxPrimeReactjs";
import { filterLatestUpdates } from "../../../const/filter";
import { useHeaders } from "../../../const/headers";
import { sortDateGroup } from "../../../const/sortData";
import { useUser } from "../../../contexts/UserContext";
import { Paginator } from "primereact/paginator";
import { getAxiosGroup } from "../../../const/getAxios";
export const ResultJoinGroup = ({
  setAllCateClient,
  toast,
  setOpenDetails,
  setItemResults,
  setListResults,
  listResults,
  setWsResults,
  wsResults,
  setTotalPage,
  totalPage,
  setGetTotal,
  getTotal,
  setRows,
  rows,
  isInitialRender,
  selectedItems,
  dataFb,
  setRefresh,
  refresh,
}) => {
  const headers = useHeaders();
  const allResults = API_URL + API_ROUTES_NTD.GET_RESULTS_JOIN_GROUP;
  const socketRef = useRef(null);
  const [results, setResults] = useState([]);
  const [joinGroup, setJoinGroup] = useState([]);
  const access = localStorage.getItem("access");
  const [renderKey, setRenderKey] = useState(Date.now());
  const getDataAllGr = () => {};
  const [first, setFirst] = useState(0);
  const { userInfo } = useUser();
  const rowsPerPageOptions = [100, 300, 500, 1000, 2000];
  const onPageChange = (event) => {
    setTotalPage(event.page);
    setFirst(event.first);
    setRows(event.rows);
  };
  useEffect(() => {
    getAxiosGroup(
      allResults,
      setResults,
      setGetTotal,
      rows,
      totalPage + 1,
      selectedItems?.length > 0 ? selectedItems[0].id : ""
    );
  }, [totalPage, rows, renderKey, selectedItems, refresh]);
  useEffect(() => {
    const client = new W3CWebSocket(
      `wss://${WS_URL}/ws/group/${userInfo?.id}/?token=${access}`
    );
    socketRef.current = client;
    client.onerror = function () {
      // console.error("Connection Error");
    };
    client.onopen = function () {};
    client.onmessage = function (message) {
      const parsedData = JSON.parse(message.data);
      if (parsedData.join_group !== undefined) {
        setRenderKey(Date.now());
      }
      if (parsedData.result !== undefined) {
        setRenderKey(Date.now());
      }
    };
    return () => {
      if (client) {
        client.close();
      }
    };
  }, []);

  const filteredData = filterLatestUpdates(results);

  // const showResult = async (selectedItems) => {
  //   const body = {
  //     id_account: selectedItems?.length > 0 ? selectedItems[0].id : null,
  //   };
  //   try {
  //     const res = await axios.post(allResults, body, headers);
  //     setResults(res.data.results);
  //   } catch (err) {}
  // };
  // useEffect(() => {
  //   showResult(selectedItems);
  // }, [selectedItems]);
  const uidPage = (rowData) => {
    return (
      <>
        <p>{rowData?.UID_group}</p>
      </>
    );
  };
  const uidACc = (rowData) => {
    const showUid = dataFb.filter((item) => item.id === rowData.account);

    return (
      <>
        <p>{showUid[0]?.UID}</p>
      </>
    );
  };
  const nameAcc = (rowData) => {
    const showName = dataFb.filter((item) => item.id === rowData.account);

    return (
      <>
        <p>{showName[0].name}</p>
      </>
    );
  };
  const dayAcc = (rowData) => {
    return (
      <>
        <p>{`${rowData?.run_at?.split("T")[1]?.split(".")[0]} - ${
          rowData?.run_at?.split("T")[0]
        }`}</p>
      </>
    );
  };
  const statusRunJoinGr = (rowData) => {
    return (
      <>
        <p>
          {rowData?.status === 0 ? (
            <AiOutlineCloseCircle
              size={20}
              style={{ color: "red" }}
              title="Thất bại"
            />
          ) : rowData?.status === 1 ? (
            <AiOutlineCheckCircle
              size={20}
              style={{ color: "green" }}
              title="Thành công"
            />
          ) : rowData?.status === 2 ? (
            ""
          ) : (
            ""
          )}
        </p>
      </>
    );
  };
  return (
    <div className="d-flex flex-column h-100 w-100 ">
      <DataTable
        size="small"
        value={sortDateGroup(results)}
        className="small-font-table dataTableWithFixedHeaderMkt dataTableWithHoverEffect datatableoverflow"
        tableStyle={{ width: "100%", height: "86%" }}
        rowClassName={(rowData) => rowClassName(rowData, listResults)}
        onRowClick={(e) => onRowClick(e.data, setListResults, listResults)}
      >
        <Column
          header={headerCheckbox(
            listResults,
            results,
            toggleSelectAll,
            setListResults
          )}
          body={(rowData) =>
            bodyCheckbox(rowData, listResults, setListResults, getDataAllGr)
          }
        />

        <Column
          field="UID_group"
          body={(rowData) => uidACc(rowData)}
          header="UID"
          sortable
        ></Column>
        <Column
          field="UID_group"
          body={(rowData) => nameAcc(rowData)}
          header="Tên tài khoản"
          sortable
        ></Column>
        <Column
          field="UID_group"
          body={(rowData) => uidPage(rowData)}
          header="UID nhóm"
          sortable
        ></Column>
        <Column
          field="run_at"
          body={(rowData) => dayAcc(rowData)}
          header="Thời gian chạy"
          sortable
        ></Column>
        <Column
          body={(rowData) => statusRunJoinGr(rowData, "status")}
          field="status"
          header="Trạng thái"
          sortable
        ></Column>
      </DataTable>
      <Paginator
        className="pagination-markerting"
        first={first}
        rows={rows}
        totalRecords={getTotal?.count}
        onPageChange={onPageChange}
        rowsPerPageOptions={rowsPerPageOptions}
      />
    </div>
  );
};
