import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import axios from "axios";
import { useHeaders } from "../../../const/headers";
import { API_URL, API_ROUTES } from "../../../api";
export const TokenAcc = ({ openToken, setOpenToken, t }) => {
  const headers = useHeaders();
  const getTokenSave = API_URL + API_ROUTES.GET_TOKEN_ACCOUNT;
  const newTokenAcc = API_URL + API_ROUTES.GET_NEW_TOKEN_ACCOUNT;
  const [tokenGet, setTokenGet] = useState([]);
  const fetchToken = async () => {
    try {
      const res = await axios.get(getTokenSave, headers);
      setTokenGet(res.data);
    } catch (err) {}
  };
  const fetchNewToken = async () => {
    try {
      const res = await axios.get(newTokenAcc, headers);
      setTokenGet(res.data);
    } catch (err) {}
  };
  useEffect(() => {
    fetchToken();
  }, [openToken]);
  const handleCopyClick = () => {
    if (tokenGet?.token) {
      navigator.clipboard
        .writeText(getProcessedToken(tokenGet?.token))
        .then(() => {})
        .catch((err) => {});
    }
  };
  const getProcessedToken = (token) => {
    if (!token) return "";
    if (token.startsWith("livechat-")) {
      token = token.replace("livechat-", "");
    }
    return `ntd-${token}`;
  };
  return (
    <div className="flex justify-content-center">
      <Dialog
        header={t("Mã đồng bộ")}
        visible={openToken}
        style={{ width: "50vw" }}
        onHide={() => setOpenToken(false)}
      >
        <div
          className="card flex flex-column flex-wrap md:flex-row gap-3"
          style={{ border: "0" }}
        >
          <div className="p-inputgroup mb-3">
            <InputText
              placeholder=""
              aria-label="Recipient's username"
              aria-describedby="basic-addon2"
              value={getProcessedToken(tokenGet?.token)}
            />
            <Button
              className="button-blue radius"
              onClick={handleCopyClick}
              id="button-addon2"
            >
              Copy
            </Button>
          </div>
          <nav>
            <p className="mb-0" style={{ color: "red", fontSize: "1rem" }}>
              {t("*Lưu ý: Không chia sẻ mã đồng bộ này với người khác")}
            </p>
          </nav>
        </div>
        <div className="d-flex justify-content-end gap-2 mt-2">
          <Button
            className="button-blue radius"
            onClick={() => fetchNewToken()}
          >
            {t("Làm mới")}
          </Button>

          <Button
            className="button-red radius"
            onClick={() => setOpenToken(false)}
          >
            {t("Thoát")}
          </Button>
        </div>
      </Dialog>
    </div>
  );
};
