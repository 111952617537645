import { Container, Row, Modal, Col, Button } from "react-bootstrap";
import { API_URL, API_ROUTES } from "../../../api";
import axios from "axios";
import { Form, Table } from "react-bootstrap";
import { InputNumber } from "primereact/inputnumber";
export const EditProducts = ({
  setRefresh,
  headers,
  props,
  productShow,
  setProductShow,
  selectedItemEdit,
  handleInputChangeE,
  editProducts,
  handleChangeEdit,
  handleRemoveEdit,
  setProductInfo,
  showFileInput,
  handleClickImage,
  handleEditProduct,
}) => {
  const editProduct = API_URL + API_ROUTES.GET_EDIT_PRODUCT;
  const editProductApi = async () => {
    try {
      const body = editProducts?.map((item, index) => ({
        id: item.id,
        order_code: selectedItemEdit.order_code,
        name: selectedItemEdit.name,
        phanloai1: item.phanloai1,
        phanloai2: item.phanloai2,
        price: Number(item.price),
        quantity: Number(item.inventory) || 0,
        thumbnail: item.imageProduct,
        detail: selectedItemEdit.detail,
      }));
      const hasNullThumbnail = body.some((item) => item.thumbnail === null);
      if (hasNullThumbnail) {
        alert("Vui lòng thêm ảnh");
        return;
      }
      const response = await axios.post(editProduct, body, headers);
      setRefresh(false);
      setProductShow(false);
      setProductInfo({
        order_code: "",
        name: "",
        thumbnail: "",
        category: "",
        price: "",
        inventory: "",
        detail: "",
      });
    } catch (error) {
    }
  };
  return (
    <Modal
      size="xl"
      backdrop="static"
      {...props}
      show={productShow}
      onHide={() => setProductShow(false)}
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Chỉnh sửa sản phẩm
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="gird">
        <Container>
          <Row>
            <Col className="w-100" xs={6} md={4}>
              <div className="input-group flex-nowrap mb-2">
                <div className="input-group-prepend">
                  <span
                    style={{ width: "220px" }}
                    className="input-group-text"
                    id="addon-wrapping"
                    type
                  >
                    Mã hàng
                  </span>
                </div>
                <input
                  value={selectedItemEdit.order_code}
                  disabled="true"
                  type="text"
                  className="form-control"
                  placeholder=""
                  aria-label="Username"
                  aria-describedby="addon-wrapping"
                />
              </div>
              <div className="input-group flex-nowrap mb-2">
                <div className="input-group-prepend">
                  <span
                    style={{ width: "220px" }}
                    className="input-group-text"
                    id="addon-wrapping"
                  >
                    Tên sản phẩm
                  </span>
                </div>
                <input
                  value={selectedItemEdit.name || ""}
                  onChange={(event) => handleInputChangeE(event, "name")}
                  type="text"
                  className="form-control"
                  aria-label="Username"
                  aria-describedby="addon-wrapping"
                />
              </div>
              <div className="d-flex list-phanloai-product">
                <div className="input-group flex-column mb-2">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text d-flex justify-content-center"
                      id="addon-wrapping"
                    >
                      Phân loại 1
                    </span>
                  </div>
                  {editProducts?.map((product, index) => (
                    <input
                      key={index}
                      value={product?.phanloai1 || ""}
                      onChange={(e) => handleChangeEdit(index, e)}
                      name="phanloai1"
                      type="text"
                      className="form-control w-100"
                      aria-label="Username"
                      aria-describedby="addon-wrapping"
                    />
                  ))}
                </div>
                <div className="input-group flex-column mb-2">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text d-flex justify-content-center"
                      id="addon-wrapping"
                    >
                      Phân loại 2
                    </span>
                  </div>
                  {editProducts?.map((product, index) => (
                    <input
                      key={index}
                      value={product?.phanloai2 || ""}
                      onChange={(e) => handleChangeEdit(index, e)}
                      name="phanloai2"
                      type="text"
                      className="form-control w-100"
                      aria-label="Username"
                      aria-describedby="addon-wrapping"
                    />
                  ))}
                </div>
                <div className="input-group flex-column mb-2">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text d-flex justify-content-center"
                      id="addon-wrapping"
                    >
                      Giá bán
                    </span>
                  </div>

                  {editProducts?.map((product, index) => (
                    <input
                      key={index}
                      value={product?.price || ""}
                      onChange={(e) => handleChangeEdit(index, e)}
                      type="number"
                      name="price"
                      min="0"
                      className="form-control w-100 text-end text-start-css"
                      aria-label="Username"
                      aria-describedby="addon-wrapping"
                    />
                  ))}
                </div>
                <div className="input-group flex-column mb-2">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text d-flex justify-content-center"
                      id="addon-wrapping"
                    >
                      Đã bán
                    </span>
                  </div>

                  {editProducts?.map((product, index) => (
                    <input
                      key={index}
                      disabled={true}
                      value={product?.soldquantity}
                      name="inventory"
                      type="number"
                      min="0"
                      className="form-control w-100 text-end text-start-css"
                      aria-label="Username"
                      aria-describedby="addon-wrapping"
                    />
                  ))}
                </div>
                <div className="input-group flex-column mb-2">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text d-flex justify-content-center text-start-css"
                      id="addon-wrapping"
                    >
                      Tồn kho
                    </span>
                  </div>

                  {editProducts?.map((product, index) => (
                    <input
                      key={index}
                      disabled={true}
                      value={product?.inventorys}
                      name="inventory"
                      type="number"
                      min="0"
                      className="form-control w-100 text-end text-start-css"
                      aria-label="Username"
                      aria-describedby="addon-wrapping"
                    />
                  ))}
                </div>
                <div className="input-group flex-column mb-2">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text d-flex justify-content-center "
                      id="addon-wrapping"
                    >
                      Số lượng tổng
                    </span>
                  </div>

                  {editProducts?.map((product, index) => (
                    <input
                      key={index}
                      disabled={true}
                      value={product?.totalquantity}
                      name="inventory"
                      type="number"
                      min="0"
                      className="form-control w-100 text-end text-start-css"
                      aria-label="Username"
                      aria-describedby="addon-wrapping"
                    />
                  ))}
                </div>
                <div className="input-group flex-column mb-2">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text d-flex justify-content-center"
                      id="addon-wrapping"
                    >
                      Nhập thêm
                    </span>
                  </div>

                  {editProducts?.map((product, index) => (
                    <input
                      key={index}
                      value={product?.inventory || ""}
                      onChange={(e) => handleChangeEdit(index, e)}
                      name="inventory"
                      type="number"
                      placeholder="0"
                      min="0"
                      className="form-control w-100 text-end text-start-css"
                      aria-label="Username"
                      aria-describedby="addon-wrapping"
                    />
                  ))}
                </div>
                <div className="input-group flex-nowrap flex-column">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text d-flex justify-content-center"
                    >
                      Tùy chọn
                    </span>
                  </div>
                  {editProducts?.map((product, index) => (
                    <div key={index} className="d-flex justify-content-end">
                      <div className="d-flex gap-2 mr-2 mb-2 ">
                        <label htmlFor="fileInput">
                          <button
                            className="d-flex flex-column align-items-center gap-2 border border-secondary justify-content-between rounded"
                            onClick={() => showFileInput(index)}
                          >
                            <p className="mb-0">Ảnh</p>
                          </button>
                        </label>
                        <input
                          id={`fileInput-${index}`}
                          type="file"
                          style={{ display: "none" }}
                          onChange={(e) => handleChangeEdit(index, e)}
                          name="image"
                        />
                        {editProducts[index]?.image ? (
                          <img
                            className="img-modal"
                            style={{ width: "35px" }}
                            src={URL.createObjectURL(editProducts[index].image)}
                            alt="Selected"
                            onClick={() => handleClickImage(index)}
                          />
                        ) : (
                          <div className="text-center">
                            {product?.imageProduct === null ? (
                              ""
                            ) : (
                              <img
                                className="img-modal"
                                style={{ width: "35px" }}
                                src={`${API_URL}/${product?.imageProduct}`}
                                alt="Ảnh đã chọn"
                                thumbnail
                                onClick={() => handleClickImage(index)}
                              />
                            )}
                          </div>
                        )}
                      </div>
                      <button
                        style={{ borderRadius: "0" }}
                        className="btn btn-danger mb-2"
                        onClick={() => handleRemoveEdit(index)}
                      >
                        Xóa
                      </button>
                    </div>
                  ))}
                </div>
              </div>
              <button
                className="btn btn-primary mb-2"
                style={{ borderRadius: "0" }}
                onClick={handleEditProduct}
              >
                Thêm phân loại
              </button>
              <div className="input-group flex-nowrap flex-column mb-2">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text d-flex justify-content-center"
                    id="addon-wrapping"
                  >
                    Mô tả
                  </span>
                </div>
                <Form className="w-100">
                  <Form.Group
                    style={{ height: "300px" }}
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                    value={selectedItemEdit?.detail || ""}
                    onChange={(event) => handleInputChangeE(event, "detail")}
                  >
                    <Form.Control
                      as="textarea"
                      style={{ height: "100%" }}
                      rows={3}
                      value={selectedItemEdit?.detail || ""}
                    />
                  </Form.Group>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => editProductApi()}>Lưu Sản Phẩm</Button>
        <Button
          className="bg-danger border-danger"
          onClick={() => setProductShow(false)}
        >
          Thoát Không lưu
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
