import { Container, Row, Modal, Col, Button } from "react-bootstrap";
import { API_URL, API_ROUTES } from "../../../api";
import axios from "axios";
import { useState } from "react";
import { Form, Table } from "react-bootstrap";
import { InputNumber } from "primereact/inputnumber";
export const EditMessConfirm = ({
  handleClose,
  modalMess,
  headers,
  setRefresh,
  setModalMess,
  refresh,
  messFirst,
}) => {
  const [textareaValue, setTextareaValue] = useState("");
  const handleTextareaChange = (event) => {
    setTextareaValue(event.target.value);
  };

  const editMessFirst = API_URL + API_ROUTES.EDIT_FIRST_MESS;
  const editLinkPro = async () => {
    const data = {
      text: textareaValue,
    };
    const accses = localStorage.getItem("access");
    try {
      const response = await axios.post(editMessFirst, data, headers);
      setRefresh(!refresh);
      setModalMess(false);
    } catch (error) {
    }
  };
  return (
    <Modal show={modalMess} onHide={handleClose} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Sửa tin nhắn xác nhận
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="gird">
        <Container>
          <Form>
            <Form.Group
              style={{ height: "300px" }}
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
              value={textareaValue}
              onChange={handleTextareaChange}
            >
              <Form.Control
                value={textareaValue || messFirst}
                as="textarea"
                style={{ height: "100%" }}
                rows={3}
              />
            </Form.Group>
          </Form>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button
          // disabled={loading || returns}
          onClick={() => editLinkPro()}
        >
          Lưu
        </Button>
        <Button
          className="bg-danger border-danger"
          onClick={() => setModalMess(false)}
        >
          Hủy
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
