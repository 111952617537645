import React, { useEffect, useState, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import axios from "axios";
import { useHeaders } from "../../../const/headers";
import { API_URL, API_ROUTES, API_ROUTES_NTD } from "../../../api";
import { Checkbox } from "primereact/checkbox";
import { InputNumber } from "primereact/inputnumber";
import { ListContentJoinGroup } from "./script/ListContentJoinGroup";
import { toast, ToastContainer } from "react-toastify";
import { Tooltip, OverlayTrigger, Popover } from "react-bootstrap";
import { ListContentInviteFriend } from "./script/ListContentInviteFriend";
import { IoInformationCircle } from "react-icons/io5";
import { RiExpandDiagonal2Line } from "react-icons/ri";
export const ModalInviteFriendJoinGroup = ({
  props,
  openCategory,
  setOpenCategory,
  setRefresh,
  refresh,
  dataFb,
  setDataFb,
  getDataFb,
}) => {
  const headers = useHeaders();
  const startMarketing = API_URL + API_ROUTES_NTD.START_GROUP_CATEGORY;
  const stopMarketing = API_URL + API_ROUTES_NTD.STOP_GROUP_CATEGORY;
  const deleteResultGroup = API_URL + API_ROUTES_NTD.DELETE_RESULTS_INVITE;
  const createCateMkt = API_URL + API_ROUTES_NTD.CREATE_EDIT_CATEGORY_GROUP;
  const joinGroup = API_URL + API_ROUTES_NTD.JOIN_GROUP;
  const inviteJoinGroup = API_URL + API_ROUTES_NTD.INVITE_JOIN_GROUP;
  const resultScanClient = API_URL + API_ROUTES_NTD.GET_RESULTS_DREIND;
  const checkListFr = API_URL + API_ROUTES_NTD.GET_LIST_FRIEND;
  const [listResults, setListResults] = useState([]);
  const [listImage, setListImage] = useState([]);
  const [listImageInbox, setListImageInbox] = useState([]);
  const [statusScan, setStatusScan] = useState(false);
  const [listScript, setListScript] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [listFr, setListFr] = useState([]);
  const [listGr, setListGr] = useState([]);
  const [name, setName] = useState("");
  const [group, setGroup] = useState([]);
  const [taskId, setTaskId] = useState([]);
  const [dataScanRs, setDataScanRs] = useState("");
  const [openDetails, setOpenDetails] = useState(false);
  const [itemResults, setItemResults] = useState([]);
  const [statusRun, setStatusRun] = useState(false);
  const intervalRef = useRef();
  const [ingredients, setIngredients] = useState({
    devide: false,
  });
  const [itemManager, setItemManager] = useState([]);
  const [valueCmt, setValueCmt] = useState("");
  const [comments, setComments] = useState([]);
  const [delayTime, setDelayTime] = useState(30);
  const [numberCount, setNumberCount] = useState(0);
  const [waitTime, setWaitTime] = useState(0);
  const handleChange = (e) => {
    let value = parseInt(e.value, 10);
    if (Number.isNaN(value) || e.value === null) {
      value = 60;
    } else if (value < 60) {
      value = 60;
    }
    setDelayTime(value);
  };
  const inviteJoinGroupFn = async () => {
    if (selectedItems?.length === 0) {
      alert("Vui lòng chọn tài khoản");
    } else if (selectedItems?.length > 1) {
      alert("Vui lòng chỉ chọn 1 tài khoản");
    } else if (listGr?.length === 0) {
      alert("Vui lòng chọn nhóm bạn muốn mời");
    } else if (listGr?.length > 1) {
      alert("Vui lòng chỉ chọn 1 nhóm");
    } else if (listFr?.length === 0) {
      alert("Vui lòng chọn bạn bè bạn muốn mời");
    } else {
      const body = {
        id_account: selectedItems[0].id,
        id_group: listGr[0].id,
        id_friends: listFr?.map((item) => item.id),
        delay_time: delayTime,
        number_count: numberCount,
        wait_time: waitTime,
      };
      try {
        const res = await axios.post(inviteJoinGroup, body, headers);
        toast.success("Mời tham gia nhóm thành công");
        setRefresh(!refresh);
      } catch (err) {}
    }
  };
  const deleteInvite = async () => {
    if (listResults?.length === 0) {
      alert("Chọn ít nhất 1 chiến dịch để xóa ");
      return;
    }
    const body = {
      id_results: listResults?.map((item) => item.id),
    };
    try {
      const res = await axios.post(deleteResultGroup, body, headers);
      toast.success("Xóa chiến dịch thành công");
      setRefresh(!refresh);
    } catch (err) {
      toast.error("Xóa chiến dịch thất bại");
    }
  };
  const scanListFr = async () => {
    if (selectedItems?.length === 0) {
      alert("Bạn vui lòng chọn ít nhất một tài khoản");
    } else {
      setStatusScan(true);
      const data = selectedItems?.map((item) => item?.id);
      const body = { id_accounts: data };
      try {
        const res = await axios.post(checkListFr, body, headers);
        toast.success("Đang quét danh sách bạn bè");
        setTaskId(res.data.id_task);
        setStatusRun(true);
        setSelectedItems([]);
      } catch (err) {}
    }
  };
  const checkResultsClient = async () => {
    if (taskId?.length === 0) {
      return;
    }
    const body = { id_task: taskId };
    try {
      const res = await axios.post(resultScanClient, body, headers);
      setDataScanRs(res.data);
    } catch (err) {}
    setStatusScan(false);
  };
  useEffect(() => {
    intervalRef.current = setInterval(() => {
      checkResultsClient();
    }, 3000);

    return () => clearInterval(intervalRef.current);
  }, [taskId]);

  useEffect(() => {
    if (dataScanRs?.status === "SUCCESS" || dataScanRs?.status === "FAILURE") {
      setStatusRun(false);
      clearInterval(intervalRef.current);
    }
  }, [dataScanRs]);
  return (
    <Dialog
      header={
        <div className="d-flex align-items-center gap-2">
          <span>Mời bạn bè tham gia nhóm</span>
          <Button
            disabled={statusRun}
            onClick={scanListFr}
            className="button-blue radius"
            style={{ height: "30px" }}
          >
            <div style={{ fontSize: "12px" }}>Quét bạn bè</div>
          </Button>
        </div>
      }
      visible={openCategory}
      style={{ position: "relative", width: "80vw" }}
      onHide={() => setOpenCategory(false)}
      className="overFlowJoinGr"
      footer={
        <div className="d-flex justify-content-end gap-2 mt-2">
          <Button
            onClick={inviteJoinGroupFn}
            className="d-flex justify-content-center button-blue"
            style={{
              width: "180px",
            }}
            disabled={statusScan}
          >
            Chạy chiến dịch
          </Button>
          <Button
            severity="danger"
            className="d-flex justify-content-center button-red"
            style={{
              width: "180px",
            }}
            onClick={deleteInvite}
          >
            Xóa chiến dịch
          </Button>
          <RiExpandDiagonal2Line
            size={20}
            style={{
              position: "absolute",
              right: "0",
              bottom: "0",
            }}
          />
        </div>
      }
    >
      <div className="d-flex flex-column h-100" style={{ overflow: "auto" }}>
        <div className="p-inputgroup flex-1 w-100">
          <div className="d-flex flex-column w-100 gap-2">
            <nav className="d-flex gap-2">
              <span>Thời gian chờ (Giây)</span>
              <OverlayTrigger
                key="tooltip"
                placement="bottom"
                overlay={
                  <Tooltip id="tooltip-right" className="custom-tooltip">
                    <div className="text-start">
                      Thời gian chờ giữa mỗi lần tham gia vào nhóm
                      <br />- Ví dụ: Bạn tham gia vào 3 nhóm thì sau khi tham
                      gia vào nhóm thứ nhất sẽ chờ một khoảng thời gian do bạn
                      cài đặt rồi mới tham gia tiếp theo vào nhóm thứ 2. Thời
                      gian chờ tối thiểu là 120s và khuyến khích bạn nên để là
                      600s để an toàn cho tài khoản. Hành động tham gia nhóm quá
                      nhanh sẽ dễ bị hạn chế tính năng trên tài khoản do cơ chế
                      chống spam của Facebook
                    </div>
                  </Tooltip>
                }
              >
                <span
                  style={{
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                >
                  <IoInformationCircle size={15} />
                </span>
              </OverlayTrigger>
            </nav>
            <InputNumber
              style={{ width: "100%" }}
              value={delayTime}
              placeholder="Thời gian tính bằng giây (s)"
              onValueChange={(e) => setDelayTime(e.value)}
              useGrouping={false}
              min={120}
            />
          </div>
          <div className="d-flex flex-column w-100 gap-2">
            <nav className="d-flex gap-2">
              <span>Số lượt</span>
              <OverlayTrigger
                key="tooltip"
                placement="bottom"
                overlay={
                  <Tooltip id="tooltip-right" className="custom-tooltip">
                    <div className="text-start">
                      Tài khoản đi tham gia vào 1 nhóm được tính là 1 lượt. Nên
                      để số lượt là 1. Và cài đặt thêm thời gian chờ lặp lại ở ô
                      bên cạnh
                    </div>
                  </Tooltip>
                }
              >
                <span
                  style={{
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                >
                  <IoInformationCircle size={15} />
                </span>
              </OverlayTrigger>
            </nav>
            <InputNumber
              style={{ width: "100%" }}
              value={numberCount}
              placeholder=""
              onValueChange={(e) => setNumberCount(e.value)}
              useGrouping={false}
              min={1}
            />
          </div>
          <div className="d-flex flex-column w-100 gap-2">
            <nav className="d-flex gap-2">
              <span style={{ width: "" }}>Thời gian chờ lặp lại (Giờ)</span>
              <OverlayTrigger
                key="tooltip"
                placement="bottom"
                overlay={
                  <Tooltip id="tooltip-right" className="custom-tooltip">
                    <div className="text-start">
                      Chức năng này có tác dụng khi bạn cài đặt số lượt (số lượt
                      được cài đặt ở ô bên cạnh). Chức năng này giúp chạy tiếp
                      chiến dịch hoàn toàn tự động khi bạn đặt số lượt ở ô bên
                      cạnh. Nên để là 3 giờ. Hành động tham gia nhóm quá nhanh
                      sẽ dễ bị hạn chế tính năng trên tài khoản do cơ chế chống
                      spam của Facebook
                    </div>
                  </Tooltip>
                }
              >
                <span
                  style={{
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                >
                  <IoInformationCircle size={15} />
                </span>
              </OverlayTrigger>
            </nav>
            <InputNumber
              style={{ width: "100%" }}
              value={waitTime}
              placeholder="Thời gian tính bằng giờ (h)"
              onValueChange={(e) => setWaitTime(e.value)}
              useGrouping={false}
              min={1}
            />
          </div>
        </div>
        <ListContentInviteFriend
          refresh={refresh}
          setRefresh={setRefresh}
          ingredients={ingredients}
          setIngredients={setIngredients}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          group={group}
          setGroup={setGroup}
          dataFb={dataFb}
          comments={comments}
          listImage={listImage}
          setListImage={setListImage}
          valueCmt={valueCmt}
          setComments={setComments}
          setValueCmt={setValueCmt}
          listResults={listResults}
          setListResults={setListResults}
          listFr={listFr}
          setListFr={setListFr}
          listGr={listGr}
          setListGr={setListGr}
          openDetails={openDetails}
          setOpenDetails={setOpenDetails}
          itemResults={itemResults}
          setItemResults={setItemResults}
        />
      </div>
    </Dialog>
  );
};
