import classNames from "classnames/bind";
import styles from "./Header.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IoIosPricetags } from "react-icons/io";
import Button from "../../../components/Button";
import {
  faBars,
  faClipboardQuestion,
  faHouse,
  faListCheck,
  faPhone,
  faRightToBracket,
  faSignIn,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useAuth } from "../../../../../../AuthContext";
import { API_URL } from "../../../../../../api";
const cx = classNames.bind(styles);
function Header() {
  const { userLoggedIn } = useAuth();
  const currentUser = false;
  const navigate = useNavigate();
  const handleNavi = (link) => {
    navigate(link);
  };
  const dashboardPrice = () => {
    navigate("/redue");
  }
  const [showMenu, setShowMenu] = useState(false);
  return (
    <>
      <header className={cx("wrapper")}>
        <div className={cx("inner")}>
          <div className={cx("actions", "actions-right")}>
            <Button
              className={cx(".action-item")}
              href="#home"
              leftIcon={<FontAwesomeIcon icon={faHouse} />}
            >
              Trang chủ
            </Button>
            <Button
              className={cx(".action-item")}
              href="#feature"
              leftIcon={<FontAwesomeIcon icon={faListCheck} />}
            >
              Tính năng
            </Button>
            <Button
              className={cx(".action-item")}
              href="#questions"
              leftIcon={<FontAwesomeIcon icon={faClipboardQuestion} />}
            >
              Câu hỏi
            </Button>
            <Button
              className={cx(".action-item")}
              href="https://www.facebook.com/messages/t/352162647978429"
              target="_blank"
              leftIcon={<FontAwesomeIcon icon={faPhone} />}
            >
              Liên hệ
            </Button>
            <Button
              className={cx(".action-item")}
              onClick={dashboardPrice}
              target="_blank"
              leftIcon={<IoIosPricetags />}
            >
              Bảng giá
            </Button>

            {userLoggedIn ? (
              <Button
                onClick={() => handleNavi("/account")}
                leftIcon={<FontAwesomeIcon icon={faRightToBracket} />}
              >
                Chức năng
              </Button>
            ) : (
              <>
                <Button
                  onClick={() => handleNavi("/login")}
                  leftIcon={<FontAwesomeIcon icon={faSignIn} />}
                >
                  Đăng nhập
                </Button>
                <Button
                  onClick={() => handleNavi("/register")}
                  leftIcon={<FontAwesomeIcon icon={faUserPlus} />}
                >
                  Đăng ký
                </Button>
              </>
            )}
          </div>
        </div>
      </header>
      <header className={cx("wrapper-mobile")}>
        <nav className={cx("drop-menu")}>
          <Button
            className={cx(".action-item")}
            leftIcon={<FontAwesomeIcon icon={faBars} />}
            onClick={() => setShowMenu(!showMenu)}
          ></Button>
        </nav>
        <div className={cx("inner-mobile", { "display-none": !showMenu })}>
          <Button
            className={cx(".action-item")}
            href="#home"
            leftIcon={<FontAwesomeIcon icon={faHouse} />}
          >
            Trang chủ
          </Button>
          <Button
            className={cx(".action-item")}
            href="#feature"
            leftIcon={<FontAwesomeIcon icon={faListCheck} />}
          >
            Tính năng
          </Button>
          <Button
            className={cx(".action-item")}
            href="#questions"
            leftIcon={<FontAwesomeIcon icon={faClipboardQuestion} />}
          >
            Câu hỏi
          </Button>
          <Button
            className={cx(".action-item")}
            href="https://www.facebook.com/messages/t/352162647978429"
            target="_blank"
            leftIcon={<FontAwesomeIcon icon={faPhone} />}
          >
            Liên hệ
          </Button>
          <Button
              className={cx(".action-item")}
              onClick={dashboardPrice}
              target="_blank"
              leftIcon={<FontAwesomeIcon icon={faPhone} />}
            >
              Bảng giá
            </Button>
          {userLoggedIn ? (
            <Button
              onClick={() => handleNavi("/account")}
              leftIcon={<FontAwesomeIcon icon={faRightToBracket} />}
            >
              Chức năng
            </Button>
          ) : (
            <>
              <Button
                onClick={() => handleNavi("/login")}
                leftIcon={<FontAwesomeIcon icon={faSignIn} />}
              >
                Đăng nhập
              </Button>
              <Button
                onClick={() => handleNavi("/register")}
                leftIcon={<FontAwesomeIcon icon={faUserPlus} />}
              >
                Đăng ký
              </Button>
            </>
          )}
        </div>
      </header>
    </>
  );
}

export default Header;
