import { useEffect, useRef, useState } from "react";
import { InputGroup, Form, Tooltip, OverlayTrigger } from "react-bootstrap";
import { IoSearch } from "react-icons/io5";
import { MdNewLabel } from "react-icons/md";
import { MdLabel } from "react-icons/md";
import axios from "axios";
import { useHeaders } from "../../../const/headers";
import { API_URL, API_ROUTES, API_ROUTES_NTD } from "../../../api";
import { LuUserPlus2 } from "react-icons/lu";
import { truncateMarket } from "../../../const/splittext";
import { IoReload } from "react-icons/io5";
import { useUser } from "../../../contexts/UserContext";
import ClipLoader from "react-spinners/ClipLoader";
import { Checkbox } from "primereact/checkbox";
import { FaExclamation } from "react-icons/fa";
import { GrStatusGoodSmall } from "react-icons/gr";
export const ListPageChat = ({
  heightAddNext,
  sortServer,
  idAcc,
  messServer,
  ftLabel,
  setItemLabel,
  setOpenAddLabel,
  messages,
  idPage,
  faimg,
  setIdAcc,
  resetUnread,
  getMessSend,
  setOnRef,
  onRef,
  setSortServer,
  setMessManual,
  getApiOnServer,
  toast,
  setMessReply,
  getPageAtib,
  listAccout,
  getListAccount,
  openPage,
  setOpenPage,
  t,
  checkLabel,
  setCheckLabel,
}) => {
  const headers = useHeaders();
  const [search, SetSearch] = useState("");
  const apiGetAll = API_URL + API_ROUTES.GET_MESS_SERVER;
  const apiScanImg = API_URL + API_ROUTES_NTD.GET_AVATAR_ERROR;
  const reloadMess = API_URL + API_ROUTES.RELOAD_CONVERSATIONS;
  const reusltsReload = API_URL + API_ROUTES.RESULTS_RELOAD_CONVERSATIONS;
  const [allMess, setAllMess] = useState([]);
  const [numberPage, setNumberPage] = useState([]);
  const [idTask, setIdTask] = useState(false);
  const [dataRs, setDataRs] = useState([]);
  const containerRef = useRef(null);
  const [deviceType, setDeviceType] = useState(false);
  const searchChange = (event) => {
    const { value } = event.target;
    SetSearch(value);
  };
  const getResultsComment = async (taskid) => {
    const body = { id_task: taskid.id_task };
    try {
      const res = await axios.post(reusltsReload, body, headers);
      setDataRs(res.data.status);
      // if (res.data.data.status === true) {
      //   // toast.success("Trả lời bình luận Thành công");
      // }
    } catch (err) {
      setDataRs(err?.response?.data?.status);
      toast.error(err?.response?.data?.error);
    }
  };
  useEffect(() => {
    if (idTask.id_task) {
      const interval = setInterval(() => {
        getResultsComment(idTask);
      }, 3000);
      if (dataRs === "SUCCESS" || dataRs === "FAILURE") {
        getListAccount();
        clearInterval(interval);
      }
      return () => clearInterval(interval);
    }
  }, [dataRs, idTask]);
  const handleReloadMess = async () => {
    const body = { id_account: idPage };
    try {
      const res = await axios.post(reloadMess, body, headers);
      setIdTask(res.data);
    } catch (err) {
      toast.error(err.response.data.error);
    }
  };
  const handleManualMess = () => {
    if (idPage === undefined) {
      alert(
        "Bạn phải chọn ít nhất 1 tài khoản để thực hiện gửi tin nhắn thủ công"
      );
    } else {
      setMessManual(true);
    }
  };
  useEffect(() => {
    setAllMess(sortServer?.results);
    setNumberPage(sortServer);
  }, [idPage, sortServer]);
  function filterByName(allMess, search) {
    return allMess?.filter((item) =>
      Object.values(item)?.some(
        (value) =>
          typeof value === "string" &&
          value.toLowerCase().includes(search.toLowerCase())
      )
    );
  }
  const resultFlLabel = allMess?.filter((item) =>
    item.category_message.some((category) => category?.id === ftLabel?.id)
  );
  const getItemSend = (item) => {
    if (idAcc?.unread_count > 0) {
      resetUnread();
    }
  };
  const filterAccountConnect = allMess?.sort((a, b) => {
    const dateA = new Date(a.updated_time);
    const dateB = new Date(b.updated_time);
    return dateB - dateA;
  });
  const scanAvatar = async (link) => {
    const body = { links: [link] };
    try {
      const res = await axios.post(apiScanImg, body, headers);
    } catch (err) {}
  };
  const handleImageError = async (event) => {
    const url = event.target.src;
    await scanAvatar(url);
    if (url.includes("signature expired")) {
    }
  };

  const nextGetData = async () => {
    const url = `${apiGetAll}?id_account=${idPage}&page=${
      numberPage?.next?.split("page=")[1]
    }`;
    try {
      const res = await axios.get(url, headers);
      setAllMess([...allMess, ...res?.data?.results]);
      setNumberPage(res?.data);
    } catch (err) {}
  };
  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width >= 1024) {
        // setDeviceType('PC hoặc Laptop');
      } else if (width <= 900) {
        setDeviceType(true);
      } else {
        setDeviceType(false);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div
      className={`${openPage === false ? "me-auto-responsive " : ""}`}
      ref={containerRef}
      style={{
        background: "#9e9e9e ",
        width: "200px",
        height: `calc(100vh - ${heightAddNext}px - ${
          deviceType ? "46px" : "0px"
        })`,
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      <nav
        className="d-flex justify-content-around  mess-question-zal thead-fixed "
        style={{
          background: "#9e9e9e",
        }}
      >
        <InputGroup
          className="responsive-width-acc"
          style={{
            width: "auto",
            borderRadius: "0",
          }}
        >
          <Form.Control
            placeholder="Tìm kiếm nhanh"
            aria-describedby="basic-addon2"
            onChange={searchChange}
            value={search}
            style={{
              borderRadius: "0",
            }}
          />
          {/* <IoSearch
            className="responsive-hidden-search"
            style={{ display: "none", fontSize: "42px" }}
          /> */}
        </InputGroup>
        {idPage ? (
          <>
            {listAccout?.filter((item) => item.id === getPageAtib.id)[0]
              ?.reload_message ? (
              <div
                style={{ color: "#000", background: "#fff" }}
                className="d-flex justify-content-center align-items-center"
              >
                <ClipLoader size={30} />
              </div>
            ) : (
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip
                    id={`tooltip-bottom`}
                    className="custom-tooltip overlaytrighestyle"
                  >
                    Cập nhật tin nhắn
                  </Tooltip>
                }
              >
                <div
                  onClick={handleReloadMess}
                  style={{
                    cursor: "pointer",
                    color: "#000",
                    background: "#fff",
                  }}
                  className="d-flex justify-content-center align-items-center"
                >
                  <IoReload size={30} />
                </div>
              </OverlayTrigger>
            )}
          </>
        ) : (
          <></>
        )}
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip
              id={`tooltip-bottom`}
              className="custom-tooltip overlaytrighestyle"
            >
              {t("Gửi tin nhắn tới người lạ")}
            </Tooltip>
          }
        >
          <div
            onClick={handleManualMess}
            style={{
              cursor: "pointer",
              color: "#000",
              background: "#fff",
              borderLeft: "0.5px solid #9b9b9b99",
            }}
            className="d-flex justify-content-center align-items-center"
          >
            <LuUserPlus2 color="rgb(0 147 255)" size={30} />
          </div>
        </OverlayTrigger>
      </nav>
      {filterAccountConnect?.map((item, index) => item.account)[0] ===
      idPage ? (
        <div>
          {(ftLabel?.length === 0
            ? filterByName(allMess, search) || allMess
            : resultFlLabel
          )?.map((item, i) => (
            <div
              key={i}
              style={{
                background: idAcc?.id === item?.id ? "#0866ff" : "",
              }}
              className="d-flex justify-content-between align-items-center border-top border-bottom sell-mess-zalo"
            >
              <div
                className="d-flex gap-2 align-items-center  w-100"
                style={{
                  padding: "2px 0",
                  marginLeft: "2px",
                  cursor: "pointer",
                }}
              >
                <Checkbox
                  onChange={() => {
                    checkLabel.some((id) => id === item.id)
                      ? setCheckLabel(checkLabel?.filter((i) => i !== item.id))
                      : setCheckLabel((prevCheckLabel) => [
                          ...prevCheckLabel,
                          item.id,
                        ]);
                  }}
                  checked={checkLabel.some((id) => id === item.id)}
                ></Checkbox>

                <nav
                  onClick={() => {
                    setOpenPage(false);
                    setIdAcc(item);
                    getItemSend(item);
                    // getMessSend(item);
                    // handleItemClick(item);
                    resetUnread(item);
                    setMessReply([]);
                  }}
                  style={{ position: "relative" }}
                >
                  {item.thread_type === "MARKETPLACE" ? (
                    <img
                      alt="fb"
                      style={{
                        width: "35px",
                        height: "35px",
                        borderRadius: "50%",
                      }}
                      src={item.avatar || faimg}
                      onError={handleImageError}
                    />
                  ) : (
                    <img
                      alt="fb"
                      style={{
                        width: "35px",
                        height: "35px",
                        borderRadius: "50%",
                      }}
                      src={item.client_avatar || faimg}
                      onError={handleImageError}
                    />
                  )}
                  {/* {deleCount === true ? ( */}
                  <span
                    className="d-flex align-items-center justify-content-center position-absolute top-0 end-0 text-white rounded-circle"
                    style={{}}
                  >
                    {idAcc?.id === item?.id ? (
                      ""
                    ) : item?.unread_count > 0 ? (
                      item?.unread_count > 9 ? (
                        <GrStatusGoodSmall color="f44336" />
                      ) : (
                        <GrStatusGoodSmall color="f44336" />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </nav>
                <div
                  onClick={() => {
                    setOpenPage(false);
                    setIdAcc(item);
                    getItemSend(item);
                    // getMessSend(item);
                    // handleItemClick(item);
                    resetUnread(item);

                    setMessReply([]);
                  }}
                  className=""
                >
                  <h6 style={{ fontSize: "12px" }} className="p-0 m-0">
                    {item.thread_type === "MARKETPLACE"
                      ? truncateMarket(item?.name, 17)
                      : truncateMarket(item?.client_fullname, 17)}
                  </h6>
                  <nav className="d-flex align-items-start flex-column">
                    <p
                      className="m-0 font-weight-light text-light"
                      style={{
                        fontWeight: "500",
                        fontSize: "10px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "19ch",
                      }}
                    >
                      {truncateMarket(item?.snippet, 20)}
                    </p>
                    <nav className="d-flex">
                      {item?.category_message?.map((item, index) => (
                        <div>
                          <OverlayTrigger
                            key={index}
                            placement="bottom"
                            overlay={
                              <Tooltip
                                id={`tooltip-bottom`}
                                className="custom-tooltip overlaytrighestyle"
                              >
                                {item?.name}
                              </Tooltip>
                            }
                          >
                            <div>
                              <MdLabel color={`${item.color}`} />
                            </div>
                          </OverlayTrigger>
                        </div>
                      ))}
                    </nav>
                  </nav>
                </div>
              </div>
            </div>
          ))}
          <p
            onClick={nextGetData}
            style={{
              cursor: "pointer",
              textAlign: "center",
              display:
                numberPage?.next?.split("page=")[1] === undefined ? "none" : "",
            }}
          >
            Xem thêm...
          </p>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};
