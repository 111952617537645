import { useEffect } from "react";
import { API_URL, API_ROUTES_NTD } from "../../../api";
import axios from "axios";
import { useHeaders } from "../../../const/headers";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { locale } from "primereact/api";
import {
  toggleSelectAll,
  headerCheckbox,
  bodyCheckbox,
} from "../../../const/checkboxPrimeReactjs";
import { onRowClick } from "../../../const/checkboxPrimeReactjs";
import { rowClassName } from "../../../const/checkboxPrimeReactjs";
export const TableAccountClientAddFr = ({
  dataFb,
  selectedItems,
  setSelectedItems,
  refresh,
  setAllAddFriend,
}) => {
  locale("vi");
  const getAllAddFr = API_URL + API_ROUTES_NTD.GET_ALL_ADD_FRIEND;
  const headers = useHeaders();
  const filterAccountCheckpoit = dataFb?.filter(
    (item) => item?.checkpoint === false && item.status_proxy === true
  );
  const getDataAllAddFr = async (item) => {
    if (item?.id !== undefined) {
      const body = { id_account: item?.id };
      try {
        const res = await axios.post(getAllAddFr, body, headers);
        setAllAddFriend(res.data);
      } catch (err) {}
    } else {
      setAllAddFriend([]);
    }
  };
  useEffect(() => {
    getDataAllAddFr(selectedItems[0]);
  }, [refresh, selectedItems]);
  const nameAndAvt = (rowData) => {
    return (
      <div className="d-flex gap-2 align-items-center">
        <img
          className="rounded-circle"
          style={{ width: "20px", height: "20px" }}
          src={rowData?.avatar}
        />
        <p>{rowData?.name}</p>
      </div>
    );
  };
  const indexTemplate = (rowData, rowIndex) => {
    return <>{rowIndex.rowIndex + 1}</>;
  };
  return (
    <div style={{ border: "1px solid", width: "100%", height: "400px" }}>
      <DataTable
        size="small"
        value={filterAccountCheckpoit || []}
        className="small-font-table dataTableWithFixedHeader dataTableWithHoverEffect datatableoverflow"
        tableStyle={{ minWidth: "100%" }}
        rowClassName={(rowData) => rowClassName(rowData, selectedItems)}
        onRowClick={(e) => onRowClick(e.data, setSelectedItems, selectedItems)}
      >
        <Column
          header={headerCheckbox(
            selectedItems,
            filterAccountCheckpoit || [],
            toggleSelectAll,
            setSelectedItems
          )}
          body={(rowData) =>
            bodyCheckbox(
              rowData,
              selectedItems,
              setSelectedItems,
              getDataAllAddFr
            )
          }
        />
        <Column body={indexTemplate} field="UID" header="STT"></Column>
        <Column field="UID" header="UID" sortable></Column>
        <Column
          body={(rowData) => nameAndAvt(rowData)}
          field="name"
          header="Tên tài khoản"
          sortable
        ></Column>
      </DataTable>
    </div>
  );
};
