import axios from "axios";
import { API_URL, API_ROUTES } from "../../../api";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

export const EditAccount = ({
  setShowData,
  showData,
  toast,
  props,
  setRefresh,
  headers,
  setSelectedItemEdit,
  selectedItemEdit,
  refresh,
  t,
}) => {
  const editData = API_URL + API_ROUTES.CHECK_ACC_EDIT;
  const handleNoteChange = (event) => {
    setSelectedItemEdit((prevItem) => ({
      ...prevItem,
      note: event.target.value,
    }));
  };
  const handleNameChange = (event) => {
    setSelectedItemEdit((prevItem) => ({
      ...prevItem,
      name: event.target.value,
    }));
  };
  const handleProxyChange = (event) => {
    setSelectedItemEdit((prevItem) => ({
      ...prevItem,
      proxy: event.target.value,
    }));
  };
  const editDataFb = async () => {
    const newArray = [
      {
        id: selectedItemEdit.id,
        UID: selectedItemEdit.UID,
        name: selectedItemEdit.name,
        proxy: selectedItemEdit.proxy,
        access_token: selectedItemEdit.access_token,
        note: selectedItemEdit.note,
      },
    ];
    try {
      const body = newArray;
      const response = await axios.post(editData, body, headers);
      setShowData(false);
      setRefresh(!refresh);
    } catch (error) {
      toast.error(t("sửa không thành công"));
    }
  };
  return (
    <Dialog
      header={t("Chỉnh sửa thông tin")}
      visible={showData}
      style={{ width: "50vw" }}
      onHide={() => setShowData(false)}
    >
      <div className="w-100" xs={6} md={4}>
        <div className="p-inputgroup flex-1">
          <span
            style={{ width: "9rem" }}
            className="input-group-text"
            id="addon-wrapping"
          >
            UID
          </span>
          <InputText
            value={selectedItemEdit?.UID}
            placeholder={t("Tên đăng nhập")}
          />
        </div>
        <div className="p-inputgroup flex-1">
          <span
            style={{ width: "9rem" }}
            className="input-group-text"
            id="addon-wrapping"
          >
            {t("Tên tài khoản")}
          </span>
          <InputText
            value={selectedItemEdit?.name || ""}
            onChange={handleNameChange}
          />
        </div>
        <div className="p-inputgroup flex-1">
          <span
            style={{ width: "9rem" }}
            className="input-group-text"
            id="addon-wrapping"
          >
            Proxy
          </span>
          <InputText
            value={selectedItemEdit?.proxy || ""}
            onChange={handleProxyChange}
          />
        </div>
        <div className="p-inputgroup flex-1">
          <span
            style={{ width: "9rem" }}
            className="input-group-text"
            id="addon-wrapping"
          >
            {t("Ghi chú")}
          </span>
          <InputText
            value={selectedItemEdit?.note || ""}
            onChange={handleNoteChange}
          />
        </div>
        <div className="d-flex justify-content-end gap-2 mt-2">
          <Button
            className="button-blue radius"
            label={t("Lưu")}
            onClick={() => editDataFb()}
          />
        </div>
      </div>
    </Dialog>
  );
};
