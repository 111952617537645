import React, { useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { FloatLabel } from "primereact/floatlabel";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { API_URL, API_ROUTES } from "../../../api";
import axios from "axios";
import { useHeaders } from "../../../const/headers";
import { deleteFileOnServer } from "../../../const/deleteFileOnServer";
import { TiDelete } from "react-icons/ti";
export const CreateFastMess = ({
  setOpenCreate,
  openCreate,
  editQuestion,
  setRf,
  toast,
  rf,
  idPage,
  handleGetMess,
}) => {
  const headers = useHeaders();
  const addMess = API_URL + API_ROUTES.CREATE_FAST_REPLY;
  const getLinkImage = API_URL + API_ROUTES.GET_LINK_FILE;
  const formData = new FormData();
  const [shortcut, setShortcut] = useState("");
  const [title, setTitle] = useState("");
  const [mess, setMess] = useState("");
  const [linkImage, setLinkImage] = useState("");
  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      extracLinkImage(e.target.files[0]);
    }
  };
  const extracLinkImage = async (selectFileFast) => {
    formData.append("file", selectFileFast);
    try {
      const response = await axios.post(getLinkImage, formData, headers);
      setLinkImage(response.data.file);
    } catch (err) {}
  };
  const removeImage = () => {
    setLinkImage("");
    deleteFileOnServer(linkImage);
  };
  const handleCreate = async () => {
    const body = {
      id_account: idPage,
      command: "",
      title: title,
      content: mess,
      image: linkImage || "",
    };
    try {
      const responsive = await axios.post(addMess, body, headers);
      toast.success("Thành công");
      setOpenCreate(false);
      handleGetMess();
    } catch (err) {
      toast.error("Thất bại");
    }
  };
  return (
    <div className="flex justify-content-center">
      <Dialog
        header="Thêm tin nhắn nhanh"
        visible={openCreate}
        onHide={() => setOpenCreate(false)}
        style={{ width: "30vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        <div className="d-flex flex-column gap-4 mt-4 p-2 w-100">
          <FloatLabel>
            <InputText
              id="title"
              className="w-100"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <label htmlFor="title">Tiêu đề</label>
          </FloatLabel>
          <FloatLabel>
            <InputTextarea
              id="mess"
              className="w-100"
              value={mess}
              onChange={(e) => setMess(e.target.value)}
            />
            <label htmlFor="mess">Tin nhắn</label>
          </FloatLabel>
          <FloatLabel className="d-flex">
            <input
              id="file"
              type="file"
              className="w-100"
              lang="vi"
              onChange={handleFileChange}
            />
            {linkImage && (
              <div style={{ position: "relative" }}>
                <img
                  style={{ width: "80px", height: "80px" }}
                  src={`${API_URL}/${linkImage}`}
                />
                <TiDelete
                  onClick={removeImage}
                  size={20}
                  style={{
                    position: "absolute",
                    top: "0",
                    right: "0",
                    color: "red",
                  }}
                />
              </div>
            )}
          </FloatLabel>
        </div>
        <nav className="d-flex justify-content-end">
          <Button
            className="button-blue radius"
            style={{ marginTop: "1rem" }}
            label="Thêm"
            onClick={() => handleCreate()}
            text
          />
        </nav>
      </Dialog>
    </div>
  );
};
