import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { API_ROUTES, API_ROUTES_NTD, API_URL } from "../../../api";
import { InputText } from "primereact/inputtext";
import { FloatLabel } from "primereact/floatlabel";
import { useHeaders } from "../../../const/headers";
import { InputTextarea } from "primereact/inputtextarea";

export const ModalMessManual = ({
  setMessManual,
  messManual,
  editQuestion,
  setRf,
  toast,
  rf,
  idPage,
}) => {
  const headers = useHeaders();
  const apiSendMess = API_URL + API_ROUTES_NTD.SEND_MESS_MANUAL;
  const apiGetRs = API_URL + API_ROUTES_NTD.GET_TASKID_MANUAL;
  const [getTask, setGetTask] = useState([]);
  const [statusSend, setStatusSend] = useState("");
  const getRsSendManual = async (taskid) => {
    const body = {
      id_task: taskid.id_task,
    };
    try {
      const res = await axios.post(apiGetRs, body, headers);
      console.log(res.data);
      if (res.data.data === true && res.data.status === "SUCCESS") {
        toast.success("Gửi tin nhắn thành công");
      } else if (res.data.data !== true && res.data.status === "SUCCESS") {
        toast.error("Gửi tin nhắn thất bại");
      }
      setStatusSend(res.data.status);
    } catch (err) {
      toast.error("Gửi tin nhắn thất bại");
    }
  };
  const handleSendManual = async () => {
    if (!uid) {
      toast.error("Vui lòng nhập UID hoặc Link Facebook");
      return;
    }
    if (!content) {
      toast.error("Vui lòng nhập nội dung tin nhắn");
      return;
    }
    const body = {
      id_account: idPage,
      UID: uid,
      message: content,
    };
    try {
      const res = await axios.post(apiSendMess, body, headers);
      setGetTask(res.data);
      setContent("");
      setUid("");
      setMessManual(false);
      getRsSendManual(res.data);
      toast.success("Đã gửi tin nhắn đi");
    } catch (err) {}
  };
  useEffect(() => {
    if (getTask.id_task) {
      const interval = setInterval(() => {
        getRsSendManual(getTask);
      }, 3000);
      if (statusSend === "SUCCESS") {
        clearInterval(interval);
      }
      return () => clearInterval(interval);
    }
  }, [statusSend, getTask]);
  const [uid, setUid] = useState("");
  const [content, setContent] = useState("");
  return (
    <div className="flex justify-content-center">
      <Dialog
        header="Gửi tin nhắn thủ công"
        visible={messManual}
        onHide={() => setMessManual(false)}
        style={{ width: "30vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        <div className="d-flex flex-column gap-4 mt-4 p-2 w-100">
          <FloatLabel>
            <InputText
              id="username"
              className="w-100"
              value={uid}
              onChange={(e) => setUid(e.target.value)}
            />

            <label htmlFor="UID hoặc Link Facebook">
              UID hoặc Link Facebook
            </label>
          </FloatLabel>
          <FloatLabel>
            <InputTextarea
              id="username"
              className="w-100"
              value={content}
              onChange={(e) => setContent(e.target.value)}
              rows={5}
              cols={30}
            />
            <label htmlFor="Nội dung tin nhắn">Nội dung tin nhắn</label>
          </FloatLabel>
        </div>
        <nav className="d-flex justify-content-end">
          <Button
            className="button-blue
             radius"
            style={{ marginTop: "1rem" }}
            onClick={handleSendManual}
            label="Gửi tin nhắn"
            text
          />
        </nav>
      </Dialog>
    </div>
  );
};
