import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Navigate from "../../Navigate";
import { toast, ToastContainer } from "react-toastify";
import { IoMdArrowBack } from "react-icons/io";
import CommonProduct from "./CommonProduct";
import Vehicles from "./Vehicles";
import HouseForRent from "./HouseForRent";

const CreateMarket = () => {
  // Khai báo các state cần thiết
  const navigate = useNavigate();
  // Xử lý sự kiện khi người dùng nhấn nút tạo thị trường

  return (
    <div className="d-flex flex-column " style={{ height: "100vh" }}>
      <div className="notifica-container">
        <ToastContainer />
        <div className="navigate-container" id="width-navigate">
          <Navigate />
        </div>
        <div
          className="d-flex flex-column gap-2 w-100 justify-content-center align-items-center"
          style={{
            // paddingLeft: "200px",
            // paddingRight: "200px",
            backgroundColor: "#dee2e6",
            flex: "1",
            position: "relative",
            overflow: "auto",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "8px",
              left: "8px",
              cursor: "pointer",
            }}
            onClick={() => navigate("/market")}
          >
            <IoMdArrowBack fontSize={30} />
          </div>
          <div style={{ width: "100%", padding: "300px" }}>
            <h2 style={{ textAlign: "left" }}>Chọn loại bài niêm yết</h2>
            <div
              style={{ width: "100%", cursor: "pointer" }}
              className="d-flex gap-2"
            >
              <CommonProduct
                onClick={() => navigate("/addmarket")}
                style={{ width: "calc(100%/ 3)" }}
              />
              <Vehicles
                onClick={() => navigate("/vehiclemarket")}
                style={{ width: "calc(100%/ 3)" }}
              />
              <HouseForRent
                onClick={() => navigate("/housemarket")}
                style={{ width: "calc(100%/ 3)" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateMarket;
