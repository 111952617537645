import { useEffect, useRef, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import "./john.css";
import { TableAccountInviteFriend } from "../TableAccountInviteFriend";
import { TableGroupOfInviteFriend } from "../TableGroupOfInviteFriend";
import { toast, ToastContainer } from "react-toastify";
import { TableFriendOfAccount } from "../TableFriendOfAccount";
import { ResultInviteFriend } from "../ResultInviteFriend";
import { ModalDetailsInvite } from "../ModalDetailsInvite";
export const ListContentInviteFriend = ({
  dataFb,
  selectedItems,
  setSelectedItems,
  listResults,
  setListResults,
  refresh,
  setRefresh,
  scriptClient,
  setScriptClient,
  setManagerScript,
  listFr,
  setListFr,
  listGr,
  setListGr,
}) => {
  const [checkboxes, setCheckboxes] = useState({});
  const isInitialRender = useRef(true);
  const [openDetails, setOpenDetails] = useState(false);
  const [itemResults, setItemResults] = useState([]);
  const [wsResults, setWsResults] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [getTotal, setGetTotal] = useState([]);
  const [rows, setRows] = useState(100);
  const [allCateClient, setAllCateClient] = useState([]);
  const [allFriend, setAllFriend] = useState([]);
  const [allGroup, setAllGroup] = useState([]);
  useEffect(() => {
    setListFr([]);
  }, [selectedItems]);
  return (
    <div className=" d-flex flex-column gap-2">
      <div className="mt-2 d-flex gap-2 w-100">
        <div style={{ width: "30%" }} className="table-popup3 d-flex mr-2">
          <TableAccountInviteFriend
            setRefresh={setRefresh}
            refresh={refresh}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            dataFb={dataFb}
            setListFr={setListFr}
            setListGr={setListGr}
            setAllFriend={setAllFriend}
            setAllGroup={setAllGroup}
            checkboxes={checkboxes}
            setCheckboxes={setCheckboxes}
          />
        </div>
        <div style={{ width: "35%" }} className="table-popup4">
          <TableGroupOfInviteFriend
            refresh={refresh}
            setRefresh={setRefresh}
            scriptClient={scriptClient}
            setScriptClient={setScriptClient}
            allFriend={allFriend}
            allGroup={allGroup}
            setAllGroup={setAllGroup}
            listFr={listFr}
            listGr={listGr}
            setListGr={setListGr}
            setListFr={setListFr}
            toast={toast}
            setManagerScript={setManagerScript}
            totalPage={totalPage}
            rows={rows}
            setGetTotal={setGetTotal}
            setRows={setRows}
            setTotalPage={setTotalPage}
            getTotal={getTotal}
            selectedItems={selectedItems}
          />
        </div>
        <div style={{ width: "35%" }} className="table-popup5">
          <TableFriendOfAccount
            refresh={refresh}
            setRefresh={setRefresh}
            selectedItems={selectedItems}
            scriptClient={scriptClient}
            setScriptClient={setScriptClient}
            allFriend={allFriend}
            setAllFriend={setAllFriend}
            allGroup={allGroup}
            listFr={listFr}
            setListFr={setListFr}
            toast={toast}
            setManagerScript={setManagerScript}
            totalPage={totalPage}
            rows={rows}
            setGetTotal={setGetTotal}
            setRows={setRows}
            setTotalPage={setTotalPage}
            getTotal={getTotal}
          />
        </div>
      </div>
      <div>
        <div className="table-popup6">
          <ResultInviteFriend
            setRefresh={setRefresh}
            refresh={refresh}
            dataFb={dataFb}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            isInitialRender={isInitialRender}
            rows={rows}
            setRows={setRows}
            totalPage={totalPage}
            setTotalPage={setTotalPage}
            getTotal={getTotal}
            setGetTotal={setGetTotal}
            allCateClient={allCateClient}
            setAllCateClient={setAllCateClient}
            listResults={listResults}
            setListResults={setListResults}
            setOpenDetails={setOpenDetails}
            openDetails={openDetails}
            setItemResults={setItemResults}
            wsResults={wsResults}
            setWsResults={setWsResults}
          />
        </div>
      </div>
      <ModalDetailsInvite
        openDetails={openDetails}
        setOpenDetails={setOpenDetails}
        itemResults={itemResults}
        dataFb={dataFb}
      />
    </div>
  );
};
