import { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { API_URL, API_ROUTES } from "../../../api";
import axios from "axios";
import { Button } from "primereact/button";
import { useHeaders } from "../../../const/headers";
import { getAxios } from "../../../const/getAxios";
export const ResetPassSep = ({ toast }) => {
  const headers = useHeaders();
  const getInforPass = API_URL + API_ROUTES.GET_INFOR_RESET_PASS;
  const resetPass = API_URL + API_ROUTES.ADMIN_RESET_PASS;
  const handleDelete = API_URL + API_ROUTES.DELETE_RESET_PASS;
  const [allInfor, setAllInfor] = useState([]);
  const [rfPass, setRfPass] = useState(false);
  useEffect(() => {
    getAxios(getInforPass, setAllInfor);
  }, [rfPass]);
  const handleResetPass = async (item) => {
    const body = { username: item.username };
    try {
      const res = await axios.post(resetPass, body, headers);
      setRfPass(!rfPass);
      toast.success("Đặt lại mật khẩu thành công");
    } catch (err) {
      toast.error("Đặt lại mật khẩu thất bại");
    }
  };
  const handleDeleteRs = async (item) => {
    const confirm = window.confirm("Bạn có chắc chắn muốn xóa không");
    if (confirm) {
      const body = { id: item.id };
      try {
        const res = await axios.post(handleDelete, body, headers);
        toast.success("Xóa thành công");
      } catch (err) {
        toast.error("Xóa thất bại");
      }
    }
  };
  return (
    <div className="overflow-auto h-100" style={{ borderTop: "1px solid" }}>
      <h3
        className="manage-domain-respon"
        style={{
          padding: "6px",
          margin: "0",
          fontSize: "20px",
          fontWeight: "700",
          color: "#545454",
        }}>
        Reset Password
      </h3>
      <Table style={{ borderTop: "1px solid" }} striped bordered hover>
        <thead className="thead-fixed">
          <tr style={{ borderTop: "0" }}>
            <th>STT</th>
            <th>Họ tên</th>
            <th>Username</th>
            <th>Số điện thoại</th>
            <th>Tùy chọn</th>
          </tr>
        </thead>
        <tbody>
          {allInfor?.map((item, index) => (
            <tr>
              <td>{index + 1}</td>
              <td>{item?.fullname}</td>
              <td>{item?.username}</td>
              <td>{item?.phone_number}</td>
              <td>
                <Button
                  onClick={() => handleResetPass(item)}
                  label="Reset"
                  className="mr-2 mb-2 radius button-blue"
                  severity="info"
                />
                <Button
                  onClick={() => handleDeleteRs(item)}
                  label="Xóa"
                  className="mb-2 radius button-red"
                  severity="danger"
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};
