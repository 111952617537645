export const expirationDateToFormattedDate = (expirationDate) => {
  let formattedDate = "";
  if (expirationDate) {
    const [date, time] = expirationDate.split(" ");
    const [year, month, day] = date.split("-");

    if (date && time && year && month && day) {
      formattedDate = `${time} ${day}-${month}-${year}`;
    }
  }

  return formattedDate;
};

export const formatDateArticle = (date) => {
  if (!date) return "";
  const pad = (number) => (number < 10 ? `0${number}` : number);
  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());
  const seconds = pad(date.getSeconds());
  const day = pad(date.getDate());
  const month = pad(date.getMonth() + 1);
  const year = date.getFullYear();
  return `${hours}:${minutes}:${seconds} ${day}-${month}-${year}`;
};
