import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useAuth } from "../../../AuthContext";
import { useUser } from "../../../contexts/UserContext";
import { API_URL } from "../../../api";
export default function LongContentDemo() {
  const { dataEx, setVisibleEx, visibleEx, userInfo } = useUser();
  const { userLoggedIn } = useAuth();
  const expiredLocal = localStorage.getItem("expired");
  useEffect(() => {
    const hasVisited = localStorage.getItem("hasVisited");
    if (!hasVisited && userLoggedIn === true) {
      const expirationDate = new Date(userInfo.expiration_date);
      const currentDate = new Date();
      if (expirationDate < currentDate) {
        setVisibleEx(true);
        localStorage.setItem("hasVisited", true);
      }
    }
  }, []);

  return (
    <div style={{ border: "0" }} className=" flex justify-content-center">
      <Dialog
        header=""
        visible={visibleEx}
        style={{ width: "50vw" }}
        onHide={() => setVisibleEx(false)}
      >
        <div className="d-flex align-items-center w-100 gap-4">
          {dataEx?.image === "" ? (
            <div></div>
          ) : (
            <img style={{ width: "30%" }} src={`${API_URL}/${dataEx?.image}`} />
          )}

          <p
            style={{
              wordWrap: "break-word",
              width: "100%",
              textAlign: dataEx?.image === "" ? "start" : "",
            }}
            className="mb-2 mt-2"
          >
            {dataEx?.content}
          </p>
        </div>
        <div className="d-flex justify-content-end">
          <Button
            className="button-blue radius"
            label="Liên hệ gia hạn"
            onClick={() => {
              window.open(dataEx?.link, "_blank");
            }}
          />
        </div>
      </Dialog>
    </div>
  );
}
