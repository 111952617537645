import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const DescriptionModal = ({
  description,
  showDescriptionModal,
  setShowDescriptionModal,
}) => {
  const dialogRef = useRef(null);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [startDragPoint, setStartDragPoint] = useState({ x: 0, y: 0 });
  const [modalShow, setModalShow] = useState(false);
  useEffect(() => {
    if (showDescriptionModal) {
      dialogRef.current.showModal();
    } else {
      if (dialogRef.current && dialogRef.current.close) {
        dialogRef.current.close();
      }
    }
  }, [showDescriptionModal]);

  const startDrag = (e) => {
    setIsDragging(true);
    setStartDragPoint({
      x: e.clientX - position.x,
      y: e.clientY - position.y,
    });
  };

  const onDrag = (e) => {
    if (isDragging) {
      setPosition({
        x: e.clientX - startDragPoint.x,
        y: e.clientY - startDragPoint.y,
      });
    }
  };

  const endDrag = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    if (isDragging) {
      document.addEventListener("mousemove", onDrag);
      document.addEventListener("mouseup", endDrag);
    }

    return () => {
      document.removeEventListener("mousemove", onDrag);
      document.removeEventListener("mouseup", endDrag);
    };
  }, [isDragging, onDrag]);

  // Hàm để copy nội dung description
  const copyDescription = () => {
    navigator.clipboard.writeText(description).then(() => {
      toast.success("Nội dung đã được copy vào clipboard!");
    });
  };

  return (
    <div className="flex justify-content-center">
      <dialog
        header="Detail Debug"
        visible={modalShow}
        style={{
          width: "50vw",
          height: "50vh",
        }}
        ref={dialogRef}
        className="description-dialog"
      >
        <div className="dialog-header" onMouseDown={startDrag}>
          <button onClick={copyDescription} className="button-blue radius">
            Copy
          </button>
          <button
            onClick={() => setShowDescriptionModal(false)}
            className="close-button"
          >
            Đóng
          </button>
        </div>
        <div>
          <p>{description}</p>
        </div>
        <style jsx>{`
          .description-dialog {
            border: 1px solid #ccc;
            border-radius: 5px;
            padding: 20px;
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
            width: 50%;
            max-width: 600px;
            position: fixed;
            background-color: white;
            z-index: 1000;
          }
          .description-dialog p {
            color: #333;
            font-size: 16px;
          }
          .dialog-header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
            cursor: move; /* Thêm con trỏ di chuyển */
          }
          .close-button,
          .copy-button {
            padding: 10px 20px;
            color: white;
            border: none;
            border-radius: 5px;
            cursor: pointer;
          }
          .close-button {
            background-color: #dc3545;
          }
          .close-button:hover {
            background-color: #c82333;
          }
          .copy-button {
            background-color: #007bff;
          }
          .copy-button:hover {
            background-color: #0056b3;
          }
        `}</style>
      </dialog>
    </div>
  );
};

export default DescriptionModal;
