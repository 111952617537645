import { Checkbox } from "primereact/checkbox";
import { InputTextarea } from "primereact/inputtextarea";
import { useEffect, useRef, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { API_ROUTES, API_ROUTES_NTD, API_URL } from "../../../../api";
import { ResultJoinGroup } from "../ResultJoinGroup";
import { TableAccountJoinGroup } from "../TableAccountJoinGroup";
import "./john.css";
import { IoInformationCircle } from "react-icons/io5";
export const ListContentJoinGroup = ({
  dataFb,
  group,
  setGroup,
  selectedItems,
  setSelectedItems,
  ingredients,
  setIngredients,
  listResults,
  setListResults,
  refresh,
  setRefresh,
}) => {
  const [allFriend, setAllFriend] = useState([]);
  const [checkboxes, setCheckboxes] = useState({});
  const isInitialRender = useRef(true);
  const [openDetails, setOpenDetails] = useState(false);
  const [itemResults, setItemResults] = useState([]);
  const [wsResults, setWsResults] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [getTotal, setGetTotal] = useState([]);
  const [rows, setRows] = useState(100);
  const [allCateClient, setAllCateClient] = useState([]);
  const handleChangeGroup = (e) => {
    setGroup(e.target.value);
  };
  const onIngredientsChange = (e) => {
    const { value, checked } = e.target;
    setIngredients((prevIngredients) => ({
      ...prevIngredients,
      [value]: checked,
    }));
  };
  return (
    <>
      <div className="mt-2 d-flex gap-2">
        <div className="table-popup mr-4">
          <TableAccountJoinGroup
            setRefresh={setRefresh}
            refresh={refresh}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            dataFb={dataFb}
            setAllFriend={setAllFriend}
            checkboxes={checkboxes}
            setCheckboxes={setCheckboxes}
          />
        </div>
        <div className="w-100">
          <div className="flex align-items-center">
            <Checkbox
              inputId="ingredient5"
              name="pizza"
              value="devide"
              onChange={onIngredientsChange}
              checked={ingredients.devide}
            />
            <label htmlFor="ingredient5" className="ml-2">
              Chia nhóm
            </label>
            <div className="ml-2">
              <OverlayTrigger
                key="tooltip"
                placement="bottom"
                overlay={
                  <Tooltip id="tooltip-right" className="custom-tooltip">
                    <div className="text-start">
                      Chức năng này giúp bạn chia đều số lượng UID nhóm muốn
                      tham gia cho các tài khoản được chọn trong quá trình chạy
                      chức năng tham gia nhóm
                      <br />
                      - Ví dụ 1: Bạn nhập 10 UID nhóm và chọn 1 tài khoản thì
                      khi bấm "Chia nhóm" chức năng này sẽ không có tác dụng
                      <br />
                      - Ví dụ 2: Bạn nhập 10 UID nhóm và chọn 2 tài khoản thì
                      khi bấm "Chia nhóm" mỗi tài khoản sẽ đi tham gia vào 5
                      nhóm
                      <br />- Áp dụng tương tự cho trường hợp khi bạn nhập nhiều
                      UID nhóm và chọn nhiều tài khoản
                    </div>
                  </Tooltip>
                }
              >
                <span
                  style={{
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                >
                  <IoInformationCircle size={15} />
                </span>
              </OverlayTrigger>
            </div>
          </div>
          <nav style={{ width: "100%" }}>
            <nav className="d-flex gap-2 mt-4">
              <span style={{ width: "", marginBottom: "10px" }}>
                Danh sách UID
              </span>
              <OverlayTrigger
                key="tooltip"
                placement="bottom"
                overlay={
                  <Tooltip id="tooltip-right" className="custom-tooltip">
                    <div className="text-start">
                      Có thể nhập nhiều UID nhóm. UID nhóm bạn có thể quét và
                      tìm kiếm bằng cách đến mục "Công cụ Facebook" và chọn chức
                      năng quét UID Group
                      <br />
                      Nhập danh sách UID cần tiếp cận vào đây
                      <br />
                      (Vui lòng nhập định dạng
                      <br />
                      0123654478964
                      <br />
                      154502134564566
                      <br />
                      ....),
                      <br />
                      Lưu ý: không để dòng trống
                    </div>
                  </Tooltip>
                }
              >
                <span
                  style={{
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                >
                  <IoInformationCircle size={15} />
                </span>
              </OverlayTrigger>
            </nav>
            <InputTextarea
              style={{
                width: "100%",
                height: "195px",
                maxHeight: "340px",
                resize: "none",
                borderRadius: "0px",
                border: "black 1px solid",
              }}
              value={group}
              onChange={handleChangeGroup}
              placeholder={``}
              rows={5}
              cols={30}
            />
          </nav>
        </div>
      </div>
      <div className="mt-4">
        <div className="w-100 table-popup2">
          <ResultJoinGroup
            setRefresh={setRefresh}
            refresh={refresh}
            dataFb={dataFb}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            isInitialRender={isInitialRender}
            rows={rows}
            setRows={setRows}
            totalPage={totalPage}
            setTotalPage={setTotalPage}
            getTotal={getTotal}
            setGetTotal={setGetTotal}
            allCateClient={allCateClient}
            setAllCateClient={setAllCateClient}
            listResults={listResults}
            setListResults={setListResults}
            setOpenDetails={setOpenDetails}
            openDetails={openDetails}
            setItemResults={setItemResults}
            wsResults={wsResults}
            setWsResults={setWsResults}
          />
        </div>
      </div>
    </>
  );
};
