import React from "react";
import { Card } from "primereact/card";
import vehicle from "../../../img/vehicle.png";
import "./CreateMarket.css";

const Vehicles = ({ onClick }) => {
  return (
    <div style={{ width: "100%" }}>
      <Card
        className="hover-effect"
        style={{ height: "100%", padding: "20px" }}
        onClick={onClick}
      >
        <div className="d-flex flex-column gap-2">
          <img
            style={{ borderRadius: "50%", display: "block", margin: "auto" }}
            src={vehicle}
            alt=""
          />
          <div className="d-flex flex-column gap-1">
            <p style={{ fontWeight: "600", fontSize: "18px" }}>Xe cần bán</p>
            <p className="m-0">Bán xe hơi, xe tải, hoặc loại xe khác.</p>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default Vehicles;
