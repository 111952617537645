import { useState, useEffect } from "react";
import axios from "axios";
import { API_URL, API_ROUTES } from "../../../api";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { useHeaders } from "../../../const/headers";
import { Button } from "react-bootstrap";
import { useCity } from "../listlocation/ListLocation";
import CommentsInMess from "./CommentsInMess";
export const ModalCreateOrderMess = ({
  element1,
  openProduct,
  elementx,
  elementy,
  setSeclecItem,
  selecItem,
  getPageAtib,
  toast,
  elementW,
  allComments,
  setAllComments,
  listAccout,
  setRfCmt,
  rfCmt,
  t,
}) => {
  const headers = useHeaders();
  const createOrder = API_URL + API_ROUTES.CREATE_ORDER;
  const getDicrict = API_URL + API_ROUTES.GET_DISTRICT;
  const getWard = API_URL + API_ROUTES.GET_WARD;
  const [dataDic, setDataDic] = useState([]);
  const [dataWard, setDataWard] = useState([]);
  const { cities } = useCity();
  const [inputError, setInputError] = useState("");
  const countries = cities;
  const handleInputChange = (event, field, isDropdown = false) => {
    let value = isDropdown ? event.value : event.target.value;
    if (!isDropdown && field === "final_price" && !-/^\d*$/.test(value)) {
      setInputError("Vui lòng chỉ nhập số cho trường Thành tiền.");
    } else {
      setInputError("");
      setSeclecItem((prevItem) => ({
        ...prevItem,
        [field]: value,
      }));
    }
  };
  const pricePro = [];
  const postCreateOrder = async () => {
    const body = {
      name_order: selecItem?.name_order,
      phone_number: selecItem?.phone_number,
      quantity: Number(selecItem?.quantity),
      city: selecItem?.selectedCountry.city,
      district: selecItem?.selectedDirct.district,
      ward: selecItem?.selectedWard.ward,
      address: selecItem?.address,
      price: Number(selecItem?.final_price),
      note: selecItem?.note,
      product: selecItem?.product,
    };
    try {
      const response = await axios.post(createOrder, body, headers);
      setSeclecItem({});
      toast.success("Tạo đơn hàng thành công");
    } catch (err) {
      toast.error("Tạo đơn hàng thất bại");
    }
  };
  //Lấy danh sách tỉnh , huyện ,xã
  const getDataDicrict = async () => {
    if (selecItem?.selectedCountry?.id === undefined) {
    } else {
      const body = { id_city: selecItem?.selectedCountry?.id };
      try {
        const res = await axios.post(getDicrict, body, headers);
        setDataDic(res.data);
      } catch (err) {}
    }
  };
  const getDataWard = async () => {
    if (selecItem?.selectedDirct?.id === undefined) {
    } else {
      const body = { id_district: selecItem?.selectedDirct?.id };
      try {
        const res = await axios.post(getWard, body, headers);
        setDataWard(res.data);
      } catch (err) {}
    }
  };
  useEffect(() => {
    getDataDicrict();
  }, [selecItem?.selectedCountry]);
  useEffect(() => {
    getDataWard();
  }, [selecItem?.selectedDirct]);

  //Giao diện selec tỉnh huyện xã
  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <div>{option?.city}</div>
        </div>
      );
    }
    return <span>{props.placeholder}</span>;
  };
  const selectedDirctTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <div>{option?.district}</div>
        </div>
      );
    }
    return <span>{props.placeholder}</span>;
  };
  const selectedWardTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <div>{option?.ward}</div>
        </div>
      );
    }
    return <span>{props.placeholder}</span>;
  };

  return (
    <div
      className={`${openProduct === false ? "add-product-responsive" : ""}`}
      style={{ width: "300px", background: "#9e9e9e" }}
    >
      <div
        className="messzalo-addproduct d-flex h-100 flex-column"
        id="heightcreateoder"
      >
        <nav
          className="d-flex align-items-center justify-content-center "
          id="heighttitleorder"
          style={{
            height: `${element1?.offsetHeight}px`,
            borderBottom: "1px solid ",
            borderLeft: "1px solid #fff",
            background: "#2C343F",
          }}
        >
          <CommentsInMess
            t={t}
            toast={toast}
            setRfCmt={setRfCmt}
            rfCmt={rfCmt}
            listAccout={listAccout}
            setAllComments={setAllComments}
            allComments={allComments}
            getPageAtib={getPageAtib}
          />
        </nav>
        <div
          className="d-flex  flex-column p-2  justify-content-between align-items-between"
          style={{
            overflow: "auto",
            height: `calc(${elementx}px - ${elementy}px)`,
          }}
        >
          <h3
            className="text-center m-0 text-light bg-secondary p-2 radius"
            style={{
              fontSize: "24px",
            }}
          >
            {t("TẠO ĐƠN HÀNG")}
          </h3>
          <div
            style={{ height: "100%", overflow: "auto" }}
            className="w-100 d-flex flex-column reponse-overflow-order"
            xs={6}
            md={4}
          >
            <div
              className="input-group-prepend "
              style={{ width: "100%", fontSize: "1rem" }}
            >
              <span className="text-dark ">{t("Tên khách hàng")}</span>
            </div>
            <input
              type="text"
              className="form-control  w-100 radius"
              placeholder=""
              aria-label="Username"
              style={{
                height: "24px",
                padding: "6px",
                fontSize: "1rem",
                marginTop: "5px",
              }}
              aria-describedby="addon-wrapping"
              value={selecItem.name_order || ""}
              onChange={(event) => handleInputChange(event, "name_order")}
            />
            <div
              style={{ marginBottom: "0.5rem" }}
              className="flex-nowrap flex-column"
            >
              <div
                className="input-group-prepend"
                style={{ width: "100%", fontSize: "1rem" }}
              >
                <span className="text-dark">{t("Số điện thoại")}</span>
              </div>
              <input
                type="number"
                className="form-control  w-100 no-arrows radius"
                placeholder=""
                aria-label="Username"
                style={{
                  height: "24px",
                  padding: "6px",
                  fontSize: "1rem",
                  marginTop: "5px",
                }}
                aria-describedby="addon-wrapping"
                value={selecItem.phone_number || ""}
                onChange={(event) => handleInputChange(event, "phone_number")}
              />
            </div>

            <div
              style={{ marginBottom: "4px" }}
              className="flex-nowrap flex-column"
            >
              <div
                className="input-group-prepend"
                style={{ width: "100%", fontSize: "1rem" }}
              >
                <span className="text-dark">{t("Địa chỉ")}</span>
              </div>
              <div style={{}} className=" flex mb-2 justify-content-center">
                <Dropdown
                  style={{
                    height: "24px",
                    padding: "0",
                    alignItems: "center",
                    marginTop: "5px",
                  }}
                  value={selecItem?.selectedCountry}
                  onChange={(e) =>
                    handleInputChange(e, "selectedCountry", true)
                  }
                  options={countries}
                  optionLabel="city"
                  placeholder={t("Tỉnh / Thành Phố")}
                  filter
                  valueTemplate={selectedCountryTemplate}
                  className="w-full"
                />
              </div>
              <div className="mb-2 flex justify-content-center">
                <Dropdown
                  style={{
                    height: "24px",
                    padding: "0",
                    alignItems: "center",
                  }}
                  value={selecItem?.selectedDirct}
                  onChange={(e) => handleInputChange(e, "selectedDirct", true)}
                  options={dataDic}
                  optionLabel="district"
                  placeholder={t("Quận / Huyện")}
                  filter
                  valueTemplate={selectedDirctTemplate}
                  className="w-full "
                />
              </div>
              <div className=" flex justify-content-center">
                <Dropdown
                  style={{
                    height: "24px",
                    padding: "0",
                    alignItems: "center",
                  }}
                  value={selecItem?.selectedWard}
                  onChange={(e) => handleInputChange(e, "selectedWard", true)}
                  options={dataWard}
                  optionLabel="ward"
                  placeholder={t("Xã / Phường")}
                  filter
                  valueTemplate={selectedWardTemplate}
                  className="w-full "
                />
              </div>
              <input
                type="text"
                className="form-control  w-100 radius"
                placeholder={t("Địa chỉ cụ thể")}
                style={{
                  height: "24px",
                  padding: "0.5rem",
                  fontSize: "1rem",
                  marginTop: "6px",
                }}
                aria-label="Username"
                aria-describedby="addon-wrapping"
                value={selecItem.address || ""}
                onChange={(event) => handleInputChange(event, "address")}
              />
            </div>
            <div
              className="input-group flex-nowrap mb-2 flex-column"
              id="flexnowrap"
              style={{ marginBottom: "4px" }}
            >
              <div
                style={{ marginBottom: "4px" }}
                className="input-group flex-nowrap flex-column"
              >
                <div
                  className="input-group-prepend"
                  style={{ width: "100%", fontSize: "1rem" }}
                >
                  <span className="text-dark">{t("Sản phẩm")}</span>
                </div>
                <InputTextarea
                  style={{
                    padding: "6px",
                    fontSize: "1rem",
                    borderRadius: "5px",
                    marginTop: "5px",
                  }}
                  value={selecItem.product || ""}
                  onChange={(event) => handleInputChange(event, "product")}
                />
              </div>
              <div className="input-group flex-nowrap flex-column">
                <div
                  className="input-group-prepend"
                  style={{
                    width: "100%",
                    fontSize: "1rem",
                    marginTop: "10px",
                  }}
                >
                  <span className="text-dark">{t("Số lượng")}</span>
                </div>
                <input
                  type="number"
                  className="form-control  w-100 "
                  placeholder=""
                  aria-label="Username"
                  style={{
                    height: "24px",
                    padding: "6px",
                    fontSize: "1rem",
                    borderRadius: "5px",
                    marginTop: "5px",
                  }}
                  aria-describedby="addon-wrapping"
                  value={selecItem.quantity || ""}
                  onChange={(event) => handleInputChange(event, "quantity")}
                />
              </div>
              <div className="input-group flex-nowrap  flex-column">
                <div
                  className="input-group-prepend"
                  style={{ width: "100%", fontSize: "1rem" }}
                >
                  <span className="text-dark">{t("Thành tiền")}</span>
                </div>
                <nav className="d-flex gap-2 w-100">
                  <input
                    type="number"
                    className="form-control   no-arrows"
                    style={{
                      width: "70%",
                      height: "24px",
                      padding: "6px",
                      fontSize: "1rem",
                      marginTop: "5px",
                    }}
                    placeholder=""
                    aria-label="Username"
                    aria-describedby="addon-wrapping"
                    value={selecItem.final_price || ""}
                    onChange={(event) =>
                      handleInputChange(event, "final_price")
                    }
                  />
                  <input
                    disabled={true}
                    type="text"
                    className="form-control  no-arrows"
                    style={{
                      height: "24px",
                      padding: "6px",
                      width: "30%",
                      fontSize: "0.8rem",
                      marginTop: "5px",
                    }}
                    placeholder=""
                    aria-label="Username"
                    aria-describedby="addon-wrapping"
                    value="VND"
                  />
                </nav>
                {inputError && <div style={{ color: "red" }}>{inputError}</div>}
              </div>
            </div>
            <div className=" flex-nowrap mb-2 flex-column">
              <div
                className="radius input-group-prepend"
                style={{ width: "100%", fontSize: "1rem" }}
              >
                <span className="text-dark">{t("Ghi chú")}</span>
              </div>
              <InputTextarea
                style={{
                  padding: "6px",
                  fontSize: "1rem",
                  width: "100%",
                  marginTop: "5px",
                }}
                value={selecItem.note || ""}
                onChange={(event) => handleInputChange(event, "note")}
              />
            </div>
          </div>
          <div
            style={{ height: "max-content" }}
            className="d-flex justify-content-center p-0"
          >
            <Button
              onClick={postCreateOrder}
              style={{
                width: `${elementW}px`,
                height: "max-content",
                marginRight: "0",
              }}
              className="button-blue radius"
            >
              {t("Tạo")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
