import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Fieldset } from "primereact/fieldset";
import { Avatar } from "primereact/avatar";
import { Chip } from "primereact/chip";
import { API_URL, API_ROUTES_NTD } from "../../../api";
import axios from "axios";
import { useHeaders } from "../../../const/headers";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { TiTick } from "react-icons/ti";
import { Paginator } from "primereact/paginator";
import { getAxiosMarket } from "../../../const/getAxios";
import { truncateMarket } from "../../../const/splittext";
import { RiExpandDiagonal2Line } from "react-icons/ri";
export const ManageScriptGroup = ({
  setManagerScript,
  managerScript,
  scriptClient,
  setRefresh,
  refresh,
  scanListFr,
  statusRun,
  dataFb,
  setAllFriend,
  allFriend,
  listFr,
  setListFr,
  toast,
  getTotal,
  rows,
  setGetTotal,
  setRows,
  setTotalPage,
  totalPage,
  allResults,
  setListScript,
  listScript,
  itemCate,
  copyResults,
  setFirst,
  first,
}) => {
  const headers = useHeaders();
  const deleteCate = API_URL + API_ROUTES_NTD.REMOVE_CATEGORY_OF_GROUP;
  const getAllFr = API_URL + API_ROUTES_NTD.GET_ALL_GROUP;
  const deleteGroup = API_URL + API_ROUTES_NTD.DELETE_GROUP;
  const addCateGroup = API_URL + API_ROUTES_NTD.ADD_GROUP_TO_CATEGORY;
  const resultsDelete = API_URL + API_ROUTES_NTD.RESULTS_DELETE_GROUP;
  const [checked, setChecked] = useState(false);
  const [itemAccout, setItemAccount] = useState([]);
  const [deleGroup, setDeleGroup] = useState("");
  const [getTask, setGetTask] = useState([]);
  const rowsPerPageOptions = [100, 300, 500, 1000, 2000];
  const onPageChange = (event) => {
    setTotalPage(event.page);
    setFirst(event.first);
    setRows(event.rows);
  };
  const filterAccountCheckpoit = dataFb?.filter(
    (item) => item?.checkpoint === false && item.status_proxy === true
  );
  const handleRemoveChip = async (item) => {
    const body = {
      id_groups: listFr?.map((item) => item.id),
      id_category: itemCate?.id,
    };
    try {
      const res = await axios.post(deleteCate, body, headers);
      setRefresh(!refresh);
      setListFr([]);
    } catch (err) {}
  };
  const handleItemClick = (item) => {
    if (listFr.includes(item)) {
      setListFr(listFr.filter((i) => i !== item));
    } else {
      setListFr([...listFr, item]);
    }
  };
  useEffect(() => {
    if (itemAccout.id) {
      getAxiosMarket(
        getAllFr,
        setAllFriend,
        setGetTotal,
        rows,
        totalPage + 1,
        itemAccout.id
      );
    }
    setChecked(false);
  }, [refresh, itemAccout, totalPage, rows]);

  const getRsSendManual = async (taskid) => {
    const body = {
      id_task: taskid.id_task,
    };
    try {
      const res = await axios.post(resultsDelete, body, headers);
      if (res.data.status === "SUCCESS") {
        setRefresh(!refresh);
        toast.success("Rời nhóm thành công");
      }
      setDeleGroup(res.data.status);
    } catch (err) {
      setDeleGroup(err.response.data.status);
      toast.error("Rời nhóm thất bại");
    }
  };
  const removeFrClient = async (item) => {
    const confirm = window.confirm(`Bạn chắc chắn muốn xoá nhóm ${item?.name}`);
    if (confirm) {
      const body = {
        id_account: item?.account,
        id_group: item?.id,
      };
      try {
        const res = await axios.post(deleteGroup, body, headers);
        setGetTask(res.data);
        getRsSendManual(res.data);
        toast.success("Đang thực hiện rời nhóm");
      } catch (err) {
        toast.error("Rời nhóm thất bại");
      }
    }
  };
  useEffect(() => {
    if (getTask.id_task) {
      const interval = setInterval(() => {
        getRsSendManual(getTask);
      }, 3000);
      if (deleGroup === "SUCCESS" || deleGroup === "FAILURE") {
        clearInterval(interval);
      }
      return () => clearInterval(interval);
    }
  }, [deleGroup, getTask]);
  const addCateInClient = async () => {
    if (listFr?.length > 0) {
      const body = {
        id_category: itemCate.id,
        id_groups: listFr?.map((item) => item.id),
      };
      try {
        const res = await axios.post(addCateGroup, body, headers);
        toast.success("Thêm kịch bản thành công");
        setListFr([]);
        setRefresh(!refresh);
      } catch (err) {}
    }
  };
  useEffect(() => {
    checked ? setListFr(allFriend) : setListFr([]);
  }, [checked]);
  return (
    <Dialog
      header="Quản lý kịch bản"
      visible={managerScript}
      style={{ position: "relative", width: "40vw" }}
      onHide={() => setManagerScript(false)}
      className="overFlowJoinGr"
      footer={
        <div className="d-flex justify-content-end gap-2 mt-2">
          <Button
            style={{
              width: "max-content",
              fontSize: "12px",
            }}
            disabled={statusRun}
            onClick={() => addCateInClient()}
            className="mr-0 button-blue d-flex justify-content-center"
          >
            Gán kịch bản
          </Button>
          <Button
            style={{
              width: "max-content",
              fontSize: "12px",
            }}
            disabled={statusRun}
            onClick={() => handleRemoveChip()}
            className="mr-0 button-red d-flex justify-content-center"
          >
            Bỏ gán
          </Button>
          <RiExpandDiagonal2Line
            size={20}
            style={{
              position: "absolute",
              right: "0",
              bottom: "0",
            }}
          />
        </div>
      }
    >
      <div className="d-flex flex-column" style={{ height: "75vh" }}>
        <div className="d-flex gap-2">
          <Button
            style={{
              width: "max-content",
              fontSize: "12px",
            }}
            disabled={statusRun}
            onClick={() => scanListFr(itemAccout)}
            className="button-blue d-flex justify-content-center"
          >
            Quét danh sách nhóm
          </Button>
          <Button
            style={{
              width: "180px",
              fontSize: "12px",
              display: listFr.length === 0 ? "none" : "block",
            }}
            className="button-blue"
            onClick={copyResults}
          >
            Sao chép UID nhóm
          </Button>
        </div>
        <div
          className="d-flex w-100 overflow-auto mt-2 mb-2"
          style={{ border: "1px solid", height: "142px" }}
        >
          {filterAccountCheckpoit?.map((item, index) => (
            <div
              className={`flex flex-column p-2 align-items-center gap-2 w-100`}
              style={{ cursor: "pointer" }}
              onClick={() => setItemAccount(item)}
            >
              <img
                style={{
                  width: "36px",
                  borderRadius: "50%",
                  background: itemAccout.id === item.id ? "#0084ff" : "#cdc4c5",
                  padding: itemAccout.id === item.id ? "8px" : "",
                }}
                src={item.avatar}
                alt={item.name}
              />
              <p style={{ width: "max-content", fontSize: "12px" }}>
                {item.name}
              </p>
            </div>
          ))}
        </div>
        <div
          className="d-flex flex-column h-100 overflow-auto"
          style={{ border: "1px solid" }}
        >
          <div className="d-flex gap-2 align-items-center">
            <p style={{ fontWeight: "700", padding: "0.5rem" }}>
              Danh sách nhóm
            </p>
            <Checkbox
              onChange={(e) => setChecked(e.checked)}
              checked={checked}
            ></Checkbox>
            <p style={{ fontSize: "12px" }}>Chọn tất cả</p>
          </div>
          <div className="d-flex flex-column h-100 overflow-auto custom-scrollbar">
            {allFriend?.map((item, index) => (
              <div
                className={`flex p-2 justify-content-between align-items-center gap-2 w-100 ${
                  listFr.includes(item) ? "selected-item" : ""
                }`}
                style={{ cursor: "pointer" }}
                onClick={() => handleItemClick(item)}
              >
                <nav className="d-flex gap-2 align-items-center">
                  <p
                    style={{
                      width: "max-content",
                      fontSize: "12px",
                    }}
                  >
                    {index + 1}
                    {"/ "}
                  </p>
                  <p
                    style={{
                      width: "max-content",
                      fontSize: "12px",
                    }}
                  >
                    {item.group_uid}
                  </p>
                  <p
                    style={{
                      width: "max-content",
                      fontSize: "12px",
                    }}
                  >
                    {truncateMarket(item.name, 150)}
                  </p>
                  {/* <button
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleExpand(item.id);
                    }}
                  >
                    {isExpandedItems[item.id] ? "Thu gọn" : "Xem thêm"}
                  </button> */}
                </nav>
                <nav className="d-flex gap-2 align-items-center">
                  {item?.category_groups?.length !== 0 &&
                    item?.category_groups?.some(
                      (item) => item.id === itemCate.id
                    ) && (
                      <p style={{ color: "blue", fontSize: "12px" }}>
                        Đã được gán
                        <TiTick />
                      </p>
                    )}
                  <Button
                    style={{
                      width: "max-content",
                      fontSize: "12px",
                      borderRadius: "5px",
                    }}
                    onClick={() => removeFrClient(item)}
                    className="mr-0 button-red d-flex justify-content-center"
                  >
                    Rời nhóm
                  </Button>
                </nav>
              </div>
            ))}
          </div>
          <Paginator
            className="pagination-markerting"
            first={first}
            rows={rows}
            totalRecords={getTotal?.count}
            onPageChange={onPageChange}
            rowsPerPageOptions={rowsPerPageOptions}
          />
        </div>
      </div>
    </Dialog>
  );
};
