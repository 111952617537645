import { useState } from "react";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { useHeaders } from "../../../../../const/headers";
import { API_URL, API_ROUTES } from "../../../../../api";
import { Image } from "primereact/image";
import { TiDelete } from "react-icons/ti";
import { deleteFileOnServer } from "../../../../../const/deleteFileOnServer";
import axios from "axios";
export const DataEditScriptArticlePost = ({
  setComments,
  setListImage,
  listImage,
  comments,
}) => {
  const headers = useHeaders();
  const apiGetLink = API_URL + API_ROUTES.GET_LINK_FILE;
  const [imageURLs, setImageURLs] = useState([]);
  const [listFile, setListFile] = useState([]);
  const showFileInput = () => {
    document.getElementById(`fileInput`).click();
  };
  const handleGetImg = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    try {
      const res = await axios.post(apiGetLink, formData, headers);
      setListImage((prevURLs) => [...prevURLs, res.data.file]);
    } catch (err) {}
  };
  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    for (let i = 0; i < files.length; i++) {
      handleGetImg(files[i]);
    }
    setListFile(files);
    const newImageURLs = files.map((file) => URL.createObjectURL(file));
    setImageURLs((prevURLs) => [...prevURLs, ...newImageURLs]);
  };
  const handleRemoveImage = (index) => {
    const updatedURLs = [...imageURLs];
    const updatedFile = [...listFile];
    const updatedImage = [...listImage];
    deleteFileOnServer(listImage[index]);
    updatedURLs.splice(index, 1);
    updatedFile.splice(index, 1);
    updatedImage.splice(index, 1);
    setImageURLs(updatedURLs);
    setListFile(updatedFile);
    setListImage(updatedImage);
  };
  return (
    <div className="mt-2 d-flex gap-2 justify-content-between w-100">
      <div className="d-flex flex-column" style={{ width: "50%" }}>
        <nav className="d-flex align-items-center gap-2">
          <p className="mb-0">Nội dung </p>
        </nav>
        <div className="p-inputgroup flex-1 mt-2">
          <InputTextarea
            value={comments}
            onChange={(e) => setComments(e.target.value)}
            placeholder={`Nhập nội dung `}
            rows={5}
            cols={30}
          />
        </div>
      </div>
      <div style={{ width: "50%" }}>
        <nav className="d-flex align-items-center gap-2">
          <p className="mb-0">Danh sách ảnh </p>
          <Button
            className="button-blue radius"
            style={{ padding: "6px 8px" }}
            onClick={showFileInput}
          >
            Thêm ảnh
          </Button>
        </nav>
        <div className="flex flex-wrap">
          {listImage?.map((url, index) => (
            <div key={index} className="position-relative m-2">
              <Image
                width="50"
                height="50"
                src={`${API_URL}/${url}`}
                alt={`Selected ${index}`}
                preview
              />
              <button
                className="btn-remove"
                style={{ position: "absolute", top: "-6px", right: "-8px" }}
                onClick={() => handleRemoveImage(index)}
              >
                <TiDelete size={15} />
              </button>
            </div>
          ))}
        </div>
        <input
          id={`fileInput`}
          type="file"
          multiple
          style={{ display: "none" }}
          onChange={(e) => handleImageChange(e)}
        />
      </div>
    </div>
  );
};
