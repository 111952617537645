import React, { useState, useEffect, useRef } from "react";
import Navigate from "../container/Navigate";
import "./style.css";
import _ from "lodash";
import { Footer } from "../layout/Footer";
import { Toast } from "primereact/toast";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Form } from "react-bootstrap";
import { IoMdAdd } from "react-icons/io";
import { useHeaders } from "../../const/headers";
import { API_URL, API_ROUTES } from "../../api";
import { useUser } from "../../contexts/UserContext";
import { InputTextarea } from "primereact/inputtextarea";
import { InputSwitch } from "primereact/inputswitch";
import { MdDeleteForever } from "react-icons/md";
import axios from "axios";
import { InputGroup } from "react-bootstrap";
import { IoSearch } from "react-icons/io5";
import TrainBot from "../container/bottraining/TrainBot";
import { updateTitle } from "../../const/updateTitle";
import { MultiSelect } from "primereact/multiselect";
import { getAxios } from "../../const/getAxios";
import { Tooltip, OverlayTrigger, Popover } from "react-bootstrap";
import { downloadImage } from "../../const/dowloadFile";
import { FaTimes } from "react-icons/fa";
import { deleteFileOnServer } from "../../const/deleteFileOnServer";
import { IoInformationCircle } from "react-icons/io5";
import { ModalCopyBot } from "../container/chatbot/ModalCopyBot";
import { AiFillEyeInvisible } from "react-icons/ai";
import { AiFillEye } from "react-icons/ai";
import { PositionChatbot } from "../container/chatbot/PositionChatbot";
const ChatBotMess = (props) => {
  updateTitle("Chốt Sale - Chatbot");
  const getLinkImg = API_URL + API_ROUTES.GET_LINK_FILE;
  const createChatbot = API_URL + API_ROUTES.CREATE_CHATBOT;
  const getMessChatbot = API_URL + API_ROUTES.CHAT_BOT_API;
  const activeChatbot = API_URL + API_ROUTES.ACTIVE_CHATBOT;
  const deleteChabot = API_URL + API_ROUTES.DELETE_CHATBOT;
  const deleteFile = API_URL + API_ROUTES.DELETE_FILE;
  const getDataFb = API_URL + API_ROUTES.GET_ACC_FB;
  const headers = useHeaders();
  const toastBottomRight = useRef(null);
  const toastBottomRight1 = useRef(null);
  const { closeList } = useUser();
  const formData = new FormData();
  const [elementHeight, setElementHeight] = useState(0);
  const [openMdCopy, setOpenMdCopy] = useState(false);
  const [openPosition, setOpenPosition] = useState(false);
  const [idFile, setIdFile] = useState("");
  const [heightAll, setHeightAll] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [imageURL, setImageURL] = useState("");
  const [imagLink, setImagLink] = useState([]);
  const [itemPage, setItemPage] = useState([]);
  const [fileChanged, setFileChanged] = useState(false);
  const [getScript, setGetScript] = useState([]);
  const [jsonParse, setJsonParse] = useState();
  const [jsonFile, setJsonFile] = useState([{}]);
  const fileInputRef = useRef(null);
  const [dataFb, setDataFb] = useState([]);
  const [dataBlob, setDataBlob] = useState(null);
  const rfLocal = localStorage.getItem("access");
  const [hiddenBot, setHiddenBot] = useState(false);
  const inputRef = useRef(null);

  const [contents, setContents] = useState([
    {
      id: 1,
      list_key: [],
      messages_bot: "",
      images: null,
      imageURL: null,
    },
  ]);
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file.type.match("application/json")) {
      return;
    }
    const reader = new FileReader();

    reader.onload = (event) => {
      const fileContent = event.target.result;
      const jsonData = JSON.parse(fileContent);
      setJsonParse(jsonData);
    };

    reader.readAsText(file);
  };
  useEffect(() => {
    const postContents = async () => {
      if (!jsonParse || jsonParse.length === 0) {
        return;
      }
      for (const item of jsonParse) {
        const body = {
          id_chatbot: null,
          id_account: itemPage.id,
          list_key: item.list_key,
          messages_bot: item.messages_bot,
          image: item.images,
        };
        try {
          const res = await axios.post(createChatbot, body, headers);
          toast.success("Lưu thành công");
        } catch (err) {}
      }
      getContent();
    };

    postContents();
  }, [jsonParse]);

  const chatBotDele = async (item) => {
    const confirm = window.confirm("Bạn chắc chắn muốn xoá chứ !");
    if (confirm) {
      if (item.bot_id) {
        const body = {
          id_chatbot: item.bot_id,
        };
        try {
          const res = await axios.post(deleteChabot, body, headers);
          setRefresh(!refresh);
          toast.success("Xoá thành công !");
        } catch (err) {
          toast.error("Xoá thất bại !");
        }
      }
    }
  };
  const addContent = () => {
    const newId = contents.length;
    setContents([
      ...contents,
      {
        id: newId,
        list_key: [],
        messages_bot: "",
        images: null,
        imageURL: null,
      },
    ]);
  };
  const handleDeleteContent = (id) => {
    const newContents = contents.filter((content) => content.id !== id);
    setContents(newContents);
  };
  const postContent = async (content) => {
    if (content?.list_key.length === 0 || content.messages_bot.length === 0) {
      alert("Vui lòng nhập đầy đủ thông tin");
      return;
    }
    const body = {
      id_chatbot: content.bot_id || null,
      id_account: itemPage.id,
      list_key: content?.list_key,
      messages_bot:
        typeof content.messages_bot === "object"
          ? content.messages_bot
          : content.messages_bot,
      image:
        content && content.images && content.images.file !== undefined
          ? content.images.file
          : content && content.images
          ? content.images
          : null,
    };
    try {
      const res = await axios.post(createChatbot, body, headers);
      toast.success("Lưu thành công");
      getContent();
    } catch (err) {}
  };
  const getContent = async () => {
    if (itemPage.id) {
      const body = { id_account: itemPage.id };
      try {
        const res = await axios.post(getMessChatbot, body, headers);
        setGetScript(res.data);
      } catch (err) {}
    }
  };
  useEffect(() => {
    if (Array.isArray(getScript)) {
      const newContents = getScript.map((item, i) => ({
        bot_id: item.id,
        page_id: item.page,
        active_chatbot: item.active_chatbot,
        id: i,
        list_key: item.list_key,
        messages_bot: item.messages_bot,
        images: item.image,
        imageURL: null,
      }));
      setContents(newContents);
    }
    if (Array.isArray(getScript)) {
      const newContents = getScript.map((item, i) => ({
        page_id: item.page,
        list_key: item.list_key,
        messages_bot: item.messages_bot,
        images: item.image,
        active_chatbot: item.active_chatbot,
      }));
      setJsonFile(newContents);
    }
  }, [getScript]);
  const chatbotActive = async (item) => {
    if (item.bot_id === undefined) {
      alert("Vui lòng lưu kịch bản này trước khi bật/tắt");
    } else {
      const body = {
        id_chatbot: item.bot_id,
        active_chatbot: item?.active_chatbot === true ? 0 : 1,
      };
      try {
        const res = await axios.post(activeChatbot, body, headers);
        setRefresh(!refresh);
      } catch (err) {}
    }
  };
  useEffect(() => {
    getAxios(getDataFb, setDataFb);
    getContent();
  }, [itemPage.id, refresh]);
  const handleImageChange = (e, id) => {
    setImageURL(e.target.files[0]);
    const updatedContents = contents.map((content) =>
      content.id === id ? { ...content, imageURL: e.target.files[0] } : content
    );
    setContents(updatedContents);
    setFileChanged(true);
  };
  const linkImage = async (index) => {
    const selectedFile = contents[index]?.imageURL;
    if (selectedFile !== null) {
      formData.append("file", selectedFile || "");
      try {
        const res = await axios.post(getLinkImg, formData, headers);
        setImagLink((prevImagLink) => [...prevImagLink, res.data]);
        setContents((prevContents) =>
          prevContents.map((content, i) =>
            i === index ? { ...content, images: res.data } : content
          )
        );
      } catch (err) {}
    }
  };
  const deleteFiles = async () => {
    if (imagLink.length > 1) {
      const lastFile = imagLink[imagLink.length - 1];
      const imagLinkToDelete = imagLink.slice(0, imagLink.length - 1);
      for (const file of imagLinkToDelete) {
        await deleteFileImg(file.id);
      }
    }
  };
  const deleteFileImg = async (id) => {
    const body = { id: id };
    try {
      const res = await axios.post(deleteFile, body, headers);
      setImagLink((prevImagLink) =>
        prevImagLink.filter((file) => file.id !== id)
      );
    } catch (err) {}
  };
  useEffect(() => {
    deleteFiles();
  }, [imagLink]);
  useEffect(() => {
    if (fileChanged) {
      linkImage(idFile);
      setFileChanged(false);
    }
  }, [fileChanged, contents, idFile]);

  const showFileInput = (id) => {
    setIdFile(id);
    document.getElementById(`fileInput_${id}`).click();
  };
  const [element3, setElement3] = useState("");
  useEffect(() => {
    const element = document.getElementById("select-eight");
    const element1 = document.getElementById("heightOne");
    const element2 = document.getElementById("heightTwo");
    setElement3(
      document.getElementById("navigate-container-height").offsetWidth
    );
    if (element) {
      setElementHeight(element?.offsetHeight);
      setHeightAll(element1?.offsetHeight + element2?.offsetHeight);
    }
  }, [itemPage, closeList]);

  const exportData = async () => {
    try {
      for (let i = 0; i < jsonFile.length; i++) {
        const item = jsonFile[i];
        await downloadImage(
          `${API_URL}/${item.images}`,
          "image.png",
          setDataBlob
        );

        if (dataBlob instanceof File) {
          const formData = new FormData();
          formData.append("file", dataBlob);
          try {
            const res = await axios.post(getLinkImg, formData, {
              headers: {
                Authorization: `Bearer ${rfLocal}`,
              },
            });
            jsonFile[i].images = res.data.file;
          } catch (err) {
            console.error("Lỗi khi gửi POST request:", err);
          }
        } else {
          console.error("dataBlob không phải là một đối tượng File hợp lệ.");
        }
      }

      // Tạo file JSON và tự động tải xuống sau khi tất cả các promises hoàn thành
      const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
        JSON.stringify(jsonFile)
      )}`;
      const link = document.createElement("a");
      link.href = jsonString;
      link.download = `data.json`;
      link.click();
    } catch (error) {
      console.error("Có lỗi xảy ra trong quá trình xuất dữ liệu:", error);
    }
  };

  const [selectedKeywords, setSelectedKeywords] = useState([]);
  useEffect(() => {
    if (contents && contents.length > 0) {
      const selectedListKey = contents[0]?.list_key || [];
      setSelectedKeywords(selectedListKey);
    }
  }, [contents]);

  const handleKeyDown = (e, content) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const newKeyword = e.target.value.trim();
      if (newKeyword !== "") {
        const newContent = {
          ...content,
          list_key: [...content.list_key, newKeyword],
        };
        const updatedContents = contents.map((c) =>
          c.id === content.id ? newContent : c
        );
        setContents(updatedContents);
        setSelectedKeywords(newContent.list_key);
        e.target.value = "";
      }
    }
  };

  const handleMultiSelectChange = (e, index) => {
    const updatedContents = [...contents];
    updatedContents[index].list_key = e.value;
    setContents(updatedContents);
  };

  const handleAddSplitMarker = (index) => {
    const updatedContents = [...contents];
    updatedContents[
      index
    ].messages_bot = `${updatedContents[index]?.messages_bot}Xin chào [gender] [name] ! bên shop em có sản phẩm [gender] cần`;
    setContents(updatedContents);
    inputRef.current.focus();
  };

  useEffect(() => {
    if (contents && contents.length > 0) {
      setSelectedKeywords(contents[0].list_key);
    } else {
      setSelectedKeywords([]);
    }
  }, [contents]);
  const removeImage = (index, content) => {
    deleteFileOnServer(content.imageURL || content.images);
    setContents((prevItems) => {
      const newItems = [...prevItems];
      newItems[index].images = null;
      newItems[index].imageURL = null;
      return newItems;
    });
  };
  const toggleHiddenBot = (id) => {
    setHiddenBot((prevHidden) => ({
      ...prevHidden,
      [id]: !prevHidden[id],
    }));
  };

  return (
    <div className="d-flex flex-column " style={{ height: "100vh" }}>
      <div className="notifica-container">
        <div className="navigate-container" id="navigate-container-height">
          <Navigate />
          <ToastContainer />
          <Toast ref={toastBottomRight} position="bottom-right" />
          <Toast ref={toastBottomRight1} position="bottom-right" />
        </div>
        <div
          className="d-flex flex-column justify-content-between"
          style={{
            width: `calc(100vw - ${element3}px)`,
            overflowY: "hidden",
          }}
        >
          <div className="h-100 d-flex flex-column">
            <nav
              id="heightOne"
              className="w-100  d-flex align-items-center gap-4 pl-2"
              style={{
                height: "85px",
                background: "#2c343f",
                overflowX: "auto",
              }}
            >
              {dataFb
                ?.filter((item) => item.checkpoint === false)
                ?.filter((item) => item.status_proxy === true)
                ?.map((item, index) => (
                  <nav
                    key={index}
                    style={{ cursor: "pointer", width: "max-content" }}
                    onClick={() => {
                      setItemPage(item);
                      setContents([]);
                    }}
                    className="d-flex flex-column align-items-center mr-2"
                  >
                    <img
                      style={{
                        width: "36px",
                        borderRadius: "50%",
                        background:
                          itemPage.id === item.id ? "#0084ff" : "#cdc4c5",
                        padding: itemPage.id === item.id ? "8px" : "",
                      }}
                      src={item.avatar}
                      alt={item.name}
                    />
                    <p
                      className="mb-0 text-light"
                      style={{ width: "max-content", textWrap: "nowrap" }}
                    >
                      {item.name}
                    </p>
                  </nav>
                ))}
            </nav>
            {itemPage.id !== undefined ? (
              <nav
                id="heightTwo"
                className="w-100 d-flex p-2 gap-2 align-items-center"
                style={{ height: "60px", background: "#545454" }}
              >
                <nav
                  className="d-flex gap-2 algin-items-center"
                  style={{ width: "50%" }}
                >
                  <p className="mb-0 text-light" style={{ fontSize: "12px" }}>
                    {`Chú ý : Khi người dùng nhắn tin chứa một trong các câu hoặc từ khóa dưới đây thì chat bot tự động trả lời theo kịch bản.
                    Thêm nhiều nội dung để xây dựng hệ thống chatbot tự động bán hàng.`}
                  </p>
                </nav>
                <nav
                  className="d-flex gap-4 justify-content-end"
                  style={{ width: "50%" }}
                >
                  {/* <Button
                    onClick={() => setOpenPosition(true)}
                    className="button-blue border"
                    style={{ borderRadius: "0" }}
                  >
                    Đổi vị trí Bot
                  </Button> */}
                  <Button
                    onClick={() => setOpenMdCopy(true)}
                    className="button-blue border"
                    style={{ borderRadius: "0" }}
                  >
                    Sao chép Bot
                  </Button>
                  {/* <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                  <OverlayTrigger
                    key="tooltip"
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-right" className="custom-tooltip">
                        <div className="text-start">
                          Chức năng "xuất BOT" và "nhập BOT" giúp bạn đồng bộ
                          kịch bản CHATBOT cho nhiều tài khoản sau khi bạn đã có
                          một kịch bản chuẩn trên một tài khoản
                          <br />
                          - Cách sử dụng:
                          <br />
                          + Cài đặt kịch bản bao gồm từ khóa câu hỏi và câu trả
                          lời cho một tài khoản sau đó chọn "xuất BOT"
                          <br />+ Tiếp theo: Chọn một tài khoản và chọn chức
                          năng "nhập BOT" rồi chọn file kịch bản BOT bạn đã
                          "xuất BOT" trước đó, sau đó kích vào các nút "Bật/Tắt"
                          để khởi động BOT cho tài khoản mới
                        </div>
                      </Tooltip>
                    }
                  >
                    <span
                      style={{
                        cursor: "pointer",
                        fontWeight: "bold",
                        color: "red",
                        fontSize: "14px",
                        marginTop: "10px",
                      }}
                    >
                      <IoInformationCircle size={15} />
                    </span> 
                  </OverlayTrigger> */}
                  {/* <Button
                    className="button-blue border w-100"
                    style={{ borderRadius: "0" }}
                    onClick={handleButtonClick}
                  >
                    Nhập Bot
                  </Button> */}
                </nav>
              </nav>
            ) : (
              <nav></nav>
            )}
            {itemPage.id !== undefined ? (
              <div
                className="d-flex h-100"
                style={{
                  height: `calc(100vh - ${heightAll}px`,
                  overflow: "auto",
                }}
              >
                <div
                  className="border-dark p-2"
                  style={{
                    width: "50%",
                    borderRight: "1px solid",
                    overflow: "auto",
                  }}
                >
                  {contents?.map((content, index) => (
                    <div>
                      <div
                        className=" w-100 text-center d-flex justify-content-between align-items-center"
                        style={{
                          background: "#054ddb",
                          border: "1px solid #054ddb",
                          marginRight: "8px",
                          padding: "0.5rem 0.75rem",
                          color: "white",
                          marginBottom: "-1px",
                          borderStartEndRadius: "6px",
                          borderStartStartRadius: "6px",
                          marginBottom: hiddenBot[content.id] ? "0.5rem" : "",
                          borderRadius: hiddenBot[content.id] ? "6px" : "",
                        }}
                      >
                        <h6 className="mb-0">Bot {content.id + 1}</h6>
                        {hiddenBot[content.id] ? (
                          <AiFillEyeInvisible
                            onClick={() => toggleHiddenBot(content.id)}
                            style={{ cursor: "pointer" }}
                            size={20}
                          />
                        ) : (
                          <AiFillEye
                            onClick={() => toggleHiddenBot(content.id)}
                            style={{ cursor: "pointer" }}
                            size={20}
                          />
                        )}
                      </div>
                      {!hiddenBot[content.id] ? (
                        <div
                          key={content.id}
                          className="card flex justify-content-center p-2"
                          style={{
                            border: "1px solid",
                            marginBottom: "10px",
                            borderRadius: "0",
                            borderEndEndRadius: "6px",
                            borderEndStartRadius: "6px",
                          }}
                        >
                          <div className="d-flex justify-content-end gap-2">
                            <Button
                              onClick={() => {
                                setContents((pre) => [
                                  ...pre,
                                  {
                                    bot_id: null,
                                    images: content.images,
                                    list_key: content.list_key,
                                    messages_bot: content.messages_bot,
                                  },
                                ]);
                              }}
                              className="button-blue text-light "
                            >
                              Sao chép
                            </Button>
                            <Button
                              onClick={() => postContent(content)}
                              className="button-blue text-light "
                            >
                              Lưu
                            </Button>
                            <MdDeleteForever
                              style={{ cursor: "pointer", color: "red" }}
                              onClick={() => {
                                handleDeleteContent(content.id);
                                chatBotDele(content);
                              }}
                              size={40}
                            />
                            <div className="card d-flex flex-row justify-content-around  align-items-center ">
                              <InputSwitch
                                onClick={() => chatbotActive(content)}
                                checked={content?.active_chatbot}
                              />
                              <p className="mb-0">
                                {content?.active_chatbot === true
                                  ? "Bật"
                                  : "Tắt"}
                              </p>
                            </div>
                          </div>
                          <nav
                            className="d-flex"
                            style={{
                              fontSize: "1rem",
                            }}
                          >
                            <p className="mb-0 mr-2">Câu - Từ khóa: lưu ý</p>
                            <OverlayTrigger
                              key="tooltip"
                              placement="top"
                              overlay={
                                <Tooltip
                                  id="tooltip-right"
                                  className="custom-tooltip"
                                >
                                  <div className="text-start">
                                    Từ khóa dài thì chỉ cần nhập 1 từ khóa trong
                                    câu hỏi của khách hàng nhắn cho bạn thì phần
                                    mềm sẽ tự động bắt nội dung và trả lời
                                    <br />
                                    - Ví dụ: Câu hỏi của khách hàng là "Giá bao
                                    nhiêu" thì thay vì nhập nguyên câu, bạn áp
                                    dụng theo trường hợp cụ thể được mô tả bên
                                    dưới.
                                    <br />
                                    - Trường hợp cụ thể: Khách hàng nhắn cho bạn
                                    "Giá bao nhiêu vậy shop"
                                    <br />+ Bạn chỉ cần cài đặt từ khóa là:
                                    "Giá"
                                    <br />- Như vậy sẽ tránh tình trạng trùng
                                    lặp tin nhắn nhiều lân cùng nội dung.
                                  </div>
                                </Tooltip>
                              }
                            >
                              <span
                                style={{
                                  cursor: "pointer",
                                  fontWeight: "bold",
                                }}
                              >
                                <IoInformationCircle size={15} />
                              </span>
                            </OverlayTrigger>
                          </nav>
                          <input
                            style={{
                              border: "1px solid #d1d1d1",
                              borderRadius: "6px",
                              padding: "10px",
                              marginBottom: "5px",
                              fontSize: "1rem",
                            }}
                            placehlder="Nhập nội dung và nhấn Enter để tạo 1 từ khoá"
                            onKeyDown={(e) => handleKeyDown(e, content)}
                            rows={5}
                            cols={30}
                          />
                          <MultiSelect
                            value={content.list_key}
                            onChange={(e) => handleMultiSelectChange(e, index)}
                            options={content.list_key}
                            optionLabel=""
                            display="chip"
                            placeholder="Danh sách từ khoá"
                            maxSelectedLabels={5}
                            className="w-full w-100"
                          />
                          <nav
                            className="d-flex mt-4"
                            style={{
                              fontSize: "1rem",
                            }}
                          >
                            <div className="d-flex align-items-center">
                              <p className="mb-0 mr-2">Nội dung câu trả lời</p>
                              <OverlayTrigger
                                key="tooltip"
                                placement="top"
                                overlay={
                                  <Tooltip
                                    id="tooltip-right"
                                    className="custom-tooltip"
                                  >
                                    <div className="text-start">
                                      Nội dung viết xuống dòng bằng cách bấm
                                      phím enter hoặc tổ hợp phím shift+enter
                                      <br />
                                      Lưu ý
                                      <br />
                                      Mẫu trả lời tự động bắt buộc phải có
                                      [gender] và [name]
                                      <br />
                                      [gender] - Chèn giới tính của khách hàng
                                      <br />
                                      [name] - Chèn tên của khách hàng
                                      <br />
                                      - Ví dụ: xin chào [gender] [name] ! bên
                                      shop em có sản phẩm [gender] cần.
                                      <br />
                                      sẽ là: xin chào anh Vũ ! bên shop em có
                                      sản phẩm anh cần.
                                    </div>
                                  </Tooltip>
                                }
                              >
                                <span
                                  style={{
                                    cursor: "pointer",
                                    fontWeight: "bold",
                                  }}
                                >
                                  <IoInformationCircle size={15} />
                                </span>
                              </OverlayTrigger>
                              <Button
                                className="button-blue radius"
                                style={{
                                  padding: "6px 8px",
                                  marginLeft: "10px",
                                }}
                                onClick={() => handleAddSplitMarker(index)}
                              >
                                Mẫu
                              </Button>
                            </div>
                          </nav>
                          <InputTextarea
                            style={{
                              marginTop: "5px",
                              fontSize: "1rem",
                            }}
                            placeholder="Nhập câu trả lời của bạn"
                            value={content.messages_bot}
                            onChange={(e) => {
                              const newContents = contents.map((c) =>
                                c.id === content.id
                                  ? { ...c, messages_bot: e.target.value }
                                  : c
                              );
                              setContents(newContents);
                            }}
                            rows={5}
                            cols={30}
                            ref={inputRef}
                          />
                          <div className="d-flex gap-2 justify-content-between mt-2">
                            <div className="d-flex gap-2 ">
                              <label htmlFor="fileInput">
                                <div className="d-flex">
                                  <button
                                    className="d-flex flex-column align-items-center gap-4 p-4 border border-secondary justify-content-between rounded mr-2"
                                    onClick={() => showFileInput(content.id)}
                                  >
                                    <IoMdAdd />
                                    <p className="mb-0">Chọn ảnh</p>
                                  </button>
                                  <OverlayTrigger
                                    key="tooltip"
                                    placement="top"
                                    overlay={
                                      <Tooltip
                                        id="tooltip-right"
                                        className="custom-tooltip"
                                      >
                                        <div className="text-start">
                                          Hạn chế gửi kèm ảnh. Tính năng gửi kèm
                                          ảnh dễ bị hạn chế do cơ chế chống spam
                                          của Facebook
                                        </div>
                                      </Tooltip>
                                    }
                                  >
                                    <span
                                      style={{
                                        cursor: "pointer",
                                        fontWeight: "bold",
                                        fontSize: "15px",
                                      }}
                                    >
                                      <IoInformationCircle size={15} />
                                    </span>
                                  </OverlayTrigger>
                                </div>
                              </label>
                              <input
                                id={`fileInput_${content.id}`}
                                type="file"
                                style={{ display: "none" }}
                                onChange={(e) =>
                                  handleImageChange(e, content.id)
                                }
                              />
                              <div
                                style={{
                                  position: "relative",
                                  display: "inline-block",
                                }}
                              >
                                {content?.images !== null ? (
                                  <img
                                    style={{
                                      width: "100px",
                                      height: "100px",
                                      display:
                                        content?.images?.length === 0
                                          ? "none"
                                          : "block",
                                    }}
                                    src={`${API_URL}/${
                                      content?.images?.id
                                        ? content?.images?.file
                                        : content?.images
                                    }`}
                                    alt="Selected"
                                  />
                                ) : (
                                  <div>
                                    {content?.imageURL &&
                                      typeof content?.imageURL === "object" && (
                                        <img
                                          style={{
                                            width: "100px",
                                            height: "100px",
                                          }}
                                          src={URL.createObjectURL(
                                            content?.imageURL
                                          )}
                                          alt="Selected"
                                        />
                                      )}
                                  </div>
                                )}
                                <FaTimes
                                  onClick={() => removeImage(index, content)}
                                  style={{
                                    position: "absolute",
                                    top: 0,
                                    right: 0,
                                    cursor: "pointer",
                                    backgroundColor: "white",
                                    borderRadius: "50%",
                                    padding: "2px",
                                    fontSize: "18px",
                                    display:
                                      content?.imageURL || content?.images
                                        ? "block"
                                        : "none",
                                  }}
                                  src={`${API_URL}/${
                                    content?.images?.id
                                      ? content?.images?.file
                                      : content?.images
                                  }`}
                                  alt="Selected"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  ))}
                  <nav
                    className="pt-2 w-100 d-flex gap-2"
                    style={{ marginBottom: "20px" }}
                  >
                    <Button
                      className="mb-4 w-100 custom-button"
                      onClick={addContent}
                    >
                      Thêm nội dung
                    </Button>
                  </nav>
                </div>
                <div
                  className="p-2 d-flex flex-column h-100"
                  style={{ width: "50%" }}
                >
                  <div className="d-flex justify-content-between">
                    <div className="d-flex">
                      <h6
                        style={{
                          background: "#054ddb",
                          border: "1px solid #054ddb",
                          marginRight: "8px",
                          padding: "0.5rem 0.75rem",
                          color: "white",
                          marginBottom: "-1px",
                        }}
                        className=" radius text-center"
                      >
                        Câu hỏi mới
                      </h6>
                      <OverlayTrigger
                        key="tooltip"
                        placement="top"
                        overlay={
                          <Tooltip
                            id="tooltip-right"
                            className="custom-tooltip"
                          >
                            <div className="text-start">
                              Là những tin nhắn mới hoặc câu hỏi mới chưa có câu
                              trả lời tự động trong BOT.
                              <br />- Bạn có thể copy những câu này và nhập vào
                              bên mục BOT. Hệ thống sẽ tự động trả lời giúp bạn
                              ở lần sau.
                            </div>
                          </Tooltip>
                        }
                      >
                        <span
                          style={{
                            cursor: "pointer",
                            fontWeight: "bold",
                            fontSize: "15px",
                          }}
                        >
                          <IoInformationCircle size={15} />
                        </span>
                      </OverlayTrigger>
                    </div>

                    <InputGroup
                      className="responsive-width-acc"
                      style={{
                        width: "auto",
                        borderRadius: "0",
                      }}
                    >
                      <Form.Control
                        placeholder="Tìm kiếm nhanh"
                        aria-describedby="basic-addon2"
                        className="radius"
                      />
                      <Button
                        variant="outline-secondary"
                        id="button-addon2"
                        className="search-text-respon"
                        style={{
                          borderTopRightRadius: "5px",
                          borderBottomRightRadius: "5px",
                          borderRadius: "5px",
                          backgroundColor: "#054DDB",
                          color: "white",
                        }}
                      >
                        Tìm kiếm
                      </Button>
                      <IoSearch
                        className="responsive-hidden-search"
                        style={{ display: "none", fontSize: "42px" }}
                      />
                    </InputGroup>
                  </div>
                  <TrainBot itemPage={itemPage} />
                </div>
              </div>
            ) : (
              <nav></nav>
            )}
          </div>
        </div>
        <ModalCopyBot
          itemPage={itemPage}
          toast={toast}
          dataFb={dataFb}
          openMdCopy={openMdCopy}
          setOpenMdCopy={setOpenMdCopy}
        />
        {/* <PositionChatbot
          getScript={getScript}
          openPosition={openPosition}
          setOpenPosition={setOpenPosition}
        /> */}
      </div>
      <Footer />
    </div>
  );
};

export default ChatBotMess;
