import axios from "axios";
import { CountUp } from "countup.js";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import { useEffect, useRef, useState } from "react";
import { TiTick } from "react-icons/ti";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import validator from "validator";
import { API_ROUTES_NTD, API_URL } from "../../../api";
import { useHeaders } from "../../../const/headers";
import "./ModalFormRedue.css";
import thankyou from "./Thanks-for-purchase-of-software.jpg";
import img2 from "./close.png";
import img1 from "./tickpng.png";
function ModalFromRedue({
  openModal,
  setOpenModal,
  typeModal,
  openPaySuccess,
  setOpenPaySuccess,
}) {
  const postExtend = API_URL + API_ROUTES_NTD.POST_DATA_EXTEND;
  const apiGetQr = API_URL + API_ROUTES_NTD.GET_QR;
  const headers = useHeaders();
  const [dataInfor, setDataInfor] = useState({
    name: "",
    phone: "",
    mail: "",
    username: "",
  });
  const [time, setTime] = useState("threemonth");
  const counterRef = useRef(null);
  const [counterValue, setCounterValue] = useState("");
  const [openPay, setOpenPay] = useState(false);
  const [qrCode, setQrCode] = useState("");
  useEffect(() => {
    const options = {
      startVal: 0,
      endVal: parseInt(
        time === "threemonth"
          ? "2500000"
          : time === "oneyear"
          ? "4000000"
          : time === "common"
          ? "1500000"
          : "3500000"
      ),
      duration: 0.5,
      separator: ",",
    };
    const countUp = new CountUp(counterRef.current, options.endVal, options);
    countUp.start();
    setCounterValue(
      time === "threemonth"
        ? "2.500.000"
        : time === "oneyear"
        ? "4.000.000"
        : time === "common"
        ? "1.500.000"
        : "3.500.000"
    );
  }, [time]);
  useEffect(() => {
    setTime(typeModal);
  }, [openModal]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setDataInfor((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const getQrCode = async () => {
    if (!validator.isMobilePhone(dataInfor.phone, "vi-VN")) {
      alert("Số điện thoại không hợp lệ");
      return;
    }
    if (!dataInfor.name) {
      alert("Bạn phải nhập Họ và tên");
      return;
    }
    try {
      const response = await axios.get(
        `${apiGetQr}?amount=${
          time === "threemonth"
            ? "2500000"
            : time === "oneyear"
            ? "4000000"
            : time === "common"
            ? "1500000"
            : "3500000"
        }`
      );
      setOpenPay(true);
      setQrCode(response.data);
    } catch (error) {}
  };

  const base64Image = qrCode?.image;

  const submitGoogleSheet = async () => {
    if (!validator.isMobilePhone(dataInfor.phone, "vi-VN")) {
      alert("Số điện thoại không hợp lệ");
      return;
    }
    if (!dataInfor.name) {
      alert("Bạn phải nhập Họ và tên");
      return;
    }
    const data = {
      fullname: dataInfor.name,
      phone_number: dataInfor.phone,
      mail: dataInfor.mail,
      username: dataInfor.username,
      package:
        time === "common"
          ? "1"
          : time === "threemonth"
          ? "2"
          : time === "oneyear"
          ? "4"
          : "3",
      note: qrCode?.note,
    };
    await axios
      .post(postExtend, data)
      .then((response) => {
        toast.success("Yêu cầu đã được thực hiện");
        setOpenPay(false);
        setOpenModal(false);
        setOpenPaySuccess(true);
      })
      .catch((error) => {
        toast.error("Yêu cầu thực hiện không thành công");
      });
  };
  return (
    <>
      <Dialog
        className="dialog-thankyou"
        visible={openPaySuccess}
        style={{ width: "50vw" }}
        onHide={() => {
          if (!openPaySuccess) return;
          setOpenPaySuccess(false);
        }}
      >
        <div className="d-flex flex-column align-items-center gap-1 fs-6">
          <img className="img-thankyou" src={thankyou} alt="" />
          <p className="fz">
            Cảm ơn quý khách đã sử dụng dịch vụ của Chốt Sale!
          </p>
          <p className="fz">
            Chúng tôi rất trân trọng sự ủng hộ của quý khách.
          </p>
        </div>
      </Dialog>
      <Dialog
        visible={openModal}
        header=""
        size="lg"
        backdrop="static"
        onHide={() => setOpenModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        className="header-dialog"
      >
        <Dialog
          header="QUÉT MÃ QR THANH TOÁN"
          className="dialog-payment"
          visible={openPay}
          style={{ width: "60vw" }}
          onHide={() => {
            if (!openPay) return;
            setOpenPay(false);
          }}
        >
          <div className="payment-info-mobile d-flex w-100 gap-4">
            <img
              className="qr-mobile"
              style={{ width: "25%", height: "100%" }}
              src={`data:image/png;base64,${btoa(base64Image)}`}
              alt=""
            />
            <div
              style={{ width: "75%" }}
              className="info-mobile-payment d-flex flex-column gap-4"
            >
              <h4
                style={{ fontSize: "26px" }}
                className="title-qr-mobile font-bold "
              >
                THÔNG TIN THANH TOÁN
              </h4>

              <nav className="desc-qr-mobile d-flex flex-column gap-1">
                <p>
                  Số tài khoản: <strong>0121000768848</strong>
                </p>
                <p>
                  Ngân hàng: <strong>Vietcombank</strong>
                </p>
                <p>
                  Chủ tài khoản: <strong>NGUYEN THANH LONG</strong>
                </p>
                <p>
                  Gói mua:{" "}
                  <strong>
                    {time === "threemonth"
                      ? "1 năm"
                      : time === "oneyear"
                      ? "3 năm"
                      : time === "common"
                      ? "6 tháng"
                      : "2 năm"}{" "}
                  </strong>
                </p>

                <p>
                  Số tiền thanh toán:{" "}
                  <strong>
                    {time === "threemonth"
                      ? "2.500.000"
                      : time === "oneyear"
                      ? "4.000.000"
                      : time === "common"
                      ? "1.500.000"
                      : "3.500.000"}{" "}
                    VNĐ
                  </strong>
                </p>
                <p>
                  Nội dung chuyển khoản: <strong>{qrCode?.note}</strong>
                </p>
                <p>
                  Lưu ý: <strong>Nhập chính xác nội dung chuyển khoản</strong>
                </p>
              </nav>
              <nav>
                <p className="content-qr-mobile" style={{ lineHeight: "20px" }}>
                  Hình thức nhận: Nhận qua Mail đăng ký.
                  <br />
                  Trong trường hợp thông tin sai lệch chúng tôi sẽ liên hệ Quý
                  khách qua số điện thoại Quý khách đã đăng ký để xác nhận lại
                  thông tin.
                  <br />
                  XIN CHÂN THÀNH CẢM ƠN QUÝ KHÁCH ĐÃ SỬ DỤNG DỊCH VỤ CỦA CHỐT
                  NHANH.
                </p>
              </nav>
              <div className="d-flex btn-payment-mobile">
                <Button
                  style={{
                    width: "max-content",
                    borderRadius: "12px",
                    background:
                      "linear-gradient(45deg, rgb(61 87 207), rgb(255 49 0 / 48%)) text",
                    fontWeight: "700",
                    color: "transparent",
                    backgroundClip: "text",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                  onClick={submitGoogleSheet}
                >
                  Xác nhận thanh toán
                </Button>
              </div>
            </div>
          </div>
        </Dialog>

        <ToastContainer />
        <div
          style={{ padding: "1.5rem" }}
          className=" bg-light rounded d-flex postion-relative"
        >
          <img
            onClick={() => setOpenModal(false)}
            src={img2}
            style={{
              width: "30px",
              height: "30px",
              position: "absolute",
              top: "17px",
              right: "17px",
              cursor: "pointer",
            }}
          />
          <nav className=" fillInfo-mobile w-100 d-flex gap-2 h-100">
            <nav
              className="back-ground-mobile"
              style={{
                borderRadius: "12px",
                border: "1px solid",
                padding: "12px",
                background:
                  time === "threemonth"
                    ? "linear-gradient(to right, #1565c0, #1976d2)"
                    : time === "oneyear"
                    ? "linear-gradient(to right, #d32f2f, #ff6f61"
                    : time === "common"
                    ? "linear-gradient(to right, #42a5f5, #90caf9)"
                    : "linear-gradient(to right, #009688, #4db6ac)",
              }}
            >
              <nav
                className="background-mobile  position-relative  d-flex gap-2 flex-column  "
                style={{
                  width: "max-content",
                }}
              >
                <nav
                  style={{
                    height: "50px",
                    // background: "linear-gradient(45deg, #173be3, #1a99bf7a)",
                    width: "100%",
                    borderRadius: "12px",
                  }}
                ></nav>
                <nav
                  className="d-flex flex-column justify-content-center align-items-center"
                  style={{ marginTop: "25px", color: "#fff" }}
                >
                  <h4>
                    {time === "threemonth"
                      ? "GÓI 1 NĂM"
                      : time === "oneyear"
                      ? "GÓI 3 NĂM"
                      : time === "common"
                      ? "GÓI 6 THÁNG"
                      : "GÓI 2 NĂM"}
                  </h4>
                  <h4
                    style={{
                      fontSize: "30px",
                      color: "#fff",
                      fontWeight: "700",
                    }}
                  >
                    {/* <span ref={counterRef}>
      {counterRef.current
        ? ""
        : time === "threemonth"
        ? "1.000.000"
        : time === "oneyear"
        ? "2.000.000"
        : "1.500.000"}
    </span>{" "} */}
                    <span ref={counterRef}>{counterValue}</span>
                    VND
                  </h4>
                </nav>
                <nav className="w-100 d-flex justify-content-center">
                  <hr style={{ width: "60%" }} />
                </nav>
                <nav className="content-mobile d-flex flex-column gap-2">
                  <nav
                    style={{ color: "#fff" }}
                    className="d-flex gap-2 align-items-center"
                  >
                    <TiTick />
                    <p> Thời hạn sử dụng :</p>
                    <p style={{ color: "#fff", fontWeight: "800" }}>
                      {time === "threemonth"
                        ? "1 năm"
                        : time === "oneyear"
                        ? "3 năm"
                        : time === "common"
                        ? "6 tháng"
                        : "2 năm"}
                    </p>
                  </nav>
                  <nav
                    style={{ color: "#fff" }}
                    className="d-flex gap-2 align-items-center"
                  >
                    <TiTick />
                    <p>Sử dụng trên mọi thiết bị</p>
                  </nav>
                  <nav
                    style={{ color: "#fff" }}
                    className="d-flex gap-2 align-items-center"
                  >
                    <TiTick />
                    <p>Đầy đủ các tính năng</p>
                  </nav>
                  <nav
                    style={{ color: "#fff" }}
                    className="d-flex gap-2 align-items-center"
                  >
                    <TiTick />
                    <p>Cập nhật miễn phí</p>
                  </nav>
                  <nav
                    style={{ color: "#fff" }}
                    className="d-flex gap-2 align-items-center"
                  >
                    <TiTick />
                    <p>Cài đặt miễn phí qua Zalo</p>
                  </nav>
                  {time === "common" ? (
                    <nav
                      style={{ color: "#fff" }}
                      className="d-flex gap-2 align-items-center"
                    >
                      <TiTick />
                      <p>Tài liệu, Video hướng dẫn chi tiết sử dụng</p>
                    </nav>
                  ) : (
                    <nav
                      style={{ color: "#fff" }}
                      className="d-flex gap-2 align-items-center"
                    >
                      <TiTick />
                      <p>
                        Tài liệu, Video hướng dẫn chi tiết sử dụng
                        <br /> & nhóm Support riêng
                      </p>
                    </nav>
                  )}
                </nav>
                <img
                  src={img1}
                  style={{
                    position: "absolute",
                    left: "50%",
                    top: "50px",
                    transform: "translate(-50%, -50%)",
                    width: "50px",
                  }}
                />
              </nav>
            </nav>
            <nav
              className="register-mobile d-flex flex-column  gap-2  align-items-center"
              style={{ width: "500px" }}
            >
              <h4 className="text-center">Đăng ký mua phần mềm Chốt Sale</h4>
              <nav className="d-flex h-100 flex-column gap-2 justify-content-between">
                <div style={{ position: "relative" }}>
                  <InputText
                    style={{ width: "100%" }}
                    value={dataInfor.name}
                    name="name"
                    onChange={handleChange}
                    placeholder="Nhập tên của bạn"
                  />
                  <span
                    style={{
                      color: "red",
                      position: "absolute",
                      top: "50%",
                      right: "10px",
                      transform: "translateY(-50%)",
                    }}
                  >
                    *
                  </span>
                </div>
                <div style={{ position: "relative" }}>
                  <InputText
                    style={{ width: "100%" }}
                    value={dataInfor.phone}
                    name="phone"
                    onChange={handleChange}
                    placeholder="Nhập số điện thoại của bạn"
                  />
                  <span
                    style={{
                      color: "red",
                      position: "absolute",
                      top: "50%",
                      right: "10px",
                      transform: "translateY(-50%)",
                    }}
                  >
                    *
                  </span>
                </div>
                <InputText
                  value={dataInfor.mail}
                  name="mail"
                  onChange={handleChange}
                  placeholder="Mail đăng ký"
                />
                <InputText
                  value={dataInfor.username}
                  name="username"
                  onChange={handleChange}
                  placeholder="Tên đăng nhập hiện tại (nếu có)"
                />
                <div className="flex align-items-center">
                  <RadioButton
                    inputId="ingredient1"
                    name="pizza"
                    value="common"
                    onChange={(e) => setTime(e.value)}
                    checked={time === "common"}
                  />
                  <label htmlFor="ingredient1" className="ml-2">
                    Gói 6 tháng
                  </label>
                </div>{" "}
                <div className="flex align-items-center">
                  <RadioButton
                    inputId="ingredient1"
                    name="pizza"
                    value="threemonth"
                    onChange={(e) => setTime(e.value)}
                    checked={time === "threemonth"}
                  />
                  <label htmlFor="ingredient1" className="ml-2">
                    Gói 1 năm
                  </label>
                </div>{" "}
                <div className="flex align-items-center">
                  <RadioButton
                    inputId="ingredient1"
                    name="pizza"
                    value="sixmonth"
                    onChange={(e) => setTime(e.value)}
                    checked={time === "sixmonth"}
                  />
                  <label htmlFor="ingredient1" className="ml-2">
                    Gói 2 năm
                  </label>
                </div>
                <div className="flex align-items-center">
                  <RadioButton
                    inputId="ingredient1"
                    name="pizza"
                    value="oneyear"
                    onChange={(e) => setTime(e.value)}
                    checked={time === "oneyear"}
                  />
                  <label htmlFor="ingredient1" className="ml-2">
                    Gói 3 năm
                  </label>
                </div>
                <nav className="d-flex w-100 justify-content-center">
                  <Button
                    style={{
                      width: "max-content",
                      borderRadius: "12px",
                      background:
                        "linear-gradient(45deg, rgb(61 87 207), rgb(255 49 0 / 48%)) text",
                      fontWeight: "700",
                      color: "transparent",
                      backgroundClip: "text",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                    }}
                    onClick={getQrCode}
                  >
                    Mua phần mềm
                  </Button>
                </nav>
                <p>Xin vui lòng nhập đúng số điện thoại đang sử dụng</p>
              </nav>
            </nav>
          </nav>
        </div>
      </Dialog>
    </>
  );
}

export default ModalFromRedue;
