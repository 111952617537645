import React, { useEffect, useState, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";
import { API_URL, API_ROUTES_NTD } from "../../api";
import axios from "axios";
import { useHeaders } from "../../const/headers";
import { RiExpandDiagonal2Line } from "react-icons/ri";
export const ModalScanPage = ({
  props,
  dataFb,
  pageModal,
  setPageModal,
  setRefresh,
  refresh,
  toast,
  setHiddenPage,
  setHiddenUser,
  setHiddenGroup,
}) => {
  const startScanPage = API_URL + API_ROUTES_NTD.TOOlBOX_PAGE_SCAN;
  const headers = useHeaders();
  const [keyword, setKeyword] = useState("");
  const [quantity, setQuantity] = useState("");
  const [selecAccount, setSelectAccount] = useState(null);
  const countries = dataFb?.filter((item) => item.checkpoint === false);
  const [showDoneToast, setShowDoneToast] = useState(false);
  const selecAccountTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <img
            alt={option.name}
            src={option.avatar}
            className="mr-2  rounded-circle"
            style={{ width: "20px" }}
          />
          <div>{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <img
          alt={option.name}
          src={option.avatar}
          className="mr-2  rounded-circle"
          style={{ width: "20px" }}
        />
        <div>{option.name}</div>
      </div>
    );
  };
  const scanToolUser = async () => {
    if (selecAccount?.id === undefined || !keyword || !quantity) {
      alert("Vui lòng nhập đầy đủ thông tin");
      return;
    }
    const body = {
      id_account: selecAccount?.id,
      keyword: keyword,
      quantity: quantity,
    };
    try {
      const res = await axios.post(startScanPage, body, headers);
      setPageModal(false);
      toast.success("Đang quét", {
        onClose: () => setShowDoneToast(true),
      });
      setHiddenUser(false);
      setHiddenPage(true);
      setHiddenGroup(false);
      setQuantity("");
      setKeyword("");
      setSelectAccount(null);
    } catch (err) {}
  };
  useEffect(() => {
    if (showDoneToast) {
      toast.success("Đã quét xong");
      setShowDoneToast(false);
    }
  }, [showDoneToast]);
  return (
    <Dialog
      header="Thêm thông tin"
      visible={pageModal}
      style={{ position: "relative", width: "50vw" }}
      onHide={() => setPageModal(false)}
      className="overFlowJoinGr"
      footer={
        <div className="d-flex justify-content-end gap-2 mt-2">
          <Button className="button-blue radius" onClick={scanToolUser}>
            Bắt đầu quét
          </Button>
          <Button
            onClick={() => setPageModal(false)}
            className="button-red radius"
          >
            Thoát không lưu
          </Button>
          <RiExpandDiagonal2Line
            size={20}
            style={{
              position: "absolute",
              right: "0",
              bottom: "0",
            }}
          />
        </div>
      }
    >
      <div className="d-flex flex-column h-100" style={{ overflow: "auto" }}>
        <div className="p-inputgroup flex-1 mb-2">
          <span className="p-inputgroup-addon " style={{ width: "9rem" }}>
            Tài khoản
          </span>
          <Dropdown
            value={selecAccount}
            onChange={(e) => setSelectAccount(e.value)}
            options={countries}
            optionLabel="name"
            placeholder="Danh sách tài khoản"
            filter
            valueTemplate={selecAccountTemplate}
            itemTemplate={countryOptionTemplate}
            className="w-full md:w-14rem pl-2"
          />
        </div>
        <div>
          <div className="p-inputgroup flex-1 mb-2">
            <span className="p-inputgroup-addon " style={{ width: "9rem" }}>
              Từ khoá
            </span>
            <InputText
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              placeholder="Nhập từ khoá ( Chỉ nhập 1 từ khóa tìm kiếm ví dụ: Thời trang )"
            />
          </div>
          <div className="p-inputgroup flex-1 mb-2">
            <span className="p-inputgroup-addon" style={{ width: "9rem" }}>
              Số lượng
            </span>
            <InputNumber
              value={quantity}
              placeholder="Số lượng UID cần quét"
              onValueChange={(e) => setQuantity(e.value)}
              useGrouping={false}
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
};
