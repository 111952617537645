import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { API_URL, API_ROUTES_NTD } from "../../../api";
import axios from "axios";
import { useHeaders } from "../../../const/headers";
import {
  toggleSelectAll,
  headerCheckbox,
  bodyCheckbox,
} from "../../../const/checkboxPrimeReactjs";
import { filterLatestUpdates } from "../../../const/filter";
import { statusLineCheck } from "../../../const/updateTitle";
import { Paginator } from "primereact/paginator";
import { getAxiosResults } from "../../../const/getAxios";
import { onRowClick } from "../../../const/checkboxPrimeReactjs";
import { rowClassName } from "../../../const/checkboxPrimeReactjs";
import { sortDataByDate } from "../../../const/sortData";
import { Dropdown } from "primereact/dropdown";
import { getAxiosResultsCategory } from "../../../const/getAxios";
export const ResultClient = ({
  toast,
  setOpenDetails,
  setItemResults,
  setListResults,
  listResults,
  setWsResults,
  wsResults,
  setTotalPage,
  totalPage,
  setGetTotal,
  getTotal,
  setRows,
  rows,
  renderKey,
  allCateClient,
}) => {
  const headers = useHeaders();
  const deleteResultClient = API_URL + API_ROUTES_NTD.DELETE_RESULTS_CLIENT;
  const allResults = API_URL + API_ROUTES_NTD.GET_ALL_CLIENT;
  const getDataAllFr = () => {};
  const [first, setFirst] = useState(0);
  const rowsPerPageOptions = [100, 300, 500, 1000, 2000];
  const [seclecCate, setSelecCate] = useState(null);

  const onPageChange = (event) => {
    setTotalPage(event.page);
    setFirst(event.first);
    setRows(event.rows);
  };
  useEffect(() => {
    getAxiosResultsCategory(
      allResults,
      setWsResults,
      setGetTotal,
      rows,
      totalPage + 1,
      seclecCate?.id || "null"
    );
  }, [totalPage, rows, renderKey, seclecCate]);
  const deleteResult = async (rowData) => {
    const body = { id_results: [rowData?.id] };
    try {
      await axios.post(deleteResultClient, body, headers);
      getAxiosResultsCategory(
        allResults,
        setWsResults,
        setGetTotal,
        rows,
        totalPage + 1,
        seclecCate?.id || "null"
      );
      toast.success("Xoá thành công");
    } catch (err) {}
  };
  const filteredData = filterLatestUpdates(wsResults || []);

  const optionResults = (rowData) => {
    return (
      <>
        <Button
          className=" button-blue radius"
          onClick={() => {
            setOpenDetails(true);
            setItemResults(rowData);
          }}
          style={{
            padding: "6px 8px",
            marginRight: "0.5rem",
            fontSize: "12px",
          }}
        >
          Chi tiết
        </Button>
        <Button
          className=" button-red radius"
          onClick={() => deleteResult(rowData)}
          severity="danger"
          style={{ padding: "6px 8px", fontSize: "12px" }}
        >
          Xóa
        </Button>
      </>
    );
  };
  const uidPage = (rowData) => {
    return (
      <>
        <p>{rowData?.UID_friend}</p>
      </>
    );
  };
  const namePage = (rowData) => {
    return (
      <>
        <p>{rowData?.name_friend}</p>
      </>
    );
  };
  const date = (rowData) => {
    return (
      <>
        <p>{`${rowData?.created_at?.split("T")[1]?.split(".")[0]} - ${
          rowData?.created_at?.split("T")[0]
        }`}</p>
      </>
    );
  };
  const indexTemplate = (rowData, rowIndex) => {
    return <>{totalPage * rows + rowIndex.rowIndex + 1}</>;
  };
  const uidACc = (rowData) => {
    return (
      <>
        <p>{rowData?.account.UID}</p>
      </>
    );
  };
  const nameAcc = (rowData) => {
    return (
      <>
        <p>{rowData?.account.name}</p>
      </>
    );
  };

  return (
    <div className="d-flex flex-column h-100 w-100">
      <Dropdown
        value={seclecCate}
        onChange={(e) => setSelecCate(e.value)}
        options={[...allCateClient, { name: "Tất cả", id: null }]}
        optionLabel="name"
        placeholder="Chọn kịch bản"
        className="w-full md:w-14rem"
      />{" "}
      <div
        className="d-flex gap-2 flex-column h-100"
        style={{ border: "1px solid", overflow: "auto" }}
      >
        <DataTable
          size="small"
          value={sortDataByDate(filteredData)}
          className="small-font-table dataTableWithFixedHeaderMkt dataTableWithHoverEffect datatableoverflow"
          tableStyle={{ width: "100%", height: "86%" }}
          rowClassName={(rowData) => rowClassName(rowData, listResults)}
          onRowClick={(e) => onRowClick(e.data, setListResults, listResults)}
        >
          <Column
            header={headerCheckbox(
              listResults,
              filteredData,
              toggleSelectAll,
              setListResults
            )}
            body={(rowData) =>
              bodyCheckbox(rowData, listResults, setListResults, getDataAllFr)
            }
          />
          <Column body={indexTemplate} field="UID" header="STT"></Column>
          <Column
            field="created_at"
            body={(rowData) => date(rowData)}
            header="Thời gian"
            sortable
          ></Column>
          <Column
            body={(rowData) => uidACc(rowData)}
            header="UID"
            sortable
          ></Column>
          <Column
            field="name"
            body={(rowData) => nameAcc(rowData)}
            header="Tên tài khoản"
            sortable
          ></Column>
          <Column
            body={(rowData) => uidPage(rowData)}
            header="UID khách"
            sortable
          ></Column>
          <Column
            field="name"
            body={(rowData) => namePage(rowData)}
            header="Tên khách hàng"
            sortable
          ></Column>
          <Column
            field="status_like"
            body={(rowData) => statusLineCheck(rowData, "status_like")}
            header="Like"
            sortable
          ></Column>
          <Column
            field="status_comment"
            body={(rowData) => statusLineCheck(rowData, "status_comment")}
            header="Bình luận"
            sortable
          ></Column>
          <Column
            body={(rowData) => statusLineCheck(rowData, "status_message")}
            field="status_message"
            header="Nhắn tin"
            sortable
          ></Column>
          <Column
            field=""
            body={(rowData) => optionResults(rowData)}
            header="Tuỳ chọn"
          ></Column>
        </DataTable>
        <Paginator
          className="pagination-markerting"
          first={first}
          rows={rows}
          totalRecords={getTotal?.count}
          onPageChange={onPageChange}
          rowsPerPageOptions={rowsPerPageOptions}
        />
      </div>
    </div>
  );
};
