import React, { useEffect, useRef } from "react";
import Navigate from "../container/Navigate";
import { useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_URL, API_ROUTES, API_ROUTES_NTD, WS_URL } from "../../api";
import { Footer } from "./Footer";
import { Button } from "primereact/button";
import { useHeaders } from "../../const/headers";
import { updateTitle } from "../../const/updateTitle";
import { useAuth } from "../../AuthContext";
import { ResultMarketing } from "../container/marketing/ResultMarketing";
import { ListAccMarketing } from "../container/marketing/ListAccMarketing";
import { ScriptMarketing } from "../container/marketing/ScriptMarketing";
import { ListCateMkt } from "../container/marketing/ListCateMkt";
import { getAxios } from "../../const/getAxios";
import { locale } from "primereact/api";
import { ModalDetailRs } from "../container/marketing/ModalDetailRs";
import { getAxiosResults } from "../../const/getAxios";
import { Sidebar } from "primereact/sidebar";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { ManagerCateMarketing } from "../container/marketing/ManagerCateMarketing";
import { useUser } from "../../contexts/UserContext";
export const MarketingFeils = () => {
  locale("vi");
  const headers = useHeaders();
  updateTitle("Chốt Sale - Quản Lý Khách Hàng");
  const { userInfo } = useUser();
  const socketRef = useRef(null);
  const access = localStorage.getItem("access");
  const getDataFb = API_URL + API_ROUTES.GET_ACC_FB;
  const getAllCateMkt = API_URL + API_ROUTES_NTD.GET_ALL_CATEGORY_MARKETING;
  const addCateInMkt = API_URL + API_ROUTES_NTD.ADD_CATEGORY_IN_ACCOUNT;
  const startMarketing = API_URL + API_ROUTES_NTD.START_CATEGORY_ACCOUNT_MKT;
  const stopMarketing = API_URL + API_ROUTES_NTD.STOP_CATEGORY_ACCOUNT_MKT;
  const deleteResultMkt = API_URL + API_ROUTES_NTD.DELETE_RESULTS_MARKETING;
  const allResults = API_URL + API_ROUTES_NTD.GET_ALL_RESULTS;
  const { setUserLoggedIn } = useAuth();
  const [modalScript, setModalScript] = useState(false);
  const [dataFb, setDataFb] = useState();
  const [refresh, setRefresh] = useState(true);
  const [statusScan, setStatusScan] = useState(false);
  const [allCate, setAllCate] = useState([]);
  const [listAccMkt, setListAccMkt] = useState([]);
  const [listMktCate, setListMktCate] = useState([]);
  const [wsResults, setWsResults] = useState([]);
  const [openDetails, setOpenDetails] = useState(false);
  const [itemResults, setItemResults] = useState([]);
  const [listResults, setListResults] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [getTotal, setGetTotal] = useState([]);
  const [rows, setRows] = useState(100);
  const isInitialRender = useRef(true);
  const [renderKey, setRenderKey] = useState(Date.now());
  const [openSidebar, setOpenSidebar] = useState(false);
  const [openManager, setOpenManager] = useState(false);
  const [itemCate, setItemCate] = useState([]);

  const deleteResults = async () => {
    const body = { id_results: listResults?.map((item) => item?.id) };
    try {
      const res = await axios.post(deleteResultMkt, body, headers);
      getAxiosResults(
        allResults,
        setWsResults,
        setGetTotal,
        rows,
        totalPage + 1
      );
      toast.success("Xoá thành công");
      setListResults([]);
    } catch (err) {}
  };
  useEffect(() => {
    axios
      .get(getDataFb, headers)
      .then(function (response) {
        setDataFb(response.data);
      })
      .catch(function (error) {
        if (error?.response?.status === 401) {
          setUserLoggedIn(false);
        }
      });
  }, [refresh]);
  useEffect(() => {
    getAxios(getAllCateMkt, setAllCate);
  }, [refresh]);
  const addCateInAcc = async () => {
    if (listMktCate?.length === 1 && listAccMkt?.length > 0) {
      const body = {
        id_category: listMktCate[0].id,
        id_accounts: listAccMkt?.map((item) => item.id),
      };
      try {
        const res = await axios.post(addCateInMkt, body, headers);
        toast.success("Thêm kịch bản thành công");
        setListMktCate([]);
        setListAccMkt([]);
        setRefresh(!refresh);
      } catch (err) {}
    }
  };
  useEffect(() => {
    addCateInAcc();
  }, [listMktCate]);
  const dataFbWithCheckpoint = dataFb?.filter(
    (item) => item.checkpoint === false
  );
  const checkCategoryExists = (id_categories, dataFbWithCheckpoint) => {
    return id_categories.some((id_category) => {
      return dataFbWithCheckpoint.some((data) => {
        const exists = data.category_marketing.some(
          (category) => category.id === id_category
        );
        return exists; // Trả về true ngay lập tức nếu tìm thấy
      });
    });
  };
  const startMarketingCate = async (type) => {
    const categoryExists = checkCategoryExists(
      listMktCate?.map((item) => item.id),
      dataFbWithCheckpoint
    );
    if (listMktCate?.length === 0) {
      alert("Chọn ít nhất 1 kịch bản để chạy ");
    } else if (!categoryExists) {
      toast.error(
        "Không có tài khoản nào được gán cho kịch bản. Vui lòng gán kịch bản cho tài khoản để chạy"
      );
    } else {
      const body = {
        id_categories: listMktCate?.map((item) => item.id),
        type: type,
      };
      try {
        const res = await axios.post(startMarketing, body, headers);
        toast.success("Chạy chiến dịch thành công");
        setRefresh(!refresh);
      } catch (err) {}
    }
  };
  const stopMarketingCate = async () => {
    if (listMktCate?.length === 0) {
      alert("Chọn ít nhất 1 kịch bản để dừng ");
    } else {
      const body = {
        id_categories: listMktCate?.map((item) => item.id),
      };
      try {
        const res = await axios.post(stopMarketing, body, headers);
        setRefresh(!refresh);
        toast.success("Dừng chiến dịch thành công");
      } catch (err) {
        toast.error("Dừng chiến dịch thất bại");
      }
    }
  };
  useEffect(() => {
    const client = new W3CWebSocket(
      `wss://${WS_URL}/ws/marketing/${userInfo?.id}/?token=${access}`
    );
    socketRef.current = client;
    client.onerror = function () {
      // console.error("Connection Error");
    };
    client.onopen = function () {};
    client.onmessage = function (message) {
      const parsedData = JSON.parse(message.data);
      if (parsedData.category !== undefined) {
        const newCategory = parsedData.category;
        setAllCate((prevCate) => {
          const index = prevCate.findIndex((cat) => cat.id === newCategory.id);
          if (index !== -1) {
            return prevCate.map((cat) =>
              cat.id === newCategory.id ? newCategory : cat
            );
          } else {
            return [...prevCate, newCategory];
          }
        });
      }
      if (parsedData.result !== undefined) {
        setRenderKey(Date.now());
      }
    };
    return () => {
      if (client) {
        client.close();
      }
    };
  }, []);
  const widthNavi = document.getElementById("width-navigate")?.offsetWidth;
  return (
    <div className="d-flex flex-column " style={{ height: "100vh" }}>
      <div className="notifica-container">
        <ToastContainer />
        <div className="navigate-container" id="width-navigate">
          <Navigate />
        </div>
        <div
          className="d-flex h-100 "
          style={{ width: `calc(100vw - ${widthNavi}px)` }}
        >
          <div
            className="overflow-auto h-100 d-flex flex-column"
            style={{
              width: "100%",
              borderRight: "0.5px solid",
              borderLeft: "0.5px solid",
            }}
          >
            <div
              className="d-flex gap-2 w-100 justify-content-end pt-2 pl-2 pr-2"
              id="lisstSeclect"
            >
              <Button
                style={{
                  width: "200px",
                }}
                onClick={() => setModalScript(true)}
                className="d-flex justify-content-center button-blue"
              >
                Thêm kịch bản
              </Button>
              <Button
                onClick={() => startMarketingCate("new")}
                style={{
                  width: "200px",
                }}
                disabled={statusScan}
                className="d-flex justify-content-center button-blue"
              >
                Chạy
              </Button>
              <Button
                onClick={() => startMarketingCate("")}
                style={{
                  width: "200px",
                }}
                disabled={statusScan}
                className="d-flex justify-content-center button-blue"
              >
                Chạy Tiếp
              </Button>
              <Button
                onClick={() => setOpenSidebar(true)}
                style={{
                  width: "200px",
                }}
                disabled={statusScan}
                className="d-flex justify-content-center button-blue"
              >
                Kết quả
              </Button>
              <Button
                severity="danger"
                style={{ width: "200px" }}
                className="d-flex justify-content-center button-red"
                onClick={stopMarketingCate}
              >
                Dừng
              </Button>
            </div>
            <div
              className="d-flex flex-column  gap-2 h-100 m-2"
              style={{ overflow: "hidden" }}
            >
              <div className="d-flex w gap-2" style={{ height: "100%" }}>
                {/* <ListAccMarketing
                  refresh={refresh}
                  setRefresh={setRefresh}
                  dataFb={dataFb}
                  listAccMkt={listAccMkt}
                  setListAccMkt={setListAccMkt}
                  allCate={allCate}
                  toast={toast}
                /> */}
                <ListCateMkt
                  itemCate={itemCate}
                  setItemCate={setItemCate}
                  openManager={openManager}
                  setOpenManager={setOpenManager}
                  toast={toast}
                  dataFb={dataFb}
                  listMktCate={listMktCate}
                  setListMktCate={setListMktCate}
                  allCate={allCate}
                  refresh={refresh}
                  setRefresh={setRefresh}
                />
              </div>

              <Sidebar
                visible={openSidebar}
                onHide={() => setOpenSidebar(null)}
                position="bottom"
                className="sidebar-market"
              >
                <div className="d-flex flex-column" style={{ height: "100%" }}>
                  {" "}
                  <Button
                    severity="danger"
                    style={{
                      width: "200px",
                      display: listResults.length === 0 ? "none" : "block",
                    }}
                    className="justify-content-center button-red"
                    onClick={deleteResults}
                  >
                    Xoá kết quả
                  </Button>
                  <div className="d-flex" style={{ height: "100%" }}>
                    <ResultMarketing
                      renderKey={renderKey}
                      isInitialRender={isInitialRender}
                      rows={rows}
                      setRows={setRows}
                      toast={toast}
                      openDetails={openDetails}
                      setOpenDetails={setOpenDetails}
                      itemResults={itemResults}
                      setItemResults={setItemResults}
                      allCate={allCate}
                      setAllCate={setAllCate}
                      wsResults={wsResults}
                      setWsResults={setWsResults}
                      listResults={listResults}
                      setListResults={setListResults}
                      totalPage={totalPage}
                      setTotalPage={setTotalPage}
                      getTotal={getTotal}
                      setGetTotal={setGetTotal}
                    />
                  </div>
                </div>
              </Sidebar>
            </div>
          </div>
        </div>
      </div>
      <ScriptMarketing
        modalScript={modalScript}
        refresh={refresh}
        setRefresh={setRefresh}
        setModalScript={setModalScript}
      />
      <ModalDetailRs
        openDetails={openDetails}
        setOpenDetails={setOpenDetails}
        itemResults={itemResults}
        setItemResults={setItemResults}
      />
      <ManagerCateMarketing
        itemCate={itemCate}
        dataFb={dataFb}
        openManager={openManager}
        setOpenManager={setOpenManager}
        allCate={allCate}
        refresh={refresh}
        setRefresh={setRefresh}
        toast={toast}
      />
      <Footer />
    </div>
  );
};
