import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { API_ROUTES, API_URL } from "../../../api";
import { InputText } from "primereact/inputtext";
import { IoIosLink } from "react-icons/io";
import { MdContentCopy } from "react-icons/md";
import { useHeaders } from "../../../const/headers";
export const ManageResource = ({
  setModalCreate,
  modalCreate,
  dataRes,
  setRf,
  rf,
}) => {
  const headers = useHeaders();
  const createUpdate = API_URL + API_ROUTES.CREATE_EDIT_RESOURCE;
  const [content, setContent] = useState(dataRes.content || "");
  const [link, setLink] = useState(dataRes.link || "");
  const createResouce = async () => {
    const data = {
      id: dataRes.id === undefined ? null : dataRes.id,
      content: content,
      link: link,
    };
    try {
      const res = await axios.post(createUpdate, data, headers);
      setRf(!rf);
      setLink("");
      setContent("");
      setModalCreate(false);
    } catch (err) {
    }
  };
  useEffect(() => {
    setContent(dataRes.content || "");
    setLink(dataRes.link || "");
  }, [dataRes]);
  const onChangeContent = (e) => {
    setContent(e.target.value);
  };
  const onChangeLink = (e) => {
    setLink(e.target.value);
  };
  return (
    <div className="card flex justify-content-center">
      <Dialog
        header="Quản lý"
        visible={modalCreate}
        onHide={() => setModalCreate(false)}
        style={{ width: "50vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        <div className="card flex flex-column  gap-3" style={{ border: "0" }}>
          <div className="p-inputgroup flex-1">
            <span className="p-inputgroup-addon">
              <MdContentCopy />
            </span>
            <InputText
              onChange={(e) => onChangeContent(e)}
              placeholder="Nội dung"
              value={content}
            />
          </div>
          <div className="p-inputgroup flex-1">
            <span className="p-inputgroup-addon">
              <IoIosLink />
            </span>
            <InputText
              onChange={(e) => onChangeLink(e)}
              value={link}
              placeholder="Link"
            />
          </div>
        </div>
        <div className="d-flex justify-content-end gap-2 mt-2">
          <Button
            className=" button-blue radius"
            label="Sửa"
            onClick={() => createResouce()}
          />
          <Button
            className=" button-red radius"
            label="Hủy"
            onClick={() => setModalCreate(false)}
            severity="danger"
          />
        </div>
      </Dialog>
    </div>
  );
};
