import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useAuth } from "../../../AuthContext";
import { useUser } from "../../../contexts/UserContext";
import { API_URL } from "../../../api";
export default function FooterDemo() {
  const { alerts, visibleTb, setVisibleTb } = useUser();
  const { userLoggedIn, setUserLoggedInState } = useAuth();
  useEffect(() => {
    const hasVisited = localStorage.getItem("Thongbao");
    if (!hasVisited && userLoggedIn === true && alerts?.active === true) {
      setVisibleTb(true);
      localStorage.setItem("Thongbao", true);
    }
  }, [userLoggedIn, alerts?.active]);

  return (
    <div
      style={{ border: "0" }}
      className="card b-0 flex justify-content-center"
    >
      <Dialog
        header=""
        visible={visibleTb}
        style={{ width: "50vw" }}
        onHide={() => setVisibleTb(false)}
      >
        <div className="d-flex flex-column gap-2 w-100">
          <div
            className="d-flex align-items-center gap-2"
            style={{ width: "100%" }}
          >
            <img
              style={{
                width: "30%",
                display: alerts?.image === "" ? "none" : "block",
              }}
              src={`${API_URL}/${alerts?.image}`}
            />
            <p
              style={{ width: "70%", wordWrap: "break-word" }}
              className="m-0"
              dangerouslySetInnerHTML={{ __html: alerts?.content }}
            />
          </div>
          <div
            className="d-flex justify-content-end mt-2"
            style={{ width: "100%" }}
          >
            <Button
              className="button-blue radius"
              label="Xem ngay"
              onClick={() => {
                window?.open(alerts?.link, "_blank");
              }}
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
}
