import React, { useState, useRef, useEffect } from "react";
import { PanelMenu } from "primereact/panelmenu";
import { Badge } from "primereact/badge";
import { MdOutlineComment } from "react-icons/md";
import ModalDetailsComments from "./ModalDetailsComments";
import { FaBell } from "react-icons/fa";
import { FaBellSlash } from "react-icons/fa6";
import { API_URL, API_ROUTES_NTD, API_ROUTES, WS_URL } from "../../../api";
import { useHeaders } from "../../../const/headers";
import axios from "axios";
import { useUser } from "../../../contexts/UserContext";
import { useStatus } from "../../../contexts/StatusContext";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import bellSound from "../../audio/cnaudio.wav";
export default function CommentsInMess({
  getPageAtib,
  allComments,
  setAllComments,
  listAccout,
  setRfCmt,
  rfCmt,
  toast,
  t,
}) {
  const { language } = useStatus();
  const getInfor = API_URL + API_ROUTES.GET_INFO_UERS;
  const showCoutCmt = API_URL + API_ROUTES_NTD.SHOW_SET_COUNT;
  const [visible, setVisible] = useState(false);
  const [onBell, setOnBell] = useState(true);
  const headers = useHeaders();
  const socketRef = useRef(null);
  const access = localStorage.getItem("access");
  const [results, setResults] = useState([]);
  const [userInfo, setUserInfo] = useState([]);
  // const getShowCout = async () => {
  //   try {
  //     const res = await axios.get(showCoutCmt, headers);
  //   } catch (err) {}
  // };

  const fetchDataIf = async () => {
    try {
      const response = await axios.get(getInfor, headers);
      setUserInfo(response.data);
    } catch (err) {}
  };
  useEffect(() => {
    fetchDataIf();
  }, []);
  useEffect(() => {
    if (userInfo?.id) {
      const client = new W3CWebSocket(
        `wss://${WS_URL}/ws/comment/${userInfo?.id}/?token=${access}`
      );
      socketRef.current = client;
      client.onerror = function () {};
      client.onopen = function () {};
      client.onmessage = function (message) {
        const parsedData = JSON.parse(message.data);
        if (parsedData.result) {
          setResults(parsedData.result);
          // setMessages((prevDetails) => [
          //   ...prevDetails,
          //   parsedData.message_details,
          // ]);
        }
        if (parsedData?.accounts) {
          // setPageConnet(parsedData?.accounts);
        }
        if (parsedData.message) {
          // setAllPageMess(parsedData.message);
        }
      };
      return () => {
        if (client) {
          client.close();
        }
      };
    }
  }, [userInfo]);
  const apiActive = API_URL + API_ROUTES_NTD.ACTIVE_COMMENTS_IN_MESS;
  const itemRenderer = (item, options) => (
    <a
      // onClick={getShowCout}
      className="flex align-items-center px-3 py-2 cursor-pointer"
    >
      <MdOutlineComment />
      <span
        onClick={() => setVisible(true)}
        className={`mx-2 ${item?.items && "font-semibold"}`}
      >
        {item?.label}
      </span>
      <div
        style={{ position: "relative", display: "inline-block" }}
        className="mx-2"
      >
        {onBell ? (
          <FaBell
            onClick={() => {
              setOnBell(!onBell);
            }}
          />
        ) : (
          <FaBellSlash
            onClick={() => {
              setOnBell(!onBell);
            }}
          />
        )}

        {item.badge && (
          <Badge
            value={item.badge}
            style={{
              position: "absolute",
              top: "-3px",
              right: "0",
              transform: "translate(50%, -50%)",
              backgroundColor: "#ff0000",
              color: "#ffffff",
              borderRadius: "50%",
            }}
          />
        )}
      </div>
    </a>
  );
  const items = [
    {
      label: language === "vi" ? "Bình Luận" : "Comment",
      icon: MdOutlineComment,
      badge:
        results?.new_comment_count === 0 || !results.new_comment_count
          ? ""
          : results?.new_comment_count < 9
          ? results?.new_comment_count
          : `${9}+`,
      template: itemRenderer,
    },
  ];
  useEffect(() => {
    if (onBell) {
      const hasNewMsg = results?.new_comment === true;
      if (hasNewMsg) {
        const audio = new Audio(bellSound);
        audio
          .play()
          .then(() => {})
          .catch((error) => {});
      }
    }
  }, [results, onBell]);

  return (
    <div className=" flex justify-content-center">
      <PanelMenu model={items} className="" />
      <ModalDetailsComments
        t={t}
        toast={toast}
        setRfCmt={setRfCmt}
        rfCmt={rfCmt}
        allComments={allComments}
        setAllComments={setAllComments}
        visible={visible}
        setVisible={setVisible}
        getPageAtib={getPageAtib}
        listAccout={listAccout}
      />
    </div>
  );
}
