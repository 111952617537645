import { Container, Row, Modal, Col, Button } from "react-bootstrap";
import { API_URL, API_ROUTES } from "../../../api";
import axios from "axios";
import { Form, Table } from "react-bootstrap";
import { InputNumber } from "primereact/inputnumber";

export const CreateProducts = ({
  isOrderCodeExist,
  headers,
  modalShow,
  setModalShow,
  props,
  widthContent,
  productInfo,
  handleChangeMa,
  addProducts,
  setRefresh,
  handleChangePro,
  handleRemoveProduct,
  setAddProducts,
  handleAddProduct,
  setProductInfo,
  showFileInput,
}) => {
  const createProduct = API_URL + API_ROUTES.GET_CREATE_PRODUCT;
  const postDataPro = async () => {
    if (isOrderCodeExist === true) {
      alert("Mã sản phẩm đã tồn tại vui lòng nhập mã mới");
    } else {
      try {
        let hasEmptyField = false;
        for (let i = 0; i < addProducts.length; i++) {
          const product = addProducts[i];
          if (!product.price || !product.inventory || !product.image) {
            hasEmptyField = true;
            break;
          }
        }
        if (hasEmptyField) {
          alert("Vui lòng điền đầy đủ thông tin cho tất cả các trường");
        } else {
          const body = addProducts?.map((item, index) => ({
            order_code: productInfo.order_code,
            name: productInfo.name,
            phanloai1: item.phanloai1,
            phanloai2: item.phanloai2,
            price: Number(item.price),
            quantity: Number(item.inventory),
            detail: productInfo.detail,
            thumbnail: item.imageProduct,
          }));
          const response = await axios.post(createProduct, body, headers);
          setRefresh(false);
          setModalShow(false);
          setAddProducts([
            {
              phanloai1: "",
              phanloai2: "",
              price: 0,
              inventory: 0,
              imageProduct: null,
            },
          ]);
          setProductInfo({
            order_code: "",
            name: "",
            thumbnail: "",
            category: "",
            price: "",
            inventory: "",
            detail: "",
          });
        }
      } catch (error) {
        alert("Lỗi, Vui lòng kiểm tra lại thông tin");
      }
    }
  };
  return (
    <Modal
      size="lg"
      {...props}
      backdrop="static"
      show={modalShow}
      onHide={() => setModalShow(false)}
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Thêm sản phẩm
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="gird">
        <Container>
          <Row>
            <Col className="w-100" xs={6} md={4}>
              <div className="input-group flex-nowrap mb-2">
                <div className="input-group-prepend">
                  <span
                    style={{ width: `${widthContent}px` }}
                    className="input-group-text"
                    id="addon-wrapping"
                  >
                    Mã Sản Phẩm
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  aria-label="Username"
                  aria-describedby="addon-wrapping"
                  name="order_code"
                  value={productInfo.order_code}
                  onChange={handleChangeMa}
                />
              </div>
              <div className="input-group flex-nowrap mb-2">
                <div className="input-group-prepend">
                  <span
                    style={{ width: `${widthContent}px` }}
                    className="input-group-text"
                    id="addon-wrapping"
                  >
                    Tên sản phẩm
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  aria-label="Username"
                  aria-describedby="addon-wrapping"
                  name="name"
                  value={productInfo.name}
                  onChange={handleChangeMa}
                />
              </div>
              <div>
                <nav className="d-flex w-100 mb-2 list-phanloai-product">
                  <div className="input-group flex-column ">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="phanloai1">
                        Phân loại 1
                      </span>
                    </div>
                    {addProducts.map((product, index) => (
                      <input
                        key={index}
                        id={`category-${index}`}
                        style={{ borderRadius: "0" }}
                        type="text"
                        className="form-control w-100 mb-2"
                        aria-label="Category"
                        aria-describedby={`category-${index}`}
                        placeholder="Đen,vàng..."
                        name="phanloai1"
                        value={product.phanloai1}
                        onChange={(e) => handleChangePro(index, e, "phanloai1")}
                      />
                    ))}
                  </div>
                  <div className="input-group flex-column ">
                    <div className="input-group-prepend">
                      <span className="input-group-text">Phân loại 2</span>
                    </div>
                    {addProducts.map((product, index) => (
                      <input
                        key={index}
                        id={`category-${index}`}
                        style={{ borderRadius: "0" }}
                        type="text"
                        className="form-control w-100 mb-2"
                        aria-label="Category"
                        aria-describedby={`category-${index}`}
                        placeholder="X,L..."
                        name="phanloai2"
                        value={product.phanloai2}
                        onChange={(e) => handleChangePro(index, e, "phanloai2")}
                      />
                    ))}
                  </div>
                  <div
                    className="input-group flex-nowrap flex-column"
                    // style={{ width: `${widthPhanloai1}px` }}
                  >
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text"
                        // id={`price-${index}`}
                      >
                        Giá bán
                      </span>
                    </div>
                    {addProducts.map((product, index) => (
                      <InputNumber
                        key={index}
                        style={{
                          padding: "0 !important",
                          marginBottom: "0.6rem",
                        }}
                        className="borderRadiusIpnb"
                        name="price"
                        inputId="integeronly"
                        value={product.price}
                        onChange={(e) =>
                          handleChangePro(index, e.value, "price")
                        }
                      />
                    ))}
                  </div>
                  <div
                    className="input-group flex-nowrap flex-column"
                    style={{ width: "100%" }}
                  >
                    <div className="input-group-prepend">
                      <span className="input-group-text">Số lượng</span>
                    </div>
                    {addProducts.map((product, index) => (
                      <input
                        key={index}
                        id={`inventory-${index}`}
                        type="number"
                        min="0"
                        style={{ borderRadius: "0" }}
                        className="form-control w-100 mb-2"
                        placeholder="0"
                        aria-label="Inventory"
                        aria-describedby={`inventory-${index}`}
                        name="inventory"
                        value={product.inventory || ""}
                        onChange={(e) => handleChangePro(index, e, "inventory")}
                      />
                    ))}
                  </div>
                  <div
                    className="input-group flex-nowrap flex-column"
                    style={{ width: "100%" }}
                  >
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text"
                        // id={`inventory-${index}`}
                      >
                        Tùy chọn
                      </span>
                    </div>
                    {addProducts.map((product, index) => (
                      <div key={index} className="d-flex justify-content-end">
                        <div className="d-flex gap-2 mr-2 mb-2 ">
                          <label htmlFor="fileInput">
                            <button
                              className="d-flex flex-column align-items-center gap-2 border border-secondary justify-content-between rounded"
                              onClick={() => showFileInput(index)}
                            >
                              <p className="mb-0">Ảnh</p>
                            </button>
                          </label>
                          <input
                            id={`fileInput-${index}`}
                            type="file"
                            style={{ display: "none" }}
                            onChange={(e) => handleChangePro(index, e, "image")}
                            name="image"
                          />
                          {addProducts[index]?.image && (
                            <img
                              style={{
                                height: "30px",
                                width: "40px",
                              }}
                              src={URL.createObjectURL(
                                addProducts[index].image
                              )}
                              alt="Selected"
                            />
                          )}
                        </div>
                        <button
                          style={{ borderRadius: "0" }}
                          className="btn btn-danger mb-2"
                          onClick={() => handleRemoveProduct(index)}
                        >
                          Xóa
                        </button>
                      </div>
                    ))}
                  </div>
                </nav>
                <button
                  className="btn btn-primary mb-2"
                  style={{ borderRadius: "0" }}
                  onClick={handleAddProduct}
                >
                  Thêm phân loại
                </button>
              </div>
              <p
                style={{ width: `${widthContent}px` }}
                className="mb-0 input-group-text mt-2 d-flex justify-content-center"
              >
                Mô tả sản phẩm
              </p>
              <div className="input-group flex-nowrap mb-2">
                <Form className="w-100">
                  <Form.Group
                    style={{ height: "300px" }}
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Control
                      as="textarea"
                      style={{ height: "100%" }}
                      rows={3}
                      name="detail"
                      value={productInfo.detail}
                      onChange={handleChangeMa}
                    />
                  </Form.Group>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => postDataPro()}>Lưu Sản Phẩm</Button>
        <Button
          className="bg-danger border-danger"
          onClick={() => setModalShow(false)}
        >
          Thoát Không lưu
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
