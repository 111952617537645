import React, { useEffect, useRef } from "react";
import Navigate from "../container/Navigate";
import { useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_URL, API_ROUTES } from "../../api";
import { Footer } from "./Footer";
import { Button } from "primereact/button";
import { useHeaders } from "../../const/headers";
import { InputGroup } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { updateTitle } from "../../const/updateTitle";
import { CreateAccount } from "../container/account/CreateAccount";
import { TokenAcc } from "../container/modalAccount/TokenAcc";
import { EditAccount } from "../container/account/EditAccount";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ModalExpProxy } from "../container/account/ModalExpProxy";
import { useTranslation } from "react-i18next";
import {
  toggleSelectAll,
  headerCheckbox,
  bodyCheckbox,
  onRowClick,
  rowClassName,
} from "../../const/checkboxPrimeReactjs";
export const ManageAccount = () => {
  updateTitle("Chốt Sale - Quản Lý Tài Khoản");
  const getDataFb = API_URL + API_ROUTES.GET_ACC_FB;
  const deleteFb = API_URL + API_ROUTES.DELETE_ACC_FB;
  const checkAcc = API_URL + API_ROUTES.CHECK_ACC_FB;
  const checkProxy = API_URL + API_ROUTES.CHECK_PROXY_FB;
  const checkReultsAcc = API_URL + API_ROUTES.CHECK_ACCOUT_RESULTS;
  const checkReultsProxy = API_URL + API_ROUTES.CHECK_PROXY_RESULTS;
  const headers = useHeaders();
  const [modalShow, setModalShow] = useState(false);
  const [selectedItem, setSelectedItem] = useState([]);
  const [dataFb, setDataFb] = useState([]);
  const [search, SetSearch] = useState("");
  const [showData, setShowData] = useState(false);
  const [selectedItemEdit, setSelectedItemEdit] = useState([]);
  const [refresh, setRefresh] = useState(true);
  const [selectedUIDStatus, setSelectedUIDStatus] = useState([]);
  const [selectedUIDProxy, setSelectedUIDProxy] = useState([]);
  const [openToken, setOpenToken] = useState(false);
  const [rfDataFb, setRfDataFb] = useState(false);
  const [taskId, setTaskId] = useState([]);
  const [taskIdProxy, setTaskIdProxy] = useState([]);
  const [dataRsAcc, setDataRsAcc] = useState("");
  const [dataRsProxy, setDataRsProxy] = useState("");
  const access = localStorage.getItem("access");
  const intervalRef = useRef();
  const { t } = useTranslation();

  const changeSearch = (event) => {
    const { value } = event.target;
    SetSearch(value);
  };
  const deleteSecletAccount = async () => {
    if (listUidCheck.length <= 0) {
      alert(t("Bạn phải chọn ít nhất 1 tài khoản để xóa"));
      return;
    }
    const confirm = window.confirm(
      t(
        "Lưu ý: Khi xoá tài khoản ra khỏi hệ thống. Tất cả các chiến dịch, kịch bản, nhãn khách hàng..., Dữ liệu liên quan đến tài khoản sẽ bị mất"
      )
    );
    if (confirm) {
      try {
        const body = { id: listUidCheck.map(Number) };
        const response = await axios.post(deleteFb, body, headers);
        setRefresh(!refresh);
      } catch (error) {
        toast.error(t("Xóa không thành công"));
      }
    }
  };
  const deleteOneAccount = async (item) => {
    const confirm = window.confirm(
      t(
        "Lưu ý: Khi xoá tài khoản ra khỏi hệ thống. Tất cả các chiến dịch, kịch bản, nhãn khách hàng..., Dữ liệu liên quan đến tài khoản sẽ bị mất"
      )
    );
    if (confirm) {
      try {
        const body = { id: [item.id] };
        const response = await axios.post(deleteFb, body, headers);
        setRefresh(!refresh);
      } catch (error) {
        toast.error(t("Xóa không thành công"));
      }
    }
  };
  const listUidCheck = selectedItem?.map((item) => item.id);
  const checkAccount = async () => {
    if (listUidCheck.length <= 0) {
      alert(t("Bạn phải chọn ít nhất 1 tài khoản để kiểm tra"));
    } else {
      setSelectedUIDStatus(listUidCheck);
      try {
        const response = await axios.post(
          checkAcc,
          { id_accounts: listUidCheck.map(Number) },
          headers
        );
        setTaskId(response.data.id_task);
        return response.data.status;
      } catch (error) {
        alert(error?.response?.data?.error);
        setRfDataFb(!rfDataFb);
        return null;
      }
    }
  };
  const checkResultsAccount = async () => {
    if (taskId?.length === 0) {
      return;
    }
    const body = { id_task: taskId };
    try {
      const res = await axios.post(checkReultsAcc, body, headers);
      setDataRsAcc(res.data);
      setRfDataFb(!rfDataFb);
    } catch (err) {
      setSelectedUIDStatus([]);
    }
  };
  useEffect(() => {
    intervalRef.current = setInterval(() => {
      checkResultsAccount();
    }, 3000);

    return () => clearInterval(intervalRef.current);
  }, [taskId]);

  useEffect(() => {
    if (dataRsAcc?.status === "SUCCESS") {
      setSelectedUIDStatus([]);
      setRfDataFb(!rfDataFb);
      clearInterval(intervalRef.current);
    }
  }, [dataRsAcc]);
  const checkAccountProxy = async () => {
    if (listUidCheck.length <= 0) {
      alert(t("Bạn phải chọn ít nhất 1 tài khoản để kiểm tra"));
    } else {
      setSelectedUIDProxy(listUidCheck);
      try {
        const response = await axios.post(
          checkProxy,
          { id_accounts: listUidCheck.map(Number) },
          headers
        );
        setTaskIdProxy(response.data.id_task);
        return response.data.status;
      } catch (error) {
        setSelectedUIDProxy([]);
        return null;
      }
    }
  };
  const checkResultsProxy = async () => {
    if (taskIdProxy?.length === 0) {
      return;
    }
    const body = { id_task: taskIdProxy };
    try {
      const res = await axios.post(checkReultsProxy, body, headers);
      setDataRsProxy(res.data);
      setRfDataFb(!rfDataFb);
    } catch (err) {
      setSelectedUIDProxy([]);
    }
  };
  useEffect(() => {
    intervalRef.current = setInterval(() => {
      checkResultsProxy();
    }, 3000);

    return () => clearInterval(intervalRef.current);
  }, [taskIdProxy]);

  useEffect(() => {
    if (dataRsProxy?.status === "SUCCESS") {
      setSelectedUIDProxy([]);
      setRfDataFb(!rfDataFb);
      clearInterval(intervalRef.current);
    }
  }, [dataRsProxy]);

  useEffect(() => {
    axios
      .get(getDataFb, headers)
      .then(function (response) {
        setDataFb(response.data);
      })
      .catch(function (error) {});
  }, [refresh, rfDataFb]);

  const handleEdit = (item) => {
    setShowData(true);
    setSelectedItemEdit(item);
  };
  const getDataAllFr = () => {};
  const statusBodyTemplate = (item) => (
    <span
      style={{
        color: selectedUIDStatus.includes(item?.id)
          ? "#000"
          : item?.checkpoint === false && item?.status_proxy === true
          ? "blue"
          : "red",
      }}
    >
      {t(
        selectedUIDStatus.includes(item?.id)
          ? "Đang kiểm tra"
          : item?.checkpoint === false && item?.status_proxy === true
          ? "Hoạt động"
          : item?.checkpoint === null
          ? ""
          : "Không hoạt động"
      )}
    </span>
  );
  const statusBodyProxy = (item) => (
    <span
      style={{
        color: selectedUIDProxy.includes(item?.id)
          ? "#000"
          : item?.status_proxy === true
          ? "blue"
          : "red",
      }}
    >
      {t(
        selectedUIDProxy.includes(item?.id)
          ? "Đang kiểm tra"
          : item?.status_proxy === false
          ? "Không hoạt động"
          : "Hoạt động"
      )}
    </span>
  );
  const buttonTemplate = (item) => (
    <td>
      <Button
        onClick={() => handleEdit(item)}
        className="button-blue radius"
        style={{
          marginRight: "8px",
          padding: "0.5rem 0.75rem",
        }}
      >
        {t("Sửa")}
      </Button>

      <Button
        className="button-red radius"
        style={{
          marginRight: "8px",
          padding: "0.5rem 0.75rem",
        }}
        onClick={() => {
          deleteOneAccount(item);
        }}
      >
        {t("Xoá")}
      </Button>
    </td>
  );
  const indexTemplate = (rowData, rowIndex) => {
    return <>{rowIndex.rowIndex + 1}</>;
  };
  const nameAndAvt = (rowData) => {
    return (
      <div className="d-flex gap-2 align-items-center">
        <img
          className="rounded-circle"
          style={{ width: "20px", height: "20px" }}
          src={rowData?.avatar}
        />
        <p>{rowData?.name}</p>
      </div>
    );
  };
  function filterByName(filterDataFb, searchKey) {
    return filterDataFb?.filter((item) =>
      Object.values(item).some(
        (value) =>
          typeof value === "string" &&
          value.toLowerCase().includes(searchKey.toLowerCase())
      )
    );
  }
  const resultPro = filterByName(dataFb, search);

  return (
    <div className="d-flex flex-column " style={{ height: "100vh" }}>
      <div className="notifica-container">
        <ToastContainer />
        <div className="navigate-container">
          <Navigate />
        </div>
        <div className="d-flex h-100 w-100">
          <div
            className="h-100 d-flex flex-column"
            style={{
              width: "100%",
              borderRight: "0.5px solid",
              borderLeft: "0.5px solid",
            }}
          >
            <div className="d-flex gap-2 w-100 justify-content-between p-2">
              <Button
                style={{ width: "100%" }}
                onClick={() => setModalShow(true)}
                className="button-blue d-flex justify-content-center"
              >
                {t("Thêm tài khoản Facebook")}
              </Button>
              <Button
                style={{ width: "100%" }}
                onClick={() => setOpenToken(true)}
                className="button-blue d-flex justify-content-center"
              >
                {t("Lấy mã đồng bộ")}
              </Button>
              <Button
                style={{
                  borderRadius: "0",
                  width: "100%",
                }}
                className="button-blue d-flex justify-content-center"
                onClick={() => {
                  checkAccount();
                }}
                disabled={
                  selectedUIDStatus?.length !== 0 ||
                  selectedUIDProxy?.length !== 0
                    ? true
                    : false
                }
              >
                {t("Kiểm tra tài khoản")}
              </Button>
              <Button
                style={{
                  borderRadius: "0",
                  width: "100%",
                }}
                className="button-blue d-flex justify-content-center"
                onClick={() => {
                  checkAccountProxy();
                }}
                disabled={
                  selectedUIDStatus?.length !== 0 ||
                  selectedUIDProxy?.length !== 0
                    ? true
                    : false
                }
              >
                {t("Kiểm tra Proxy")}
              </Button>
              <Button
                style={{
                  width: "100%",
                }}
                onClick={() => {
                  deleteSecletAccount();
                }}
                className="button-red d-flex justify-content-center"
              >
                {t("Xoá")}
              </Button>
              <InputGroup
                className="responsive-width-acc"
                style={{
                  width: "100%",
                  borderRadius: "0",
                }}
              >
                <Form.Control
                  placeholder={t("Tìm kiếm nhanh")}
                  aria-describedby="basic-addon2"
                  value={search}
                  onChange={changeSearch}
                  style={{
                    borderRadius: "0",
                  }}
                />
              </InputGroup>
            </div>
            <div
              className="m-2 h-100"
              style={{ border: "1px solid", overflow: "auto" }}
            >
              <DataTable
                size="small"
                value={resultPro || dataFb}
                className="small-font-table dataTableWithFixedHeader dataTableWithHoverEffect datatableoverflow"
                tableStyle={{ minWidth: "100%" }}
                rowClassName={(rowData) => rowClassName(rowData, selectedItem)}
                onRowClick={(e) =>
                  onRowClick(e.data, setSelectedItem, selectedItem)
                }
              >
                <Column
                  header={headerCheckbox(
                    selectedItem,
                    resultPro || dataFb,
                    toggleSelectAll,
                    setSelectedItem
                  )}
                  body={(rowData) =>
                    bodyCheckbox(
                      rowData,
                      selectedItem,
                      setSelectedItem,
                      getDataAllFr
                    )
                  }
                />
                <Column body={indexTemplate} header="STT"></Column>
                <Column field="UID" header="UID" sortable></Column>
                <Column
                  body={(rowData) => nameAndAvt(rowData)}
                  field="name"
                  header={t("Tên tài khoản")}
                  sortable
                ></Column>
                <Column field="note" header={t("Ghi chú")} sortable></Column>
                <Column
                  body={statusBodyTemplate}
                  header={t("Trạng thái tài khoản")}
                  field="checkpoint"
                  sortable
                ></Column>
                <Column
                  body={statusBodyProxy}
                  header={t("Trạng thái Proxy")}
                  field="status_proxy"
                  sortable
                ></Column>
                <Column body={buttonTemplate} header={t("Tuỳ chọn")}></Column>
              </DataTable>
              <CreateAccount
                t={t}
                headers={headers}
                setRefresh={setRefresh}
                modalShow={modalShow}
                setModalShow={setModalShow}
                toast={toast}
              />
              <TokenAcc
                openToken={openToken}
                setOpenToken={setOpenToken}
                t={t}
              />
              <EditAccount
                t={t}
                selectedItemEdit={selectedItemEdit}
                setSelectedItemEdit={setSelectedItemEdit}
                headers={headers}
                setRefresh={setRefresh}
                refresh={refresh}
                setShowData={setShowData}
                showData={showData}
                toast={toast}
              />
              <ModalExpProxy dataFb={dataFb} />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
