import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { API_ROUTES, API_URL } from "../../../api";
import { InputText } from "primereact/inputtext";
import { MdContentCopy } from "react-icons/md";
import { useHeaders } from "../../../const/headers";
import { ColorPicker } from "primereact/colorpicker";
import { useUser } from "../../../contexts/UserContext";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
// import { toast, ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import { MdLabel } from "react-icons/md";
import { AiOutlineEdit } from "react-icons/ai";
import { AiOutlineDelete } from "react-icons/ai";
import CustomColorPicker from "./colorPicker";

export const CreateLabels = ({
  setOpenLabel,
  openLabel,
  editQuestion,
  setRf,
  toast,
  setFtLabel,
  rf,
}) => {
  const headers = useHeaders();
  const { label, setLabel, rfLable, setRfLabel } = useUser();
  const createUpdate = API_URL + API_ROUTES.CREATE_LABELS;
  const deleteLabel = API_URL + API_ROUTES.DELETE_LABEL;
  const [content, setContent] = useState("");
  const [color, setColor] = useState("#FFEB3B");
  const [itemEdit, setItemEdit] = useState(null);
  const [nameEdit, setNameEdit] = useState("");
  const [colorEdit, setColorEdit] = useState("");
  const [modalCreate, setModalCreate] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const onChangeContent = (e) => {
    setContent(e.target.value);
  };
  const onChangeNameEdit = (e) => {
    setNameEdit(e.target.value);
  };
  useEffect(() => {
    setColorEdit(itemEdit?.color || "");
    setNameEdit(itemEdit?.name || "");
  }, [modalEdit]);
  const createNhan = async () => {
    if (label.length === 12) {
      alert("Bạn đã tạo đủ 12 nhãn , xoá nhãn củ để thêm nhãn mới");
      return;
    }
    if (content === "") {
      alert("Vui lòng nhập đầy đủ thông tin");
      return;
    }
    const data = {
      id_category: null,
      name: content,
      color: color === "" ? "#ff0000" : color,
    };
    try {
      const res = await axios.post(createUpdate, data, headers);
      toast.success("Tạo thành công");
      setRfLabel(!rfLable);
      setContent("");
      setColor("#FFEB3B");
      setModalCreate(false);
    } catch (err) {
      toast.error("Tạo thất bại");
    }
  };
  const editNhan = async () => {
    if (nameEdit === "") {
      alert("Vui lòng đặt tên cho nhãn");
      return;
    }
    const data = {
      id_category: itemEdit.id,
      name: nameEdit,
      color: colorEdit,
    };
    try {
      const res = await axios.post(createUpdate, data, headers);
      toast.success("Sửa thành công");
      setRfLabel(!rfLable);
      setItemEdit(null);
      setModalEdit(false);
    } catch (err) {
      toast.error("Tạo thất bại");
    }
  };
  const deleLabels = async (item) => {
    const confirm = window.confirm("Bạn có chắc chắn muốn xoá không?");
    if (confirm) {
      const body = { id_category: item?.id };
      try {
        await axios.post(deleteLabel, body, headers);
        setFtLabel([]);
        setRfLabel(!rfLable);
      } catch (err) {}
    }
  };

  return (
    <div className="flex justify-content-center">
      <Dialog
        header="Quản lý nhãn"
        visible={openLabel}
        onHide={() => setOpenLabel(false)}
        style={{ width: "30vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        <div
          style={{ border: "1px solid", borderRadius: "6px" }}
          className="d-flex flex-column gap-2 mt-2 p-2"
        >
          <h6>Danh sách nhãn</h6>
          <div className="d-flex flex-column gap-2">
            {label?.map((item, index) => (
              <div key={index} className="d-flex justify-content-between">
                <nav className="d-flex align-items-center">
                  <MdLabel style={{ color: `${item?.color}` }} />
                  <p
                    className="mb-0 p-2 rounded"
                    style={{
                      color: "#000",
                      cursor: "pointer",
                      width: "max-content",
                    }}
                  >
                    {item?.name}
                  </p>
                </nav>
                <nav className="d-flex gap-2">
                  <AiOutlineEdit
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setItemEdit(item);
                      setModalEdit(true);
                    }}
                  />
                  <AiOutlineDelete
                    style={{ cursor: "pointer" }}
                    onClick={() => deleLabels(item)}
                  />
                </nav>
              </div>
            ))}
          </div>
        </div>
        <nav>
          <Button
            className="button-blue
             radius"
            style={{ marginTop: "1rem" }}
            onClick={() => setModalCreate(true)}
            label="Thêm nhãn"
            text
          />
        </nav>
      </Dialog>
      <Dialog
        header=""
        visible={modalEdit}
        onHide={() => setModalEdit(false)}
        style={{ width: "30vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        <div style={{ border: "1px solid" }} className="p-2 mt-2">
          <h6>Sửa nhãn</h6>
          <div className="card d-flex flex-row align-items-center">
            <div className="p-inputgroup flex-1">
              <span
                className="p-inputgroup-addon "
                style={{ border: "0", padding: "7px" }}
              >
                <p className="mb-0">Tên nhãn</p>
              </span>
              <InputText
                style={{ border: "0", padding: "7px" }}
                onChange={onChangeNameEdit}
                placeholder=""
                value={nameEdit}
              />
            </div>

            <div>
              <CustomColorPicker
                value={`${colorEdit}`}
                onChange={(e) => setColorEdit(e.value)}
              />
            </div>
          </div>
          <div className="d-flex justify-content-end gap-2 mt-2">
            <Button
              className="button-blue radius"
              label="Lưu"
              onClick={editNhan}
            />
            <Button
              className="button-red radius"
              label="Hủy"
              onClick={() => setItemEdit(null)}
              severity="danger"
            />
          </div>
        </div>
      </Dialog>
      <Dialog
        header=""
        visible={modalCreate}
        onHide={() => setModalCreate(false)}
        style={{ width: "30vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        <div style={{ border: "1px solid" }} className="p-2">
          <h6>Thêm nhãn</h6>
          <div className="card d-flex flex-row align-items-center">
            <div className="p-inputgroup flex-1">
              <span
                className="p-inputgroup-addon "
                style={{ border: "0", padding: "7px" }}
              >
                <p className="mb-0">Tên nhãn</p>
              </span>
              <InputText
                style={{ border: "0", padding: "7px" }}
                onChange={onChangeContent}
                placeholder=""
                value={content}
              />
            </div>
            {/* <ColorPicker value={color} onChange={(e) => setColor(e.value)} /> */}
            <div>
              <CustomColorPicker
                value={color}
                onChange={(e) => setColor(e.value)}
              />
            </div>
          </div>
          <div className="d-flex justify-content-end gap-2 mt-2">
            <Button
              className="button-blue radius"
              label="Tạo"
              onClick={createNhan}
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};
