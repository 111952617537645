import { useState } from "react";
import { FaRegFileLines } from "react-icons/fa6";

export const FilterTypeAtackment = ({
  message,
  getSender,
  idAcc,
  idPage,
  faimg,
  setMessReply,
  MdReply,
  filteredMessages,
  inputRef,
}) => {
  const [isZoomed, setIsZoomed] = useState(false);
  const [zoomedImage, setZoomedImage] = useState(null);

  const handleImageClick = (image) => {
    setZoomedImage(image);
    setIsZoomed(true);
  };
  const handleCloseZoom = () => {
    setIsZoomed(false);
    setZoomedImage(null);
  };

  const styles = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "#4a4b4b",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 1000,
      cursor: "pointer",
    },
    zoomedImageContainer: {
      position: "relative",
    },
    zoomedImage: {
      maxWidth: "90%",
      maxHeight: "90%",
    },
  };
  return (
    <div>
      {message?.video_attachment?.length > 0 ? (
        <div className="d-flex align-items-end gap-2">
          <img
            style={{
              display: getSender === message?.from_id ? "none" : "",
              borderRadius: "50%",
              width: "20px",
            }}
            src={idAcc?.page === idPage ? idAcc?.client_avatar || faimg : ""}
          />
          <video
            style={{
              borderRadius: "12px",
              width: "200px",
            }}
            controls
          >
            <source
              src={message?.video_attachment?.map((item) => item.playable_url)}
              type="video/mp4"
            />
          </video>
        </div>
      ) : message?.image_attachment.length > 0 ? (
        <div className="d-flex align-items-end gap-2 w-100">
          <img
            style={{
              display: getSender === message?.from_id ? "none" : "",
              borderRadius: "50%",
              width: "20px",
            }}
            src={idAcc?.page === idPage ? idAcc?.client_avatar || faimg : ""}
          />
          <nav className="d-flex flex-wrap mt-2 hiden-reply">
            {message?.image_attachment.map((item, index) => (
              <div
                className="d-flex align-items-center"
                style={{
                  borderRadius: "12px",
                  width: "50%",
                  height: "50%",
                  marginBottom: "0.5rem",
                  cursor: "pointer",
                  position: "relative",
                }}
              >
                {getSender === message?.from_id ? (
                  <MdReply
                    onClick={() => {
                      setMessReply(message);
                      if (inputRef.current) {
                        inputRef.current.focus();
                      }
                    }}
                    className="reply-icon"
                    style={{
                      color: "#000",
                      position: "absolute",
                      left: "-30px",
                      cursor: "pointer",
                    }}
                    size={30}
                  />
                ) : (
                  <></>
                )}
                <nav
                  className={`d-flex flex-column ${
                    getSender === message?.from_id
                      ? "align-items-end"
                      : "align-items-start"
                  }`}
                >
                  {message?.replied_to_message !== null ? (
                    <p
                      style={{
                        background: "#c5c5c5",
                        borderRadius: "6px",
                        fontSize: "10px",
                        textAlign: "-webkit-fill-available",
                        width: "100%",
                        padding: "4px",
                        fontWeight: "700",
                      }}
                      className="text-dark"
                    >
                      {`
                     ${
                       filteredMessages?.filter(
                         (item) =>
                           item.id_message ===
                           message?.replied_to_message?.message_id
                       )[0]?.from_id === idAcc.client_profile_id &&
                       filteredMessages?.filter(
                         (item) =>
                           item.id_message ===
                           message?.replied_to_message?.message_id
                       )[0]?.from_id === message.from_id
                         ? `${idAcc.client_fullname} đã trả lời chính họ:  `
                         : ""
                     }
                                                          ${
                                                            filteredMessages?.filter(
                                                              (item) =>
                                                                item.id_message ===
                                                                message
                                                                  ?.replied_to_message
                                                                  ?.message_id
                                                            )[0]?.from_id !==
                                                              idAcc.client_profile_id &&
                                                            filteredMessages?.filter(
                                                              (item) =>
                                                                item.id_message ===
                                                                message
                                                                  ?.replied_to_message
                                                                  ?.message_id
                                                            )[0]?.from_id ===
                                                              message.from_id
                                                              ? `Bạn đã trả lời chính mình:  `
                                                              : ""
                                                          }
                                                           ${
                                                             filteredMessages?.filter(
                                                               (item) =>
                                                                 item.id_message ===
                                                                 message
                                                                   ?.replied_to_message
                                                                   ?.message_id
                                                             )[0]?.from_id ===
                                                               idAcc.client_profile_id &&
                                                             filteredMessages?.filter(
                                                               (item) =>
                                                                 item.id_message ===
                                                                 message
                                                                   ?.replied_to_message
                                                                   ?.message_id
                                                             )[0]?.from_id !==
                                                               message.from_id
                                                               ? `Bạn đã trả lời ${idAcc.client_fullname}:  `
                                                               : `  `
                                                           }
                                                            ${
                                                              filteredMessages?.filter(
                                                                (item) =>
                                                                  item.id_message ===
                                                                  message
                                                                    ?.replied_to_message
                                                                    ?.message_id
                                                              )[0]?.from_id !==
                                                                idAcc.client_profile_id &&
                                                              filteredMessages?.filter(
                                                                (item) =>
                                                                  item.id_message ===
                                                                  message
                                                                    ?.replied_to_message
                                                                    ?.message_id
                                                              )[0]?.from_id !==
                                                                message.from_id
                                                                ? `${idAcc.client_fullname} đã trả lời bạn:   `
                                                                : `   `
                                                            }
                                                            ${
                                                              filteredMessages?.filter(
                                                                (item) =>
                                                                  item.id_message ===
                                                                  message
                                                                    ?.replied_to_message
                                                                    ?.message_id
                                                              )[0]?.message
                                                            }
                      `}
                    </p>
                  ) : (
                    <></>
                  )}
                  <img
                    key={index}
                    style={{
                      borderRadius: "12px",
                      width: "100%",
                      height: "100%",
                      marginBottom: "0.5rem",
                      cursor: "pointer",
                    }}
                    src={item.large_preview}
                    alt={`hinhanh-${index}`}
                    onClick={() => handleImageClick(item.large_preview)}
                  />
                </nav>
                {getSender === message?.from_id ? (
                  <></>
                ) : (
                  <MdReply
                    size={30}
                    onClick={() => {
                      setMessReply(message);
                      if (inputRef.current) {
                        inputRef.current.focus();
                      }
                    }}
                    className="reply-icon"
                    style={{
                      color: "#000",
                      cursor: "pointer",
                      position: "absolute",
                      right: "-40px",
                    }}
                  />
                )}
              </div>
            ))}
            {isZoomed && (
              <div style={styles.overlay} onClick={handleCloseZoom}>
                <div style={styles.zoomedImageContainer}>
                  <img
                    src={zoomedImage}
                    alt="Zoomed"
                    style={styles.zoomedImage}
                  />
                </div>
              </div>
            )}
          </nav>
        </div>
      ) : message?.file_attachment?.length > 0 ? (
        <div className="d-flex align-items-end gap-2">
          <img
            style={{
              display: getSender === message?.from_id ? "none" : "",
              borderRadius: "50%",
              width: "20px",
            }}
            src={idAcc?.page === idPage ? idAcc?.client_avatar || faimg : ""}
          />
          <a
            className="d-flex h-100 p-2 "
            style={{
              background: "#f0f0f0",
              color: "#000000",
              fontSize: "16px",
              fontWeight: 700,
              borderRadius: "12px",
            }}
            href={message?.file_attachment?.map((item) => item.url)}
            target="blank"
          >
            <FaRegFileLines />
            <p className="m-0">
              {message?.file_attachment?.map((item) => item?.filename)}
            </p>
          </a>
        </div>
      ) : message?.audio_attachment?.length > 0 ? (
        <div className="d-flex align-items-end gap-2 ">
          <img
            style={{
              display: getSender === message?.from_id ? "none" : "",
              borderRadius: "50%",
              width: "20px",
            }}
            src={idAcc?.page === idPage ? idAcc?.client_avatar || faimg : ""}
          />
          <audio controls>
            <source
              src={(message?.audio_attachment)[0].playable_url}
              type="audio/mpeg"
            />
          </audio>
        </div>
      ) : message?.sticker.length > 0 ? (
        <div className="d-flex align-items-end gap-2">
          <img
            style={{
              display: getSender === message?.from_id ? "none" : "",
              borderRadius: "50%",
              width: "20px",
            }}
            src={idAcc?.page === idPage ? idAcc?.client_avatar || faimg : ""}
          />
          <img
            style={{
              borderRadius: "",
              width: "50%",
              marginBottom: "0.5rem",
            }}
            src={message?.sticker?.map((item) => item?.url)}
            alt="sticker"
          />
        </div>
      ) : message?.animated_image_attachment.length > 0 ? (
        <div className="d-flex align-items-end gap-2">
          <img
            style={{
              display: getSender === message?.from_id ? "none" : "",
              borderRadius: "50%",
              width: "20px",
            }}
            src={idAcc?.page === idPage ? idAcc?.client_avatar || faimg : ""}
          />
          <img
            style={{
              borderRadius: "",
              width: "50%",
              marginBottom: "0.5rem",
            }}
            src={message?.animated_image_attachment?.map(
              (item) => item?.animated_image
            )}
            alt="animated"
          />
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};
