import React, { useState, useEffect } from "react";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { API_URL, API_ROUTES } from "../../../api";
import { useHeaders } from "../../../const/headers";
import axios from "axios";
import { FaDeleteLeft } from "react-icons/fa6";

export default function TrainBot({ itemPage }) {
  const headers = useHeaders();
  const getTrain = API_URL + API_ROUTES.GET_ALL_TRAIN_BOT;
  const deleteBot = API_URL + API_ROUTES.DELETE_TRAIN_BOT;
  const [dataTrain, setDataTrain] = useState([]);
  const deleteBotTrain = async (id) => {
    const body = { id_trainbot: id };
    try {
      const res = await axios.post(deleteBot, body, headers);
      fetchData();
    } catch (err) {}
  };
  const fetchData = async () => {
    const body = {
      id_account: itemPage.id,
    };
    try {
      const res = await axios.post(getTrain, body, headers);
      setDataTrain(res.data);
    } catch (err) {}
  };

  useEffect(() => {
    fetchData();
  }, [itemPage]);

  const generateGreetings = (input) => {
    const words = input.split(" ");
    const greetings = [];

    for (let i = 0; i < words.length; i++) {
      let greeting = "";
      for (let j = i; j < words.length; j++) {
        greeting += (greeting ? " " : "") + words[j];
        greetings.push(greeting);
      }
    }

    return greetings;
  };

  const data = dataTrain?.trainbots?.map((item, index) => {
    const greetings = generateGreetings(item.question);
    const children = greetings.map((greeting, i) => ({
      key: `child-${index}-${i}`,
      data: {
        name: greeting,
        size: "10kb",
        type: "Zip",
      },
    }));

    return {
      key: index,
      data: {
        name: item.question,
        id: item.id,
      },
      children: children,
    };
  });

  const deleteItem = (rowData) => {
    deleteBotTrain(rowData.data.id);
  };

  const actionTemplate = (rowData) => {
    if (rowData?.children) {
      return (
        <Button
          icon={FaDeleteLeft}
          className="button-red radius"
          onClick={() => deleteItem(rowData)}
        />
      );
    } else {
      return null;
    }
  };
  return (
    <div className="card" style={{ overflow: "auto" }}>
      <TreeTable
        value={data}
        tableStyle={{ minWidth: "30rem" }}
        emptyMessage=""
      >
        <Column field="name" header="Câu hỏi" expander></Column>
        <Column header="Tùy chọn" body={actionTemplate}></Column>
      </TreeTable>
    </div>
  );
}
