import React, { useEffect, useState, useRef } from "react";
import { MdLanguage } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { useStatus } from "../../contexts/StatusContext";
export const Footer = () => {
  const { i18n, t } = useTranslation();
  const { language, setLanguage } = useStatus();
  const handleLaguage = () => {
    if (language === "vi") {
      i18n.changeLanguage("en");
      setLanguage("en");
    } else {
      i18n.changeLanguage("vi");
      setLanguage("vi");
    }
  };
  return (
    <div
      id="footer"
      className="d-flex w-100  text justify-content-center"
      style={{
        background: "#2c343f",
        gap: "80px",
        padding: "10px 20px",
      }}
    >
      <div className="footer d-flex justify-content-between w-100">
        <a
          href="https://www.facebook.com/phanmemchotsale"
          target="blank"
          style={{
            width: "max-content",
            color: "#fff",
            background: "#000",
            padding: "6px 12px",
            textDecoration: "none",
            fontSize: "14px",
          }}
        >
          LIÊN HỆ CHỐT NHANH
        </a>
        <MdLanguage
          cursor="pointer"
          onClick={() => handleLaguage()}
          size={25}
          color="#fff"
        />
      </div>
      {/* <nav className="text-center" style={{ flex: "1" }}>
        {location.pathname === "/managepage" ? (
          <p
            className="mb-0 text-light "
            style={{ fontWeight: "300", fontSize: "12px" }}
          >
            {`Tổng số Page đã kết nối: ${dataFb?.length}/${userInfo?.page_limit}`}
          </p>
        ) : (
          <></>
        )}
        {location.pathname === "/manageemployee" ? (
          <p
            className="mb-0 text-light "
            style={{ fontWeight: "300", fontSize: "12px" }}
          >
            {`Tổng số nhân viên đã được tạo: ${listEmpl?.length}/${userInfo?.employee_limit}`}
          </p>
        ) : (
          <></>
        )}
      </nav> */}
    </div>
  );
};
