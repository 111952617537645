import React from "react";
import { Dialog } from "primereact/dialog";
import { API_URL } from "../../../../api";
export const ModalDetailsSending = ({
  setModalDetails,
  modalDetails,
  itemResults,
}) => {
  return (
    <Dialog
      header="Trạng thái chi tiết"
      visible={modalDetails}
      style={{ width: "50vw" }}
      onHide={() => setModalDetails(false)}
    >
      <div className="f-flex flex-column gap-2">
        <nav
          style={{
            paddingBottom: "0",
            paddingTop: "0.5rem",
          }}
          className="d-flex gap-2"
        >
          <h5>UID: {itemResults?.account?.UID}</h5>
          <h5>{itemResults?.account?.name}</h5>
        </nav>

        <nav
          style={{
            borderBottom: "1px solid",
            paddingBottom: "0.5rem",
            paddingTop: "0",
          }}
          className="d-flex gap-2"
        >
          <h6 style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <>
              <p>Link bài viết:</p>
              <p>
                <a
                  href={itemResults?.link}
                  target="_blank"
                  rel="nofollow noreferrer"
                >
                  {itemResults?.link
                    ? itemResults.link.length > 50
                      ? `${itemResults.link.slice(0, 50)}...`
                      : itemResults.link
                    : ""}
                </a>
              </p>
            </>
          </h6>
        </nav>
        <nav
          style={{
            borderBottom: "1px solid",
            paddingBottom: "0.5rem",
            paddingTop: "0.5rem",
          }}
          className="d-flex gap-2"
        >
          <p>
            {itemResults?.status_like === 2
              ? "Like: Không chạy"
              : itemResults?.status_like === 1
              ? "Like thành công."
              : itemResults?.status_like === 0
              ? "Like thất bại."
              : "Hạn chế tính năng like."}
          </p>
        </nav>
        <nav
          style={{
            borderBottom: "1px solid",
            paddingBottom: "0.5rem",
            paddingTop: "0.5rem",
          }}
          className=""
        >
          <nav
            style={{
              paddingBottom: "0.5rem",
              paddingTop: "0.5rem",
            }}
            className="d-flex gap-2"
          >
            <p>
              {itemResults?.status_comment === 2 ? (
                "Bình luận: Không chạy"
              ) : itemResults?.status_comment === 1 ? (
                <>
                  Bình luận thành công:&nbsp;
                  <a
                    href={`https://www.facebook.com/${itemResults?.id_comment}`}
                    target="_blank"
                    rel="nofollow noreferrer"
                  >
                    {itemResults?.id_comment}
                  </a>
                </>
              ) : itemResults?.status_comment === 0 ? (
                "Bình luận thất bại."
              ) : (
                "Hạn chế tính năng bình luận."
              )}
            </p>
          </nav>
          <nav
            style={{
              paddingBottom: "0.5rem",
              paddingTop: "0.5rem",
            }}
            className="d-flex gap-2 w-100"
          >
            <p style={{ width: "max-content" }}>Nội dung bình luận:</p>
            <p style={{ width: "fit-content", textAlign: "justify" }}>
              {itemResults?.comment_content}
            </p>
            {itemResults?.comment_image && (
              <img
                style={{ width: "100px", height: "100%" }}
                src={`${API_URL}/${itemResults?.comment_image}`}
              />
            )}
          </nav>
        </nav>

        <nav
          style={{
            paddingBottom: "0.5rem",
            paddingTop: "0.5rem",
          }}
          className="d-flex gap-2"
        >
          <p>
            {itemResults?.status_share === 2 ? (
              "Chia sẻ: Không chạy"
            ) : itemResults?.status_share === 1 ? (
              <>
                Chia sẻ thành công:
                <a
                  href={`https://www.facebook.com/${itemResults?.id_share}`}
                  target="_blank"
                  rel="nofollow noreferrer"
                >
                  {itemResults?.id_share}
                </a>
              </>
            ) : itemResults?.status_share === 0 ? (
              "Chia sẻ thất bại."
            ) : (
              "Hạn chế tính năng chia sẻ."
            )}
          </p>
        </nav>
      </div>
    </Dialog>
  );
};
